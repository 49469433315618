/** React / Utils */
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import { withRouter } from 'react-router-dom';

/** Material UI */
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

/** Local */
import { pageTitle } from '../../constants/page.const';
import { DialogTypes } from '../../constants/dialog.const';
import useSnacks from '../../hooks/useSnacks';
import ORDER_DETAIL_QUERY from '../../queries/orderDetail.query';
import QUERY_GC_BALANCE_MUTATION from '../../mutations/queryGcBalance.mutation';
import ASSET_SEARCH_QUERY from '../../queries/assetSearch.query';
import Loading from '../shared/loading';
import ResponseSnackBar from '../shared/snackbar';
import consumerActions from '../../store/actions/consumerActions';
import { actions as dialogActions } from '../../store/actions/dialogActions';
import { ConsumerContext } from '../../store/contexts/consumerContext';
import { DialogContext } from '../../store/contexts/dialogContext';
import { AthleteContext } from '../../store/contexts/athleteContext';
import { OrderContext } from '../../store/contexts/orderContext';
import AssetsContext from '../../store/contexts/assetsContext';
import { isSwooshOrder, filterNikeIDLines } from '../../utils/order';
import { decorateOrderDetail } from '../../utils/orderDetailDecoration';
import TopBar from './topBar';
import translations from './order.i18n';
import InfoDrawer from './infoDrawer';
import Content from './content';
import InfoHeadingCard from './content/details/infoHeadingCard';
import { Geos } from '../../constants/origin.const';
import { getOrderDetailQuery } from '../../utils/query';
import { getAssetsSearchQuery } from '../../utils/product';
import Geo from '../../constants/geos.const';
import { useOktaAuth } from '@okta/okta-react';

/**
 * Main react component housing the orderDetail sidebar, tabs and toolbar
 */
const Order = ({ location, match }) => {
  const [, setOrderDetail] = useContext(OrderContext);
  const [, consumerDispatch] = useContext(ConsumerContext);
  const [, setAssets] = useContext(AssetsContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [, setAthlete] = useContext(AthleteContext);
  const { i18nString } = useContext(NikeI18nContext);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(true);
  const { setConsumer, setIsSwooshMember } = consumerActions;
  const { authState } = useOktaAuth();
  const { setError } = useSnacks();
  const orderId = match && match.params && match.params.orderId;
  const classes = useStyles();
  const { lock } = dialogState;

  const {
    CANNOT_ENTER_SPECIFIED_FLOW,
    ERROR_FROM_GIFT_CARD_API,
    MISSING_ORDER_LINES_ERROR,
    KOREAN_DATA_ACCESS_ERROR,
  } = mapValues(translations, i18nString);

  // TODO: Add orderDetailQuery Hook to avoid props drilling
  const [orderDetailQuery, { loading, error, data }] = useLazyQuery(ORDER_DETAIL_QUERY, {
    errorPolicy: 'all',
    variables: {
      orderNumber: orderId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const toggleInfoDrawer = () => setInfoDrawerOpen(!infoDrawerOpen);

  const [getGiftCardBalance] = useMutation(QUERY_GC_BALANCE_MUTATION, {
    onError: (err) => {
      setError(`${ERROR_FROM_GIFT_CARD_API} ${err?.message}`);
    },
  });

  const [fetchAssets, { data: assetSearchData }] = useLazyQuery(ASSET_SEARCH_QUERY, {
    variables: {
      anchor: 0,
      count: 500,
      facetFields: '',
      facetQuery: 'assetType==image;type==product;cropType==1X1;view==A',
      queryFields: '',
      searchTerms: '',
      sort: '',
      where: getAssetsSearchQuery(data?.orderDetail?.orderLines),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    // resets price mod flow
    dialogDispatch(dialogActions.reset());
    orderDetailQuery();
  }, [orderId]);

  useEffect(() => {
    if (error?.graphQLErrors && data) {
      let ignoreError = false;
      /**
       * converts graphql error to detailed format for error snack
       * simplified data looks like:
        {
          graphQLErrors: [
            {
              message: '401: Unauthorized',
              path: ['orderDetail', 'shipmentsV2'],
            },
          ],
          networkError: null,
          message: 'GraphQL error: 401: Unauthorized',
        }
       * resulting message:
        GraphQL Error: Error 1: orderDetail, shipmentsV2, Message: 401: Unauthorized.
       */
      const graphQLErrorPaths = error.graphQLErrors.reduce((accu, error, index) => {
        if (error.path.includes('shipmentsV2') && error.message?.includes('404')) {
          ignoreError = true;
        }
        let errorMessage = error.message;
        if (errorMessage === 'Can not access/modify Korea consumer data') {
          errorMessage = KOREAN_DATA_ACCESS_ERROR;
        }
        return (
          accu +
          `Error ${index + 1}: ${error.path.reduce((accu, action) => {
            return `${accu} ${action},`;
          }, '')} Message: ${errorMessage}. `
        );
      }, '');
      if (!ignoreError) {
        setError('GraphQL Error: ' + graphQLErrorPaths);
      }
    } else if (error) {
      setError('Order Detail Error: ' + error.message);
    }
    if (data?.orderDetail) {
      const { orderDetail } = data;

      // Update page title to active order number
      document.title = `Order #${orderDetail.orderNumber} ${pageTitle}`;

      // remove duplicate order lines in NBY orders
      filterNikeIDLines(orderDetail);

      // add CS-specific details to order object
      decorateOrderDetail(orderDetail);

      // and set that order object in state
      setOrderDetail(orderDetail);

      // TODO: tidy this up when we add actual profile integration
      const isSwoosh = isSwooshOrder(orderDetail);
      consumerDispatch(setConsumer(orderDetail.customerProfileReference));
      consumerDispatch(setIsSwooshMember(isSwoosh));
    }
  }, [data, error]);

  /*
   * For each gift card orderline, get gift card balance for that orderline
   * and it to the order details data
   */
  useEffect(() => {
    if (data?.orderDetail?.orderLines) {
      const { orderDetail } = data;
      orderDetail.orderLines.forEach((orderLine, i) => {
        if (orderLine.styleNumber === 'GIFTCARD') {
          const giftCardValue = orderLine?.giftCardDetail?.giftCardValue;

          // corrects test data issues
          orderLine.isGiftCardUsed = true;
          orderLine.giftCardBalance = 0;
          orderLine.giftCardValue = giftCardValue || 0;
          setOrderDetail(orderDetail);
          const geo = orderDetail?.omsRegionReference;
          if (
            Array.isArray(orderLine?.giftCardDetail?.giftCards) &&
            geo !== Geos.NIKECN &&
            geo !== Geos.NIKEKR
          ) {
            getGiftCardBalance({
              variables: {
                input: {
                  accountNumber: orderLine?.giftCardDetail?.giftCards[0]?.giftCardNumber,
                  pin: orderLine?.giftCardDetail?.giftCards[0]?.pin || '',
                  currency: orderDetail.currency,
                },
              },
              /*
              update runs similarly to onCompleted when response data is returned. 
              However, we can pass a custom function within this forEach scope.

              Here we use the response data to calculate current balance over the original
              GC card balance, and modify the order detail.
              */
              update: (_, { data: { queryGiftCardBalance } }) => {
                /* 
                checks if a gift card has been used, so that it cannot be returned
                also checks for GCs with missing data and marks them as used as to not cause errors
                */
                orderLine.giftCardBalance = queryGiftCardBalance?.balance || 0;
                orderLine.giftCardValue = giftCardValue || 0;
                orderLine.isGiftCardUsed = Boolean(
                  orderLine.giftCardValue === 0 || queryGiftCardBalance?.balance < giftCardValue
                );

                setOrderDetail(orderDetail);
              },
            });
          }
        }
      });
    }
  }, [data]);

  /*
   * For each product orderline, fetch the product image
   */
  useEffect(() => {
    // call assetSearch only for NON-CHINA orders
    if (data?.orderDetail?.orderLines && data?.orderDetail?.omsRegionReference !== Geo.CHINA) {
      fetchAssets();
    }
  }, [data]);

  useEffect(() => {
    const assetsObject = {};
    assetSearchData?.assetSearchV2?.objects?.forEach((asset) => {
      assetsObject[asset?.results[0]?.styleColor] = asset?.results[0]?.defaultURL;
    });
    setAssets(assetsObject);
  }, [assetSearchData]);

  /*
    Open up a specific dialog flow if the 'flow' query parameter is present and the order qualifies.
   */
  useEffect(() => {
    // Only execute if have order detail information.
    if (data?.orderDetail) {
      // Retrieve the flow parameter from URL.
      const params = new URLSearchParams(location.search);
      const flow = params.get('flow');

      // If the flow parameter is there, dispatch the appropriate dialog/snack.
      if (flow) {
        switch (flow) {
          case 'cancel':
            dialogDispatch(dialogActions.open(DialogTypes.CANCEL, true));
            break;
          case 'inspect':
            dialogDispatch(dialogActions.open(DialogTypes.INSPECT, true));
            break;
          case 'reinspect':
            dialogDispatch(dialogActions.open(DialogTypes.INSPECT, true));
            break;
          case 'discountshipping':
            dialogDispatch(dialogActions.open(DialogTypes.DISCOUNT_SHIPPING, true));
            break;
          case 'return':
            dialogDispatch(dialogActions.open(DialogTypes.RETURN, true));
            break;
          case 'resendreturnlabel':
            dialogDispatch(dialogActions.open(DialogTypes.RESEND_RETURN_LABEL, true));
            break;
          default:
            setError(`${CANNOT_ENTER_SPECIFIED_FLOW} ${flow}.`);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (authState?.isAuthenticated && authState?.idToken?.claims) {
      const claims = authState?.idToken?.claims;
      setAthlete({ email: claims?.email, name: claims?.name });
    }
  }, [authState]);

  const {
    orderLines,
    omsRegionReference,
    orderType,
    isFallbackOrderDetails,
  } = data?.orderDetail || {
    ...getOrderDetailQuery(),
    isFallbackOrderDetails: true,
  };

  return (
    <>
      <div style={{ display: loading || isFallbackOrderDetails ? 'block' : 'none' }} role='main'>
        <h1 className='accessibly-hidden'>OMOBO</h1>
        <Loading />
      </div>

      <div style={{ display: !loading ? 'flex' : 'none' }} className={classes.root}>
        <Box className={classes.page}>
          <TopBar handleInfoDrawer={toggleInfoDrawer} infoDrawerOpen={infoDrawerOpen} />
          {/* hide content if screen is locked */}
          {!lock &&
            (orderLines ? (
              <Content region={omsRegionReference} orderType={orderType} />
            ) : (
              <InfoHeadingCard
                className={classes.errorBanner}
                headings={MISSING_ORDER_LINES_ERROR}
                data-testid='order-has-no-orderLines-message'
              />
            ))}
        </Box>
        {/* close info drawer if screen is locked */}
        <InfoDrawer open={lock ? false : infoDrawerOpen} orderChangeCallback={orderDetailQuery} />
      </div>
      <ResponseSnackBar />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    overflowX: 'hidden',
  },
  page: {
    flex: '1 1 auto',
    overflow: 'auto',
    backgroundColor: theme.palette.grey[50],
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1,
    overflowX: 'hidden',
  },
  content: {
    display: 'flex',
  },
  errorBanner: {
    margin: '2rem auto',
    width: '80%',
  },
}));

export default withRouter(Order);
