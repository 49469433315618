const hiraganaCharacterRange = /[\u3040-\u309f]/;
const katakanaCharacterRange = /[\u30a0-\u30ff]/;

/**
 * Given an alternateName from an orderDetail and a kanaName from an IDN payload, picks which name
 * to use as the preferred kana name.
 *
 * @param orderName {string} - the alternateName from the orderDetail
 * @param idnName {string} - the kanaName from the IDN service
 * @returns {string} - the preferred kana name
 */
export const pickKanaName = (orderName, idnName) => {
  if (orderName) {
    if (!idnName || orderName === idnName) {
      return orderName;
    } else {
      return pickKanaNameWhenEachHasADifferentValue(orderName, idnName);
    }
  } else {
    /*
    There is no alternateName available from the orderDetail.
     */
    return idnName;
  }
};

/**
 * This function handles the edge case were both names have values that do not equal each other.
 * It picks the name that has a significantly higher percentage of kana characters. If neither name
 * has a significant kana or katakana advantage, the alternateName from the orderDetail is returned.
 *
 * @param orderName {string} - the alternateName from the orderDetail
 * @param idnName {string} - the kanaName from the IDN service
 * @returns {string} - whichever of the two names has a significantly higher percentage of kana
 * characters
 */
const pickKanaNameWhenEachHasADifferentValue = (orderName, idnName) => {
  const orderPercentages = getKanaPercentages(orderName);
  const idnPercentages = getKanaPercentages(idnName);
  // When calculating advantage, divide by very small number if orderPercentage value is zero.
  const idnKanaAdvantage = idnPercentages.kana / (orderPercentages.kana || 0.000001);
  const orderKanaAdvantage = 1 / idnKanaAdvantage;
  const idnKatakanaAdvantage = idnPercentages.katakana / (orderPercentages.katakana || 0.000001);

  if (idnKanaAdvantage > 2) {
    // If the IDN name is significantly more kana, prefer the IDN name.
    return idnName;
  } else if (orderKanaAdvantage > 2) {
    // If the Order name is significantly more kana, prefer the Order name.
    return orderName;
  } else if (idnKatakanaAdvantage > 2) {
    /*
     If one name is not more significantly kana than the other, only prefer the IDN name if it is
     significantly more katakana than the Order name (katakana is preferred over hiragana).
     */
    return idnName;
  } else {
    // If the IDN name has no significant kana or katakana advantage, return the order name.
    return orderName;
  }
};

/**
 * Gets the percentages (0 through 100) of kana, katakana, and hiragana characters in the given
 * value. If the value is falsy, returns a percentages object with values of 0.
 *
 * @param value {string} - the value to evaluate
 * @returns {{hiragana: number, kana: number, katakana: number}} - the percentages of kana,
 * katakana, and hiragana characters
 */
export const getKanaPercentages = (value) => {
  let katakanaCount = 0;
  let hiraganaCount = 0;
  let kanaCount = 0;
  const textCharacters = value ? [...value] : [];
  textCharacters.forEach((character) => {
    if (character.match(katakanaCharacterRange)) {
      katakanaCount++;
      kanaCount++;
    } else if (character.match(hiraganaCharacterRange)) {
      hiraganaCount++;
      kanaCount++;
    }
  });

  /*
  If the value is empty or otherwise falsy, don't divide by zero! In this case the
  numerator is zero anyway, so the denominator is irrelevant and the resulting
  percentage will be zero.
   */
  const denominator = value?.length || 1;

  // Multiply the numerator by 100 so the value will be a percent.
  return {
    kana: (100 * kanaCount) / denominator,
    katakana: (100 * katakanaCount) / denominator,
    hiragana: (100 * hiraganaCount) / denominator,
  };
};
