import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Displays an information card
 * Is used to display information about order status
 *
 * @param {boolean} displayCondition - conditions to display the heading
 * @param {ReactNode | ReactNode[]} headings - card heading[s]
 * @param {boolean} warning - add a warning icon before the title
 * @param {boolean} error - style the card to appear as error-y
 */
const InfoHeading = ({
  displayCondition = true,
  warning = false,
  error = false,
  headings = [],
  className,
  ...cardProps
}) => {
  const classes = useStyles();

  if (!Array.isArray(headings)) {
    headings = [headings];
  }

  if (!displayCondition) return null;

  return (
    <Card className={clsx(classes.card, { [className]: className })} elevation={3} {...cardProps}>
      <CardContent className={classes.cardContent}>
        <CardHeader
          disableTypography
          className={classes.cardHeader}
          title={
            <h2 className={clsx(classes.heading, { [classes.headingError]: error })}>
              {headings.filter(Boolean).map((title, i) => (
                <span key={title} className={classes.headingSpan}>
                  {/* prepend the first header with warning icom
                   * if `warning` prop is provided
                   */}
                  {warning && i === 0 && (
                    <WarningIcon name='pending-warning' className={classes.warningIcon} />
                  )}
                  {title}
                </span>
              ))}
            </h2>
          }
        />
      </CardContent>
    </Card>
  );
};

InfoHeading.propTypes = {
  displayCondition: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  headings: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default React.memo(InfoHeading);

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  cardContent: {
    'paddingTop': 0,
    'paddingBottom': 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    padding: '16px 8px',
  },
  heading: {
    margin: '0.25em 0',
    display: 'flex',
    fontSize: '1.3rem',
    justifyContent: 'space-between',
    lineHeight: 1.334,
    fontWeight: 400,
    letterSpacing: '0em',
  },
  headingError: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    paddingTop: '1px',
    paddingRight: '5px',
    marginBottom: '-4px',
  },
}));
