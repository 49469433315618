import Geo from '../constants/geos.const';

/**
 * Stitches a string name from the given nameObject.
 * The stitched name is either from fields given and family, or if those fields aren't
 * present, then from fields firstName and lastName, or returns an empty string if none of these
 * fields are available.
 *
 * @param {Object} nameObject the object from which to stitch a string name
 * @param {Object} regionReference the string which is order region reference
 * @returns {string} the stitched string name
 */
export const stitchName = (nameObject, regionReference) => {
  if (nameObject?.given) {
    return nameObject.given + ' ' + nameObject.family;
  } else if (nameObject?.firstName) {
    if (regionReference === Geo.KOREA || regionReference === Geo.JAPAN) {
      return nameObject.lastName + ' ' + nameObject.firstName;
    }
    return nameObject.firstName + ' ' + nameObject.lastName;
  } else {
    return '';
  }
};

/**
 * Trim string in case it has length grater than defined. Otherwise, it returns the same string
 *
 * @param value string to possible trim
 * @param length length to trim
 * @param ellipsis the end symbols if string trimmed
 * @returns {string}
 */
export const trimTo = (value, length, ellipsis = '...') => {
  return value?.length <= length ? value : value?.substring(0, length) + ellipsis;
};
