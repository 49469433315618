export const translations = {
  ERROR_BUTTON_TEXT: {
    description: 'button label for error',
    primaryValue: 'Failed',
    stringKey: 'errorButton',
  },
  COPIED_BUTTON_TEXT: {
    description: 'button label for copied',
    primaryValue: 'Copied',
    stringKey: 'copied',
  },
  COPY_BUTTON_TEXT: {
    description: 'button label for copy',
    primaryValue: 'Copy',
    stringKey: 'copy',
  },
  CREATED_BY: {
    description: 'label for created by in order summary panel',
    primaryValue: 'Created By',
    stringKey: 'createdBy',
  },
  ENTERPRISE: {
    description: 'label for enterprise in order summary panel',
    primaryValue: 'Enterprise',
    stringKey: 'enterprise',
  },
  ORDER_CLASSIFICATION: {
    description: 'label for order number in order summary panel',
    primaryValue: 'Order Classification',
    stringKey: 'orderClassification',
  },
  ORDER_DATE: {
    description: 'label for order number in order summary panel',
    primaryValue: 'Order Date',
    stringKey: 'orderDate',
  },
  ORDER_NUMBER: {
    description: 'label for order number in order summary panel',
    primaryValue: 'Order Number',
    stringKey: 'orderNumber',
  },
  ORDER_SUMMARY: {
    description: 'title of order summary panel',
    primaryValue: 'Order Summary',
    stringKey: 'orderSummary',
  },
  ORDER_TYPE: {
    description: 'label for order number in order summary panel',
    primaryValue: 'Order Type',
    stringKey: 'orderType',
  },
  ORIGIN: {
    description: 'label for origin number in order summary panel',
    primaryValue: 'Origin',
    stringKey: 'origin',
  },
  PARTNER_ORDER_NUMBER: {
    description: 'label for partner order number in order summary panel',
    primaryValue: 'Partner Order Number',
    stringKey: 'partnerOrderNumber',
  },
  PARTNER_REFERENCE_NUMBER: {
    description: 'label for partner order reference in order summary panel',
    primaryValue: 'Partner Reference Number',
    stringKey: 'partnerOrderReference',
  },
  STATUS: {
    description: 'label for status for order summary, shipping group, line item',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  CONSUMER: {
    description: 'value for return orders created by consumer',
    primaryValue: 'Consumer',
    stringKey: 'consumer',
  },
  PAYMENT_STATUS: {
    description: 'label for Payment status in order summary panel',
    primaryValue: 'Payment Status',
    stringKey: 'paymentStatus',
  },
};

export default translations;
