import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import HasPermission from '../shared/hasPermission';
import { Developer } from '../../constants/permissions.const';

/**
 * This component is for rows of the order table
 *
 * @param {object} props – React props object
 */
const OrderRow = ({ order }) => {
  return (
    <>
      <TableRow key={order.orderNumber}>
        <TableCell component='th' scope='row'>
          <Link to={`/order/${order.orderNumber}/details`}>{order.orderNumber}</Link>
        </TableCell>
        <TableCell align='right'>{order.omsRegionReference}</TableCell>
        <TableCell align='right'>{order?.orderLines?.length}</TableCell>
        {/* only show this data if you're a dev, as it's a little confusing otherwise */}
        <HasPermission permission={Developer}>
          <TableCell align='right'>{order?.status}</TableCell>
        </HasPermission>
        <TableCell align='right'>
          {order?.dateVisited && new Date(order.dateVisited).toLocaleString()}
        </TableCell>
        <TableCell align='right'> </TableCell>
      </TableRow>
    </>
  );
};

export default OrderRow;
