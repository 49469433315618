import gql from 'graphql-tag';

const REASON_CODES_QUERY = gql`
  query getReasonCodesV2(
    $omsRegionReference: String!
    $appId: String!
    $type: ReasonCodeTypes
    $language: String
  ) {
    reasonCodesV2(
      appId: $appId
      omsRegionReference: $omsRegionReference
      type: $type
      language: $language
    ) {
      reasons {
        id
        code
        description
      }
    }
  }
`;

export default REASON_CODES_QUERY;
