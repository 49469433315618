/* React/Utils */
import React from 'react';
import PropTypes from 'prop-types';

/* Material-UI */
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import translations from '../payments/payments.i18n';

/**
 * Component to that will display a link when giftcard number is present, a spinner when isLoading
 * is set to true and just a react fragment when giftcard number is not present and isLoading is set
 * to false. For the giftcard link, the text  displayed is the giftcard order number and when
 * clicked, will navigate the user to that particular order.
 *
 * @param {boolean} isLoading - Boolean representing if the retrieving of the gift card number is
 * still in process.
 * @param {string} gcOrderNumber - Gift card number that shall be displayed as a link.
 * @returns React component.
 */
function GcOrderLink({ isLoading = false, gcOrderNumber }) {
  const classes = useStyles();
  const setRoute = useHistoryPushWithSessionId();
  const { ARIA_ORDER_SUMMARY_LOADING } = useMemoTranslations(translations);

  const handleOrderClick = (orderNumber, page) => {
    setRoute(`/order/${orderNumber}/${page}`);
  };
  return (
    <>
      {isLoading && <CircularProgress aria-label={ARIA_ORDER_SUMMARY_LOADING} size={15} />}
      {gcOrderNumber && (
        <Link
          data-testid={'RFO-link'}
          tabIndex={0}
          onClick={() => handleOrderClick(gcOrderNumber, 'details')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleOrderClick(gcOrderNumber);
            }
          }}
          className={classes.link}
          component={Button}>
          {gcOrderNumber}
        </Link>
      )}
    </>
  );
}

GcOrderLink.propTypes = {
  isLoading: PropTypes.bool,
  gcOrderNumber: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    padding: '0',
  },
}));

export default GcOrderLink;
