import React from 'react';

import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { makeStyles, Typography } from '@material-ui/core';

const ThreeColumnContainer = ({
  rows = 1,
  headers,
  firstColumn,
  secondColumn,
  thirdColumn,
  shouldDisplayDivider = false,
}) => {
  const classes = useStyles();

  const generateSecondColumn = (data) => {
    if (!data) return;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '1%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          {shouldDisplayDivider && <Divider orientation='vertical' />}
        </div>
        <div style={{ width: '98%' }}>{data}</div>
        <div style={{ width: '1%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          {shouldDisplayDivider && <Divider orientation='vertical' />}
        </div>
      </Box>
    );
  };

  return (
    <div className={classes.container}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
          {/* Headers go here */}
          {headers && (
            <>
              <Box sx={{ width: '30%' }}>
                <Typography className={classes.columnHeaders}>{headers[0]}</Typography>
              </Box>
              <Box sx={{ width: '1%' }}></Box>
              <Box style={{ width: '30%' }}>
                <Typography className={classes.columnHeaders}>{headers[1]}</Typography>
              </Box>
              <Box sx={{ width: '3%' }}></Box>
              <Box style={{ width: '36%' }}>
                <Typography className={classes.columnHeaders}>{headers[2]}</Typography>
              </Box>
            </>
          )}
        </Box>

        {/* Body goes here */}
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.firstColumnOfThree} style={{ display: 'flex' }}>
            {firstColumn}
          </div>
          <div
            className={classes.secondColumnOfThree}
            style={{ display: 'flex', flexDirection: 'column' }}>
            {generateSecondColumn(secondColumn)}
          </div>
          <div
            className={classes.thirdColumnOfThree}
            style={{ display: 'flex', flexDirection: 'column' }}>
            {thirdColumn}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ThreeColumnContainer;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '0rem 1rem 0rem 1rem',
  },
  firstColumnOfThree: {
    width: '30%',
    padding: '1rem',
  },
  secondColumnOfThree: {
    width: '34%',
    padding: '1rem',
  },
  thirdColumnOfThree: {
    width: '36%',
    padding: '1rem',
  },
  columnHeaders: {
    fontWeight: 'bold',
    paddingLeft: '1rem',
  },
}));
