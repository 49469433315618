/* React/Utils */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
/* Material-UI */
import { TableCell, TableRow } from '@material-ui/core';
/* Local */
import Mock from './../../constants/mock.const.js';
import NewTabButton from '../shared/newTabButton';
import { protocolAndHost } from '../../utils/link';
import { FormattedCurrency } from './../shared/formatCurrency';
import { formatDateTime } from '../../utils/date.js';
import { PermissionContext } from '../../store/contexts/permissionContext';
import Geo from '../../constants/geos.const.js';
import { Granted } from '../../constants/permissions.const.js';

/**
 * This component is for rows of the search order table
 *
 * @param {object} props – React props object
 * @param {object} props.order - Order data for this row's search result
 */
const OrderSearchResultsRow = ({ order }) => {
  const { channel, orderLines, store, orderType, omsRegionReference } = order;
  const [, hasModificationPermissions] = useContext(PermissionContext);
  const isStoreOrder = channel?.includes('store');
  const storeTransactionDate = store?.[0]?.transactionBeginDate;

  const generateSanitizedName = (name) => {
    if (name?.length > 1) {
      return name.charAt(0) + '*'.repeat(name.length - 1);
    }
    return name;
  };

  const generateStyleNumberColorCode = (orderLine) => {
    if (
      orderLine?.styleNumber !== 'N/A' &&
      Boolean(orderLine?.colorCode) &&
      orderLine?.colorCode !== 'N/A'
    ) {
      return `${orderLine.styleNumber}-${orderLine.colorCode}`;
    }
    return orderLine.styleNumber;
  };

  const styleNumbers = orderLines?.map(generateStyleNumberColorCode) || [];
  // Consumer Display Name
  const lastName = order?.billTo?.recipient?.lastName
    ? `${order?.billTo?.recipient?.lastName}, `
    : '';
  const firstName = order?.billTo?.recipient?.firstName || '';
  const sanitizedName =
    omsRegionReference === Geo.KOREA && hasModificationPermissions !== Granted
      ? generateSanitizedName(firstName)
      : firstName;
  const consumerName = lastName + sanitizedName;
  // Bill To Address
  const city = order?.billTo?.address?.city ? `${order?.billTo?.address?.city}, ` : '';
  const state = order?.billTo?.address?.state ? `${order?.billTo?.address?.state}, ` : '';
  const country = order?.billTo?.address?.country || '';
  const billToAddress = city + state + country;

  /**
   * returns route for clicked on order
   * @param {*} orderNumber - Order number that is clicked
   * @return {String} - returns route for clicked on order
   */
  const orderUrl = (orderNumber) => {
    // This is to handle order click in Mock search page.
    if (process.env.REACT_APP_ENV === 'training' || process.env.REACT_APP_ENV === 'train_local') {
      return `/order/${Mock.DefaultOrder}/details`;
    } else {
      return `${process.env.PUBLIC_URL}/order/${orderNumber}/details`;
    }
  };

  return (
    <>
      <TableRow key={order.orderNumber}>
        <TableCell component='th' scope='row'>
          <NewTabButton
            href={`${protocolAndHost()}${orderUrl(order.orderNumber)}`}
            label={order.orderNumber}
          />
        </TableCell>
        <TableCell align='left'>
          {(isStoreOrder &&
            orderType === 'SALES_ORDER' &&
            formatDateTime(storeTransactionDate, order.locale)) ||
            formatDateTime(order.orderSubmitDate, order.locale)}
        </TableCell>
        <TableCell align='left'>{consumerName}</TableCell>
        <TableCell align='left'>{billToAddress}</TableCell>
        <TableCell align='left'>
          <FormattedCurrency amount={order.totalAmount} currency={order.currency} />
        </TableCell>
        <TableCell align='left'>{order.status}</TableCell>
        <TableCell align='left'>{styleNumbers?.join(', ')}</TableCell>
      </TableRow>
    </>
  );
};

OrderSearchResultsRow.propTypes = {
  order: PropTypes.shape({
    billTo: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
      }),
      recipient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    orderLines: PropTypes.arrayOf(
      PropTypes.shape({
        styleNumber: PropTypes.string,
        colorCode: PropTypes.string,
      })
    ),
    omsRegionReference: PropTypes.string,
    orderNumber: PropTypes.string,
    orderSubmitDate: PropTypes.string,
    status: PropTypes.string,
    totalAmount: PropTypes.number,
    currency: PropTypes.string,
    channel: PropTypes.string,
    locale: PropTypes.string,
    store: PropTypes.shape({
      transactionBeginDate: PropTypes.string,
    }),
    orderType: PropTypes.string,
  }).isRequired,
};

export default OrderSearchResultsRow;
