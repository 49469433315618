const newRelic = window.newrelic || {};

/**
 * A function for capturing errors in catch blocks and sending to New Relic
 *
 * @param {Error} error - The error being caught and sent to New Relic
 * @param  {Object} data - extra data that you want to send to New Relic
 */
export function logErrorAction(error, data) {
  // Log errors/messages in production
  if (!error.stack && Error.captureStackTrace) {
    Error.captureStackTrace(error, Error);
  }
  if (window.newrelic) {
    newRelic.noticeError(error, {
      ...data,
    });
  }
}

export function logInfoAction(message, data) {
  if (window.newrelic) {
    newRelic.addPageAction(message, {
      ...data,
    });
  }
}
