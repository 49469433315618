module.exports = {
  cn: [
    {
      abbreviation: 'CN-11',
      name: '北京市',
    },
    {
      abbreviation: 'CN-12',
      name: '天津市',
    },
    {
      abbreviation: 'CN-13',
      name: '河北省',
    },
    {
      abbreviation: 'CN-14',
      name: '山西省',
    },
    {
      abbreviation: 'CN-15',
      name: '内蒙古自治区',
    },
    {
      abbreviation: 'CN-21',
      name: '辽宁省',
    },
    {
      abbreviation: 'CN-22',
      name: '吉林省',
    },
    {
      abbreviation: 'CN-23',
      name: '黑龙江省',
    },
    {
      abbreviation: 'CN-31',
      name: '上海市',
    },
    {
      abbreviation: 'CN-32',
      name: '江苏省',
    },
    {
      abbreviation: 'CN-33',
      name: '浙江省',
    },
    {
      abbreviation: 'CN-34',
      name: '安徽省',
    },
    {
      abbreviation: 'CN-35',
      name: '福建省',
    },
    {
      abbreviation: 'CN-36',
      name: '江西省',
    },
    {
      abbreviation: 'CN-37',
      name: '山东省',
    },
    {
      abbreviation: 'CN-41',
      name: '河南省',
    },
    {
      abbreviation: 'CN-42',
      name: '湖北省',
    },
    {
      abbreviation: 'CN-43',
      name: '湖南省',
    },
    {
      abbreviation: 'CN-44',
      name: '广东省',
    },
    {
      abbreviation: 'CN-45',
      name: '广西壮族自治区',
    },
    {
      abbreviation: 'CN-46',
      name: '海南省',
    },
    {
      abbreviation: 'CN-50',
      name: '重庆市',
    },
    {
      abbreviation: 'CN-51',
      name: '四川省',
    },
    {
      abbreviation: 'CN-52',
      name: '贵州省',
    },
    {
      abbreviation: 'CN-53',
      name: '云南省',
    },
    {
      abbreviation: 'CN-54',
      name: '西藏自治区',
    },
    {
      abbreviation: 'CN-61',
      name: '陕西省"',
    },
    {
      abbreviation: 'CN-62',
      name: '甘肃省',
    },
    {
      abbreviation: 'CN-63',
      name: '青海省',
    },
    {
      abbreviation: 'CN-64',
      name: '宁夏回族自治区',
    },
    {
      abbreviation: 'CN-65',
      name: '新疆维吾尔自治区',
    },
  ],
};
