/* React/Utils */
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';

/* Local */
import './styles/App.css';
import Order from './components/orders';
import Admin from './components/admin';
import OrderSearch from './components/orderSearch';
import Settings from './components/settings';
import Emails from './components/orders/content/emails';
import ErrorBoundary from './components/error/errorBoundary';
import EmailBody from './components/orders/content/emailBody';
import RecentOrders from './components/recentOrders';
import MemberOrders from './components/member/memberOrders';
import PidReview from './components/pidReview';
import { SnackProvider } from './store/contexts/snackContext';
import Providers from './providers';
import config from './utils/config';
import useAuth from './hooks/useAuth';

const oktaAuth = new OktaAuth({
  ...config.okta,
  redirectUri: `${
    window.location.origin
  }${process.env.PUBLIC_URL?.toLowerCase()}/implicit/callback/`,
  tokenManager: {
    storage: 'localStorage',
    autoRenew: true,
    /*
      This expireEarlySeconds param determines that an athlete will need to  
      have taken an action that makes a call in the last 5 minutes in order 
      for the refresh token to successfully refresh the accessToken. We 
      believe this gives an appropriate amount of flexibility to SwooshDesk users.
    */
    expireEarlySeconds: 300,
  },
});
/**
 * The main application component for CS Order Management
 *
 * @param {Object} props – React.props
 */
const App = (props) => {
  const { validateOktaToken, oktaToken: AuthErrorComponent } = useAuth();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || '/', window.location.origin);
  };

  /*
    In the event that we're reading oktaToken here & it's not a string, 
    it will be an error component we render for users to indicate what changes need to be made.
  */
  if (AuthErrorComponent && typeof AuthErrorComponent !== 'string') {
    return AuthErrorComponent;
  }

  /*
  Checks for the okta token present in the local storage and removes it from local storage
  if it is expired. This wil help secure route to update the local storage with a new token
  */
  validateOktaToken();
  return (
    // process.env.PUBLIC_URL contains the value from env file and is used only in china
    <BrowserRouter basename={process.env.PUBLIC_URL?.toLowerCase()}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} pkce={true}>
        <SnackProvider>
          <Providers>
            <ErrorBoundary>
              {/* this route is for Okta purposes */}
              <Route path='/implicit/callback/' component={LoginCallback} />
              {/* our routes */}
              <SecureRoute path='/' exact={true} component={OrderSearch} />
              {/* TODO: remove this route when lightning team updates the end point */}
              <SecureRoute path='/order/search' exact={true} component={OrderSearch} />
              <SecureRoute path='/order/:orderId/:route' exact={true} component={Order} />
              <SecureRoute path='/admin' exact={true} component={Admin} />
              <SecureRoute path='/recent/orders' exact={true} component={RecentOrders} />
              <SecureRoute path='/settings' exact={true} component={Settings} />
              <SecureRoute
                path='/order/:orderId/emails/:requestId/:standalone'
                exact={true}
                component={EmailBody}
              />
              {/* PiD Review Access is required to see go to PiD Review */}
              <SecureRoute path='/pidReview' exact={true} component={PidReview} />
              <SecureRoute path='/embedded/emails/:orderId' exact={true} component={Emails} />
              <SecureRoute path='/member/:upmId/orders' exact={true} component={MemberOrders} />
            </ErrorBoundary>
          </Providers>
        </SnackProvider>
      </Security>
    </BrowserRouter>
  );
};

export default App;
