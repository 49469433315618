import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import clsx from 'clsx';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Local */
import { FapiaoEnum } from '../../../../constants/efapiao.const.js';
import { ApiTimeOut } from '../../../../constants/dialog.const';
import translations from './efapiao.i18n';
import ISSUE_EFAPIAO_MUTATION from '../../../../mutations/issueEfapiao.mutation';
import useSnacks from '../../../../hooks/useSnacks';
import OrderContext from '../../../../store/contexts/orderContext';

const { PERSONAL, BUSINESS } = FapiaoEnum;

/**
 * Component to control issue efapiao logic
 *
 * @param {array} orderInvoices - array of all efapiao data objects
 * @param {string} orderNumber - order ID
 * @param {function} queryOrderInvoice - hook to fetch efapiao data
 */
const IssueEfapiao = ({ orderInvoices, orderNumber, queryOrderInvoice }) => {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const { setError, setSnack, setLoading } = useSnacks();
  const { i18nString } = useContext(NikeI18nContext);
  const {
    FAPIAO_TYPE,
    FAPIAO_TYPE_PERSONAL,
    FAPIAO_TYPE_BUSINESS,
    FAPIAO_TITLE,
    TAX_ID,
    ISSUE_EFAPIAO,
    ISSUE_EFAPIAO_ERROR,
    ISSUE_EFAPIAO_SUCCESS,
  } = mapValues(translations, i18nString);

  // grabbing title from most recent eFapiao, may require better solution
  const { vatReceiptTitle } = orderInvoices[0];
  const { instructions } = orderDetail;
  const vatTaxId =
    Array.isArray(instructions) &&
    instructions.find((item) => item.instructionType === 'VAT_TAXID')?.detail;

  const [fapiaoType, setFapiaoType] = useState(PERSONAL);
  const [fapiaoTitle, setFapiaoTitle] = useState(vatReceiptTitle || 'Personal');
  const [taxID, setTaxID] = useState(vatTaxId || '');

  const handleChange = (event) => {
    if (event.target.value === BUSINESS) {
      setFapiaoType(BUSINESS);
      setFapiaoTitle('Business');
    } else {
      setFapiaoType(PERSONAL);
      setFapiaoTitle('Personal');
    }
  };

  const [issueEfapiao] = useMutation(ISSUE_EFAPIAO_MUTATION, {
    onError: () => {
      setError(ISSUE_EFAPIAO_ERROR);
    },
    onCompleted: (data) => {
      if (data?.issueEfapiao?.errors || data?.issueEfapiao?.error) {
        setError(ISSUE_EFAPIAO_ERROR);
      } else {
        setSnack(ISSUE_EFAPIAO_SUCCESS);

        queryOrderInvoice({
          variables: {
            orderNumber,
          },
        });
      }
    },
  });

  const handleSubmit = () => {
    setLoading();
    const efapiao = {
      request: {
        requestId: `${uuidv4()}`,
        efapiaoTitle: fapiaoTitle,
        taxId: taxID,
      },
    };

    issueEfapiao({ variables: { orderNumber, efapiao, timeout: ApiTimeOut } });
  };

  // checks if any invoice in array of invoices contains an issued eFapiao (vatStatus === 600)
  const haseFapiaoIssued = orderInvoices.find((invoice) => invoice.vatStatus === '600')
    ? true
    : false;

  /** Some fields are hidden if an efapiao is in the issued state (vatStatus 600) */
  return (
    <>
      {!haseFapiaoIssued && (
        <label className={classes.fieldLabel} data-testid='efapiao-type-radio'>
          <span className={classes.fieldTitle}>{FAPIAO_TYPE}</span>
          <RadioGroup className={classes.invoiceField} value={fapiaoType} onChange={handleChange}>
            <FormControlLabel
              value={PERSONAL}
              control={<Radio color='primary' />}
              label={FAPIAO_TYPE_PERSONAL}
              className={classes.controlLabel}
            />
            <FormControlLabel
              value={BUSINESS}
              control={<Radio color='primary' />}
              label={FAPIAO_TYPE_BUSINESS}
              className={classes.controlLabel}
            />
          </RadioGroup>
        </label>
      )}

      <div className={classes.wrapper}>
        <div className={classes.editableFields}>
          {fapiaoType !== BUSINESS && (
            <label className={classes.fieldLabel}>
              {FAPIAO_TITLE}
              <TextField
                aria-label={FAPIAO_TITLE}
                data-testid='efapiao-title-field'
                disabled={haseFapiaoIssued}
                value={fapiaoTitle}
                onChange={(event) => setFapiaoTitle(event.target.value)}
                className={classes.invoiceField}
              />
            </label>
          )}
          <label className={classes.fieldLabel}>
            {TAX_ID}
            <TextField
              aria-label={TAX_ID}
              data-testid='efapiao-taxID-field'
              disabled={haseFapiaoIssued}
              value={taxID}
              onChange={(event) => setTaxID(event.target.value)}
              className={classes.invoiceField}
              inputProps={{
                maxLength: 20,
              }}
            />
          </label>
        </div>

        {!haseFapiaoIssued && (
          <Button
            aria-label={ISSUE_EFAPIAO}
            data-testid='issue-efapiao-button'
            onClick={handleSubmit}
            className={clsx(classes.button, { [classes.btnTop]: fapiaoType === BUSINESS })}
            disabled={!fapiaoTitle || (fapiaoType === BUSINESS && !taxID)}>
            {ISSUE_EFAPIAO}
          </Button>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    fontSize: '1rem',
    width: '28rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    paddingLeft: theme.spacing(1.4),
  },
  fieldTitle: {
    alignSelf: 'flex-start',
    paddingTop: '9px',
  },
  invoiceField: {
    marginLeft: '40px',
    width: '14rem',
    paddingTop: 0,
  },
  wrapper: {
    display: 'flex',
  },
  editableFields: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginLeft: '40px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '-0.01em',
  },
  btnTop: {
    alignSelf: 'flex-start',
  },
  controlLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default IssueEfapiao;
