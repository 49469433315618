import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  spacing: 8,
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    body1: {
      fontSize: '1rem',
    },
  },
  fonts: {
    roboto: 'Roboto, Helvetica, Arial, sans-serif',
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    type: 'light',
    primary: {
      light: '#7986cb',
      main: '#252E6E',
      dark: '#303f9f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      main: '#EE0005',
      dark: '#8b0000',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#007D48',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#007D48',
      dark: '#309828',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e5e5e5',
      350: '#ccc',
      400: '#707072',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    text: {
      primary: '#111',
    },
    contrastThreshold: 3,
    inventory: {
      low: '#EE0005',
      medium: '#E15010',
      high: '#128A09',
    },
    validation: {
      success: '#128A09',
      error: '#EE0005',
    },
  },
});

export default theme;
