/** React/Utils */
import React from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

/** Local */
import { prepareAddress } from '../../../../../utils/address';
import translations from '../paymentDetails.i18n';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import BasicTableRow from '../../../../shared/table/tableRow';

export default function PaymentDetailsOFBT({ payment }) {
  const {
    firstName, // bank name
    middleName, // bank city
    accountNumber, // IBAN
    creditCardHolderName, // Swift/BIC code
    checkReference, // name on account
  } = payment;

  const classes = useStyles();

  // get billing address from OFBT payment if available
  const billingAddress = payment?.billTo?.address?.address1
    ? prepareAddress(payment)?.slice(1)
    : null;
  const formattedBillingAddress = billingAddress?.join('\n');

  const {
    ARIA_OFBT_PAYMENT_DETAILS,
    BANK_NAME,
    BANK_CITY,
    NAME_ON_ACCOUNT,
    SWIFT_BIC_CODE,
    IBAN,
    BILLING_ADDRESS,
  } = useMemoTranslations(translations);

  const bankLabels = [BANK_NAME, BANK_CITY, IBAN, SWIFT_BIC_CODE, NAME_ON_ACCOUNT, BILLING_ADDRESS];

  // bank/consumer information values
  const bankValues = [
    firstName,
    middleName,
    accountNumber,
    creditCardHolderName,
    checkReference,
    formattedBillingAddress,
  ];

  return (
    <Table
      aria-label={ARIA_OFBT_PAYMENT_DETAILS}
      data-testid={'OFBT-payment-table'}
      className={classes.table}>
      <TableBody>
        {bankLabels.map((label, i) => (
          <BasicTableRow
            data={[label, bankValues[i]]}
            key={i}
            cellRootClassName={classes.tableCell}
          />
        ))}
      </TableBody>
    </Table>
  );
}

const useStyles = makeStyles(() => ({
  table: {
    width: '50%',
    minWidth: '400px',
    gridArea: 'Details',
  },
  tableCell: {
    padding: '0em 1em 0.5em 1em',
    border: '0',
    whiteSpace: 'pre-line',
    verticalAlign: 'top',
  },
}));
