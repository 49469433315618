/**
 * Does invoice NOT exists in orderInvoicesState array. Search by invoice's number.
 *
 * @param orderInvoicesState array with invoices objects
 * @param invoiceNum number of invoice
 * @returns {boolean} true if doesn't exist in array
 */
export const invoiceDoesNotExist = (orderInvoicesState, invoiceNum) => {
  return orderInvoicesState.findIndex((item) => item.invoiceNo === invoiceNum) === -1;
};

/**
 * Add order invoice to array with order invoices if it's not there.
 *
 * @param orderInvoicesState pre-fill array with order invoices
 * @param invoiceData order invoice to add
 * @returns {*[]} array with order invoices
 */
export const getFilledOrderInvoices = (orderInvoicesState, invoiceData) => {
  const loadedOrderInvoices = orderInvoicesState || [];
  const invoicesToAdd = invoiceData.filter((invoice) =>
    invoiceDoesNotExist(orderInvoicesState, invoice.invoiceNo)
  );
  return loadedOrderInvoices.concat(invoicesToAdd);
};
