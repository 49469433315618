/** React/Utils */
import React from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CopyIcon from '@material-ui/icons/FilterNone';
import CancelIcon from '@material-ui/icons/Cancel';
import useMemoTranslations from '../../../hooks/useMemoTranslations';

/** Local */
import translations from './summary.i18n';

const CopyButton = ({ copyText }) => {
  const { COPY_BUTTON_TEXT, COPIED_BUTTON_TEXT, ERROR_BUTTON_TEXT } = useMemoTranslations(
    translations
  );
  const classes = useStyles();
  const [iconState, setIcon] = React.useState('copy');
  const handleButtonClick = () => {
    navigator.clipboard.writeText(copyText).then(
      () => {
        setIcon('copied');
        setTimeout(() => {
          setIcon('copy');
        }, 1500);
      },
      (err) => {
        setIcon('error');
      }
    );
  };
  const buttonItems = [
    {
      name: 'copy',
      icon: <CopyIcon className={classes.copyIcon} data-testid='copy-icon' />,
      text: COPY_BUTTON_TEXT,
    },
    {
      name: 'copied',
      icon: <CheckCircleIcon className={classes.checkIcon} data-testid='check-icon' />,
      text: COPIED_BUTTON_TEXT,
    },
    {
      name: 'error',
      icon: <CancelIcon className={classes.cancelIcon} data-testid='cancel-icon' />,
      text: ERROR_BUTTON_TEXT,
    },
  ];

  let currentButton = buttonItems.findIndex((i) => i.name === iconState);

  return (
    <Button
      size='small'
      data-testid='copy-button'
      className={classes.copyButton}
      onClick={handleButtonClick}
      variant={iconState === 'copy' ? 'text' : 'contained'}
      startIcon={buttonItems[currentButton].icon}>
      {buttonItems[currentButton].text}
    </Button>
  );
};

export default CopyButton;

const useStyles = makeStyles((theme) => ({
  copyButton: {
    padding: '0',
    marginLeft: theme.spacing(1),
  },
  copyIcon: {
    transform: 'scaleY(-1)',
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
}));
