import React from 'react';
import { formatDateTime } from './date';
import { FormattedCurrency } from '../components/shared/formatCurrency';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';

import { PaymentServiceMethodsDict } from '../constants/paymentTypes.const';

/**
 * Takes a chargeTransactionDetailForInvoice and returns an array of values in the
 * order matching the table columns in Payments > All Transactions Table
 * @param {object} transaction - chargeTransactionDetail
 * @param {string} currency - sets the country currency code
 * @param {string} locale - sets the locale
 * @returns {array} [
 * Date,
 * Auth Date,
 * Type,
 * Amount,
 * Auth #,
 * Status,
 * Invoices
 * ]
 */
export const transformTransactionDetailsForInvoice = (
  transaction,
  locale = 'en_US',
  currency,
  action,
  index,
  open,
  classes,
  transactionStatus,
  GcOrderLink = React.Fragment
) => {
  const {
    invoiceCollectionDetails,
    transactionDate,
    chargeReference,
    debitAmount,
    requestAmount,
    // authorizationReference,
    bookAmount,
  } = transaction;
  const invoices =
    (Array.isArray(invoiceCollectionDetails) && invoiceCollectionDetails.length) || 0;

  const carrot = invoices ? (
    open.index !== index ? (
      <IconButton
        style={{ margin: `0px`, padding: `0px` }}
        onClick={() => action(index, invoiceCollectionDetails[0]?.invoiceNumber)}>
        <KeyboardArrowRight
          className={classes.carrot}
          data-testid={`see-invoices-carrot-${index}`}
        />
      </IconButton>
    ) : (
      <IconButton
        style={{ margin: `0px`, padding: `0px` }}
        onClick={() => action(index, invoiceCollectionDetails[0]?.invoiceNumber)}>
        <KeyboardArrowDown
          className={classes.carrot}
          data-testid={`see-invoices-carrot-${index}`}
        />
      </IconButton>
    )
  ) : (
    ''
  );
  let paymentAmount;

  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }
  // this sets the hierarchy of which value to display as the "Amount"
  if (bookAmount) {
    paymentAmount = bookAmount;
  } else if (requestAmount) {
    paymentAmount = requestAmount;
  } else {
    paymentAmount = debitAmount;
  }

  // TODO: this is temporary for displaying all invoice numbers, can remove later
  const invoiceNumbers = Array.isArray(invoiceCollectionDetails)
    ? invoiceCollectionDetails.map((invoice) => invoice.invoiceNumber).join(', ')
    : '';
  const transactionType = chargeReference === 'TRANSFER_OUT' ? <GcOrderLink /> : chargeReference;
  return [
    carrot,
    formatDateTime(transactionDate, locale),
    transaction?.creditCardTransactions?.length > 0
      ? formatDateTime(transaction?.creditCardTransactions[0].authorizationTime, locale)
      : null,
    transactionType,
    formatCurrency(paymentAmount),
    // authorizationReference,
    transaction?.creditCardTransactions?.length > 0
      ? transaction?.creditCardTransactions[0].authorizationCode
      : null,
    transactionStatus,
    // TODO: use invoices value below, not invoiceNumbers once table is not behind dev permission
    // invoices,
    invoiceNumbers,
  ];
};

/**
 * Takes a chargeTransactionDetail and returns an array of values in the
 * order matching the table columns in Payments > All Transactions Table
 * @param {object} transaction - chargeTransactionDetail
 * @param {string} currency - sets the country currency code
 * @param {string} locale - sets the locale
 * @returns {array} [
 * Date,
 * Auth Date,
 * Type,
 * Amount,
 * Auth #,
 * Status,
 * Invoices
 * ]
 */
export const transformTransactionDetails = (transaction, locale = 'en_US', currency) => {
  // note: invoices may need to be more complex than this
  // adds a comma to the end of every invoice number except for the last one
  let invoices =
    (Array.isArray(transaction.invoiceCollectionDetails) &&
      transaction.invoiceCollectionDetails.map((detail, i, array) =>
        i === array.length - 1 ? detail.invoiceNumber : `${detail.invoiceNumber}, `
      )) ||
    undefined;
  let paymentAmount;

  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }
  // this sets the hierarchy of which value to display as the "Amount"
  if (transaction.bookAmount) {
    paymentAmount = transaction.bookAmount;
  } else if (transaction.requestAmount) {
    paymentAmount = transaction.requestAmount;
  } else {
    paymentAmount = transaction.debitAmount;
  }

  return [
    formatDateTime(transaction.transactionDate, locale),
    transaction?.creditCardTransactions?.length > 0
      ? formatDateTime(transaction?.creditCardTransactions[0].authorizationTime, locale)
      : null,
    transaction.chargeReference,
    formatCurrency(paymentAmount),
    transaction?.creditCardTransactions?.length > 0
      ? transaction?.creditCardTransactions[0].authorizationCode
      : null,
    transaction.status,
    invoices,
  ];
};

/**
 * Takes an operation request and returns an array of values in the
 * order matching the table columns in Payment Type > Transactions Table
 * @param {object} operationRequest - chargeTransactionDetail
 * @param {string} locale - sets the locale
 * @returns {array} [
 * Date,
 * Auth Date,
 * Type,
 * Amount,
 * Auth #,
 * Status,
 * Invoices
 * ]
 */
export const transformOperationRequestDetails = (operationRequest, locale) => {
  // TODO: add invoices logic here to map from order details
  let invoices = [];
  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={operationRequest.currency} />;
  }

  return [
    formatDateTime(operationRequest.createTime, locale),
    operationRequest.updateTime ? formatDateTime(operationRequest.updateTime, locale) : null,
    operationRequest.action,
    formatCurrency(operationRequest.actualAmount),
    // TODO: something needs to go below, waiting to hear on this. will need to update test also
    'AUTH #',
    operationRequest.requestStatus,
    invoices,
  ];
};

/**
 * Takes an array of chargeTransactionDetails and returns a TotalTransferAmounts object
 * with in and out values aggregated from the chargeTransactionDetails.
 * @param {array} chargeTransactionDetails
 * @returns {object} totalTransferAmounts
 */
export const calculateTotalTransferInAndOut = (chargeTransactionDetails) => {
  let totalTransferAmounts = {};
  totalTransferAmounts.in = 0;
  totalTransferAmounts.out = 0;
  if (chargeTransactionDetails !== null) {
    for (let index = 0; index < chargeTransactionDetails?.length; index++) {
      let chargeTransactionDetail = chargeTransactionDetails[index];
      if (
        chargeTransactionDetail.chargeReference === 'TRANSFER_IN' &&
        chargeTransactionDetail.creditAmount
      ) {
        totalTransferAmounts.in += chargeTransactionDetail.creditAmount;
      } else if (
        chargeTransactionDetail.chargeReference === 'TRANSFER_OUT' &&
        chargeTransactionDetail.creditAmount
      ) {
        totalTransferAmounts.out += chargeTransactionDetail.creditAmount;
      }
    }
  }
  return totalTransferAmounts;
};

/**
 * Takes array of chargeTransactionDetails and returns array consisting of objects containing
 * transfer date and orderHeaderKey whose charge reference is transfer_out for the new gift
 * card order
 *
 * @param {array} chargeTransactionDetails
 * @returns {array} Objects containing RFODetails containing date and orderHeaderKey
 */
export const getRfoDetails = (chargeTransactionDetails) => {
  return (
    chargeTransactionDetails
      ?.filter((chargeDetail) => chargeDetail?.chargeReference === 'TRANSFER_OUT')
      .map((result) => ({
        rfoDate: result?.transactionDate,
        rfoGcOrderHeaderKey: result?.transferToOrderHeaderKey,
      })) || []
  );
};

/**
 * This util derives an address from a payment method from an order object.
 *
 * @param {object} paymentMethod – an entry in the PaymentMethods array on an order object
 * @returns {string} – a multiline string containing a formatted address
 */
export const getPaymentAddress = (paymentMethod) => {
  const address = paymentMethod && paymentMethod.billTo && paymentMethod.billTo.address;
  if (!address) return;

  // TODO: get a schema of what all addresses can look like, and adjust logic accordingly

  const cityState =
    (address.city && address.state && `${address.city}, ${address.state}`) ||
    address.state ||
    address.city;

  // ? Question: do payment methods have alt first/last names?
  return [
    `${paymentMethod.firstName || ''} ${paymentMethod.lastName || ''}`.trim(),
    address.address1,
    address.address2,
    cityState,
    address.zip,
  ]
    .filter(Boolean)
    .join('\n')
    .trim();
};

/**
 * Takes an array of headerCharges from all orderLines within an order
 * and returns sum of valid chargeAmounts, or 0
 * @param {array} headerCharges
 * @returns {int} sum of chargeAmounts
 */
export const getHeaderCharges = (headerCharges) => {
  if (!headerCharges || headerCharges.length === 0) return 0;
  return headerCharges.reduce(
    (acc, charge) =>
      (acc +=
        !charge.isDiscount || charge.isDiscount === 'N' ? parseFloat(charge.chargeAmount) : 0),
    0
  );
};

/**
 * Takes an array of headerCharges from all orderLines within an order
 * and returns sum of valid discount chargeAmounts, or 0
 * @param {array} headerCharges
 * @returns {int} sum of discount chargeAmounts
 */
export const getHeaderDiscounts = (headerCharges) => {
  if (!headerCharges || headerCharges.length === 0) return 0;
  return headerCharges.reduce(
    (acc, charge) => (acc += charge.isDiscount === 'Y' ? parseFloat(charge.chargeAmount) : 0),
    0
  );
};

/**
 * Takes an array of headerTaxes from all orderLines within an order
 * and returns sum of valid taxAmounts, or 0
 * @param {array} headerTaxes
 * @returns {int} sum of taxAmounts
 */
export const getHeaderTaxes = (headerTaxes) => {
  if (!headerTaxes || headerTaxes.length === 0) return 0;
  return headerTaxes.reduce(
    (acc, tax) => (acc += tax.taxName !== 'VAT' ? parseFloat(tax.taxAmount) : 0),
    0
  );
};

/**
 * Takes an array of orderLines within an order
 * and returns sum of valid linePriceInformation extendedPrices, or 0
 * @param {array} orderLines
 * @returns {int} sum of linePriceInformation.extendedPrice
 */
export const getLineSubtotals = (orderLines) => {
  if (!orderLines || orderLines.length === 0) return 0;
  return orderLines.reduce(
    (acc, ol) => (acc += parseFloat(ol.linePriceInformation?.extendedPrice)),
    0
  );
};

/**
 * Takes a single orderline obj
 * and returns sum of valid not discount chargeAmounts, or 0
 * @param {obj} line
 * @returns {int} sum of not discount chargeAmounts
 */
export const getLineCharges = (line) => {
  if (!line || line.length === 0) return 0;
  return line.lineCharges.reduce(
    (acc, charge) =>
      (acc +=
        !charge.isDiscount || charge.isDiscount === 'N' ? parseFloat(charge.chargeAmount) : 0),
    0
  );
};

/**
 * Takes an array of orderLines within an order
 * and returns sum of not discount chargeAmounts
 * @param {array} orderLines
 * @returns {int} sum of not discount chargeAmounts
 */
export const getAllLineCharges = (orderLines) => {
  if (!orderLines || orderLines.length === 0) return 0;
  return orderLines.reduce((acc, ol) => (acc += getLineCharges(ol)), 0);
};

/**
 * Takes a single orderline obj
 * and returns sum of valid discount chargeAmounts, or 0
 * @param {obj} line
 * @returns {int} sum of discount chargeAmounts
 */
export const getLineDiscounts = (line) => {
  if (!line || line.length === 0) return 0;
  return line.lineCharges.reduce(
    (acc, charge) => (acc += charge.isDiscount === 'Y' ? parseFloat(charge.chargeAmount) : 0),
    0
  );
};

/**
 * Takes an array of orderLines within an order
 * and returns sum of valid discount chargeAmounts, or 0
 * @param {array} orderLines
 * @returns {int} sum of discountChargeAmounts
 */
export const getAllLineDiscounts = (orderLines) => {
  if (!orderLines || orderLines.length === 0) return 0;
  return orderLines.reduce((acc, ol) => (acc += getLineDiscounts(ol)), 0);
};

/**
 * Takes a single orderline obj
 * and returns sum of valid taxAmounts, or 0
 * @param {obj} line
 * @returns {int} sum of taxAmounts
 */
export const getLineTaxes = (line) => {
  if (!line || line.length === 0) return 0;
  return line.lineTaxes.reduce(
    (acc, tax) => (acc += tax.taxName !== 'VAT' ? parseFloat(tax.taxAmount) : 0),
    0
  );
};

/**
 * Takes an array of orderLines within an order
 * and returns sum of valid taxAmounts, or 0
 * @param {array} orderLines
 * @returns {int} sum of taxAmounts
 */
export const getAllLineTaxes = (orderLines) => {
  if (!orderLines || orderLines.length === 0) return 0;
  return orderLines.reduce((acc, ol) => (acc += getLineTaxes(ol)), 0);
};

/**
 * Takes payment object from Payment Details service
 * and returns last four digits of payment method number
 * @param {object} payment
 * @returns {string} last 4 digits of payment method number
 */
export const getLast4 = (payment) => {
  const { BANK_TRANSFER, CREDIT_CARD, GIFT_CARD } = PaymentServiceMethodsDict;
  const { bankTransferInfo, creditCardInfo, giftCardInfo, orderPayment } = payment;
  const { paymentMethod, account } = orderPayment;

  const defaultStr = '****';

  if (account === 'voucher' || paymentMethod === GIFT_CARD) {
    return giftCardInfo?.accountNumber?.slice(-4) || defaultStr;
  }

  switch (paymentMethod) {
    case CREDIT_CARD:
      return creditCardInfo?.accountNumber?.slice(-4) || defaultStr;
    case BANK_TRANSFER:
      return bankTransferInfo?.iban?.slice(-4) || defaultStr;
    default:
      return defaultStr;
  }
};

const PAYMENT_TYPE_CASH = Array.of('COD', 'CONV_STORE');

/**
 * Checks whether transaction includes cache
 * @param order order details
 * @param transactionPaymentKey which we need to mark as closed
 * @returns {boolean} {true} if the transaction is cache type, otherwise {false}
 */
export const isTransactionCash = (order, transactionPaymentKey) => {
  return (
    transactionPaymentKey &&
    PAYMENT_TYPE_CASH.includes(
      order?.paymentMethods?.find(
        (paymentMethod) => paymentMethod.paymentKey === transactionPaymentKey
      )?.paymentType
    )
  );
};
