// enum of possible geos
const Geo = {
  CHINA: 'NIKECN',
  EUROPE: 'NIKEEUROPE',
  JAPAN: 'NIKEJP',
  US: 'NIKEUS',
  KOREA: 'NIKEKR',
  NIKEGS: 'NIKEGS',
  NIKEXA: 'NIKEXA',
  NIKEHK: 'NIKEHK',
  JDSPORTS: 'JDSPORTS',
  AUSTRALIA: 'NIKEAU',
};

export const RegionToCountryMap = {
  ALL: {
    ALL: '',
  },
  NIKEUS: {
    'ALL': 'ALL',
    'UNITED STATES': 'US',
  },
  NIKEEUROPE: {
    'ALL': 'ALL',
    'UNITED KINGDOM': 'GB',
    'AUSTRIA': 'AT',
    'BELGIUM': 'BE',
    'CZECH REPUBLIC': 'CZ',
    'DENMARK': 'DK',
    'FINLAND': 'FI',
    'FRANCE': 'FR',
    'GERMANY': 'DE',
    'GREECE': 'GR',
    'HUNGARY': 'HU',
    'IRELAND': 'IE',
    'ITALY': 'IT',
    'LUXEMBOURG': 'LU',
    'NETHERLANDS': 'NL',
    'NORWAY': 'NO',
    'POLAND': 'PL',
    'PORTUGAL': 'PT',
    'SLOVENIA': 'SI',
    'SPAIN': 'SP',
    'SWEDEN': 'SE',
    'SWITZERLAND': 'CH',
  },
  NIKEJP: {
    ALL: 'ALL',
    JAPAN: 'JP',
  },
  NIKECN: {
    ALL: 'ALL',
    CHINA: 'CN',
  },
  NIKEHK: {
    'ALL': 'ALL',
    'HONG KONG': 'HK',
  },
  NIKEELITE: {
    ALL: 'ALL',
  },
  NIKEGS: {
    'CANADA': 'CA',
    'SWITZERLAND': 'CH',
    'NORWAY': 'NO',
    'TÜRKIYE': 'TR',
    'MEXICO': 'MX',
    'CHILE': 'CL',
    'ISRAEL': 'IL',
    'SLOVAKIA': 'SK',
    'RUSSIA': 'RU',
    'CROATIA': 'HR',
    'BULGARIA': 'BG',
    'PUERTO RICO': 'PR',
    'ROMANIA': 'RO',
    'EGYPT': 'EG',
    'MOROCCO': 'MA',
    'SOUTH AFRICA': 'ZA',
  },
  NIKEXA: {
    'TAIWAN': 'TW',
    'AUSTRALIA': 'AU',
    'NEW ZEALAND': 'NZ',
    'SINGAPORE': 'SG',
    'MALAYSIA': 'MY',
    'THAILAND': 'TH',
    'INDIA': 'IN',
    'VIETNAM': 'VN',
  },
};

export default Geo;
