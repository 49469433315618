import gql from 'graphql-tag';

const PRICE_MODIFICATION = gql`
  mutation addLineItemDiscount(
    $input: AddLineDiscountInputV1!
    $orderNumber: String!
    $timeout: Int!
  ) {
    addLineItemDiscount(input: $input, orderNumber: $orderNumber, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
    }
  }
`;

export default PRICE_MODIFICATION;
