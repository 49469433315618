import gql from 'graphql-tag';

const PID_VALIDATION = gql`
  query pidValidation($text: [String!]!) {
    pidValidation(text: $text) {
      termValue
      isAllowed
    }
  }
`;

export default PID_VALIDATION;
