import { formatDateTime } from './date.js';
/**
 * Takes a comment and returns an array of values in the
 * order matching the table columns in Comments Table
 * @param {object} comment
 * @returns {array} [
 * Date,
 * Prime Line,
 * User,
 * Reason,
 * Contact Type,
 * Reference,
 * Details,
 * ]
 */
export const transformComment = (comment = {}) => {
  const formattedDate = formatDateTime(comment.contactDate);
  return [
    formattedDate,
    comment.line,
    comment.contactUser,
    comment.reasonCode,
    comment.contactType,
    comment.contactReference,
    comment.details,
  ];
};
