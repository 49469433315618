import gql from 'graphql-tag';

const EXCHANGE_PREVIEW_QUERY = gql`
  query exchangePreview($input: ExchangePreviewInput!, $timeout: Int!, $userInfo: UserInfo!) {
    exchangePreview(input: $input, timeout: $timeout, userInfo: $userInfo) {
      id
      status
      resourceType
      links {
        self {
          ref
        }
      }
      eta
      response {
        parentReturnOrderNumber
        parentSalesOrderNumber
        email
        phoneNumber {
          type
          countryCode
          subscriberNumber
          accountId
          verifyId
        }
        country
        currency
        locale
        fulfillmentGroups {
          items {
            id
            skuId
            quantity
            itemCosts {
              priceInfo {
                price
                employeePrice
                discount
                total
                taxTotal
                priceId
                priceSnapshotId
              }
              taxes {
                type
                rate
                total
              }
              promotionDiscounts {
                code
                amount
                id
                displayName
              }
              priceAdjustments {
                discount {
                  amountPerUnit
                  type
                  reasonCode
                }
              }
            }
            fulfillmentDetails {
              type
              location {
                type
                postalAddress {
                  address1
                  address2
                  address3
                  city
                  state
                  postalCode
                  country
                  county
                }
              }
            }
          }
          recipient {
            firstName
            lastName
            altFirstName
            altLastName
            middleName
            middleInitial
            givenName
          }
          contactInfo {
            email
          }
        }
        priceSummary {
          price
          discount
          taxTotal
          total
        }
      }
      error {
        httpStatus
        message
        code
        errors {
          field
          code
          message
        }
      }
    }
  }
`;

export default EXCHANGE_PREVIEW_QUERY;
