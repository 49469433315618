import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import clsx from 'clsx';

/**
 * Table that is styled in a very specific way, where the header column has a lower border
 * and the fields/values located under it will not have a border
 *
 * @param {string} label - label to render in first cell
 * @param {string} value - value to render in second cell
 * @param {string} secondValue - second value to follow first
 * @param {boolean} header - is header row
 * @param {boolean} indented - should be styled as indented under previous row
 * @param {boolean} withLeftShift - will shift values left
 * @returns
 */
function DetailsTableRow({ label, value, secondValue, header, indented, withLeftShift, ...props }) {
  const classes = useStyles();
  // If data-testid was passed in through the props, extract it for usage.
  const { 'data-testid': dataTestId } = props;

  return (
    <TableRow
      className={header ? classes.firstRow : classes.productInformationTableRow}
      data-testid={dataTestId}>
      <TableCell
        className={clsx(
          classes.shortTableHeader,
          withLeftShift && 'leftShift',
          indented && 'indented'
        )}
        aria-label={label}>
        {label}
      </TableCell>
      {value && (
        <TableCell
          className={clsx(
            classes.shortTableCell,
            withLeftShift && 'leftShift',
            indented && 'indented'
          )}>
          <span>{value}</span>
          {/* If a second value is present, place it alongside the first */}
          <span>{secondValue ? ` ${secondValue}` : null}</span>
        </TableCell>
      )}
    </TableRow>
  );
}

export default DetailsTableRow;

DetailsTableRow.propTypes = {
  'label': PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  'value': PropTypes.oneOfType([PropTypes.number, PropTypes.elementType, PropTypes.object]),
  'secondValue': PropTypes.oneOfType([PropTypes.number, PropTypes.elementType, PropTypes.object]),
  'header': PropTypes.bool,
  'indented': PropTypes.bool,
  'withLeftShift': PropTypes.bool,
  'data-testid': PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  firstRow: {
    borderBottom: '1px solid #e0e0e0',
  },
  productInformationTableRow: {
    height: '100%',
  },
  shortTableHeader: {
    'border': 'none',
    'borderBottom': 'none',
    'color': theme.palette.grey[700],
    'fontSize': '0.8125rem',
    'height': '10px',
    'padding': theme.spacing(0.5),
    'paddingLeft': theme.spacing(2),
    'whiteSpace': 'pre',
    '&.leftShift': {
      width: '20%',
    },
    '&.indented': {
      paddingLeft: theme.spacing(5),
    },
  },
  shortTableCell: {
    'border': 'none',
    'borderBottom': 'none',
    'height': '10px',
    'padding': theme.spacing(0.5),
    'paddingLeft': theme.spacing(2),
    '&.leftShift': {
      width: '80%',
    },
    '&.indented': {
      paddingLeft: theme.spacing(5),
    },
  },
}));
