import gql from 'graphql-tag';

const returnAsNewGiftCardMutation = gql`
  mutation postReturnAsNewGiftCard(
    $orderNumber: String!
    $giftCardDetails: GiftCardDetails!
    $timeout: Int!
  ) {
    postReturnAsNewGiftCard(
      orderNumber: $orderNumber
      giftCardDetails: $giftCardDetails
      timeout: $timeout
    ) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
      errors {
        error {
          field
          message
          code
        }
      }
    }
  }
`;

export default returnAsNewGiftCardMutation;
