/** React Util */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useLazyQuery } from 'react-apollo';
import { v4 as uuidv4 } from 'uuid';
/** Material UI */
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MuiTableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

/** Local */
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import dialogActions from '../../../../store/actions/dialogActions';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { getArrayProperty } from '../../../../utils/getArrayProperty';
import { findExchangeableLines } from '../../../../utils/orderLine.js';
import { step1SharedStyles } from '../sharedStyles';
import translations from './exchange.i18n';
import EXCHANGE_OPTIONS_QUERY from '../../../../queries/exchangeOptions.query';
import LANGUAGE_QUERY from '../../../../queries/languageByMarketplace.query';
import { ApiTimeOut } from '../../../../constants/dialog.const';
import { FormattedCurrency } from '../../../shared/formatCurrency.js';
import StyleLink from '../../content/shared/itemLink';
import useSnacks from '../../../../hooks/useSnacks';
import Loading from '../../../shared/loading';
import { formatExchangeItems } from '../../../../utils/exchanges';

export default function Step1() {
  const classes = useStyles();
  const [randomId] = useState(uuidv4());
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [assets] = useContext(AssetsContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const {
    selectLine,
    setAllSelectedLines,
    setExchangeLocale,
    setExchangeCountry,
    clearSelectedSkus,
  } = dialogActions;
  const { profileId, consumerType, consumerEmail } = consumerState;
  const { exchangeLocale, selectedLines } = dialogState;
  const [exchangeOptions, setExchangeOptions] = useState(null);
  const { setError } = useSnacks();
  const {
    SIZE,
    SELECT_ITEMS_TO_EXCHANGE,
    SEARCH_STYLE_NUMBER,
    SELECT_ALL,
    PRODUCT_NAME,
    QUANTITY,
    COLOR,
    PRICE,
    INELIGIBLE_ITEMS,
    ELIGIBLE,
    ITEM_NOT_EXCHANGEABLE,
    EXCHANGE_OPTIONS_ERROR,
    NO_PRODUCTS_AVAILABLE,
  } = useMemoTranslations(translations);

  let {
    orderLines,
    currency,
    locale,
    orderNumber,
    billTo,
    customerProfileReference,
    shipmentsV2,
  } = orderDetail;
  const upmId = profileId;
  orderLines = Array.from(orderLines);

  const shipments = getArrayProperty(shipmentsV2, 'objects');

  /* using country from shipping address, as there are cases where
   * billing and shipping countries are different causing INVALID_LOCALE error */
  const exchangeCountry = shipments[0]?.shipTo?.country ?? orderLines[0]?.shipTo?.address?.country;

  const exchangeableLineKeys = useMemo(() => findExchangeableLines(orderLines), [orderLines]);

  const exchangeableLines = orderLines.filter((line) =>
    exchangeableLineKeys.has(line.orderLineKey)
  );

  const exchangeItems = formatExchangeItems(exchangeableLines);

  const ineligibleLines = orderLines.filter((line) => !exchangeableLineKeys.has(line.orderLineKey));

  // reset selected skus to prevent duplicate skus when going back from step 2
  useEffect(() => dialogDispatch(clearSelectedSkus()), []);

  // query to get language locale
  const { data, loading: exchangeLocaleLoading } = useQuery(LANGUAGE_QUERY, {
    variables: {
      marketplace: exchangeCountry,
    },
    onCompleted: () => {
      /*
        reset the locale based on legacy mapping. this is required because 
        for some european countries that support both english and other language, 
        the latest locale mapping does not provide content. using the legacy mapping 
        to retrieve the original tag based on the incoming locale. this is a temporary fix 
        as the merch API is legacy and only uses legacy locale mapping
      */
      let legacyLocale = data?.marketplaceLanguages?.legacyMappings?.find(
        (lm) => lm.langLocale === locale
      );
      legacyLocale =
        legacyLocale?.legacyLanguageTag !== undefined ? legacyLocale.legacyLanguageTag : locale;

      dialogDispatch(setExchangeLocale(legacyLocale));
      dialogDispatch(setExchangeCountry(exchangeCountry));
    },
  });

  // query to get product options
  const [exchangeOptionsQuery, { loading: exchangeOptionsLoading }] = useLazyQuery(
    EXCHANGE_OPTIONS_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ exchangeOptions: exchangeOptionsData }) => {
        if (exchangeOptionsData.error) {
          const errorMessageOnJobCompletion = `${EXCHANGE_OPTIONS_ERROR}: ${exchangeOptionsData.error.message}`;
          setError(errorMessageOnJobCompletion);
        }

        /**
         * we don't have SKU IDs in the orderDetail response, we were meant to filter options by SKU
         * IDs. Instead we filter by styleName. This leaves us with duplicate exchange options. In
         * order to only display unique colorCode, styleName combinations we filter out duplicates
         * below
         */
        // TODO request service team add gtin to the response sku objects and filter by
        const uniqueExchangeOptions = exchangeOptionsData.response?.skus.reduce((acc, cur) => {
          if (
            !acc.find((sku) => sku.colorCode === cur.colorCode && sku.styleName === cur.styleName)
          ) {
            acc.push(cur);
          }
          return acc;
        }, []);

        setExchangeOptions(uniqueExchangeOptions);
      },
      onError: (error) => {
        setError(`${EXCHANGE_OPTIONS_ERROR} ${error.message}`);
      },
    }
  );

  useEffect(() => {
    const userInfo = {
      identityType: consumerType,
    };

    if (!customerProfileReference) {
      userInfo.email = billTo?.contactInformation?.email || consumerEmail;
      userInfo.upmId = randomId;
    } else {
      userInfo.upmId = upmId;
    }

    if (exchangeLocale) {
      exchangeOptionsQuery({
        variables: {
          input: {
            items: exchangeItems,
            parentReturnOrderNumber: orderNumber,
            email: billTo?.contactInformation?.email || consumerEmail,
            phoneNumber: billTo?.contactInformation?.dayPhoneNumber,
            locale: exchangeLocale,

            country: exchangeCountry,
          },
          timeout: ApiTimeOut,
          userInfo,
        },
      });
    }
  }, [exchangeLocale]);

  /**
   * Handles the onClick events for items that selectable.
   * @param {object} line orderLine item the action is being executed against.
   */
  const handleSelectLine = (line) => () => {
    dialogDispatch(selectLine(line));
  };
  const handleSelectAllLines = () => {
    const exchangeableLinesWithOptions = exchangeableLines.filter((line) =>
      Boolean(line.exchangeOptions?.length)
    );
    dialogDispatch(setAllSelectedLines(exchangeableLinesWithOptions));
  };

  const generateItemRow = (line, i, isExchangeable) => {
    const divKey = `exchange-item-thumb-${i}`;
    line.exchangeOptions = exchangeOptions?.filter(
      (skuObject) => skuObject.styleName === line.styleNumber
    );
    const hasExchangeOptions = Boolean(line.exchangeOptions?.length);
    const enabled = isExchangeable && hasExchangeOptions;

    // if the item is exchangeable and has options, row is selectable
    return (
      <TableRow
        key={divKey}
        data-testid={`item-to-select-${i}`}
        className={enabled ? '' : classes.notExchangeable}>
        <TableCell>
          {enabled && (
            <FormControl>
              <Checkbox
                color={'primary'}
                checked={Boolean(selectedLines[line.lineNumber])}
                inputProps={{ 'aria-label': line.item.itemDescription }}
                onKeyPress={handleSelectLine(line)}
                onClick={handleSelectLine(line)}
                tabIndex={0}
                data-testid={`item-checkbox-${i}`}
                classes={{ root: classes.checkbox }}
                icon={<CheckBoxOutlineBlankIcon fontSize='large' />}
                checkedIcon={<CheckBoxIcon fontSize='large' />}
              />
            </FormControl>
          )}
        </TableCell>
        <TableCell>
          <img
            style={{ maxHeight: '82px', maxWidth: '82px' }}
            src={getProductImageFromOrderLine(line, assets)}
            alt={line.item.itemDescription}
          />
        </TableCell>
        <TableCell>
          <StyleLink orderDetail={orderDetail} line={line} />
        </TableCell>
        <TableCell>
          {line.item.itemDescription}
          {!hasExchangeOptions && (
            <Typography className={classes.errorMessage}>{NO_PRODUCTS_AVAILABLE}</Typography>
          )}
        </TableCell>
        <TableCell>{line.displaySize}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{line.quantity}</TableCell>
        <TableCell>{line.colorDescription}</TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <FormattedCurrency currency={currency} amount={line.lineTotal} />
        </TableCell>
      </TableRow>
    );
  };

  return exchangeOptionsLoading || exchangeLocaleLoading ? (
    <Loading />
  ) : (
    <div style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}>
      {/* Step Title */}
      <Divider />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0 1rem',
        }}>
        <Typography className={classes.title}>{SELECT_ITEMS_TO_EXCHANGE}</Typography>
        <Typography className={classes.eligibleHeader}>
          {exchangeableLineKeys.size} {ELIGIBLE}
        </Typography>
      </Box>

      {/* Step Body */}
      <Table>
        <TableBody>
          <TableRow className={classes.headerRow}>
            <MuiTableCell>
              {Boolean(exchangeOptions?.length) && (
                <FormControl>
                  <Checkbox
                    inputProps={{
                      'aria-label': SELECT_ALL,
                      'aria-checked': Boolean(
                        Object.keys(selectedLines).length >= orderLines.length
                      ),
                    }}
                    checked={Boolean(
                      Object.keys(selectedLines).length >= exchangeableLineKeys.size
                    )}
                    onKeyPress={handleSelectAllLines}
                    onClick={handleSelectAllLines}
                    color={'primary'}
                    data-testid={'select-all-checkbox'}
                    classes={{ root: classes.checkbox }}
                    icon={<CheckBoxOutlineBlankIcon fontSize='large' />}
                    checkedIcon={<CheckBoxIcon fontSize='large' />}
                  />
                </FormControl>
              )}
            </MuiTableCell>
            <MuiTableCell>{Boolean(exchangeOptions?.length) && SELECT_ALL}</MuiTableCell>
            <MuiTableCell>{SEARCH_STYLE_NUMBER}</MuiTableCell>
            <MuiTableCell>{PRODUCT_NAME}</MuiTableCell>
            <MuiTableCell>{SIZE}</MuiTableCell>
            <MuiTableCell>{QUANTITY}</MuiTableCell>
            <MuiTableCell>{COLOR}</MuiTableCell>
            <MuiTableCell style={{ textAlign: 'right' }}>{PRICE}</MuiTableCell>
          </TableRow>
          {exchangeableLines.map((line, i) => generateItemRow(line, i, true))}
          {Boolean(ineligibleLines.length) && (
            <>
              <TableRow style={{ width: '100%' }}>
                <TableCell colSpan={8} className={classes.ineligibleTitle}>
                  <Typography className={classes.eligibleHeader}>{INELIGIBLE_ITEMS}</Typography>
                </TableCell>
              </TableRow>
              {ineligibleLines.map((line, i) => {
                return (
                  <Tooltip title={ITEM_NOT_EXCHANGEABLE} key={i}>
                    {generateItemRow(line, i, false)}
                  </Tooltip>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
      <Divider />
    </div>
  );
}

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    padding: '15px 10px',
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
  title: {
    marginTop: '.25rem',
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  eligibleHeader: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  headerRow: {
    '& > .MuiTableCell-root': {
      fontSize: '0.875rem',
      fontWeight: 500,
      padding: '15px 10px',
    },
  },
  checkbox: {
    width: '30px',
    height: '30px',
  },
  notExchangeable: {
    '& > .MuiTableCell-root': {
      color: theme.palette.grey[600],
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.875rem',
  },
  ineligibleTitle: {
    paddingLeft: '1rem',
    paddingBottom: 0,
  },
}));
