import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const InventoryProgressBar = ({ inventoryLevel }) => {
  const classes = useStyles();

  // map HIGH, MEDIUM, LOW to number values and bar colors
  let customClass = '';
  let value = 0;
  switch (inventoryLevel) {
    case 'LOW': {
      customClass = classes.low;
      value = 30;
      break;
    }
    case 'MEDIUM': {
      customClass = classes.medium;
      value = 60;
      break;
    }
    case 'HIGH': {
      customClass = classes.high;
      value = 100;
      break;
    }
    default: {
      // includes 'OOS' level
      break;
    }
  }

  return (
    <LinearProgress
      variant='determinate'
      value={value}
      role='meter'
      aria-label='inventory-indicator'
      classes={{
        colorPrimary: classes.colorPrimary,
        bar: classes.bar,
        barColorPrimary: customClass,
      }}
      className={classes.progressBar}
    />
  );
};

InventoryProgressBar.propTypes = {
  inventoryLevel: PropTypes.oneOf(['OOS', 'LOW', 'MEDIUM', 'HIGH']),
};

export default InventoryProgressBar;

const useStyles = makeStyles(({ palette }) => {
  return {
    progressBar: {
      width: '35px',
      borderRadius: 15,
      height: 8,
    },
    colorPrimary: {
      backgroundColor: palette.grey[350],
    },
    bar: {
      borderRadius: 15,
    },
    low: {
      backgroundColor: palette.inventory.low,
    },
    medium: {
      backgroundColor: palette.inventory.medium,
    },
    high: {
      backgroundColor: palette.inventory.high,
    },
  };
});
