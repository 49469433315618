import React, { createContext, useReducer } from 'react';
import { orderSearchReducer } from '../reducers/orderSearchReducer';

export const SearchContext = createContext({});
const { Provider } = SearchContext;

export const defaultState = {
  showSearchPage: false,
  /* searchFields' keys may look a little strange, but they've been defined
  this way to reflect the filter names that the cs_orderSummaryV2 API
  is designed to accommodate. */
  searchFields: {
    // Basic
    'orderNumber': '',
    'trackingNumber': '',
    'billTo.contactInformation.email': '',
    'billTo.contactInformation.dayPhoneNumber': '',
    // Advanced
    'addressIsBilling': '',
    'omsRegionReference': '',
    'address': {
      firstName: '',
      lastName: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    },
    'channel': '',
    'paymentMethods.paymentType': '',
    'paymentMethods.displayCardNumber': '',
    'orderLines.item.universalProductCode': '',
    'orderLines.orderLineType': '',
    'orderLines.statuses.contractNumber': '',
    'orderLines.statuses.chainedOrderNumber': '',
    'orderLines.styleNumber': '',
    'orderSubmitDateAfter': '',
    // Devs only
    'orderType': '',
    'status': '',
    'paymentType': '',
  },
  searchResults: [],
  pagination: {
    prev: '',
    next: '',
  },
  anchor: 0,
  sort: 'orderSubmitDateDesc',
};

/**
 * Provider for search results and search fields
 *
 * @param {Object} props – React props
 */
export const SearchResultsProvider = ({ children }) => {
  const [state, reducer] = useReducer(orderSearchReducer, defaultState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
