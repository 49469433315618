import React, { createContext, useState } from 'react';

export const AssetsContext = createContext([{}, () => {}]);
const { Provider } = AssetsContext;

/**
 * A basic provider for a context with only a single value
 *
 * @param {Object} props  – React props
 */
export const AssetsProvider = ({ children }) => {
  const [state, setState] = useState({});

  return <Provider value={[state, setState]}>{children}</Provider>;
};

export default AssetsContext;
