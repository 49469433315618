const translations = {
  ADMIN: {
    stringKey: 'admin',
    primaryValue: 'Admin',
    description: 'title on the top of the Admin page',
  },
  GROUPS: {
    stringKey: 'groups',
    primaryValue: 'Groups',
    description: 'label for permissions groups on Admin page',
  },
  SAVE: {
    stringKey: 'save',
    primaryValue: 'Save',
    description: 'label for submit button on Admin permissions form',
  },
  CANCEL: {
    stringKey: 'cancel',
    primaryValue: 'Cancel',
    description: 'label for reset button on Admin permissions form',
  },
  ARIA_ADMIN_CONTROL: {
    stringKey: 'ariaAdminControl',
    primaryValue: 'admin control',
    description: 'aria label for admin control',
  },
  ARIA_ADMIN_PAGE: {
    stringKey: 'ariaAdminPage',
    primaryValue: 'admin page',
    description: 'aria label for admin page',
  },
  ARIA_GROUP_PERMISSIONS: {
    stringKey: 'ariaGroupPermissions',
    primaryValue: 'group permissions',
    description: 'aria label for group permissions',
  },
};

export default translations;
