import gql from 'graphql-tag';

// Mutation to verify address entered
const ADDRESS_VALIDATION_V2_MUTATION = gql`
  mutation postAddressValidatorV2($input: AddressValidatorRequestBodyV2!, $guid: String!) {
    addressValidatorV2(input: $input, guid: $guid) {
      verificationCode
      score
      pobox
      address {
        address1
        address2
        address3
        city
        state
        country
        postalCode
      }
    }
  }
`;

export default ADDRESS_VALIDATION_V2_MUTATION;
