import { v4 as uuidv4 } from 'uuid';
import { getEmail } from './utility';
import { getShipmentField } from '../../../../../utils/shipment';
import getArrayProperty from '../../../../../utils/getArrayProperty';

export const generateAccertifyReturnRequestPayload = (
  dialogState,
  orderLine,
  orderDetails,
  athleteInfo
) => {
  const shipments = getArrayProperty(orderDetails.shipmentsV2, 'objects');
  const {
    linePriceInformation,
    quantityToReturn,
    returnReason,
    item,
    styleNumber,
    colorCode,
  } = orderLine;
  const { currency, omsRegionReference, orderNumber } = orderDetails;
  const { email } = athleteInfo;
  const { address } = dialogState;
  const trackingNumberForShipment = getTrackingNumberForShipment(shipments, orderLine.orderLineKey);
  return {
    eventSource: 'OMOBO',
    careID: `${uuidv4()}`,
    careTransactionType: 'Request',
    careTransactionDateTime: new Date().toISOString(),
    careAmount: (linePriceInformation?.listPrice || 0) * quantityToReturn,
    careCurrencyCode: currency,
    careCustomType: 'Return',
    careCustomReason: `${omsRegionReference}-RETURN-${returnReason?.code}`,
    athleteEmail: email,
    transactionID: orderNumber,
    transactionGeo: omsRegionReference,
    emailAddress: getEmail(dialogState),
    phoneNumber: address?.dayPhoneNumber,
    items: [
      {
        description: item?.itemDescription,
        itemID: `${styleNumber}-${colorCode}`,
        price: linePriceInformation?.listPrice,
        quantity: quantityToReturn,
        productCondition: 'new',
        shippingCompany: getShipmentField(shipments[0], 'standardCarrierAlphaCode'),
        shippingMethod: getShipmentField(shipments[0], 'actualShippingMethod'),
        shippingTrackingNumber: trackingNumberForShipment,
        originalDeliveryDateTime: new Date().toISOString(),
        pickUpLocation: `${address?.address1} ${address?.address2} ${address?.city} ${address?.state} ${address?.postalCode} ${address?.country}`,
        pickUpPersonFirstNamePrimary: address?.firstName,
        pickUpPersonLastNamePrimary: address?.lastName,
        pickUpPersonFirstNameAlternate: address?.alternateFirstName,
        pickUpPersonLastNameAlternate: address?.alternateLastName,
      },
    ],
  };
};

export const getTrackingNumberForShipment = (shipments, orderLineKey) => {
  for (const shipment of shipments) {
    for (const container of shipment.containers) {
      for (const shipmentLine of container.shipmentLines) {
        if (shipmentLine.orderLineIdentifier === orderLineKey) {
          return shipment.trackingNumber;
        }
      }
    }
  }
  return null;
};
