/** React Util */
import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { useQuery } from 'react-apollo';

/** Material UI */
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import dialogActions from '../../../../store/actions/dialogActions';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { step2SharedStyles } from '../sharedStyles';
import translations from './exchange.i18n';
import ChooseOrderLineReplacements from './chooseOrderlIneReplacements';
import { DiscountTypes, ApiTimeOut } from '../../../../constants/dialog.const';
import EXCHANGE_OPTIONS_QUERY from '../../../../queries/exchangeOptions.query';
import useSnacks from '../../../../hooks/useSnacks';
import { FormattedCurrency } from '../../../shared/formatCurrency.js';

export default function Step2() {
  const classes = useStyles();
  const [randomId] = useState(uuidv4());
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const { profileId, consumerType, consumerEmail } = consumerState;
  const { i18nString } = useContext(NikeI18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const {
    selectedLines,
    selectedSkus,
    exchangeCreditValue,
    exchangeCountry,
    exchangeLocale,
  } = dialogState;
  const { selectSku, setExchangeCreditValue } = dialogActions;
  const { setError } = useSnacks();

  const {
    CHOOSE_NEW_ITEMS,
    EXCHANGE_OPTIONS_ERROR,
    EXCHANGE_CREDIT_VALUE,
    EXCHANGE_CREDIT_VALUE_LOADING,
  } = mapValues(translations, i18nString);

  const { orderNumber, billTo, customerProfileReference } = orderDetail;
  const linesArray = Object.values(selectedLines);

  // query to get exchange credit value
  const userInfo = {
    identityType: consumerType,
  };

  if (!customerProfileReference) {
    userInfo.email = billTo?.contactInformation?.email || consumerEmail;
    userInfo.upmId = randomId;
  } else {
    userInfo.upmId = profileId;
  }

  const { loading: exchangeCreditLoading } = useQuery(EXCHANGE_OPTIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        items: linesArray.map((line) => ({
          gtin: line.item.universalProductCode,
          quantity: line.quantity,
        })),
        parentReturnOrderNumber: orderNumber,
        email: billTo?.contactInformation?.email || consumerEmail,
        phoneNumber: billTo?.contactInformation?.dayPhoneNumber,
        locale: exchangeLocale,
        country: exchangeCountry,
      },
      timeout: ApiTimeOut,
      userInfo,
    },
    onCompleted: ({ exchangeOptions: exchangeOptionsData }) => {
      if (exchangeOptionsData.error) {
        const errorMessageOnJobCompletion = `${EXCHANGE_OPTIONS_ERROR}: ${exchangeOptionsData.error.message}`;
        setError(errorMessageOnJobCompletion);
      }
      dialogDispatch(setExchangeCreditValue(exchangeOptionsData?.response?.exchangeCreditValue));
    },
    onError: (error) => {
      setError(`${EXCHANGE_OPTIONS_ERROR} ${error.message}`);
    },
  });

  // future todo: add messaging if athlete hasn't picked a color or size for one of the options

  const handleSelectSku = (originalLineNumber, product, sizeSpecs) => {
    const newSkuObject = {
      ...product,
      selectedSize: sizeSpecs,
      /* in step 4, omoboGeneratedId allows us to associate exchange preview response data
       * with exchange items stored in dialogState
       */
      omoboGeneratedId: uuidv4(),
      discount: {
        type: DiscountTypes.PERCENT_OFF,
        value: '',
        reason: { code: '-1', description: '', id: '' },
      },
    };
    if (
      !product || // if no product provided
      selectedSkus[originalLineNumber]?.selectedSize?.skuId === newSkuObject?.selectedSize?.skuId || // if same sku is already selected
      !product.sizeSpecifications?.includes(sizeSpecs) // if selected product doesn't have selected size
    ) {
      newSkuObject.selectedSize = null;
      dialogDispatch(selectSku(originalLineNumber, newSkuObject));
    } else {
      dialogDispatch(selectSku(originalLineNumber, newSkuObject));
    }
  };

  useEffect(() => {
    dialogDispatch(setExchangeCreditValue(null));
    // on first load select default color and size
    linesArray.forEach((orderLine) => {
      // if a line already has a selected line in dialog state don't change it
      if (selectedSkus[orderLine.lineNumber]?.selectedSize) return;

      const { exchangeOptions } = orderLine;
      const originalColorCodeIndex = exchangeOptions.findIndex(
        (option) => option.colorCode === orderLine.colorCode
      );

      const defaultSize = exchangeOptions[
        originalColorCodeIndex !== -1 ? originalColorCodeIndex : 0
      ].sizeSpecifications?.find((sizeSpec) => sizeSpec.localizedSize === orderLine.displaySize);

      // check if the original color code is available as an exchange option, and if so select it
      if (originalColorCodeIndex !== -1) {
        handleSelectSku(
          orderLine.lineNumber,
          exchangeOptions[originalColorCodeIndex],
          defaultSize?.level !== 'OOS' ? defaultSize : null
        );
        return;
      }
      // if the original color code is not available, then select the first exchange option
      handleSelectSku(
        orderLine.lineNumber,
        exchangeOptions[0],
        defaultSize?.level !== 'OOS' ? defaultSize : null
      );
    });
  }, []);

  /*
      Group into key value pairs, where key is the styleColorCode and the value is an
      array containing orderLines of the same styleColorCode.
     */
  const styleColorOrderLinesMapping = {};
  linesArray.forEach((orderLine) => {
    const key = `${orderLine.styleNumber}-${orderLine.colorCode}`;
    if (!styleColorOrderLinesMapping[key]) {
      styleColorOrderLinesMapping[key] = [orderLine];
    } else {
      styleColorOrderLinesMapping[key].push(orderLine);
    }
  });
  return (
    <>
      {/* Step Title */}
      <Divider variant='middle' />
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{CHOOSE_NEW_ITEMS}</Typography>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.exchangeCreditHeader}>{EXCHANGE_CREDIT_VALUE}:</Typography>
          {exchangeCreditLoading ? (
            <CircularProgress size={20} aria-label={EXCHANGE_CREDIT_VALUE_LOADING} />
          ) : (
            <Typography
              className={classes.exchangeCreditHeader}
              data-testid={'exchange-credit-value'}>
              <FormattedCurrency amount={exchangeCreditValue} currency={orderDetail.currency} />
            </Typography>
          )}
        </Box>
      </Box>

      {/* Step Body */}
      {/* Render a ChooseOrderLineReplace for each orderLine, but only displaying the header of the grouping where necessary. */
      Object.values(styleColorOrderLinesMapping).map((orderLines) =>
        orderLines.map((orderLine, i) => (
          <ChooseOrderLineReplacements
            originalLineNumber={orderLine.lineNumber}
            exchangeOptions={orderLine.exchangeOptions}
            handleSelectSku={handleSelectSku}
            key={`product-options-${i}`}
            displayHeader={i === 0}
          />
        ))
      )}
      <Divider variant='middle' />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.25rem 2rem 0.25rem 1rem',
  },
  title: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  exchangeCreditHeader: {
    fontWeight: 500,
    fontSize: '1.25rem',
    marginRight: '1rem',
  },
}));
