/**
 * This utility takes the data from an attachment and returns a url that downloads the attachment
 * @param {object} data an object containing all attachment info
 * @returns {string} url a link that will download the attachment
 */
export default function buildAttachmentUrl(data) {
  // data.attachment contains a base-64 encoded string that has the attachment value
  // atob decodes the string
  const byteCharacters = atob(data.attachment);
  // create an array of the byteCharacters
  const byteNumbers = new Array(byteCharacters.length);
  // loop over the array of byteCharacters to create integers representing the
  // UTF-16 code unit at the given index
  for (let n = 0; n < byteCharacters.length; n++) {
    byteNumbers[n] = byteCharacters.charCodeAt(n);
  }
  // create a typed array of 8-bit unsigned integers
  const byteArray = new Uint8Array(byteNumbers);
  // now that we have the byte array, construct the blob from it
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });
  // create the url that will be used as the href element to download the attachment
  const url = window.URL.createObjectURL(blob);

  return url;
}
