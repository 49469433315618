import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import TopBarContainer from '../orders/topBar/topBarContainer';
import SearchForm from './searchForm';
import WelcomeMessage from './../shared/welcomeMessage';
import ResponseSnackBar from './../shared/snackbar';
import NavLinks from './navLinks';

/**
 * Main react component housing order search page
 */
export default function OrderSearch() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.page}>
          <TopBarContainer />
          <div className={classes.welcomeNav}>
            <WelcomeMessage />
            <NavLinks />
          </div>
          <SearchForm className={classes.searchForm} />
        </Box>
        <ResponseSnackBar />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  welcomeNav: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  page: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.grey[100],
    overflowX: 'hidden',
  },
  searchForm: {
    padding: theme.spacing(2),
  },
}));
