import React, { useContext } from 'react';
import Link from '@material-ui/core/Link';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import translations from '../content.i18n';
import PropTypes from 'prop-types';

/**
 * gives us a clickable link to the store page of the item in the provided line
 * @param {Object} orderDetail field of params, contains data from orderDetail graphQL request
 * @param {Object} line field of params, contains info about a specific product on the order
 * @returns React component displays style number, color code and a link to store page
 */
const StyleLink = ({ orderDetail, line }) => {
  const { i18nString } = useContext(NikeI18nContext);

  const { ARIA_NIKE_PDP_HYPERLINK } = mapValues(translations, i18nString);
  // nike pdp link using style number and marketplace
  const nikePdpUrl =
    'https://nike.com/' +
    orderDetail.locale.substring(3, 5).toLowerCase() +
    '/t/' +
    line?.item?.itemDescription?.replaceAll(/[ /]/g, '-') +
    '/' +
    line?.styleNumber +
    '-' +
    line.colorCode;

  return (
    <Link
      // links style number to nike pdp
      aria-label={ARIA_NIKE_PDP_HYPERLINK}
      href={nikePdpUrl}
      target='_blank'
      rel='noopener noreferrer'
      data-testid='nike-pdp-link'>
      {line.styleNumber + '-' + line.colorCode}
    </Link>
  );
};

StyleLink.propTypes = {
  line: PropTypes.shape({
    lineNumber: PropTypes.number,
    item: PropTypes.shape({
      itemDescription: PropTypes.string,
    }),
    colorCode: PropTypes.string,
    orderLineType: PropTypes.string,
    styleNumber: PropTypes.string,
  }).isRequired,
  orderDetail: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default StyleLink;
