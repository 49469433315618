/** React/Utils */
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import decodeToken from 'jwt-decode';

/** Material-UI */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

/** Local */
import AddressForm from '../../../address/addressForm';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { PermissionContext } from '../../../../store/contexts/permissionContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { areLineItemsDigitalGiftCards } from '../../../../utils/order';
import { hasAndIsKoreanWarehouseWorkerOnly } from '../../../../utils/adGroups';
import { ShippingOptions } from '../../../../constants/shippingOptions.const';
import translations from './shared.i18n';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { Granted } from '../../../../constants/permissions.const';

/**
 * This component is step 3 of the Korea Create Return flow
 *
 * @param {Object} props – props object for a React component
 */
const KoreaStep3 = ({ className }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, hasModificationPermissions] = useContext(PermissionContext);
  const { authState } = useOktaAuth();
  const { accessToken } = authState;

  const { selectedLines, shippingOption, trackingNumber } = dialogState;
  const { setInitialShippingOption, setShippingOption, setTrackingNumber } = dialogActions;
  const { omsRegionReference: region } = orderDetail;
  const { groups } = decodeToken(accessToken?.accessToken);
  const { PICKUP, NON_PICKUP } = ShippingOptions;
  const { NONPICKUP_FRIENDLY, PICKUP_FRIENDLY, TRACKING_ID } = useMemoTranslations(translations);

  const isWarehouseWorkerOnly = hasAndIsKoreanWarehouseWorkerOnly(groups);
  const shouldBeEditable = hasModificationPermissions === Granted;

  // just once, we'll get our initial shipping options.
  useEffect(() => {
    if (isWarehouseWorkerOnly) {
      dialogDispatch(setInitialShippingOption(NON_PICKUP));
    } else {
      dialogDispatch(setInitialShippingOption(PICKUP));
    }
  }, []);

  const shouldDisplayShippingOptions = !isWarehouseWorkerOnly;

  const handleChangeShippingOption = (event) => {
    const { value } = event.target;
    dialogDispatch(setShippingOption(value));
  };

  const handleChangeTrackingNumber = (event) => {
    const { value } = event.target;
    dialogDispatch(setTrackingNumber(value));
  };

  return (
    <form className={clsx(className, classes.step4Form)}>
      <>
        {shouldDisplayShippingOptions && (
          <RadioGroup
            className={classes.shippingOptions}
            value={shippingOption}
            onChange={handleChangeShippingOption}
            data-testid='shipping-option-radio-group'
            row>
            <FormControlLabel
              label={PICKUP_FRIENDLY}
              value={PICKUP}
              control={<Radio color='primary' />}
            />
            <FormControlLabel
              label={NONPICKUP_FRIENDLY}
              value={NON_PICKUP}
              control={<Radio color='primary' />}
            />
          </RadioGroup>
        )}
        {shippingOption === PICKUP ? (
          <AddressForm
            region={region}
            className={classes.addressForm}
            isGiftCardAddress={areLineItemsDigitalGiftCards(selectedLines)}
            shouldBeEditable={shouldBeEditable}
          />
        ) : (
          <div className={classes.trackingNumberInput}>
            <FormControlLabel
              aria-label={'trackingNumber'}
              data-testid='tracking-id-number'
              style={{ display: 'block', margin: 0 }}
              control={
                <TextField
                  value={trackingNumber || ''}
                  onChange={handleChangeTrackingNumber}
                  label={TRACKING_ID}
                  style={{ display: 'block' }}
                />
              }
            />
          </div>
        )}
      </>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  addressForm: {
    gridArea: 'AF',
    width: '75%',
  },
  trackingNumberInput: {
    gridArea: 'AF',
  },
  shippingOptions: {
    gridArea: 'SO',
    marginBottom: '1rem',
  },
  step4Form: {
    marginTop: '1rem',
    marginBottom: '2rem',
    display: 'grid',
    gridTemplateAreas: `
      " SO SO SO "
      " AF AF AF "
    `,
  },
}));

export default KoreaStep3;
