const translations = {
  WELCOME_BACK: {
    stringKey: 'welcomeBack',
    primaryValue: 'Welcome Back',
    description: 'title on the top of the recent orders page',
  },
  ARIA_TABLE: {
    stringKey: 'ariaOrderTable',
    primaryValue: 'Order Table',
    description: 'aria-label for order table on recent orders page',
  },
};

export default translations;
