import { actionTypes } from '../actions/settingsActions';
import { defaultState } from '../contexts/settingsContext';

/**
 * The reducer for the admin context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const settingsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_PREFERENCES: {
      return {
        ...state,
        ...action.preferences,
        isSettingsAvailable: true,
      };
    }
    case actionTypes.SET_LANGUAGE: {
      return {
        ...state,
        language: action.language,
      };
    }
    case actionTypes.SET_EMAIL: {
      return {
        ...state,
        athlete_email: action.email,
      };
    }
    case actionTypes.IS_SETTINGS_AVAILABLE: {
      return {
        ...state,
        isSettingsAvailable: action.isSettingsAvailable,
      };
    }
    default: {
      return state;
    }
  }
};
