/** React/Utils */
import React, { useState, useEffect, useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

/** Local */
import translations from './editableField.i18n';

/**
 *  Component to edit inline text with save/cancel button and possibility to validate value
 */
const EditableField = ({
  fieldValue,
  fieldLabel,
  editModeBtnLabel,
  validatePattern,
  disableChange,
  onSave,
}) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { SAVE_BUTTON, CANCEL_BUTTON } = mapValues(translations, i18nString);
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [inputValue, setInputValue] = useState(fieldValue || '');

  useEffect(() => {
    setSaveBtnEnabled();
  });

  const handleEditMode = () => {
    setInputValue(fieldValue);
    setEditing(true);
  };

  const handleSave = () => {
    onSave(inputValue);
  };

  const handleCancel = () => {
    setEditing(false);
    setValidationError(null);
  };

  const validateField = (value) => {
    if (validatePattern && validatePattern.value && !validatePattern.value.test(value)) {
      setValidationError(validatePattern.message);
    } else {
      setValidationError(null);
    }
  };

  const handleChange = (e) => {
    validateField(e.target.value);
    setInputValue(e.target.value);
  };

  const setSaveBtnEnabled = () => {
    setSaveEnabled(fieldValue !== inputValue && validationError === null);
  };

  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={fieldLabel}
            defaultValue={fieldValue}
            onChange={handleChange}
            error={!!validationError}
            helperText={validationError}
            className={classes.textPanel}
            disabled={disableChange}
            data-testid={'edit-email-field'}
          />
          <div className={classes.buttonPanel}>
            <Button
              onClick={handleSave}
              data-testid='editable-field-save'
              disabled={!isSaveEnabled || disableChange}>
              <Tooltip title={SAVE_BUTTON}>
                <CheckCircleIcon className={classes.buttonIcon} />
              </Tooltip>
            </Button>
            <Button onClick={handleCancel} data-testid='editable-field-cancel'>
              <Tooltip title={CANCEL_BUTTON}>
                <CancelIcon className={classes.buttonIcon} />
              </Tooltip>
            </Button>
          </div>
        </>
      ) : (
        <div data-testid='billing-email'>
          {fieldValue}
          <Button
            aria-label={editModeBtnLabel ? editModeBtnLabel.toLowerCase() : ''}
            onClick={handleEditMode}
            data-testid='edit-email-button'
            className={classes.button}>
            <EditIcon className={classes.buttonIcon} />
            {editModeBtnLabel ? editModeBtnLabel.toLowerCase() : ''}
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textPanel: {
    width: '100%',
  },
  buttonPanel: {
    display: 'flex',
    flexDirection: 'row',
    float: 'right',
  },
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  linkProfileButton: {
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
  },
  addressTypography: {
    whiteSpace: 'pre-line',
    lineHeight: 2,
  },
}));

export default EditableField;
