import sharedTranslations from './shared.i18n';

const translations = {
  ARIA_CARRIER: sharedTranslations.ARIA_CARRIER,
  ARIA_RETURN_PAYMENT: {
    description: 'aria label for return payment selection',
    primaryValue: 'choose return payment type',
    stringKey: 'ariaReturnPayment',
  },
  ARIA_REFUND_DETAILS: {
    description: 'aria label for return refund details',
    primaryValue: 'refund details',
    stringKey: 'ariaRefundDetails',
  },
  BACK: sharedTranslations.BACK,
  CREATE_RETURN: sharedTranslations.CREATE_RETURN,
  NEXT: sharedTranslations.NEXT,
  BILLING_ADDRESS: {
    description: 'label on the returns wizard for "Billing Address"',
    primaryValue: 'Billing Address',
    stringKey: 'billingAddress',
  },
  GIFTEE_EMAIL: {
    description: 'label on the returns wizard for "Giftee Email"',
    primaryValue: 'Giftee Email',
    stringKey: 'gifteeEmail',
  },
  NEW_GIFT_CARD: {
    description: 'label on the returns wizard for "New Gift Card"',
    primaryValue: 'New Gift Card',
    stringKey: 'newGiftCard',
  },
  ORIGINAL_PAYMENT_METHOD: {
    description: 'label on the returns wizard for "Original Payment Method"',
    primaryValue: 'Original Payment Method',
    stringKey: 'originalPaymentMethod',
  },
  ORIGINAL_TOTAL: {
    description: 'label on the returns wizard for "Original Total"',
    primaryValue: 'Original Total',
    stringKey: 'originalTotal',
  },
  PAYMENT_TYPE: {
    description: 'label on the returns wizard for "Payment Type"',
    primaryValue: 'Payment Type',
    stringKey: 'paymentType',
  },
  PAYMENT_INFORMATION_HEADER: {
    description: 'header on the returns wizard when new gift card is selected',
    primaryValue: 'Payment Information',
    stringKey: 'paymentInformationHeader',
  },
  REFUND_AMOUNT: {
    description: 'label on the returns wizard for "Refund Amount"',
    primaryValue: 'Refund Amount',
    stringKey: 'refundAmount',
  },
  REFUND_WILL_BE_SENT_TO_ORIGINAL_PAYMENT_METHOD: {
    description: 'display text when original payment method is the only refund option',
    primaryValue: 'Refund will be sent to the original payment method',
    stringKey: 'refundWillBeSentToOriginalPaymentMethod',
  },
  THIS_IS_A_GIFT_RETURN: {
    description: 'label on the returns wizard for "This is a Gift Return"',
    primaryValue: 'This is a Gift Return',
    stringKey: 'thisIsAGiftReturn',
  },
  GIFT_CARD_SHIPPING_ADDRESS: {
    description: 'label on the returns wizard for the return to gift card section.',
    primaryValue: 'Gift Card Shipping Address',
    stringKey: 'giftCardShippingAddress',
  },
  FIRST_NAME: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'First Name',
    stringKey: 'firstName',
  },
  LAST_NAME: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Last Name',
    stringKey: 'lastName',
  },
  ADDRESS_ONE: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Address 1',
    stringKey: 'address1',
  },
  ADDRESS_TWO: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Address 2',
    stringKey: 'address2',
  },
  CITY: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'City',
    stringKey: 'city',
  },
  STATE: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'State',
    stringKey: 'state',
  },
  ZIPCODE: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Zip Code',
    stringKey: 'zipCode',
  },
  COUNTRY: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Country',
    stringKey: 'country',
  },
  DAY_PHONE_NUMBER: {
    description: 'label on the address input field for refunding to gift card',
    primaryValue: 'Daytime Phone Number',
    stringKey: 'dayPhoneNumber',
  },
};

export default translations;
