export const actionTypes = {
  SET_PREFERENCES: 'SET_PREFERENCES',
  SET_EMAIL: 'SET_EMAIL',
  SET_LANGUAGE: 'SET_LANGUAGE',
  IS_SETTINGS_AVAILABLE: 'IS_SETTINGS_AVAILABLE',
};

export const actions = {
  setPreferences:
    /**
     * This action sets the settings preferences
     *
     * @param {Object} preferences – the preferences to set in state
     */
    (preferences) => ({
      type: actionTypes.SET_PREFERENCES,
      preferences,
    }),

  setLanguage:
    /**
     * This action sets the language settings
     *
     * @param {String} language – the language to set in state
     */
    (language) => ({
      type: actionTypes.SET_LANGUAGE,
      language,
    }),

  setEmail:
    /**
     * This action sets the language settings
     *
     * @param {String} email – the email to set in state
     */
    (email) => ({
      type: actionTypes.SET_EMAIL,
      email,
    }),

  setIsSettingsAvailable:
    /**
     * This action sets the boolean isSettingsAvailable
     *
     * @param {Boolean} isSettingsAvailable – isSettingsAvailable to set in state
     */
    (isSettingsAvailable) => ({
      type: actionTypes.IS_SETTINGS_AVAILABLE,
      isSettingsAvailable,
    }),
};

export default actions;
