/** React/Utils */
import React from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PhoneIcon from '@material-ui/icons/Phone';
import FaceIcon from '@material-ui/icons/Face';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import PaymentIcon from '@material-ui/icons/Payment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PauseIcon from '@material-ui/icons/Pause';

/** Local */
import Geo from '../../../../../constants/geos.const';
import {
  PaymentTypesDict,
  suspendedPaymentRefs,
} from '../../../../../constants/paymentTypes.const';
import {
  SuspendOrActivatePayment,
  ViewFullGiftCardNumber,
} from '../../../../../constants/permissions.const';
import BasicTableRow from '../../../../shared/table/tableRow';
import translations from '../paymentDetails.i18n';
import config from '../../../../../utils/config';
import { prepareAddress } from '../../../../../utils/address';
import useHasPermission from '../../../../../hooks/useHasPermission';
import HasPermission from '../../../../shared/hasPermission';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import PaymentDetailsOFBT from './paymentDetailsOFBT';
import NewTabButton from '../../../../shared/newTabButton';
import { sha256 } from 'js-sha256';

/**
 * Item details component.
 * Provide it:
 * the payment - the paymentMethod object used in this component
 * children - whatever you want to display in the context of this payment
 */
export default function PaymentDetails({
  payment,
  children,
  cardClass,
  cardSubHeadingClass,
  currency,
  displayModButtons,
  tableClassName,
  tableHeadingClass,
  cellClass,
  index,
  orderNumber,
  orderType,
  parentReturnOrder,
  partner,
  region,
}) {
  const classes = useStyles();
  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderNumber}&orderHeaderKey=`;

  const {
    AUTHORIZED,
    CHARGED,
    DETAILS,
    PAYMENT_TYPE,
    REFUNDED,
    REQUESTED_AUTH,
    REQUESTED_CHARGE,
    SUSPENDED,
    TRANSACTIONS,
    ARIA_PAYMENT_DETAILS_1,
    ARIA_PAYMENT_DETAILS_2,
    ARIA_PAYMENT_SUMMARY,
    ACTIVATE_PAYMENT,
    SUSPEND_PAYMENT,
  } = useMemoTranslations(translations);

  const { hasPermission } = useHasPermission();

  const {
    displayCreditCardNumber,
    displayDebitCardNumber,
    displayGiftCardNumber,
    displayCardNumber,
    creditCardExpiration,
    firstName,
    lastName,
    billTo,
    suspendChargeReference,
    paymentId,
    svcNumber,
    svcNumberHash,
  } = payment;

  const giftCardHash = svcNumberHash || (svcNumber && sha256(svcNumber)) || '';
  const giftCardUrlQuery = `&q=${giftCardHash}&c=${currency}&or=${orderNumber}`;

  const address = prepareAddress(payment);

  const summaryHeaders = [REQUESTED_AUTH, AUTHORIZED, REQUESTED_CHARGE, CHARGED, REFUNDED];

  const summaryValues = [
    payment.requestedAuthorizationAmount,
    payment.totalAuthorizedAmount,
    payment.requestedChargeAmount,
    payment.totalChargedAmount,
    payment.totalRefundedAmount,
  ];

  const dayPhoneNum = billTo?.contactInformation?.dayPhoneNumber || '';

  const isPaymentTypeSuspended = suspendedPaymentRefs.includes(
    suspendChargeReference?.toUpperCase()
  );
  const PaymentTypeStatusText = () =>
    isPaymentTypeSuspended ? (
      <Typography
        className={classes.typeStatus}
        variant='h3'
        color='error'>{` (${SUSPENDED.toUpperCase()})`}</Typography>
    ) : (
      ''
    );

  const paymentType =
    payment.merchantAccountNumber === 'voucher'
      ? PaymentTypesDict.VOUCHER
      : PaymentTypesDict[payment.paymentType];

  // fields for payment preview
  const stars = '**** **** **** ';
  const getPaymentPreview = () => {
    if (payment.merchantAccountNumber === 'voucher') return payment.svcNumber;
    if (paymentType === PaymentTypesDict.GIFT_CERTIFICATE) {
      const giftCardNumber = hasPermission(ViewFullGiftCardNumber, region)
        ? payment?.svcNumber || displayGiftCardNumber || displayCardNumber
        : stars + displayGiftCardNumber;
      return (
        <NewTabButton
          label={giftCardNumber}
          href={config.giftCardUrl}
          query={giftCardUrlQuery}
          testId='payment-preview-link'
        />
      );
    }
    return stars + (displayCardNumber || displayCreditCardNumber || displayDebitCardNumber || '');
  };

  const nameFromAddress = firstName && lastName ? address?.[0] : null;

  const formattedAddress = address?.slice(nameFromAddress ? 1 : 0).join('\n');

  const showPaymentPreview =
    payment.paymentType !== 'ALIPAY' &&
    payment.paymentType !== 'WECHAT' &&
    getPaymentPreview() !== stars;

  return (
    <Card className={cardClass} data-testid={`payment-details-${paymentId}`}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.detailsGrid}>
          <CardHeader
            className={classes.detailsTitle}
            classes={{ root: classes.cardHeaderRoot }}
            disableTypography
            title={
              <>
                <Typography variant='h2' className={classes.typeStatus}>
                  {`${PAYMENT_TYPE}: ${paymentType}`}
                </Typography>
                <PaymentTypeStatusText />
              </>
            }
          />
          {displayModButtons && (
            <Box
              p={1}
              className={classes.detailsButtons}
              data-testid={`payment-mod-buttons-${index}`}>
              {/* only display a button if we have a non-exchange, 
                non-partner sales order and region is not Korea, China, Global, or XA */}
              {orderType === 'SALES_ORDER' &&
                !parentReturnOrder &&
                !partner &&
                ![Geo.KOREA, Geo.CHINA, Geo.NIKEGS, Geo.NIKEXA].includes(region) && (
                  <>
                    <HasPermission permission={SuspendOrActivatePayment} region>
                      {/* ensure we display the correct button for Payment Suspension/Activation */}
                      {isPaymentTypeSuspended ? (
                        <Button
                          aria-label={ACTIVATE_PAYMENT.toLowerCase()}
                          className={classes.button}
                          href={cspUrl}
                          data-testid='activate-payment-button'
                          target='_blank'>
                          <PlayCircleFilled className={classes.buttonIcon} />
                          {ACTIVATE_PAYMENT}
                        </Button>
                      ) : (
                        <Button
                          aria-label={SUSPEND_PAYMENT.toLowerCase()}
                          className={classes.button}
                          href={cspUrl}
                          data-testid='suspend-payment-button'
                          target='_blank'>
                          <PauseIcon className={classes.buttonIcon} />
                          {SUSPEND_PAYMENT}
                        </Button>
                      )}
                    </HasPermission>
                  </>
                )}
            </Box>
          )}
          {/* slightly different UI for OFBT payment, lives in its own component */}
          {paymentType === PaymentTypesDict.CUSTOMER_OFBT ? (
            <PaymentDetailsOFBT payment={payment} />
          ) : (
            paymentType !== PaymentTypesDict.COD && (
              <Box display='flex' className={classes.detailsDetails}>
                <List aria-label={ARIA_PAYMENT_DETAILS_1} className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon>
                      <FaceIcon />
                    </ListItemIcon>
                    <ListItemText primary={nameFromAddress} />
                  </ListItem>
                  <ListItem alignItems='flex-start' className={classes.listItem}>
                    <ListItemIcon>
                      <HomeWorkIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={formattedAddress}
                      primaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
                    />
                  </ListItem>
                </List>
                <List aria-label={ARIA_PAYMENT_DETAILS_2} className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary={dayPhoneNum} />
                  </ListItem>
                  <ListItem alignItems='flex-start' className={classes.listItem}>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText
                      /*    
                          Displays payment preview if not from Wechat, Alipay, or empty. 
                          Otherwise, returns empty string. 
                        */
                      data-testid='payment-preview'
                      primary={showPaymentPreview ? getPaymentPreview() : ''}
                      secondary={creditCardExpiration}
                    />
                  </ListItem>
                </List>
              </Box>
            )
          )}
        </Box>
        <Typography aria-label={DETAILS.toLowerCase()} variant='h3' className={cardSubHeadingClass}>
          {DETAILS}
        </Typography>
        <Table aria-label={ARIA_PAYMENT_SUMMARY} className={tableClassName}>
          <TableBody>
            <BasicTableRow header data={summaryHeaders} cellClassName={tableHeadingClass} />
            <BasicTableRow data={summaryValues} cellClassName={cellClass} />
          </TableBody>
        </Table>
      </CardContent>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls={`payment-transactions-${index}`}
          classes={{ root: classes.accordionStyle, expanded: classes.accordionStyle }}>
          <Typography
            aria-label={TRANSACTIONS.toLowerCase()}
            variant='h3'
            className={cardSubHeadingClass}>
            {TRANSACTIONS}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionStyle}>{children}</AccordionDetails>
      </Accordion>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '16em',
  },
  listItem: { minHeight: '1em' },
  typeStatus: {
    display: 'inline',
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  detailsGrid: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridTemplateColumns: '1.5fr 0.5fr',
    gridTemplateAreas: `
    "Title Buttons"
    "Details Buttons"`,
    paddingBottom: 0,
  },
  detailsButtons: {
    gridArea: 'Buttons',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  detailsDetails: {
    gridArea: 'Details',
  },
  detailsTitle: {
    gridArea: 'Title',
  },
  list: {
    margin: 0,
    paddingTop: theme.spacing(0.75),
  },
  accordionStyle: {
    'padding': '0 inherit',
    'margin': 0,
    '&:first-child': {
      margin: 0,
      paddingTop: 0,
    },
  },
  cardContent: {
    margin: 0,
    paddingBottom: 0,
  },
  buttonIcon: { marginRight: theme.spacing(1), color: theme.palette.primary.dark },
}));
PaymentDetails.propTypes = {
  cardClass: PropTypes.string.isRequired,
  cardSubHeadingClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  currency: PropTypes.string,
  index: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    accountNumber: PropTypes.string,
    billTo: PropTypes.shape({
      address: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
      }),
      contactInformation: PropTypes.shape({
        dayPhoneNumber: PropTypes.string,
      }),
      recipient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    checkReference: PropTypes.string,
    creditCardExpiration: PropTypes.string,
    displayCreditCardNumber: PropTypes.string,
    displayDebitCardNumber: PropTypes.string,
    displayGiftCardNumber: PropTypes.string,
    displayCardNumber: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    merchantAccountNumber: PropTypes.string,
    svcNumber: PropTypes.string,
    svcNumberHash: PropTypes.string,
    paymentId: PropTypes.string,
    paymentType: PropTypes.string,
    requestedAuthorizationAmount: PropTypes.string,
    requestedChargeAmount: PropTypes.number,
    totalAuthorizedAmount: PropTypes.string,
    totalChargedAmount: PropTypes.number,
    totalRefundedAmount: PropTypes.number,
    suspendChargeReference: PropTypes.string,
    creditCardHolderName: PropTypes.string,
  }).isRequired,
  tableClassName: PropTypes.string,
  tableHeadingClass: PropTypes.string.isRequired,
  orderNumber: PropTypes.string,
  orderType: PropTypes.string,
  parentReturnOrder: PropTypes.string,
  partner: PropTypes.string,
  region: PropTypes.string,
  displayModButtons: PropTypes.bool,
};

PaymentDetails.defaultProps = {
  payment: {
    billTo: {
      address: {
        address1: '',
        city: '',
        postalCode: '',
        state: '',
      },
      contactInformation: {
        dayPhoneNumber: '',
      },
    },
    creditCardExpiration: '',
    displayCardNumber: '',
    displayCreditCardNumber: '',
    displayDebitCardNumber: '',
    displayGiftCardNumber: '',
    displayModButtons: true,
    firstName: '',
    lastName: '',
    middleName: '',
    requestedAuthorizationAmount: '',
    requestedChargeAmount: 0,
    totalAuthorizedAmount: '',
    totalChargedAmount: 0,
    totalRefundedAmount: 0,
    suspendChargeReference: '',
    creditCardHolderName: '',
  },
  tableClassName: '',
  orderNumber: '',
  partner: '',
  displayModButtons: true,
};
