const translations = {
  ADD_RFO: {
    description: 'text for add rfo button',
    primaryValue: 'Add RFO',
    stringKey: 'addRfo',
  },
  ORDER_INVOICE_ERROR: {
    description: 'label for invoice error notification',
    primaryValue: 'Error fetching invoice',
    stringKey: 'invoiceError',
  },
  ARIA_INVOICE_LOADING: {
    description: 'ARIA label for invoice details loading icon',
    primaryValue: 'invoice info loading',
    stringKey: 'ariaInvoiceLoading',
  },
  TRANSACTIONS: {
    description: 'label on the Payment Details page for "transactions"',
    primaryValue: 'Transactions',
    stringKey: 'transactions',
  },
  ARIA_PAY_TYPE_TRANS_DETAILS: {
    description: 'aria label for transaction details per payment type',
    primaryValue: 'payment type transaction details',
    stringKey: 'ariaPayTypeTransDetails',
  },
  ALL_TRANS: {
    description: 'label on the Payments page for "All Transactions"',
    primaryValue: 'All Transactions',
    stringKey: 'allTransactions',
  },
  AMOUNT: {
    description: 'label on the Payments page for "Amount"',
    primaryValue: 'Amount',
    stringKey: 'amount',
  },
  AUTH_DATE: {
    description: 'label on the Payments page for "Auth Date"',
    primaryValue: 'Auth Date',
    stringKey: 'authDate',
  },
  AUTH_NUM: {
    description: 'label on the Payments page for "Auth #"',
    primaryValue: 'Auth #',
    stringKey: 'authNum',
  },
  DATE: {
    description: 'label on the Payments page for "Date"',
    primaryValue: 'Date',
    stringKey: 'date',
  },
  INVOICES: {
    description: 'label on the Payments page for "Invoices"',
    primaryValue: 'Invoices',
    stringKey: 'invoices',
  },
  PAYMENT_DETAILS: {
    description: 'accessible heading for the Payments page',
    primaryValue: 'Payment Details',
    stringKey: 'paymentDetails',
  },
  PAYMENT_SUMMARY: {
    description: 'label on the Payments page for "Summary" section',
    primaryValue: 'Payment Summary',
    stringKey: 'paymentSummary',
  },
  STATUS: {
    description: 'label on the Payments page for "Status"',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  STATUS_DATE: {
    description: 'label on Details page for "status date"',
    primaryValue: 'Status Date',
    stringKey: 'statusDate',
  },
  SUMMARY: {
    description: 'label on the Payments page for "Summary" section',
    primaryValue: 'Summary',
    stringKey: 'summary',
  },
  TOTAL_TRANSF_IN: {
    description: 'label on the Payments page for "Total Transferred In"',
    primaryValue: 'Total Transferred In',
    stringKey: 'totalTransferredIn',
  },
  TOTAL_TRANSF_OUT: {
    description: 'label on the Payments page for "Total Transferred Out"',
    primaryValue: 'Total Transferred Out',
    stringKey: 'totalTransferredOut',
  },
  TRANSF_DATE: {
    description: 'label on the Payments page for "Transfer Date',
    primaryValue: 'Transfer Date',
    stringKey: 'transferDate',
  },
  TOTALS: {
    description: 'label on the Payments page for "Totals"',
    primaryValue: 'Totals',
    stringKey: 'totals',
  },
  ARIA_TRANS_DETAILS: {
    description: 'aria label on the Payments page for the "transactions details" table',
    primaryValue: 'Transactions details',
    stringKey: 'ariaTransDetails',
  },
  ARIA_TRANS_TOTALS: {
    description: 'aria label on the Payments page for the "transactions totals" table',
    primaryValue: 'Transactions totals',
    stringKey: 'ariaTransTotals',
  },
  TYPE: {
    description: 'label on the Payments page for "Type"',
    primaryValue: 'Type',
    stringKey: 'type',
  },
  ADD_PAYMENT: {
    description: 'label for a button that adds a payment method to an order',
    primaryValue: 'Add Payment Method',
    stringKey: 'addPaymentMethod',
  },
  ADD_NEW_PAYMENT_METHOD: {
    description: 'label for the section that contains forms for adding a new payment method',
    primaryValue: 'Add New Payment Method',
    stringKey: 'addNewPaymentMethod',
  },
  SUBMIT_PAYMENT: {
    description: 'label for button to submit a payment for an order',
    primaryValue: 'Submit',
    stringKey: 'submitForm',
  },
  REFUND: {
    description: 'title for refunds section of payments tab',
    primaryValue: 'Refund',
    stringKey: 'refund',
  },
  REFUNDED_TO: {
    description: 'label for refunded to on payments tab ',
    primaryValue: 'Refunded to',
    stringKey: 'refundedTo',
  },
  NEW_GC: {
    description: 'label for refund to new gift card on payments tab',
    primaryValue: 'New Gift Card',
    stringKey: 'newGiftCard',
  },
  RFO_ORDER_NUMBER: {
    description: 'label for RFO order number on payments tab',
    primaryValue: 'RFO Order Number',
    stringKey: 'RFOOrderNumber',
  },
  OG_PAYMENT: {
    description: 'label for refund to original payment type on payments tab',
    primaryValue: 'Original Payment Method',
    stringKey: 'originalPaymentMethod',
  },
  SUBMIT_PAYMENT_ERROR: {
    description: 'user error message for Submit Payment',
    primaryValue: 'Error submitting payment:',
    stringKey: 'submitPaymentError',
  },
  SUBMIT_PAYMENT_SUCCESS: {
    description: 'user message when Submit Payment succeeds',
    primaryValue: 'Success submitting payment.  If order does not reload, refresh the window.',
    stringKey: 'submitPaymentSuccess',
  },
  ARIA_SUBMIT_PAYMENT_LOADING: {
    description: 'aria label for submit payment button spinner',
    primaryValue: 'submit payment loading',
    stringKey: 'submitPaymentLoading',
  },
  PAYMENT_DETAILS_ERROR: {
    description: 'user error message for Payment Details',
    primaryValue: 'Error fetching Payment Details:',
    stringKey: 'paymentDetailsError',
  },
  CARD_NUMBER: {
    description: 'label for the gc number input when checking the balance of a gift card.',
    primaryValue: 'Card Number',
    stringKey: 'cardNumber',
  },
  PIN: {
    description: 'label for the pin input when checking the balance of a gift card.',
    primaryValue: 'PIN',
    stringKey: 'pin',
  },
  CHECK_BALANCE: {
    description: 'button that, when clicked, checks the balance of a gift card',
    primaryValue: 'Check Balance',
    stringKey: 'checkBalance',
  },
  GIFT_CARD_NUMBER: {
    description: 'label for gift card number input.',
    primaryValue: 'Gift Card Number',
    stringKey: 'giftCardNumber',
  },
  PIN_NUMBER: {
    description: 'label for pin number input for a gift card.',
    primaryValue: 'PIN Number',
    stringKey: 'pinNumber',
  },
  FAILED_GIFT_CARD_ADD: {
    description:
      'error message displayed when request to add a gift card to an order as a form of payment fails',
    primaryValue: 'Failed to add gift card payment type',
    stringKey: 'failedGiftCardAdd',
  },
  SUCCESSFUL_GIFT_CARD_ADD: {
    description:
      'success message to display when a gift card is added to an order as a form of payment',
    primaryValue: 'Successfully added gift card payment type to order',
    stringKey: 'successfulGiftCardAdd',
  },
  CANCEL: {
    stringKey: 'cancel',
    primaryValue: 'Cancel',
    description: 'Label for the cancel button of the add new payment form.',
  },
  SUBMIT: {
    description: 'Label for a submit button on a form',
    primaryValue: 'Submit',
    stringKey: 'submitForm',
  },
  INVALID_GIFTCARD_NUMBER: {
    description: 'Error message to display when a gift card number is invalid.',
    primaryValue: 'Gift card number not valid',
    stringKey: 'invalidGiftCardNumber',
  },
  INVALID_PIN_NUMBER: {
    description: 'Error message to display when a pin number is invalid.',
    primaryValue: 'PIN number not valid',
    stringKey: 'invalidPinNumber',
  },
  NOT_VALID: {
    description: 'Error message to display when a gift card is not valid',
    primaryValue: 'Not Valid',
    stringKey: 'notValid',
  },
  BALANCE: {
    description: 'Label that will be displayed before the current balance of a gift card.',
    primaryValue: 'Balance',
    stringKey: 'balance',
  },
  CREDIT_CARD_NUMBER: {
    description: 'label for the credit card number input of a credit card',
    primaryValue: 'Credit Card Number',
    stringKey: 'creditCardNumber',
  },
  EXPIRATION_DATE: {
    description: 'label for a credit card expiration date',
    primaryValue: 'Expiration Date',
    stringKey: 'expirationDate',
  },
  INVALID_EXPIRATION_DATE: {
    description: 'label for a credit card expiration date being invalid',
    primaryValue: 'Expiration date not valid',
    stringKey: 'invalidExpirationDate',
  },
  SECURITY_CODE: {
    description: 'label for a credit card security code',
    primaryValue: 'Security Code/CVV',
    stringKey: 'securityCode',
  },
  AUTOMATIC_SUSPEND_WARNING: {
    description: 'warning when adding new payment method',
    primaryValue:
      'Attention: If there is already an active credit card on this order, adding a new one will automatically suspend it.',
    stringKey: 'automaticSuspendWarning',
  },
  CREDIT_CARD_PURPOSE_WARNING: {
    description: 'warning, informs athletes when a card should not be added',
    primaryValue: 'Remember, only add a new credit card for a charge, not a refund',
    stringKey: 'creditCardPurposeWarning',
  },
  INVALID_CREDIT_CARD: {
    description: 'error when the provided credit card data is not valid',
    primaryValue: 'Credit Card Info Is Invalid',
    stringKey: 'invalidCreditCard',
  },
  VALIDATE_CARD: {
    description:
      "label for button that takes in provided credit card info and determines if it's valid",
    primaryValue: 'Validate Card',
    stringKey: 'validateCard',
  },
  ADD_CREDIT_CARD_SUCCESS: {
    description: 'message when an athlete has successfully added a credit card to an order',
    primaryValue: 'Successfully added credit card to order',
    stringKey: 'addCreditCardSuccess',
  },
  ADD_CREDIT_CARD_FAILURE: {
    description: 'message when an athlete has met an error when adding a credit card to an order',
    primaryValue: 'Error adding credit card to order: ',
    stringKey: 'addCreditCardFailure',
  },
  EXPIRED_CREDIT_CARD: {
    description: 'message when an athlete has met an error when adding a credit card to an order',
    primaryValue: 'Credit Card Is Expired',
    stringKey: 'expiredCreditCard',
  },
  INVALID_CREDIT_CARD_NUMBER: {
    description: 'error message for an invalid credit card number',
    primaryValue: 'Card number not valid',
    stringKey: 'invalidCreditCardNumber',
  },
  COUNTRY: {
    description: 'label on the Address form for country',
    primaryValue: 'Country',
    stringKey: 'country',
  },
  ACTIVATE_PAYMENT_METHOD: {
    description: 'title for confirmation modal to activate payment method',
    primaryValue: 'Activate Payment Method',
    stringKey: 'activatePaymentMethod',
  },
  SUSPEND_PAYMENT_SUCCESS: {
    description: 'success message when payment method is successfully suspended',
    primaryValue: 'Payment method successfully suspended',
    stringKey: 'suspendPaymentSuccess',
  },
  SUSPEND_PAYMENT_ERROR: {
    description: 'error message when payment method fails to be suspended',
    primaryValue: 'Failed to suspend payment method',
    stringKey: 'suspendPaymentFailure',
  },
  YES: {
    description:
      'text to confirm that user would like to proceed or answer a question in the affirmative',
    primaryValue: 'Yes',
    stringKey: 'yes',
  },
  CONFIRM_ACTIVATING_CARD: {
    description:
      'dialog text for confirmation on activating a payment method; the action will suspend the current active payment method.',
    primaryValue:
      'Activating this card will automatically suspend the current active payment method, ending in',
    stringKey: 'confirmActivatePaymentMethod',
  },
  SURE_TO_CONTINUE: {
    description: 'text asking user if they are sure they want to continue with the current action',
    primaryValue: 'Are you sure you want to continue?',
    stringKey: 'sureToContinue',
  },
  NO_TRANSACTIONS: {
    description: 'title of section when no transactions are available',
    primaryValue: 'No Transactions',
    stringKey: 'noTransactions',
  },
  GIFTCARD_ZERO_BALANCE: {
    description: 'label to display on giftcard form when balance is zero.',
    primaryValue: 'Gift Card balance is zero',
    stringKey: 'giftcardZeroBalance',
  },
  CARD_NOT_VALID: {
    description:
      'error label to display when the card whose balance is being checked, is not valid.',
    primaryValue: 'Card not valid',
    stringKey: 'cardNotValid',
  },
  APPLY: {
    description: 'label for add payment dialog apply button',
    primaryValue: 'Apply',
    stringKey: 'apply',
  },
  FAILED_GIFT_CARD_BALANCE_QUERY: {
    description: 'Error message to display when retrieving the gift card balance returns an error',
    primaryValue: 'Failed to get gift card balance',
    stringKey: 'failedGiftCardBalanceQuery',
  },
  LEGACY_PAYMENTS_WITH_ORDER: {
    description: 'Message to display when legacy payments are being shown.',
    primaryValue: 'There are legacy payment(s) associated with this order. ',
    stringKey: 'legacyPaymentsWithOrder',
  },
  GO_TO_CSP_PAYMENTS_TAB: {
    description: 'Message to display that notifies the athlete where to navigate to.',
    primaryValue: 'Go to the CSP Payments tab to view.',
    stringKey: 'goToCspPaymentsTab',
  },
  ARIA_ORDER_SUMMARY_LOADING: {
    description:
      'aria label to be mentioned for screen readers when the order summary call is still loading.',
    primaryValue: 'Order Summary Loading',
    stringKey: 'ariaOrderSummaryLoading',
  },
};

export default translations;
