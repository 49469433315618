import gql from 'graphql-tag';

const SUBMIT_PAYMENT_MUTATION = gql`
  mutation postSubmitPaymentJob(
    $input: CSOrderModsCNV1_SubmitPaymentJobInput!
    $orderNumber: String!
    $timeout: Int!
  ) {
    submitPaymentJob(orderNumber: $orderNumber, input: $input, timeout: $timeout) {
      id
      eta
      status
      errors {
        code
        field
        message
      }
      message
    }
  }
`;

export default SUBMIT_PAYMENT_MUTATION;
