import gql from 'graphql-tag';

const RETURN_CAPTURE_MUTATION = gql`
  mutation postReturnCaptures($input: ReturnsCaptureInputV1!, $region: String, $timeout: Int!) {
    createReturn(input: $input, region: $region, timeout: $timeout) {
      jobId
      status
      response {
        returnTrackingNumber
        partnerReturnOrderNumber
        returnOrderNumber
      }
      error {
        httpStatus
        message
        code
        errorId
        errors {
          code
          field
          message
        }
      }
    }
  }
`;

export default RETURN_CAPTURE_MUTATION;
