import gql from 'graphql-tag';

const RECALCULATE_TAX_MUTATION = gql`
  mutation recalculateTax(
    $orderNumber: String!
    $recalculateTaxDetails: RecalculateTaxDetails!
    $timeout: Int!
  ) {
    recalculateTax(
      orderNumber: $orderNumber
      recalculateTaxDetails: $recalculateTaxDetails
      timeout: $timeout
    ) {
      id
      status
      resourceType
      message
      errors {
        field
        message
        code
      }
    }
  }
`;

export default RECALCULATE_TAX_MUTATION;
