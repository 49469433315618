/*
 This file determines which environment we're in, and provides the app with the appropriate config
 */
export const defaults = {
  axeEnv: false,
  imageBaseURI: 'http://images2.nike.com/is/image/DPILS/',
  customizationImageBaseURI: 'https://api.nike.com/customization/images/v1',
  promoCodeUrl: 'https://ui.csp-prod.nikecloud.com/portlets/promocode/implicit',
  paymentIframeHost: 'https://paymentcc.nike.com',
};

export const foundryRoutes = (uri) => ({
  uri: uri,
  activePermissions: uri + '/permissions/v1/activePermissions',
  allPermissions: uri + '/permissions/v1/allPermissions',
  allGroups: uri + '/permissions/v1/allGroups',
  editPermissions: uri + '/permissions/v1/editPermissions',
  athleteSettings: uri + '/settings/v1/athleteSettings',
  editSettings: uri + '/settings/v1/editSettings',
  modificationPermissions: uri + '/permissions/v1/hasModificationPermissions',
  graphqlProxy: uri + '/graphql',
});

export const dev = {
  axeEnv: true,
  foundry: foundryRoutes('http://localhost:9090'),
  ocoboUrl: 'http://localhost:3000',
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/',
  customizationImageBaseURI: 'https://api-test.nikecloud.com/customization/images/v1',
  // TODO: deprecating nikecloud.com host soon, member links will change to omobo.nike.com/portlets
  memberProfileUrl: 'https://omobo-test.nike.com/portlets/memberinfo',
  paymentIframeHost: 'https://any.edge.paymentcc.x.global.test.origins.nike',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const devCN = {
  axeEnv: true,
  foundry: foundryRoutes('http://localhost:9090'),
  ocoboUrl: 'http://localhost:3000/portlets/shop',
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://ui.c4c-csp-test.nikecloud.com.cn/portlets/giftcard/',
  customizationImageBaseURI: 'https://api-test.nike.com.cn/customization/images/v1',
  // TODO: went with working URL here, may need to update once app is live
  memberProfileUrl: 'https://csp-test.nike.com.cn/portlets/memberinfo',
  promoCodeUrl: 'https://csp.nike.com.cn/portlets/promocode',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  },
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
};

export const train_local = {
  foundry: foundryRoutes('http://localhost:9090'),
  mockUrl: 'http://localhost:8080/mockUrl/v1',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/details/',
  customizationImageBaseURI: 'https://api-test.nikecloud.com/customization/images/v1',
  // TODO: deprecating nikecloud.com host soon, member links will change to omobo.nike.com/portlets
  memberProfileUrl: 'https://omobo-test.nike.com/portlets/memberinfo',
  paymentIframeHost: 'https://any.edge.paymentcc.x.global.test.origins.nike',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const training = {
  foundry: foundryRoutes('https://foundry-test.nike.com'),
  mockUrl: 'https://mocks-ecs-us-west-2.forge-test.nikecloud.com/mockUrl/v1',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/',
  salesForceUrl: 'https://nikeconsumerservices--csstaging.sandbox.lightning.force.com',
  customizationImageBaseURI: 'https://api-test.nikecloud.com/customization/images/v1',
  // TODO: deprecating nikecloud.com host soon, member links will change to omobo.nike.com/portlets
  memberProfileUrl: 'https://omobo-test.nike.com/portlets/memberinfo',
  paymentIframeHost: 'https://any.edge.paymentcc.x.global.test.origins.nike',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const test = {
  // temporarily changing this to non-akamaized endpoint in an effort to ameliorate Japan UAT issue
  axeEnv: true,
  foundry: foundryRoutes('https://foundry-test.nike.com'),
  ocoboUrl: 'https://ocobo-test.nike.com',
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/',
  salesForceUrl: 'https://nikeconsumerservices--csstaging.sandbox.lightning.force.com',
  customizationImageBaseURI: 'https://api-test.nikecloud.com/customization/images/v1',
  memberProfileUrl: 'https://omobo-test.nike.com/portlets/memberinfo',
  paymentIframeHost: 'https://any.edge.paymentcc.x.global.test.origins.nike',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const testCN = {
  axeEnv: true,
  foundry: foundryRoutes('https://ui.c4c-csp-test.nikecloud.com.cn/foundry'),
  ocoboUrl: 'https://ui.c4c-csp-test.nikecloud.com.cn/portlets/shop',
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://ui.c4c-csp-test.nikecloud.com.cn/portlets/giftcard/',
  salesForceUrl: 'https://nikeconsumerservices--csstaging.sandbox.lightning.force.com',
  customizationImageBaseURI: 'https://api-test.nike.com.cn/customization/images/v1',
  // TODO: went with working URL here, may need to update once app is live
  memberProfileUrl: 'https://csp-test.nike.com.cn/portlets/memberinfo',
  promoCodeUrl: 'https://csp.nike.com.cn/portlets/promocode',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
};

export const dark = {
  foundry: foundryRoutes('https://foundry-dark.forge-prod.nikecloud.com'),
  cspUrl: 'https://csr-store.nike.com/',
  ocoboUrl: 'https://ocobo.nike.com',
  giftCardUrl: 'https://omobo.nike.com/portlets/giftcard/implicit/',
  salesForceUrl: 'https://nikeconsumerservices.lightning.force.com',
  memberProfileUrl: 'https://omobo.nike.com/portlets/memberinfo',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const prodCN = {
  foundry: foundryRoutes('https://csp.nike.com.cn/foundry'),
  cspUrl: 'https://csr-store.nike.com/',
  ocoboUrl: 'https://csp.nike.com.cn/portlets/shop',
  giftCardUrl: 'https://csp.nike.com.cn/portlets/giftcard',
  salesForceUrl: 'https://nikeconsumerservices.lightning.force.com',
  customizationImageBaseURI: 'https://api.nike.com.cn/customization/images/v1',
  // TODO: went with working URL here, may need to update once app is live
  memberProfileUrl: 'https://csp.nike.com.cn/portlets/memberinfo',
  promoCodeUrl: 'https://csp.nike.com.cn/portlets/promocode',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
  imageBaseURI: 'https://images.nike.com.cn/is/image/DPILS/',
};

export const prod = {
  foundry: foundryRoutes('https://foundry.nike.com'),
  cspUrl: 'https://csr-store.nike.com/',
  ocoboUrl: 'https://ocobo.nike.com',
  giftCardUrl: 'https://omobo.nike.com/portlets/giftcard/implicit/',
  salesForceUrl: 'https://nikeconsumerservices.lightning.force.com',
  customizationImageBaseURI: 'https://api.nike.com/customization/images/v1',
  memberProfileUrl: 'https://omobo.nike.com/portlets/memberinfo',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    scopes: [
      'offline_access',
      'openid',
      'email',
      'profile',
      'consumer_profile:l7r.token_exchange::create:',
    ],
  },
};

export const okta = {
  clientId: 'nike.serve.omobo',
  issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
};

const config = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return dev;
    case 'devCN':
      return devCN;
    case 'training':
      return training;
    case 'train_local':
      return train_local;
    case 'test':
      return test;
    case 'testCN':
      return testCN;
    case 'dark':
      return dark;
    case 'prodCN':
      return prodCN;
    default:
      return prod;
  }
};

const configObj = {
  ...defaults,
  ...config(),
};

if (window.Cypress) {
  configObj.okta = okta;
}

export default configObj;
