/**
 * Array of orderLine.lineCharges.chargeCategory vals that are DISCOUNTs
 * all other chargeCategories values are CHARGES
 */
export const discounts = [
  'COD_DISCOUNT',
  'DISCOUNT',
  'EMPLOYEE',
  'INTSERVFEE_DISCOUNT',
  'ITEM',
  'POVR_DISCOUNT',
  'PRICE',
  'PROMO',
  'SHIPPING_DISCOUNT',
  'TAXREFUND',
  'TRAN',
  'VAS_DISCOUNT',
  'VAS_PRINT_DISCOUNT',
];
