import EUCountries from './../constants/address/EUCountries.const';
import OtherCountries from './../constants/address/OtherCountries.const';

/**
 * Not all services return addresses formatted the same way. Some use a zipCode field while
 * others use a postalCode field, and some send ISO Alpha 2 values for country, while others
 * send Alpha 3 values. This function takes an address and formats it consistently so that
 * differences don't have to be accounted for in other areas of the code base.
 *
 * The best place to call this function is as soon as possible after receiving a payload from
 * an API. In the case of Order Detail addresses, this would be in the orderDetailDecoration
 * file.
 *
 * @param address {object} - The Address to format
 */
export const formatAddress = (address) => {
  if (!address) {
    return;
  }

  /*
  Some services send addresses with a zipCode field, while others send addresses with a postalCode
  field. This can lead to all sorts of bugs and confusion. Rather than accounting for differently
  named fields for zipCode/postalCode address values across the code base, we'll change zipCode to
  postalCode for consistency. (The term "Zip Code" is used mainly in the US and the Philippines,
  while the term "Postal Code" is used and recognized more globally.)
   */
  if (address.zipCode && !address.postalCode) {
    address.postalCode = address.zipCode;
    delete address.zipCode;
  }

  /*
  Some services send the ISO Alpha 2 code for country, while others send the ISO Alpha 3 code. This
  can also lead to bugs and confusion. Rather than accounting for different country code standards
  throughout the code base, we'll convert all country values to the ISO Alpha 2 code ("abbreviation"
  in the country files). While we're at it, we'll also check for the full country name being used.
   */
  if (address.country?.length >= 3) {
    // Grab all the countries, both EU and other.
    const countryList = EUCountries.concat(OtherCountries);
    // Determine whether to look up by ISO Alpha 3, or by name, and retrieve the country.
    const countryField = address.country.length === 3 ? 'alpha3Code' : 'name';
    const country = countryList.find((country) => country[countryField] === address.country);
    // Only change the value of country if we successfully retrieved it from the list.
    if (country) address.country = country.abbreviation;
  }
};
