/** React/Utils */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

/** Material UI */
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';

/** Local */

import IDENTITY_USER_QUERY from '../../../../mutations/identityUser.mutation';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import CUSTOMER_PROFILE_MUTATION from '../../../../mutations/postCustomerProfile.mutation';
import { ApiTimeOut } from '../../../../constants/dialog.const';
import useSnacks from '../../../../hooks/useSnacks';
import translation from './linkMemberDialog.i18n';

/**
 * Component that displays modal window with linking customer profile functionality
 * Include getting user profile details by entering upm id and linking profile functionality
 */
const LinkMemberProfileModal = ({ isOpen, handleClose, orderNumber, onSuccess }) => {
  const {
    DIALOG_TITLE,
    DESCRIPTION,
    SEARCH_CAPTION,
    SEARCH_RESULTS_HEADER,
    SEARCH_RESULTS_ARIA_LABEL,
    CANCEL_BUTTON_CAPTION,
    LINK_CUSTOMER_ERROR,
    LINK_CUSTOMER_SUCCESS,
    SUBMIT_BUTTON_CAPTION,
    USER_NOT_FOUND,
  } = useMemoTranslations(translation);
  const classes = useStyles();
  const { setSnack, setError } = useSnacks();

  const [upmId, setUpmId] = useState('');
  const [searchError, setSearchError] = useState(null);

  // Mutation for get information about identity user
  const [getIdentityUser, { loading: dataLoading, data: profileResponse }] = useMutation(
    IDENTITY_USER_QUERY,
    {
      onCompleted: (idnData) => {
        setSearchError(null);
      },
      onError: (err) => {
        if (err.message && err.message.includes('error: 404')) {
          setSearchError(USER_NOT_FOUND);
        }
      },
    }
  );
  // Mutation that calls link/unlink member profile
  const [postCustomerProfile, { loading: postCustomerProfileLoading }] = useMutation(
    CUSTOMER_PROFILE_MUTATION,
    {
      onError: (err) => {
        setError(`${LINK_CUSTOMER_ERROR} ${err.message}`);
      },
      onCompleted: (response) => {
        setSnack(`${LINK_CUSTOMER_SUCCESS}`);
        onSuccess();
      },
    }
  );

  const handlePostCustomerProfile = () => {
    postCustomerProfile({
      variables: {
        input: {
          requestId: `${uuidv4()}`,
          customerProfileReference: profileResponse.identityUser.upmId,
        },
        orderNumber: orderNumber,
        timeout: ApiTimeOut,
      },
    });
  };
  const changeUpmId = (e) => {
    setUpmId(e.target.value);
  };

  const getuserInfo = () => {
    getIdentityUser({
      variables: {
        id: upmId,
        input: {
          fields: ['name.latin.given', 'name.latin.family', 'contact.email.address', 'upmId'],
        },
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{DESCRIPTION}</DialogContentText>
        <Box display='flex' justifyContent='space-between'>
          <TextField
            value={upmId}
            onChange={changeUpmId}
            data-testid='link-profile-upmid'
            id='name'
            className={classes.textField}
            fullWidth
            error={searchError}
          />
          <Button
            variant='contained'
            color='primary'
            size='small'
            data-testid='find-user-by-upm-id'
            onClick={getuserInfo}
            disabled={!upmId || dataLoading}>
            {SEARCH_CAPTION}
          </Button>
        </Box>
        {profileResponse && !dataLoading && (
          <Box className={classes.profileDetailsWrapper}>
            <Typography variant='h5' className={classes.addressTypography}>
              {SEARCH_RESULTS_HEADER}
            </Typography>
            <Typography
              aria-label={SEARCH_RESULTS_ARIA_LABEL}
              className={classes.addressTypography}>
              {`${profileResponse?.identityUser?.upmId}
                ${profileResponse?.identityUser?.name?.latin?.given}
                ${profileResponse?.identityUser?.name?.latin?.family}
                ${profileResponse?.identityUser?.contact?.email?.address}
              `}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePostCustomerProfile}
          color='primary'
          data-testid='submit-link-user'
          disabled={postCustomerProfileLoading || !profileResponse?.identityUser?.upmId}>
          {SUBMIT_BUTTON_CAPTION}
        </Button>
        <Button onClick={handleClose} color='secondary' data-testid='cancel-submit-link-user'>
          {CANCEL_BUTTON_CAPTION}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LinkMemberProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  orderNumber: PropTypes.string,
  onSuccess: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: '8px',
  },
  addressTypography: {
    whiteSpace: 'pre-line',
    lineHeight: 2,
  },
  profileDetailsWrapper: {
    marginTop: 24,
  },
}));
export default LinkMemberProfileModal;
