import { actionTypes } from '../actions/adminActions';
import { defaultState } from '../contexts/adminContext';

/**
 * The reducer for the admin context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const adminReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_GROUPS: {
      return {
        ...state,
        groups: action.groups,
      };
    }
    case actionTypes.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.permissions,
      };
    }
    default: {
      return state;
    }
  }
};
