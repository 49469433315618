import gql from 'graphql-tag';

const CANCEL_LINE_ITEM = gql`
  mutation postCancelLineItem(
    $orderNumber: String!
    $input: CSOrderModsV1_CancelLineItemInputV1!
    $region: String
    $timeout: Int!
  ) {
    cancelLineItem(orderNumber: $orderNumber, input: $input, region: $region, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
    }
  }
`;

export default CANCEL_LINE_ITEM;
