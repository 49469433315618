import React, { createContext, useReducer } from 'react';
import { pidReviewSearchReducer } from '../reducers/pidReviewSearchReducer';

export const PidReviewSearchContext = createContext({});
const { Provider } = PidReviewSearchContext;

export const defaultState = {
  pidSearchFormState: {
    'businessUnit': '',
    'country': '',
    'orderNumber': '',
    'orderLines.customizedProductReference': '',
    'billTo.contactInformation.email': '',
    'paymentMethods.displayCardNumber': '',
    'paymentMethods.paymentType': '',
    'billingName': '',
    'shippingName': '',
  },
  searchResults: {
    totals: {
      reviewable: 0,
      pending: 0,
      held: 0,
    },
    data: [],
  },
  page: 1,
};

/**
 * Provider for search results and search fields
 *
 * @param {Object} props – React props
 */
export const PidSearchResultsProvider = ({ children }) => {
  const [state, reducer] = useReducer(pidReviewSearchReducer, defaultState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
