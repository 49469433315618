import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';

/** Local */
import translations from './pidReview.i18n';
import useMemoTranslations from '../../hooks/useMemoTranslations';
import config from '../../utils/config';

const IMAGE_WIDTH = 150;
const FULLSCREEN_IMAGE_WIDTH = 800;

const getApiUrl = (metricId, viewNumber, width = IMAGE_WIDTH) =>
  `${config.customizationImageBaseURI}/mtr-${metricId}/vw-${viewNumber}/wid-${width}/.png`;

const MAX_IMAGES_COUNT = 6;

const getInitialImagesArray = () => {
  const imagesArray = [];
  for (let i = 0; i < MAX_IMAGES_COUNT; i++) {
    // viewId is necessary to delete items that failed to load
    imagesArray.push({ loading: true, src: null, viewId: i + 1 });
  }
  return imagesArray;
};

/**
 * TODO: As a part of PiD optimization process we may want
 * to move this component to <PidSearchResults/> component,
 * We might also want to check whether that improves performance first
 */

/**
 * @param {Boolean} open - whether a Popover should be open
 * @param {React.Ref} anchorRef - an element that the Popover will appear nearby
 * @param {function} onClose - a function that would set `open` prop to `false`
 * @param {string} metricId - an id of an item to fetch images for
 */
const PidImagesPopover = ({ open, anchorRef, onClose, metricId }) => {
  const imagesSetRef = useRef(new Set());
  const [images, setImages] = useState(getInitialImagesArray);

  const classes = useStyles();
  const { PID_IMAGE, NO_IMAGES_FOUND, METRIC_IMAGES } = useMemoTranslations(translations);

  /**
   * This huge effect handles loading images in such a way that we don't get any duplicates
   * The issue with customization images API is that we don't know in advance how many images
   * it can serve: when it's out of images, it'll just keep sending the first one.
   * Here we convert every image to a blob and add it to a set and thus exclude duplicates
   * if there are less than MAX_IMAGES_COUNT images available (which is the expected amount)
   */
  useEffect(() => {
    // don't fetch images if the popover is closed or all the images already loaded
    if (!open || images.every((image) => image.loading === false)) return;

    for (let i = 0; i < MAX_IMAGES_COUNT; i++) {
      const viewId = i + 1;
      fetch(getApiUrl(metricId, viewId))
        .then((response) => response.blob())
        // convert the blob to a dataURL
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
          });
        })
        // check for duplicates and handle the converted object
        .then((data64Image) => {
          const imagesInSetBefore = imagesSetRef.current.size;
          imagesSetRef.current.add(data64Image);
          const imagesInSetAfter = imagesSetRef.current.size;

          // this will be handled in `.catch`
          if (imagesInSetAfter === imagesInSetBefore) throw new Error('Image is a duplicate');
          setImages((prevImagesArray) => {
            const loadedImageIndex = prevImagesArray.findIndex((image) => image.viewId === viewId);
            if (loadedImageIndex === -1) return prevImagesArray;
            return [
              ...prevImagesArray.slice(0, loadedImageIndex),
              { loading: false, src: data64Image, viewId },
              ...prevImagesArray.slice(loadedImageIndex + 1),
            ];
          });
        })
        // if either reading the blob failed or an item is a duplicate, we remove the item
        .catch(() => {
          setImages((prevImagesArray) => {
            const duplicateImageIndex = prevImagesArray.findIndex(
              (image) => image.viewId === viewId
            );
            if (duplicateImageIndex === -1) return prevImagesArray;
            return [
              ...prevImagesArray.slice(0, duplicateImageIndex),
              ...prevImagesArray.slice(duplicateImageIndex + 1),
            ];
          });
        });
    }
  }, [open]);

  const imagesFound = images.length > 0;

  return (
    <Popover
      open={open}
      anchorEl={anchorRef?.current}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      PaperProps={{ className: classes.popover }}>
      <div className={classes.header}>
        <Typography
          className={classes.heading}
          variant='overline'
          color='textSecondary'
          component='h2'>
          {METRIC_IMAGES}
        </Typography>
        <Button onClick={onClose}>
          <CloseIcon color='action' />
        </Button>
      </div>
      <div className={classes.imagesContainer}>
        {imagesFound &&
          images.map(({ loading, src, viewId }, i) => {
            if (loading)
              return (
                <div className={classes.placeholder} key={viewId || i}>
                  <CircularProgress />
                </div>
              );
            else
              return (
                <a
                  href={getApiUrl(metricId, viewId, FULLSCREEN_IMAGE_WIDTH)}
                  target='_blank'
                  rel='noopener noreferrer'
                  key={viewId}
                  className={classes.link}>
                  <img
                    data-testid={`pid-image-${viewId}`}
                    src={src}
                    alt={`${PID_IMAGE} ${viewId}`}
                    className={classes.img}
                  />
                </a>
              );
          })}
        {!imagesFound && <Typography variant='overline'>{NO_IMAGES_FOUND}</Typography>}
      </div>
    </Popover>
  );
};

PidImagesPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  metricId: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  popover: { padding: '15px 25px 25px 25px' },
  placeholder: {
    display: 'grid',
    placeItems: 'center',
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  heading: { lineHeight: '100%' },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 'calc(100vh) - 100px',
    gap: 10,
  },
  link: {
    'boxShadow': '3px 3px 7px 1px rgba(0, 0, 0, 0)',
    'transition': 'all 0.15s ease-in-out',
    'borderRadius': 5,
    '&:hover': {
      boxShadow: '3px 3px 7px 1px rgba(0, 0, 0, 0.40)',
      transform: 'scale(1.035)',
    },
  },
  img: {
    width: IMAGE_WIDTH,
    aspectRatio: '1/1',
    objectFit: 'cover',
  },
}));

export default PidImagesPopover;
