import gql from 'graphql-tag';

const ACTIVATE_PAYMENT_MUTATION_V2 = gql`
  mutation activatePaymentMethodV2($input: PaymentStatusModInput!, $timeout: Int!) {
    activatePaymentMethodV2(input: $input, timeout: $timeout) {
      id
      eta
      status
      error {
        httpStatus
        code
        message
        errors {
          code
          field
          message
        }
      }
    }
  }
`;

export default ACTIVATE_PAYMENT_MUTATION_V2;
