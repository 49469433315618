import React, { useContext } from 'react';
import { mapValues } from 'lodash';
import { NikeI18nContext } from '@nike/i18n-react';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import translations from './pidReview.i18n';
import { RegionToCountryMap } from '../../constants/geos.const';
import searchActions from './../../store/actions/pidReviewSearchActions';
import { PidReviewSearchContext } from '../../store/contexts/pidReviewSearchContext';
import PidSearchResults from './pidSearchResults';
import useGetPids from '../../hooks/useGetPids';
import { setPidRegion } from '../../utils/browserStorage';

/**
 * Component to display PiD search form and results.
 */
const PidSearchForm = () => {
  const { i18nString } = useContext(NikeI18nContext);

  const {
    PID_REVIEWS_SEARCH,
    REGION,
    SEARCH_COUNTRY,
    METRIC_ID,
    BILLING_NAME,
    SHIPPING_NAME,
    LAST,
    FIRST,
    SEARCH_EMAIL,
    SEARCH_GIFT_CARD,
    FIND,
    ORDER_NUMBER,
  } = mapValues(translations, i18nString);
  const [searchState, searchDispatch] = useContext(PidReviewSearchContext);
  const { setSearchFieldValue } = searchActions;
  const pidSearchFormState = React.useMemo(() => searchState.pidSearchFormState, [
    searchState.pidSearchFormState,
  ]);

  const { runQueryPidSearch } = useGetPids();

  const classes = useStyles();

  // Update individual form values when onChange event fires by dispatching to context
  const updateFormValue = (e) => {
    searchDispatch(
      setSearchFieldValue({
        key: e.target.name,
        value: e.target.value,
      })
    );
    /*
      If user enters value for gift card number, we set paymentType to 'GIFT_CERTIFICATE',
      otherwise we leave it blank.
    */
    if (e.target.name === 'paymentMethods.displayCardNumber') {
      searchDispatch(
        setSearchFieldValue({
          key: 'paymentMethods.paymentType',
          value: e.target.value ? 'GIFT_CERTIFICATE' : '',
        })
      );
    }
    if (e.target.name === 'businessUnit' && pidSearchFormState.country) {
      searchDispatch(
        setSearchFieldValue({
          key: 'country',
          value: '',
        })
      );
    }
    // set pid region to local storage for dockyard logging
    if (e.target.name === 'businessUnit') {
      setPidRegion(e.target.value);
    }
  };

  // Prepare filters and fire the pidSearch query
  const onFormSubmit = React.useCallback(
    (e) => {
      if (e.key === 'Enter' || !e.key) {
        runQueryPidSearch();
      }
    },
    [pidSearchFormState]
  );

  const isOrderNumberDisabled = React.useMemo(
    () =>
      Boolean(
        pidSearchFormState['billTo.contactInformation.email'] ||
          pidSearchFormState['paymentMethods.displayCardNumber']
      ),
    [pidSearchFormState]
  );
  const isEmailDisabled = React.useMemo(
    () =>
      Boolean(
        pidSearchFormState.orderNumber || pidSearchFormState['paymentMethods.displayCardNumber']
      ),
    [pidSearchFormState]
  );
  const isGiftCardDisabled = React.useMemo(
    () =>
      Boolean(
        pidSearchFormState['billTo.contactInformation.email'] || pidSearchFormState.orderNumber
      ),
    [pidSearchFormState]
  );

  return (
    <>
      <Paper elevation={1} className={classes.searchFormPaper}>
        <form noValidate id='pidreview-search-form' autoComplete='off'>
          <Typography className={classes.searchFormHeading} variant='h2'>
            {PID_REVIEWS_SEARCH}
          </Typography>
          <TextField
            className={classes.searchFields}
            id='region'
            name='businessUnit'
            value={pidSearchFormState.businessUnit}
            label={REGION}
            data-testid={`pid-search-region`}
            onKeyPress={onFormSubmit}
            select
            onChange={updateFormValue}>
            {process.env.PUBLIC_URL !== '/portlets/postpurchase' ? (
              Object.keys(RegionToCountryMap).map((region, i) => (
                <MenuItem key={`${region}-${i}`} value={region}>
                  {region}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={'NIKECN'} value={'NIKECN'}>
                NIKECN
              </MenuItem>
            )}
          </TextField>
          <TextField
            className={classes.searchFields}
            id='country'
            name='country'
            data-testid={'pid-search-country'}
            value={pidSearchFormState.country}
            label={SEARCH_COUNTRY}
            onKeyPress={onFormSubmit}
            select
            onChange={updateFormValue}>
            {Object.entries(
              RegionToCountryMap[pidSearchFormState.businessUnit] || RegionToCountryMap.ALL
            ).map(([countryName, countryCode], i) => (
              <MenuItem key={`${countryCode}-${i}`} value={countryCode}>
                {countryName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.searchFields}
            id='order-number'
            name='orderNumber'
            data-testid={'pid-search-orderNumber'}
            value={pidSearchFormState.orderNumber}
            label={ORDER_NUMBER}
            onKeyPress={onFormSubmit}
            disabled={isOrderNumberDisabled}
            onChange={updateFormValue}
          />
          <TextField
            className={classes.searchFields}
            id='metricId'
            name='orderLines.customizedProductReference'
            data-testid={'pid-search-metricId'}
            value={pidSearchFormState['orderLines.customizedProductReference']}
            label={METRIC_ID}
            onKeyPress={onFormSubmit}
            onChange={updateFormValue}
          />
          <TextField
            className={classes.searchFields}
            id='email'
            name='billTo.contactInformation.email'
            data-testid={'pid-search-email'}
            value={pidSearchFormState['billTo.contactInformation.email']}
            label={SEARCH_EMAIL}
            onKeyPress={onFormSubmit}
            disabled={isEmailDisabled}
            onChange={updateFormValue}
          />
          <TextField
            className={classes.searchFields}
            id='giftCard'
            name='paymentMethods.displayCardNumber'
            data-testid={'pid-search-giftCard'}
            value={pidSearchFormState['paymentMethods.displayCardNumber']}
            label={SEARCH_GIFT_CARD}
            onKeyPress={onFormSubmit}
            disabled={isGiftCardDisabled}
            onChange={updateFormValue}
          />
          <TextField
            className={classes.searchFields}
            id='billingName'
            name='billingName'
            data-testid={'pid-search-billingName'}
            value={pidSearchFormState.billingName}
            label={BILLING_NAME}
            onKeyPress={onFormSubmit}
            onChange={updateFormValue}
            placeholder={`${LAST},${FIRST}`}
          />
          <TextField
            className={classes.searchFields}
            id='shippingName'
            name='shippingName'
            data-testid={'pid-search-shippingName'}
            value={pidSearchFormState.shippingName}
            label={SHIPPING_NAME}
            onKeyPress={onFormSubmit}
            onChange={updateFormValue}
            placeholder={`${LAST},${FIRST}`}
          />
          <Box className={classes.cta}>
            <Button
              variant='contained'
              color='primary'
              data-testid='pid-search-button'
              className={classes.searchButton}
              onClick={onFormSubmit}>
              {FIND}
            </Button>
          </Box>
        </form>
      </Paper>
      {searchState.searchResults && <PidSearchResults />}
    </>
  );
};

/**
 * CSS in JS for the screen elements.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    padding: theme.spacing(2),
    display: 'flex',
  },
  cta: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  searchButton: {
    minWidth: '6.5rem',
    marginTop: '2rem',
  },
  searchFormPaper: {
    margin: theme.spacing(1),
    width: '20vw',
    minWidth: '215px',
    height: 'max-content',
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },
  searchFormHeading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.75,
  },
  searchFields: {
    width: '100%',
  },
  nameTypeRadioGroup: {
    color: theme.palette.common.black,
    marginTop: '1rem',
  },
}));

export default PidSearchForm;
