const translations = {
  ARIA_CANCEL_REASON: {
    description: 'aria label for select cancel reason',
    primaryValue: 'select cancel reason',
    stringKey: 'ariaCancelReason',
  },
  ADD_ITEM_DETAILS: {
    description: 'label on the Cancel Items Dialog for "Add Item Details"',
    primaryValue: 'Add Item Details',
    stringKey: 'addItemDetails',
  },
  ADD_RETURNEE_DETAILS: {
    description: 'label on the Cancel Items Dialog for "Add Returnee Details"',
    primaryValue: 'Add Returnee Details',
    stringKey: 'addReturneeDetails',
  },
  EXIT: {
    description: 'label on the Cancel Items Dialog for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  CANCEL_ITEMS: {
    description: 'label on the Cancel Items Dialog for "Cancel Items"',
    primaryValue: 'Cancel Items',
    stringKey: 'cancelItems',
  },
  SELECT_ITEMS_TO_CANCEL: {
    description: 'label on the Cancel Items Dialog for "Select Items to Cancel"',
    primaryValue: 'Select Items to Cancel',
    stringKey: 'selectItemsToCancel',
  },
  SELECT_QUANTITY: {
    description: 'label on the dropdown to select quantity',
    primaryValue: 'Select Quantity',
    stringKey: 'selectQuantity',
  },
  BACK: {
    stringKey: 'back',
    primaryValue: 'Back',
    description: 'label on the returns wizard for "Back"',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'label on the returns wizard for "Next"',
  },
  ONLY_1_AVAILABLE: {
    description: 'label on the cancel items dialog for "Only 1 Available"',
    primaryValue: 'Only 1 Available',
    stringKey: 'only1Available',
  },
  QUANTITY: {
    description: 'label on the cancel items dialog for "Quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  ARIA_REASON_CODES_LOADING: {
    description: 'aria label for reason code loading spinner',
    primaryValue: 'reason codes loading',
    stringKey: 'ariaReasonCodes',
  },
  REASON_FOR_CANCEL: {
    description: 'label on the cancel items dialog for "Reason for Cancel"',
    primaryValue: 'Reason for Cancellation',
    stringKey: 'reasonForCancel',
  },
  REASON_UNAVAILABLE: {
    description: 'error if reason code service unavailable',
    primaryValue: 'Reason codes unavailable',
    stringKey: 'reasonUnavailable',
  },
  CLOSE: {
    stringKey: 'close',
    primaryValue: 'Close',
    description: 'label to close Cancel Items Dialog',
  },
  ITEM_CANCELLATION: {
    description: 'cancel action type in submission response message',
    primaryValue: 'Item cancellation',
    stringKey: 'itemCancellation',
  },
  REQUEST_SUBMITTED: {
    description: 'request submitted label',
    primaryValue: 'request submitted.',
    stringKey: 'requestSubmitted',
  },
  ERROR: {
    description: 'label for alerting the user that an action was unsuccessful',
    primaryValue: 'unsuccessful. Please try again!',
    stringKey: 'dialogError',
  },
  FILE_INCIDENT_TICKET: {
    description: 'label to tell the athlete to file a ticket.',
    primaryValue: 'If the error continues, please log an incident ticket.',
    stringKey: 'fileIncidentTicket',
  },
  SUCCESS: {
    description: 'label for alerting the user that an action was successful',
    primaryValue: 'successful!',
    stringKey: 'dialogSuccess',
  },
  CANCEL_ITEMS_TIME_OUT_ERROR_MESSAGE: {
    stringKey: 'cancelItemsTimeoutErrorMessage',
    primaryValue:
      'Cancel is still pending. Please return to SwooshDesk and check back after some time',
    description: 'Error message when cancellation call times out',
  },
  ITEM_NOT_CANCELLABLE: {
    stringKey: 'itemNotCancellable',
    primaryValue: 'Item is not cancellable',
    description: 'Tool tip message to display when an item is not cancellable.',
  },
  VAS_NOT_CANCELLABLE: {
    stringKey: 'vasNotCancellable',
    primaryValue: 'VAS cannot be cancelled',
    description: 'Tool tip message to display when a vas item is not cancellable.',
  },
  BOPIS_CANCEL_ALL: {
    stringKey: 'bopisCancelAll',
    primaryValue: 'For BOPIS, all items in the order must be cancelled.',
    description: 'Message for cancelling BOPIS Orders',
  },
  SIZE: {
    description: 'label on the size',
    primaryValue: 'Size',
    stringKey: 'size',
  },
};

export default translations;
