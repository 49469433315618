const translations = {
  ARIA_EXPANDED_ITEM_DETAIL: {
    description: 'aria label on expanded item',
    primaryValue: 'expanded item details',
    stringKey: 'ariaExpandedItemDetail',
  },
  ARIA_REASON_CODES_LOADING: {
    description: 'aria label for reason code loading spinner',
    primaryValue: 'reason codes loading',
    stringKey: 'ariaReasonCodes',
  },
  CANCEL_REASON: {
    description: 'label on Details page for section with reason for an items cancellation',
    primaryValue: 'Cancel Reason',
    stringKey: 'cancelReason',
  },
  CANCEL_REQUESTED: {
    description:
      'label on the details page that identifies how many cancel request were made for an item.',
    primaryValue: 'Cancel Requested',
    stringKey: 'cancelRequested',
  },
  EDD: {
    description: 'label on Details page for Estimated Delivery Date or "edd',
    primaryValue: 'EDD',
    stringKey: 'edd',
  },
  FULFILLER: {
    description: 'label on Details page for Fulfiller',
    primaryValue: 'Fulfiller',
    stringKey: 'fulfiller',
  },
  HIDE_DETAIL: {
    description: 'label on collapse button for each individual item on the item details page',
    primaryValue: 'Hide Detail',
    stringKey: 'hideDetail',
  },
  HOLD: {
    description: 'label for order holds',
    primaryValue: 'Hold',
    stringKey: 'hold',
  },
  INSPECTION_REASON: {
    description: 'label on Details page for inspection reason',
    primaryValue: 'Inspection Reason',
    stringKey: 'inspectionReasonLabel',
  },
  LINE_TOTAL: {
    description: 'label on Details page for "line total"',
    primaryValue: 'Line Total',
    stringKey: 'lineTotal',
  },
  LIST_PRICE: {
    description: 'label on Details page for "list price"',
    primaryValue: 'List Price',
    stringKey: 'listPrice',
  },
  PICKUP_POINTS_ERROR: {
    description: 'error message for pickup point service err',
    primaryValue: 'Error fetching pickup point details',
    stringKey: 'pickupPointError',
  },
  QUANTITY: {
    description: 'label on Details page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  RETURN_REASON: {
    description: 'label on Details page for return reason',
    primaryValue: 'Return Reason',
    stringKey: 'returnReason',
  },
  SEE_DETAIL: {
    description: 'label on expand button for each individual item on the item details page',
    primaryValue: 'See Detail',
    stringKey: 'seeDetail',
  },
  SIZE: {
    description: 'label on Details page for "size"',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  STATUS: {
    description: 'label on Details page for "status"',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  STORE_ERROR: {
    description: 'error message for store service error',
    primaryValue: 'Error fetching store details',
    stringKey: 'storeError',
  },
  STORE_INFO_NOT_AVAILABLE: {
    description: 'display message when store refund info is not available',
    primaryValue: 'Store Refund details are not available.',
    stringKey: 'storeInfoNotAvailable',
  },
  STYLE_NAME: {
    description: 'label on Details page for "style name"',
    primaryValue: 'Style Name',
    stringKey: 'styleName',
  },
  STYLE_COLOR: {
    description: 'label on Details page for "style color"',
    primaryValue: 'Style-Color',
    stringKey: 'styleColor',
  },
  TO_GIFT_CARD_APP: {
    description: 'link to gift card app',
    primaryValue: 'link to gift card app',
    stringKey: 'toGiftCardApp',
  },
  RETURNED_AT_STORE_NUMBER: {
    description: 'number of the store an item was returned at',
    primaryValue: 'Return Store Number',
    stringKey: 'returnedAtStoreNumber',
  },
  RETURN_DATE: {
    description: 'number of the store an item was returned at',
    primaryValue: 'Return Date',
    stringKey: 'returnDate',
  },
  TRANSACTION_ID: {
    description: 'id number for transaction',
    primaryValue: 'Transaction ID',
    stringKey: 'transactionId',
  },
  REFUND_PAYMENT_TYPE: {
    description: 'number of the store an item was returned at',
    primaryValue: 'Refund Payment Type',
    stringKey: 'refundPaymentType',
  },
  PENDING_CANCEL: {
    description: 'label on tooltip for pending cancel icon',
    primaryValue: 'Cancel pending',
    stringKey: 'cancelPending',
  },
  UNIT_PRICE: {
    description: 'label on Details page for "unit price"',
    primaryValue: 'Unit Price',
    stringKey: 'unitPrice',
  },
  SDD: {
    description: 'label on Details page for Scheduled Delivery Date or "edd"',
    primaryValue: 'SDD',
    stringKey: 'SDD',
  },
};

export default translations;
