const translations = {
  ERROR_HEADER: {
    description: 'a default header for error boundaries',
    primaryValue: 'Something Broke!',
    stringKey: 'errorHeader',
  },
  ERROR_PREAMBLE: {
    description: 'a short description of what to do in the case of an error',
    primaryValue: 'Try reloading the page. If the problem persists, let us know.',
    stringKey: 'errorPreamble',
  },
};

export default translations;
