import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const LabelValueText = ({ label = '', value = '', style }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.text} style={style}>
      {label && <span className={classes.label}>{label}</span>}
      {value && <span className={classes.value}>{value}</span>}
    </Typography>
  );
};

export default LabelValueText;

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
    fontWeight: 400,
  },
  text: {
    fontSize: '16px',
    margin: '0',
    lineHeight: '24px',
  },
  value: {
    fontWeight: 500,
  },
}));
