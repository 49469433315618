const translations = {
  DIALOG_TITLE: {
    description: 'Title of Close Transaction dialog',
    primaryValue: 'Close transaction',
    stringKey: 'close_transaction_dialog_title',
  },
  DESCRIPTION: {
    description: 'Description of Close Transaction dialog',
    primaryValue: 'Are you sure you want to close this transaction? This action can not be undone',
    stringKey: 'close_transaction_dialog_description',
  },
  CLOSE_BUTTON_CAPTION: {
    description: '"Close transactionn" dialog button caption',
    primaryValue: 'Close transaction',
    stringKey: 'close_transaction_dialog_accept_button_caption',
  },
  CANCEL_BUTTON_CAPTION: {
    description: 'Close transactionn dialog of Cancel button',
    primaryValue: 'Cancel',
    stringKey: 'close_transaction_dialog_close_button_caption',
  },
  CLOSE_TRANSACTION_ERROR: {
    description: 'Error displayed if transaction was not canceled',
    primaryValue: 'Transaction was not canceled: ',
    stringKey: 'close_transaction_error_response',
  },
  CLOSE_TRANSACTION_SUCCESS: {
    description: 'Success message displayed if transaction was closed',
    primaryValue: 'Transaction succesfully closed',
    stringKey: 'close_transaction_success_response',
  },
};

export default translations;
