import React from 'react';
import PropTypes from 'prop-types';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PaymentIcon from '@material-ui/icons/Payment';
import Typography from '@material-ui/core/Typography';

import config from '../../../../../utils/config';
import translations from './paymentMethodStatus.i18n';
import useHasPermission from '../../../../../hooks/useHasPermission';
import { ViewFullGiftCardNumber } from '../../../../../constants/permissions.const';
import { PaymentServiceMethodsDict } from '../../../../../constants/paymentTypes.const';
import NewTabButton from '../../../../shared/newTabButton';
import { sha256 } from 'js-sha256';

/**
 * Payment Method Status component
 * This component is responsible for displaying critical payment method details
 * (e.g. CC/GC number, expiration dates) alongside the status [active/suspended].
 * The rendered data and styles are conditional upon the type of payment method
 * and its respective status.
 */
const PaymentMethodStatus = (props) => {
  const {
    creditCardInfo,
    currency,
    giftCardInfo,
    bankTransferIBAN,
    orderNumber,
    paymentType,
    paymentStatus,
    region,
  } = props;
  const { i18nString } = React.useContext(NikeI18nContext);
  const { ACTIVE, ARIA_PAYMENT_DETAILS_2, EXP, PIN, SUSPENDED, VOIDED } = mapValues(
    translations,
    i18nString
  );
  const { CREDIT_CARD, GIFT_CARD, BANK_TRANSFER } = PaymentServiceMethodsDict;
  const classes = useStyles(props);
  const { hasPermission } = useHasPermission();
  const giftCardNumberHash = giftCardInfo?.accountNumber && sha256(giftCardInfo.accountNumber);
  const giftCardUrlQuery = `&q=${giftCardNumberHash}&c=${currency}&or=${orderNumber}`;

  let statusText = ACTIVE;
  if (paymentStatus !== 'ACTIVE') {
    statusText = paymentStatus === 'SUSPENDED' ? SUSPENDED : VOIDED;
  }

  // TODO: was told gift card numbers would be hidden but does not appear to be the case
  const getGiftCardNumber = (number) => {
    return hasPermission(ViewFullGiftCardNumber, region)
      ? number
      : Array(number.length - 4)
          .fill('*', 0)
          .join('') + number?.slice(-4);
  };
  // gets number to display based on payment method. can add more cases here if necessary
  const getAccountNumber = () => {
    switch (paymentType) {
      case CREDIT_CARD:
        return `**** **** **** ${creditCardInfo?.accountNumber?.slice(-4)}`;
      case GIFT_CARD:
        return (
          giftCardNumberHash && (
            <NewTabButton
              label={getGiftCardNumber(giftCardInfo?.accountNumber)}
              href={config.giftCardUrl}
              query={giftCardUrlQuery}
              testId='payment-account-number-link'
            />
          )
        );
      case BANK_TRANSFER:
        return bankTransferIBAN;
      case 'voucher':
        return giftCardInfo?.accountNumber;
      default:
        return '';
    }
  };

  const creditCardExpiration =
    creditCardInfo?.expirationMonth && creditCardInfo?.expirationYear
      ? `${creditCardInfo.expirationMonth}/${creditCardInfo.expirationYear}`
      : null;

  // TODO: use view GC Pin permission to reveal all or partial value from giftCardInfo.pin
  const giftCardPin = '***-***-****';

  const boldStyle = { fontWeight: 500 };

  return (
    <List aria-label={ARIA_PAYMENT_DETAILS_2} className={classes.list}>
      <ListItem className={classes.listItem}>
        {paymentType !== BANK_TRANSFER && (
          <ListItemIcon className={classes.listItemIcon}>
            <PaymentIcon />
          </ListItemIcon>
        )}
        <ListItemText
          data-testid='payment-account-number'
          primary={getAccountNumber()}
          className={classes.listItemText}
          primaryTypographyProps={paymentType !== GIFT_CARD ? { style: boldStyle } : {}}
        />
      </ListItem>
      {paymentType === CREDIT_CARD && (
        <ListItem className={classes.listItem}>
          <ListItemText
            data-testid='pmt-exp-date'
            primary={
              <>
                <span>{EXP}:&nbsp;</span>
                <span>{creditCardExpiration}</span>
              </>
            }
            primaryTypographyProps={{ style: boldStyle }}
            className={classes.listItemText}
          />
        </ListItem>
      )}
      {paymentType === GIFT_CARD && (
        <ListItem className={classes.listItem}>
          <ListItemText
            data-testid='pmt-exp-date'
            primary={
              <>
                <span>{PIN}:&nbsp;</span>
                <span>{giftCardPin}</span>
              </>
            }
            primaryTypographyProps={{ style: boldStyle }}
            className={classes.listItemText}
          />
        </ListItem>
      )}
      <ListItem data-testid='pmt-status-label' className={classes.listItem}>
        <ListItemText
          data-testid='pmt-status'
          primary={
            <Typography component='span' variant='subtitle1' className={classes.statusText}>
              {statusText}
            </Typography>
          }
          className={classes.listItemText}
        />
      </ListItem>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  statusText: {
    lineHeight: 1.5,
    fontWeight: 500,
    color: (props) =>
      props.paymentStatus !== 'ACTIVE' ? theme.palette.error.main : theme.palette.success.main,
  },
  list: {
    display: 'flex',
    margin: `${theme.spacing(2)}px 0`,
    padding: 0,
  },
  listItem: {
    minHeight: '1em',
    maxWidth: 'max-content',
    alignItems: 'flex-start',
    marginRight: theme.spacing(5),
    padding: 0,
  },
  listItemIcon: {
    minWidth: 'unset',
    marginRight: theme.spacing(1.25),
    color: (props) =>
      props.paymentStatus !== 'ACTIVE' ? theme.palette.grey[400] : theme.palette.text.primary,
  },
  listItemText: {
    margin: 0,
    color: (props) =>
      props.paymentStatus !== 'ACTIVE' ? theme.palette.grey[400] : theme.palette.text.primary,
  },
}));

PaymentMethodStatus.propTypes = {
  creditCardInfo: PropTypes.shape({
    accountNumber: PropTypes.string,
    expirationMonth: PropTypes.string,
    expirationYear: PropTypes.string,
  }),
  currency: PropTypes.string,
  giftCardInfo: PropTypes.shape({
    accountNumber: PropTypes.string,
    pin: PropTypes.string,
  }),
  bankTransferIBAN: PropTypes.shape({
    iban: PropTypes.string,
  }),
  orderNumber: PropTypes.string,
  paymentStatus: PropTypes.string,
  paymentType: PropTypes.string,
  region: PropTypes.string,
};

PaymentMethodStatus.defaultProps = {
  creditCardInfo: {},
  giftCardInfo: {},
  orderNumber: '',
  paymentStatus: '',
  paymentType: '',
};

export default PaymentMethodStatus;
