const translations = {
  CLOSE: {
    description: 'label for button to cancel dialog action',
    primaryValue: 'Close',
    stringKey: 'close',
  },
  CONFIRM: {
    description: 'label for button to confirm dialog action',
    primaryValue: 'Confirm',
    stringKey: 'confirm',
  },
};

export default translations;
