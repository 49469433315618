import { useHistory } from 'react-router-dom';
import { useGetUrlParams } from './useGetUrlParams';

/**
 * hook to replace history.push. adds sessionId onto the end of url
 */
export const useHistoryPushWithSessionId = () => {
  const history = useHistory();
  const sessionId = useGetUrlParams('sessionId');

  return (url) => {
    history.push(`${url}/?sessionId=${sessionId}`);
  };
};
