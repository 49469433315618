import { useState, useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import PropTypes from 'prop-types';

import ORDER_DETAIL_QUERY from '../queries/orderDetail.query';
import getOrderRoutes from '../routes';
import { actions as dialogActions } from '../store/actions/dialogActions';
import OrderContext from '../store/contexts/orderContext';
import { DialogContext } from '../store/contexts/dialogContext';
import useSnacks from './useSnacks';
import { useHistoryPushWithSessionId } from './useHistorySessionId';

/**
 * Hook to encapsulate Get Order Details functionality for better re-use.
 *
 * @param {func} basicOnCompleted Optional. Callback function for generic success
 * @param {func} basicOnError Optional. Callback function for generic failure
 * @param {func} followUpOnCompleted Optional. Callback function for followUp success
 * @param {func} followUpOnError Optional. Callback function for followUp failure
 *
 * @returns {object} with the following properties:
 *  - runQueryOrderDetails {func} triggers query
 *  - loading {boolean} indicates if query is currently in process
 */
const useGetOrderDetails = ({
  basicOnCompleted,
  basicOnError,
  followUpOnCompleted,
  followUpOnError,
}) => {
  const [, setOrderDetails] = useContext(OrderContext);
  const [, dialogDispatch] = useContext(DialogContext);
  const { setError, setStopLoading } = useSnacks();
  const setRoute = useHistoryPushWithSessionId();
  const routes = getOrderRoutes();
  const { reset } = dialogActions;

  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);

  const resetDialog = () => {
    dialogDispatch(reset());
  };

  /* 
    Call to get order details as a follow-up to a previous operation (e.g. createReturn, 
    createExchange), which makes a request for realtime data 
  */
  const [
    getFollowUpOrderDetails,
    { data: followUpData, stopPolling, loading: followUpLoading },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1500,
    onError: (error) => {
      const isOrderDetail404 =
        error?.message?.includes('404') &&
        /* 
          if the 404 were coming from a nested/stitched query, "path" would include nested 
          data nodes (e.g. ["orderDetail", "shipmentsV2"]), which is why we ensure the path
          only includes "orderDetail"
        */
        error?.path?.toString() === 'orderDetail';
      if (!isOrderDetail404 && followUpData?.orderDetail) {
        // If we have orderDetail data, stop polling, set state, and redirect user to new page.
        stopPolling();
        setOrderDetails(followUpData?.orderDetail);
        resetDialog();
        setRoute(`/order/${followUpData?.orderDetail?.orderNumber}/${routes[0]}`);
        if (followUpOnCompleted) followUpOnCompleted(followUpData);
      } else if (queryDetailsCounter === 10) {
        // If we've maxed out our polling, just stop.
        resetDialog();
        stopPolling();
        setStopLoading();
      } else {
        // Otherwise, keep polling.
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
      // Call the passed onError callback if it exists
      if (followUpOnError) {
        followUpOnError({
          error,
          data: followUpData,
          isPollingComplete: queryDetailsCounter === 10,
        });
      }
    },
    onCompleted: async () => {
      // If call is successful without errors, we stop making retry calls
      stopPolling();
      // Set state and redirect to details page
      setOrderDetails(followUpData.orderDetail);
      // Call the passed onCompleted callback if it exists
      if (followUpOnCompleted) followUpOnCompleted(followUpData);
      setRoute(`/order/${followUpData?.orderDetail?.orderNumber}/${routes[0]}`);
    },
  });

  // Basic call to get order details
  const [getOrderDetails, { data: basicData, loading: basicLoading }] = useLazyQuery(
    ORDER_DETAIL_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        setError(error.message);
        basicOnError({ error, data: basicData });
      },
      onCompleted: () => {
        const { orderDetail } = basicData;
        setOrderDetails(orderDetail);
        basicOnCompleted(basicData);
      },
    }
  );

  /**
   * Utility for querying order details service.
   *
   * @param {string} orderNumber
   * @param {boolean} isFollowUp - indicates if this is a follow-up call, requiring realtime data.
   */
  const runQueryOrderDetails = ({ orderNumber, isFollowUp }) => {
    if (isFollowUp) {
      getFollowUpOrderDetails({ variables: { orderNumber, isFollowUp } });
    } else {
      getOrderDetails({ variables: { orderNumber } });
    }
  };

  return { runQueryOrderDetails, loading: basicLoading || followUpLoading };
};

useGetOrderDetails.propTypes = {
  followUpOnCompleted: PropTypes.func,
  followUpOnError: PropTypes.func,
  basicOnCompleted: PropTypes.func,
  basicOnError: PropTypes.func,
};

useGetOrderDetails.defaultProps = {
  followUpOnCompleted: () => null,
  followUpOnError: () => null,
  basicOnCompleted: () => null,
  basicOnError: () => null,
};

export default useGetOrderDetails;
