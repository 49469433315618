// https://www.iso.org/obp/ui/#iso:code:3166:KR
module.exports = {
  kr: {
    'KR-11': {
      name: '서울특별시',
      label: '서울특별시',
      abbreviation: 'KR-11',
    },
    'KR-28': {
      name: '인천광역시',
      label: '인천광역시',
      abbreviation: 'KR-28',
    },
    'KR-30': {
      name: '대전광역시',
      label: '대전광역시',
      abbreviation: 'KR-30',
    },
    'KR-50': {
      name: '세종특별자치시',
      label: '세종특별자치시',
      abbreviation: 'KR-50',
    },
    'KR-27': {
      name: '대구광역시',
      label: '대구광역시',
      abbreviation: 'KR-27',
    },
    'KR-31': {
      name: '울산광역시',
      label: '울산광역시',
      abbreviation: 'KR-31',
    },
    'KR-26': {
      name: '부산광역시',
      label: '부산광역시',
      abbreviation: 'KR-26',
    },
    'KR-29': {
      name: '광주광역시',
      label: '광주광역시',
      abbreviation: 'KR-29',
    },
    'KR-42': {
      name: '강원도',
      label: '강원도',
      abbreviation: 'KR-42',
    },
    'KR-41': {
      name: '경기도',
      label: '경기도',
      abbreviation: 'KR-41',
    },
    'KR-48': {
      name: '경상남도',
      label: '경상남도',
      abbreviation: 'KR-48',
    },
    'KR-47': {
      name: '경상북도',
      label: '경상북도',
      abbreviation: 'KR-47',
    },
    'KR-46': {
      name: '전라남도',
      label: '전라남도',
      abbreviation: 'KR-46',
    },
    'KR-45': {
      name: '전라북도',
      label: '전라북도',
      abbreviation: 'KR-45',
    },
    'KR-44': {
      name: '충청남도',
      label: '충청남도',
      abbreviation: 'KR-44',
    },
    'KR-43': {
      name: '충청북도',
      label: '충청북도',
      abbreviation: 'KR-43',
    },
    'KR-49': {
      name: '제주특별자치도',
      label: '제주특별자치도',
      abbreviation: 'KR-49',
    },
  },
  en: {
    'KR-11': {
      name: 'Seoul',
      label: 'Seoul',
      abbreviation: 'KR-11',
    },
    'KR-28': {
      name: 'Incheon',
      label: 'Incheon',
      abbreviation: 'KR-28',
    },
    'KR-30': {
      name: 'Daejeon',
      label: 'Daejeon',
      abbreviation: 'KR-30',
    },
    'KR-50': {
      name: 'Sejong-si',
      label: 'Sejong-si',
      abbreviation: 'KR-50',
    },
    'KR-27': {
      name: 'Daegu',
      label: 'Daegu',
      abbreviation: 'KR-27',
    },
    'KR-31': {
      name: 'Ulsan',
      label: 'Ulsan',
      abbreviation: 'KR-31',
    },
    'KR-26': {
      name: 'Busan',
      label: 'Busan',
      abbreviation: 'KR-26',
    },
    'KR-29': {
      name: 'Gwangju',
      label: 'Gwangju',
      abbreviation: 'KR-29',
    },
    'KR-42': {
      name: 'Gangwon-do',
      label: 'Gangwon-do',
      abbreviation: 'KR-42',
    },
    'KR-41': {
      name: 'Gyeonggi-do',
      label: 'Gyeonggi-do',
      abbreviation: 'KR-41',
    },
    'KR-48': {
      name: 'Gyeongsangnam-do',
      label: 'Gyeongsangnam-do',
      abbreviation: 'KR-48',
    },
    'KR-47': {
      name: 'Gyeongsangbuk-do',
      label: 'Gyeongsangbuk-do',
      abbreviation: 'KR-47',
    },
    'KR-46': {
      name: 'Jeollanam-do',
      label: 'Jeollanam-do',
      abbreviation: 'KR-46',
    },
    'KR-45': {
      name: 'Jeollabuk-do',
      label: 'Jeollabuk-do',
      abbreviation: 'KR-45',
    },
    'KR-44': {
      name: 'Chungcheongnam-do',
      label: 'Chungcheongnam-do',
      abbreviation: 'KR-44',
    },
    'KR-43': {
      name: 'Chungcheongbuk-do',
      label: 'Chungcheongbuk-do',
      abbreviation: 'KR-43',
    },
    'KR-49': {
      name: 'Jeju-do',
      label: 'Jeju-do',
      abbreviation: 'KR-49',
    },
  },
};
