const translations = {
  EDIT_EMAIL: {
    description: 'label for button to edit the email of a user',
    primaryValue: 'EDIT',
    stringKey: 'edit_email',
  },
  EDIT_LABEL: {
    description: 'label for field to edit the email of a user',
    primaryValue: 'email',
    stringKey: 'email_label',
  },
  VALIDATE_EMAIL_MSG: {
    description: 'incorrect email message',
    primaryValue: 'incorrect email',
    stringKey: 'incorrect_email_label',
  },
  UPDATE_EMAIL_SUCCESS: {
    description: 'success message for updating email',
    primaryValue: 'Email updated',
    stringKey: 'update_email_success',
  },
  UPDATE_EMAIL_ERROR: {
    description: 'error message for updating email',
    primaryValue: 'Failed to update email',
    stringKey: 'update_email_error',
  },
  UPDATE_EMAIL_DETAILS: {
    description: 'details for updating email',
    primaryValue: 'Change billing email',
    stringKey: 'update_email_details',
  },
};

export default translations;
