import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import translations from './errorSummary.i18n';
import mapValues from 'lodash/mapValues';
import { makeStyles } from '@material-ui/core';

/**
 * A simple component intended to show the details of an application error.
 *
 * @param {Object} props React Props
 */
const ErrorSummary = ({ error, errorStack, header: headerProp, message: messageProp }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { ERROR_HEADER, ERROR_PREAMBLE } = mapValues(translations, i18nString);
  const classes = useStyles();

  const header = headerProp || ERROR_HEADER;
  const message = messageProp || (error && error.message) || ERROR_PREAMBLE;

  return (
    <div className={classes.errorContainer}>
      <h2>{header}</h2>
      <p>{message}</p>
      {(error || errorStack) && (
        <details className={classes.errorDetailsContainer}>
          <div className={classes.errorDetails}>
            {error && error.toString()}
            <br />
            {errorStack && errorStack}
          </div>
        </details>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    padding: '1em',
    backgroundColor: '#FFF0F0',
    border: `3px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.dark,
    height: '100%',
    width: '100%',
  },
  link: {
    color: theme.palette.error.dark,
  },
  errorDetailsContainer: {
    whiteSpace: 'pre-wrap',
    color: 'black',
  },
  errorDetails: {
    backgroundColor: 'white',
    padding: '1em',
    border: `1px dotted ${theme.palette.error.dark}`,
  },
}));

export default ErrorSummary;
