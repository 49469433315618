import {
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DialogTypes } from '../../../../constants/dialog.const';
import Geo from '../../../../constants/geos.const';
import dialogActions from '../../../../store/actions/dialogActions';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { getShipToAddressFromOrder } from '../../../../utils/address';
import { getEmailForReturn } from '../../../../utils/dialog';
import { areLineItemsDigitalGiftCards, getEmeaPartner } from '../../../../utils/order';
import { ReturnLabelAddress } from '../../../address/returnLabelAddress';
import AddressForm from '../../../address/addressForm';
import translations from './step4.i18n';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import InfoIcon from '@material-ui/icons/InfoOutlined';
/**
 * This component is step 4 of the Create Return flow
 *
 * @param {Object} props – props object for a React component
 */
const Step4 = ({ className }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [consumerState] = useContext(ConsumerContext);
  const [orderDetail] = useContext(OrderContext);
  const [labelType, setLabelType] = useState('nonPrepaid');
  const [showLabelTypes, setShowLabelTypes] = useState(false);
  const [showCarriers, setShowCarriers] = useState(false);
  const classes = useStyles();

  const { isRegistered, consumerEmail } = consumerState;

  const { omsRegionReference: region } = orderDetail;
  const emeaPartner = getEmeaPartner(orderDetail);
  const {
    address,
    gcShippingAddress,
    isAlaskanAddress,
    isMilitaryAddress,
    deliveryMethod,
    dialogType,
    shippingOption,
    selectedLines,
  } = dialogState;

  const {
    setAddressField,
    setDeliveryMethod,
    setInitialShippingOption,
    setReturnLabelEmail,
    setIsMilitaryAddress,
    setShippingOption,
    setAddress,
  } = dialogActions;

  const {
    ARIA_CARRIER,
    ARIA_RETURN_LABEL,
    EMAIL,
    MAIL,
    MILITARY_ADDRESS,
    PREPAID_MESSAGE,
    DIGITAL_PREPAID_MESSAGE,
    NON_PREPAID_MESSAGE,
    UPS,
    USPS_MESSAGE,
    USPS,
    PICKUP,
    PREPAID,
    NON_PREPAID,
    GUEST_PREPAID_MESSAGE,
    NON_PICKUP,
    RETURN_LABEL_EMAIL,
    NO_FREE_SHIPPING_ELIGIBILITY,
  } = useMemoTranslations(translations);

  const email = getEmailForReturn(address, gcShippingAddress, consumerEmail);

  // Only show the "Email" vs "Mail" options for US.
  const showDeliveryMethodOptions = region === Geo.US;

  // TODO this useCallback has become WAY TOO COMPLICATED. COMMENTS.
  const getShippingInformation = useCallback(() => {
    if (region === Geo.EUROPE || emeaPartner) {
      // Shipping options for EU orders.
      return {
        message: DIGITAL_PREPAID_MESSAGE,
        initialShippingOption: 'Cycleon',
      };
    } else if (region === Geo.AUSTRALIA) {
      // Shipping options for australia orders.
      return {
        message: PREPAID_MESSAGE,
        initialShippingOption: 'ausplm',
      };
    } else if (region === Geo.CHINA) {
      // Shipping options for CN orders.
      return {
        message: RETURN_LABEL_EMAIL,
        initialShippingOption: 'nonPickup',
      };
    } else if (region === Geo.JAPAN) {
      // Shipping options for JP orders.
      return {
        message: RETURN_LABEL_EMAIL,
        initialShippingOption: 'none',
      };
    } else {
      // Shipping options for US orders.
      if (isMilitaryAddress || isAlaskanAddress) {
        return {
          message: USPS_MESSAGE,
          initialShippingOption: 'usps',
        };
      } else if (isRegistered) {
        return {
          message: PREPAID_MESSAGE,
          initialShippingOption: 'ups',
        };
      } else if (labelType === 'prepaid') {
        return {
          message: GUEST_PREPAID_MESSAGE,
          initialShippingOption: 'ups',
        };
      } else {
        return {
          message: NON_PREPAID_MESSAGE,
          initialShippingOption: 'none',
        };
      }
    }
  }, [
    isAlaskanAddress,
    isMilitaryAddress,
    isRegistered,
    NON_PREPAID_MESSAGE,
    USPS_MESSAGE,
    labelType,
    region,
  ]);

  /**
   * This function will update email field in shipping
   * and returnLabelEmail field in root level of dialog state
   * @param {*} event - event triggered on changing return label email
   */
  const updateShippingAndReturnLabelEmail = (event) => {
    dialogDispatch(setAddressField('email', event.target.value));
    dialogDispatch(setReturnLabelEmail(event.target.value));
  };

  /**
   * This useEffect determines whether the label types (Prepaid and Non-Prepaid) should be
   * displayed. Currently it will only display for US orders.  If the consumer is a Guest and they
   * do not have a military or Alaska address then the athlete should see the label types.
   */
  useEffect(() => {
    setShowLabelTypes(
      region === Geo.US && !isRegistered && !isMilitaryAddress && !isAlaskanAddress
    );
  }, [isMilitaryAddress, isAlaskanAddress]);

  /**
   * This useEffect determines whether the carrier options (UPS, USPS, etc.) should be displayed.
   */
  useEffect(() => {
    if (region === Geo.EUROPE || emeaPartner || region === Geo.JAPAN || region === Geo.AUSTRALIA) {
      // Never show carrier options for Europe or Japan and Australia.
      setShowCarriers(false);
    } else if (region === Geo.CHINA) {
      // Always show carrier options for China (Non-Pickup vs Pickup).
      setShowCarriers(true);
    } else {
      /*
       Show carrier options for US (UPS vs USPS) only if the address is neither military or Alaska
       (USPS does not serve these addresses), and then either the user is registered or the athlete
       has selects 'Prepaid'.
       */
      setShowCarriers(
        (isRegistered || labelType === 'prepaid') && !isMilitaryAddress && !isAlaskanAddress
      );
    }
  }, [isRegistered, labelType, isMilitaryAddress, isAlaskanAddress]);

  useEffect(() => {
    if (dialogType === DialogTypes.RESEND_RETURN_LABEL) {
      const address = getShipToAddressFromOrder(orderDetail);
      if (address) {
        dialogDispatch(setAddress(address));
      }
    }
  }, [orderDetail]);

  // just once, we'll get our initial shipping options and email and set them in state
  useEffect(() => {
    const initialShippingOption = getShippingInformation().initialShippingOption;
    dialogDispatch(setInitialShippingOption(initialShippingOption));

    dialogDispatch(setAddressField('email', email));
    dialogDispatch(setReturnLabelEmail(email));
  }, []);

  // a message for certain scenarios. null if no message is required.
  const shippingMessage = getShippingInformation().message;
  const DeliveryMethod = () => (
    <>
      {showDeliveryMethodOptions && (
        <RadioGroup
          value={deliveryMethod}
          aria-label={ARIA_RETURN_LABEL}
          data-testid='dialog-step4-delivery-options'
          onChange={(event) => dialogDispatch(setDeliveryMethod(event.target.value))}
          row>
          <FormControlLabel label={EMAIL} value={'email'} control={<Radio color='primary' />} />
          <FormControlLabel label={MAIL} value={'mail'} control={<Radio color='primary' />} />
        </RadioGroup>
      )}
      {shippingMessage && (
        <Typography variant='caption' className={classes.shippingMessage}>
          {shippingMessage}
        </Typography>
      )}

      {region === Geo.AUSTRALIA && !isRegistered && (
        <Typography variant='caption'>
          <span
            style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F5F5F5' }}
            data-testid='free-shipping-eligibility-message'>
            <InfoIcon style={{ marginRight: '4px' }} />
            {NO_FREE_SHIPPING_ELIGIBILITY}
          </span>
        </Typography>
      )}
    </>
  );
  const handleChangeLabelType = (event) => {
    const { value } = event.target;
    setLabelType(value);
    if (value === 'prepaid') dialogDispatch(setShippingOption('ups')); // set default prepaid label shippingOption
  };

  return dialogType === DialogTypes.RETURN ? (
    <form className={clsx(className, classes.step4Form)}>
      <FormControl className={classes.deliverMethodOptions} component='fieldset'>
        <DeliveryMethod />
        {showLabelTypes && (
          <RadioGroup value={labelType} onChange={handleChangeLabelType} row>
            <FormControlLabel
              label={NON_PREPAID}
              value={'nonPrepaid'}
              control={<Radio color='primary' />}
            />
            <FormControlLabel
              label={PREPAID}
              value={'prepaid'}
              control={<Radio color='primary' />}
            />
          </RadioGroup>
        )}
      </FormControl>

      {deliveryMethod === 'email' && (
        <FormControlLabel
          aria-label={EMAIL}
          className={classes.returnLabelEmail}
          control={
            <TextField
              required
              label={EMAIL}
              // aria-label={`${EMAIL}*`}
              aria-required={true}
              fullWidth={true}
              onBlur={() => {} /* TODO validate */}
              onChange={updateShippingAndReturnLabelEmail}
              error={false /* TODO validation error */}
              value={email}
              data-testid={'input-email'}
            />
          }></FormControlLabel>
      )}
      {region === Geo.US && (
        <FormControlLabel
          label={MILITARY_ADDRESS}
          className={classes.militaryAddressCheckbox}
          control={
            <Checkbox
              checked={isMilitaryAddress}
              aria-required={true}
              aria-checked={isMilitaryAddress}
              onChange={(event) => dialogDispatch(setIsMilitaryAddress(event.target.checked))}
            />
          }
        />
      )}

      {showCarriers && (
        <FormControl
          className={classes.shippingOptions}
          legend='carrier'
          aria-label={ARIA_CARRIER}
          component='fieldset'>
          <RadioGroup
            value={shippingOption}
            onChange={(event) => dialogDispatch(setShippingOption(event.target.value))}
            row>
            {region === Geo.CHINA ? (
              <>
                <FormControlLabel
                  label={NON_PICKUP}
                  value={'nonPickup'}
                  control={<Radio color='primary' />}
                />
                <FormControlLabel
                  label={PICKUP}
                  value={'pickup'}
                  control={<Radio color='primary' />}
                />
              </>
            ) : (
              <>
                <FormControlLabel label={UPS} value={'ups'} control={<Radio color='primary' />} />
                <FormControlLabel label={USPS} value={'usps'} control={<Radio color='primary' />} />
              </>
            )}
          </RadioGroup>
        </FormControl>
      )}
      <AddressForm
        region={region}
        className={classes.addressForm}
        isGiftCardAddress={areLineItemsDigitalGiftCards(selectedLines)}
      />
    </form>
  ) : (
    <>
      <DeliveryMethod />
      <ReturnLabelAddress />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    gridArea: 'AC',
  },
  addressForm: {
    gridArea: 'AF',
    width: '75%',
  },
  deliverMethodOptions: {
    gridArea: 'DM',
    marginTop: '4px',
  },
  militaryAddressCheckbox: {
    gridArea: 'MA',
    marginTop: '8px',
  },
  returnLabelEmail: {
    gridArea: 'EM',
    width: '100%',
    margin: '2px 0px 0px 0px',
  },
  shippingMessage: {
    gridArea: 'MS',
    marginLeft: '24px',
    lineHeight: '42px',
  },
  shippingOptions: {
    gridArea: 'SO',
  },
  step4Form: {
    marginBottom: '1rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: '1rem 3rem',
    gridTemplateAreas: `
      " DM DM DM DM .. .. .. .. "
      " EM EM EM SO SO SO .. .. "
      " MA MA MA .. .. .. .. .. "
      " AF AF AF AF AF AF AF AF "
      " AF AF AF AF AF AF AF AF "
      " AF AF AF AF AF AF AF AF "
      " AF AF AF AF AF AF AF AF "
      " AC AC AC AC .. .. .. .. "
    `,
  },
}));

export default Step4;
