/** React Utils */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const SizeCard = ({ originalLineNumber, product, sku, selectedSize, handleSelectSku }) => {
  const classes = useStyles();

  const isOOS = sku?.level === 'OOS' || sku?.level === 'UNKNOWN';

  let className =
    sku?.localizedSize === selectedSize?.localizedSize && sku.level !== 'OOS' ? 'selected' : null;
  if (!className && isOOS) className = 'oos';
  // a size card can never be both selected and OOS
  return (
    <Grid item>
      <Card elevation={0} data-testid={`sku-${originalLineNumber}-${sku.localizedSize}`}>
        <CardActionArea
          disabled={isOOS}
          onClick={() => handleSelectSku(originalLineNumber, product, sku)}>
          <CardContent className={clsx(className, classes.sizeGridItem)}>
            {sku?.localizedSize}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

SizeCard.propTypes = {
  originalLineNumber: PropTypes.number,
  product: PropTypes.object,
  sku: PropTypes.shape({
    localizedSize: PropTypes.string,
    level: PropTypes.string,
  }),
  selectedSize: PropTypes.object,
  handleSelectSku: PropTypes.func,
};

export default SizeCard;

const useStyles = makeStyles((theme) => ({
  sizeGridItem: {
    'borderRadius': '4px',
    'justifyContent': 'center',
    'alignItems': 'center',
    'textAlign': 'center',
    'display': 'flex',
    'width': '77px',
    'height': '48px',
    'padding': '14px 10px',
    'border': 'none',
    'backgroundColor': theme.palette.grey[100],
    '&.oos': {
      // linear gradient creates diagonal line through the card
      background: `linear-gradient(to bottom right, ${theme.palette.background.paper}, ${theme.palette.background.paper} 49%, ${theme.palette.grey[300]} 49%, ${theme.palette.grey[300]} 51%, ${theme.palette.background.paper} 49%)`,
      color: theme.palette.grey[700],
    },
    '&.selected': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
}));
