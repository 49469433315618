const translations = {
  SAVE_BUTTON: {
    description: 'Save button text',
    primaryValue: 'Save',
    stringKey: 'save_button_title',
  },
  CANCEL_BUTTON: {
    description: 'Cancel button text',
    primaryValue: 'Cancel',
    stringKey: 'cancel_button_title',
  },
  SAVE_ERROR: {
    description: 'Error message displayed when save operation failed',
    primaryValue: 'Failed to save: ',
    stringKey: 'save_operation_fail',
  },
  SAVE_SUCCESS: {
    description: 'Success message displayed when save operation succeed',
    primaryValue: 'Successfully saved',
    stringKey: 'save_operation_success',
  },
};

export default translations;
