/** React/Utils */
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

/** Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

/** Local */
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import CUSTOMER_PROFILE_MUTATION from '../../../../mutations/postCustomerProfile.mutation';
import { ApiTimeOut } from '../../../../constants/dialog.const';
import useSnacks from '../../../../hooks/useSnacks';
import translation from './unlinkMemberDialog.i18n';

/**
 * Component that displays modal window with unlinking customer profile functionality
 */
const UnLinkMemberProfileModal = ({ isOpen, handleClose, orderNumber, onSuccess }) => {
  const {
    DIALOG_TITLE,
    DESCRIPTION,
    UNLINK_CAPTION,
    CANCEL_BUTTON_CAPTION,
    UNLINK_CUSTOMER_ERROR,
    UNLINK_CUSTOMER_SUCCESS,
  } = useMemoTranslations(translation);

  const { setSnack, setError } = useSnacks();

  // Mutation that calls link/unlink member profile
  const [postCustomerProfile, { loading: postCustomerProfileLoading }] = useMutation(
    CUSTOMER_PROFILE_MUTATION,
    {
      onError: (err) => {
        setError(`${UNLINK_CUSTOMER_ERROR} ${err.message}`);
      },
      onCompleted: () => {
        setSnack(`${UNLINK_CUSTOMER_SUCCESS}`);
        onSuccess();
      },
    }
  );

  // Function to trigger postCustomerProfile call
  const handlePostCustomerProfile = () => {
    postCustomerProfile({
      variables: {
        input: {
          requestId: `${uuidv4()}`,
          customerProfileReference: '',
        },
        orderNumber: orderNumber,
        timeout: ApiTimeOut,
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{DESCRIPTION}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePostCustomerProfile}
          color='primary'
          data-testid='submit-unlink-user'
          disabled={postCustomerProfileLoading}>
          {UNLINK_CAPTION}
        </Button>
        <Button onClick={handleClose} color='secondary' data-testid='cancel-submit-unlink-user'>
          {CANCEL_BUTTON_CAPTION}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnLinkMemberProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  orderNumber: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default UnLinkMemberProfileModal;
