import { makeStyles } from '@material-ui/core';
import React from 'react';

import Box from '@material-ui/core/Box';

import PidSearchForm from './pidSearchForm';
import TopBarContainer from '../orders/topBar/topBarContainer';
import ResponseSnackBar from './../shared/snackbar';
import HasPermissionComponent from '../shared/hasPermission';
import { ViewPidReviewLink } from '../../constants/permissions.const';

const PidReview = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBarContainer />
      <HasPermissionComponent permission={ViewPidReviewLink}>
        <Box className={classes.page}>
          <PidSearchForm />
        </Box>
        <ResponseSnackBar />
      </HasPermissionComponent>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
  },
  page: {
    padding: theme.spacing(2),
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
  },
  searchFormPaper: {
    margin: theme.spacing(1),
    position: 'sticky',
    top: 58,
    width: '20vw',
    minWidth: '215px',
    height: 'max-content',
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },
  searchFormHeading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.75,
  },
  searchFields: {
    width: '100%',
  },
  advancedSearchToggle: {
    float: 'right',
    alignItems: 'center',
    textTransform: 'none',
    paddingRight: 0,
    marginRight: 0,
  },
  ctaButtons: {
    display: 'flex',
    width: '100%',
    margin: '4rem 0 .5rem 0',
    justifyContent: 'space-around',
  },
  clearSearchButton: { padding: '.5rem 1rem' },
  addressTypeRadioGroup: {
    color: theme.palette.common.black,
    marginTop: '1rem',
  },
  listSubheader: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  searchButton: { minWidth: '6.5rem' },
  searchResults: {
    height: '30vh',
    display: 'inherit',
    padding: theme.spacing(1),
  },
}));

export default PidReview;
