import gql from 'graphql-tag';

const SUBMIT_CREDIT_CARD_MUTATION = gql`
  mutation submitCreditCard($input: SubmitCreditCardInput!, $timeout: Int!) {
    submitCreditCard(input: $input) {
      addCreditCardResponse(input: $input, timeout: $timeout) {
        id
        status
        eta
        resourceType
        links {
          self {
            ref
          }
        }
        error {
          httpStatus
          message
          errors {
            field
            code
            message
          }
        }
      }
    }
  }
`;

export default SUBMIT_CREDIT_CARD_MUTATION;
