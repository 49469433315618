import gql from 'graphql-tag';

const OMA_SEARCH_DETAIL_QUERY = gql`
  query getSearchDetailOMA($sendId: String!, $channel: Channel) {
    searchDetailOMA(sendId: $sendId, channel: $channel) {
      send_id
      channel
      creation_date
      locale_language
      marketplace
      notification_type
      notification_class
      thread_id
      comm_id
      user_id
      user_email_address
      user_phone_number
      order_id
      communication_header
      communication_body
      communication_payload
      is_resend
      has_attachments
      properties {
        firstName
        lastName
        isMarketingEnabled
        offerId
        storeId
        productCategory
        cpCode
        commId
        senderName
        email {
          subject
        }
      }
      attachments {
        id
        name
        attachment
      }
    }
  }
`;

export default OMA_SEARCH_DETAIL_QUERY;
