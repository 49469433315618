import { Channel } from '../../../../../constants/dialog.const';
import { ShippingOptions } from '../../../../../constants/shippingOptions.const';
import { restructureAddress, generateOrderLines, getEmail } from './utility';

/**
 * Convert the dialog state into the format that the return captures mutation input is expecting.
 * @param {Object} dialogState The dialogs state.
 * @param {Object} orderDetails The sales order details.
 * @param {String} receivingNode receivingNode that will be sent in return payload
 * @param {Object} standardCarrierAlphaCode standardCarrierAlphaCode that will be sent
 * in return payload
 * @param {Object} athleteInfo Object containing athlete details.
 */
export const generateReturnCapturePayload = (
  dialogState,
  orderDetails,
  receivingNode,
  standardCarrierAlphaCode,
  athleteInfo
) => {
  const {
    customerProfileReference,
    locale,
    currency,
    orderNumber,
    omsRegionReference,
  } = orderDetails;
  const {
    returnPaymentOption,
    shipTo,
    address,
    areAllOrderLinesGiftCards,
    gcShippingAddress,
    selectedLines,
    shippingOption,
    trackingNumber,
  } = dialogState;
  const { NON_PICKUP } = ShippingOptions;
  const refundAsGiftCard = returnPaymentOption === 'giftcard';
  const orderLines = generateOrderLines(selectedLines);
  const createDate = new Date();

  let returnMethod = '';
  /* 
    mp-titan wants us to send returnMethod which will all be `PICK_UP` for KOREA region unless, 
    the return is being created by either a warehouse/L1/L2/L3 employee and shippingOption is 
    nonPickup, which would mean that the item is already at the warehouse and the return is being
    created after the item has already been received. 
    */
  if (shippingOption === NON_PICKUP) {
    returnMethod = 'RECEIVED_AT_WAREHOUSE';
  } else {
    returnMethod = 'PICK_UP';
  }

  // TODO: remove unneeded logic after Return Captures testing
  const result = {
    customerProfileReference,
    // mp-titan team asked us to send this channel info
    channel: Channel,
    // Limitation in Doms as assistingAgentReference can only take 20 characters
    assistingAgentReference: athleteInfo?.name?.substring(0, 20) || '',
    omsRegionReference,
    returnMethod,
    locale,
    currency,
    orderNumber,
    // mp-titan wants us to send channel details for this field
    captureSystem: Channel,
    email: getEmail(dialogState),
    orderCreateDate: createDate.toISOString(),
    refundOrderFlag: refundAsGiftCard,
    /*
    We don't have to ask mp-titan to create return labels Korea
    (downstream services taking care of it)
    */
    createReturnLabelFlag: false,
    receivingNode: receivingNode,
    orderLines,
  };

  /*
    Location the physical return label will be sent to.
    For Korea downstream systems will handle the logic of sending return labels
    so we are sending the shipTo info in return capture payload irrespective of athlete permission
    to send return labels.
  */
  result.shipTo = { ...shipTo };
  result.shipFrom = restructureAddress(address, dialogState);
  result.standardCarrierAlphaCode = standardCarrierAlphaCode;

  /*
   * delete email field when there is no email as return capture api
   * throws error when email is empty
   */
  if (!result?.shipFrom?.contactInformation?.email) {
    delete result?.shipFrom?.contactInformation?.email;
  }

  /* 
	Overwrite shipFrom address with billTo address if 
		a) the refund is going to gift card location that the physical giftcard will be sent to,
			 replace shipFrom with the values entered
		b) if all the order lines that is being returned are gift cards, do the same
  */
  if (refundAsGiftCard || areAllOrderLinesGiftCards) {
    result.shipFrom = restructureAddress(gcShippingAddress, dialogState);
  }

  if (shippingOption === NON_PICKUP && trackingNumber) {
    result.returnTrackingNumber = trackingNumber;
  }

  return result;
};

/**
 * Convert state into the format that the return location mutation input is expecting.
 * @param {*} dialogState The dialogs state.
 * @param {*} consumerState The consumer state.
 * @param {*} orderDetails The sales order details.
 */
export const generateReturnLocationPayload = (dialogState, consumerState, orderDetails) => {
  const { omsRegionReference } = orderDetails;
  const { selectedLines, address } = dialogState;
  let orderLineReference = '';
  const selectedLineKeys = Object.keys(selectedLines);
  if (selectedLineKeys.length) {
    const selectedLine = selectedLines[selectedLineKeys[0]];
    if (selectedLine?.orderLineKey) {
      orderLineReference = selectedLine.orderLineKey;
    }
  }

  return {
    registeredUser: consumerState.isRegistered,
    orderLineReference,
    omsRegionReference,
    city: address?.city,
    state: address?.state,
    country: address?.country,
  };
};

/**
 *
 * @param {*} address - Address for which validation needs to be done
 * @param {*} locale - Geo info
 */
export const generateAddressValidationPayload = (address, locale, language) => {
  const { address1, address2, address3, city, state, postalCode, country } = address;
  const payload = {
    address1,
    address2,
    address3,
    city,
    state,
    postalCode,
    country,
    locale,
  };
  if (language) {
    // valid language params for addressValidatorV2 are different than i18 for some reason
    payload.language = language;
  }
  return payload;
};
