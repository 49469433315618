/** React / Utils */
import React, { useContext } from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

/** Local */
import { DialogTypes } from '../../../../constants/dialog.const';
import useSnacks from '../../../../hooks/useSnacks';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { areReasonsMissingFrom, isQuantityMissingForLineItems } from '../../../../utils/dialog';
import translations from './exchange.i18n';
import { stepControlSharedStyles } from '../sharedStyles';
import { getLineTotal, getNewSkuPrice } from '../../../../utils/price';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
export default function StepControl() {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { setSlowLoading, getLoadingStatus } = useSnacks();

  const { prevStep, nextStep, setIsExchangeSubmitting } = dialogActions;
  const {
    selectedLines,
    selectedSkus,
    exchangeCreditValue,
    exchangeTotalPrice,
    activeStep,
    submissionSteps,
    hasTimedOut,
    isSubmitReady,
    isMilitaryAddress,
  } = dialogState;
  const { SUBMIT_EXCHANGE, NEXT, BACK } = useMemoTranslations(translations);

  const thereAreReasonsMissing = areReasonsMissingFrom(selectedLines, DialogTypes.EXCHANGE);
  const isQuantityMissing = isQuantityMissingForLineItems(selectedLines, DialogTypes.EXCHANGE);
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionStep = submissionSteps?.includes(activeStep);
  const isSubmissionLoading = getLoadingStatus();

  const ExchangeBackButton = () => (
    <Button
      variant='contained'
      color='primary'
      data-testid='return-back-button'
      className={classes.buttonStyling}
      onClick={() => dialogDispatch(prevStep())}
      startIcon={<NavigateBeforeIcon />}
      aria-label={BACK.toLowerCase()}
      classes={{
        root: classes.stepperButton,
      }}>
      {BACK}
    </Button>
  );

  /**
   * This exchange submit handler set a boolean value in dialog state,
   * which is read by the Step4 component in the exchange dialog flow,
   * triggering the actual API call to submit the exchange, utilizing
   * the exchange preview data acquired in the Step4 component.
   */
  const handleExchangeSubmit = () => {
    setSlowLoading();
    dialogDispatch(setIsExchangeSubmitting(true));
  };

  const isNextButtonDisabled = () => {
    switch (activeStep) {
      case 0: {
        return !selectedItemKeys.length;
      }
      case 1: {
        return !(
          exchangeCreditValue &&
          Object.values(selectedLines).length === Object.values(selectedSkus).length &&
          Object.values(selectedSkus).every(
            (sku) => Boolean(sku.skuId) && Boolean(sku.selectedSize)
          )
        );
      }
      case 2: {
        // Disable next button if...
        return (
          // the new total is greater than the exchange credit value OR
          !exchangeTotalPrice ||
          exchangeTotalPrice > exchangeCreditValue ||
          // one of the selected items has a new price larger than the original price OR
          Object.entries(selectedSkus).some(
            ([lineNumber, sku]) =>
              getNewSkuPrice(sku?.priceInfo?.total, sku.discount) >
              getLineTotal(selectedLines[lineNumber], isMilitaryAddress)
          ) ||
          // a selected exchange item is not in stock OR
          Object.values(selectedSkus).some((sku) => sku.inStock === false) ||
          /* one of the selected items has
            - a discount value with no reason provided OR 
            - a validation error
          */
          Object.values(selectedSkus).some(
            (sku) => (sku.discount.value && !sku.discount.reason.id) || sku.discount.error
          )
        );
      }
      default:
        return true;
    }
  };

  const isSubmissionButtonDisabled =
    isSubmissionLoading ||
    thereAreReasonsMissing ||
    hasTimedOut ||
    isQuantityMissing ||
    !isSubmitReady;

  // future todo: fix the button style and spacing, it doesn't match designs

  if (!isSubmissionStep)
    return (
      <div className={classes.actionsContainer}>
        {activeStep !== 0 && <ExchangeBackButton />}
        <Button
          className={classes.buttonStyling}
          disabled={isNextButtonDisabled()}
          variant='contained'
          color='primary'
          data-testid='next-button'
          onClick={() => dialogDispatch(nextStep())}
          endIcon={<NavigateNextIcon />}
          aria-label={NEXT.toLowerCase()}
          classes={{
            root: classes.stepperButton,
            disabled: classes.nextDisabled,
          }}>
          {NEXT}
        </Button>
      </div>
    );
  else
    return (
      <div className={classes.actionsContainer}>
        <ExchangeBackButton />
        <Button
          className={classes.buttonStyling}
          variant='contained'
          color='primary'
          type='submit'
          disabled={isSubmissionButtonDisabled}
          data-testid='exchange-submit-button'
          onClick={handleExchangeSubmit}
          classes={{
            root: classes.stepperButton,
            disabled: classes.nextDisabled,
          }}>
          {SUBMIT_EXCHANGE}
        </Button>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles(theme),
  buttonStyling: {
    marginRight: theme.spacing(2),
    borderRadius: '30px',
    padding: '8px 20px',
    fontSize: '16px',
    textTransform: 'none',
  },
}));
