const translations = {
  APPROVE: {
    stringKey: 'approve',
    primaryValue: 'Approve',
    description: 'label for Approve button',
  },
  BILLING_NAME: {
    stringKey: 'billingName',
    primaryValue: 'Billing Name',
    description: 'label for Billing Name input on pidreview search form',
  },
  CANCEL: {
    stringKey: 'cancel',
    primaryValue: 'Cancel',
    description: 'Cancel button for PiD Review Update Form',
  },
  CURRENT_VALUE: {
    stringKey: 'currentValue',
    primaryValue: 'Current Value',
    description: 'Title for Current Value Column in PiD Review Update Form',
  },
  FIND: {
    stringKey: 'find',
    primaryValue: 'Find',
    description: 'label for submit button on pidreview search form',
  },
  FIRST: {
    stringKey: 'first',
    primaryValue: 'First',
    description: 'label for First Name placeholder on pidreview search form',
  },
  FIRST_NAME: {
    stringKey: 'firstName',
    primaryValue: 'First Name',
    description: 'label for First Name input on pidreview search form',
  },
  FOLLOW_UP: {
    stringKey: 'followUp',
    primaryValue: 'Follow-Up',
    description: 'tab label for pid review results with "pending feedback" status',
  },
  HELD: {
    stringKey: 'held',
    primaryValue: 'Held',
    description: 'tab title for pid review results with "held" status',
  },
  LAST: {
    stringKey: 'last',
    primaryValue: 'Last',
    description: 'label for Last Name placeholder on pidreview search form',
  },
  LAST_NAME: {
    stringKey: 'lastName',
    primaryValue: 'Last Name',
    description: 'label for Last Name input on pidreview search form',
  },
  LOCATION: {
    stringKey: 'location',
    primaryValue: 'Location',
    description: 'Title for Location Column in PiD Review Update Form',
  },
  METRIC_ID: {
    stringKey: 'metricId',
    primaryValue: 'Metric ID',
    description: 'label for NBY design ID input on pidreview search form',
  },
  METRIC_IMAGES: {
    stringKey: 'metricImages',
    primaryValue: 'Customization Images',
    description: 'Images for an item with a particular metricId',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'next link',
  },
  NEW_VALUE: {
    stringKey: 'newValue',
    primaryValue: 'New Value',
    description: 'Title for New Value Column in PiD Review Update Form',
  },
  NO_SEARCH_RESULT: {
    stringKey: 'noSearchResults',
    primaryValue: 'No search results',
    description: 'no search results text',
  },
  NO_IMAGES_FOUND: {
    stringKey: 'noImagesFound',
    primaryValue: 'No images found',
    description: 'a label to show after we tried to fetch images but got nothing',
  },
  ORDER_NUMBER: {
    stringKey: 'orderNumber',
    primaryValue: 'Order Number',
    description: 'pidreview table heading for Order Number column',
  },
  ORDER_DATE: {
    stringKey: 'orderDate',
    primaryValue: 'Order Date',
    description: 'pidreview table heading for Order Date column',
  },
  ORDER_SUMMARY_ERROR: {
    stringKey: 'orderSummaryError',
    primaryValue: 'Order Summary Error',
    description: 'error message from order summary api',
  },
  PID_SEARCH_ERROR: {
    stringKey: 'pidSearchError',
    primaryValue: 'PiD Search Error',
    description: 'error message from PiD search api',
  },
  PID_LOCATION: {
    stringKey: 'pidLocation',
    primaryValue: 'PiD Location',
    description: 'pidreview table heading for PiD Location column',
  },
  PID_REVIEWS_SEARCH: {
    stringKey: 'pidReviewSearch',
    primaryValue: 'PiD REVIEWS',
    description: 'label for PiD Reviews search form',
  },
  PID_IMAGE: {
    stringKey: 'pidImage',
    primaryValue: 'PiD Image',
    description: 'descriptive attribute for a PiD review item image',
  },
  PID_TEXT: {
    stringKey: 'pidText',
    primaryValue: 'PiD Text',
    description: 'pidreview table heading for PiD Text column',
  },
  UPDATE_PID: {
    stringKey: 'updatePid',
    primaryValue: 'Update PiD',
    description: 'Title of PiD Review Update Form',
  },
  PID_DISPOSITION_SUCCESS: {
    stringKey: 'pidDispositionSuccess',
    primaryValue: 'PiD processed successfully',
    description: 'Success message shown after PiD approval/reject is successful.',
  },
  PID_DISPOSITION_ERROR: {
    stringKey: 'pidDispositionError',
    primaryValue: 'There was an error while updating PiD. Please try again.',
    description: 'Error message shown after PiD approval/reject has a failure.',
  },
  PID_UPDATE_ERROR: {
    stringKey: 'pidUpdateError',
    primaryValue: 'There was an error while updating PiD. Please try again.',
    description: 'Error message shown after PiD update has a failure.',
  },
  PID_UPDATE_SUCCESS: {
    stringKey: 'pidUpdateSuccess',
    primaryValue: 'PiD update sent successfully.',
    description: 'Success message shown after PiD update is successful.',
  },
  PREV: {
    stringKey: 'prev',
    primaryValue: 'Prev',
    description: 'prev link',
  },
  QUEUE: {
    stringKey: 'reviewQueue',
    primaryValue: 'Queue Review',
    description: 'title of tab to view pid review results with status "review pending"',
  },
  REGION: {
    stringKey: 'region',
    primaryValue: 'Region',
    description: 'label for Region input on pidreview search form',
  },
  REJECT: {
    stringKey: 'reject',
    primaryValue: 'Reject',
    description: 'label for Reject button',
  },
  SAVE: {
    stringKey: 'save',
    primaryValue: 'Save',
    description: 'Save button for PiD Review Update Form',
  },
  SEARCH_COUNTRY: {
    stringKey: 'country',
    primaryValue: 'Country',
    description: 'label for Country input on pidreview search form',
  },
  SEARCH_EMAIL: {
    stringKey: 'email',
    primaryValue: 'Email',
    description: 'label for Email input on pidreview search form',
  },
  SEARCH_GIFT_CARD: {
    stringKey: 'giftCardNumber',
    primaryValue: 'Gift Card Number',
    description: 'label for GiftCard Number input on pidreview search form',
  },
  SELECT_ALL: {
    stringKey: 'selectAll',
    primaryValue: 'select all',
    description: 'aria label for checkbox whose purpose is to select all items in the list',
  },
  SELECT_PID: {
    stringKey: 'selectPidReviewSearchResult',
    primaryValue: 'select PiD Review search result',
    description: 'label for checkbox on each row of PiD Review search results',
  },
  SHIPPING_NAME: {
    stringKey: 'shippingName',
    primaryValue: 'Shipping Name',
    description: 'label for Shipping Name input on pidreview search form',
  },
  TIME_IN_QUEUE: {
    stringKey: 'timeInQueue',
    primaryValue: 'Time In Queue',
    description: 'pidreview table heading for Time In Queue column',
  },
  VALIDATE: {
    stringKey: 'validate',
    primaryValue: 'Validate',
    description: 'Validate button for PiD Review Update Form',
  },
  VALIDATION: {
    stringKey: 'validation',
    primaryValue: 'Validation',
    description: 'Title for Validation Column in PiD Review Update Form',
  },
  VALIDATION_FAILED: {
    stringKey: 'validationFailed',
    primaryValue: 'Validation failed. Please try again.',
    description: 'Error message to show when customization text validation service is unavailable',
  },
  PID_STATUS_CHANGE_ERROR: {
    stringKey: 'pidStatusChangeError',
    primaryValue: 'Review status change error: ',
    description:
      'message that changing the status of a review to queue, follow-up, or held was not successful',
  },
  PID_STATUS_CHANGE_SUCCESS: {
    stringKey: 'pidStatusChangeSuccess',
    primaryValue: 'Review status change was successful',
    description:
      'message that changing the status of a review to queue, follow-up, or held was successful',
  },
  INVALID_CHARACTERS: {
    stringKey: 'invalidCharacters',
    primaryValue: 'Invalid Characters',
    description: 'error label for PiD text update when entered text does not satisfy validation',
  },
  INLINE_VALIDATION_ERROR: {
    stringKey: 'inlineValidationError',
    primaryValue: 'Error fetching validation rules. Please try again.',
    description: 'error message displayed when we fail to fetch inline validation rules from api',
  },
};

export default translations;
