import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { useMutation, useLazyQuery } from 'react-apollo';
import CANCEL_LINE_ITEM from '../../../../mutations/cancelItem.mutation';
import translations from './cancelItems.i18n';
import { areReasonsMissingFrom, isQuantityMissingForLineItems } from '../../../../utils/dialog';
import { v4 as uuidv4 } from 'uuid';
import {
  ResponseStatuses,
  DialogTypes,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
} from '../../../../constants/dialog.const';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import useSnacks from '../../../../hooks/useSnacks';
import AthleteContext from '../../../../store/contexts/athleteContext';
import { stepControlSharedStyles } from '../sharedStyles';
import Geo from '../../../../constants/geos.const';
import { BackButton, NextButton } from '../shared/buttons';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
export default function StepControl() {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { setSnack, setSlowLoading, setError, getLoadingStatus } = useSnacks();
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const [athleteInfo] = useContext(AthleteContext);

  const { prevStep, nextStep, reset, setHasTimedOut } = dialogActions;
  const { selectedLines, activeStep, submissionSteps, lock, hasTimedOut } = dialogState;
  const {
    ITEM_CANCELLATION,
    ERROR,
    FILE_INCIDENT_TICKET,
    REQUEST_SUBMITTED,
    CANCEL_ITEMS,
    CANCEL_ITEMS_TIME_OUT_ERROR_MESSAGE,
  } = useMemoTranslations(translations);

  const geo = orderDetail.omsRegionReference;
  // NA, EMEA, and JP unlocked for order details
  const isLocked = lock && geo !== Geo.US && geo !== Geo.EUROPE && geo !== Geo.JAPAN;

  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      dispatchError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      const { orderDetail } = queryOrderDetailsData;
      dialogDispatch(reset());
      setSnack(`${ITEM_CANCELLATION} ${REQUEST_SUBMITTED}`);
      setOrderDetail(orderDetail);
    },
  });

  const dispatchError = (errorMessage) => {
    !isLocked && dialogDispatch(reset());
    setError(`${ITEM_CANCELLATION} ${ERROR} ${errorMessage}`);
  };

  // If locked, we will display success snack and message to close the tab - not close the dialog
  const lockedCompletedAction = () => {
    setSnack(`${ITEM_CANCELLATION} ${REQUEST_SUBMITTED}`);
    dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true));
  };

  const [sendLineItems, { loading: submitLoading }] = useMutation(CANCEL_LINE_ITEM, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Cancel Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CANCEL_ITEMS_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        setError(`${ITEM_CANCELLATION} ${ERROR} ${FILE_INCIDENT_TICKET}`);
      }
    },
    onCompleted: (data) => {
      const { cancelLineItem } = data;
      /*
       * data.response is available only when the job is completed and successful
       * calls order details when cancel is successful
       * shows error message from api on order details if the cancel is not successful
       * shows time out message on order details page if the response is pending on in progress
       */
      if ((cancelLineItem.status = ResponseStatuses.COMPLETED && cancelLineItem.response)) {
        // if locked, display close tab message, else query order details + close dialog
        isLocked
          ? lockedCompletedAction()
          : queryOrderDetails({
              variables: {
                orderNumber: orderDetail.orderNumber,
              },
            });
      } else if (
        cancelLineItem.status === ResponseStatuses.IN_PROGRESS ||
        cancelLineItem.status === ResponseStatuses.PENDING
      ) {
        dispatchError(CANCEL_ITEMS_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (cancelLineItem.error) {
        // Logging the error message so we do not lose track of it.
        console.error('CancelLineItem Error', cancelLineItem);
        dispatchError(FILE_INCIDENT_TICKET);
      }
    },
  });

  const thereAreReasonsMissing = areReasonsMissingFrom(selectedLines, DialogTypes.CANCEL);
  const isQuantityMissing = isQuantityMissingForLineItems(selectedLines, DialogTypes.CANCEL);
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionStep = submissionSteps.includes(activeStep);
  const isSubmissionLoading = getLoadingStatus() || submitLoading;

  const handleCancelSubmit = () => {
    setSlowLoading();
    const input = structureCancelLineInput(selectedLines, athleteInfo.email);
    sendLineItems({
      variables: {
        orderNumber: orderDetail.orderNumber,
        input,
        region: orderDetail.omsRegionReference,
        timeout: ApiTimeOut,
      },
    });
  };

  /*
  For BOPIS orders "next" button is enabled on cancel step 1 only if at least one of the orderline 
  has cancellableQuantity > 0. This condition has been changed from all the orderlines to have 
  cancellable quantity more than 0 to at least one of the line. This is to cancel BOPIS orders 
  where some of the orderlines are cancelled in the store (short shipped).
  */

  const determineBOPISNext = () => orderDetail.orderLines.some((ol) => ol.cancellableQuantity > 0);
  const nextDisabled = orderDetail.omoboFlags.isBOPIS
    ? !determineBOPISNext()
    : selectedItemKeys.length === 0;

  const isSubmissionButtonDisabled =
    isSubmissionLoading || thereAreReasonsMissing || hasTimedOut || isQuantityMissing;

  if (!isSubmissionStep)
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <NextButton disabled={nextDisabled} onClick={() => dialogDispatch(nextStep())} />
      </div>
    );
  else
    return (
      <div className={classes.actionsContainer}>
        <BackButton onClick={() => dialogDispatch(prevStep())} />
        <Button
          variant='contained'
          color='primary'
          type='submit'
          disabled={isSubmissionButtonDisabled}
          data-testid='cancel-submit-button'
          onClick={handleCancelSubmit}
          classes={{
            root: classes.stepperButton,
            disabled: classes.nextDisabled,
          }}>
          {CANCEL_ITEMS}
        </Button>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

/**
 * This function takes dialog form data (selectedLines) and reformats to the
 * format expected by the graphQL mutation.
 *
 * @param {Object} selectedLines – the dialog form data
 * @param {Object} contactUser – the email of the acting user
 * @returns {Object} structureCancelLineInput
 */
export const structureCancelLineInput = (selectedLines, contactUser) => {
  return {
    request: {
      requestId: uuidv4(),
      orderLines: Object.values(selectedLines).map((line) => ({
        cancellationQty: line.quantityToCancel || 1,
        lineNumber: line.lineNumber,
        universalProductCode: line.item.universalProductCode,
        reasonCode: line.cancelReason.code,
        reasonText: line.cancelReason.text,
        lineNotes: [
          {
            contactTime: new Date().toISOString(),
            contactType: 'CSR Cancel',
            contactUser,
            noteText: line.cancelReason.text,
          },
        ],
      })),
    },
  };
};
