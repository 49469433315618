import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, FormLabel } from '@material-ui/core';
import { AdminContext } from '../../store/contexts/adminContext';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import translations from './admin.i18n.js';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import config from '../../utils/config';
import useAuth from '../../hooks/useAuth';
import useSnacks from '../../hooks/useSnacks';

/**
 * Main react component housing the Permissions page
 */
export default function PermissionsForm({ activeGroup, index }) {
  const classes = useStyles();
  const [adminState] = useContext(AdminContext);
  const { i18nString } = useContext(NikeI18nContext);

  const [formState, setFormState] = useState({});
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const { permissions, refetchGroups } = adminState;
  const { oktaToken } = useAuth();
  const { getLoadingStatus, setError, setLoading } = useSnacks();

  const { SAVE, CANCEL, ARIA_GROUP_PERMISSIONS } = mapValues(translations, i18nString);

  useEffect(() => {
    /*
    step 1: turn all permissions into an object dictionary structured like so:
    { permission name: toggle boolean }
     */

    // if we're loading, don't display anything, to avoid displaying false information
    if (getLoadingStatus()) return;

    const permissionsObj = permissions.reduce((obj, cur, i) => {
      return {
        ...obj,
        [cur]: false,
      };
    }, {});

    /*
    step 2: use the dictionary to find permissions toggled ON for the active group and set their
    toggle boolean to true.
     */
    activeGroup.permissions?.map((activePermission) => (permissionsObj[activePermission] = true));

    // step 3: save as form state
    setFormState(permissionsObj);
  }, [activeGroup]);

  /*
   * Makes a shallow copy of the form state to use dictionary lookup to toggle clicked permission,
   * and sets updated copy as new form state.
   * @param {Array} permission - array with two values: [permission name, toggle boolean]
   */
  function togglePermission(permission) {
    const newFormState = {
      ...formState,
    };
    newFormState[permission[0]] = !newFormState[permission[0]];
    setFormState(newFormState);
    setIsFormUpdated(true);
  }

  /**
   * onClick action for submitting the permission form changes.
   */
  async function handleSubmit() {
    setLoading();
    /** due to db differences in global we want to send the activeGroup's value
    in China we want to send the entire group so we can use it's id in a query */
    const adGroup = process.env.PUBLIC_URL ? activeGroup : activeGroup.value;
    const updatedPermissions = { ...formState };

    let requestBody = {
      group: adGroup,
      permissions: updatedPermissions,
      region: activeGroup.region,
    };

    const response = await fetch(config.foundry.editPermissions, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': oktaToken,
      },
      method: 'post',
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      // We have successfully submitted the change. Let's update our groups data.
      refetchGroups();
    } else {
      setError('Oh noes. Updating of permissions has failed.');
    }
  }

  return (
    <div className={classes.permissionsForm}>
      <FormControl
        className={classes.formControl}
        component='fieldset'
        aria-label={ARIA_GROUP_PERMISSIONS}>
        <FormLabel component='legend' className={classes.permissionsTitle}>
          Permissions
        </FormLabel>
        <FormGroup className={classes.formGroup} classes={{ root: classes.formGroupRoot }}>
          {Object.entries(formState).map((permission, index) => (
            /*
            Object.entries turns the formState object into an array of permission arrays like so:
            [[permission1, true], [permission2, false]]
            for each entry, p[0] is the permission name and p[1] is the toggle boolean
             */
            <FormControlLabel
              className={classes.formControlLabel}
              key={index}
              control={
                <Switch
                  color='primary'
                  name={permission[0]}
                  checked={permission[1]}
                  onChange={() => togglePermission(permission)}
                />
              }
              label={permission[0]}
            />
          ))}
        </FormGroup>
      </FormControl>
      <div className={classes.formButtons}>
        <Button
          className={classes.formButton}
          variant='contained'
          type='button'
          disabled={!isFormUpdated}>
          {CANCEL}
        </Button>
        <Button
          className={classes.formButton}
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleSubmit}
          disabled={!isFormUpdated}>
          {SAVE}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  permissionsTitle: {
    marginBottom: theme.spacing(2),
  },
  permissionsForm: {
    paddingTop: theme.spacing(4),
    width: '100%',
  },
  formButtons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formButton: {
    margin: theme.spacing(1),
  },
  formControl: {
    height: 'calc(100% - 68.5px)',
  },
  formControlLabel: {
    display: 'block',
    marginLeft: 0,
  },
  formGroup: {
    // 176 px of padding, all told
    height: '100%',
    maxHeight: 'calc(100vh - 176px)',
    width: '100%',
    overflowY: 'auto',
  },
  formGroupRoot: {
    display: 'block',
  },
}));
