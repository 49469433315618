import React, { createContext, useReducer, useEffect } from 'react';
import config from '../../utils/config';
import { settingsReducer } from '../reducers/settingsReducer';
import { actions } from '../actions/settingsActions';
import useSnacks from '../../hooks/useSnacks';
import useAuth from '../../hooks/useAuth';
import decodeToken from 'jwt-decode';

export const SettingsContext = createContext([{}, () => {}]);
export const defaultState = {
  language: 'en',
};

const { Provider } = SettingsContext;

/**
 * A provider for the OMOBO settings
 *
 * @param {Object} props  – React props
 */
export const SettingsProvider = ({ children }) => {
  const { setSnack, setError } = useSnacks();
  const [state, reducer] = useReducer(settingsReducer, defaultState);
  const { oktaToken } = useAuth();

  const fetchAthleteSettings = async (snackMessage = false) => {
    const response = await fetch(config.foundry.athleteSettings, {
      headers: {
        authorization: oktaToken,
      },
      cors: true,
    });

    if (response.ok) {
      const athleteSettings = await response.json();
      if (Object.keys(athleteSettings).length !== 0) {
        reducer(actions.setPreferences(athleteSettings));
        // if we don't have any settings data, add the athlete email from the token instead
        // this way we know when this request has completed
      } else {
        const token = decodeToken(oktaToken);
        reducer(actions.setEmail(token.sub));
      }
      // After a successful retrieval, reset the flag so it can be retrigger as needed.
      if (snackMessage) {
        setSnack('Retrieved athletes settings');
      }
    } else {
      setError(`${response.statusText} ${response.status}`);
    }
  };

  useEffect(() => {
    fetchAthleteSettings();
  }, []);

  const newState = { ...state };
  return <Provider value={[newState, reducer]}>{children}</Provider>;
};

export default SettingsContext;
