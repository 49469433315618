const translations = {
  NO_PERMISSION_MESSAGE: {
    description: "Message displayed when athletes don't have permission to perform an action",
    primaryValue:
      "Sorry you don't have the permission to perform this action. Please close the tab and go back to SwooshDesk",
    stringKey: 'noPermission',
  },
};

export default translations;
