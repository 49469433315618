/** React / Utils */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { isValidBIC, isValidIBAN } from 'ibantools';
import PropTypes from 'prop-types';

/** Material UI */
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import translations from './paymentMethodMods.i18n';
import OrderContext from '../../../../../store/contexts/orderContext';
import ADD_BANK_MUTATION from '../../../../../mutations/addBank.mutation';
import useSnacks from '../../../../../hooks/useSnacks';
import EUCountries from '../../../../../constants/address/EUCountries.const';
import Loading from '../../../../shared/loading';
import { getOmoboFlags } from '../../../../../utils/orderLine';
import { GenerateOrderNotes } from '../../../../../hooks/usePaymentMods';

/**
 * form used to add bank payment to an order
 * @param {Object}: {
 *   @param {boolean} triggerSubmission - boolean indicating if the submit button has been clicked
 *   @param {function} setTriggerSubmission - function that sets triggerSubmission in modal state
 *   @param {function} shouldSubmitBeDisabled - function that sets submitDisabled in modal state
 *   @param {function} updatePaymentMethods - function that refetches payment details
 *   @param {function} setDialogOpen - function that sets isDialogOpen in state
 * }
 * @returns React component
 */
const AddBankPaymentForm = ({
  triggerSubmission,
  setTriggerSubmission,
  shouldSubmitBeDisabled,
  updatePaymentMethods,
  setDialogOpen,
}) => {
  const classes = useStyles();
  const { setError, setSnack, setLoading } = useSnacks();
  const [orderDetail] = useContext(OrderContext);
  const { orderNumber, billTo, orderLines } = orderDetail;
  const { isBOPIS } = getOmoboFlags(orderLines);
  const [bankForm, setBankForm] = useState({
    address1: '',
    address2: '',
    city: '',
    country: '',
    firstName: '',
    lastName: '',
    iban: '',
    swiftCode: '',
  });
  const { address1, address2, city, country, firstName, lastName, iban, swiftCode } = bankForm;
  const [showFieldValidation, setShowFieldValidation] = useState({
    iban: false,
    swiftCode: false,
  });
  const [ibanIsValid, setIbanIsValid] = useState(false);
  const [swiftIsValid, setSwiftIsValid] = useState(false);
  const {
    ADD_BANK_ERROR,
    ADD_BANK_SUCCESS,
    BILLING_ADDRESS_1,
    BILLING_ADDRESS_2,
    BILLING_CITY,
    COUNTRY,
    FIRST_NAME,
    IBAN,
    IBAN_NOT_VALID,
    LAST_NAME,
    SWIFT_BIC,
    SWIFT_NOT_VALID,
  } = useMemoTranslations(translations);

  const handleBankFormChange = ({ target }) => {
    setBankForm((form) => ({ ...form, [target.name]: target.value }));
  };

  const handleOnFocus = ({ target: { name } }) => {
    setShowFieldValidation({ ...showFieldValidation, [name]: false });
  };

  const handleOnBlur = ({ target: { name } }) => {
    if (name === 'iban') {
      setIbanIsValid(isValidIBAN(iban));
    } else if (name === 'swiftCode') {
      setSwiftIsValid(isValidBIC(swiftCode));
    }
    setShowFieldValidation({ ...showFieldValidation, [name]: true });
  };

  const [addBank, { loading: addBankLoading }] = useMutation(ADD_BANK_MUTATION, {
    onError: (error) => {
      // resets trigger Submission so athlete can resubmit when there is an error
      setTriggerSubmission(false);
      setError(`${ADD_BANK_ERROR}: ${error.message}`);
    },
    onCompleted: (response) => {
      const error = response?.addBank?.error;
      if (error) {
        setTriggerSubmission(false);
        setError(`${ADD_BANK_ERROR} ${error.message}`);
        return;
      }
      setDialogOpen(false);
      setSnack(ADD_BANK_SUCCESS);
      updatePaymentMethods();
    },
  });

  const orderNotes = GenerateOrderNotes('CSR Payment Add', `Added OFBT ${iban}`);

  useEffect(() => {
    if (triggerSubmission) {
      addBank({
        variables: {
          input: {
            orderInfo: {
              id: orderNumber,
            },
            bankTransferInfo: {
              accountName: `${firstName} ${lastName}`,
              iban,
              swiftCode,
              billingInfo: {
                firstName,
                lastName,
                city,
                country,
                address1,
                address2,
              },
            },
            orderNotes,
          },
        },
      });
      setLoading();
    }
  }, [triggerSubmission]);

  useEffect(() => {
    if (iban && swiftCode && firstName && lastName && address1 && city && country) {
      shouldSubmitBeDisabled(false);
    } else {
      shouldSubmitBeDisabled(true);
    }
  }, [bankForm]);

  // when the app first loads we want to ensure we have a default country
  useEffect(() => {
    setBankForm((form) => ({ ...form, country: billTo?.address?.country }));
  }, [orderDetail]);

  const countryName = useMemo(
    () => EUCountries.find((euCountry) => euCountry.abbreviation === country),
    [country]
  );

  return (
    <div className={classes.addressGrid}>
      <TextField
        className={classes.customField}
        required
        value={iban}
        name={'iban'}
        data-testid={'iban'}
        style={{ gridArea: 'IB' }}
        label={IBAN}
        variant='outlined'
        onChange={handleBankFormChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        error={showFieldValidation.iban && !ibanIsValid}
        helperText={showFieldValidation.iban && !ibanIsValid && IBAN_NOT_VALID}
      />
      <TextField
        className={classes.customField}
        required
        onChange={handleBankFormChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        value={swiftCode}
        name={'swiftCode'}
        variant='outlined'
        data-testid={'swift-code'}
        style={{ gridArea: 'SC' }}
        label={SWIFT_BIC}
        error={showFieldValidation.swiftCode && !swiftIsValid}
        helperText={showFieldValidation.swiftCode && !swiftIsValid && SWIFT_NOT_VALID}
      />
      <TextField
        className={classes.customField}
        value={firstName}
        name='firstName'
        variant='outlined'
        data-testid={'input-first-name'}
        style={{ gridArea: 'FN' }}
        label={FIRST_NAME}
        onChange={handleBankFormChange}
        required
        disabled={isBOPIS}
      />
      <TextField
        className={classes.customField}
        value={lastName}
        name='lastName'
        variant='outlined'
        data-testid={'input-last-name'}
        style={{ gridArea: 'LN' }}
        label={LAST_NAME}
        onChange={handleBankFormChange}
        required
        disabled={isBOPIS}
      />
      <TextField
        className={classes.customField}
        value={address1}
        name='address1'
        variant='outlined'
        data-testid={'ofbt-billing-address-1'}
        style={{ gridArea: 'A1' }}
        label={BILLING_ADDRESS_1}
        onChange={handleBankFormChange}
        required
        disabled={isBOPIS}
      />
      <TextField
        className={classes.customField}
        value={address2 || undefined}
        name='address2'
        variant='outlined'
        data-testid={'ofbt-billing-address-2'}
        style={{ gridArea: 'A2' }}
        label={BILLING_ADDRESS_2}
        onChange={handleBankFormChange}
        disabled={isBOPIS}
      />
      <TextField
        className={classes.customField}
        value={city}
        name='city'
        variant='outlined'
        data-testid={'ofbt-billing-city'}
        style={{ gridArea: 'CI' }}
        label={BILLING_CITY}
        onChange={handleBankFormChange}
        required
        disabled={isBOPIS}
      />
      {/*  for tax reasons athletes cannot select a different country */}
      <TextField
        required
        className={classes.disabledCountry}
        data-testid={'country'}
        disabled={true}
        value={countryName?.name}
        label={COUNTRY}
      />
      {/* uncomment for future ticket https://jira.nike.com/browse/FORGE-11401 */}
      {/* <FormControlLabel
        control={
          <Checkbox defaultChecked classes={{ root: classes.checkbox, checked: classes.checked }} />
        }
        label='Suspend previous payment method'
        style={{ gridArea: 'CB' }}
      /> */}
      {addBankLoading && <Loading />}
    </div>
  );
};

AddBankPaymentForm.propTypes = {
  triggerSubmission: PropTypes.bool,
  setTriggerSubmission: PropTypes.func,
  shouldSubmitBeDisabled: PropTypes.func,
  updatePaymentMethods: PropTypes.func,
  setDialogOpen: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  addressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " IB IB IB SC SC SC "
      " FN FN FN LN LN LN "
      " A1 A1 A1 A2 A2 A2 "
      " CI CI DC DC .. .. "
    `,
    gridGap: '1.5rem 1.5rem',
  },
  customField: {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  disabledCountry: {
    'width': '100%',
    'gridArea': 'DC',
    '& .MuiInputBase-formControl': {
      '& input': {
        color: theme.palette.common.black,
      },
      // removing underline to make it clear athletes can't change country
      '&::before': {
        border: '0',
      },
      '&::after': {
        border: '0',
      },
    },
  },
  // checkbox: {
  //   '&$checked': {
  //     color: theme.palette.common.black,
  //   },
  // },
  // checked: {},
}));

export default AddBankPaymentForm;
