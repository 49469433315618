import { Channel } from '../../../../../constants/dialog.const';
import { restructureAddress, generateOrderLines, getEmail } from './utility';
import { isReturnLabelNotRequired } from '../../../../../utils/dialog';
import { ShippingOptions } from '../../../../../constants/shippingOptions.const';
import { hasAndIsWarehouseWorkerOnly } from '../../../../../utils/adGroups';
/**
 * Convert the dialog state into the format that the return captures mutation input is expecting.
 * @param {Object} dialogState The dialogs state.
 * @param {Object} consumerState The sales containg consumer details.
 * @param {Object} orderDetails The sales order details.
 * @param {Boolean} permissionToSendReturnLabel Boolean that specifies if the athlete has permission
 * to send return label.
 * @param {String} receivingNode receivingNode that will be sent in return payload
 * @param {Object} standardCarrierAlphaCode standardCarrierAlphaCode that will be sent
 * in return payload
 * @param {Object} athleteInfo Object containing athlete details.
 */
export const generateReturnCapturePayload = (
  dialogState,
  consumerState,
  orderDetails,
  permissionToSendReturnLabel,
  receivingNode,
  standardCarrierAlphaCode,
  athleteInfo
) => {
  const refundAsGiftCard = dialogState.returnPaymentOption === 'giftcard';
  const orderLines = generateOrderLines(dialogState.selectedLines);
  const geo = orderDetails.omsRegionReference;
  const createDate = new Date();
  const createReturnLabelFlag =
    permissionToSendReturnLabel && !isReturnLabelNotRequired(dialogState.selectedLines, geo);

  let returnMethod = '';
  let { shippingOption } = dialogState;
  let { NON_PICKUP } = ShippingOptions;
  /* 
      mp-titan wants us to send returnMethod which will all be `PICK_UP` for KOREA region unless, 
      the return is being created by either a warehouse/L1/L2/L3 employee and shippingOption is 
      nonPickup, which would mean that the item is already at the warehouse and the return is being
      created after the item has already been received. 
      */
  if (shippingOption === NON_PICKUP && hasAndIsWarehouseWorkerOnly(athleteInfo.groups, 'US')) {
    returnMethod = 'RECEIVED_AT_WAREHOUSE';
  } else if (createReturnLabelFlag) {
    returnMethod = 'CARRIER_DROP_OFF';
  } else {
    returnMethod = undefined;
  }

  const result = {
    customerProfileReference: orderDetails.customerProfileReference,
    // mp-titan team asked us to send this channel info
    channel: Channel,
    // Limitation in Doms as assistingAgentReference can only take 20 characters
    assistingAgentReference: athleteInfo?.name?.substring(0, 20) || '',
    omsRegionReference: geo,
    locale: orderDetails.locale,
    currency: orderDetails.currency,
    orderNumber: orderDetails.orderNumber,
    // mp-titan wants us to send channel details for this field
    captureSystem: Channel,
    email: getEmail(dialogState),
    orderCreateDate: createDate.toISOString(),
    refundOrderFlag: refundAsGiftCard,
    createReturnLabelFlag,
    receivingNode: receivingNode,
    orderLines,
    returnMethod,
  };

  /*
      Location the physical return label will be sent to.
      Setting infomation related to return labels when
      1. athletes have permission to send/resend label or
    */
  if (createReturnLabelFlag) {
    result.shipTo = { ...dialogState.shipTo };
    result.shipFrom = restructureAddress(dialogState.address, dialogState);
    /* 
          These are the conditions if the order is for the US Geo.
          Currently dialogState.shippingOption only changes if the consumer is registered 
          or if the consumer is guest and they are given a courtesy prepaid label.
          We should add a check for permissions to make sure the courtesy prepaid label is eligible.
        */
    if (consumerState.isRegistered) {
      /* 
        HACK: For some reason we are getting shippingOption as 'none' intermittently. 
        Just to avoid this scenario we are sending the standardCarrierAlphaCode as default option.
          */
      // TODO: Update code to send default option as per geo
      if (dialogState.shippingOption === 'none') {
        result.standardCarrierAlphaCode = 'UPS';
      } else {
        // TODO: update this with permissionToChosePrepaidForGuest ^^
        result.standardCarrierAlphaCode = dialogState.shippingOption.toUpperCase();
      }
    } else if (dialogState.shippingOption !== 'none') {
      // We will get here when guest user gets courtesy prepaid label
      result.standardCarrierAlphaCode = dialogState.shippingOption.toUpperCase();
    } else {
      // Guest users not getting prepaid return label
      result.standardCarrierAlphaCode = standardCarrierAlphaCode;
    }
  }

  /* 
      Overwrite shipFrom address with billTo address if 
          a) the refund is going to gift card location that the physical giftcard will be sent to,
               replace shipFrom with the values entered
          c) if all the order lines that is being returned are gift cards, do the same
    */
  if (refundAsGiftCard || dialogState.areAllOrderLinesGiftCards) {
    result.shipFrom = restructureAddress(dialogState.gcShippingAddress, dialogState);
  }
  return result;
};

/**
 * Convert state into the format that the return location mutation input is expecting.
 * @param {*} dialogState The dialogs state.
 * @param {*} consumerState The consumer state.
 * @param {*} orderDetails The sales order details.
 */
export const generateReturnLocationPayload = (dialogState, consumerState, orderDetails) => {
  let orderLineReference = '';
  const selectedLineKeys = Object.keys(dialogState.selectedLines);
  if (selectedLineKeys.length) {
    const selectedLine = dialogState.selectedLines[selectedLineKeys[0]];
    if (selectedLine?.orderLineKey) {
      orderLineReference = selectedLine.orderLineKey;
    }
  }

  return {
    registeredUser: consumerState.isRegistered,
    orderLineReference,
    omsRegionReference: orderDetails.omsRegionReference,
    // the below is for gift card orders when shipping address won't be available
    city: dialogState?.address?.city
      ? dialogState?.address?.city
      : dialogState?.gcShippingAddress?.city,
    state: dialogState?.address?.state
      ? dialogState?.address?.state
      : dialogState?.gcShippingAddress?.state,
    country: dialogState?.address?.country
      ? dialogState?.address?.country
      : dialogState?.gcShippingAddress?.country,
  };
};

/**
 *
 * @param {*} address - Address for which validation needs to be done
 * @param {*} locale - Geo info
 */
export const generateAddressValidationPayload = (address, locale) => {
  const { address1, address2, address3, city, state, postalCode, country } = address;
  return {
    address1,
    address2,
    address3,
    city,
    state,
    postalCode,
    country: country || 'US',
    locale,
  };
};
