import React, { useContext } from 'react';
import { useLazyQuery } from 'react-apollo';

import PID_REVIEW_SEARCH_QUERY from '../queries/pidSearch.query';
import useSnacks from './useSnacks';
import searchActions from '../store/actions/pidReviewSearchActions';
import { PidReviewSearchContext } from '../store/contexts/pidReviewSearchContext';
import useMemoTranslations from './useMemoTranslations';

import translations from '../components/pidReview/pidReview.i18n';

/**
 * Hook to encapsulate PiD search functionality for better re-use.
 *
 * @returns lazy query function to trigger the PiD query, loading boolean
 */
const useGetPids = () => {
  const { setError, setLoading, setStopLoading } = useSnacks();
  const { setSearchResults, setSearchTablePage } = searchActions;
  const [searchState, searchDispatch] = useContext(PidReviewSearchContext);

  const pidSearchFormState = React.useMemo(() => searchState.pidSearchFormState, [
    searchState.pidSearchFormState,
  ]);

  // Call to pid review search api
  const { PID_SEARCH_ERROR } = useMemoTranslations(translations);
  const [queryPidSearch, { data: pidReviewsData, loading, error: summaryError }] = useLazyQuery(
    PID_REVIEW_SEARCH_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        setError(`${PID_SEARCH_ERROR} ${summaryError.message}`);
      },
      onCompleted: () => {
        const {
          getPidReviews: { totals: newTotals, data: searchResults },
        } = pidReviewsData;
        searchDispatch(setSearchTablePage(1));
        searchDispatch(
          setSearchResults({
            totals: newTotals,
            data: searchResults,
          })
        );
        if (!searchResults?.length) {
          setStopLoading();
        }
      },
    }
  );

  const runQueryPidSearch = () => {
    const filters = [];
    Object.entries(pidSearchFormState).forEach(([key, value]) => {
      if (value && value !== 'ALL') {
        filters.push({ key, value });
      }
    });
    setLoading();

    queryPidSearch({
      variables: {
        filters,
        anchor: 0,
      },
    });
  };

  return { runQueryPidSearch, loading };
};

export default useGetPids;
