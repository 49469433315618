/* React */
import React, { useContext, useState, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

/* Material-UI */
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/* Constants/Utils */
import { getDateByRelativeDays } from './../../utils/date';
import USStates from './../../constants/address/USStates.const';
import OtherCountries from './../../constants/address/OtherCountries.const';
import EUCountries from './../../constants/address/EUCountries.const';
import CNStates from './../../constants/address/CNStates.const';
import JPStates from './../../constants/address/JPStates.const';
import { orderLineTypes, orderTypesList, orderStatuses } from './../../constants/order.const';
import { Partners, EmeaPartners, EmeaEnterprises, Geos } from './../../constants/origin.const';
import { Developer } from './../../constants/permissions.const';
import { paymentTypes } from '../../constants/paymentTypes.const';

/* Local */
import OrderSearchTable from './orderSearchTable';
import useSnacks from '../../hooks/useSnacks';
import Loading from './../shared/loading';
import translations from './orderSearch.i18n';
import ORDER_SUMMARY_QUERY from './../../queries/orderSummary.query';
import searchActions from './../../store/actions/orderSearchActions';
import Geo from './../../constants/geos.const';
import { SearchContext } from './../../store/contexts/orderSearchContext';
import { useHistoryPushWithSessionId } from '../../hooks/useHistorySessionId';
import TRACKING_SHIPMENTS_QUERY from '../../queries/shipmentsByTracking.query';
import useMemoTranslations from '../../hooks/useMemoTranslations';
import HasPermission from './../shared/hasPermission';
import useHasPermission from '../../hooks/useHasPermission';
import { PROD_ENVIRONMENTS } from './../../constants/env.const';

const SearchForm = () => {
  const classes = useStyles();
  const {
    setSearchFieldValues,
    resetSearchFieldValues,
    setSearchResults,
    setPagination,
    setAnchor,
    setShowSearchPage,
    setSort,
  } = searchActions;
  const {
    ADVANCED_SEARCH,
    ARIA_ADDRESS_TYPE,
    BILLING_ADDRESS,
    CLEAR_SEARCH,
    LAST_10_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
    LAST_120_DAYS,
    ORDER_SUMMARY_ERROR,
    ORDER_SEARCH,
    SEARCH_BUTTON,
    SEARCH_CITY,
    SEARCH_COUNTRY,
    SEARCH_DATE_RANGE,
    SEARCH_EMAIL,
    SEARCH_FIRST_NAME,
    SEARCH_GIFT_CARD,
    SEARCH_LAST_NAME,
    SEARCH_ORDER_NUMBER,
    SEARCH_ITEM_TYPE,
    SEARCH_PARTNER,
    SEARCH_GEO,
    SEARCH_RESULTS,
    SEARCH_SAP_SALES_ORDER,
    SEARCH_SAP_PURCHASE_ORDER,
    SEARCH_STATE,
    SEARCH_UPC,
    SEARCH_PHONE,
    SEARCH_STYLE_NUMBER,
    SEARCH_ZIP,
    SHIPPING_ADDRESS,
    TODAY,
  } = useMemoTranslations(translations);
  const sapSalesOrderKey = 'orderLines.statuses.contractNumber';
  const sapPurchaseOrderKey = 'orderLines.statuses.chainedOrderNumber';

  const setRoute = useHistoryPushWithSessionId();
  const { setError } = useSnacks();
  const [searchState, searchDispatch] = useContext(SearchContext);
  const { hasPermission } = useHasPermission();

  // Manage which search menu is displayed with local state.
  const [advancedOpen, setAdvancedOpen] = useState(false);
  // Store relative iso date-time strings for date-range select box.
  const [dateRangeOptions] = useState({
    [TODAY]: getDateByRelativeDays(0),
    [LAST_10_DAYS]: getDateByRelativeDays(10),
    [LAST_30_DAYS]: getDateByRelativeDays(30),
    [LAST_90_DAYS]: getDateByRelativeDays(90),
    [LAST_120_DAYS]: getDateByRelativeDays(120),
  });

  // checks if we are in china
  const isInCN = process.env.PUBLIC_URL === '/portlets/postpurchase';

  // clear search filters on component unmount
  useEffect(() => {
    return () => clearSearch();
  }, []);

  // Call to order summary api
  const [
    queryOrderSummary,
    { data: summaryData, loading: summaryLoading, error: summaryError },
  ] = useLazyQuery(ORDER_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${ORDER_SUMMARY_ERROR} ${summaryError.message}`);
    },
    onCompleted: () => {
      const { csOrderSummary } = summaryData;
      searchDispatch(setSearchResults(csOrderSummary.objects));
      searchDispatch(setPagination(csOrderSummary.pages));

      if (csOrderSummary.objects.length === 1) {
        const orderNo = csOrderSummary.objects[0].orderNumber;
        searchDispatch(setShowSearchPage(false));
        setRoute(`/order/${orderNo}/details`);
      }
    },
  });

  // Call to shipments api (for tracking number only)
  const [
    queryShipmentsByTrackingNo,
    { data: shipmentsData, loading: shipmentsLoading, error: shipmentsError },
  ] = useLazyQuery(TRACKING_SHIPMENTS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${ORDER_SUMMARY_ERROR} ${shipmentsError.message}`);
    },
    onCompleted: () => {
      const { shipmentsByTracking } = shipmentsData;
      const shipments = shipmentsByTracking.objects;
      searchDispatch(setSearchResults(shipments));
      if (shipmentsByTracking.pages) searchDispatch(setPagination(shipmentsByTracking.pages));

      if (shipments.length) {
        const sortedShipmentsNewestFirst = [...shipments].sort((a, b) => {
          const aDate = new Date(a.creationDate);
          const bDate = new Date(b.creationDate);
          return bDate - aDate;
        });
        const orderNo = sortedShipmentsNewestFirst[0].orderNumber;
        searchDispatch(setShowSearchPage(false));
        setRoute(`/order/${orderNo}/details`);
      }
    },
  });

  /**
   * Prepare search fields for query.
   *
   * @return {array} array of filters objects with key and value properties.
   */
  function prepSearchFilters() {
    // Extract searchFields from searchState in order to query based on user input.
    const newSearchFields = { ...searchState.searchFields };

    if (newSearchFields[sapSalesOrderKey]) {
      return [
        {
          key: sapSalesOrderKey,
          value: newSearchFields[sapSalesOrderKey],
        },
      ];
    }

    if (newSearchFields[sapPurchaseOrderKey]) {
      return [
        {
          key: sapPurchaseOrderKey,
          value: newSearchFields[sapPurchaseOrderKey],
        },
      ];
    }

    // when in CN athlete's cannot select a GEO, but should only see results for Nike CN
    if (isInCN) {
      newSearchFields.omsRegionReference = Geo.CHINA;

      /**
       * if we're in europe and the selected channel is an emea enterprise
       * we need to replace omsRegionReference with the partner's enterprise code
       */
    } else if (newSearchFields.omsRegionReference === Geo.EUROPE) {
      if (newSearchFields.channel in EmeaEnterprises) {
        newSearchFields.omsRegionReference = EmeaEnterprises[newSearchFields.channel];
        newSearchFields.channel = null;
        /**
         * footlocker only has orders when the omsRegionReference is WHOLESALEEUROPE
         */
      } else if (newSearchFields.channel === 'footlocker.digital.web') {
        newSearchFields.omsRegionReference = 'WHOLESALEEUROPE';
      }
    }

    if (newSearchFields.paymentType) {
      newSearchFields['paymentMethods.paymentType'] = newSearchFields.paymentType;
      delete newSearchFields.paymentType;
    }

    /* In order to pass the appropriate filter keys for address values, 
    we remove the address object from our filters object, and iterate over
    the address values in searchState setting new key names as we add the
    values to the filters argument for the GraphQL query. */
    delete newSearchFields.address;
    const filters = [];
    for (const fieldName in newSearchFields) {
      if (newSearchFields[fieldName]) {
        const value =
          typeof newSearchFields[fieldName] === 'string'
            ? newSearchFields[fieldName].trim()
            : newSearchFields[fieldName];
        filters.push({
          key: fieldName,
          value,
        });
      }
    }

    const { address } = searchState.searchFields;
    for (const addressKey in address) {
      if (address[addressKey]) {
        /**
         * Using the getAddressFilterKey utility, we add the address value to the search query
         * renaming the key based on the address type selected by the user. For example, if the
         * key the user selected billing address and the key is 'city', then the key gets set here
         * as 'billTo.address.city'. If it were a shipping address, the key would get set as
         * 'orderLines.shipTo.address.city'.
         */
        filters.push({
          key: getAddressFilterKey(filters.addressIsBilling, addressKey),
          value: address[addressKey].trim(),
        });
      }
    }

    // Remove the addressIsBilling filter object prior to utilizing current filters.
    return filters.filter((filter) => filter.key !== 'addressIsBilling');
  }

  /**
   * Function that handles clicking submit button or pressing enter key
   * @param {*} event - JS event on clicking submit or pressing enter key
   */
  const handleSubmit = (event) => {
    /* Prevent submit if values have been added to address fields,
    but an address type has not been selected */
    if (!isFormReadyToSubmit()) {
      return;
    }
    searchDispatch(setAnchor(0));
    searchDispatch(setSort('orderSubmitDateDesc'));

    const filters = prepSearchFilters();

    if (event.key === 'Enter' || !event.key) {
      // Only tracking number searches require a different query
      const trackingNumInput = filters.find((f) => f.key === 'trackingNumber');
      if (trackingNumInput) {
        queryShipmentsByTrackingNo({
          variables: {
            trackingNumber: trackingNumInput.value,
          },
        });
      } else {
        queryOrderSummary({
          variables: {
            filters,
            anchor: 0,
            sort: 'orderSubmitDateDesc',
          },
        });
        document.getElementById('welcomeMessage').scrollIntoView();
      }
    }
  };

  /**
   * Based on the link that is passed, calls order summary api
   * @param {*} link - Prev or Next link
   */
  function handleSearchNavigation(link) {
    let updatedAnchor = '';
    if (link === 'prev') {
      updatedAnchor = searchState.anchor - 100;
      searchDispatch(setAnchor(searchState.anchor - 100));
    } else {
      updatedAnchor = searchState.anchor + 100;
      searchDispatch(setAnchor(searchState.anchor + 100));
    }
    const filters = prepSearchFilters();
    queryOrderSummary({
      variables: {
        filters,
        anchor: updatedAnchor || 0,
        sort: searchState.sort,
      },
    });
    document.getElementById('welcomeMessage').scrollIntoView();
  }

  function handleSort(sortValues) {
    const filters = prepSearchFilters();
    queryOrderSummary({
      variables: {
        filters,
        anchor: searchState.anchor,
        sort: sortValues,
      },
    });
  }

  /**
   * Return appropriate searchField key for address data based on
   * whether or not the current selection is for billing/shipping address
   *
   * @param {bool} isBilling - Is current selection for billing address.
   * @param {string} id - ID of input for which key will be returned.
   * @return {string} key
   */
  function getAddressFilterKey(isBilling, id) {
    let key;
    switch (id) {
      case 'firstName':
        key = isBilling ? 'billTo.recipient.firstName' : 'orderLines.shipTo.recipient.firstName';
        break;
      case 'lastName':
        key = isBilling ? 'billTo.recipient.lastName' : 'orderLines.shipTo.recipient.lastName';
        break;
      case 'city':
        key = isBilling ? 'billTo.address.city' : 'orderLines.shipTo.address.city';
        break;
      case 'state':
        key = isBilling ? 'billTo.address.state' : 'orderLines.shipTo.address.state';
        break;
      case 'country':
        key = isBilling ? 'billTo.address.country' : 'orderLines.shipTo.address.country';
        break;
      case 'zipCode':
        key = isBilling ? 'billTo.address.zipCode' : 'orderLines.shipTo.address.zipCode';
        break;
      default:
        break;
    }
    return key;
  }

  /**
   * Check to see if the form is ready to submit based on values entered at the time
   */
  function isFormReadyToSubmit() {
    // china can always search as its default terms (omsRegionReference) is sufficient
    if (isInCN) return true;

    // can submit with any of the developer-only filters
    if (
      hasPermission(Developer) &&
      (searchState.searchFields.status ||
        searchState.searchFields.paymentType ||
        searchState.searchFields.orderType)
    ) {
      return true;
    }

    if (!advancedOpen) {
      return (
        searchState.searchFields.orderNumber ||
        searchState.searchFields['billTo.contactInformation.email'] ||
        searchState.searchFields['billTo.contactInformation.dayPhoneNumber']
      );
    }
    const filters = searchState.searchFields;

    // filter out addressIsBilling field
    const filterOutAddressBilling = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => key !== 'addressIsBilling')
    );
    // If user has entered a postal code, then a country selection is required.
    if (filterOutAddressBilling.address.zipCode) {
      return filterOutAddressBilling.address.country ? true : false;
    }
    /* If user has selected one of either product type or date range, 
    then prevent submit until BOTH are selected. */
    if (
      filterOutAddressBilling['orderLines.orderLineType'] ||
      filterOutAddressBilling.orderSubmitDateAfter
    ) {
      return (
        filterOutAddressBilling['orderLines.orderLineType'] &&
        filterOutAddressBilling.orderSubmitDateAfter
      );
    }
    return Object.values(filterOutAddressBilling).some((filterVal) => {
      if (typeof filterVal === 'object') {
        return Object.values(filterVal).some((nestedVal) => {
          return nestedVal !== '';
        });
      }
      return filterVal !== '';
    });
  }

  const clearSearch = () => {
    searchDispatch(resetSearchFieldValues());
  };

  const isSapSearchFieldBeingUsed =
    Boolean(searchState.searchFields[sapSalesOrderKey]) ||
    Boolean(searchState.searchFields[sapPurchaseOrderKey]);

  return (
    <div className={classes.root}>
      <Paper elevation={1} className={classes.searchFormPaper}>
        <Typography className={classes.searchFormHeading} variant='h2'>
          {ORDER_SEARCH}
        </Typography>
        <form className={classes.searchForm} id='search-form' noValidate autoComplete='off'>
          <Collapse in={true}>
            <TextField
              className={classes.searchFields}
              id='orderNumber'
              data-testid={'search-order-number'}
              value={searchState.searchFields.orderNumber}
              label={SEARCH_ORDER_NUMBER}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={
                Boolean(searchState.searchFields['billTo.contactInformation.email']) ||
                Boolean(searchState.searchFields['billTo.contactInformation.dayPhoneNumber']) ||
                isSapSearchFieldBeingUsed
              }
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    // based on athlete feedback we are converting order number to CAPS
                    orderNumber: PROD_ENVIRONMENTS.includes(process.env.REACT_APP_ENV)
                      ? event.target.value?.toUpperCase()
                      : event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='email'
              data-testid={'search-email'}
              value={searchState.searchFields['billTo.contactInformation.email']}
              label={SEARCH_EMAIL}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={
                Boolean(searchState.searchFields.orderNumber) ||
                Boolean(searchState.searchFields['billTo.contactInformation.dayPhoneNumber']) ||
                isSapSearchFieldBeingUsed
              }
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'billTo.contactInformation.email': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='phoneNumber'
              data-testid={'search-phone'}
              value={searchState.searchFields['billTo.contactInformation.dayPhoneNumber']}
              label={SEARCH_PHONE}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={
                Boolean(searchState.searchFields.orderNumber) ||
                Boolean(searchState.searchFields['billTo.contactInformation.email']) ||
                isSapSearchFieldBeingUsed
              }
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'billTo.contactInformation.dayPhoneNumber': event.target.value.replace(
                      /[^0-9]/g,
                      ''
                    ),
                  })
                );
              }}
            />
          </Collapse>
          {/* Advanced Search  */}
          <Collapse in={advancedOpen}>
            <TextField
              className={classes.searchFields}
              id='sapSalesOrderNumber'
              data-testid={'search-sap-sales-order'}
              value={searchState.searchFields[sapSalesOrderKey]}
              label={SEARCH_SAP_SALES_ORDER}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={Boolean(searchState.searchFields[sapPurchaseOrderKey])}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    [sapSalesOrderKey]: event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='sapPurchaseOrderNumber'
              data-testid={'search-sap-purchase-order'}
              value={searchState.searchFields[sapPurchaseOrderKey]}
              label={SEARCH_SAP_PURCHASE_ORDER}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={Boolean(searchState.searchFields[sapSalesOrderKey])}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    [sapPurchaseOrderKey]: event.target.value,
                  })
                );
              }}
            />
            {!isInCN && (
              <TextField
                className={classes.searchFields}
                id='geo'
                data-testid={'search-geo'}
                value={searchState.searchFields.omsRegionReference || 'ALL'}
                label={SEARCH_GEO}
                size={'small'}
                onKeyPress={(event) => handleSubmit(event)}
                disabled={isSapSearchFieldBeingUsed}
                select
                onChange={(event) => {
                  if (event.target.value !== 'ALL') {
                    searchDispatch(
                      setSearchFieldValues({
                        ...searchState.searchFields,
                        omsRegionReference: event.target.value,
                        channel:
                          event.target.value === Geo.US || event.target.value === Geo.EUROPE
                            ? searchState.searchFields.channel
                            : '',
                      })
                    );
                  } else {
                    /* 
                      TODO: FORGE-11239 (https://jira.nike.com/browse/FORGE-11239)
                      Once component testing is implemented, we should add a test to confirm
                      that omsRegionReference/channel values are indeed cleared after the geo value
                      is changed from NIKEUS/NIKEEUROPE to something else.
                      Cypress is unable to check for this test because it relies solely on checking
                      React state, and there's no indication in the DOM to test for this.
                    */
                    searchDispatch(
                      setSearchFieldValues({
                        ...searchState.searchFields,
                        omsRegionReference: '',
                        channel: '',
                      })
                    );
                  }
                }}>
                <MenuItem value='ALL'>ALL</MenuItem>
                {Object.entries(Geos).map(([geo, label], index) => (
                  <MenuItem data-testid={`geo-${index}`} value={geo} key={`${geo}-${index}`}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {(searchState.searchFields.omsRegionReference === Geo.US ||
              searchState.searchFields.omsRegionReference === Geo.EUROPE) && (
              <TextField
                className={classes.searchFields}
                id='channel'
                data-testid={'search-partner'}
                value={searchState.searchFields.channel}
                label={SEARCH_PARTNER}
                size={'small'}
                onKeyPress={(event) => handleSubmit(event)}
                disabled={isSapSearchFieldBeingUsed}
                select
                onChange={(event) => {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      channel: event.target.value,
                    })
                  );
                }}>
                <MenuItem value=''>—</MenuItem>
                {Object.entries(
                  searchState.searchFields.omsRegionReference === Geo.EUROPE
                    ? EmeaPartners
                    : Partners
                ).map(([channel, label], index) => (
                  <MenuItem value={channel} key={`${channel}-${index}`}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              className={classes.searchFields}
              id='firstName'
              data-testid={'search-first-name'}
              value={searchState.searchFields.address.firstName}
              label={SEARCH_FIRST_NAME}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      firstName: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='lastName'
              data-testid={'search-last-name'}
              value={searchState.searchFields.address.lastName}
              label={SEARCH_LAST_NAME}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      lastName: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='city'
              data-testid={'search-city'}
              value={searchState.searchFields.address.city}
              label={SEARCH_CITY}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      city: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='state'
              data-testid={'search-state'}
              value={searchState.searchFields.address.state}
              label={SEARCH_STATE}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      state: event.target.value,
                    },
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              <ListSubheader className={classes.listSubheader}>U.S.</ListSubheader>
              {USStates.map((state, key) => (
                <MenuItem data-key='state' value={state.abbreviation} key={key}>
                  {state.name}
                </MenuItem>
              ))}
              <ListSubheader className={classes.listSubheader}>China</ListSubheader>
              {CNStates.cn.map((state, key) => (
                <MenuItem data-key='state' value={state.abbreviation} key={key}>
                  {state.name} ({state.abbreviation})
                </MenuItem>
              ))}
              <ListSubheader className={classes.listSubheader}>Japan</ListSubheader>
              {Object.keys(JPStates.ja).map((jpKey, key) => {
                const { abbreviation, name } = JPStates.ja[jpKey];
                return (
                  <MenuItem data-key='state' value={abbreviation} key={key}>
                    {name} ({abbreviation})
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='country'
              data-testid={'search-country'}
              value={searchState.searchFields.address.country}
              label={SEARCH_COUNTRY}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      country: event.target.value,
                    },
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {OtherCountries.map((country, key) => (
                <MenuItem value={country.abbreviation} key={key}>
                  {country.name}
                </MenuItem>
              ))}
              <ListSubheader>E.U.</ListSubheader>
              {EUCountries.map((country, key) => (
                <MenuItem value={country.abbreviation} key={key}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='zipCode'
              data-testid={'search-zip'}
              value={searchState.searchFields.address.zipCode}
              label={SEARCH_ZIP}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      zipCode: event.target.value,
                    },
                  })
                );
              }}
            />
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label={ARIA_ADDRESS_TYPE}
                className={classes.addressTypeRadioGroup}
                id='isBillingAddress'
                data-testid={'search-address-type'}
                size={'small'}
                value={searchState.searchFields.addressIsBilling || false}
                disabled={isSapSearchFieldBeingUsed}
                onChange={(event) => {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      addressIsBilling: event.target.value === 'true',
                    })
                  );
                }}>
                <FormControlLabel
                  value={false}
                  control={<Radio color='primary' />}
                  label={SHIPPING_ADDRESS}
                />
                <FormControlLabel
                  value={true}
                  control={<Radio color='primary' />}
                  label={BILLING_ADDRESS}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              className={classes.searchFields}
              id='upc'
              data-testid={'search-upc'}
              value={searchState.searchFields['orderLines.item.universalProductCode']}
              label={SEARCH_UPC}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.item.universalProductCode': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='styleNumber'
              data-testid={'search-style-number'}
              value={searchState.searchFields['orderLines.styleNumber']}
              label={SEARCH_STYLE_NUMBER}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.styleNumber': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='giftCardNumber'
              data-testid={'search-gift-card'}
              value={searchState.searchFields['paymentMethods.displayCardNumber']}
              label={SEARCH_GIFT_CARD}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    // If user enters value for gift card number, we set paymentType
                    // to 'GIFT_CERTIFICATE', otherwise we leave it blank.
                    'paymentMethods.paymentType': event.target.value ? 'GIFT_CERTIFICATE' : '',
                    'paymentMethods.displayCardNumber': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='trackingNumber'
              data-testid={'search-tracking-no'}
              value={searchState.searchFields['trackingNumber']}
              label={'Tracking Number'}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    trackingNumber: event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='productType'
              data-testid={'search-product-type'}
              value={searchState.searchFields['orderLines.orderLineType']}
              label={SEARCH_ITEM_TYPE}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.orderLineType': event.target.value,
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {orderLineTypes.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='dateRange'
              data-testid={'search-date-range'}
              value={searchState.searchFields.orderSubmitDateAfter}
              label={SEARCH_DATE_RANGE}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={isSapSearchFieldBeingUsed}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    orderSubmitDateAfter: event.target.value,
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {Object.entries(dateRangeOptions).map(([label, value], index) => (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Collapse>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.advancedSearchToggle}
              onClick={() => setAdvancedOpen(!advancedOpen)}
              data-testid={'advanced-search-toggle'}>
              {ADVANCED_SEARCH} {advancedOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </div>
          <HasPermission permission={Developer}>
            <Divider />
            <TextField
              className={classes.searchFields}
              id='orderType'
              data-testid={'search-order-type'}
              value={searchState.searchFields.orderType || 'Any'}
              label={'Order Type'}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                if (event.target.value !== 'Any') {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      orderType: event.target.value,
                    })
                  );
                } else {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      orderType: '',
                    })
                  );
                }
              }}>
              <MenuItem value='Any'>Any</MenuItem>
              {orderTypesList.map((type, index) => (
                <MenuItem
                  data-testid={`status-${index}`}
                  value={type.value}
                  key={`${type.name}-${index}`}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='status'
              data-testid={'search-status'}
              value={searchState.searchFields.status || 'Any'}
              label={'Order Status'}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                if (event.target.value !== 'Any') {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      status: event.target.value,
                    })
                  );
                } else {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      status: '',
                    })
                  );
                }
              }}>
              <MenuItem value='Any'>Any</MenuItem>
              {/* most helpful statuses listed first, a divider, then the rest */}
              {/* todo: handle multi-word statuses (not yet supported but will be soon) */}
              {orderStatuses.slice(0, 7).map((status, index) => (
                <MenuItem data-testid={`status-${index}`} value={status} key={`${status}-${index}`}>
                  {status}
                </MenuItem>
              ))}
              <Divider />
              {orderStatuses.slice(7).map((status, index) => (
                <MenuItem data-testid={`status-${index}`} value={status} key={`${status}-${index}`}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='paymentType'
              data-testid={'search-payment-type'}
              value={searchState.searchFields.paymentType || 'Any'}
              label={'Order Payment Type'}
              size={'small'}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                if (event.target.value !== 'Any') {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      paymentType: event.target.value,
                    })
                  );
                } else {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      paymentType: '',
                    })
                  );
                }
              }}>
              <MenuItem value='Any'>Any</MenuItem>
              {Object.keys(paymentTypes).map((paymentType, index) => (
                <MenuItem
                  data-testid={`paymentType-${index}`}
                  value={paymentType}
                  key={`${paymentType}-${index}`}>
                  {paymentType}
                </MenuItem>
              ))}
            </TextField>
          </HasPermission>
          <div className={classes.ctaButtons}>
            <Button
              data-testid={'search-button'}
              id={'search-button'}
              className={classes.searchButton}
              variant='contained'
              color='primary'
              disabled={!isFormReadyToSubmit()}
              onClick={(event) => handleSubmit(event)}>
              {SEARCH_BUTTON}
            </Button>
            {advancedOpen && (
              <Button
                className={classes.clearSearchButton}
                size='small'
                onClick={clearSearch}
                data-testid={'clear-search-button'}>
                {CLEAR_SEARCH}
              </Button>
            )}
          </div>
        </form>
      </Paper>
      {(summaryLoading || shipmentsLoading) && <Loading />}
      {searchState.searchResults && (
        <OrderSearchTable
          title={SEARCH_RESULTS}
          searchResults={searchState.searchResults}
          anchor={searchState.anchor}
          paginationLinks={searchState.pagination}
          handleNavigation={handleSearchNavigation}
          handleSort={handleSort}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100vw',
    'padding': theme.spacing(2),
    'paddingTop': theme.spacing(1),
    'display': 'flex',
    '& .MuiInputLabel-shrink': {
      transform: `translate(0px, 3px) scale(0.75)`,
    },
    '& label + .MuiInput-formControl': {
      marginTop: '11px',
    },
  },
  searchFormPaper: {
    margin: theme.spacing(1),
    top: 58,
    width: '20vw',
    minWidth: '215px',
    height: 'max-content',
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },
  searchFormHeading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1,
  },
  searchFields: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  advancedSearchToggle: {
    alignItems: 'center',
    textTransform: 'none',
    paddingRight: 0,
    marginRight: 0,
  },
  ctaButtons: {
    display: 'flex',
    width: '100%',
    margin: '1.5rem 0 .5rem 0',
    justifyContent: 'space-around',
  },
  clearSearchButton: { padding: '.5rem 1rem' },
  addressTypeRadioGroup: {
    color: theme.palette.common.black,
    marginTop: '0.5rem',
  },
  listSubheader: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  searchButton: { minWidth: '6.5rem' },
  searchResults: {
    height: '30vh',
    display: 'inherit',
    padding: theme.spacing(1),
  },
}));

export default SearchForm;
