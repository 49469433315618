import gql from 'graphql-tag';

const PID_STATUS_MUTATION = gql`
  mutation putPidStatus($pidIds: [String]!, $status: String!) {
    pidStatus(pidIds: $pidIds, status: $status) {
      value
      status
      reason {
        extensions {
          code
          response {
            status
            statusText
            body
          }
        }
      }
    }
  }
`;

export default PID_STATUS_MUTATION;
