export const actionTypes = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  RESET: 'RESET',
  NEXT_STEP: 'NEXT_STEP',
  PREV_STEP: 'PREV_STEP',
  SELECT_LINE: 'SELECT_LINE',
  SELECT_SKU: 'SELECT_SKU',
  SELECT_STYLECOLOR: 'SELECT_STYLECOLOR',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_GIFT_CARD_ADDRESS: 'SET_GIFT_CARD_ADDRESS',
  SET_ADDRESS_FIELD: 'SET_ADDRESS_FIELD',
  SET_GC_SHIPPING_ADDRESS_FIELD: 'SET_GC_SHIPPING_ADDRESS_FIELD',
  SET_ADDRESS_TYPE: 'SET_ADDRESS_TYPE',
  SET_GIFTEE_EMAIL: 'SET_GIFTEE_EMAIL',
  SET_IS_GIFT_RETURN: 'SET_IS_GIFT_RETURN',
  SET_DELIVERY_METHOD: 'SET_DELIVERY_METHOD',
  SET_IS_LABEL_EMAILED: 'SET_IS_LABEL_EMAILED',
  SET_MANUALLY_ENTERING_ADDRESS: 'SET_MANUALLY_ENTERING_ADDRESS',
  SET_QUANTITY_TO_RETURN: 'SET_QUANTITY_TO_RETURN',
  SET_QUANTITY_TO_CANCEL: 'SET_QUANTITY_TO_CANCEL',
  SET_RETURN_REASON: 'SET_RETURN_REASON',
  SET_RETURN_LABEL_EMAIL: 'SET_RETURN_LABEL_EMAIL',
  SET_CANCEL_REASON: 'SET_CANCEL_REASON',
  SET_INSPECTION_REASON: 'SET_INSPECTION_REASON',
  SET_PRICE_MOD_REASON: 'SET_PRICE_MOD_REASON',
  SET_RETURN_PAYMENT_OPTION: 'SET_RETURN_PAYMENT_OPTION',
  SET_SHIPPING_OPTION: 'SET_SHIPPING_OPTION',
  SET_INITIAL_SHIPPING_OPTION: 'SET_INITIAL_SHIPPING_OPTION',
  SET_IS_MILITARY_ADDRESS: 'SET_IS_MILITARY_ADDRESS',
  SET_INSPECT_REASON_CODE_TYPE: 'SET_INSPECT_REASON_CODE_TYPE',
  SET_INSPECTION_STATUS: 'SET_INSPECTION_STATUS',
  SET_DISCOUNT_TYPE: 'SET_DISCOUNT_TYPE',
  SET_LINE_DISCOUNT_VALUE: 'SET_LINE_DISCOUNT_VALUE',
  SET_VALIDATE_ADDRESS: 'SET_VALIDATE_ADDRESS',
  SET_RETURN_DISPOSITION: 'SET_RETURN_DISPOSITION',
  SET_ESCALATION_NOTES: 'SET_ESCALATION_NOTES',
  SET_RETURN_ORDER_NUMBER: 'SET_RETURN_ORDER_NUMBER',
  SET_SHIP_TO_ADDRESS: 'SET_SHIP_TO_ADDRESS',
  SET_TIMED_OUT: 'SET_TIMED_OUT',
  SET_ARE_ALL_ORDER_LINES_GIFT_CARDS: 'SET_ARE_ALL_ORDER_LINES_GIFT_CARDS',
  SET_LINE_PRICE_VALIDATION_ERROR: 'SET_LINE_PRICE_VALIDATION_ERROR',
  SET_SHIPPING_DISCOUNT_REASON: 'SET_SHIPPING_DISCOUNT_REASON',
  SET_SHIPPING_GROUP: 'SET_SHIPPING_GROUP',
  SET_ALL_SELECTED_LINES: 'SET_ALL_SELECTED_LINES',
  SET_IS_EXCHANGE_SUBMITTING: 'SET_IS_EXCHANGE_SUBMITTING',
  SET_SKU_DISCOUNT: 'SET_SKU_DISCOUNT',
  SET_TRACKING_NUMBER: 'SET_TRACKING_NUMBER',
  SET_EXCHANGE_CREDIT_VALUE: 'SET_EXCHANGE_CREDIT_VALUE',
  SET_EXCHANGE_TOTAL_PRICE: 'SET_EXCHANGE_TOTAL_PRICE',
  SET_SKU_IN_STOCK: 'SET_SKU_IN_STOCK',
  SET_IS_SUBMIT_READY: 'SET_IS_SUBMIT_READY',
  CLEAR_SKUS: 'CLEAR_SKUS',
  SET_EXCHANGE_LOCALE: 'SET_EXCHANGE_LOCALE',
  SET_EXCHANGE_COUNTRY: 'SET_EXCHANGE_COUNTRY',
};

export const actions = {
  open:
    /**
     * This action sets "open" in state to true, and sets the type of the
     * dialog.
     *
     * @param {String} dialogType – the type of dialog to open
     * @param {Boolean} lock – lock = true => the dialog can not be closed
     */
    (dialogType, lock) => ({
      type: actionTypes.OPEN,
      lock,
      dialogType,
    }),

  close:
    /**
     * This action sets "open" in state to false.
     */
    () => ({
      type: actionTypes.CLOSE,
    }),

  reset:
    /**
     * This action resets the dialog to its default state. As a side effect of
     * this action, any open dialog will close.
     */
    () => ({
      type: actionTypes.RESET,
    }),

  setReturnDisposition:
    /**
     * This action sets up the disposition
     * @param line - selected order line on return inspection
     * @param disposition - selected on return inspection
     *
     */
    (line, disposition) => ({
      type: actionTypes.SET_RETURN_DISPOSITION,
      line,
      disposition,
    }),

  setEscalationNotes:
    /**
     * This action sets up escalation notes
     * @param line - selected order line on return inspection
     * @param notes - inspection escalation notes
     *
     */
    (line, notes) => ({
      type: actionTypes.SET_ESCALATION_NOTES,
      line,
      notes,
    }),

  nextStep:
    /**
     * This action increments the active step by one.
     */
    () => ({
      type: actionTypes.NEXT_STEP,
    }),

  prevStep:
    /**
     * This action reduces the active step by one.
     */
    () => ({
      type: actionTypes.PREV_STEP,
    }),

  selectLine:
    /**
     * This action sets an orderline in the selectedLines object.
     *
     * @param {Object} line – the order line object to select
     */
    (line) => ({
      type: actionTypes.SELECT_LINE,
      line,
    }),

  selectSku:
    /**
     * This action adds a sku to the selected items to exchange for
     *
     * @param {Number} originalLineNumber - key in selectedOrderLines of the line being exchanged
     * @param {Object} sku - the sku object containing product details, quantity, and size specs
     */
    (originalLineNumber, sku) => ({
      type: actionTypes.SELECT_SKU,
      originalLineNumber,
      sku,
    }),

  clearSelectedSkus:
    /**
     * This action resets selectedSkus an empty object
     */
    () => ({
      type: actionTypes.CLEAR_SKUS,
    }),

  setAddress:
    /**
     * This action sets sets a complete address.
     *
     * @param {Object} address – the address to be set
     */
    (address) => ({
      type: actionTypes.SET_ADDRESS,
      address,
    }),

  setAddressField:
    /**
     * This action sets an address field on the address in state.
     *
     * @param {String} key – the key of the address object to be set
     * @param {String} value – the value to set on the address field
     */
    (key, value) => ({
      type: actionTypes.SET_ADDRESS_FIELD,
      key,
      value,
    }),

  setGiftCardAddress:
    /**
     * This action sets a complete gcShippingAddress.
     *
     * @param {Object} address - the gcShippingAddress to be set.
     */
    (address) => ({
      type: actionTypes.SET_GIFT_CARD_ADDRESS,
      address,
    }),

  setGCShippingAddressField:
    /**
     * This action sets an field on the gcShippingAddress in state.
     *
     * @param {String} key - the key of the gcShippingAddress to be set.
     * @param {String} value - the value to set on the gcShippingAddress field.
     */
    (key, value) => ({
      type: actionTypes.SET_GC_SHIPPING_ADDRESS_FIELD,
      key,
      value,
    }),

  setAreAllOrderLinesGiftCards:
    /**
     * @param {Boolean} areAllOrderLinesGiftCards - areAllOrderLinesGiftCards to be set
     */
    (areAllOrderLinesGiftCards) => ({
      type: actionTypes.SET_ARE_ALL_ORDER_LINES_GIFT_CARDS,
      areAllOrderLinesGiftCards,
    }),

  setIsMilitaryAddress:
    /**
     * This action sets whether the address is military.
     *
     * @param {Boolean} isMilitaryAddress – the isMilitaryAddress to be set
     */
    (isMilitaryAddress) => ({
      type: actionTypes.SET_IS_MILITARY_ADDRESS,
      isMilitaryAddress,
    }),

  setInspectionStatus:
    /**
     * @param line order line for which inspection is being done
     * @param inspectReasonCodeType - inspection reason code selected from UI
     */

    (line, inspectReasonCodeType) => {
      return {
        type: actionTypes.SET_INSPECTION_STATUS,
        line,
        inspectReasonCodeType,
      };
    },

  setInspectReasonCodeType:
    /**
     * This action sets inspect reason code type.
     *
     * @param line order line for which inspection is being done
     * @param inspectReasonCodeType - inspection reason code selected from UI
     */

    (line, inspectReasonCodeType) => {
      return {
        type: actionTypes.SET_INSPECT_REASON_CODE_TYPE,
        line,
        inspectReasonCodeType,
      };
    },

  setDiscountType:
    /**
     * This action sets discount type.
     *
     * @param line order line for which discount is being done
     * @param  discountType - discount type selected from UI
     */

    (line, discountType) => ({
      type: actionTypes.SET_DISCOUNT_TYPE,
      line,
      discountType,
    }),

  setGifteeEmail:
    /**
     * This action sets a the email for the recipient of the gift order.
     *
     * @param {String} gifteeEmail – the giftee email to be set
     */
    (gifteeEmail) => ({
      type: actionTypes.SET_GIFTEE_EMAIL,
      gifteeEmail,
    }),

  setIsGiftReturn:
    /**
     * This action sets whether a return if for an order which was a gift.
     *
     * @param {Boolean} isGiftReturn – the isGiftReturn to be set
     * @param {Boolean} canRefundToGiftCard
     *    – the canRefundToGiftCard to be set
     */
    (isGiftReturn, canRefundToGiftCard) => ({
      type: actionTypes.SET_IS_GIFT_RETURN,
      isGiftReturn,
      canRefundToGiftCard,
    }),

  setDeliveryMethod:
    /**
     * This action sets a delivery method for the return.
     *
     * @param {String} deliveryMethod – the delivery method to be set
     */
    (deliveryMethod) => ({
      type: actionTypes.SET_DELIVERY_METHOD,
      deliveryMethod,
    }),

  setQuantityToReturn:
    /**
     * This action sets the quantity of a particular line item to be
     * added to a return in progress.
     *
     * @param {Object} line – the order line for which we are modifying quantity
     * @param {Number} quantity – the quantity to be set
     */
    (line, quantity) => ({
      type: actionTypes.SET_QUANTITY_TO_RETURN,
      line,
      quantity,
    }),

  setQuantityToCancel:
    /**
     * This action sets the quantity of a particular line item to be
     * added to a cancel in progress.
     *
     * @param {Object} line – the order line for which we are modifying quantity
     * @param {Number} quantity – the quantity to be set
     */
    (line, quantity) => ({
      type: actionTypes.SET_QUANTITY_TO_CANCEL,
      line,
      quantity,
    }),

  setCancelReason:
    /**
     * This action sets a reason for cancelling an item
     *
     * @param {Object} line – the order line to be set
     * @param {Object} cancelReason – object containing the cancel reason code and
     * description
     */
    (line, cancelReason) => ({
      type: actionTypes.SET_CANCEL_REASON,
      line,
      cancelReason,
    }),

  setValidateAddress:
    /**
     * This action sets boolean toggle for showing address validator dialog
     *
     * @param {boolean} boolean – whether to set showAddressValidator
     */
    (boolean) => ({
      type: actionTypes.SET_VALIDATE_ADDRESS,
      boolean,
    }),

  setInspectionReason:
    /**
     * This action sets a reason for inspecting an item
     *
     * @param {Object} line – the order line to be set
     * @param {Object} inspectionReason – object containing the inspection reason code and
     * description
     */
    (line, inspectionReason) => ({
      type: actionTypes.SET_INSPECTION_REASON,
      line,
      inspectionReason,
    }),

  setDiscountReason:
    /**
     * This action sets a reason for changing the price of an item
     *
     * @param {Object} line – the order line to be set
     * @param {Object} discountReason – object containing the price mod reason codes and
     * description
     */
    (line, discountReason) => ({
      type: actionTypes.SET_PRICE_MOD_REASON,
      line,
      discountReason,
    }),

  setLinePriceValidationError:
    /**
     * This action sets a price validation error for a line item
     *
     * @param {Object} line – the order line to be set
     * @param {Boolean} isError – true if error false otherwise
     */
    (line, isError) => ({
      type: actionTypes.SET_LINE_PRICE_VALIDATION_ERROR,
      line,
      isError,
    }),

  setLineDiscountValue:
    /**
     * This action sets a discount value applied for a line item
     *
     * @param {Object} line – the order line to be set
     * @param {string} discountValue – discount value
     */
    (line, discountValue) => ({
      type: actionTypes.SET_LINE_DISCOUNT_VALUE,
      line,
      discountValue,
    }),

  setReturnReason:
    /**
     * This action sets a reason for returning an item in a return in
     * progress.
     *
     * @param {Object} line – the order line to be set
     * @param {Object} reason – object containing the reason code and
     * description
     */
    (line, returnReason) => ({
      type: actionTypes.SET_RETURN_REASON,
      line,
      returnReason,
    }),

  setReturnLabelEmail:
    /**
     * This action sets a return label email.
     *
     * @param {String} returnLabelEmail – return label email to be set
     */
    (returnLabelEmail) => ({
      type: actionTypes.SET_RETURN_LABEL_EMAIL,
      returnLabelEmail,
    }),

  setReturnPaymentOption:
    /**
     * This action sets a payment option for the return.
     *
     * @param {String} returnPaymentOption – the payment option to be set
     */
    (returnPaymentOption) => ({
      type: actionTypes.SET_RETURN_PAYMENT_OPTION,
      returnPaymentOption,
    }),

  setShippingOption:
    /**
     * This action sets the shipment option to be used for a return
     *
     * @param {String} shippingOption – the shipment option to be set
     */
    (shippingOption) => ({
      type: actionTypes.SET_SHIPPING_OPTION,
      shippingOption,
    }),

  setInitialShippingOption:
    /**
     * This action sets a initial shipment option for a return order. This
     * option provides the initial value that will be set, as well as a
     * fallback to be returned to if options (outside of shipping option)
     * cause the shipping option to be changed, and then changed back.
     *
     * @param {String} shippingOption – the initial shipment option to be set
     */
    (shippingOption) => ({
      type: actionTypes.SET_INITIAL_SHIPPING_OPTION,
      shippingOption,
    }),

  setReturnOrderNumber:
    /**
     * This action sets the return order number
     */
    (returnOrderNumber) => ({
      type: actionTypes.SET_RETURN_ORDER_NUMBER,
      returnOrderNumber,
    }),

  setShipToAddress:
    /**
     * This action will set the returnLocation response's shipTo obj.
     * The shipTo obj is used for the returnCaptures request
     * and references where to ship the return to.
     * @param {Object} shipTo – the shipTo object
     */
    (shipTo) => ({
      type: actionTypes.SET_SHIP_TO_ADDRESS,
      shipTo,
    }),

  setHasTimedOut:
    /**
     * This action sets the hasTimedOut boolean to disable UI after the dialog
     * has timed out on a request
     *
     * @param {boolean} boolean – the boolean to set hasTimedOut
     */
    (boolean) => ({
      type: actionTypes.SET_TIMED_OUT,
      boolean,
    }),

  setShippingDiscountReason:
    /**
     * This action sets shipping discount reason
     * @param {Object} shippingDiscountReason – object wth reason code id and text
     */
    (shippingDiscountReason) => ({
      type: actionTypes.SET_SHIPPING_DISCOUNT_REASON,
      shippingDiscountReason,
    }),

  setShippingGroup:
    /**
     * This action sets shipping group
     * @param {Object} shippingGroup – object wth shipgroup info
     */
    (shippingGroup) => ({
      type: actionTypes.SET_SHIPPING_GROUP,
      shippingGroup,
    }),

  setAllSelectedLines:
    /**
     * This action sets all the selected lines
     * @param {Array} orderLines – array of all available lines
     */
    (selectableLines) => {
      return {
        type: actionTypes.SET_ALL_SELECTED_LINES,
        selectableLines,
      };
    },

  setIsExchangeSubmitting:
    /**
     * This action toggles the value of dialog's indicator that exchange is submitting
     * @param {boolean} isExchangeSubmitting
     */
    (isExchangeSubmitting) => {
      return {
        type: actionTypes.SET_IS_EXCHANGE_SUBMITTING,
        isExchangeSubmitting,
      };
    },

  setSelectedSkuDiscount:
    /**
     * This action sets the discount type for a specific sku in the exchange flow
     * @param {object} discount the discount being selected (e.g. Amount vs. % off)
     * @param {number} lineNumber the lineNumber associated with the sku being given the discount
     */
    (discount, lineNumber) => ({
      type: actionTypes.SET_SKU_DISCOUNT,
      lineNumber,
      discount,
    }),

  setSelectedSkuInStock:
    /**
     * This action sets the value for whether or not an exchange item is in stock
     * @param {number} lineNumber the index for which selected skus is being updated
     * @param {boolean} inStock the indication of whether or not the item is in stock
     */
    (lineNumber, inStock) => ({
      type: actionTypes.SET_SKU_IN_STOCK,
      lineNumber,
      inStock,
    }),

  setTrackingNumber:
    /**
     * This action sets the tracking number associated with an item that has been returned.
     * @param {*} trackingNumber tracking number of the item that was returned.
     */
    (trackingNumber) => ({
      type: actionTypes.SET_TRACKING_NUMBER,
      trackingNumber,
    }),

  setExchangeLocale:
    /**
     * This action sets the exchange locale for the entire dialog flow
     * @param {string} exchangeLocale a string representing a language/locale
     */
    (exchangeLocale) => ({
      type: actionTypes.SET_EXCHANGE_LOCALE,
      exchangeLocale,
    }),

  setExchangeCountry:
    /**
     * This action sets the exchange country for the entire dialog flow
     * @param {string} exchangeCountry a string representing shipping country
     */
    (exchangeCountry) => ({
      type: actionTypes.SET_EXCHANGE_COUNTRY,
      exchangeCountry,
    }),

  setExchangeCreditValue:
    /**
     * This action sets the exchange credit value for items being exchanged
     * @param {number} exchangeCreditValue a float of exchange credit value
     */
    (exchangeCreditValue) => ({
      type: actionTypes.SET_EXCHANGE_CREDIT_VALUE,
      exchangeCreditValue,
    }),

  setExchangeTotalPrice:
    /**
     * This action sets the exchange total price for items being exchanged
     * @param {number} exchangeTotalPrice a float of exchange total price
     */
    (exchangeTotalPrice) => ({
      type: actionTypes.SET_EXCHANGE_TOTAL_PRICE,
      exchangeTotalPrice,
    }),

  setIsSubmitReady:
    /**
     * This action sets the variable that determines whether or not the submit button is disabled
     * this in a new variable and not used often
     * @param {Boolean} isSubmitReady
     */
    (isSubmitReady) => ({
      type: actionTypes.SET_IS_SUBMIT_READY,
      isSubmitReady,
    }),
};

export default actions;
