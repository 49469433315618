/* React/Utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';

/* Material-UI */
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

/* Local */
import translations from '../payments.i18n';

export default function InvoiceMenu({ action, data, currentInvoiceNo }) {
  const { i18nString } = useContext(NikeI18nContext);
  const { INVOICES } = mapValues(translations, i18nString);
  const classes = useStyles();

  return (
    <div className={classes.invoicesMenu}>
      <Box className={classes.invoiceDetailsHeader}>
        <Typography aria-label={INVOICES.toLowerCase()} variant='subtitle1' component='h3'>
          {INVOICES}
        </Typography>
      </Box>
      {data.invoiceCollectionDetails.map((detail) => (
        <IconButton
          key={detail.invoiceNumber}
          aria-label='expand row'
          data-testid={`see-invoice-button-${detail.invoiceNumber}`}
          style={{ fontSize: '1rem' }}
          className={
            detail.invoiceNumber === currentInvoiceNo ? classes.selected : classes.notSelected
          }
          onClick={() => action(detail.invoiceNumber)}>
          {detail.invoiceNumber}
        </IconButton>
      ))}
    </div>
  );
}

InvoiceMenu.propTypes = {
  data: PropTypes.shape({
    invoiceCollectionDetails: PropTypes.arrayOf(
      PropTypes.shape({
        invoiceNumber: PropTypes.string,
      })
    ),
  }).isRequired,
  action: PropTypes.func.isRequired,
  currentInvoiceNo: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  selected: {
    borderRadius: '24px',
    margin: `0px ${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(0.6)}px ${theme.spacing(1.7)}px`,
    backgroundColor: theme.palette.grey[300],
    color: 'black',
  },
  notSelected: {
    borderRadius: '24px',
    margin: `0px ${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(0.6)}px ${theme.spacing(1.7)}px`,
    color: 'rgb(55,141,248)',
  },
  invoicesMenu: {
    flexBasis: `10%`,
    flexGrow: 1,
    display: `flex`,
    marginLeft: '12px',
    flexDirection: `column`,
    gap: '10px',
    padding: `${theme.spacing(1.5)}px 0px`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
  },
  invoiceDetailsHeader: {
    padding: `0px ${theme.spacing(1.5)}px`,
    alignSelf: 'start',
  },
}));
