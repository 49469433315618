import React from 'react';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

/**
 * Just a plain orangey component for displaying warnings in code.
 */
const Warning = (props) => {
  return (
    <Box marginTop='50px' width='100%' textAlign='center'>
      <Box
        padding='10px'
        color='white'
        bgcolor='#ffa000'
        borderRadius='5px'
        fontWeight='bold'
        component='span'>
        <Box fontSize='1.25rem' marginBottom='-4px' marginRight='7px' clone>
          <WarningIcon />
        </Box>{' '}
        {props.message}
      </Box>
    </Box>
  );
};

export default Warning;
