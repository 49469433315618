const translations = {
  INVOICE_DETAILS_HEADER: {
    description: 'Header of Invoice expand section',
    primaryValue: 'Invoice Details',
    stringKey: 'invoice_details_header',
  },
  INVOICE_DETAILS_INVOICE_NUMBER_LABEL: {
    description: 'Label of Invoice Number Line',
    primaryValue: 'Invoice #',
    stringKey: 'invoice_details_invoice_number_label',
  },

  INVOICE_DETAILS_INVOICE_DATE_LABEL: {
    description: 'Label of Invoice Date Line',
    primaryValue: 'Invoice Date',
    stringKey: 'invoice_details_invoice_date_label',
  },
  INVOICE_DETAILS_INVOICE_TYPE_LABEL: {
    description: 'Label of Invoice Type Line',
    primaryValue: 'Invoice Type',
    stringKey: 'invoice_details_invoice_type_label',
  },
  INVOICE_DETAILS_ORDER_NUMBER: {
    description: 'Label of Order Number Line',
    primaryValue: 'Order #',
    stringKey: 'invoice_details_order_number_label',
  },
  INVOICE_DETAILS_ORDER_DATE: {
    description: 'Label of Order Date Line',
    primaryValue: 'Order Date',
    stringKey: 'invoice_details_order_Date_label',
  },

  TABLE_HEADER_STYLE_NUMBER: {
    description: 'table header caption for Style # column',
    primaryValue: 'Style #',
    stringKey: 'invoice_details_style_number',
  },
  TABLE_HEADER_STYLE_NAME: {
    description: 'table header caption for "Style Name" column',
    primaryValue: 'Style name',
    stringKey: 'invoice_details_style_name',
  },
  TABLE_HEADER_CLR: {
    description: 'table header caption for "CLR" column',
    primaryValue: 'CLR',
    stringKey: 'invoice_details_clr',
  },
  TABLE_HEADER_SIZE: {
    description: 'table header caption for "Size" column',
    primaryValue: 'Size',
    stringKey: 'invoice_details_size',
  },
  TABLE_HEADER_QTY: {
    description: 'table header caption for "QTY" column',
    primaryValue: 'QTY',
    stringKey: 'invoice_details_qty',
  },
  TABLE_HEADER_UNIT: {
    description: 'table header caption for "UNIT" column',
    primaryValue: 'Unit',
    stringKey: 'invoice_details_unit',
  },
  TABLE_HEADER_EXT_PRICE: {
    description: 'table header caption for "EXT PRICE" column',
    primaryValue: 'EXT Price',
    stringKey: 'invoice_details_ext_price',
  },
  TABLE_HEADER_EXT_CHRG: {
    description: 'table header caption for "CHRG" column',
    primaryValue: 'CHRG',
    stringKey: 'invoice_details_chrg',
  },
  TABLE_HEADER_TAX: {
    description: 'table header caption for "TAX" column',
    primaryValue: 'TAX',
    stringKey: 'invoice_details_tax',
  },
  TABLE_HEADER_TOTAL: {
    description: 'table header caption for "TOTAL" column',
    primaryValue: 'Total',
    stringKey: 'invoice_details_total',
  },

  INVOICE_DETAILS_SUMMARY_HEADER: {
    description: 'Header of section "Summary"',
    primaryValue: 'Summary',
    stringKey: 'invoice_details_summary_header',
  },
  INVOICE_DETAILS_SUMMARY_TYPE: {
    description: 'Label of "Type" row in summary section',
    primaryValue: 'Type',
    stringKey: 'invoice_details_summary_type_label',
  },
  INVOICE_DETAILS_SUMMARY_CARD: {
    description: 'Label of "Card" row in summary section',
    primaryValue: 'Card',
    stringKey: 'invoice_details_summary_card_label',
  },
  INVOICE_DETAILS_SUMMARY_AMOUNT: {
    description: 'Label of "Amount" row in summary section',
    primaryValue: 'Amount',
    stringKey: 'invoice_details_summary_amount_label',
  },
  INVOICE_DETAILS_SUMMARY_CARD_NUMBER: {
    description: 'Label of "Card number" row in summary section',
    primaryValue: 'Card number',
    stringKey: 'invoice_details_summary_card_number_label',
  },
  INVOICE_DETAILS_SUMMARY_EXPIRATION: {
    description: 'Label of "Expiration" row in summary section',
    primaryValue: 'Expiration',
    stringKey: 'invoice_details_summary_expiration_label',
  },

  INVOICE_DETAILS_BILLING_ADDRESS_HEADER: {
    description: 'Header of section "Billing address"',
    primaryValue: 'Billing address',
    stringKey: 'invoice_details_billing_address_section',
  },
  INVOICE_DETAILS_BILLING_ADDRESS_NAME: {
    description: 'Label of "Name" row in billing address section',
    primaryValue: 'Name',
    stringKey: 'invoice_details_billing_address_name_label',
  },
  INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS1: {
    description: 'Label of "Address line 1" row in billing address section',
    primaryValue: 'Address',
    stringKey: 'invoice_details_billing_address_address1',
  },
  INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS2: {
    description: 'Label of "Address line 2" row in billing address section',
    primaryValue: 'CITY, STATE, ZIP',
    stringKey: 'invoice_details_billing_address_address2',
  },
  INVOICE_DETAILS_BILLING_ADDRESS_DAYTIME: {
    description: 'Label of "Daytime" row in billing address section',
    primaryValue: 'Daytime',
    stringKey: 'invoice_details_billing_address_daytime',
  },

  INVOICE_DETAILS_CHARGES_DISCOUNT_HEADER: {
    description: 'Header of section "Header charges and discount"',
    primaryValue: 'Header charges and discount',
    stringKey: 'invoice_details_charges_discount_header',
  },
  INVOICE_DETAILS_CHARGES_DISCOUNT_CHARGES: {
    description: 'Label of "Charges" row in "Header Charges and discounts" section',
    primaryValue: 'Charges',
    stringKey: 'invoice_details_charges_discount_charges_label',
  },
  INVOICE_DETAILS_CHARGES_DISCOUNT_DISCOUNTS: {
    description: 'Label of "Discounts" row in "Header Charges and discounts" section',
    primaryValue: 'Discounts',
    stringKey: 'invoice_details_charges_discount_discounts_label',
  },
  INVOICE_DETAILS_CHARGES_DISCOUNT_TAX: {
    description: 'Label of "Tax" row in "Header Charges and discounts" section',
    primaryValue: 'TAX',
    stringKey: 'invoice_details_charges_discount_tax_label',
  },

  INVOICE_DETAILS_TOTALS_HEADER: {
    description: 'Header of section "Totals""',
    primaryValue: 'Totals',
    stringKey: 'invoice_details_totals_header',
  },
  INVOICE_DETAILS_TOTALS_LINE_SUBTOTALS: {
    description: 'Label of "Line subtotals" row in "Totals" section',
    primaryValue: 'Line subtotals',
    stringKey: 'invoice_details_totals_line_subtotals_label',
  },
  INVOICE_DETAILS_TOTALS_TOTAL_CHARGES: {
    description: 'Label of "Total charges" row in "Totals" section',
    primaryValue: 'Total charges',
    stringKey: 'invoice_details_totals_total_charges_label',
  },
  INVOICE_DETAILS_TOTALS_TOTAL_DISCOUNTS: {
    description: 'Label of "Total discounts" row in "Totals" section',
    primaryValue: 'Total discounts',
    stringKey: 'invoice_details_totals_total_discounts_label',
  },
  INVOICE_DETAILS_TOTALS_TOTAL_TAX: {
    description: 'Label of "Total tax" row in "Totals" section',
    primaryValue: 'Total tax',
    stringKey: 'invoice_details_totals_total_tax_label',
  },

  INVOICE_DETAILS_TRANSACTIONS_HEADER: {
    description: 'Header of section "Transaction"',
    primaryValue: 'Transaction',
    stringKey: 'invoice_details_transactions_header',
  },
  INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_DATE: {
    description: 'Label of "Transaction date" row in "Transactions" section',
    primaryValue: 'Transaction date',
    stringKey: 'invoice_details_transactions_transaction_date_label',
  },
  INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_TYPE: {
    description: 'Label of "Transaction type" row in "Transactions" section',
    primaryValue: 'Transaction type',
    stringKey: 'invoice_details_transactions_transaction_type_label',
  },
  INVOICE_DETAILS_TRANSACTIONS_AMT: {
    description: 'Label of "AMT" row in "Transactions" section',
    primaryValue: 'AMT',
    stringKey: 'invoice_details_transactions_amt_label',
  },
  INVOICE_DETAILS_TRANSACTIONS_STATUS: {
    description: 'Label of "Status" row in "Transactions" section',
    primaryValue: 'Status',
    stringKey: 'invoice_details_transactions_status_label',
  },
  INVOICE_DETAILS_TRANSACTIONS_REF_ID: {
    description: 'Label of "REF ID" row in "Transactions" section',
    primaryValue: 'REF ID',
    stringKey: 'invoice_details_transactions_ref_id_label',
  },
  INVOICE_DETAILS_RESEND_INVOICE: {
    description: 'Text on button for resending specific invoice number',
    primaryValue: 'Resend invoice No:',
    stringKey: 'invoice_details_resend_invoice',
  },
  INVOICE_DETAILS_INVOICE_RESENT: {
    description: 'Text on button for resending specific invoice number after resending',
    primaryValue: 'Invoice sent',
    stringKey: 'invoice_details_invoice_resent',
  },
  INVOICE_DETAILS_ENTER_RECIPIENT_EMAIL: {
    description: 'Text on dialog asking user for enter email to wicht to resend invoice',
    primaryValue: 'Enter recipient email',
    stringKey: 'invoice_details_enter_recipient_email',
  },
  INVOICE_DETAILS_SEND_EMAIL: {
    description: 'button for sending invoice',
    primaryValue: 'Send',
    stringKey: 'invoice_details_send_email',
  },
  INVOICE_DETAILS_CLOSE_MODAL: {
    description: 'button for closing the modal',
    primaryValue: 'Cancle',
    stringKey: 'invoice_details_close_modal',
  },
  INVOICE_DETAILS_RESEND_ERROR: {
    description: 'Error that will be shown when resending invoice fails',
    primaryValue: 'Something went wrong with the resending of an invoice',
    stringKey: 'invoice_details_resend_error',
  },
  ARIA_INVOICE_RESENDING: {
    description: 'ARIA label for invoice resending',
    primaryValue: 'invoice is being resent',
    stringKey: 'aria_invoice_resending',
  },
  INVOICE_NOT_READY: {
    description: 'Error that we shown when invoice is not available',
    primaryValue: 'Invoice is not available at this time. Please try again later.',
    stringKey: 'invoice_not_ready_error',
  },
};

export default translations;
