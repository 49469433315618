/* React/Utils */
import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

/* Local */
import OrderSearchResultsRow from './orderSearchResultsRow';
import translations from './orderSearch.i18n';
import { SearchContext } from './../../store/contexts/orderSearchContext';
import searchActions from './../../store/actions/orderSearchActions';
import useMemoTranslations from '../../hooks/useMemoTranslations';

/**
 * This component is used to display orders search results
 *
 * @param {object} props – React props object
 */
const OrderSearchTable = ({
  title,
  searchResults,
  paginationLinks,
  handleNavigation,
  handleSort,
}) => {
  const classes = useStyles();
  const { prev, next } = paginationLinks;
  const {
    ARIA_ORDER_SEARCH,
    SEARCH_ORDER_NUMBER,
    SEARCH_RESULTS_ORDER_DATE,
    SEARCH_RESULTS_CONSUMER_NAME,
    SEARCH_RESULTS_BILL_TO,
    SEARCH_RESULTS_TOTAL,
    SEARCH_RESULTS_ORDER_STATUS,
    SEARCH_RESULTS_ITEMS,
    NO_SEARCH_RESULT,
    PREV,
    NEXT,
  } = useMemoTranslations(translations);

  const [searchState, searchDispatch] = useContext(SearchContext);
  const { setSort } = searchActions;

  const [sortMapKeys, setSortMapKeys] = useState([
    { key: 'orderNumber', order: '' },
    { key: 'orderSubmitDate', order: 'Desc' },
    { key: 'status', order: '' },
  ]);

  // Next link pagination component
  const Next = () => (
    <Button
      className={clsx(classes.navLink, classes.nextLink)}
      endIcon={<NavigateNext />}
      onClick={() => handleNavigation('next')}>
      {NEXT}
    </Button>
  );

  // Prev link pagination component
  const Prev = () => (
    <Button
      className={clsx(classes.navLink, classes.prevLink)}
      startIcon={<NavigateBefore />}
      onClick={() => handleNavigation('prev')}>
      {PREV}
    </Button>
  );

  const HeaderSortButton = ({ buttonText, sortKey }) => {
    return (
      <Button className={classes.sortToggle} onClick={() => setSortingOrderToSelectedKey(sortKey)}>
        {buttonText}
        {findSortOrderByKeyName(sortKey) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
    );
  };

  const findSortOrderByKeyName = (key) => {
    const selectedOrder = sortMapKeys.find((item) => item.key === key).order;
    return Boolean(selectedOrder === 'Desc' || selectedOrder === '');
  };

  const setSortingOrderToSelectedKey = (key) => {
    if (searchResults.length > 0) {
      const keys = [...sortMapKeys];
      const selectedKey = keys.find((item) => item.key === key);
      if (selectedKey.order === 'Desc' || selectedKey.order === '') {
        selectedKey.order = 'Asc';
      } else {
        selectedKey.order = 'Desc';
      }
      // selected key should be listed first so it is prioritized in the sort
      keys.splice(keys.indexOf(selectedKey), 1); // remove it from the original array
      keys.unshift(selectedKey); // add selected key to front
      setSortMapKeys(keys);
      const sortString = keys
        .filter((item) => item.order !== '')
        .map((elem) => elem.key + elem.order)
        .join(',');
      if (searchState.sort !== setSort(sortString)) {
        searchDispatch(setSort(sortString));
      }
      handleSort(sortString);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        data-testid={'search-results-table'}>
        <Box className={classes.tableCap}>
          {title && (
            <Typography className={classes.title} variant='h2' id='tableTitle'>
              {title}
            </Typography>
          )}
          <div id={'paginationButtons'}>
            {Boolean(prev) && <Prev />}
            {Boolean(next) && <Next />}
          </div>
        </Box>
        <Table aria-label={ARIA_ORDER_SEARCH}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                <HeaderSortButton buttonText={SEARCH_ORDER_NUMBER} sortKey='orderNumber' />
              </TableCell>
              <TableCell className={classes.tableHeader}>
                <HeaderSortButton
                  buttonText={SEARCH_RESULTS_ORDER_DATE}
                  sortKey='orderSubmitDate'
                />
              </TableCell>
              <TableCell className={classes.tableHeader}>{SEARCH_RESULTS_CONSUMER_NAME}</TableCell>
              <TableCell className={classes.tableHeader}>{SEARCH_RESULTS_BILL_TO}</TableCell>
              <TableCell className={classes.tableHeader}>{SEARCH_RESULTS_TOTAL}</TableCell>
              <TableCell className={classes.tableHeader}>
                <HeaderSortButton buttonText={SEARCH_RESULTS_ORDER_STATUS} sortKey='status' />
              </TableCell>
              <TableCell className={classes.tableHeader}>{SEARCH_RESULTS_ITEMS}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid={'search-results-table-body'}>
            {searchResults.length > 0 &&
              !searchResults[0].shipmentIdentifier &&
              searchResults.map((order) => (
                <OrderSearchResultsRow key={order.orderNumber} order={order} />
              ))}
          </TableBody>
        </Table>
        {searchResults.length > 0 && (
          <Box className={classes.tableCap}>
            {Boolean(prev) && <Prev />}
            {Boolean(next) && <Next />}
          </Box>
        )}
        {!searchResults.length && (
          <Typography
            variant='subtitle1'
            id='noSearchResults'
            align='center'
            className={classes.zeroSearchResults}
            component='div'>
            {NO_SEARCH_RESULT}
          </Typography>
        )}
      </TableContainer>
    </>
  );
};

OrderSearchTable.propTypes = {
  title: PropTypes.string,
  searchResults: PropTypes.array.isRequired,
  anchor: PropTypes.number,
  paginationLinks: PropTypes.object.isRequired,
  handleNavigation: PropTypes.func,
  handleSort: PropTypes.func,
};

export default OrderSearchTable;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: theme.spacing(1),
    maxWidth: 1250,
    minWidth: 850,
  },
  tableHeader: {
    fontWeight: 'bold',
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  zeroSearchResults: {
    padding: theme.spacing(3.75),
  },
  title: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.75,
    flexGrow: 1,
  },
  navLink: {
    fontWeight: 'bold',
  },
  prevLink: {
    paddingRight: theme.spacing(1.5),
  },
  nextLink: {
    paddingLeft: theme.spacing(1.5),
  },
  tableCap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  sortToggle: {
    alignItems: 'center',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'left',
  },
}));
