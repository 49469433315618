import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import BasicTableRow from '../../../shared/table/tableRow';
import { transformEfapiaoOrderItem } from '../../../../utils/efapiao';

/**
 * Items table for the current order on efapiao page
 *
 * @param {array} itemsHeaders - headers for order items table
 * @param {array} orderLines - items of the current order
 * @param {string} itemsLabel - aria label for items table
 * @returns
 */

const ItemsTable = ({ itemsHeaders, orderLines, itemsLabel }) => {
  const classes = useStyles();

  return (
    <Table className={classes.halfTable} aria-label={itemsLabel} data-testid='efapiao-items-table'>
      <BasicTableRow
        header
        data={itemsHeaders}
        className={classes.tableHeader}
        cellRootClassName={classes.headerCell}
      />
      {orderLines.map((orderLine, i) => (
        <BasicTableRow
          key={i}
          data={transformEfapiaoOrderItem(orderLine)}
          cellRootClassName={classes.tableCell}
        />
      ))}
    </Table>
  );
};

ItemsTable.propTypes = {
  itemsHeaders: PropTypes.array,
  orderLines: PropTypes.array,
  itemsLabel: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.4),
  },
  tableCell: {
    padding: theme.spacing(1.4),
  },
  halfTable: {
    width: '50%',
  },
  headerCell: {
    whiteSpace: 'nowrap',
  },
}));

export default ItemsTable;
