import gql from 'graphql-tag';

const ISSUE_EFAPIAO_MUTATION = gql`
  mutation postIssueEfapiao($orderNumber: String!, $efapiao: Efapiao!, $timeout: Int!) {
    issueEfapiao(orderNumber: $orderNumber, efapiao: $efapiao, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
      errors {
        error {
          field
          message
          code
        }
      }
    }
  }
`;

export default ISSUE_EFAPIAO_MUTATION;
