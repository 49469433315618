const translations = {
  APPLY: {
    description: 'label for add payment dialog apply button',
    primaryValue: 'Apply',
    stringKey: 'apply',
  },
  DIGITAL_GIFT_CARD_RFO_LABEL: {
    description: 'label for digital gift card radio option',
    primaryValue: 'Digital Gift Card',
    stringKey: 'digitalGiftCardRfoLabel',
  },
  PHYSICAL_GIFT_CARD_RFO_LABEL: {
    description: 'label for physical gift card radio option',
    primaryValue: 'Physical Gift Card',
    stringKey: 'physicalGiftCardRfoLabel',
  },
  RETURN_AS_NEW_GIFT_CARD_ERROR: {
    description: 'error message for create rfo',
    primaryValue: 'Error submitting RFO:',
    stringKey: 'returnAsNewGiftCardError',
  },
  RETURN_AS_NEW_GIFT_CARD_SUCCESS: {
    description: 'success message for create rfo',
    primaryValue: 'Success submitting RFO',
    stringKey: 'returnAsNewGiftCardSuccess',
  },
  RFO_TAKES_TIME: {
    description:
      'notification message to notify users that RFO details may take time to be reflected on screen',
    primaryValue:
      'It may take some time for the RFO order number to appear. Please refresh the page.',
    stringKey: 'rfoOrderTakesTime',
  },
  ADD_RFO_TITLE: {
    description: 'title for add rfo info card',
    primaryValue: 'Add Refund to New Gift Card (RFO)',
    stringKey: 'addRfoTitle',
  },
  CANCEL: {
    stringKey: 'cancel',
    primaryValue: 'Cancel',
    description: 'Label for the cancel button of the add new payment form.',
  },
};
export default translations;
