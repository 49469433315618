import gql from 'graphql-tag';

const ADD_COMMENT_MUTATION = gql`
  mutation postComment($orderNumber: String!, $commentDetails: CommentDetails!, $timeout: Int!) {
    addComment(orderNumber: $orderNumber, commentDetails: $commentDetails, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
      errors {
        error {
          field
          message
          code
        }
      }
    }
  }
`;

export default ADD_COMMENT_MUTATION;
