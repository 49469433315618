import getArrayProperty from './getArrayProperty';
import PostalAddress from 'i18n-postal-address';
import EUCountries from '../constants/address/EUCountries.const';
import KRProvinces from '../constants/address/KRProvinces.const';
import JPStates from '../constants/address/JPStates.const';
import { logErrorAction, logInfoAction } from '../newrelic/logAction';
import isEqual from 'lodash.isequal';

/**
 * This function takes in an orderLine and returns the address to which it was
 * shipped in an array where each item is an address line string
 *
 * @param {Object} line – the order line from which to derive an address
 */
export const prepareAddress = (line) => {
  if (
    !line ||
    (!Boolean(line.shipTo) && !Boolean(line.billTo)) ||
    (!Boolean(line.shipTo?.address) && !Boolean(line.billTo?.address))
  )
    return;

  const address = line.shipTo?.address || line.billTo?.address;

  // get needed data from other places in the order line
  const nameArray = getNameFromOrderLine(line);
  const phoneNumber = getDayPhoneNumberFromOrderLine(line);

  // format data into object
  const addressObj = {
    ...address,
    firstName: nameArray?.[0],
    middleName: nameArray?.[1],
    lastName: nameArray?.[2],
    alternateFirstName: nameArray?.[3],
    alternateLastName: nameArray?.[4],
    dayPhoneNumber: phoneNumber,
  };

  // pass address object to function below
  return prepareAddressObject(addressObj);
};

export const convertBillingAddressToBillingInfo = (billingAddress) => {
  const { address, contactInfo, name } = billingAddress;
  return {
    email: contactInfo?.email,
    firstName: name?.firstName,
    lastName: name?.lastName,
    address1: address?.address1,
    address2: address?.address2,
    address3: address?.address3,
    city: address?.city,
    state: address?.state,
    postalCode: address?.postalCode,
    country: address?.country,
    phoneNumber: contactInfo?.phoneNumber,
  };
};

/**
 * This function takes in an address object and returns the address formatted
 * based on country in an array where each item is an address line
 *
 * pasted from address.js in OCOBO
 *
 * @param {Object} addressObject – the address object to format
 */

export const prepareAddressObject = (addressObject) => {
  if (!addressObject || !addressObject.country || typeof addressObject !== 'object') return;

  // https://www.npmjs.com/package/i18n-postal-address
  const myAddress = new PostalAddress();
  // custom format for China
  myAddress.addFormat({
    country: 'CN',
    format: [
      ['lastName', 'firstName'],
      ['country'],
      ['province', 'city', 'region'],
      ['address1'],
      ['address2'],
      ['postalCode'],
    ],
  });

  // custom format for Korea
  myAddress.addFormat({
    country: 'KR',
    format: [
      ['lastName', 'firstName'],
      ['province', 'city', 'address1'],
      ['address2'],
      ['postalCode'],
      ['title'],
    ],
  });

  // custom format for Japan
  myAddress.addFormat({
    country: 'JP',
    format: [
      ['lastName', 'firstName'],
      ['firstLastName'],
      ['country'],
      ['postalCode'],
      ['province', 'city', 'prefecture', 'address1'],
      ['address2'],
      ['gu'], // gu is used for dayPhoneNumber, due to constraints of PostalAddress
    ],
  });

  // custom format for EMEA
  EUCountries.map((country) =>
    myAddress.addFormat({
      country: `${country.abbreviation}`,
      format: [
        ['firstName', 'lastName'],
        ['address1'],
        ['address2'],
        ['city'],
        ['region'],
        ['postalCode'],
        ['country'],
        ['title'],
      ],
    })
  );

  // Prevents duplication of city and state for province
  // If Korean, changes KR-XX code passed through state into corresponding province name
  //  Ex: KR-11 => 서울특별시 (Seoul)
  // It also checks if provided value is a valid province name, and if so returns it
  // Same with Japan
  const formattedState = () => {
    if (addressObject.city === addressObject.state) {
      return null;
    } else if (addressObject.country === 'KR') {
      return getKoreaProvinceFromKRCode(addressObject.state);
    } else if (addressObject.country === 'JP') {
      return getJpStateFromJpCode(addressObject.state);
    }
    return addressObject.state;
  };

  myAddress
    .setFormat({
      country: addressObject.country,
    })
    .setOutputFormat('array')
    .setCompanyName(
      addressObject.locationName !== addressObject.address1 ? addressObject.locationName : ''
    )
    .setFirstName(addressObject.firstName)
    .setSecondName(addressObject.middleName)
    .setLastName(addressObject.lastName)
    // Returns alternateLast name + alternateFirstName, if both exist
    .setFirstLastName(
      addressObject?.alternateLastName && addressObject.alternateFirstName
        ? addressObject.alternateLastName + ' ' + addressObject.alternateFirstName
        : null
    )
    .setAddress1(addressObject.address1)
    .setAddress2(addressObject.address2)
    .setCity(addressObject.city)
    .setPrefecture(addressObject.address3) // specific to JP, currently used for area / village
    // sometimes service gives same value for city and state (eg CN), if so, don't duplicate
    // it should be noted that there is no default address format that uses both province and state
    .setState(addressObject.city === addressObject.state ? null : addressObject.state)
    .setProvince(formattedState()) // Checks for duplicate city / state values, converts iso-codes to local languages using state data (Japan, Korea))
    .setRegion(addressObject.address4) // aka district/county for CN addresses, stored in address4
    .setPostalCode(addressObject.postalCode || addressObject.zipCode)
    .setCountry(addressObject.country)
    // gu is used for dayPhoneNumber, due to constraints of PostalAddress
    .setGu(addressObject.dayPhoneNumber ? addressObject.dayPhoneNumber : null);

  const fullAddress = [];
  myAddress.output().forEach((addressLine) => fullAddress.push(addressLine.join(' ')));
  return fullAddress;
};

/**
 * This function is for mapping through Korean Provinces
 *  @param {string} code – a string from address object
 * that is an iso code for Korean provinces and metro areas
 */

export const getKoreaProvinceFromKRCode = (code) => {
  // If no code, return null now for performance
  if (!code) return null;

  // If code value is the string name of the state / province, return code
  if (
    Object.values(KRProvinces.en).find((state) => state.name === code) ||
    Object.values(KRProvinces.kr).find((state) => state.name === code)
  )
    return code;

  // If valid code translation, return state name, otherwise return null
  return KRProvinces.kr[code]?.name || null;
};

/**
 * This function is for mapping through Japan Prefectures
 *  @param {string} code – a string from address object
 * that is an iso code for Japan prefectures and metro areas
 */

export const getJpStateFromJpCode = (code) => {
  // If no code, return null now for performance
  if (!code) return null;

  // If code value is the string name of the state / province, return code
  if (
    Object.values(JPStates.en).find((state) => state.name === code) ||
    Object.values(JPStates.ja).find((state) => state.name === code)
  )
    return code;

  // If valid code translation, return state name, otherwise return null
  return JPStates.ja[code]?.name || null;
};

/**
 * This function builds an appropriate name from the recipient field of a
 * shipTo of an orderLine and returns it in an array of size 5
 *
 * @param {Object} recipient – an object containing the recipient information
 * from which to pull name information
 */
export const getNameFromRecipient = (recipient) => {
  if (!recipient || typeof recipient !== 'object') return;
  const alternateFirstName = recipient.alternateFirstName,
    alternateLastName = recipient.alternateLastName,
    firstName = recipient.firstName,
    lastName = recipient.lastName,
    middleName = recipient.middleName;

  const nameElements = [];

  if (firstName) {
    nameElements.push(firstName);
  } else if (alternateFirstName) {
    nameElements.push(alternateFirstName);
  } else nameElements.push(null);

  if (middleName) {
    nameElements.push(middleName);
  } else nameElements.push(null);

  if (lastName) {
    nameElements.push(lastName);
  } else if (alternateLastName) {
    nameElements.push(alternateLastName);
  } else nameElements.push(null);

  if (firstName && alternateFirstName) {
    nameElements.push(alternateFirstName);
  } else nameElements.push(null);

  if (lastName && alternateLastName) {
    nameElements.push(alternateLastName);
  } else nameElements.push(null);

  return nameElements;
};

/**
 * This function builds an appropriate name from the shipTo field of an
 * orderLine object.
 *
 * @param {Object} shipTo – an object containing the shipping information from
 * which to pull name information
 */
export const getNameFromShipTo = (shipTo) => {
  if (!shipTo || !shipTo.recipient) return;

  const recipient = shipTo.recipient;

  return getNameFromRecipient(recipient);
};

/**
 * This function builds an appropriate name from an orderLine object.
 *
 * @param {Object} line – the order line from which to pull name information
 */
export const getNameFromOrderLine = (line) => {
  if (!line) return;
  if (line.firstName || line.middleName || line.lastName) {
    // names are provided like this when called from paymentDetails
    return [
      line.firstName ? line.firstName : null,
      line.middleName ? line.middleName : null,
      line.lastName ? line.lastName : null,
    ];
  }
  if (!line.shipTo) return;
  const shipTo = line.shipTo;
  return getNameFromShipTo(shipTo);
};

/**
 * This function extracts email data from a shipTo field, found on an orderLine
 * object.
 *
 * @param {shipTp} shipTo – an object containing shipping information from
 * which to pull an email
 */
export const getEmailFromShipTo = (shipTo) => {
  const email = shipTo?.contactInformation?.email;
  if (!email) return;
  return email;
};

/**
 * This function extracts email data from an orderLine object.
 *
 * @param {Object} line the order line from which to pull an email
 */
export const getEmailFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getEmailFromShipTo(shipTo);
};

/**
 * This function extracts a phone number from the shipTo field of an orderLine
 * object
 *
 * @param {Object} shipTo – the shipTo object from which to derive a phone
 * number
 */
export const getDayPhoneNumberFromShipTo = (shipTo) => {
  const phone = shipTo?.contactInformation?.dayPhoneNumber;
  if (!phone) return;
  return phone;
};

/**
 * This function extracts a phone number from an orderLine object
 *
 * @param {Object} line – the order line from which to pull an phone number
 */
export const getDayPhoneNumberFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getDayPhoneNumberFromShipTo(shipTo);
};

/**
 * This function extracts a phone number from the shipTo field of an orderLine
 * object
 *
 * @param {Object} shipTo – the shipTo object from which to derive a phone
 * number
 */
export const getEveningPhoneNumberFromShipTo = (shipTo) => {
  const phone = shipTo?.contactInformation?.eveningPhoneNumber;
  if (!phone) return;
  return phone;
};

/**
 * This function extracts a phone number from an orderLine object
 *
 * @param {Object} line – the order line from which to pull an email
 */
export const getEveningPhoneNumberFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getEveningPhoneNumberFromShipTo(shipTo);
};

/**
 * This function determines whether or not an address is to an Army Post Office
 * or a Fleet Post Office.
 *
 * @param {Object} address – an address to check for militariness
 */
export const isMilitary = (address) => {
  if (!address) return false;
  const city = (address.city || '').toLowerCase();
  return city === 'apo' || city === 'fpo';
};

/**
 * This function derives a billing address from an order object by safely
 * digging in to the payment methods therein.
 *
 * @param {Object} orderDetail - the order object from which to derive an
 * address
 */
export const getBillToAddressFromOrder = (orderDetail) => {
  if (!orderDetail) return;

  const paymentMethods = getArrayProperty(orderDetail, 'paymentMethods');

  if (!paymentMethods.length) return;

  /*
  safely pull name info from the first payment method, which is guaranteed to
  exist based on the length check above (and to be an array, by the contract
  of getArrayProperty).  Cannot use destructuring in case of null value.
  */
  const firstPaymentMethod = paymentMethods[0];
  const firstName = firstPaymentMethod.firstName || '';
  const lastName = firstPaymentMethod.lastName || '';
  const alternateFirstName = firstPaymentMethod.alternateFirstName || '';
  const alternateLastName = firstPaymentMethod.alternateLastName || '';

  // map payment methods to their billTo fields, and filter out undefineds
  const billTos = paymentMethods
    .map((paymentMethod) => {
      return paymentMethod.billTo;
    })
    .filter(Boolean);

  if (!billTos.length) return;

  const { address, contactInformation } = billTos[0];

  return {
    firstName,
    lastName,
    alternateFirstName,
    alternateLastName,
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    address3: address?.address3 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
    country: address?.country || '',
    email: contactInformation?.email || '',
    dayPhoneNumber: contactInformation?.dayPhoneNumber || '',
  };
};

export const getShipToAddressFromOrderLines = (originalOrderLines) => {
  // filter out any lines which don't have the pertinent information
  let orderLines = originalOrderLines.filter((orderLine) => {
    return Boolean(orderLine.shipTo?.address?.address1);
  });

  const line = orderLines?.[0];

  if (!line) return;

  const { address, recipient } = line.shipTo;

  return {
    firstName: recipient?.firstName || '',
    middleName: recipient?.middleName || '',
    lastName: recipient?.lastName || '',
    alternateFirstName: recipient?.alternateFirstName || '',
    alternateLastName: recipient?.alternateLastName || '',
    locationName: address?.locationName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    address3: address?.address3 || '',
    address4: address?.address4 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
    country: address?.country || '',
    county: address?.county || '',
    dayPhoneNumber: getDayPhoneNumberFromOrderLine(line) || '',
    eveningPhoneNumber: getEveningPhoneNumberFromOrderLine(line) || '',
    email: getEmailFromOrderLine(line) || '',
  };
};

/**
 * This function derives a shipping address from the currently selected order
 * lines by safely digging in to the shipTo fields therein.
 *
 * @param {Object} selectedLines - an object containing the currently selected
 * order lines
 */
export const getShipToAddressFromSelectedLines = (selectedLines) => {
  if (typeof selectedLines !== 'object') return;

  let orderLines = Object.values(selectedLines);
  return getShipToAddressFromOrderLines(orderLines);
};

export const getShipToAddressFromOrder = (orderDetail) => {
  if (!orderDetail || !orderDetail.orderLines) return;

  let orderLines = orderDetail.orderLines;
  return getShipToAddressFromOrderLines(orderLines);
};

/**
 * Function to change given string to came case
 * @param {*} str - String that needs to be changed to camel case
 */
const titleCase = (str) => {
  return str
    .split(' ')
    .map((word) =>
      word
        .toLowerCase()
        .replace(/^(.{1})(.*)/, (match, offset, string) => `${offset.toLocaleUpperCase()}${string}`)
    )
    .join(' ');
};

/**
 * Helper function to normalize address.
 * Address Validator api needs certain address attributes to be in camel case.
 * @param {*} address - Address Object
 * returns - normalized address
 */
export const normalizeAddress = (address) => {
  const normalizedAddress = Object.keys(address).reduce((acc, key) => {
    acc[key] =
      key !== 'postalCode' && key !== 'state' && key !== 'country' && address[key] !== null
        ? titleCase(address[key])
        : address[key];

    return acc;
  }, {});
  return normalizedAddress;
};

export const addressChangeLogging = (
  selectedLines,
  address,
  omsRegionReference,
  orderNumber,
  athleteEmail
) => {
  try {
    if (!selectedLines) throw new Error('selectedLines must be provided.');
    if (!address) throw new Error('address must be provided.');
    if (!omsRegionReference) throw new Error('omsRegionReference must be provided.');
    if (!orderNumber) throw new Error('orderNumber must be provided.');
    if (!athleteEmail) throw new Error('athleteEmail must be provided.');

    const originalAddress = getShipToAddressFromSelectedLines(selectedLines);
    if (!isEqual(originalAddress, address)) {
      logInfoAction('postReturnCaptures_athleteChangedAddress', {
        omsRegionReference,
        orderNumber,
        athleteEmail,
      });
    }
  } catch (error) {
    logErrorAction('Error logging New Relic addPageAction.', error);
  }
};
