const translations = {
  ADD_ITEM_DETAILS: {
    description: 'label on the Inspect Return Dialog for "Add Item Details"',
    primaryValue: 'Add Item Details',
    stringKey: 'addItemDetails',
  },
  APPROVE: {
    stringKey: 'approve',
    primaryValue: 'Approve',
    description: 'label for Approve radio button',
  },
  EXIT: {
    description: 'label on the Inspect Return Dialog for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  INSPECT_ITEMS: {
    description: 'label on the Inspect Return Dialog to "Inspect Items"',
    primaryValue: 'Inspect Items',
    stringKey: 'inspectItems',
  },
  INSPECTION_REASON: {
    description: 'label on the Inspect Return Dialog for "Inspection Reason"',
    primaryValue: 'Select an Inspection Reason',
    stringKey: 'inspectionReason',
  },
  BACK: {
    stringKey: 'back',
    primaryValue: 'Back',
    description: 'label on the returns wizard for "Back"',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'label on the returns wizard for "Next"',
  },
  QUANTITY: {
    description: 'label on the Inspect Return Dialog for "Quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  REASON_UNAVAILABLE: {
    description: 'error if reason code service unavailable',
    primaryValue: 'Reason codes unavailable',
    stringKey: 'reasonUnavailable',
  },
  CLOSE: {
    stringKey: 'close',
    primaryValue: 'Close',
    description: 'label to close Inspect Return Dialog',
  },
  ESCALATE: {
    stringKey: 'escalate',
    primaryValue: 'Escalate',
    description: 'label for escalate radio button',
  },
  RETRY: {
    stringKey: 'retry',
    primaryValue: 'Retry',
    description: 'label to retry submission of return inspection form',
  },
  SELECT_INSPECTION_STATUS: {
    description: 'Label on the Return Inspect dialog to select inspection status',
    stringKey: 'selectInspectionStatus',
    primaryValue: 'Select Inspection Status',
  },
  SELECT_RETURN_DISPOSITION: {
    description: 'Label on the Return Inspect dialog to select return disposition',
    stringKey: 'selectReturnDisposition',
    primaryValue: 'Select a Return Disposition',
  },
  REJECT: {
    stringKey: 'reject',
    primaryValue: 'Reject',
    description: 'label for reject radio button',
  },
  RETURN_INSPECT_TIME_OUT_ERROR_MESSAGE: {
    stringKey: 'returnInspectTimeoutErrorMessage',
    primaryValue:
      'Return inspection is still pending. Please return to SwooshDesk and check back after some time',
    description: 'Error message when inspection call times out',
  },
  INSPECT: {
    description: 'cancel action type in submission response message',
    primaryValue: 'Return Item Inspection',
    stringKey: 'returnInspection',
  },
  ERROR: {
    description: 'label for alerting the user that an action was unsuccessful',
    primaryValue: 'unsuccessful. Please try again!',
    stringKey: 'dialogError',
  },
  SUCCESS: {
    description: 'label for alerting the user that an action was successful',
    primaryValue: 'successful!',
    stringKey: 'dialogSuccess',
  },
  CONSUMER_RETURN_REASON: {
    description: 'label to show consumer reason for returns',
    primaryValue: 'Consumer Return Reason',
    stringKey: 'consumerReturnReason',
  },
  SIZE: {
    description: 'label on the size',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  PLEASE_WAIT_AND_RELOAD: {
    description:
      'message to show when inspection was successful but order detail has not yet updated',
    primaryValue:
      'Please wait, it may take a few minutes to update, then reload to see completed inspection.',
    stringKey: 'pleaseWaitAndReload',
  },
};

export default translations;
