import { v4 as uuidv4 } from 'uuid';
import Geos from '../constants/geos.const';
import { ReturnExchangeDays } from '../constants/exchange.const';

/**
 * format's order lines for exchange option request
 * @param {Array} exchangeableLines comes from orderDetail
 * @returns {Array} of exchange items that condenses items based on gtin
 */
export const formatExchangeItems = (exchangeableLines) => {
  return exchangeableLines.reduce((accu, line) => {
    const itemIsNotInAccu =
      accu.findIndex((item) => item.gtin === line.item.universalProductCode) === -1;
    if (itemIsNotInAccu) {
      accu.push({
        gtin: line.item.universalProductCode,
        quantity: line.quantity,
      });
      return accu;
    }
    const indexOfMatchingGtin = accu.findIndex(
      (item) => item.gtin === line.item.universalProductCode
    );

    accu[indexOfMatchingGtin].quantity += line.quantity;

    return accu;
  }, []);
};

/**
 * This method filters data from Exchanges Preview response in order
 * to assemble the request body for the Exchanges Submit mutation
 * @param {object} exchangePreviewData response from exchange preview service
 * @returns {object} request object for Exchanges Submit mutation
 */
export const assembleExchangeSubmitInput = (exchangePreviewData, email, parentSalesOrder) => {
  const {
    parentReturnOrderNumber,
    parentSalesOrderNumber,
    country,
    currency,
    locale,
    fulfillmentGroups,
  } = exchangePreviewData?.exchangePreview?.response || {};
  // buckets for items and totals data
  const items = [];
  const totals = {
    items: { total: 0, details: { price: 0, discount: 0 } },
    taxes: { total: 0 },
  };
  const address = parentSalesOrder?.orderLines?.[0]?.shipTo?.address;
  const recipient = parentSalesOrder?.orderLines?.[0]?.shipTo?.recipient;

  // iterate over fulfillmentGroups data to collect totals & items
  fulfillmentGroups.forEach((grp) => {
    grp.items.forEach(({ id, skuId, quantity, itemCosts }) => {
      const {
        priceInfo: { price, discount, taxTotal, total },
        taxes,
      } = itemCosts;
      const roundedDiscount = Math.round(discount * 100) / 100;
      const priceAdjustments = [];
      // increment totals' values
      totals.items.details.price += price;
      totals.items.details.discount += roundedDiscount;
      totals.items.total += total;
      totals.taxes.total += taxTotal;
      const adjustment = {};
      if (discount) {
        adjustment.discount = {
          id: uuidv4(),
          amountPerUnit: roundedDiscount,
          type: 'PRICE_OVERRIDE',
          reasonCode: discount?.reason?.id || '',
        };
      }
      const salesTax = taxes?.find((tax) => ['SALES_TAX', 'SALESTAX'].includes(tax.type));
      // only add tax object to priceAdjustments if taxTotal for the exchange is > 0
      if (taxTotal > 0 && salesTax) {
        adjustment.tax = {
          type: 'SALES_TAX',
          amount: salesTax.total,
          rate: salesTax.rate,
          treatment: 'ADDED', // TODO: confirm which countries should be using "INCLUDED" treatment.
        };
      }
      // only add a priceAdjustment object if we've created a discount or tax object for this item.
      if (adjustment.tax || adjustment.discount) priceAdjustments.push(adjustment);
      // add item data to "items"
      items.push({
        id,
        skuId,
        quantity,
        priceAdjustments,
      });
    });
  });
  totals.items.details.discount = Math.round(totals.items.details.discount * 100) / 100;

  /* add address to the payload only if mandatory 
  fields are available in original salesorder's shipTo */
  const {
    address1,
    country: shipToCountry,
    city,
    zipCode,
    state,
    postalCode,
    address2,
    address3,
    address4,
    county,
  } = address;
  const { firstName, lastName } = recipient;
  const addAddressToPayload =
    address1 && shipToCountry && city && (zipCode || postalCode) && firstName ? true : false;
  const shipToAddressRecipient = {
    address: {
      address1,
      country: shipToCountry,
      city,
      zipCode: zipCode || postalCode,
      ...(state && { state }),
      ...(address2 && { address2 }),
      ...(address3 && { address3 }),
      ...(address4 && { address4 }),
      ...(county && { county }),
    },
    recipient: {
      firstName,
      ...(lastName && { lastName }),
    },
  };

  return {
    parentReturnOrderNumber,
    parentSalesOrderNumber,
    email,
    channel: 'nike.consumerservices.portal',
    country,
    currency,
    locale,
    items,
    totals,
    ...(addAddressToPayload && shipToAddressRecipient),
  };
};

/**
 * This method formats the exchange return policy message as per the region
 * @param {string} policy exchange return policy message
 * @param {string} region the order is associated with
 * @returns {string} formatted exchange return policy message
 */
export const formatPolicyAsPerRegion = (policy, region) => {
  switch (region) {
    case Geos.EUROPE: {
      return policy.replace(ReturnExchangeDays.DEFAULT, ReturnExchangeDays.EUROPE);
    }
    default: {
      return policy;
    }
  }
};
