import gql from 'graphql-tag';

const RETURN_LOCATION_MUTATION = gql`
  mutation postReturnLocation($input: ReturnLocationInputV1!) {
    returnLocation(input: $input) {
      receivingNode
      standardCarrierAlphaCode
      shipTo {
        address {
          address1
          address2
          address3
          address4
          city
          state
          country
          zipCode
        }
        recipient {
          company
        }
        contactInformation {
          dayPhoneNumber
          email
          eveningPhoneNumber
        }
      }
    }
  }
`;

export default RETURN_LOCATION_MUTATION;
