const translations = {
  ARIA_ORDER_SEARCH: {
    stringKey: 'ariaOrderSearch',
    primaryValue: 'order search results',
    description: 'aria label for order search results table',
  },
  ARIA_ADDRESS_TYPE: {
    stringKey: 'ariaAddressType',
    primaryValue: 'Address Type',
    description: 'aria label for radio button group: Address Type',
  },
  ADVANCED_SEARCH: {
    stringKey: 'advancedSearch',
    primaryValue: 'Advanced Search',
    description: 'link text to open form fields for advanced search',
  },
  BILLING_ADDRESS: {
    description: 'label for advanced search option: Address Type',
    primaryValue: 'Billing Address',
    stringKey: 'billingAddress',
  },
  CLEAR_SEARCH: {
    description: 'button label for clearing advanced search field inputs/options',
    primaryValue: 'Clear',
    stringKey: 'clearSearch',
  },
  GIFT_CARD_LOOKUP: {
    stringKey: 'giftCardLookup',
    primaryValue: 'Gift Card Lookup',
    description: 'gift card lookup',
  },
  LAST_10_DAYS: {
    stringKey: 'last10Days',
    primaryValue: 'Last 10 Days',
    description: 'label for date range option: Last 10 Days',
  },
  LAST_30_DAYS: {
    stringKey: 'last30Days',
    primaryValue: 'Last 30 Days',
    description: 'label for date range option: Last 30 Days',
  },
  LAST_90_DAYS: {
    stringKey: 'last90Days',
    primaryValue: 'Last 90 Days',
    description: 'label for date range option: Last 90 Days',
  },
  LAST_120_DAYS: {
    stringKey: 'last120Days',
    primaryValue: 'Last 120 Days',
    description: 'label for date range option: Last 120 Days',
  },
  ORDER_SUMMARY_ERROR: {
    stringKey: 'orderSummaryError',
    primaryValue: 'Order Summary Error',
    description: 'error message from order summary api',
  },
  ORDER_SEARCH: {
    stringKey: 'orderSearch',
    primaryValue: 'ORDER SEARCH',
    description: 'order search title',
  },
  PID_REVIEW: {
    stringKey: 'pidReviewLink',
    primaryValue: 'PiD Review',
    description: 'label for link to PiD Reviews page',
  },
  PROMO_CODES: {
    stringKey: 'promoCodesLink',
    primaryValue: 'Promo Codes',
    description: 'label for the link to the promo codes page',
  },
  SEARCH_CITY: {
    stringKey: 'city',
    primaryValue: 'City',
    description: 'label for order search field: City',
  },
  SEARCH_ORDER_NUMBER: {
    stringKey: 'orderNumber',
    primaryValue: 'Order Number',
    description: 'order text box label',
  },
  SEARCH_COUNTRY: {
    stringKey: 'country',
    primaryValue: 'Country',
    description: 'label for order search field: Country',
  },
  SEARCH_DATE_RANGE: {
    stringKey: 'dateRangeField',
    primaryValue: 'Date Range',
    description: 'label for advanced search field: Date Range',
  },
  SEARCH_EMAIL: {
    stringKey: 'email',
    primaryValue: 'Email',
    description: 'email text box label',
  },
  SEARCH_FIRST_NAME: {
    stringKey: 'firstName',
    primaryValue: 'First Name',
    description: 'label for order search field: First Name',
  },
  SEARCH_BUTTON: {
    stringKey: 'searchButton',
    primaryValue: 'SEARCH',
    description: 'search button',
  },
  SEARCH_GIFT_CARD: {
    stringKey: 'giftCardNumber',
    primaryValue: 'Gift Card Number',
    description: 'label for order search field: gift card number ',
  },
  SEARCH_LAST_NAME: {
    stringKey: 'lastName',
    primaryValue: 'Last Name',
    description: 'label for order search field: Last Name',
  },
  SEARCH_PARTNER: {
    stringKey: 'partner',
    primaryValue: 'Partner',
    description: 'label for advanced search field: Partner',
  },
  SEARCH_ORDER_TYPE: {
    stringKey: 'orderType',
    primaryValue: 'Order Type',
    description: 'label for advanced search field: Order Type',
  },
  SEARCH_RESULTS: {
    stringKey: 'searchResults',
    primaryValue: 'Search Results',
    description: 'search results header',
  },
  SEARCH_RESULTS_ORDER_DATE: {
    stringKey: 'orderDate',
    primaryValue: 'Order Date',
    description: 'search results order date',
  },
  SEARCH_RESULTS_CONSUMER_NAME: {
    stringKey: 'consumerName',
    primaryValue: 'Consumer Name',
    description: 'search results consumer name',
  },
  SEARCH_RESULTS_BILL_TO: {
    stringKey: 'billTo',
    primaryValue: 'Bill To',
    description: 'search results bill to',
  },
  SEARCH_RESULTS_TOTAL: {
    stringKey: 'searchResultsTotal',
    primaryValue: 'Total',
    description: 'search results total',
  },
  SEARCH_RESULTS_ORDER_STATUS: {
    stringKey: 'searchResultsOrderStatus',
    primaryValue: 'Order Status',
    description: 'search results order status',
  },
  SEARCH_RESULTS_ITEMS: {
    stringKey: 'searchResultsItems',
    primaryValue: 'Items',
    description: 'search results items',
  },
  NO_SEARCH_RESULT: {
    stringKey: 'noSearchResults',
    primaryValue: 'No search results',
    description: 'no search results text',
  },
  SEARCH_SAP_SALES_ORDER: {
    stringKey: 'searchSapSalesOrder',
    primaryValue: 'SAP Sales Order',
    description: 'label for field within advanced order search that looks for SAP order number',
  },
  SEARCH_SAP_PURCHASE_ORDER: {
    stringKey: 'searchSapPurchaseOrder',
    primaryValue: 'SAP Purchase Order',
    description:
      'label for field within advanced order search that takes the number provided by shipping labels of and used to process undeliverable packages',
  },
  SEARCH_STATE: {
    stringKey: 'state',
    primaryValue: 'State',
    description: 'label for advanced search field: State',
  },
  SEARCH_STYLE_NUMBER: {
    stringKey: 'styleNumber',
    primaryValue: 'Style Number',
    description: 'label for advanced search field: Style Number',
  },
  SEARCH_TRACKING_NUMBER: {
    description: 'label for advanced search field Tracking Number',
    primaryValue: 'Tracking Number',
    stringKey: 'tracking',
  },
  SEARCH_UPC: {
    stringKey: 'upc',
    primaryValue: 'UPC',
    description: 'label for advanced search field: UPC',
  },
  SEARCH_ZIP: {
    stringKey: 'postalCode',
    primaryValue: 'Postal Code',
    description: 'label for advanced search field: Postal Code',
  },
  SHIPPING_ADDRESS: {
    description: 'label on the returns wizard for "Shipping Address"',
    primaryValue: 'Shipping Address',
    stringKey: 'shippingAddress',
  },
  TODAY: {
    stringKey: 'today',
    primaryValue: 'Today',
    description: 'label for date range option: Today',
  },
  PREV: {
    stringKey: 'prev',
    primaryValue: 'Prev',
    description: 'prev link',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'next link',
  },
  SEARCH_GEO: {
    stringKey: 'geo',
    primaryValue: 'Geo',
    description: 'Geo dropdown',
  },
  SEARCH_PHONE: {
    stringKey: 'phoneNumber',
    primaryValue: 'Phone Number',
    description: 'search by phone',
  },
  MEMBER_PROFILE_LOOKUP: {
    stringKey: 'memberProfile',
    primaryValue: 'Member Profile Lookup',
    description: 'label for member profile link on omobo home page',
  },
  ARIA_MEMBER_PROFILE_LINK: {
    stringKey: 'ariaMemberLink',
    primaryValue: 'link to member profile lookup app',
    description: 'aria label for member profile lookup app',
  },
  SEARCH_ITEM_TYPE: {
    stringKey: 'itemType',
    primaryValue: 'Item Type',
    description: 'label for advanced search field: Item Type',
  },
};

export default translations;
