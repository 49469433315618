const translations = {
  ARIA_SUGGESTED_ADDRESS: {
    stringKey: 'ariaSuggestedAddress',
    primaryValue: 'suggested address',
    description: 'aria label for suggested address',
  },
  ARIA_SELECT_ADDRESS: {
    stringKey: 'ariaSelectAddress',
    primaryValue: 'selected address',
    description: 'aria label for address selection',
  },
  SUGGESTED_ADDRESS: {
    stringKey: 'suggestedAddress',
    primaryValue: 'Suggested Address',
    description: 'Header label for suggested address',
  },
  ADDRESS_ENTERED: {
    stringKey: 'addressEntered',
    primaryValue: 'Address Entered',
    description: 'Header label for address entered',
  },
  CONFIRM_ADDRESS: {
    stringKey: 'confirmAddress',
    primaryValue: 'Confirm address',
    description: 'Label on address validation component',
  },
  WARNING_CLOSE_MATCH: {
    stringKey: 'warningCloseMatch',
    primaryValue:
      "We couldn't verify the exact address entered, but did find a close match. Please make sure the mailing address is correct to ensure accurate delivery.",
    description: 'Warning text on address validation component when close was found',
  },
  WARNING_NO_MATCH: {
    stringKey: 'warningNoMatch',
    primaryValue:
      "We couldn't verify the exact address entered. Please double check to confirm it's correct or make any necessary changes.",
    description: 'Warning text on address validation component when no match was found',
  },
  EDIT_ADDRESS: {
    stringKey: 'editAddress',
    primaryValue: 'EDIT ADDRESS',
    description: 'Label for edit address button',
  },
  SAVE_AND_CREATE_RETURN_ADDRESS: {
    stringKey: 'saveAndCreateReturn',
    primaryValue: 'SAVE AND CREATE RETURN',
    description: 'Label for save button on address component',
  },
  SAVE_AND_CONTINUE: {
    stringKey: 'saveAndContinue',
    primaryValue: 'SAVE AND CONTINUE',
    description: 'Label for save and continue button on address component',
  },
};

export default translations;
