const translations = {
  ACTIVE: {
    description: 'label for active payment mwthod',
    primaryValue: 'Active',
    stringKey: 'paymentActive',
  },
  EXP: {
    description:
      'abbreviation indicating "Expiration Date." This will serve as a label for expiration date of a payment method',
    primaryValue: 'EXP',
    stringKey: 'expirationDate',
  },
  PIN: {
    description: 'label on the Payment Details page for gift card pin number',
    primaryValue: 'PIN',
    stringKey: 'pin',
  },
  SUSPENDED: {
    description: 'label on the Payment Details page for payment types in suspended status',
    primaryValue: 'Suspended',
    stringKey: 'suspended',
  },
  VOIDED: {
    stringKey: 'voided',
    primaryValue: 'Voided',
    description: 'label on the Payment Details page for voided payment method',
  },
};

export default translations;
