/** React / Utils */
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { NikeI18nContext } from '@nike/i18n-react/dist/nike-i18n-provider';
import { mapValues } from 'lodash';

/** local */
import ResponseSnackBar from '../../shared/snackbar';
import ORDER_SUMMARY_QUERY from '../../../queries/orderSummary.query';
import translations from '../../../lib/translations.json';
import useSnacks from '../../../hooks/useSnacks';
import Loading from '../../shared/loading';
import OrderSearchTable from '../../orderSearch/orderSearchTable';

/**
 * Main react component housing member orders page
 */
export default function MemberOrders({ match }) {
  const { i18nString } = useContext(NikeI18nContext);
  const { setError } = useSnacks();
  const [memberOrders, setMemberOrders] = useState({ orders: [], pages: [] });
  const { ORDER_SUMMARY_ERROR } = mapValues(translations, i18nString);
  // Call to order summary api
  const [queryOrderSummary, { data, loading, error }] = useLazyQuery(ORDER_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${ORDER_SUMMARY_ERROR} ${error.message}`);
    },
    onCompleted: () => {
      const { csOrderSummary } = data;
      setMemberOrders({ orders: csOrderSummary.objects, pages: csOrderSummary.pages });
    },
  });

  useEffect(() => {
    queryOrderSummary({
      variables: {
        filters: [{ value: match.params.upmId, key: 'customerProfileReference' }],
        anchor: memberOrders.anchor,
      },
    });
  }, []);

  return (
    <>
      <div role='main'>
        {loading && <Loading />}
        {!loading && Array.isArray(memberOrders.orders) && (
          <OrderSearchTable
            searchResults={memberOrders.orders}
            anchor={0}
            paginationLinks={memberOrders.pages}
          />
        )}
      </div>
      <ResponseSnackBar />
    </>
  );
}
