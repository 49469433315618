import gql from 'graphql-tag';

const IDENTITY_USER_QUERY = gql`
  mutation postReadIdentityUser($id: String!, $input: IdentityAccountUserV1RequestBody!) {
    identityUser(id: $id, input: $input) {
      name {
        kana {
          given
          middle
          family
        }
        latin {
          given
          middle
          family
        }
        phonetic {
          given
          family
        }
      }
      contact {
        email {
          address
          verified
        }
        sms {
          verifiedNumber
          verifiedNumberCountry
          verificationRequired
        }
      }
      dob {
        alternativeReason
        day
        minimumAge
        month
        year
      }
      emailonly
      gender
      language
      upmId
      userType
      entitlements {
        swoosh
      }
    }
  }
`;

export default IDENTITY_USER_QUERY;
