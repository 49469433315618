// enum of possible statuses for the response
export const ResponseStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  IN_PROGRES: 'IN_PROGRES',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
};

// Channel used to set in return request payload
export const Channel = 'nike.consumerservices.portal';

// enum of dialog types, should match orderDetail.orderType
export const DialogTypes = {
  CANCEL: 'CANCEL',
  RETURN: 'RETURN',
  INSPECT: 'INSPECT',
  DISCOUNT_SHIPPING: 'DISCOUNT_SHIPPING',
  RESEND_RETURN_LABEL: 'RESEND_RETURN_LABEL',
  ACTION_COMPLETE: 'ACTION_COMPLETE',
  ADD_COMMENT: 'ADD_COMMENT',
  MODIFY_PRICE: 'MODIFY_PRICE',
  EXCHANGE: 'EXCHANGE',
};

// enum for return inspection status
export const InspectionStatus = {
  APPROVE: 'APPROVE',
  DENY: 'DENY',
  ESCALATE: 'ESCALATE',
};

// enum for discount types
export const DiscountTypes = {
  PERCENT_OFF: 'PERCENT_OFF',
  AMOUNT_OFF: 'AMOUNT_OFF',
  EXACT_PRICE: 'EXACT_PRICE',
};

// enum for reason code types
export const ReasonCodeTypes = {
  CANCEL: 'CANCEL',
  INSPECTION_PASS: 'INSPECTION_PASS',
  REJECT: 'REJECT',
  ESCALATION: 'ESCALATION',
  RETURN_CANCEL: 'RETURN_CANCEL',
  RETURN: 'RETURN',
  RETURN_DISPOSITION: 'RETURN_DISPOSITION',
  PRICE_ADJUSTMENT: 'PRICE_ADJUSTMENT',
};

// dictionary enum for array of step(s) that will submit the dialog (0 indexed)
export const SubmissionSteps = {
  CANCEL: [1],
  RETURN: [2, 3],
  INSPECT: [1],
  RESEND_RETURN_LABEL: [0],
  MODIFY_PRICE: [1],
  EXCHANGE: [3],
};

// Amount of time in milliseconds we want to wait before showing timeout message for CQRS API
export const ApiTimeOut = 50000;

export const AddressValidationStatus = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
};

export const AddressTypes = {
  SUGGESTED_ADDRESS: 'suggestedAddress',
  ADDRESS_ENTERED: 'addressEntered',
};

// appId used when calling reason codes api.
export const ReasonCodeAppId = 'CSP';

export const TimeOutErrorMessageFromGrand = 'GraphQL error: MAX_TIMEOUT_REACHED';

export const CountriesExemptFromAddressValidation = [
  'ES',
  'LU',
  'IR',
  'IQ',
  'IE',
  'IP',
  'PT',
  'PL',
  'GR',
];

/*
 * non-CS cancel reason codes/descriptions - only for cases we don't get from service
 * see ticket/confluence page linked in ticket for more details:
 * https://jira.nike.com/browse/FORGE-11183
 */
export const CancelReasonCodes = {
  70: {
    'en-US': 'Cancel OOS',
    'ja-JP': 'OOSのキャンセル',
    'key': 'cancelOOS',
    'ko-KR': 'OOS 취소',
    'zh-CN': '取消 OOS',
  },
  112: {
    'en-US': 'Fraud Reject',
    'ja-JP': '不正行為の拒否',
    'key': 'fraudReject',
    'ko-KR': '사기로 인한 거부',
    'zh-CN': '存在欺诈风险，拒绝订单',
  },
  200: {
    'en-US': 'Non-Payment',
    'ja-JP': '不払い',
    'key': 'nonPayment',
    'ko-KR': '미결제',
    'zh-CN': '未付款',
  },
  202: {
    'en-US': 'Non-Payment',
    'ja-JP': '不払い',
    'key': 'nonPayment',
    'ko-KR': '미결제',
    'zh-CN': '未付款',
  },
  216: {
    'en-US': 'Self-Service Cancel',
    'ja-JP': 'セルフサービスのキャンセル',
    'key': 'selfServiceCancel',
    'ko-KR': '셀프 서비스 취소',
    'zh-CN': '取消自助服务',
  },
};

export const RetryMaxCount = 5;
