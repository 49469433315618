import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import BasicTableRow from '../../../shared/table/tableRow';
import { transformEfapiaoData } from '../../../../utils/efapiao';
import ItemsTable from './itemsTable';

import OrderContext from '../../../../store/contexts/orderContext';

/**
 * Table of eFapiaos for the current order on efapiao page
 *
 * @param {array} itemsHeaders - headers for order items table
 * @param {array} efapiaoHeaders - headers for efapiao table
 * @param {object} efapiao - each individual efapiao invoice
 * @param {string} itemsLabel - aria label for items table
 * @param {string} eFapiaosLabel - aria label for efapiaos table
 * @returns
 */

const EFapiaoTable = ({
  itemsHeaders,
  efapiaoHeaders,
  efapiao = {},
  itemsLabel,
  eFapiaosLabel,
}) => {
  const classes = useStyles();

  const [orderDetail] = useContext(OrderContext);
  const { currency, locale } = orderDetail;

  return (
    <Box className={classes.sharedBox} pt={0.25} data-testid='efapiaos-table'>
      <Table
        className={classes.halfTable}
        aria-label={eFapiaosLabel}
        data-testid='efapiaos-efapiaos-table'>
        <BasicTableRow header data={efapiaoHeaders} cellRootClassName={classes.headerCell} />
        <BasicTableRow
          data={transformEfapiaoData(efapiao, locale, currency)}
          cellRootClassName={classes.tableCell}
        />
      </Table>

      <ItemsTable
        orderLines={efapiao.lineItems}
        itemsHeaders={itemsHeaders}
        itemsLabel={itemsLabel}
      />
    </Box>
  );
};

EFapiaoTable.propTypes = {
  itemsHeaders: PropTypes.array,
  efapiaoHeaders: PropTypes.array,
  efapiao: PropTypes.object,
  itemsLabel: PropTypes.string,
  eFapiaosLabel: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  cardSubHeading: {
    fontSize: '1rem',
    padding: `10px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
  },
  tableCell: {
    padding: theme.spacing(1.4),
  },
  halfTable: {
    width: '50%',
  },
  sharedBox: {
    padding: 0,
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  headerCell: {
    whiteSpace: 'nowrap',
  },
}));

export default EFapiaoTable;
