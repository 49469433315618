import gql from 'graphql-tag';

const EXCHANGE_SUBMIT_MUTATION = gql`
  mutation exchangeSubmit($input: ExchangeSubmitInput!, $userInfo: UserInfo!, $timeout: Int!) {
    exchangeSubmit(input: $input, userInfo: $userInfo, timeout: $timeout) {
      id
      status
      resourceType
      links {
        self {
          ref
        }
      }
      eta
      response {
        parentReturnOrderNumber
        parentSalesOrderNumber
        exchangeOrderId
        email
        phoneNumber {
          type
          countryCode
          subscriberNumber
          accountId
          verifyId
        }
        country
        currency
        locale
        fulfillmentGroups {
          items {
            id
            skuId
            quantity
            itemCosts {
              priceInfo {
                price
                employeePrice
                discount
                total
                taxTotal
                priceId
                priceSnapshotId
              }
              taxes {
                type
                rate
                total
              }
              promotionDiscounts {
                code
                amount
                id
                displayName
              }
              priceAdjustments {
                discount {
                  amountPerUnit
                  type
                  reasonCode
                }
              }
            }
            fulfillmentDetails {
              type
              location {
                type
                postalAddress {
                  address1
                  address2
                  address3
                  city
                  state
                  postalCode
                  country
                  county
                }
              }
            }
          }
          recipient {
            firstName
            lastName
            altFirstName
            altLastName
            middleName
            middleInitial
            givenName
          }
          contactInfo {
            email
          }
        }
        totals {
          items {
            total
            details {
              price
              discount
            }
          }
          taxes {
            total
            details {
              items {
                tax
                type
              }
              shipping {
                tax
                type
              }
            }
          }
        }
        total
      }
      error {
        httpStatus
        message
        code
        errors {
          field
          code
          message
        }
      }
    }
  }
`;

export default EXCHANGE_SUBMIT_MUTATION;
