import { defaultState } from '../contexts/dialogContext';
import { DialogTypes, SubmissionSteps } from '../../constants/dialog.const';
import { actionTypes } from '../actions/dialogActions';
import { getShipToAddressFromSelectedLines, isMilitary } from '../../utils/address';
import {
  PostOfficeOptions as militaryPostOfficeOptions,
  RegionOptions as militaryRegionOptions,
} from '../../constants/address/militaryAddress.const';
import { safelyShallowCopyObject } from '../../utils/reactUtils';
import { showDiscountValidationError, getNewUnitPrice } from '../../utils/price';

/**
 * The reducer for the create-return context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const dialogReducer = (state, action) => {
  // we'll need the selected lines in much of what we do below
  const selectedLines = {
    ...state.selectedLines,
  };

  if (!action || !action.type) return state;

  switch (action.type) {
    // set isOpen to true (opens the dialog), sets dialogType and
    // SubmissionSteps, and resets form data if new form type is opened
    case actionTypes.OPEN: {
      const isValidType = Boolean(DialogTypes[action.dialogType]);
      const prevDialogType = state.dialogType;

      if (isValidType) {
        const updatedState = {
          isOpen: true,
          dialogType: action.dialogType,
          lock: action.lock,
          submissionSteps: SubmissionSteps[action.dialogType],
        };

        // if opening the same dialogType, selectedLines should persist
        if (prevDialogType === action.dialogType) {
          return { ...state, ...updatedState };
        } else {
          return {
            ...state,
            ...updatedState,
            activeStep: 0,
            selectedLines: {},
            retryCount: 0,
          };
        }
      } else
        return {
          ...state,
        };
    }

    // set isOpen to false (closes the dialog)
    case actionTypes.CLOSE: {
      return {
        ...state,
        isOpen: false,
        // should we set dialogType to null here? or?
        // no, it currently allows us to check "prev dialog type" when reopening
      };
    }

    // clears the state and closes the dialog
    case actionTypes.RESET: {
      return {
        ...defaultState,
        hasTimedOut: state.hasTimedOut,
      };
    }

    // increment the active step by one
    case actionTypes.NEXT_STEP: {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }

    // reduce the active step by one
    case actionTypes.PREV_STEP: {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }

    // set a complete address object
    case actionTypes.SET_ADDRESS: {
      const address = action.address;
      /*
      if there's no value, or if it doesn't look like an address (the choice to
      check address1 is completely rudimentary)
      */
      if (!address || !address.address1) return state;
      const isAlaskanAddress = address.state === 'AK';
      const isMilitaryAddress = isMilitary(address);
      const isAlaskanOrMilitary = isAlaskanAddress || isMilitaryAddress;
      const shippingIsChanging = Boolean(
        isAlaskanOrMilitary ||
          isAlaskanAddress !== state.isAlaskanAddress ||
          isMilitaryAddress !== state.isMilitaryAddress
      );

      let shippingOption;
      let prevShippingOption;
      /*
      if we're changing the shipping options, we redefine both shippingOption
      and prevShippingOption, depending on the type of address.
      */
      if (shippingIsChanging) {
        // usps is the only shipping option for military and Alaskan addresses
        if (isAlaskanOrMilitary) {
          shippingOption = 'usps';
          prevShippingOption = state.shippingOption;
        } else {
          /*
          with the current flow, in this case, we're moving away from an
          Alaskan or military address, and so we simply want to swap the values
          */
          shippingOption = state.prevShippingOption;
          prevShippingOption = state.shippingOption;
        }
      } else {
        /*
        since we're setting these values below, if they shouldn't change, set
        them to the current values in state
        */
        shippingOption = state.shippingOption;
        prevShippingOption = state.prevShippingOption;
      }

      return {
        ...state,
        isAlaskanAddress,
        isMilitaryAddress,
        shippingOption,
        prevShippingOption,
        address,
      };
    }

    // set a field on the address in state – this is complicated, stay with me
    case actionTypes.SET_ADDRESS_FIELD: {
      const { key, value } = action;
      // no setting a falsy value in state
      if (!key) return state;
      // as in SET_ADDRESS, let's store the data pertinent to state changes
      const isNewAddressAlaskan = key === 'state' && value === 'AK';
      const isNewAddressMilitary =
        (key === 'city' && (value === 'apo' || value === 'fpo')) ||
        (key === 'state' && (value === 'AA' || value === 'AE' || value === 'AP'));
      const isNewAddressAlaskanOrMilitary = isNewAddressAlaskan || isNewAddressMilitary;
      const shippingIsChanging = Boolean(
        isNewAddressAlaskanOrMilitary ||
          isNewAddressAlaskan !== state.isAlaskanAddress ||
          isNewAddressMilitary !== state.isMilitaryAddress
      );

      let shippingOption;
      let prevShippingOption;
      if (shippingIsChanging) {
        // usps is the only shipping option for military and Alaskan addresses
        if (isNewAddressAlaskanOrMilitary) {
          shippingOption = 'usps';
          prevShippingOption = state.shippingOption;
        } else {
          /*
          with the current flow, in this case, we're moving away from an
          Alaskan or military address, and so we simply want to swap the values
          */
          shippingOption = state.prevShippingOption;
          prevShippingOption = state.shippingOption;
        }
      } else {
        /*
        since we're setting these values below, if they shouldn't change, set
        them to the current values in state
        */
        shippingOption = state.shippingOption;
        prevShippingOption = state.prevShippingOption;
      }

      return {
        ...state,
        isAlaskanAddress: isNewAddressAlaskan,
        isMilitaryAddress: isNewAddressMilitary,
        shippingOption,
        prevShippingOption,
        hasUserEditedAddress: true,
        address: {
          ...state.address,
          [key]: value,
        },
      };
    }

    // set a complete gcShippingAddress
    case actionTypes.SET_GIFT_CARD_ADDRESS: {
      return {
        ...state,
        gcShippingAddress: {
          ...state.gcShippingAddress,
          ...action.address,
        },
      };
    }

    // set a returnLabelEmail
    case actionTypes.SET_RETURN_LABEL_EMAIL: {
      return {
        ...state,
        returnLabelEmail: action.returnLabelEmail,
      };
    }

    // set a field in the gcShippingAddress in state
    case actionTypes.SET_GC_SHIPPING_ADDRESS_FIELD: {
      return {
        ...state,
        gcShippingAddress: {
          ...state.gcShippingAddress,
          [action.key]: action.value,
        },
      };
    }

    // set areAllOrderLinesGiftCards based on selected items
    case actionTypes.SET_ARE_ALL_ORDER_LINES_GIFT_CARDS: {
      return {
        ...state,
        areAllOrderLinesGiftCards: Boolean(action.areAllOrderLinesGiftCards),
      };
    }

    // set if an address is military or not
    case actionTypes.SET_IS_MILITARY_ADDRESS: {
      // explicity coerce into a boolean
      const isMilitaryAddress = Boolean(action.isMilitaryAddress);
      // and bail if we don't need to do anything
      if (isMilitaryAddress === state.isMilitaryAddress) return state;

      if (isMilitaryAddress) {
        return {
          ...state,
          address: {
            ...state.address,
            city: filterMilitaryCityValue(state.address.prevCity) || '',
            prevCity: state.address.city,
            prevState: state.address.state,
            state: filterMilitaryStateValue(state.address.prevState) || '',
          },
          // mutually exclusive (or, at least, effectively)
          isAlaskanAddress: false,
          isMilitaryAddress,
          prevIsAlaskanAddress: state.isAlaskanAddress,
          prevShippingOption: state.shippingOption,
          // as per business rules
          shippingOption: 'usps',
          // set up the address for military values
        };
      } else {
        return {
          ...state,
          address: {
            ...state.address,
            state: state.address.prevState || '',
            city: state.address.prevCity || '',
            prevState: state.address.state,
            prevCity: state.address.city,
          },
          /*
          This suffices currently, as there is no subset of military addresses
          which dictate different shipping rules. If that changes, or if there
          is a bug in this domain in the future, start here.
          */
          isAlaskanAddress: state.prevIsAlaskanAddress,
          isMilitaryAddress,
          prevIsAlaskanAddress: false,
          prevShippingOption: state.shippingOption,
          shippingOption: state.prevShippingOption,
        };
      }
    }

    // set a line in the selectedLines object in state
    case actionTypes.SELECT_LINE: {
      // set the line in state

      const newSelectedLines = toggleSelectLine(selectedLines, action.line);
      /*
      if the address has been modified by the user already, let's give their
      values precedence
      */
      if (state.hasUserEditedAddress || state.dialogType !== DialogTypes.RETURN) {
        return {
          ...state,
          selectedLines: newSelectedLines,
        };
      } else {
        const address = getShipToAddressFromSelectedLines(newSelectedLines);
        const isMilitaryAddress = isMilitary(address);
        return {
          ...state,
          address,
          isMilitaryAddress,
          // as above, if the address is military, usps is our only option
          shippingOption: isMilitaryAddress ? 'usps' : state.shippingOption,
          prevShippingOption: isMilitaryAddress ? state.shippingOption : state.prevShippingOption,
          selectedLines: newSelectedLines,
        };
      }
    }

    // add a sku to the selected items to exchange for
    case actionTypes.SELECT_SKU: {
      const newSelectedSkus = state.selectedSkus;
      newSelectedSkus[action.originalLineNumber] = {
        ...action.sku,
      };
      return { ...state, selectedSkus: newSelectedSkus };
    }

    // set the email of the recipient of a gift order
    case actionTypes.SET_GIFTEE_EMAIL: {
      return {
        ...state,
        gifteeEmail: action.gifteeEmail,
      };
    }

    // set whether the return is a gift return or not
    case actionTypes.SET_IS_GIFT_RETURN: {
      // explicity coerce to a boolean, to avoid any unexpected behaviour
      const isGiftReturn = Boolean(action.isGiftReturn);
      const canRefundToGiftCard = Boolean(action.canRefundToGiftCard);

      if (action.isGiftReturn) {
        return {
          ...state,
          isGiftReturn: isGiftReturn,
          /*
           * gifts are returned to gift cards when the athletes have
           * permission to return to gift card payment
           */
          returnPaymentOption: canRefundToGiftCard ? 'giftcard' : 'original',
          prevReturnPaymentOption: state.returnPaymentOption,
        };
      } else {
        return {
          ...state,
          isGiftReturn: isGiftReturn,
          returnPaymentOption: state.prevReturnPaymentOption,
        };
      }
    }

    // set the delivery method for return shipping label
    case actionTypes.SET_DELIVERY_METHOD: {
      const { deliveryMethod } = action;

      if (deliveryMethod === 'mail') {
        return {
          ...state,
          deliveryMethod: deliveryMethod,
        };
      } else {
        return {
          ...state,
          deliveryMethod: action.deliveryMethod,
          address: {
            ...state.address,
            email: state.address.prevEmail,
            prevEmail: '',
          },
        };
      }
    }

    /*
     set the quantity of a selected item to be returned
     
     note: if this function is changed to do more than call changeOrderLine,
     more testing will be required
     */
    case actionTypes.SET_QUANTITY_TO_RETURN: {
      return {
        ...state,
        selectedLines: changeOrderLine(
          selectedLines,
          action.line,
          'quantityToReturn',
          action.quantity
        ),
      };
    }

    /*
     set the reason for returning a selected item
     
     note: if this function is changed to do more than call changeOrderLine,
     more testing will be required
     */
    case actionTypes.SET_RETURN_REASON: {
      return {
        ...state,
        selectedLines: changeOrderLine(
          selectedLines,
          action.line,
          'returnReason',
          action.returnReason
        ),
      };
    }

    /*
     set the quantity of a selected item to be cancelled
     
     note: if this function is changed to do more than call changeOrderLine,
     more testing will be required
     */
    case actionTypes.SET_QUANTITY_TO_CANCEL: {
      return {
        ...state,
        selectedLines: changeOrderLine(
          selectedLines,
          action.line,
          'quantityToCancel',
          action.quantity
        ),
      };
    }

    /*
     set the reason for cancelling a selected item
     
     note: if this function is changed to do more than call changeOrderLine,
     more testing will be required
     */
    case actionTypes.SET_CANCEL_REASON: {
      return {
        ...state,
        selectedLines: changeOrderLine(
          selectedLines,
          action.line,
          'cancelReason',
          action.cancelReason
        ),
      };
    }

    case actionTypes.SET_INSPECTION_STATUS: {
      const lineNumber = action.line?.lineNumber;
      const newSelectedLines = safelyShallowCopyObject(selectedLines);

      if (!lineNumber || !newSelectedLines?.[lineNumber]) {
        console.error(`failed to set inspection status with action: ${JSON.stringify(action)}`);
        return newSelectedLines;
      }

      return {
        ...state,
        selectedLines: {
          ...newSelectedLines,
          [lineNumber]: {
            ...newSelectedLines[lineNumber],
            inspectReasonCodeType: action.inspectReasonCodeType,
            inspectionReason: { code: '-1' },
            disposition: { code: '-1' },
          },
        },
      };
    }
    /*
     set the reason for inspecting a selected item
     
     note: if this function is changed to do more than call changeOrderLine,
     more testing will be required
     */
    case actionTypes.SET_INSPECTION_REASON: {
      return {
        ...state,
        selectedLines: changeOrderLine(
          selectedLines,
          action.line,
          'inspectionReason',
          action.inspectionReason
        ),
      };
    }

    /*
     set the reason for discount of a selected item
     */
    case actionTypes.SET_PRICE_MOD_REASON: {
      return {
        ...state,
        selectedLines: setLineDiscountReason(selectedLines, action.line, action.discountReason),
      };
    }

    /*
      sets discount value, price and validation for a line price
     */
    case actionTypes.SET_LINE_DISCOUNT_VALUE: {
      const lineNumber = action.line?.lineNumber;
      const newSelectedLines = safelyShallowCopyObject(selectedLines);

      if (!lineNumber || !newSelectedLines?.[lineNumber]) {
        console.error(`failed to set line discount value with action ${JSON.stringify(action)}`);
        return newSelectedLines;
      }

      const lineWithUpdatedDiscountValue = {
        ...action.line,
        discountValue: action.discountValue,
      };

      const lineWithUpdatedDiscountValueAndPrice = {
        ...lineWithUpdatedDiscountValue,
        discountPrice: getNewUnitPrice(lineWithUpdatedDiscountValue),
      };

      return {
        ...state,
        selectedLines: {
          ...newSelectedLines,
          [lineNumber]: {
            ...lineWithUpdatedDiscountValueAndPrice,
            linePriceValidationError: showDiscountValidationError(
              lineWithUpdatedDiscountValueAndPrice
            ),
          },
        },
      };
    }

    /*
       set the disposition reason for inspecting a selected item
       */
    case actionTypes.SET_RETURN_DISPOSITION: {
      return {
        ...state,
        selectedLines: setDisposition(selectedLines, action.line, action.disposition),
      };
    }

    /*
       set the escalation notes for inspecting a selected item
       */
    case actionTypes.SET_ESCALATION_NOTES: {
      return {
        ...state,
        selectedLines: setEscalationNotes(selectedLines, action.line, action.notes),
      };
    }

    // set the return payment option for the return
    case actionTypes.SET_RETURN_PAYMENT_OPTION: {
      return {
        ...state,
        returnPaymentOption: action.returnPaymentOption,
      };
    }

    // set the inspect reason code type for inspecting a selected item
    case actionTypes.SET_INSPECT_REASON_CODE_TYPE: {
      return {
        ...state,
        selectedLines: setReasonCodeType(selectedLines, action.line, action.inspectReasonCodeType),
      };
    }

    // set the discount type selected in the UI
    case actionTypes.SET_DISCOUNT_TYPE: {
      const lineNumber = action.line?.lineNumber;
      const newSelectedLines = safelyShallowCopyObject(selectedLines);

      if (!lineNumber || !newSelectedLines?.[lineNumber]) {
        console.error(`failed to set discount type with action ${JSON.stringify(action)}`);
        return newSelectedLines;
      }

      const lineWithUpdatedDiscountType = {
        ...action.line,
        discountType: action.discountType,
      };
      const lineWithUpdatedDiscountTypeAndPrice = {
        ...lineWithUpdatedDiscountType,
        discountPrice: getNewUnitPrice(lineWithUpdatedDiscountType),
      };

      return {
        ...state,
        selectedLines: {
          ...newSelectedLines,
          [lineNumber]: {
            ...lineWithUpdatedDiscountTypeAndPrice,
            linePriceValidationError: showDiscountValidationError(
              lineWithUpdatedDiscountTypeAndPrice
            ),
          },
        },
      };
    }

    // set the shipping method of the return shipping label
    case actionTypes.SET_SHIPPING_OPTION: {
      return {
        ...state,
        shippingOption: action.shippingOption,
        prevShippingOption: state.shippingOption,
      };
    }

    // set the initial value for the shipping method of the return label
    case actionTypes.SET_INITIAL_SHIPPING_OPTION: {
      return {
        ...state,
        shippingOption: action.shippingOption,
        prevShippingOption: action.shippingOption,
      };
    }

    // set the return order number
    case actionTypes.SET_RETURN_ORDER_NUMBER: {
      return {
        ...state,
        returnOrderNumber: action.returnOrderNumber,
      };
    }

    // set the ship to address
    case actionTypes.SET_SHIP_TO_ADDRESS: {
      return {
        ...state,
        shipTo: {
          address: {
            ...action.shipTo.address,
          },
          recipient: {
            companyName: action.shipTo.company,
            firstName: action.shipTo.company, // need to have valid firstName
          },
          // we need contact info and phone number for korea if we include this otherwise
          // it throws errors
          contactInformation: action.shipTo?.contactInformation,
        },
      };
    }

    /*
       set whether to require user address validation
       */
    case actionTypes.SET_VALIDATE_ADDRESS: {
      return {
        ...state,
        validateAddress: action.boolean,
      };
    }

    /*
       set whether or not dialog request has timed out
       */
    case actionTypes.SET_TIMED_OUT: {
      return {
        ...state,
        hasTimedOut: action.boolean,
      };
    }

    /*
      set shipping discount reason
      */
    case actionTypes.SET_SHIPPING_DISCOUNT_REASON: {
      return {
        ...state,
        shippingDiscountReason: action.shippingDiscountReason,
      };
    }

    /*
      set shipping group
      */
    case actionTypes.SET_SHIPPING_GROUP: {
      return {
        ...state,
        shippingGroup: action.shippingGroup,
      };
    }

    // set all selected lines
    case actionTypes.SET_ALL_SELECTED_LINES: {
      // if all the exchangeable lines are already selected we want to unselect everything
      if (action.selectableLines.length === Object.keys(state.selectedLines).length) {
        return {
          ...state,
          selectedLines: {},
        };
      }

      // otherwise add al the selectable lines to selected
      const newSelectedLines = {};
      action.selectableLines.forEach((orderLine) => {
        newSelectedLines[orderLine.lineNumber] = safelyShallowCopyObject(orderLine);
      });

      return {
        ...state,
        selectedLines: newSelectedLines,
      };
    }

    // set isExchangeSubmitting
    case actionTypes.SET_IS_EXCHANGE_SUBMITTING: {
      return {
        ...state,
        isExchangeSubmitting: action.isExchangeSubmitting,
      };
    }

    // set a selected SKU's discount type
    case actionTypes.SET_SKU_DISCOUNT: {
      return {
        ...state,
        selectedSkus: {
          ...state.selectedSkus,
          [action.lineNumber]: {
            ...state.selectedSkus[action.lineNumber],
            discount: action.discount,
          },
        },
      };
    }

    case actionTypes.SET_SKU_IN_STOCK: {
      const { inStock, lineNumber } = action;
      return {
        ...state,
        selectedSkus: {
          ...state.selectedSkus,
          [lineNumber]: {
            ...state.selectedSkus[lineNumber],
            inStock,
          },
        },
      };
    }

    case actionTypes.CLEAR_SKUS: {
      return {
        ...state,
        selectedSkus: {},
      };
    }

    case actionTypes.SET_TRACKING_NUMBER: {
      return {
        ...state,
        trackingNumber: action.trackingNumber,
      };
    }

    case actionTypes.SET_EXCHANGE_LOCALE: {
      return {
        ...state,
        exchangeLocale: action.exchangeLocale,
      };
    }

    case actionTypes.SET_EXCHANGE_COUNTRY: {
      return {
        ...state,
        exchangeCountry: action.exchangeCountry,
      };
    }

    case actionTypes.SET_EXCHANGE_CREDIT_VALUE: {
      return {
        ...state,
        exchangeCreditValue: action.exchangeCreditValue,
      };
    }

    case actionTypes.SET_EXCHANGE_TOTAL_PRICE: {
      return {
        ...state,
        exchangeTotalPrice: action.exchangeTotalPrice,
      };
    }

    case actionTypes.SET_IS_SUBMIT_READY: {
      return {
        ...state,
        isSubmitReady: action.isSubmitReady,
      };
    }

    default:
      return state;
  }
};

/**
 * A generic function for changing properties on an order line on an object of
 * selected lines.
 *
 * @param {Object} selectedLines – the selected order lines
 * @param {Object} line – the order line to be modified
 * @param {String} key – the key of the value to be changed on the order line
 * @param {String} value – the value to be set on the above key
 *
 * @returns {Object} – if all goes well, a copy of the selectedLine param with
 * the line corresponding to the line passed in modified with the key/value
 * supplied. If the key is malformed, or not present on selectedLines, the copy
 * of selectedLines is returned. if selectedLines is malformed, an empty object
 * is returned. hopefully, in the case that the selectedLines in state is
 * malformed, this will allow the application to recover.
 */
export const changeOrderLine = (selectedLines, line, key, value) => {
  const lineNumber = line?.lineNumber;
  const newSelectedLines = safelyShallowCopyObject(selectedLines);

  if (!lineNumber || !newSelectedLines?.[lineNumber]) {
    console.error(
      `failed to change order line properties. LineNumber: ${lineNumber} key: ${key} value: ${value}`
    );
    return newSelectedLines;
  }

  return {
    ...newSelectedLines,
    [lineNumber]: {
      ...newSelectedLines[lineNumber],
      [key]: value,
    },
  };
};

/**
 * This function handles adding/removing items from the selectedItems object
 *
 * @param {Object} selectedLines – the selected order lines
 * @param {Object} line – the order line to be selected/deselected
 */
export const toggleSelectLine = (selectedLines, line) => {
  const lineNumber = line && line.lineNumber;
  if (!lineNumber) return selectedLines;

  /*
  if selectedLines is an object, let's make a shallow copy (as to not mutate),
  otherwise, let's operate on an empty object
   */
  const newSelectedLines = safelyShallowCopyObject(selectedLines);

  // if the item is already selected, remove it
  if (Boolean(newSelectedLines[lineNumber])) {
    delete newSelectedLines[lineNumber];
    // otherwise, add it.
  } else {
    newSelectedLines[lineNumber] = line;
  }

  return newSelectedLines;
};

/**
 * This is a helper function for transitioning between regular and military
 * addresses. It takes a city name and checks if it potentially matches a
 * military address. If so, that value is returned. Otherwise, 'none' is
 * returned.
 *
 * @param {String} city – a city to filter against
 */
export const filterMilitaryCityValue = (city) => {
  if (militaryPostOfficeOptions.map((option) => option.value).includes(city)) {
    return city;
  }
  return '';
};

/**
 * This is a helper function for transitioning between regular and military
 * addresses. It takes a state name and checks if it potentially matches a
 * military address. If so, that value is returned. Otherwise, 'none' is
 * returned.
 *
 * @param {String} state – a state to filter against
 */
export const filterMilitaryStateValue = (state) => {
  if (militaryRegionOptions.map((option) => option.value).includes(state)) {
    return state;
  }
  return '';
};

/**
 * This function adds the attribute inspectReasonCodeType
 * to the state under selected lines object E.g REJECT, ESCALATION
 *
 * @param {Object} selectedLines – items selected for return inspection
 * @param {Object} line – the order line to apply the quantity change to
 * @param {String} reasonCodeType – the reasonCodeType to be set
 */
const setReasonCodeType = (selectedLines, line, inspectReasonCodeType) =>
  changeOrderLine(selectedLines, line, 'inspectReasonCodeType', inspectReasonCodeType);

/**
 * This function adds the attribute disposition code and description
 * to the state under selected lines object
 *
 * @param {Object} selectedLines – items selected for return inspection
 * @param {Object} line – the order line to apply the quantity change to
 * @param {String} disposition – the disposition code and description to be set
 */
const setDisposition = (selectedLines, line, disposition) =>
  changeOrderLine(selectedLines, line, 'disposition', disposition);

/**
 * This function adds the boolean validation error
 * to the state under selected lines object
 *
 * @param {Object} selectedLines – items selected for price modification
 * @param {Object} line – selected order line
 * @param {String} discountReason – reason for discount
 */
const setLineDiscountReason = (selectedLines, line, discountReason) =>
  changeOrderLine(selectedLines, line, 'discountReason', discountReason);

/**
 * This function adds the attribute escalationNotes
 * to the state under selected lines object
 *
 * @param {Object} selectedLines – items selected for return inspection
 * @param {Object} line – the order line to apply the quantity change to
 * @param {String} notes – the escalation notes while escalating an inspection
 */
const setEscalationNotes = (selectedLines, line, notes) =>
  changeOrderLine(selectedLines, line, 'escalationNotes', notes);
