import gql from 'graphql-tag';

const QUERY_GC_BALANCE_MUTATION = gql`
  mutation postQueryGiftCardBalance($input: QueryGiftCardInputV1!) {
    queryGiftCardBalance(input: $input) {
      currency
      balance
    }
  }
`;

export default QUERY_GC_BALANCE_MUTATION;
