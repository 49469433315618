import gql from 'graphql-tag';

const ORDER_DETAIL_QUERY = gql`
  query getOrderDetail($orderNumber: ID!, $isFollowUp: Boolean) {
    orderDetail(orderNumber: $orderNumber, isFollowUp: $isFollowUp) {
      references {
        name
        value
      }
      shipmentsV2 {
        objects {
          id
          shipmentIdentifier
          creationDate
          modificationDate
          orderNumber
          orderType
          billOfLadingNumber
          actualShipmentDate
          actualShippingMethod
          standardCarrierAlphaCode
          consolidatedCustomerTrackingUrl
          trackingNumber
          carrierTrackingUrl
          carrierStatus
          shipTo {
            upmId
            city
            state
            country
            postalCode
            locale
          }
          containers {
            containerNumber
            trackingNumber
            actualDeliveryDate
            carrierStatus
            carrierTrackingUrl
            customerTrackingUrl
            shipmentLines {
              orderLineIdentifier
              fulfillmentRequestNumber
              fulfillmentOrderLineIdentifier
              lineNumber
              universalProductCode
              customizedProductReference
              unitOfMeasure
              quantity
              serialNumbers {
                value
              }
            }
          }
        }
      }
      csOrderSummary {
        objects {
          orderNumber
          status
          orderSubmitDate
          modificationDate
          channel
          orderType
          orderLines {
            parentSalesOrderLineKey
            fulfillmentMethod
          }
          paymentMethods {
            paymentType
            displayCardNumber
            svcNumberHash
          }
          store {
            registerNumber
            storeNumber
            transactionBeginDate
            transactionNumber
          }
        }
      }
      exchangeOrderSummary {
        objects {
          orderNumber
          status
          orderSubmitDate
          modificationDate
          orderType
          orderLines {
            parentSalesOrderLineKey
          }
        }
      }
      returnMethod
      channel
      orderNumber
      orderHeaderKey
      parentReturnOrder
      customerProfileReference
      orderCreateDate
      status
      paymentStatus
      currency
      omsRegionReference
      division
      orderType
      orderClassification
      assistingAgentReference
      couponCodes
      pendingModification {
        type
        requestedDate
      }
      store {
        storeNumber
        storeId
        transactionBeginDate
      }
      billTo {
        address {
          city
          state
          address1
          zipCode
          country
        }
        contactInformation {
          phoneNumber {
            countryCode
            subscriberNumber
          }
          email
          dayPhoneNumber
        }
        recipient {
          middleName
          lastName
          firstName
        }
      }
      returnByGiftRecipient
      paymentMethods {
        accountNumber
        checkReference
        creditCardHolderName
        creditCardExpiration
        firstName
        lastName
        middleName
        merchantAccountNumber
        displayCardNumber
        displayCreditCardNumber
        displayDebitCardNumber
        displayGiftCardNumber
        paymentId
        paymentKey
        paymentType
        svcNumber
        svcNumberHash
        requestedAuthorizationAmount
        requestedChargeAmount
        suspendChargeReference
        totalChargedAmount
        totalAuthorizedAmount
        totalRefundedAmount
        billTo {
          address {
            address1
            address2
            address3
            city
            country
            state
            zipCode
          }
          contactInformation {
            email
            dayPhoneNumber
          }
          recipient {
            firstName
            lastName
          }
        }
      }
      chargeTransactionDetails {
        chargeTransactionKey
        paymentKey
        paymentId
        chargeReference
        collectionDate
        creditCardTransactions {
          authorizationTime
          authorizationCode
        }
        bookAmount
        creditAmount
        debitAmount
        requestAmount
        status
        preChargeFlag
        transactionDate
        transferToOrderHeaderKey
        authorizationReference
        invoiceCollectionDetails {
          invoiceNumber
        }
      }
      marketplaceDetail {
        partnerOrderNumber
        partnerReferenceNumber
      }
      orderHolds {
        type
        status
        lastHoldDate
      }
      orderNotes {
        contactDate
        contactReference
        contactType
        contactUser
        details
        reasonCode
      }
      orderLines {
        bomDetails {
          GetBuildBOMConfigurationService {
            bom {
              comps {
                comp {
                  name
                  values {
                    value {
                      type
                      code
                      name
                      text
                    }
                  }
                }
              }
            }
          }
        }
        storeOrderLineDetail {
          transactionType
        }
        styleNumber
        colorCode
        colorDescription
        customizedProductReference
        consumerDesigns {
          objects {
            imagery {
              imageSourceURL
            }
          }
        }
        estimatedDeliveryDate
        scheduledDeliveryStartDate
        fulfillmentMethod
        deliveryMethod
        displaySize
        sizeDescription
        orderLineType
        orderLineKey
        quantity
        lineNumber
        cancellationReason
        cancellableQuantity
        cancelRequestQuantity
        commodityCode
        productId
        refundReason
        returnableFlag
        returnableQuantity
        returnReason
        cancellableFlag
        parentSalesOrderNumber
        parentSalesOrderLineKey
        shippingMethod
        shipToStoreNumber
        giftCardDetail {
          giftCardValue
          senderLastName
          senderFirstName
          giftCards {
            authorizationCode
            giftCardInfoKey
            giftCardNumber
            pin
          }
        }
        shipTo {
          address {
            address1
            address2
            address3
            address4
            city
            state
            zipCode
            country
            county
            pickUpLocation
            pickUpLocationIdentifier
            pickUpLocationType
          }
          contactInformation {
            dayPhoneNumber
            email
            eveningPhoneNumber
          }
          recipient {
            alternateFirstName
            alternateLastName
            firstName
            lastName
            middleName
          }
          location {
            id
            type
          }
        }
        statuses {
          date
          description
          quantity
          statusCode
          shipNode
          chainedOrderNumber
          contractNumber
          shipAdviceNumber
        }
        item {
          itemDescription
          universalProductCode
          stockKeepingUnitId
          unitOfMeasure
        }
        lineDates {
          date
          dateType
        }
        lineCharges {
          additionalInformation
          agentReference
          chargeName
          chargeCategory
          chargePerQuantity
        }
        lineHolds {
          type
          status
          lastHoldDate
        }
        linePriceInformation {
          listPrice
          unitPrice
          retailPrice
        }
        lineTaxes {
          taxAmount
          taxName
          chargeCategory
          chargeName
          taxPercentage
          effectiveTaxAmount
          effectiveTaxPercentage
        }
        lineNotes {
          contactDate
          contactReference
          contactType
          contactUser
          details
          reasonCode
        }
        references {
          name
          value
        }
        instructions {
          detail
          instructionType
          sequenceNumber
        }
      }
      headerCharges {
        agentReference
        additionalInformation
        chargeName
        chargeAmount
        chargeCategory
      }
      headerTaxes {
        effectiveTaxAmount
        chargeCategory
        tax
        taxPercentage
        taxName
      }
      orderVersion
      totalAmount
      userInfo {
        userType
      }
      locale
      instructions {
        detail
        instructionType
        sequenceNumber
      }
    }
  }
`;

const ORDER_DETAIL_CHINA_QUERY = gql`
  query getOrderDetail($orderNumber: ID!) {
    orderDetail(orderNumber: $orderNumber) {
      references {
        name
        value
      }
      shipmentsV2 {
        objects {
          id
          shipmentIdentifier
          creationDate
          modificationDate
          orderNumber
          orderType
          billOfLadingNumber
          actualShipmentDate
          actualShippingMethod
          standardCarrierAlphaCode
          consolidatedCustomerTrackingUrl
          trackingNumber
          carrierTrackingUrl
          carrierStatus
          shipTo {
            upmId
            city
            state
            country
            postalCode
            locale
          }
          containers {
            containerNumber
            trackingNumber
            actualDeliveryDate
            carrierStatus
            carrierTrackingUrl
            customerTrackingUrl
            shipmentLines {
              orderLineIdentifier
              fulfillmentRequestNumber
              fulfillmentOrderLineIdentifier
              lineNumber
              universalProductCode
              customizedProductReference
              unitOfMeasure
              quantity
              serialNumbers {
                value
              }
            }
          }
        }
      }
      csOrderSummary {
        objects {
          orderNumber
          status
          orderSubmitDate
          modificationDate
          channel
          orderType
          orderLines {
            parentSalesOrderLineKey
            fulfillmentMethod
          }
          paymentMethods {
            paymentType
            displayCardNumber
            svcNumberHash
          }
          store {
            registerNumber
            storeNumber
            transactionBeginDate
            transactionNumber
          }
        }
      }
      exchangeOrderSummary {
        objects {
          orderNumber
          status
          orderSubmitDate
          modificationDate
          orderType
          orderLines {
            parentSalesOrderLineKey
          }
        }
      }
      returnMethod
      channel
      orderNumber
      orderHeaderKey
      parentReturnOrder
      customerProfileReference
      orderCreateDate
      status
      paymentStatus
      currency
      omsRegionReference
      division
      orderType
      orderClassification
      assistingAgentReference
      couponCodes
      pendingModification {
        type
        requestedDate
      }
      store {
        storeNumber
        storeId
        transactionBeginDate
      }
      billTo {
        address {
          city
          state
          address1
          zipCode
          country
        }
        contactInformation {
          phoneNumber {
            countryCode
            subscriberNumber
          }
          email
          dayPhoneNumber
        }
        recipient {
          middleName
          lastName
          firstName
        }
      }
      returnByGiftRecipient
      paymentMethods {
        accountNumber
        checkReference
        creditCardExpiration
        creditCardHolderName
        firstName
        lastName
        middleName
        merchantAccountNumber
        displayCardNumber
        displayCreditCardNumber
        displayDebitCardNumber
        displayGiftCardNumber
        paymentId
        paymentKey
        paymentType
        requestedAuthorizationAmount
        requestedChargeAmount
        svcNumberHash
        suspendChargeReference
        totalChargedAmount
        totalAuthorizedAmount
        totalRefundedAmount
        billTo {
          address {
            city
            state
            address1
            zipCode
            country
          }
          contactInformation {
            email
            dayPhoneNumber
          }
          recipient {
            firstName
            lastName
          }
        }
      }
      chargeTransactionDetails {
        chargeTransactionKey
        paymentKey
        paymentId
        chargeReference
        collectionDate
        creditCardTransactions {
          authorizationTime
          authorizationCode
        }
        bookAmount
        creditAmount
        debitAmount
        requestAmount
        status
        preChargeFlag
        transactionDate
        authorizationReference
        invoiceCollectionDetails {
          invoiceNumber
        }
      }
      marketplaceDetail {
        partnerOrderNumber
        partnerReferenceNumber
      }
      orderHolds {
        type
        status
        lastHoldDate
      }
      orderNotes {
        contactDate
        contactReference
        contactType
        contactUser
        details
        reasonCode
      }
      orderLines {
        bomDetails {
          GetBuildBOMConfigurationService {
            bom {
              comps {
                comp {
                  name
                  values {
                    value {
                      type
                      code
                      name
                      text
                    }
                  }
                }
              }
            }
          }
        }
        storeOrderLineDetail {
          transactionType
        }
        styleNumber
        colorCode
        colorDescription
        customizedProductReference
        consumerDesigns {
          objects {
            imagery {
              imageSourceURL
            }
          }
        }
        estimatedDeliveryDate
        fulfillmentMethod
        deliveryMethod
        displaySize
        sizeDescription
        orderLineType
        orderLineKey
        quantity
        lineNumber
        cancellationReason
        cancellableQuantity
        cancelRequestQuantity
        commodityCode
        productId
        refundReason
        returnableFlag
        returnableQuantity
        returnReason
        cancellableFlag
        parentSalesOrderNumber
        parentSalesOrderLineKey
        shippingMethod
        shipToStoreNumber
        giftCardDetail {
          giftCardValue
          senderLastName
          senderFirstName
          giftCards {
            authorizationCode
            giftCardInfoKey
            giftCardNumber
            pin
          }
        }
        shipTo {
          address {
            address1
            address2
            address3
            address4
            city
            state
            zipCode
            country
            pickUpLocation
            pickUpLocationIdentifier
            pickUpLocationType
          }
          contactInformation {
            dayPhoneNumber
            email
            eveningPhoneNumber
          }
          recipient {
            alternateFirstName
            alternateLastName
            firstName
            lastName
            middleName
          }
          location {
            id
            type
          }
        }
        statuses {
          date
          description
          quantity
          statusCode
          shipNode
          chainedOrderNumber
          contractNumber
          shipAdviceNumber
        }
        item {
          itemDescription
          universalProductCode
          stockKeepingUnitId
          unitOfMeasure
        }
        lineDates {
          date
          dateType
        }
        lineCharges {
          additionalInformation
          agentReference
          chargeName
          chargeCategory
          chargePerQuantity
        }
        lineHolds {
          type
          status
          lastHoldDate
        }
        linePriceInformation {
          listPrice
          unitPrice
          retailPrice
        }
        lineTaxes {
          taxAmount
          taxName
          chargeCategory
          chargeName
          taxPercentage
          effectiveTaxAmount
          effectiveTaxPercentage
        }
        lineNotes {
          contactDate
          contactReference
          contactType
          contactUser
          details
          reasonCode
        }
        references {
          name
          value
        }
        instructions {
          detail
          instructionType
          sequenceNumber
        }
      }
      headerCharges {
        agentReference
        additionalInformation
        chargeName
        chargeAmount
        chargeCategory
      }
      headerTaxes {
        effectiveTaxAmount
        chargeCategory
        tax
        taxPercentage
        taxName
      }
      orderVersion
      totalAmount
      userInfo {
        userType
      }
      locale
      instructions {
        detail
        instructionType
        sequenceNumber
      }
    }
  }
`;

// if we're using the china app we don't want
const query =
  process.env.PUBLIC_URL === '/portlets/postpurchase'
    ? ORDER_DETAIL_CHINA_QUERY
    : ORDER_DETAIL_QUERY;
export default query;
