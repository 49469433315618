/* React/Utils */
import React, { useContext, useEffect, useMemo } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import InputMask from 'react-input-mask';

/* Material-UI */
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

/* Local */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { FirstNameField, LastNameField } from './nameFields';
import KRProvinces from '../../constants/address/KRProvinces.const';
import { Country } from '../../constants/country.const';
import dialogActions from '../../store/actions/dialogActions';
import { DialogContext } from '../../store/contexts/dialogContext';

/**
 * This is a reusable component for KO consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const KOAddressForm = ({ isGiftCardAddress = false, className = '', shouldBeEditable }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);

  const classes = useStyles();

  const { setAddressField } = dialogActions;
  const {
    address1,
    address2,
    city,
    state,
    postalCode,
    dayPhoneNumber,
    country,
  } = dialogState?.address;

  const {
    KOREA_CITY,
    KOREA_PROVINCE,
    STREET_NAME,
    POSTAL_CODE,
    PHONE_NUMBER,
    ADDRESS_OTHER,
  } = mapValues(translations, i18nString);

  /**
   * This use effect is only intended to execute once, when the component loads, to ensure the
   * address country field is 'KR'.
   * Also updates phone numbers
   */
  useEffect(() => {
    if (country.toUpperCase() !== Country.KOREA) {
      dialogDispatch(setAddressField('country', Country.KOREA));
    }
    /*
      Check to see if the initial value being used for state matches the codes we have on file
      otherwise default to an empty string. This ensures the submit button is not enabled.
     */
    if (state && !KRProvinces.kr[state]) {
      dialogDispatch(setAddressField('state', ''));
    }
  }, []);

  const sortedKoreaProvinces = useMemo(() => Object.keys(KRProvinces.kr).sort(), []);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <div className={clsx(classes.addressGrid, className)} data-testid='address-form-korea'>
      <LastNameField classes={classes} required={true} disabled={!shouldBeEditable} />
      <FirstNameField classes={classes} required={true} disabled={!shouldBeEditable} />

      {/* Korea Province maps to the state field */}
      <FormControl className={classes.province} aria-label={KOREA_PROVINCE} required>
        <InputLabel id='chooseProvince' shrink>
          {KOREA_PROVINCE}
        </InputLabel>
        <Select
          value={state || 'none'}
          onChange={handleChange('state')}
          data-testid={'select-province'}
          aria-labelledby='chooseProvince'
          labelId='chooseProvince'
          disabled={!shouldBeEditable}>
          <MenuItem disabled value='none'>
            —
          </MenuItem>
          {/* TODO When implementing support for multiple geos,
           * determine when to use en or kr. */}

          {sortedKoreaProvinces.map((code) => {
            return (
              <MenuItem
                data-key='state'
                value={code}
                key={code}
                data-testid={`select-province-${code}`}>
                {KRProvinces.kr[code]?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* Korea city maps to the city field */}
      <FormControlLabel
        aria-label={KOREA_CITY}
        className={classes.city}
        control={
          <TextField
            required
            value={city}
            onChange={handleChange('city')}
            label={KOREA_CITY}
            className={classes.wideField}
            data-testid={'input-city'}
            disabled={!shouldBeEditable}
          />
        }
      />
      {/* Street Name maps to the address1 field */}
      <FormControlLabel
        aria-label={STREET_NAME}
        className={classes.address1}
        control={
          <TextField
            required
            value={address1}
            onChange={handleChange('address1')}
            label={STREET_NAME}
            className={classes.wideField}
            data-testid={'input-street-name'}
            disabled={!shouldBeEditable}
          />
        }
      />
      {/* Address Other maps to the address2 field */}
      <FormControlLabel
        aria-label={ADDRESS_OTHER}
        className={classes.address2}
        control={
          <TextField
            value={address2}
            onChange={handleChange('address2')}
            label={ADDRESS_OTHER}
            className={classes.wideField}
            data-testid={'input-address-other'}
            disabled={!shouldBeEditable}
          />
        }
      />
      <FormControlLabel
        aria-label={POSTAL_CODE}
        className={classes.postalCode}
        control={
          <TextField
            required
            value={postalCode}
            onChange={handleChange('postalCode')}
            label={POSTAL_CODE}
            className={classes.wideField}
            data-testid={'input-postal-code'}
            disabled={!shouldBeEditable}
          />
        }
      />
      <InputMask
        mask='010-9999-9999'
        value={dayPhoneNumber}
        onChange={handleChange('dayPhoneNumber')}>
        {() => (
          <FormControlLabel
            aria-label={PHONE_NUMBER}
            className={classes.phoneField}
            control={
              <TextField
                required
                aria-required={true}
                inputProps={{ 'data-key': 'dayPhoneNumber' }}
                label={PHONE_NUMBER}
                className={classes.wideField}
                data-testid={'input-phone-number'}
                disabled={!shouldBeEditable}
              />
            }
          />
        )}
      </InputMask>
    </div>
  );
};

export default KOAddressForm;

const useStyles = makeStyles({
  addressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " LN LN FN FN .. .. "
      " PR PR CI CI A1 A1 "
      " A2 A2 .. .. .. .. "
      " PO PO .. .. .. .. "
      " PH PH .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  firstNameField: {
    gridArea: 'FN',
  },
  address1: {
    gridArea: 'A1',
    width: '100%',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    width: '100%',
    margin: 0,
  },
  province: {
    gridArea: 'PR',
    width: '100%',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    width: '100%',
    margin: 0,
  },
  postalCode: {
    gridArea: 'PO',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
});
