import gql from 'graphql-tag';

const PID_REVIEW_SEARCH_QUERY = gql`
  query getPidReviews($filters: [Filter], $anchor: Int) {
    getPidReviews(filters: $filters, anchor: $anchor) {
      totals {
        reviewable
        pending
        held
      }
      data {
        id
        status
        businessUnit
        country
        locale
        creationDate
        customizationId {
          type
          value
        }
        referenceData {
          reviewId
          orderLineRef
          orderNo
          orderDate
          billingName
          shippingName
        }
      }
    }
  }
`;

export default PID_REVIEW_SEARCH_QUERY;
