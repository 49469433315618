export const actionTypes = {
  SET_GROUPS: 'SET_GROUPS',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
};

export const actions = {
  setGroups:
    /**
     * This action sets the list of all groups
     *
     * @param {Object} groups – the groups to set in state
     */
    (groups) => ({
      type: actionTypes.SET_GROUPS,
      groups,
    }),

  setPermissions:
    /**
     * This action sets the list of all permissions
     *
     * @param {String} permissions – the permissions to set in state
     */
    (permissions) => ({
      type: actionTypes.SET_PERMISSIONS,
      permissions,
    }),
};

export default actions;
