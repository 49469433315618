import gql from 'graphql-tag';

const CLOSE_TRANSACTION_MUTATION = gql`
  mutation closeChargeTransaction(
    $input: CloseChargeTransactionRequestBody!
    $orderNumber: String!
    $timeout: Int!
  ) {
    closeChargeTransaction(input: $input, orderNumber: $orderNumber, timeout: $timeout) {
      id
      eta
      status
      resourceType
      message
      errors {
        code
        field
        message
      }
    }
  }
`;

export default CLOSE_TRANSACTION_MUTATION;
