/** React / Utils */
import React, { useContext } from 'react';

/** Material UI */
import AddIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

/** Local */
import { AddComment } from '../../../../constants/permissions.const';
import OrderContext from '../../../../store/contexts/orderContext';
import { transformComment } from '../../../../utils/comments';
import getArrayProperty from '../../../../utils/getArrayProperty';
import BasicTableRow from '../../../shared/table/tableRow';
import HasPermission from '../../../shared/hasPermission';
import translations from './comments.i18n';
import ErrorBoundary from '../../../error/errorBoundary';
import { DialogTypes } from '../../../../constants/dialog.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';

const Comments = () => {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, dialogDispatch] = useContext(DialogContext);
  const orderNotes = getArrayProperty(orderDetail, 'orderNotes');
  const orderLines = getArrayProperty(orderDetail, 'orderLines');

  const {
    ADD_COMMENT,
    COMMENTS,
    CONTACT_TYPE,
    DATE,
    DETAILS,
    PRIME_LINE,
    REASON,
    REFERENCE,
    USER,
    ARIA_COMMENTS,
    NO_COMMENTS,
  } = useMemoTranslations(translations);

  const handleOpenAddCommentDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.ADD_COMMENT));
  };

  /**
   * Collecting the order level notes
   * Concatenating them to the orderLine level notes
   * Adding a line number value (empty for order level notes)
   * Sorting final array by the contact date
   */
  const comments = orderNotes
    .map((note) => Object.assign(note, { line: '' }))
    .concat(
      ...orderLines
        .filter((line) => {
          // remove lines without line notes to avoid errors
          return Boolean(line.lineNotes);
        })
        .map((line) => {
          return line.lineNotes.map((note) => Object.assign(note, { line: line.lineNumber }));
        })
    )
    .sort((a, b) => Date.parse(b.contactDate) - Date.parse(a.contactDate));

  const commentsHeaders = [DATE, PRIME_LINE, USER, REASON, CONTACT_TYPE, REFERENCE, DETAILS];

  return (
    <ErrorBoundary>
      <Card className={classes.cardSpacing} elevation={3}>
        <CardContent className={classes.cardContent}>
          <Toolbar variant={'dense'} className={classes.toolbar} disableGutters={true}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              data-testid='comments-title'
              title={<h1 className={classes.cardHeading}>{COMMENTS}</h1>}
            />
            <HasPermission permission={AddComment} region={orderDetail.omsRegionReference}>
              <Button
                color='default'
                className={`${classes.button} ${classes.buttonBotItem}`}
                onClick={handleOpenAddCommentDialog}
                disabled={orderDetail.pendingModification}
                data-testid={'add-comment-button'}>
                <AddIcon className={classes.buttonIcon} />
                {ADD_COMMENT}
              </Button>
            </HasPermission>
          </Toolbar>
          <Table aria-label={ARIA_COMMENTS}>
            <TableBody data-testid='comments-table'>
              {comments.length > 0 ? (
                <BasicTableRow header data={commentsHeaders} cellClassName={classes.tableHeader} />
              ) : (
                <BasicTableRow
                  data={[
                    <Typography
                      variant='subtitle1'
                      id='noComments'
                      className={classes.noComments}
                      data-testid={'no-comments-message'}>
                      {NO_COMMENTS}
                    </Typography>,
                  ]}
                  cellClassName={classes.tableHeader}
                />
              )}
              {comments.map((comment, i) => (
                <BasicTableRow
                  key={i}
                  data={transformComment(comment)}
                  cellRootClassName={classes.tableCell}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: '9px',
  },
  cardHeading: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
  noComments: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default Comments;
