const translations = {
  ARIA_RELATED_ORDER: {
    stringKey: 'ariaRelatedOrder',
    primaryValue: 'related order',
    description: 'aria label for related order',
  },
  ARIA_ORDER_DATE: {
    stringKey: 'ariaOrderDate',
    primaryValue: 'order submitted date',
    description: 'aria label for order submitted date',
  },
};

export default translations;
