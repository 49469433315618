/** React Util */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { prepareStyleColor } from '../../../../utils/product';
import InventoryProgressBar from './inventoryProgressBar';

const StyleColorCard = ({
  originalLineNumber,
  product,
  size,
  selectedSkuData,
  handleSelectSku,
}) => {
  const classes = useStyles();
  let isSelected =
    prepareStyleColor(selectedSkuData.styleName, selectedSkuData.colorCode) ===
    prepareStyleColor(product.styleName, product.colorCode);
  let className = isSelected ? 'selected' : null;

  const productSelectedSize = product.sizeSpecifications.find(
    (sizeSpec) => sizeSpec.localizedSize === size?.localizedSize
  );

  return (
    <Grid item className={classes.productGridItem} xs={3}>
      <Card elevation={0}>
        <CardActionArea
          data-testid={`style-color-${originalLineNumber}-${product.colorCode}`}
          className={clsx(classes.button, className)}
          aria-label={product.colorCode}
          onClick={() => handleSelectSku(originalLineNumber, product, productSelectedSize)}>
          <CardMedia
            component='img'
            image={product.productImageUrl}
            alt={
              product.title ||
              product.colorDescription ||
              `${product.styleName}-${product.colorCode}`
            }
            width='100%'
          />
        </CardActionArea>
        <CardContent
          data-testid={`inventory-bar-${originalLineNumber}-${product.colorCode}`}
          style={{
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <InventoryProgressBar
            inventoryLevel={
              product.sizeSpecifications.find(
                (sizeSpec) => sizeSpec.localizedSize === size?.localizedSize
              )?.level
            }
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

StyleColorCard.propTypes = {
  originalLineNumber: PropTypes.number,
  product: PropTypes.shape({
    styleName: PropTypes.string,
    colorCode: PropTypes.string,
    colorDescription: PropTypes.string,
    sizeSpecifications: PropTypes.array,
    productImageUrl: PropTypes.string,
    title: PropTypes.string,
  }),
  size: PropTypes.shape({
    localizedSize: PropTypes.string,
  }),
  selectedSkuData: PropTypes.shape({
    styleName: PropTypes.string,
    colorCode: PropTypes.string,
  }),
  handleSelectSku: PropTypes.func,
};

export default StyleColorCard;

const useStyles = makeStyles((theme) => ({
  button: {
    'padding': '2px',
    'margin': '0',
    'borderRadius': '.5rem',
    'border': `1px solid ${theme.palette.background.paper}`,
    '&.selected': { borderColor: theme.palette.grey[500] },
  },
  productGridItem: {
    borderRadius: '.5rem',
    width: '70px',
    height: '90px',
  },
}));
