/* React/Utils */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { useMutation } from 'react-apollo';

/* Material-UI */
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

/* Local */
import translations from './pidReview.i18n';
import useMemoTranslations from '../../hooks/useMemoTranslations';
import PID_VALIDATION from '../../queries/pidValidation.query';
import PID_TEXT_MUTATION from '../../mutations/pidText.mutation';
import useSnacks from '../../hooks/useSnacks';
import PID_INLINE_VALIDATION_QUERY from '../../queries/pidInlineValidation.query';
import { defaultInlineValid, getRule } from '../../utils/pidReview';

const Validity = {
  Valid: 'Valid',
  Invalid: 'Invalid',
  Unknown: 'Unknown',
};

/**
 * Updates personalization data properties for a given field id
 *
 * @param {object} personalizations - personalizations state data
 * @param {string} id - field id
 * @param {object} updatedData - an object with new {field: value} pairs
 * @returns {object} new personalizations state data
 */
const getUpdatedPersonalization = (personalizations, id, updatedData = {}) => {
  const updatedIndex = personalizations.findIndex((field) => field.id === id);
  if (updatedIndex === -1) return personalizations;
  return [
    ...personalizations.slice(0, updatedIndex),
    { ...personalizations[updatedIndex], ...updatedData },
    ...personalizations.slice(updatedIndex + 1),
  ];
};

/**
 * Returns initial `personalizations` state
 *
 * @param {PidTextInfo[]} pidTextInfo - an array of personalization fields
 */
const getInitialPersonalizations = (pidTextInfo) =>
  pidTextInfo.map((field) => ({
    ...field,
    newText: field.text,
    validity: Validity.Unknown,
  }));

export const UpdatePid = ({ open, setOpen, pidTextInfo = [], onSave, metricId }) => {
  const classes = useStyles();
  const {
    UPDATE_PID,
    VALIDATE,
    CANCEL,
    SAVE,
    VALIDATION,
    VALIDATION_FAILED,
    LOCATION,
    CURRENT_VALUE,
    NEW_VALUE,
    PID_UPDATE_SUCCESS,
    PID_UPDATE_ERROR,
    INVALID_CHARACTERS,
    INLINE_VALIDATION_ERROR,
  } = useMemoTranslations(translations);

  const { setError, setSnack } = useSnacks();

  const [patchPidText, { loading: patchPidTextLoading }] = useMutation(PID_TEXT_MUTATION, {
    onError: (error) => {
      setError(`${PID_UPDATE_ERROR} ${error.message}`);
    },
    onCompleted: () => {
      setSnack(PID_UPDATE_SUCCESS);
      setOpen(false);
      onSave();
    },
  });
  const [overrideInvalid, setOverrideInvalid] = useState(false);

  const [personalizations, setPersonalizations] = useState(() =>
    getInitialPersonalizations(pidTextInfo)
  );

  const [isInlineValid, setIsInlineValid] = useState({});

  const [inlineValidationRules, setInlineValidationRules] = useState([]);

  // for disabling pid text fields if error fetching rules
  const [pidTextDisabled, setPidTextDisabled] = useState(false);

  // gets the matching rule from state and validates text box value
  const validatePidInline = (referenceId, value) => {
    // allow empty fields to pass validation
    if (value.length === 0) {
      return true;
    } else {
      let rule = getRule(referenceId, inlineValidationRules);
      rule = `^${rule}$`;
      const re = new RegExp(rule);
      return re.test(String(value));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      // when the component unmounts, set personalizations to default
      if (!open) {
        setPersonalizations(getInitialPersonalizations(pidTextInfo));
        // also query for rules and set inline validation states to default
        setIsInlineValid(defaultInlineValid(pidTextInfo));
        setPidTextDisabled(false);
        getInlineValidationRules({
          variables: {
            customizedProductReference: metricId,
          },
        });
      }
    };
  }, [open]);

  const handleInputChange = useCallback(({ target: { name: referenceId, value, id } }) => {
    // get rule for label to set max characters athlete can enter
    const rule = getRule(referenceId, inlineValidationRules);
    const maxChars = parseInt(rule?.charAt(rule?.length - 2));

    // hard coded 10 as max for now for missing rules in test
    if (value.length <= (maxChars || 10)) {
      setPersonalizations((personalizations) =>
        getUpdatedPersonalization(personalizations, id, {
          newText: value,
          validity: Validity.Unknown,
        })
      );
      if (validatePidInline(referenceId, value)) {
        setIsInlineValid({
          ...isInlineValid,
          [id]: true,
        });
      } else {
        setIsInlineValid({
          ...isInlineValid,
          [id]: false,
        });
      }
    }
  });

  const [validate, { loading, error }] = useLazyQuery(PID_VALIDATION, {
    onCompleted: ({ pidValidation }) => {
      setPersonalizations((personalizations) => {
        const validationMap = pidValidation.reduce(
          (acc, { termValue, isAllowed }) => ({ ...acc, [termValue]: isAllowed }),
          {}
        );
        return personalizations.map((field) => ({
          ...field,
          validity: validationMap[field.newText] ? Validity.Valid : Validity.Invalid,
        }));
      });
    },
  });

  const [
    getInlineValidationRules,
    { data: inlineData, loading: inlineLoading, error: inlineError },
  ] = useLazyQuery(PID_INLINE_VALIDATION_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      setPidTextDisabled(true);
      setError(`${INLINE_VALIDATION_ERROR} ${inlineError.message}`);
    },
    onCompleted: () => {
      const { consumerDesigns } = inlineData;
      setInlineValidationRules(consumerDesigns?.objects[0]?.validationRules);
    },
  });

  const handleValidateClick = () =>
    validate({
      variables: {
        text: personalizations.map(({ newText }) => newText),
      },
    });

  // returns true if the save button should be disabled
  const pidSaveDisabled = () => {
    // return false if override is true
    if (overrideInvalid) {
      return false;
    }

    // check if any of the personalizations are invalid
    const newTextIsValid = personalizations.some(({ validity }) => validity !== Validity.Valid);
    // returns false if all personalizations are valid
    return newTextIsValid;
  };

  const overrideDisabled = () => {
    const textInvalid = personalizations.some(({ validity }) => validity === Validity.Invalid);
    return !textInvalid;
  };

  const toggleOverride = () => {
    setOverrideInvalid(!overrideInvalid);
  };
  const handleSave = React.useCallback(() => {
    patchPidText({
      variables: {
        pidTexts: personalizations.map(({ id, newText }) => ({ id, text: newText })),
      },
    });
  });

  const saveBtnContent = patchPidTextLoading ? <CircularProgress size={10} color='white' /> : SAVE;

  return (
    <Dialog onClose={handleClose} open={open}>
      <Card raised={true} className={classes.pidUpdate} data-testid='pidUpdate-Card'>
        <form>
          <CardContent>
            <Typography className={classes.title} color='textSecondary' gutterBottom>
              {UPDATE_PID}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>{VALIDATION}</TableCell>
                  <TableCell className={classes.tableHeader}>{LOCATION}</TableCell>
                  <TableCell className={classes.tableHeader}>{CURRENT_VALUE}</TableCell>
                  <TableCell className={classes.tableHeader}>{NEW_VALUE}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody noValidate autoComplete='off'>
                {personalizations.map(({ label, text, id, newText, validity, referenceId }, i) => (
                  <TableRow key={id} data-testid={`pid-validation-row-${i}`}>
                    <TableCell className={classes.validation}>
                      <ValidationIcon loading={loading} validity={validity} />
                    </TableCell>
                    <TableCell className='PiD location'>{label}</TableCell>
                    <TableCell className='currentValue'>{text}</TableCell>
                    <TableCell>
                      <TextField
                        id={id}
                        placeholder={text}
                        name={referenceId}
                        value={newText}
                        onChange={handleInputChange}
                        error={!isInlineValid[id] ? true : false}
                        helperText={!isInlineValid[id] ? INVALID_CHARACTERS : null}
                        // disabled if inline validation rules are loading or if error fetching
                        disabled={inlineLoading || pidTextDisabled ? true : false}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {error && (
              <Alert severity='error' className={classes.alert} data-testid='pid-validation-error'>
                {VALIDATION_FAILED}
              </Alert>
            )}
            <FormControlLabel
              label='Override'
              control={<Checkbox color='primary' checked={overrideInvalid} />}
              disabled={overrideDisabled()}
              onChange={toggleOverride}
              data-testid={'pid-override-checkbox'}
            />
            <Box className={classes.tableFooter}>
              <Box className={classes.ctaButtons}>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.actionButton}
                  data-testid={'pid-validate-button'}
                  onClick={handleValidateClick}
                  // disabled if any inline validation fails
                  disabled={Object.values(isInlineValid).includes(false) ? true : false}>
                  {VALIDATE}
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.actionButton}
                  onClick={handleClose}
                  data-testid={'pid-cancel-button'}>
                  {CANCEL}
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  disabled={pidSaveDisabled()}
                  className={classes.actionButton}
                  onClick={handleSave}
                  data-testid={'pid-save-button'}>
                  {saveBtnContent}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </form>
      </Card>
    </Dialog>
  );
};

UpdatePid.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  pidTextInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  onSave: PropTypes.func,
  metricId: PropTypes.string.isRequired,
};

const ValidationIcon = ({ loading, validity }) => {
  const classes = useStyles();

  if (loading) {
    return <CircularProgress size={24} />;
  }

  switch (validity) {
    case Validity.Invalid:
      return (
        <ErrorIcon
          className={classes.error}
          color='secondary'
          aria-hidden='false'
          aria-invalid='true'
          data-testid='validity-icon-invalid'
        />
      );
    case Validity.Valid:
      return (
        <CheckCircleIcon
          className={classes.success}
          color='error'
          aria-hidden='false'
          aria-invalid='false'
          data-testid='validity-icon-valid'
        />
      );
    case Validity.Unknown:
    default:
      return <HelpOutlineIcon color='action' data-testid='validity-icon-unknown' />;
  }
};

ValidationIcon.propTypes = {
  loading: PropTypes.bool.isRequired,
  validity: PropTypes.oneOf([Validity.Invalid, Validity.Valid, Validity.Unknown]).isRequired,
};

/**
 * CSS in JS for the screen elements.
 */

const useStyles = makeStyles((theme) => ({
  success: { color: theme.palette.success.main },
  cta: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  tableHeader: {
    align: 'left',
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '1rem',
    flexWrap: 'wrap',
  },
  validation: {
    padding: 0,
    textAlign: 'center',
    verticalAlign: 'center',
  },
  alert: {
    margin: '10px 0',
  },

  ctaButtons: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  actionButton: {
    marginRight: '0.5rem',
    whiteSpace: 'nowrap',
  },
  pidUpdate: {
    width: '100%',
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));
