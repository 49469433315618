import gql from 'graphql-tag';

const ADD_BANK_MUTATION = gql`
  mutation addBankPaymentMethod($input: AddBankInput!) {
    addBank(input: $input) {
      id
      resourceType
      status
      links {
        self {
          ref
        }
      }
      error {
        httpStatus
        message
      }
      response {
        paymentId
      }
    }
  }
`;

export default ADD_BANK_MUTATION;
