/**
 * This utility safely fetches a copy of an array property from an object.
 *
 * @param {Object} object – the object from which we're attempting to extract an array property
 * @param {String} property - the name of the array property we want
 * @returns {Array} – either a copy of the property we sought, if it exists, and is an array,
 * or an empty array
 */
export const getArrayProperty = (object, property) => {
  /*
  check the boolean value of the object, which ensures that we can safely
  access properties therein
  */
  const value = Boolean(object) && object[property];
  // always return an array, so we can safely use array functions on the result
  return Array.isArray(value) ? value.slice() : [];
};

export default getArrayProperty;
