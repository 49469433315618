import gql from 'graphql-tag';

const ASSET_SEARCH_QUERY = gql`
  query assetSearchV2(
    $anchor: Int
    $count: Int
    $facetFields: String
    $facetQuery: String
    $queryFields: String
    $searchTerms: String
    $sort: String
    $where: String
  ) {
    assetSearchV2(
      anchor: $anchor
      count: $count
      facetFields: $facetFields
      facetQuery: $facetQuery
      queryFields: $queryFields
      searchTerms: $searchTerms
      sort: $sort
      where: $where
    ) {
      pages {
        next
        prev
        totalPages
        totalResources
      }
      objects {
        results {
          aspectRatio
          assetId
          assetType
          catalogId
          color
          countryExclusions {
            items
          }
          createdBy
          creationDate
          defaultURL
          designId
          etag
          fileSize
          format
          height
          id
          imageSourceURL
          links {
            self {
              ref
            }
          }
          modificationDate
          posterImageTime
          posterImageURL
          public
          resourceType
          style
          styleColor
          subType
          tagCount
          tags {
            items
          }
          type
          view
          width
        }
      }
    }
  }
`;

export default ASSET_SEARCH_QUERY;
