import gql from 'graphql-tag';

const OMA_NOTIFICATION_SUMMARY_QUERY = gql`
  query getOrderNotificationsOMA($orderNumber: String!, $orderCreated: String!, $channel: Channel) {
    orderNotificationSummaryOMA(
      orderNumber: $orderNumber
      orderCreated: $orderCreated
      channel: $channel
    ) {
      results {
        send_id
        creation_date
        locale_language
        notification_type
        notification_class
        user_id
        user_email_address
        user_phone_number
        communication_payload
        communication_body
        communication_header
        order_id
        is_resend
        has_attachments
      }
    }
  }
`;

export default OMA_NOTIFICATION_SUMMARY_QUERY;
