import sharedTranslations from '../createReturn/shared.i18n';

const translations = {
  NEXT: sharedTranslations.NEXT,
  BACK: sharedTranslations.BACK,
  EXCHANGE_CREDIT_VALUE_ERROR_MESSAGE: {
    stringKey: 'exchangeCreditValueErrorMessage',
    primaryValue:
      'The total amount of the exchange order, exceeds the exchange credit amount. Please adjust pricing above.',
    description:
      'Message displayed when the Exchange Credit Value is less than the total price of the new exchange order',
  },
  EXCHANGE_SUMMARY_LOADING: {
    stringKey: 'exchangeSummaryLoading',
    primaryValue: 'exchange summary loading',
    description: 'aria label for loading throbber when exchange summary data is loading',
  },
  EXCHANGE_CREDIT_VALUE_LOADING: {
    stringKey: 'exchangeCreditValueLoading',
    primaryValue: 'exchange credit value loading',
    description: 'aria label for loading throbber when exchange credit value is loading',
  },
  EXCHANGE_ITEM_OOS_MESSAGE: {
    stringKey: 'exchangeItemOutOfStock',
    primaryValue:
      'This item is no longer in stock. Please try choosing a different item in the previous step',
    description: 'Message displayed when an item in a proposed exchange order item is out of stock',
  },
  EXCHANGE_VALUE_DIFF_MESSAGE2: {
    stringKey: 'exchangeValueDiffMessage2',
    primaryValue:
      'will be transferred to the exchange order after the return is received by the warehouse.',
    description: 'Part 2 of message displayed under Exchange Credit Value if the prices match.',
  },
  EXCHANGE_VALUE_DIFF_MESSAGE1: {
    stringKey: 'exchangeValueDiffMessage1',
    primaryValue: 'from your return order will be refunded back to your original payment method.',
    description: 'Part 1 of message displayed under Exchange Credit Value if the prices match.',
  },
  EXCHANGE_VALUE_MATCH_MESSAGE: {
    stringKey: 'exchangeValueMatchMessage',
    primaryValue:
      'will be transferred to the exchange order after the return is received by the warehouse.',
    description: 'Message displayed under Exchange Credit Value if the prices match.',
  },
  EXCHANGE_RETURN_ALL_MESSAGE: {
    stringKey: 'exchangeReturnAllMessage',
    primaryValue:
      "Make sure to return all the items you're exchanging within 21 days. Once we get everything back, we'll process the exchange and ship out the new gear. If it's no longer in stock, we'll give you a refund.",
    description: 'Message to display under Exchange Credit Value',
  },
  ITEM_NOT_EXCHANGEABLE: {
    stringKey: 'itemNotExchangeable',
    primaryValue: 'Item is not exchangeable',
    description: 'Tool tip message to display when an item is not exchangeable.',
  },
  NO_PRODUCTS_AVAILABLE: {
    stringKey: 'noProductsAvailable',
    primaryValue: 'There are no products available for exchange.',
    description: 'message to show when there are no exchange options for an item',
  },
  ITEMS_TOTAL: {
    stringKey: 'itemsTotal',
    primaryValue: 'Items Total',
    description: 'a string to follow behind a number (e.g. 4 "Items Total")',
  },
  INVALID_DISCOUNT_AMOUNT: {
    stringKey: 'invalidDiscountAmount',
    description:
      "A form validation error message to display when the user attempts to discount an exchange item by more than original item's price.",
    primaryValue: 'The discount may not exceed the price of the original item.',
  },
  INVALID_DISCOUNT_NUMBER: {
    stringKey: 'invalidDiscountNumber',
    description:
      'A form validation error message to display when the user enters a non-numeric discount value.',
    primaryValue: 'The discount must be a number.',
  },
  INVALID_DISCOUNT_ZERO: {
    stringKey: 'invalidDiscountZero',
    description:
      'A form validation error message to display when the user shifts focus away from the discount value after selecting the field',
    primaryValue: 'Please enter a value for the discount.',
  },
  INVALID_DISCOUNT_PERCENTAGE: {
    stringKey: 'invalidDiscountPercentage',
    description:
      'A form validation error message to display when the user attempts to discount an exchange by more than 100%',
    primaryValue: 'The discount may not exceed 100%.',
  },
  EXCHANGE_ORDER_CREATED: {
    stringKey: 'exchangeOrderCreated',
    primaryValue: 'Exchange Order Created',
    description:
      'a success message to appear as an alert after an exchange order is successfully created',
  },
  EXCHANGE_ORDER_NOT_AVAILABLE: {
    stringKey: 'exchangeOrderNotAvailable',
    primaryValue:
      'Exchange Order creation successful; Exchange order details not available yet. Please refresh the page and check the linked orders for the exchange order.',
    description:
      'An error message displayed when an exchange order has been created successfully, but the order details data is not yet available.',
  },
  SIZE: {
    description: 'label on the size',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  SELECT_ITEMS_TO_EXCHANGE: {
    description: 'label on the Exchange Items Dialog for "Select Items to Exchange"',
    primaryValue: 'Select Items to Exchange',
    stringKey: 'selectItemsToExchange',
  },
  SEARCH_STYLE_NUMBER: {
    stringKey: 'styleNumber',
    primaryValue: 'Style Number',
    description: 'label for advanced search field: Style Number',
  },
  SELECT_ALL: {
    stringKey: 'selectAll',
    primaryValue: 'Select All',
    description: 'aria label for checkbox whose purpose is to select all items in the list',
  },
  PRODUCT_NAME: {
    stringKey: 'productName',
    primaryValue: 'Product Name',
    description: 'product name',
  },
  QUANTITY: {
    description: 'label on Details page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  COLOR: {
    description: 'label on Details page for "color"',
    primaryValue: 'Color',
    stringKey: 'color',
  },
  PRICE: {
    stringKey: 'price',
    primaryValue: 'Price',
    description: "Product info label for an item's price.",
  },
  INELIGIBLE_ITEMS: {
    description: 'label for items that are not eligible for selection',
    primaryValue: 'Ineligible Items',
    stringKey: 'ineligibleItems',
  },
  ELIGIBLE: {
    description: 'label for items that are eligible for selection',
    primaryValue: 'Eligible',
    stringKey: 'eligible',
  },
  CHOOSE_NEW_ITEMS: {
    description: 'label on the second step of exchanges dialog',
    primaryValue: 'Choose New Items',
    stringKey: 'chooseNewItems',
  },
  ORIGINAL: {
    description: 'label for original product details section in exchanges dialog',
    primaryValue: 'Original',
    stringKey: 'original',
  },
  NEW: {
    description: 'label for new product details section in exchanges dialog',
    primaryValue: 'New',
    stringKey: 'new',
  },
  SELECT_COLOR: {
    description: 'label for colorway selection section in exchanges dialog',
    primaryValue: 'Select Color',
    stringKey: 'selectColor',
  },
  SELECT_SIZE: {
    description: 'label for size selection section in exchanges dialog',
    primaryValue: 'Select Size',
    stringKey: 'selectSize',
  },
  STYLE: {
    description: 'label for product style-color',
    primaryValue: 'Style',
    stringKey: 'style',
  },
  EXCHANGE_SUBMIT_ERROR: {
    description: 'label for exchange submit error message',
    primaryValue: 'Unable to create Exchange Order, please try again.',
    stringKey: 'exchangeSubmitError',
  },
  EXCHANGE_OPTIONS_ERROR: {
    description: 'warning when there was an error getting exchange options',
    primaryValue: 'Error getting options for exchange: ',
    stringKey: 'exchangeOptionsError',
  },
  VIEW_CART: {
    stringKey: 'viewCart',
    primaryValue: 'View Cart',
    description: 'Text for View Cart',
  },
  RETURN_ORDER: {
    description: 'label on Details page for return orders',
    primaryValue: 'Return Order',
    stringKey: 'returnOrder',
  },
  DISCOUNT: {
    description: 'Label for the section that displays the discount options',
    primaryValue: 'Discount',
    stringKey: 'discount',
  },
  EXCHANGE_PRICING_SUMMARY: {
    description: 'Label for the section that will display pricing summary',
    primaryValue: 'Exchange Pricing Summary',
    stringKey: 'exchangePricingSummary',
  },
  VIEW_PRODUCT_DETAILS: {
    description: 'Link that will lead to a page displaying product details',
    primaryValue: 'View Product Details',
    stringKey: 'viewProductDetails',
  },
  MSRP: {
    description: 'Label for the msrp of an item',
    primaryValue: 'MSRP',
    stringKey: 'msrp',
  },
  SHIPPING_INFORMATION: {
    description: 'label for physical delivery info',
    primaryValue: 'Shipping Information',
    stringKey: 'physicalDeliveryLabel',
  },
  SHIPPING_METHOD: {
    description: 'label on Details page for the shipping method',
    primaryValue: 'Shipping Method',
    stringKey: 'shippingMethod',
  },
  SUBMIT_EXCHANGE: {
    description: 'label on submit exchange button',
    primaryValue: 'Submit Exchange',
    stringKey: 'submitExchange',
  },
  ENTER_ITEM_DISCOUNT: {
    description: 'label for form that applies a discount to an corresponding item',
    primaryValue: 'Enter Item Discount',
    stringKey: 'enterItemDiscount',
  },
  ENTER_PERCENTAGE: {
    description: 'placeholder for discount value when discount is percentage off',
    primaryValue: 'Enter percentage',
    stringKey: 'enterPercentage',
  },
  ENTER_AMOUNT: {
    description: 'placeholder for discount value when discount is amount off',
    primaryValue: 'Enter amount',
    stringKey: 'enterAmount',
  },
  PERCENT_OFF: {
    description: 'label on the discount option percent off',
    primaryValue: 'Percent Off',
    stringKey: 'percentOff',
  },
  AMOUNT_OFF: {
    description: 'label on the discount option amount off',
    primaryValue: 'Amount off',
    stringKey: 'amountOff',
  },
  EXACT_PRICE: {
    description: 'label on the discount option exact price',
    primaryValue: 'Exact Price',
    stringKey: 'exactPrice',
  },
  PRICE_VALIDATION_ERROR: {
    description: 'error message for invalid price',
    primaryValue: 'Not a valid discount amount',
    stringKey: 'priceValidationError',
  },
  ARIA_DISCOUNT_PRICE: {
    description: 'aria label for discount price text unput box',
    primaryValue: 'discount price',
    stringKey: 'discountPrice',
  },
  REASON_CODES_LOADING: {
    description: 'aria label for reason code loading spinner',
    primaryValue: 'reason codes loading',
    stringKey: 'ariaReasonCodes',
  },
  SELECT_REASON: {
    description: 'label on dropdown to select reason',
    primaryValue: 'Select Reason',
    stringKey: 'selectReason',
  },
  TAX: {
    description: 'label on Details page for "tax"',
    primaryValue: 'Tax',
    stringKey: 'tax',
  },
  DISCOUNTS: {
    description: 'label for discounts total in totals panel',
    primaryValue: 'Discounts',
    stringKey: 'discounts',
  },
  EXCHANGE_CREDIT_VALUE: {
    description: 'label for total value to cover cost of exchange items',
    primaryValue: 'Exchange Credit Value',
    stringKey: 'exchangeCreditValue',
  },
  TOTAL_PRICE: {
    description: 'label for price total in totals panel',
    primaryValue: 'Total Price',
    stringKey: 'totalPrice',
  },
  ESTIMATED_SHIPPING: {
    description: 'label for estimated shipping in totals panel',
    primaryValue: 'Estimated Shipping',
    stringKey: 'estimatedShipping',
  },
  PRODUCT_TOTAL: {
    description: 'label for product total in totals panel',
    primaryValue: 'Product Total',
    stringKey: 'productTotal',
  },
  FINAL_ITEM_PRICE: {
    description: 'label for final item price in totals panel',
    primaryValue: 'Final Item Price',
    stringKey: 'finalItemPrice',
  },
  EXCHANGE_PREVIEW_ERROR: {
    description: 'error message when exchange preview call has failed',
    primaryValue: 'Exchange Preview Error',
    stringKey: 'exchangePreviewError',
  },
  OUT_OF_STOCK_MESSAGE: {
    description: 'message for athlete when selected colorway is out of stock',
    primaryValue: 'This item is out of stock.',
    stringKey: 'outOfStockMessage',
  },
  LOW_INVENTORY_MESSAGE: {
    description: 'message for athlete when selected size and color is low inventory',
    primaryValue: 'Inventory is low for selected size and color.',
    stringKey: 'lowInventoryMessage',
  },
  DISCOUNT_REASON: {
    description: 'label for item discount on exchanges review page',
    primaryValue: 'Discount Reason',
    stringKey: 'discountReason',
  },
  NO_DISCOUNT_NEEDED: {
    description: 'label for discount reason in exchanges flow when there is no discount for item',
    primaryValue: 'No discount needed',
    stringKey: 'noDiscountNeeded',
  },
  ITEM_DISCOUNT: {
    description: 'label on Details page for "item discount"',
    primaryValue: 'Item Discount',
    stringKey: 'itemDiscount',
  },
  MISSING_DISCOUNT_REASON_WARNING: {
    description: 'warning when an athlete has not selected a discount reason',
    primaryValue: 'Please select a reason for this discount',
    stringKey: 'missingDiscountReasonWarning',
  },
  EXCHANGE_ITEM_IS_MORE_EXPENSIVE: {
    description: "warning when an exchange item's price is greater than the original item's price",
    primaryValue: "Exchange item's price is greater than the original item's price",
    stringKey: 'exchangeItemIsMoreExpensive',
  },
};

export default translations;
