import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import translations from './../payments.i18n';
import useSnacks from '../../../../../hooks/useSnacks';
import { GenerateOrderNotes } from '../../../../../hooks/usePaymentMods';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import ADD_GC_PAYMENT_MUTATION from '../../../../../mutations/addGiftCardPaymentMethod.mutation';
import QUERY_GC_BALANCE_MUTATION from '../../../../../mutations/queryGcBalance.mutation';
import { OrderContext } from '../../../../../store/contexts/orderContext';
import { ApiTimeOut } from '../../../../../constants/dialog.const';
import { FormattedCurrency } from '../../../../shared/formatCurrency';
import Loading from '../../../../shared/loading';
import useHasPermission from '../../../../../hooks/useHasPermission';
import { CheckGCBalanceWithoutPin } from '../../../../../constants/permissions.const';

/**
 * Form for adding a gift card to an existing order as a form of payment.
 * @param {Object}: {
 *   @param {boolean} triggerSubmission - boolean indicating if the submit button has been clicked
 *   @param {function} setTriggerSubmission - function that sets triggerSubmission in modal state
 *   @param {function} shouldSubmitBeDisabled - function that sets submitDisabled in modal state
 *   @param {function} updatePaymentMethods - function that refetches payment details
 *   @param {function} setDialogOpen - function that sets isDialogOpen in state
 * }
 * @returns form component which allows a user to add a giftcard to an existing order as a form
 * of payment.
 */
export default function AddGCPaymentForm({
  triggerSubmission,
  setTriggerSubmission,
  shouldSubmitBeDisabled,
  updatePaymentMethods,
  setDialogOpen,
}) {
  const classes = useStyles();
  const {
    BALANCE,
    CHECK_BALANCE,
    FAILED_GIFT_CARD_ADD,
    FAILED_GIFT_CARD_BALANCE_QUERY,
    GIFT_CARD_NUMBER,
    INVALID_GIFTCARD_NUMBER,
    INVALID_PIN_NUMBER,
    PIN_NUMBER,
    SUCCESSFUL_GIFT_CARD_ADD,
  } = useMemoTranslations(translations);
  const { hasPermission } = useHasPermission();
  const [orderDetail] = useContext(OrderContext);
  const { currency, orderNumber, omsRegionReference } = orderDetail;

  const [balanceChecked, setBalanceChecked] = useState(false);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);
  const [isPinValid, setIsPinValid] = useState(
    hasPermission(CheckGCBalanceWithoutPin, omsRegionReference)
  );

  const [accountNumber, setAccountNumber] = useState('');
  const [pin, setPin] = useState('');
  const [balance, setBalance] = useState('');
  const [showFieldValidation, setShowFieldValidation] = useState({
    accountNumber: false,
    pin: false,
  });
  const { setLoading, setError, setSnack } = useSnacks();
  const giftCardFormSection = useRef(null);
  const orderNotes = GenerateOrderNotes(
    'CSR Payment Add',
    `Added Gift Card ${accountNumber.slice(-4)}`
  );
  const allowedGiftCardLength = [16, 19, 21];

  useEffect(() => {
    if (balanceChecked && balance) {
      shouldSubmitBeDisabled(false);
    } else {
      shouldSubmitBeDisabled(true);
    }
  }, [balanceChecked, balance]);

  const [getGiftCardBalance, { loading: checkBalanceLoading }] = useMutation(
    QUERY_GC_BALANCE_MUTATION,
    {
      onError: (error) => {
        setError(FAILED_GIFT_CARD_BALANCE_QUERY);
        setBalance('');
        setBalanceChecked(false);
      },
      onCompleted: (response) => {
        const queryGiftCardBalance = response?.queryGiftCardBalance;
        setBalance(queryGiftCardBalance.balance);
        setBalanceChecked(true);
      },
    }
  );

  const [addGiftCardPayment, { loading: addGiftCardLoading }] = useMutation(
    ADD_GC_PAYMENT_MUTATION,
    {
      onError: (error) => {
        setTriggerSubmission(false);
        setError(`${FAILED_GIFT_CARD_ADD}: ${error.message}`);
      },
      onCompleted: (response) => {
        const error = response?.addGiftCardPaymentMethod?.error;
        if (error) {
          setTriggerSubmission(false);
          setError(`${FAILED_GIFT_CARD_ADD} ${error.message}`);
          return;
        }
        setDialogOpen(false);
        setSnack(SUCCESSFUL_GIFT_CARD_ADD);
        updatePaymentMethods();
      },
    }
  );

  // Submit button for checking the gift card balance
  const handleCheckGCBalance = () => {
    getGiftCardBalance({
      variables: {
        input: {
          accountNumber,
          pin,
          currency,
        },
      },
    });
  };

  // Submit button for adding the gift card to the order.
  const handleAddGCPaymentType = () => {
    addGiftCardPayment({
      variables: {
        input: {
          orderInfo: {
            id: orderNumber,
          },
          giftCardInfo: {
            accountNumber,
            pin,
          },
          orderNotes,
        },
        timeout: ApiTimeOut,
      },
    });
  };

  useEffect(() => {
    if (triggerSubmission) {
      handleAddGCPaymentType();
      setLoading();
    }
  }, [triggerSubmission]);

  /**
   * Shows a component that will show if the gift card is considered to be valid
   * and useable or if it does not contain a balance and cannot be added as a form
   * of payment.
   * @returns Component that displays whether the giftcard has a balance or not.
   */
  const renderGiftCardValidity = () => {
    return (
      <Typography className={classes.validationText} data-testid='gift-card-balance-msg'>
        {balance ? (
          <CheckCircleOutlineIcon className={classes.checkIcon} />
        ) : (
          <NotInterestedIcon className={classes.invalidIcon} />
        )}
        <span className={classes.leftSpacer}>{BALANCE}: </span>
        <FormattedCurrency amount={balance} currency={currency} />
      </Typography>
    );
  };

  const handleOnChange = ({ target: { name, value } }) => {
    // Reset the flags when an input change has occurred so we can submit another check balance.
    setBalanceChecked(false);
    setBalance('');

    if (name === 'pin') {
      setPin(value);
    } else if (name === 'accountNumber') {
      setAccountNumber(value);
      if (hasPermission(CheckGCBalanceWithoutPin, omsRegionReference))
        setIsAccountNumberValid(allowedGiftCardLength.includes(value?.length));
    }
  };

  const handleOnFocus = ({ target: { name } }) => {
    setShowFieldValidation({ ...showFieldValidation, [name]: false });
  };

  const handleOnBlur = ({ target: { name } }) => {
    if (name === 'pin') setIsPinValid(pin?.length > 0 && pin?.length <= 6);
    if (name === 'accountNumber')
      setIsAccountNumberValid(allowedGiftCardLength.includes(accountNumber?.length));

    setShowFieldValidation({ ...showFieldValidation, [name]: true });
  };

  const accountNumberErrorMessage = () => {
    if (showFieldValidation.accountNumber && !isAccountNumberValid) return INVALID_GIFTCARD_NUMBER;
    return '';
  };

  const pinErrorMessage = () => {
    if (showFieldValidation.pin && !isPinValid) return INVALID_PIN_NUMBER;
    return '';
  };

  return (
    <Box ref={giftCardFormSection} className={classes.form}>
      <FormControl>
        <TextField
          name='accountNumber'
          label={GIFT_CARD_NUMBER}
          variant='outlined'
          defaultValue={''}
          value={accountNumber}
          required
          type={'number'}
          data-testid='gift-card-input'
          helperText={accountNumberErrorMessage()}
          error={accountNumberErrorMessage()}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          className={classes.textField}
        />
        {!hasPermission(CheckGCBalanceWithoutPin, omsRegionReference) && (
          <TextField
            required
            name='pin'
            label={PIN_NUMBER}
            variant='outlined'
            value={pin}
            type='number'
            data-testid='pin-number-input'
            helperText={pinErrorMessage()}
            error={pinErrorMessage()}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            className={clsx(classes.textField, classes.topSpacer)}
          />
        )}
        <Box className={classes.checkBalance}>
          <Button
            variant='outlined'
            className={classes.roundedButton}
            data-testid='check-balance-button'
            disabled={!isAccountNumberValid || !isPinValid || balanceChecked}
            onClick={() => {
              handleCheckGCBalance();
            }}>
            {CHECK_BALANCE}
          </Button>
          <Box className={classes.resultBox}>
            {checkBalanceLoading ? (
              <CircularProgress size={25} />
            ) : (
              balanceChecked && renderGiftCardValidity
            )}
          </Box>
        </Box>
      </FormControl>
      {addGiftCardLoading && <Loading />}
    </Box>
  );
}

AddGCPaymentForm.propTypes = {
  triggerSubmission: PropTypes.bool,
  setTriggerSubmission: PropTypes.func,
  shouldSubmitBeDisabled: PropTypes.func,
  updatePaymentMethods: PropTypes.func,
  setDialogOpen: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  textField: {
    // set border radius
    [`& fieldset`]: {
      borderRadius: 8,
    },
    // hide increment/decrement arrow icons
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
  },
  roundedButton: {
    borderRadius: '24px',
    borderWidth: '1.5px',
    borderColor: 'black',
    textTransform: 'unset',
    fontSize: '1rem',
    padding: `${theme.spacing(0.5)}px
      ${theme.spacing(2.5)}px
      ${theme.spacing(0.5)}px`,
  },
  checkBalance: {
    display: 'flex',
    marginTop: '1.5rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  topSpacer: {
    marginTop: '2rem',
  },
  leftSpacer: {
    marginLeft: '0.5rem',
    whiteSpace: 'pre',
  },
  resultBox: {
    display: 'flex',
    marginLeft: '0.75rem',
    alignItems: 'center',
  },
  checkIcon: {
    color: theme.palette.validation.success,
  },
  invalidIcon: {
    color: theme.palette.validation.error,
  },
  validationText: {
    fontWeight: 500,
    marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
}));
