/* React/Utils */
import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';

/* Material-UI */
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Local */
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import translations from './discountShipping.i18n';
import { step1SharedStyles } from '../../dialog/sharedStyles';
import { OrderContext } from '../../../../store/contexts/orderContext';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import {
  ReasonCodeTypes,
  ReasonCodeAppId,
  ApiTimeOut,
  ResponseStatuses,
} from '../../../../constants/dialog.const';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import { I18nContext } from '../../../../store/contexts/i18Context';
import Warning from '../../../shared/warning';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import ADD_SHIPPING_DISCOUNT_MUTATION from '../../../../mutations/shippingDiscount.mutation';
import useSnacks from '../../../../hooks/useSnacks';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { getNonDiscountHeaderArray } from './../../../../utils/order';
import { appropriateLanguage } from './../../../../utils/language';
import SHIPPING_CHARGES_QUERY from '../../../../queries/shippingCharges.query';

/**
 * Component to handle step actions and form submission based on dialog state for Discount Shipping
 */
export default function StepControl() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const [i18State] = useContext(I18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [athleteInfo] = useContext(AthleteContext);
  const { setShippingDiscountReason, setHasTimedOut, reset, setShippingGroup } = dialogActions;
  const [headerShippingCharge, setHeaderShippingCharge] = useState(0);
  const [returnedShippingCharge, setReturnedShippingCharge] = useState(0);
  const [selectedShippingCharge, setSelectedShippingCharge] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const { setSnack, setError, setSlowLoading, getLoadingStatus } = useSnacks();
  const { currency, headerCharges, pendingModification } = orderDetail;
  const {
    SUBMIT_SHIPPING_DISCOUNT,
    SHIPPING_DISCOUNT_TIME_OUT_ERROR_MESSAGE,
    SHIP_GROUP,
    FREE_SHIPPING,
    SHIPPING_DISCOUNT_REASON,
    REASON_CODES_LOADING,
    SHIPPING_DISCOUNT,
    SUCCESS,
    ERROR,
  } = mapValues(translations, i18nString);

  // returns shipping group name based on chargeCategory
  const getShippingGroupName = (headerCharge, index) => {
    const shippingGroupName =
      headerCharge?.chargeCategory === 'SHIPPING'
        ? headerCharges[index].additionalInformation
        : headerCharges[index].chargeCategory;
    return shippingGroupName;
  };

  // enable submit button when shipping group and reason is selected
  const shouldSubmitButtonBeDisabled = () => {
    if (dialogState.shippingGroup && dialogState.shippingDiscountReason && !pendingModification) {
      return false;
    }
    return true;
  };

  // set shipping discount based on selected shipping group
  useEffect(() => {
    const selectedHeaderCharge = getNonDiscountHeaderArray(headerCharges).filter((headerCharge) => {
      if (headerCharge.chargeCategory === 'SHIPPING') {
        return headerCharge?.additionalInformation === dialogState.shippingGroup?.chargeValue;
      }
      return headerCharge?.chargeCategory === dialogState.shippingGroup?.chargeValue;
    });
    setHeaderShippingCharge(selectedHeaderCharge[0]?.chargeAmount);
    setAdditionalInformation(selectedHeaderCharge[0]?.additionalInformation);
    const returnOrders = orderDetail.csOrderSummary.objects.map((order) => order.orderNumber);
    getReturnedShippingCharges({
      variables: {
        orderNumbers: returnOrders,
      },
    });
  }, [dialogState.shippingGroup]);

  useEffect(() => {
    setSelectedShippingCharge(headerShippingCharge - returnedShippingCharge);
  }, [returnedShippingCharge, headerShippingCharge]);

  // select the only available shipping group
  useEffect(() => {
    const nonDiscountHeaderCharges = getNonDiscountHeaderArray(headerCharges);
    if (nonDiscountHeaderCharges.length === 1) {
      const shippingGroupName =
        nonDiscountHeaderCharges[0]?.chargeCategory === 'SHIPPING'
          ? nonDiscountHeaderCharges[0].additionalInformation
          : nonDiscountHeaderCharges[0].chargeCategory;
      dialogDispatch(
        setShippingGroup({
          chargeValue: shippingGroupName,
        })
      );
    }
  }, []);

  // Call to get reason codes
  const { data, loading, error } = useQuery(REASON_CODES_QUERY, {
    variables: {
      appId: ReasonCodeAppId,
      omsRegionReference: orderDetail.omsRegionReference,
      type: ReasonCodeTypes.PRICE_ADJUSTMENT,
      language: appropriateLanguage(i18State.language),
    },
    notifyOnNetworkStatusChange: true,
  });

  // call to submit shipping discount
  const [submitShippingDiscount, { loading: discountLoading }] = useMutation(
    ADD_SHIPPING_DISCOUNT_MUTATION,
    {
      onError: (err) => {
        setError(`${SHIPPING_DISCOUNT} ${ERROR} ${err.message}`);
      },
      onCompleted: (response) => {
        const { addShippingDiscount } = response;
        if (
          (addShippingDiscount.status = ResponseStatuses.COMPLETED && !addShippingDiscount.error)
        ) {
          queryOrderDetails({
            variables: {
              orderNumber: orderDetail.orderNumber,
            },
          });
        } else if (
          addShippingDiscount.status === ResponseStatuses.IN_PROGRESS ||
          addShippingDiscount.status === ResponseStatuses.PENDING
        ) {
          dispatchError(SHIPPING_DISCOUNT_TIME_OUT_ERROR_MESSAGE);
          dialogDispatch(setHasTimedOut(true));
        } else if (addShippingDiscount.error) {
          const errorMessageOnJobCompletion = `${addShippingDiscount.error.httpStatus}: ${addShippingDiscount.error.message}`;
          dispatchError(errorMessageOnJobCompletion);
          dialogDispatch(reset());
        }
      },
    }
  );

  // call to get order details
  const [
    queryOrderDetails,
    { data: responseFromOrderDetailCall, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatchError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      dialogDispatch(reset());
      setSnack(`${SHIPPING_DISCOUNT} ${SUCCESS}`);
      setOrderDetail(responseFromOrderDetailCall.orderDetail);
    },
  });

  const [
    getReturnedShippingCharges,
    { data: shippingChargesDetails, loading: shippingDiscountLoading },
  ] = useLazyQuery(SHIPPING_CHARGES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setReturnedShippingCharge(
        shippingChargesDetails?.returnedShippingCharges?.returnedShippingValue
      );
    },
    onError: () => {
      dispatchError(shippingChargesDetails?.message);
    },
  });

  const dispatchError = (errorMessage) => {
    dialogDispatch(reset());
    setError(`${SHIPPING_DISCOUNT} ${ERROR} ${errorMessage}`);
  };

  // function that is called on clicking submit shipping discount
  const handleShippingDiscount = () => {
    setSlowLoading();
    submitShippingDiscount({
      variables: {
        orderNumber: orderDetail.orderNumber,
        input: buildShippingDiscountInput(),
        timeout: ApiTimeOut,
      },
    });
  };

  // builds the payload that needs to be sent to apply shipping discount
  const buildShippingDiscountInput = () => {
    return {
      requestId: uuidv4(),
      headerCharges: [
        {
          additionalInformation: additionalInformation,
          chargeName: `${dialogState?.shippingDiscountReason?.code}-${uuidv4()}`,
          chargeAmount: parseFloat(selectedShippingCharge),
        },
      ],
      headerNotes: [
        {
          contactDate: new Date().toISOString(),
          contactUser: athleteInfo.email,
          reasonCode: dialogState?.shippingDiscountReason?.code || '',
          details: dialogState?.shippingDiscountReason?.text || '',
        },
      ],
    };
  };

  const reason = dialogState.shippingDiscountReason || { code: '-1' };
  const shippingGroup = dialogState.shippingGroup || { chargeValue: '-1' };
  const isSubmissionLoading = getLoadingStatus() || discountLoading;

  return (
    <div>
      <Card className={classes.discountShipping} elevation={0}>
        <Box p={1} className={classes.shippingText}>
          <Typography variant='body1' className={classes.shipGroup}>
            {SHIP_GROUP}:
            <FormControl className={classes.shippingGroupDropDown}>
              <Select
                value={shippingGroup.chargeValue}
                data-testid={`select-shipping-group`}
                aria-label={`SHIPPING_GROUP_NAME`}
                aria-labelledby='chooseShippingGroup'
                labelId='chooseShippingGroup'
                onChange={(event) => {
                  const selectedShippingGroup = event.target.value;
                  dialogDispatch(
                    setShippingGroup({
                      chargeValue: selectedShippingGroup,
                    })
                  );
                }}>
                {getNonDiscountHeaderArray(headerCharges).length > 1 && (
                  <MenuItem
                    id='chooseShippingGroup'
                    className={classes.reasonCodeSelect}
                    value='-1'
                    disabled>
                    {`Select a shipping group`}
                  </MenuItem>
                )}
                {getNonDiscountHeaderArray(headerCharges).map((headerCharge, i) => (
                  <MenuItem
                    key={i}
                    value={getShippingGroupName(headerCharge, i)}
                    name={headerCharge?.additionalInformation}
                    data-testid={`shipping-group-name-${i}`}>
                    {getShippingGroupName(headerCharge, i)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {FREE_SHIPPING}
          </Typography>
          <Typography variant='body1' className={classes.discountAmount}>
            {`${SHIPPING_DISCOUNT}: `}
            {shippingDiscountLoading ? (
              <CircularProgress size={16} />
            ) : (
              <FormattedCurrency amount={selectedShippingCharge} currency={currency} />
            )}
          </Typography>
          {loading && (
            <CircularProgress aria-label={REASON_CODES_LOADING} className={classes.loading} />
          )}
          {error && <Warning message={error.message} />}
          {data && data.reasonCodesV2.reasons ? (
            <FormControl className={classes.reasonCodeSelect}>
              <Select
                value={reason.code}
                data-testid={`select-shipping-discount-reason`}
                aria-label={SHIPPING_DISCOUNT_REASON}
                aria-labelledby='chooseReason'
                labelId='chooseReason'
                onChange={(event) => {
                  /**
                   * save both the reason code and the reason
                   * description
                   */
                  const selectedReason = data.reasonCodesV2.reasons.find(
                    (reason) => reason.code === event.target.value
                  );
                  dialogDispatch(
                    setShippingDiscountReason({
                      id: selectedReason.id,
                      code: selectedReason.code,
                      text: selectedReason.description,
                    })
                  );
                }}>
                <MenuItem
                  id='chooseReason'
                  className={classes.reasonCodeSelect}
                  value='-1'
                  disabled>
                  {SHIPPING_DISCOUNT_REASON}
                </MenuItem>
                {data.reasonCodesV2.reasons.map((reason, j) => (
                  <MenuItem
                    key={j}
                    value={reason.code}
                    name={reason.description}
                    data-testid={`shipping-discount-reason-${j}`}>
                    {reason.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Box>
      </Card>
      <Button
        classes={{ root: classes.stepperButton, disabled: classes.buttonDisabled }}
        variant='contained'
        color='primary'
        type='submit'
        disabled={
          shouldSubmitButtonBeDisabled() ||
          isSubmissionLoading ||
          shippingDiscountLoading ||
          selectedShippingCharge === 0
        }
        onClick={() => handleShippingDiscount()}
        data-testid={`shipping-discount-submit`}
        style={{ marginTop: 15 }}>
        {SUBMIT_SHIPPING_DISCOUNT}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
  discountShipping: {
    backgroundColor: theme.palette.grey[50],
    height: 'auto',
    width: '500px',
  },
  shipGroup: {
    lineHeight: '1.7em',
  },
  discountAmount: {
    paddingTop: '20px',
    lineHeight: '1.7em',
  },
  shippingText: {
    padding: '20px 20px 30px',
  },
  shippingDropDown: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
  },
  reasonCodeSelect: {
    display: 'block',
    maxWidth: 250,
    minWidth: 100,
  },
  shippingGroupDropDown: {
    marginLeft: '10px',
  },
}));
