const translations = {
  ATTACHMENTS: {
    description: 'label for the attachments on the emails details page',
    primaryValue: 'Attachments',
    stringKey: 'attachments',
  },
  EMAIL_DETAILS_ERROR: {
    description: 'setup for an email details error message',
    primaryValue: 'Email Details Error',
    stringKey: 'emailDetailsError',
  },
  EXIT: {
    description: 'label on the Email Details Page for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  RESEND_EMAIL: {
    description: 'button label for resending email',
    primaryValue: 'Resend Email',
    stringKey: 'resendEmail',
  },
  RESEND_TO: {
    description: 'label for resending email to consumer',
    primaryValue: 'Resend to',
    stringKey: 'resendTo',
  },
  COMMUNICATION_DETAIL: {
    stringKey: 'communicationDetails',
    description: 'title of a page that contains details of an email or sms message',
    primaryValue: 'Communication Details',
  },
  RESEND_SMS: {
    description: 'button label for resending sms message',
    primaryValue: 'Resend SMS',
    stringKey: 'resendSMS',
  },
  RESEND_COMM_ERROR: {
    description: 'error message when resend communication fails',
    primaryValue: 'Communication resend failed. Please try again.',
    stringKey: 'resendCommError',
  },
  RESEND_COMM_SUCCESS: {
    description: 'success message when communication resend is requested successfully',
    primaryValue: 'Communication resend requested.',
    stringKey: 'resendCommSuccess',
  },
  NO_COMM_DETAILS_AVAILABLE: {
    description: 'message to display when no communication details are available',
    primaryValue: 'No communication details available',
    stringKey: 'noCommDetailsAvailable',
  },
};

export default translations;
