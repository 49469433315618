import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import AddressForm from '../../../address/addressForm';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { areLineItemsDigitalGiftCards } from '../../../../utils/order';
import PickupNonPickupRadioButtons from './shared/pickupNonPickupRadioButtons';

/**
 * This component is step 3 of the China Create Return flow
 *
 * @param {Object} props – props object for a React component
 */
const ChinaStep3 = ({ className }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);

  const { shippingOption, selectedLines } = dialogState;
  const { setInitialShippingOption, setShippingOption } = dialogActions;
  const { omsRegionReference: region } = orderDetail;

  // just once, we'll get our initial shipping options.
  useEffect(() => {
    dialogDispatch(setInitialShippingOption('pickup'));
  }, []);

  return (
    <form className={clsx(className, classes.form)}>
      <PickupNonPickupRadioButtons
        shippingOption={shippingOption}
        onChange={(event) => dialogDispatch(setShippingOption(event.target.value))}
      />
      <AddressForm
        region={region}
        className={classes.addressForm}
        isGiftCardAddress={areLineItemsDigitalGiftCards(selectedLines)}
      />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  addressForm: {
    width: '75%',
  },
  form: {
    marginTop: '1rem',
    marginBottom: '2rem',
    display: 'grid',
    gridTemplateAreas: `
      " SO SO SO "
    `,
  },
}));

export default ChinaStep3;
