/**
 * This file serves to contain constants related to an order's origin.
 */
export const Partners = {
  'dsg.digital.web': "Dick's Sporting Goods",
  'eastbay.digital.web': 'East Bay',
  'fanatics.digital.web': 'Fanatics',
  'footlocker.digital.web': 'Foot Locker',
  'finishline.digital.web': 'Finish Line',
  'hibbett.digital.web': 'Hibbetts',
  'jdsports.digital.web': 'JD Sports',
  'nordstrom.digital.web': 'Nordstrom',
};

export const PartnersExcludedFromDetailsInEMEA = new Set([
  'footlocker.digital.web',
  'perry.digital.web',
  'jdsports.digital.web',
  'prodirect.digital.web',
  'stadium.digital.web',
  'zalando.digital.web',
  'WHOLESALEEUROPE',
  'JDSPORTS',
  'ZALANDOEU',
  'PRODIRECT',
  'PERRY',
]);

export const EmeaPartners = {
  'footlocker.digital.web': 'Footlocker', // no results
  'jdsports.digital.web': 'JD SPORTS',
  'prodirect.digital.web': 'Prodirect', // enterprise
  'stadium.digital.web': 'Stadium',
  'unisport.digital.web': 'Unisport',
  'zalando.digital.web': 'Zalando', // enterprise
};

export const EmeaPartnersAllowingInspections = new Set([
  'jdsports.digital.web',
  'JDSPORTS',
  'prodirect.digital.web',
  'PRODIRECT',
  'stadium.digital.web',
  'unisport.digital.web',
  'zalando.digital.web',
  'ZALANDOEU',
]);

export const EmeaPartnersAllowingReturnLabel = new Set([
  'jdsports.digital.web',
  'JDSPORTS',
  'prodirect.digital.web',
  'PRODIRECT',
  'stadium.digital.web',
]);

export const EmeaPartnersMasqueradingAsGeos = new Set([
  'JDSPORTS',
  'PERRY',
  'PRODIRECT',
  'ZALANDOEU',
]);

export const PartnerToRegionMap = {
  JDSPORTS: 'NIKEEUROPE',
  PERRY: 'NIKEEUROPE',
  PRODIRECT: 'NIKEEUROPE',
  ZALANDOEU: 'NIKEEUROPE',
  WHOLESALEEUROPE: 'NIKEEUROPE',
};

export const PermissionsDefinedGeos = [
  'NIKEUS',
  'NIKEEUROPE',
  'NIKEJP',
  'NIKECN',
  'NIKEKR',
  'NIKEGS',
  'NIKEAU',
];

/**
 * these channels in emea don't exist. instead we need to replace omsRegionReference with
 * the corresponding value
 */
export const EmeaEnterprises = {
  'perry.digital.web': 'PERRY',
  'prodirect.digital.web': 'PRODIRECT',
  'zalando.digital.web': 'ZALANDOEU',
};

export const Geos = {
  NIKEUS: 'US',
  NIKEEUROPE: 'EMEA',
  NIKEJP: 'JAPAN',
  NIKEKR: 'KOREA',
  NIKEGS: 'GLOBAL STORE',
  NIKEXA: 'EXPANSION ASIA',
  NIKECN: 'CHINA',
  NIKEHK: 'HONG KONG',
  NIKEAU: 'AUSTRALIA',
};

export const Enterprises = {
  JETUS: 'JET_US',
  JDSPORTS: 'JD Sports',
  PERRY: 'Perry',
  PRODIRECT: 'Pro:Direct',
  ZALANDOEU: 'Zalando',
  WHOLESALEUS: 'WHOLESALE_US',
  NIKEELITE: 'Nike Elite',
};

export const Apps = {
  'com.cultivator.store.web': 'NBY Workshop',
};

export const WQDivisions = {
  600: 'BM Transactions',
  56: 'Catalog Store',
  300: 'EMEA Nike Store',
  301: 'EMEA Retail Studio',
  303: 'EMEA Sneakr App',
  304: 'EMEA Sneakr App',
  500: 'Japan Nike Store',
  501: 'Japan Retail Studio',
  503: 'Japan Sneakr App',
  504: 'Japan Sneakr App',
  40: 'NikeFind',
  24: 'Omega Orders',
  2: 'Sneakr App',
  605: 'Sneakr App',
  606: 'Sneakr App',
  55: 'Swoosh',
  25: 'Swoosh OMEGA',
  3: 'Swoosh SNKRS',
  302: 'Umbro',
  0: 'US Niketown',
  1: 'US Niketown',
  201: 'US Retail Studio',
};

export const EmeaPartnersNotAllowingCancelOnReturn = [
  'sportamore.digital.web',
  'footlocker.digital.web',
  'perry.digital.web',
  'PERRY',
];
