/* React/Utils */
import React from 'react';
import PropTypes from 'prop-types';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';

/* local */
import InvoiceDetails from './invoiceDetails';
import InvoiceMenu from './invoiceMenu';

export default function Invoices({ invoiceDetails, locale, action, invoiceList }) {
  const classes = useStyles();
  return (
    <div className={classes.invoices}>
      <InvoiceMenu
        action={action}
        data={invoiceList}
        currentInvoiceNo={invoiceDetails?.invoiceNo}
      />
      <InvoiceDetails
        invoiceDetails={invoiceDetails}
        locale={locale}
        transactionKey={invoiceList.chargeTransactionKey}
      />
    </div>
  );
}

Invoices.propTypes = {
  invoiceDetails: PropTypes.shape({
    invoiceNo: PropTypes.string,
  }).isRequired,
  locale: PropTypes.string,
  action: PropTypes.func,
  invoiceList: PropTypes.shape({
    chargeTransactionKey: PropTypes.string,
    invoiceCollections: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

const useStyles = makeStyles(() => ({
  invoices: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: `12px`,
    padding: `12px 0px`,
    justifyContent: `space-between`,
    alignItems: `start`,
  },
}));
