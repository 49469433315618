import React, { useContext } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { DialogTypes, ResponseStatuses, ApiTimeOut } from '../../../../constants/dialog.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import PRICE_MODIFICATION from '../../../../mutations/priceModification.mutation';
import translations from './modifyPrice.i18n';
import { areReasonsMissingFrom, isPriceModFieldsInLineItemMissing } from '../../../../utils/dialog';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import useSnacks from '../../../../hooks/useSnacks';
import { stepControlSharedStyles } from '../sharedStyles';
import { v4 as uuidv4 } from 'uuid';
import { getUnitPrice } from '../../../../utils/price';
import { NextButton, BackButton } from '../shared/buttons';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
export default function StepControl() {
  const classes = useStyles();
  const { setSlowLoading, setSnack, setError, getLoadingStatus } = useSnacks();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const [athleteInfo] = useContext(AthleteContext);
  const { prevStep, nextStep, reset, setHasTimedOut } = dialogActions;
  const { selectedLines, activeStep, submissionSteps, hasTimedOut } = dialogState;

  const {
    SUBMIT_MODIFY_PRICE,
    MODIFY_PRICE_TIME_OUT_ERROR_MESSAGE,
    MODIFY_PRICE,
    SUCCESS,
    ERROR,
  } = useMemoTranslations(translations);

  const [
    queryOrderDetails,
    { data: responseFromOrderDetailCall, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatchError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      dialogDispatch(reset());
      setSnack(`${MODIFY_PRICE} ${SUCCESS}`);
      setOrderDetail(responseFromOrderDetailCall.orderDetail);
    },
  });

  const dispatchError = (errorMessage) => {
    dialogDispatch(reset());
    setError(`${MODIFY_PRICE} ${ERROR} ${errorMessage}`);
  };

  const dispatchReset = () => {
    dialogDispatch(reset());
  };

  // Mutation that calls Modify Price
  const [applyDiscount, { loading: discountLoading }] = useMutation(PRICE_MODIFICATION, {
    onError: (err) => {
      dispatchReset();
      setError(`${MODIFY_PRICE} ${ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      const { addLineItemDiscount } = response;
      if ((addLineItemDiscount.status = ResponseStatuses.COMPLETED && !addLineItemDiscount.error)) {
        queryOrderDetails({
          variables: {
            orderNumber: orderDetail.orderNumber,
          },
        });
      } else if (
        addLineItemDiscount.status === ResponseStatuses.IN_PROGRESS ||
        addLineItemDiscount.status === ResponseStatuses.PENDING
      ) {
        dispatchError(MODIFY_PRICE_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (addLineItemDiscount.error) {
        const errorMessageOnJobCompletion = `${addLineItemDiscount.error.httpStatus}: ${addLineItemDiscount.error.message}`;
        dispatchError(errorMessageOnJobCompletion);
        dialogDispatch(reset());
      }
    },
  });

  const thereAreReasonsMissing = areReasonsMissingFrom(selectedLines, DialogTypes.MODIFY_PRICE);
  const isSubmissionStep = submissionSteps.includes(activeStep);
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionLoading = getLoadingStatus() || discountLoading;

  /**
   * Function that returns request body to call inspect returns
   * @param selectedLines : order lines selected in UI
   * @param orderDetail response from order details call
   * @returns request body to call return inspection
   */
  const structureModifyPriceInput = (selectedLines, orderDetail) => {
    const orderLines = Object.values(selectedLines).map((selectedLine) => {
      let orderline = {
        lineNumber: selectedLine?.lineNumber,
        universalProductCode: selectedLine?.item?.universalProductCode || '',
        lineCharges: [getLineCharges(selectedLine)],
        lineNotes: [getLineNotes(selectedLine)],
      };
      return orderline;
    });

    const priceModifyInput = {
      requestId: uuidv4(),
      orderLines,
    };
    return priceModifyInput;
  };

  const getLineCharges = (selectedLine) => {
    const discount = (getUnitPrice(selectedLine) - selectedLine.discountPrice).toFixed(2);

    return {
      chargeName: `${selectedLine?.discountReason?.code}-${uuidv4()}`,
      chargePerQuantity: parseFloat(discount),
    };
  };

  const getLineNotes = (selectedLine) => {
    return {
      contactDate: new Date().toISOString(),
      contactUser: athleteInfo.email,
      reasonCode: selectedLine?.discountReason?.code,
      details: selectedLine?.discountReason?.text,
    };
  };

  const handleSubmit = () => {
    setSlowLoading();
    const input = structureModifyPriceInput(selectedLines, orderDetail);
    applyDiscount({
      variables: { input, orderNumber: orderDetail.orderNumber, timeout: ApiTimeOut },
    });
  };

  // reused buttons:
  const shouldSubmitButtonBeDisabled = () => {
    if (thereAreReasonsMissing || hasTimedOut || isSubmissionLoading) return true;
    if (isPriceModFieldsInLineItemMissing(selectedLines)) return true;
    return false;
  };

  if (!isSubmissionStep)
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <NextButton
          disabled={selectedItemKeys.length === 0}
          onClick={() => dialogDispatch(nextStep())}
        />
      </div>
    );
  else
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <Button
          variant='contained'
          color='primary'
          data-testid='price-mod-submit-button'
          disabled={shouldSubmitButtonBeDisabled()}
          onClick={handleSubmit}
          type='submit'
          className={classes.stepperButton}>
          {SUBMIT_MODIFY_PRICE}
        </Button>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));
