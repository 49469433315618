/** React / Utils */
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';

/** Local */
import AthleteContext from '../store/contexts/athleteContext';
import useMemoTranslations from './useMemoTranslations';
import useSnacks from './useSnacks';
import translations from '../components/orders/content/payments/cloud/paymentMethodMods.i18n';
import { ApiTimeOut } from '../constants/dialog.const';
import SUSPEND_PAYMENT_MUTATION from '../mutations/suspendPaymentV2.mutation';
import ACTIVATE_PAYMENT_MUTATION from '../mutations/activatePaymentV2.mutation';

/**
 * hook for using payment methods
 * @param {Function} ranWhenSuccessful function ran when any of the methods is successful
 * @param {Function} ranWhenError function ran when any of the methods isn't successful
 * @returns various functions to alter payment methods on an order
 * handleActivatePaymentMethod,
    handleSuspendPaymentMethod,
    activatePaymentMethod,
    suspendPaymentMethod,
    suspendPaymentLoading,
    activatePaymentLoading
 */
const usePaymentMods = (ranWhenSuccessful, ranWhenError) => {
  const { setError, setSnack, setLoading } = useSnacks();
  const [currentlySuspendingToActivate, setCurrentlySuspendingToActivate] = React.useState(false);
  const {
    SUSPEND_PAYMENT_ERROR,
    SUSPEND_PAYMENT_SUCCESS,
    ACTIVATE_PAYMENT_ERROR,
    ACTIVATE_PAYMENT_SUCCESS,
  } = useMemoTranslations(translations);

  // Suspend Payment Method Mutation
  const [suspendPaymentMethod, { loading: suspendPaymentLoading }] = useMutation(
    SUSPEND_PAYMENT_MUTATION,
    {
      onError: (err) => {
        setError(`${SUSPEND_PAYMENT_ERROR}: ${err.message}`);
        if (currentlySuspendingToActivate) setCurrentlySuspendingToActivate(false);
        ranWhenError();
      },
      onCompleted: () => {
        /*
          Only setSnack and requery payment details if the current "suspend" action is
          not a prerequisite for activating another CC payment method.
        */
        if (!currentlySuspendingToActivate) {
          /*
            setSnack params: message, color, loading
            this allows loading state to persist during ranWhenSuccessful
          */
          setSnack(SUSPEND_PAYMENT_SUCCESS, 'green', true);
          ranWhenSuccessful();
        } else {
          setCurrentlySuspendingToActivate(false);
        }
      },
    }
  );

  // Reactivate Payment Method Mutation
  const [activatePaymentMethod, { loading: activatePaymentLoading }] = useMutation(
    ACTIVATE_PAYMENT_MUTATION,
    {
      onError: (err) => {
        setError(`${ACTIVATE_PAYMENT_ERROR}: ${err.message}`);
        ranWhenError();
      },
      onCompleted: () => {
        /*
          setSnack params: message, color, loading
          this allows loading state to persist during ranWhenSuccessful
        */
        setSnack(ACTIVATE_PAYMENT_SUCCESS, 'green', true);
        ranWhenSuccessful();
      },
    }
  );

  /**
   * suspends payment method
   * @param {String} orderNumber from orderDetail
   * @param {String} paymentId from payment service, method being suspended
   * @param {array} orderNotes orderNotes to be attached with to the order containing  information
   * surrounding the payment suspension, including which payment and the athlete.
   */
  const handleSuspendPaymentMethod = (orderNumber, paymentId, orderNotes, orderType) => {
    setLoading();
    suspendPaymentMethod({
      variables: {
        input: {
          orderId: orderNumber,
          paymentId,
          orderNotes,
          orderType,
        },
        timeout: ApiTimeOut,
      },
    });
  };

  /**
   * suspends current active payment method if credit card and updates new method to be active
   * @param {Object} activeCC currently active credit card needed so we know what so suspend
   * should have paymentId field
   * @param {String} paymentMethod current payment method
   * @param {String} orderNumber from orderDetail
   * @param {String} paymentId new payment ID
   * @param {array} suspendOrderNotes orderNotes to be attached with to the order containing
   * information surrounding the payment suspension, including which payment and the athlete.
   * @param {array} activateOrderNotes orderNotes to be attached with to the order containing
   * information surrounding the payment activation, including which payment and the athlete.
   */
  const handleActivatePaymentMethod = async (
    activeCC,
    paymentMethod,
    orderNumber,
    paymentId,
    suspendOrderNotes,
    activateOrderNotes
  ) => {
    setLoading();
    if (activeCC?.paymentId && paymentMethod === 'CreditCard') {
      setCurrentlySuspendingToActivate(true);
      await suspendPaymentMethod({
        variables: {
          input: {
            orderId: orderNumber,
            paymentId: activeCC?.paymentId,
            orderNotes: suspendOrderNotes,
          },
          timeout: ApiTimeOut,
        },
      });
    }
    activatePaymentMethod({
      variables: {
        input: {
          orderId: orderNumber,
          paymentId,
          orderNotes: activateOrderNotes,
        },
        timeout: ApiTimeOut,
      },
    });
  };

  return {
    handleActivatePaymentMethod,
    handleSuspendPaymentMethod,
    activatePaymentMethod,
    suspendPaymentMethod,
    suspendPaymentLoading,
    activatePaymentLoading,
  };
};

usePaymentMods.propTypes = {
  ranWhenError: PropTypes.func,
  ranWhenSuccessful: PropTypes.func.isRequired,
};

usePaymentMods.defaultProps = {
  ranWhenError: () => null,
};

export const GenerateOrderNotes = (contactType, noteText) => {
  const [athleteInfo] = useContext(AthleteContext);
  return [
    {
      contactTime: new Date().toISOString(),
      contactType,
      contactUser: athleteInfo.email,
      noteText,
    },
  ];
};

export default usePaymentMods;
