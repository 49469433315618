/* React/Utils */
import React, { useContext } from 'react';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import { useHistory } from 'react-router-dom';

/* Material-UI */
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Paper } from '@material-ui/core';

/* Local */
import TopBarContainer from '../orders/topBar/topBarContainer';
import WelcomeMessage from './../shared/welcomeMessage/index';
import { SettingsContext } from './../../store/contexts/settingsContext';
import { actions as settingsAction } from './../../store/actions/settingsActions';
import translations from './settings.i18n';
import config from './../../utils/config';
import useAuth from '../../hooks/useAuth';
import useSnacks from '../../hooks/useSnacks';
import ResponseSnackBar from '../shared/snackbar';
/**
 * React component for settings page
 */
export default function Settings() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const {
    SETTINGS,
    EXIT,
    SETTINGS_SUCCESS_MESSAGE,
    SETTINGS_ERROR_MESSAGE,
    DISPLAY_LANGUAGE,
    SAVE,
  } = mapValues(translations, i18nString);
  let history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  let query = useQuery();
  const [settingsState, dispatchSettings] = useContext(SettingsContext);
  const { setLanguage, setIsSettingsAvailable } = settingsAction;
  const { oktaToken } = useAuth();
  const { setSnack, setError, setLoading } = useSnacks();

  const languages = [
    {
      name: '中文',
      code: 'zh-Hans',
    },
    {
      name: 'English (US)',
      code: 'en',
    },
    {
      name: '日本語',
      code: 'ja',
    },
    {
      name: '한국어',
      code: 'ko',
    },
  ];

  // Handles exit click on settings page.
  const handleExitClick = () => {
    // if settings page is called within omobo app use history to navigate to previous page
    if (!history?.location?.search.includes('caseId')) {
      history.goBack();
    }
  };

  // Handles save click on settings page.
  const handleSaveClick = async (event) => {
    setLoading();
    let requestBody = {
      ...settingsState,
    };

    if (settingsState?.isSettingsAvailable) {
      const response = await fetch(config.foundry.editSettings, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': oktaToken,
        },
        cors: true,
        method: 'post',
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        dispatchSettings(setIsSettingsAvailable(true));
        // We have successfully updated the settings.
        setSnack(`${SETTINGS_SUCCESS_MESSAGE}`);
      } else {
        setError(`${SETTINGS_ERROR_MESSAGE}`);
      }
    } else {
      const response = await fetch(config.foundry.athleteSettings, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': oktaToken,
        },
        cors: true,
        method: 'post',
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        dispatchSettings(setIsSettingsAvailable(true));
        // We have successfully updated the settings.
        setSnack(`${SETTINGS_SUCCESS_MESSAGE}`);
      } else {
        setError(`${SETTINGS_ERROR_MESSAGE}`);
      }
    }
  };

  const handleLanguageChange = (event) => {
    dispatchSettings(setLanguage(event.target.value));
  };

  // Returns the href link that needs to be called on clicking exit button
  const getHref = () => {
    const queryParams = history.location.search;
    // If the settings page is called from ocobo then form the previous link based on query params
    if (history.location.search.includes('caseId')) {
      const path = query.get('path');
      const queryString = queryParams.substring(0, queryParams.indexOf('path') - 1);
      return `${config.ocoboUrl}${path}${queryString}`;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Box className={classes.page}>
        <TopBarContainer>
          <Typography variant='h1' className={classes.pageTitle}>
            {SETTINGS}
          </Typography>
          <Button
            component={Link}
            data-testid='exit-button'
            href={getHref()}
            className={classes.exit}
            onClick={handleExitClick}>
            {EXIT}
          </Button>
        </TopBarContainer>
        <ResponseSnackBar />
        <div className={classes.welcomeMessage} role='main'>
          <WelcomeMessage />
        </div>
        <Paper className={classes.settingsPage} elevation={2}>
          <FormControl className={classes.settingsForm} component='fieldset'>
            <FormLabel className={classes.languageHeader} component='legend'>
              {DISPLAY_LANGUAGE}
            </FormLabel>
            <RadioGroup
              aria-label={DISPLAY_LANGUAGE}
              name={DISPLAY_LANGUAGE}
              value={settingsState.language || 'en'}
              onChange={handleLanguageChange}>
              {languages.map((language, key) => (
                <FormControlLabel
                  className={classes.languages}
                  data-testid={`language-${key}`}
                  value={language.code}
                  key={key}
                  control={<Radio />}
                  label={language.name}
                />
              ))}
            </RadioGroup>
            <Button
              variant='contained'
              color='primary'
              className={classes.submit}
              data-testid='language_save'
              // this disables the button if the athleteSettings request has not finished yet
              // it prevents us from sending a post instead of a patch when data already exists
              disabled={!settingsState?.athlete_email}
              onClick={() => handleSaveClick()}>
              {SAVE}
            </Button>
          </FormControl>
        </Paper>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  settingsPage: {
    display: 'flex',
    marginTop: '1%',
    marginLeft: `3%`,
    height: '50vh',
    marginRight: `50%`,
    backgroundColor: theme.palette.background.paper,
  },
  settingsForm: {
    width: '100vw',
  },
  page: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.grey[100],
    overflowX: 'hidden',
  },
  pageTitle: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '2.25rem',
    marginLeft: '-2px',
    marginTop: '-2px',
    textStroke: `.8px ${theme.palette.grey[700]}`,
    fontWeight: 'bold',
    letterSpacing: '-2px',
    textAlign: 'left',
    textTransform: 'lowercase',
    width: '100%',
  },
  exit: {
    color: 'inherit',
  },
  welcomeMessage: {
    padding: '10px',
  },
  languageHeader: {
    fontWeight: '700',
    fontSize: '1.2rem',
    paddingLeft: '10px',
    paddingTop: '10px',
  },
  languages: {
    paddingLeft: '20px',
  },
  submit: {
    display: 'flex',
    width: '20%',
    color: 'primary',
    position: 'absolute',
    right: '30px',
    bottom: 15,
  },
}));
