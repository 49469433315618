const translations = {
  DIALOG_TITLE: {
    description: 'Title of Line Member dialog',
    primaryValue: 'Link profile',
    stringKey: 'linkProfile_dialog_title',
  },
  DESCRIPTION: {
    description: 'Description of Line Member dialog',
    primaryValue: 'Insert profile upmId you want to link to current order',
    stringKey: 'linkProfile_dialog_description',
  },
  SEARCH_CAPTION: {
    description: 'Search button label',
    primaryValue: 'Search',
    stringKey: 'linkProfile_dialog_search_caption',
  },
  SEARCH_RESULTS_HEADER: {
    description: 'Profile search result header',
    primaryValue: 'Validate profile details:',
    stringKey: 'linkProfile_dialog_search_results',
  },
  SEARCH_RESULTS_ARIA_LABEL: {
    description: 'Aria label for search results header',
    primaryValue: 'Search results header',
    stringKey: 'search_results_aria_label',
  },
  CANCEL_BUTTON_CAPTION: {
    description: 'Caption of Cancel button',
    primaryValue: 'Cancel',
    stringKey: 'search_results_cancel_caption',
  },
  SUBMIT_BUTTON_CAPTION: {
    description: 'Caption of Link Profile button',
    primaryValue: 'Link Profile',
    stringKey: 'link_profile_button',
  },
  USER_NOT_FOUND: {
    description: 'Error message displayed when user profile was not found',
    primaryValue: 'User not found',
    stringKey: 'user_not_found',
  },
  LINK_CUSTOMER_ERROR: {
    description: 'Error message displayed when profile link operation failed',
    primaryValue: 'Failed to link profile: ',
    stringKey: 'link_profile_operation_fail',
  },
  LINK_CUSTOMER_SUCCESS: {
    description: 'Success message displayed when profile link operation succeed',
    primaryValue: 'Succesfully linked profile',
    stringKey: 'link_profile_operation_success',
  },
};

export default translations;
