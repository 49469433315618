import gql from 'graphql-tag';

const PERSONALIZATIONS_QUERY = gql`
  query getPersonalizations($pidIds: [String]!) {
    personalizations(pidIds: $pidIds) {
      pidReviewId
      details {
        fieldName
        referenceId
        label
        text
        id
      }
    }
  }
`;

export default PERSONALIZATIONS_QUERY;
