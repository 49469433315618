import gql from 'graphql-tag';

const ORDER_SUMMARY_QUERY = gql`
  query getOrderSummaryV2($filters: [Filter], $anchor: Int, $sort: String) {
    csOrderSummary(filters: $filters, anchor: $anchor, sort: $sort) {
      objects {
        channel
        orderNumber
        orderHeaderKey
        orderSubmitDate
        currency
        status
        orderType
        totalAmount
        omsRegionReference
        orderLines {
          colorCode
          styleNumber
        }
        locale
        billTo {
          address {
            address1
            address2
            address3
            address4
            city
            country
            state
            zipCode
          }
          recipient {
            lastName
            firstName
          }
        }
        store {
          transactionBeginDate
        }
      }
      pages {
        next
        prev
      }
    }
  }
`;

export default ORDER_SUMMARY_QUERY;
