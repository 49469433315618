/** React / Util */
import React, { memo, useRef, useMemo, useState, useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';

/** Local */
import { UpdatePid } from './updatePid';
import PidImagesPopover from './pidImagesPopover';
import useGetPids from '../../hooks/useGetPids';
import translations from './pidReview.i18n';
import { formatDateTime } from '../../utils/date.js';

const PidSearchResultsRow = memo(({ review, setSelected, selected }) => {
  const [showPopover, setShowPopover] = useState(false);
  const anchorRef = useRef(null);
  const { customizationId, referenceData, businessUnit, country, pidTextInfo } = review;
  const orderDate = useMemo(() => {
    const orderDateStr = review.referenceData.orderDate;
    return orderDateStr && formatDateTime(orderDateStr);
  }, [review]);
  const { runQueryPidSearch: refreshPidSearchResults } = useGetPids();

  const { i18nString } = useContext(NikeI18nContext);
  const { SELECT_PID } = mapValues(translations, i18nString);
  dayjs.extend(relativeTime);
  const timeInQueue = useMemo(() => dayjs(new Date(review.referenceData.orderDate)).fromNow(true), [
    review,
  ]);

  const classes = useStyles();

  const handleSelectRow = (id) => {
    if (selected) {
      setSelected((prevSelected) => {
        prevSelected.delete(id);
        return new Set([...prevSelected]);
      });
      return;
    }
    setSelected((prevSelected) => new Set([...prevSelected, id]));
  };

  const [open, setOpen] = React.useState(false);

  const openUpdatePid = () => {
    setOpen(true);
  };

  return (
    <>
      <TableRow key={review?.id} data-testid='pidreview-row'>
        <TableCell padding='checkbox'>
          <Checkbox
            data-testid={`pidreview-table-checkbox-${review.id}`}
            checked={selected}
            inputProps={{
              'aria-label': `${SELECT_PID}: ${review.id}`,
            }}
            color='primary'
            onClick={() => handleSelectRow(review?.id)}
          />
        </TableCell>
        <TableCell align='left'>
          {pidTextInfo?.map((detail, i) => (
            <span className={classes.pidLocation} key={detail.id}>
              {detail.label}
            </span>
          ))}
        </TableCell>
        <TableCell align='left'>
          <Button
            color='primary'
            className={classes.pidTextWrapper}
            onClick={openUpdatePid}
            aria-label={pidTextInfo?.map((detail, i) => detail.text) || null}
            disabled={!pidTextInfo || pidTextInfo?.length === 0}
            onKeyPress={(e) => {
              if (e.keyCode === 'Enter') openUpdatePid();
            }}>
            {pidTextInfo?.map((detail, i) => (
              <span key={detail.id} data-testid={`pid-text-${i}`} className={classes.pidText}>
                {detail.text}
              </span>
            ))}
          </Button>
        </TableCell>
        <TableCell align='left'>{timeInQueue}</TableCell>
        <TableCell align='left'>
          <Button
            variant='text'
            color='primary'
            onClick={() => setShowPopover(true)}
            ref={anchorRef}
            data-testid='pid-metric-id-button'>
            {customizationId.value}
          </Button>
          <PidImagesPopover
            open={showPopover}
            anchorRef={anchorRef}
            onClose={() => setShowPopover(false)}
            metricId={customizationId.value}
          />
        </TableCell>
        <TableCell align='left'>{referenceData.billingName}</TableCell>
        <TableCell align='left'>{referenceData.shippingName}</TableCell>
        <TableCell align='left'>{referenceData.orderNo}</TableCell>
        <TableCell align='left'>{orderDate}</TableCell>
        <TableCell align='left'>{businessUnit}</TableCell>
        <TableCell align='left'>{country}</TableCell>
      </TableRow>
      {Array.isArray(pidTextInfo) && (
        <UpdatePid
          open={open}
          setOpen={setOpen}
          pidTextInfo={pidTextInfo}
          onSave={refreshPidSearchResults}
          metricId={customizationId.value}
        />
      )}
    </>
  );
});

PidSearchResultsRow.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string,
    businessUnit: PropTypes.string,
    country: PropTypes.string,
    locale: PropTypes.string,
    creationDate: PropTypes.string,
    customizationId: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    }),
    referenceData: PropTypes.shape({
      reviewId: PropTypes.string,
      orderLineRef: PropTypes.string,
      orderNo: PropTypes.string,
      orderDate: PropTypes.string,
      billingName: PropTypes.string,
      shippingName: PropTypes.string,
    }),
    pidTextInfo: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }).isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  pidLocation: {
    display: 'block',
    lineHeight: 1,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    whiteSpace: 'nowrap',
  },
  pidTextWrapper: {
    '& > .MuiButton-label': {
      display: 'block',
    },
  },
  pidText: {
    display: 'block',
    lineHeight: 1,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
}));

export default PidSearchResultsRow;
