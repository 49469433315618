import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import {
  determinePriceModEligibility,
  getMessageForPriceModInEligibility,
} from '../../../../utils/dialog';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { step1SharedStyles } from '../sharedStyles';
import { OrderContext } from './../../../../store/contexts/orderContext';
import translations from './modifyPrice.i18n.js';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

export default function Step1() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [assets] = useContext(AssetsContext);

  const { SIZE } = mapValues(translations, i18nString);

  const { selectLine } = dialogActions;
  const { selectedLines } = dialogState;

  let { orderLines, pendingModification } = orderDetail;
  // as in not synthetic
  const realOrderlines = Array.from(orderLines).filter((line) => !line.omoboFlags.isSynthetic);

  const handleSelectLine = (line) => () => {
    dialogDispatch(selectLine(line));
  };

  return (
    <div className={classes.productGrid}>
      {realOrderlines.map((line, i) => {
        const isEligibleForPriceMod = pendingModification || determinePriceModEligibility(line);
        if (isEligibleForPriceMod) {
          return (
            <div key={'item-thumb-' + i}>
              <div
                role='button'
                onKeyDown={handleSelectLine(line)}
                onClick={handleSelectLine(line)}
                tabIndex={0}>
                <Card
                  className={selectedLines[line.lineNumber] ? classes.selectedItem : classes.item}
                  elevation={selectedLines[line.lineNumber] ? 3 : 1}
                  data-testid={`item-to-select-${i}`}>
                  <CardMedia
                    className={classes.productThumb}
                    image={getProductImageFromOrderLine(line, assets)}
                    title={line.item.itemDescription}
                    children={
                      selectedLines[line.lineNumber] && (
                        <CheckIcon className={classes.productSelectCheckIcon} />
                      )
                    }
                  />
                  <Box p={1}>
                    <Typography noWrap variant='body1' gutterBottom>
                      {line.item.itemDescription}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography noWrap variant='caption'>
                        {line.styleNumber + '-' + line.colorCode}
                      </Typography>
                      <Typography noWrap variant='caption'>
                        {`${SIZE}: ${line.displaySize}`}
                      </Typography>
                    </div>
                  </Box>
                </Card>
              </div>
            </div>
          );
        } else {
          return (
            <div key={'item-thumb-' + i}>
              <Tooltip
                title={getMessageForPriceModInEligibility(line, selectedLines)}
                placement='bottom'
                enterDelay={200}
                leaveDelay={100}
                classes={{ tooltip: classes.tooltip }}>
                <Card className={classes.unselectableItem} elevation={0}>
                  <CardMedia
                    data-testid={`item-not-selectable-${i}`}
                    className={classes.disabledProductThumb}
                    image={getProductImageFromOrderLine(line, assets)}
                    title={line.item.itemDescription}
                  />
                  <Box p={1}>
                    <Typography variant='body1' className={classes.halfOpacity} gutterBottom>
                      {line.item.itemDescription}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography noWrap variant='caption'>
                        {line.styleNumber + '-' + line.colorCode}
                      </Typography>
                      <Typography noWrap variant='caption'>
                        {`${SIZE}: ${line.displaySize}`}
                      </Typography>
                    </div>
                  </Box>
                </Card>
              </Tooltip>
            </div>
          );
        }
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
}));
