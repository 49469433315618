import React from 'react';
import {
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import translations from './exchange.i18n';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import PropTypes from 'prop-types';

/**
 * displays price info for the entire exchange
 * @param {Object} param contains:
 * currency from orderDetail
 * exchangePriceSummaryData from exchangePreviews request
 * @returns React component
 */
const ExchangePriceSummary = ({ currency, exchangePriceSummaryData, exchangeCreditValue }) => {
  const {
    TAX,
    DISCOUNTS,
    PRODUCT_TOTAL,
    EXCHANGE_CREDIT_VALUE,
    // ESTIMATED_SHIPPING,
    TOTAL_PRICE,
    EXCHANGE_CREDIT_VALUE_ERROR_MESSAGE,
  } = useMemoTranslations(translations);
  const classes = useStyles();
  const { total, taxTotal, discount } = exchangePriceSummaryData || {};

  const combinedTotal = total + taxTotal - discount;
  const showErrorMessage = exchangeCreditValue.toFixed(2) < combinedTotal.toFixed(2);

  const exchangeCreditValueClass = showErrorMessage
    ? classes.exchangeCreditValueError
    : classes.exchangeCreditValue;
  const priceData = [
    { label: PRODUCT_TOTAL, value: total },
    // Estimated Shipping commented out until https://jira.nike.com/browse/FORGE-11313 is complete
    // { label: ESTIMATED_SHIPPING, value: 0 },
    { label: TAX, value: taxTotal },
    { label: DISCOUNTS, value: discount, className: classes.discounts },
    { label: TOTAL_PRICE, value: combinedTotal },
    {
      label: EXCHANGE_CREDIT_VALUE,
      value: exchangeCreditValue,
      className: exchangeCreditValueClass,
    },
  ];

  return (
    <>
      <Table>
        <TableBody>
          {priceData.map(({ label, value, className }, i) => (
            <TableRow
              key={i}
              data-testid={label.replace(' ', '-')}
              className={
                className ? `${className} ${classes.priceSummaryRow}` : classes.priceSummaryRow
              }>
              <TableCell>{label}</TableCell>
              <TableCell>
                {label === 'Discounts' && value ? <span>-</span> : null}
                <FormattedCurrency amount={value} currency={currency} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Container className={classes.container}>
        {showErrorMessage && (
          <>
            <InfoOutlinedIcon className={classes.infoOutlineIcon} />
            <Typography
              data-testid='step3-error-message'
              variant='body2'
              className={classes.viewExchangeErrorMessage}>
              {EXCHANGE_CREDIT_VALUE_ERROR_MESSAGE}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

ExchangePriceSummary.prototype = {
  currency: PropTypes.string,
  exchangePriceSummaryData: PropTypes.shape({
    price: PropTypes.number,
    discount: PropTypes.number,
    total: PropTypes.number,
    taxTotal: PropTypes.number,
  }),
  exchangeCreditValue: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  priceSummaryRow: {
    '& .MuiTableCell-root': {
      padding: '.5rem 0 0 0',
      border: 'none',
      fontSize: '1.25rem',
    },
  },
  exchangeCreditValue: {
    '& .MuiTableCell-root': {
      color: theme.palette.success.main,
    },
  },
  exchangeCreditValueError: {
    '& .MuiTableCell-root': {
      color: theme.palette.error.main,
    },
  },
  discounts: {
    '& .MuiTableCell-root': {
      '& span': {
        color: theme.palette.success.main,
      },
    },
  },
  viewExchangeErrorMessage: {
    fontWeight: 400,
    lineHeight: '21px',
    fontSize: '14px',
    color: theme.palette.error.main,
    marginLeft: '30px',
    verticalALign: 'top',
  },
  container: {
    padding: '.5rem 0 0 0',
    position: 'relative',
  },
  infoOutlineIcon: {
    fontSize: 18,
    lineHeight: '21px',
    position: 'absolute',
    marginTop: '3px',
    color: theme.palette.error.main,
  },
}));

export default ExchangePriceSummary;
