const translations = {
  SETTINGS: {
    stringKey: 'settings',
    primaryValue: 'settings',
    description: 'Settings page header',
  },
  EXIT: {
    description: 'label on the settings page "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  SETTINGS_SUCCESS_MESSAGE: {
    description: 'Snack message when settings is updated successfully',
    primaryValue: 'settings updated',
    stringKey: 'settingUpdateSuccessful',
  },
  SETTINGS_ERROR_MESSAGE: {
    description: 'Snack message when settings failed to update',
    primaryValue: 'Updating of settings has failed.',
    stringKey: 'settingUpdateFailure',
  },
  DISPLAY_LANGUAGE: {
    description: 'Header on the setting page to display languages',
    primaryValue: 'Display Language',
    stringKey: 'displayLanguage',
  },
  SAVE: {
    description: 'Save button the settings page',
    primaryValue: 'SAVE',
    stringKey: 'save',
  },
};

export default translations;
