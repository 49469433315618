import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OrderRow from './orderRow';
import HasPermission from '../shared/hasPermission';
import { Developer } from '../../constants/permissions.const';
import { NikeI18nContext } from '@nike/i18n-react';
import translations from './recentOrders.i18n';
import mapValues from 'lodash/mapValues';

/**
 * This component is used to display orders with some basic information
 *
 * @param {object} props – React props object
 */
const OrderTable = ({ title, orders }) => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { ARIA_TABLE } = mapValues(translations, i18nString);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      {title && (
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          {title}
        </Typography>
      )}
      <Table className={classes.table} aria-label={ARIA_TABLE}>
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell align='right'>Region</TableCell>
            <TableCell align='right'>Number of Items</TableCell>
            {/* only show this data if you're a dev, as it's a little confusing otherwise */}
            <HasPermission permission={Developer}>
              <TableCell align='right'>Last Recorded Status</TableCell>
            </HasPermission>
            <TableCell align='right'>Date Visited</TableCell>
            {/* an empty cell for aetheric purposes */}
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <OrderRow key={order.orderNumber} order={order} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;

const useStyles = makeStyles({
  tableContainer: {
    margin: 8,
    maxWidth: 1050,
    minWidth: 850,
  },
  title: {
    padding: 16,
  },
});
