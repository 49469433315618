import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ApiTimeOut } from '../constants/dialog.const';
import BURN_GC_MUTATION from '../mutations/burnGiftCards.mutation';
import { DialogContext } from '../store/contexts/dialogContext';
import OrderContext from '../store/contexts/orderContext';
import { isLineItemGiftCard } from '../utils/orderLine';

import useSnacks from './useSnacks';

import dialogTranslations from '../components/orders/dialog/dialog.i18n';

/**
 * Hook to encapsulate Burn Gift Cards functionality for better re-use.
 *
 * Note: This hook makes assumptions about its dependencies, specifically that
 * the relevant giftCardNumbers will be available in dialogState and the
 * hook's functionality is being used solely after a return order is created.
 *
 * @param {func} burnGCsOnCompleted Optional. Callback function for success
 * @param {func} burnGCsOnError Optional. Callback function for failure
 *
 * @returns {object} with the following properties:
 *  - findAndBurnGiftCards {func} triggers mutation
 */
const useBurnGiftCards = ({ burnGCsOnCompleted, burnGCsOnError }) => {
  const [orderDetails, setOrderDetails] = useContext(OrderContext);
  const [dialogState] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  const { BURN_GIFTCARD_ERROR, GIFTCARD_NUMBER, PUT_FUNDS_ON_HOLD } = mapValues(
    dialogTranslations,
    i18nString
  );
  const { setError } = useSnacks();
  const { lock, selectedLines } = dialogState;

  /* Mutation to clear balance on any gift cards in the return order */
  const [burnGiftCards] = useMutation(BURN_GC_MUTATION, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1500,
    onError: (err) => {
      console.error('Burn GiftCard Error', err);
      const giftCardNumbers = getGiftCardsToBurn(selectedLines).map((gc) => gc.accountNumber);
      const failures = {};
      giftCardNumbers.forEach((number) => {
        failures[number] = err?.message || '';
      });
      if (Object.keys(failures)?.length > 0) {
        const errorMarkup = getErrorMarkup(failures);
        setError(errorMarkup);
      } else {
        setError(err?.message);
      }

      // Call the passed onCompleted callback, which
      if (burnGCsOnError) burnGCsOnError({ data: {}, error: err });
    },
    onCompleted: (data) => {
      const failures = getFailures(data?.burnGiftCards);
      if (Object.keys(failures)?.length > 0) {
        if (lock) {
          // If Order Details is not live, we need to display all pertinent info in a snack.
          const errorMarkup = getErrorMarkup(failures);
          setError(errorMarkup);
        } else {
          /* 
            Otherwise, we give a generic error snack and store the meaningful error data
            in the details UI via order details state/context
          */
          const newOrderDetails = {
            ...orderDetails,
            orderLines: orderDetails?.orderLines.map((line) => {
              const currentGCNumber = line?.giftCardDetail?.giftCards?.[0]?.giftCardNumber;
              if (failures.hasOwnProperty(currentGCNumber)) {
                const newLine = {
                  ...line,
                  error: {
                    giftCardNumber: currentGCNumber,
                    message: `${GIFTCARD_NUMBER} ${currentGCNumber}: ${failures[currentGCNumber]}`,
                  },
                };
                return newLine;
              }
              return line;
            }),
          };
          setOrderDetails(newOrderDetails);
          setError(`${BURN_GIFTCARD_ERROR} ${PUT_FUNDS_ON_HOLD}`);
        }
      }
      /* 
        If there were no burn failures, then we don't do anything here.
        We let everything else be handled by instances of the createReturn mutation.
      */

      // Call the passed onCompleted callback, which
      if (burnGCsOnCompleted) burnGCsOnCompleted(data);

      return data;
    },
  });

  /**
   * Assemble Failure Data
   *
   * @param {array} burnGcObjects Array of GC data from Dockyard
   * @return {object} object with keys matching giftCardNumber, whose value is an error message
   */
  function getFailures(burnGcObjects) {
    const failures = {};
    burnGcObjects?.forEach((res) => {
      if (res.error) {
        const detailMessage = res.error?.errors?.[0]?.message || '';
        failures[res.giftCardNumber] = `${res.error.message} ${detailMessage}`;
      }
    });
    return failures;
  }

  /**
   * Generate markup for error snack
   *
   * @param {object} failures - Burn GC failure data object
   * (e.g. { 6060106822143873601: "Missing required fields. Missing Card Number" })
   */
  function getErrorMarkup(failures) {
    return (
      <>
        <Typography component='h5'>
          {BURN_GIFTCARD_ERROR} {PUT_FUNDS_ON_HOLD}
        </Typography>
        <List>
          {Object.entries(failures).map(([gcNumber, message]) => (
            <ListItem>
              <span>
                {GIFTCARD_NUMBER} {gcNumber}
              </span>
              : {message}
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  /**
   * Assemble Gift Card data for the burnGiftCards API call
   *
   * @param {array} lines selectedLines from dialogState
   * @return {array} giftCardsToBurn; each object in array should have following properties
   *  - accountNumber {string}
   *  - referenceCode {string}
   *  - currency {string}
   *  - amount {int}
   */
  const getGiftCardsToBurn = (lines) => {
    // If the return order includes gift cards, collect their data for burning.
    const giftCardsToBurn = [];
    Object.values(lines)?.forEach((line) => {
      if (isLineItemGiftCard(line)) {
        giftCardsToBurn.push({
          accountNumber: line.giftCardDetail.giftCards?.[0]?.giftCardNumber,
          referenceCode: orderDetails?.orderNumber,
          currency: orderDetails?.currency,
          amount: parseFloat(line.giftCardDetail?.giftCardValue),
        });
      }
    });
    return giftCardsToBurn;
  };

  /**
   * Utility for querying order details service.
   *
   * @return {object} same return value from getFailures function.
   */
  const findAndBurnGiftCards = async () => {
    const giftCardsToBurn = getGiftCardsToBurn(selectedLines);
    if (giftCardsToBurn.length > 0) {
      const burnData = await burnGiftCards({ variables: { giftCardsToBurn, timeout: ApiTimeOut } });
      return getFailures(burnData?.data?.burnGiftCards);
    }
    return {};
  };

  return { findAndBurnGiftCards };
};

useBurnGiftCards.propTypes = {
  burnGCsOnCompleted: PropTypes.func,
  burnGCsOnError: PropTypes.func,
  lock: PropTypes.bool,
};

useBurnGiftCards.defaultProps = {
  burnGCsOnCompleted: () => null,
  burnGCsOnError: () => null,
  lock: false,
};

export default useBurnGiftCards;
