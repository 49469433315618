import React from 'react';
import { TableRow, TableHead, TableCell, TableBody } from '@material-ui/core';
import { isValidReactChild } from '../../../utils/reactUtils';

/**
 * Pass in string of values, returns a table row,
 * result needs to still be wrapped in a <Table> tag
 *
 * @prop {boolean} header - True if the tablerow is a header
 * @prop {array} data - Required array of values for each cell in the row
 * @prop {string} rowClassName - Optional className to apply to the row
 * @prop {string} cellRootClassName - Optional className to apply to the cell root
 * @prop {string || array} cellClassName - Optional className to apply to each cell,
 * if array, each className will be applied in zero-indexed order.
 */
export default function BasicTableRowWithNestedRows({
  header,
  data,
  nestedData,
  rowClassName,
  cellClassName,
  nestedCellClassName,
  firstNestedCellClassName,
  lastNestedCellClassName,
  cellRootClassName,
  outerIndentationClass,
  innerIndentationClass,
  indentColumnIndex,
}) {
  const hasClassArray = Array.isArray(cellClassName);

  /**
   * Gets the correct class name given the nestedData and the index.
   *
   * @param nestedData {Object}
   * @param rowIndex {number}
   * @returns {*}
   */
  function getNestedCellClassName(nestedData, rowIndex) {
    if (rowIndex === 0) {
      return (
        firstNestedCellClassName ||
        (nestedData.length === 1 && lastNestedCellClassName) ||
        nestedCellClassName
      );
    } else if (rowIndex === nestedData.length - 1) {
      return lastNestedCellClassName || nestedCellClassName;
    } else {
      return nestedCellClassName;
    }
  }

  const indentContent = (shouldIndent, content) => {
    if (shouldIndent) {
      return (
        <span>
          <span className={outerIndentationClass}>
            <span className={innerIndentationClass}></span>
          </span>
          {content}
        </span>
      );
    } else {
      return content;
    }
  };

  const mainTableRow = (
    <TableRow className={rowClassName}>
      {data &&
        data.map((content, i) => {
          return (
            <TableCell
              key={i}
              classes={{ root: cellRootClassName }}
              className={hasClassArray ? cellClassName[i] : cellClassName}>
              {isValidReactChild(content) && content}
            </TableCell>
          );
        })}
    </TableRow>
  );

  const nestedTableRows = [];
  if (nestedData) {
    nestedData.map((nestedDataLine, nestedDataIndex) => {
      const nestedTableRow = (
        <TableRow className={rowClassName} key={nestedDataIndex}>
          {nestedDataLine &&
            nestedDataLine.map((content, contentIndex) => {
              return (
                <TableCell
                  key={contentIndex}
                  classes={{ root: cellRootClassName }}
                  className={getNestedCellClassName(nestedData, nestedDataIndex)}>
                  {indentContent(
                    contentIndex === indentColumnIndex,
                    isValidReactChild(content) && content
                  )}
                </TableCell>
              );
            })}
        </TableRow>
      );
      nestedTableRows.push(nestedTableRow);
      return nestedTableRow;
    });
  }

  return header ? (
    <TableHead>{mainTableRow}</TableHead>
  ) : (
    <TableBody>
      {mainTableRow}
      {nestedTableRows}
    </TableBody>
  );
}
