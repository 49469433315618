export const actionTypes = {
  SET_CONSUMER: 'SET_CONSUMER',
  SET_IS_SWOOSH_MEMBER: 'SET_IS_SWOOSH_MEMBER',
  SET_CONSUMER_TYPE: 'SET_CONSUMER_TYPE',
  SET_CONSUMER_EMAIL: 'SET_CONSUMER_EMAIL',
  SET_CONSUMER_PHONE: 'SET_CONSUMER_PHONE',
  SET_CONSUMER_NAME: 'SET_CONSUMER_NAME',
  SET_KANA_FIRST_NAME: 'SET_KANA_FIRST_NAME',
  SET_KANA_LAST_NAME: 'SET_KANA_LAST_NAME',
};

export const actions = {
  setConsumer:
    /**
     * This action takes in a profile ID, and sets it.
     * todo make me take and set a consumer object
     *
     * @param {String} profileId – the profile ID to set in state
     */
    (profileId) => ({
      type: actionTypes.SET_CONSUMER,
      profileId,
    }),

  setIsSwooshMember:
    /**
     * This action sets the Swoosh status of the consumer in state
     *
     * @param {String} isSwooshMember – Boolean representing whether the member
     * has Swoosh status
     */
    (isSwooshMember) => ({
      type: actionTypes.SET_IS_SWOOSH_MEMBER,
      isSwooshMember,
    }),

  setIdentityConsumerType:
    /**
     * This action takes in a consumerType, and sets it.
     *
     * @param {String} consumerType – the consumer type to set in state
     */
    (consumerType) => ({
      type: actionTypes.SET_CONSUMER_TYPE,
      consumerType,
    }),

  setIdentityConsumerEmail:
    /**
     * This action takes in a consumerEmail, and sets it.
     *
     * @param {String} consumerEmail – the consumer email to set in state
     */
    (consumerEmail) => ({
      type: actionTypes.SET_CONSUMER_EMAIL,
      consumerEmail,
    }),

  setIdentityConsumerPhone:
    /**
     * This action takes in a consumerPhone, and sets it.
     *
     * @param {String} consumerSMS – the consumer phone number to set in state
     */
    (consumerPhone) => ({
      type: actionTypes.SET_CONSUMER_PHONE,
      consumerPhone,
    }),

  setIdentityConsumerName:
    /**
     * This action takes in a consumerName, and sets it.
     *
     * @param {String} consumerName – the consumer name to set in state
     */
    (consumerName) => ({
      type: actionTypes.SET_CONSUMER_NAME,
      consumerName,
    }),

  setIdentityKanaFirstName:
    /**
     * This action takes in a kanaFirstName, and sets it.
     *
     * @param {String} kanaFirstName – the kana first name to set in state
     */
    (kanaFirstName) => ({
      type: actionTypes.SET_KANA_FIRST_NAME,
      kanaFirstName,
    }),

  setIdentityKanaLastName:
    /**
     * This action takes in a kanaLastName, and sets it.
     *
     * @param {String} kanaLastName – the kana last name to set in state
     */
    (kanaLastName) => ({
      type: actionTypes.SET_KANA_LAST_NAME,
      kanaLastName,
    }),
};

export default actions;
