import { BopisOrderStatus } from '../../../../../constants/order.const';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import translations from '../details.i18n';

const useBopisHeader = ({ status, nonCancelledItems, isBOPIS }) => {
  const { BOPIS_ORDER, ITEMS_READY_FOR_PICKUP, PICKED_UP_ITEMS } = useMemoTranslations(
    translations
  );

  const BOPISReadyForPickUp =
    nonCancelledItems.length > 0 && isBOPIS && status === BopisOrderStatus.ReadyForPickUp;

  const BOPISPartiallyReadyForPickUp =
    nonCancelledItems.length > 0 && isBOPIS && status === BopisOrderStatus.PartiallyReadyForPickUp;

  const BOPISPickedUp =
    nonCancelledItems.length > 0 && isBOPIS && status === BopisOrderStatus.PickedUp;

  const BOPISPartiallyPickedUp =
    nonCancelledItems.length > 0 && isBOPIS && status === BopisOrderStatus.PartiallyPickedUp;

  /**
   * Returns BOPIS label based on BOPIS order status
   * We are just showing table header as BOPIS order if the order status is
   * 'Partially ReadyForPickUp' or 'Partially PickedUp'
   */
  if (BOPISReadyForPickUp) {
    return ITEMS_READY_FOR_PICKUP;
  } else if (BOPISPartiallyReadyForPickUp) {
    return BOPIS_ORDER;
  } else if (BOPISPickedUp) {
    return PICKED_UP_ITEMS;
  } else if (BOPISPartiallyPickedUp) {
    return BOPIS_ORDER;
  } else {
    return BOPIS_ORDER;
  }
};

export { useBopisHeader };
