/**
 * Formats date/time by locale.
 *
 * @param date - any value that can parse as a date
 * @param [dateOnly] - true if only want to display date, no time
 * @param [locale] - string of ISO locale code ex 'en_US'
 * @returns {string}
 */
export const format = (date, dateOnly, locale) => {
  if (!date) return;
  date = new Date(date);
  if (date.toString() === 'Invalid Date') return;

  locale = reformatLocale(locale);
  /**
   * '^' string must not have anything before the next selector
   * '[a-z]{2,3}' string starts with 2 to 3 lowercase letters
   * '-' then string has a dash
   * '[A-Z]{2} string ends with two uppercase letters
   * '$' string must not have anything after the previous selector
   * based on this list:
   * https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
   * we're missing some of the possible inputs but we're accommodating all the geos we serve
   */
  if (locale.match(/^[a-z]{2,3}-[A-Z]{2}$/) || locale.match(/^[a-z]{2,3}$/)) {
    return dateOnly
      ? date.toLocaleDateString(locale)
      : date.toLocaleString(locale).replace(',', '');
    // same as above with '_[A-Z]{2,3}' added to match 'zh_CN_CNY'
  } else if (locale.match(/^[a-z]{2,3}-[A-Z]{2}_[A-Z]{2,3}$/)) {
    locale = locale.substring(0, locale.lastIndexOf('_')).replace('_', '-');
    return dateOnly
      ? date.toLocaleDateString(locale)
      : date.toLocaleString(locale).replace(',', '');
  } else {
    return date.toDateString();
  }
};

/**
 * Parse and properly format locale strings to be used by date formatter
 */
export const reformatLocale = (localeStr) => {
  if (!localeStr) return '';
  /*
    locale strings that come with our order data have an underscore that won't parse in date methods
    this converts it to a usable format
  */
  if (localeStr.lastIndexOf('_') > 0) {
    localeStr = localeStr.replace('_', '-');
  }
  // China's format does not meet our product requirements, Japan's format works
  if (localeStr === 'zh-CN') {
    localeStr = 'ja-JP';
  }
  return localeStr;
};

/**
 *
 * @param {*} date - any value that can parse as a date
 * @param {*} [locale] - string of ISO locale code
 * @returns {string}
 */
export const formatDateTime = (date, locale = 'en_US') => {
  return format(date, false, locale);
};

/**
 *
 * @param {*} date - any value that can parse as a date
 * @param {*} [locale]
 * @returns {string} - string of ISO locale code
 */
export const formatDate = (date, locale = 'en_US') => {
  return format(date, true, locale);
};

/**
 * Calculate date time for relative date: x number of days ago.
 *
 * @param {int} days - number of days ago.
 * @return {string} - date time string in ISO format.
 */
export const getDateByRelativeDays = (days = 90) => {
  const today = new Date();
  const daysInMilliseconds = 1000 * 60 * 60 * 24 * days;
  const xDaysAgo = new Date(today - daysInMilliseconds);
  return xDaysAgo.toISOString(); // ISOString is the format utilized by the orderSummary service.
};

/**
 * Returns a locale string based on country code and language code
 *
 * @param {string} language Language code
 * @param {string} country Country code
 *
 * @return {string}
 */
export const formatLocale = (language, country) => {
  if (language && country) {
    return language + '-' + country;
  }
  if (language) {
    return language;
  }
  return '';
};
