export const actionTypes = {
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SEARCH_FIELD_VALUE: 'SET_SEARCH_FIELD_VALUE',
  RESET_SEARCH_FIELD_VALUES: 'RESET_SEARCH_FIELD_VALUES',
  SET_SEARCH_TABLE_PAGE: 'SET_SEARCH_TABLE_PAGE',
};

export const actions = {
  setSearchResults:
    /**
     * This action sets the search results
     *
     * @param {Object} searchResults – the searchResults to be set in state
     */
    (searchResults) => ({
      type: actionTypes.SET_SEARCH_RESULTS,
      searchResults,
    }),

  setSearchFieldValue:
    /**
     * This action sets the search field value
     *
     * @param {Object} searchFieldValue – the search field key/value pair to be set in state
     */
    (searchField) => {
      return {
        type: actionTypes.SET_SEARCH_FIELD_VALUE,
        searchField,
      };
    },

  resetSearchFieldValues:
    /**
     * This actions resets all search field values to their initial value
     */
    () => ({
      type: actionTypes.RESET_SEARCH_FIELD_VALUES,
    }),

  setSearchTablePage:
    /**
     * This action sets the search results table page
     *
     * @param {Number} newPage – the page the search table should change to
     */
    (newPage) => ({
      type: actionTypes.SET_SEARCH_TABLE_PAGE,
      newPage,
    }),
};

export default actions;
