/** React Util */
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/** Material UI */
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';

/** Local */
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { step2SharedStyles } from '../sharedStyles';
import translations from './exchange.i18n';
import StyleColorCard from './styleColorCard';
import SizeCard from './sizeCard';
import ProductDetails from './productDetails';

const NewItemSelection = ({ originalLineNumber, exchangeOptions, handleSelectSku }) => {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [dialogState] = useContext(DialogContext);
  const { selectedSkus } = dialogState;
  const {
    SELECT_COLOR,
    SELECT_SIZE,
    LOW_INVENTORY_MESSAGE,
    OUT_OF_STOCK_MESSAGE,
  } = useMemoTranslations(translations);

  const selectedSkuData = selectedSkus[originalLineNumber] || {};
  const {
    sizeSpecifications,
    styleName,
    colorCode,
    title,
    priceInfo,
    colorDescription,
    productImageUrl,
    selectedSize,
  } = selectedSkuData;

  const styleLinkData = {
    styleNumber: styleName,
    colorCode: colorCode,
    item: {
      itemDescription: title,
    },
  };

  return (
    <Container
      data-testid={`select-exchange-${originalLineNumber}`}
      className={classes.productDetailsContainer}>
      {/* PRODUCT DETAILS */}
      <Box className={classes.newProductSection}>
        <ProductDetails
          orderDetail={orderDetail}
          orderLine={styleLinkData}
          imgUrl={productImageUrl}
          imgTitle={title}
          size={selectedSize?.localizedSize || '-'}
          color={colorDescription}
          price={priceInfo?.total}
        />
      </Box>
      {/* SELECT COLOR - show colorways with first one selected by default */}
      <Box className={classes.newProductSection}>
        <Typography className={clsx(classes.text, classes.bold)}>{SELECT_COLOR}</Typography>
        {/* static width prevents StyleColorCards from dynamically growing and shrinking */}
        <Grid container spacing={1} className={classes.grid} style={{ width: '305px' }}>
          {exchangeOptions.map((product, i) => (
            <StyleColorCard
              product={product}
              originalLineNumber={originalLineNumber}
              size={selectedSize}
              selectedSkuData={selectedSkuData}
              handleSelectSku={handleSelectSku}
              key={`style-color-${originalLineNumber}-${i}`}
            />
          ))}
        </Grid>
        {/* low inventory message */}
        {selectedSize?.level === 'LOW' && (
          <Box className={classes.lowAndNoStockContainer}>
            <ErrorOutline className={classes.lowAndNoStock} />
            <Typography
              data-testid={'low-inventory-message'}
              className={clsx(classes.text, classes.lowAndNoStock)}>
              {LOW_INVENTORY_MESSAGE}
            </Typography>
          </Box>
        )}
        {/* out of stock message */}
        {Array.isArray(sizeSpecifications) &&
          sizeSpecifications?.every((size) => size.level === 'OOS') && (
            <Box className={classes.lowAndNoStockContainer}>
              <ErrorOutline className={classes.lowAndNoStock} />
              <Typography
                data-testid={'out-of-stock-message'}
                className={clsx(classes.text, classes.lowAndNoStock)}>
                {OUT_OF_STOCK_MESSAGE}
              </Typography>
            </Box>
          )}
      </Box>
      {/* SELECT SIZE - show sizes for selected colorway with original size selected by default */}
      <Box className={classes.newProductSection}>
        <Typography className={clsx(classes.text, classes.bold)}>{SELECT_SIZE}</Typography>
        <Grid container spacing={1} className={classes.grid}>
          {Array.isArray(sizeSpecifications) &&
            sizeSpecifications.map((sku, i) => (
              <SizeCard
                key={`sku-${originalLineNumber}-${i}`}
                originalLineNumber={originalLineNumber}
                product={selectedSkuData}
                sku={sku}
                selectedSize={selectedSize}
                handleSelectSku={handleSelectSku}
              />
            ))}
        </Grid>
      </Box>
    </Container>
  );
};

NewItemSelection.propTypes = {
  originalLineNumber: PropTypes.number,
  exchangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      colorCode: PropTypes.string,
      sizeSpecifications: PropTypes.array,
    })
  ),
  handleSelectSku: PropTypes.func,
};

export default NewItemSelection;

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
  text: {
    fontSize: '1rem',
    margin: '0',
  },
  bold: {
    fontWeight: 500,
  },
  productImage: {
    maxWidth: '10rem',
    maxHeight: '12rem',
  },
  productDetailsContainer: {
    padding: '0',
    display: 'flex',
  },
  newProductSection: {
    margin: '0 0.5rem',
  },
  grid: {
    margin: '0.5rem 0',
    width: '340px',
  },
  selectRoot: {
    padding: '0',
  },
  lowAndNoStock: {
    color: theme.palette.error.main,
    margin: '1%',
  },
  lowAndNoStockContainer: {
    maxWidth: '305px',
    display: 'flex',
    marginTop: '2%',
  },
}));
