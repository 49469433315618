import gql from 'graphql-tag';

const PID_TEXT_MUTATION = gql`
  mutation patchPidText($pidTexts: [PidText]!, $comments: String) {
    patchPidText(pidTexts: $pidTexts, comments: $comments) {
      status
      reason {
        extensions {
          code
          response {
            status
            statusText
            body
          }
        }
      }
    }
  }
`;

export default PID_TEXT_MUTATION;
