/** React Utils */
import React, { useContext, useEffect } from 'react';
import mapValues from 'lodash/mapValues';
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import { NikeI18nContext } from '@nike/i18n-react';

/** MUI */
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import dialogActions from '../../store/actions/dialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

/** Utils */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { FirstNameField, LastNameField, LocationNameField } from './nameFields';
import EUCountries from '../../constants/address/EUCountries.const';
import { PartnersExcludedFromDetailsInEMEA } from '../../constants/origin.const';
import { DialogContext } from '../../store/contexts/dialogContext';
import { getOmoboFlags } from '../../utils/orderLine';

/**
 * This is a reusable component for EMEA consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const EUAddressForm = ({
  isGiftCardAddress = false,
  className = '',
  region = region,
  customClasses,
  children,
  variant = 'standard',
}) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  // custom classes will overwrite standard styles
  const classes = { ...useStyles(), ...customClasses };

  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const { isBOPIS, isShipToStore, isPickUpPoint } = getOmoboFlags(dialogState.selectedLines);
  const address = isGiftCardAddress ? dialogState.gcShippingAddress : dialogState.address;
  const { locationName, address1, address2, city, country, postalCode } = address;
  const dayPhoneNumber =
    address.dayPhoneNumber ||
    dialogState.gcShippingAddress?.dayPhoneNumber ||
    dialogState.address?.dayPhoneNumber;
  const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY, POSTAL_CODE, PHONE_NUMBER } = mapValues(
    translations,
    i18nString
  );

  // Updating the address and gcShippingAddress (billing address) phone numbers
  useEffect(() => {
    if (dayPhoneNumber && !dialogState.address?.dayPhoneNumber) {
      dialogDispatch(setAddressField('dayPhoneNumber', dayPhoneNumber));
    } else if (dayPhoneNumber && !dialogState.gcShippingAddress?.dayPhoneNumber) {
      dialogDispatch(setGCShippingAddressField('dayPhoneNumber', dayPhoneNumber));
    }
  }, [dayPhoneNumber]);

  /**
   * since country is a dropdown we want to ensure we're setting a valid value by default
   * here we check if the provided value is valid and set it to null otherwise
   */
  useEffect(() => {
    const isEmeaCountry = EUCountries.some(({ abbreviation }) => abbreviation === country);
    if (!isEmeaCountry) {
      dialogDispatch(setAddressField('country', ''));
    }
  }, []);
  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const isStoreOrPickupAddress = (isBOPIS || isShipToStore || isPickUpPoint) && !isGiftCardAddress;
  const isDisabledBecauseBOPIS = isBOPIS && !isGiftCardAddress;
  const addressGridClassName = isStoreOrPickupAddress
    ? classes.storeOrPickupAddressGrid
    : classes.addressGrid;
  // Checks if order is an EMEA partner order
  const isPartnerOrder = PartnersExcludedFromDetailsInEMEA.has(region);
  return (
    <div className={clsx(addressGridClassName, className)}>
      {/* if we need to add additional jsx elements to the form they can be passed down and
     styles can be overwritten */}
      {children}
      {isStoreOrPickupAddress && locationName ? (
        <LocationNameField
          classes={classes}
          isGiftCardAddress={isGiftCardAddress}
          required={true}
          disabled={isDisabledBecauseBOPIS}
        />
      ) : (
        <>
          <FirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            disabled={isDisabledBecauseBOPIS}
            variant={variant}
          />
          <LastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            disabled={isDisabledBecauseBOPIS}
            variant={variant}
          />
        </>
      )}
      <FormControlLabel
        aria-label={ADDRESS_1}
        className={classes.address1}
        control={
          <TextField
            data-testid={'eu-address-1'}
            required
            disabled={isDisabledBecauseBOPIS}
            value={address1}
            onChange={handleChange('address1')}
            label={ADDRESS_1}
            className={classes.wideField}
            variant={variant}
          />
        }
      />
      <FormControlLabel
        aria-label={ADDRESS_2}
        className={classes.address2}
        control={
          <TextField
            data-testid={'eu-address-2'}
            disabled={isDisabledBecauseBOPIS}
            value={address2 || undefined}
            onChange={handleChange('address2')}
            label={ADDRESS_2}
            aria-labelledby={ADDRESS_2}
            className={classes.wideField}
            variant={variant}
          />
        }
      />
      <FormControlLabel
        aria-label={CITY}
        className={classes.city}
        control={
          <TextField
            data-testid={'eu-city'}
            disabled={isDisabledBecauseBOPIS}
            required
            value={city}
            onChange={handleChange('city')}
            label={CITY}
            className={classes.wideField}
            variant={variant}
          />
        }
      />
      <TextField
        select
        required
        variant={variant}
        label={COUNTRY}
        aria-label={COUNTRY}
        disabled={isDisabledBecauseBOPIS}
        data-testid={'eu-country'}
        value={country || ''}
        onChange={handleChange('country')}
        className={clsx(classes.country, classes.wideField)}
        aria-labelledby='chooseCountry'>
        {EUCountries.map((country, key) => (
          <MenuItem data-key='country' value={country.abbreviation} key={key}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        aria-label={POSTAL_CODE}
        className={classes.postalCode}
        control={
          <TextField
            variant={variant}
            data-testid={'eu-postal-code'}
            required
            disabled={isDisabledBecauseBOPIS}
            value={postalCode}
            onChange={handleChange('postalCode')}
            label={POSTAL_CODE}
            className={classes.wideField}
          />
        }
      />
      {!isStoreOrPickupAddress && isPartnerOrder && (
        // Phone number optional in EMEA Partner Orders
        <InputMask
          mask='(999) 999-9999'
          value={dayPhoneNumber}
          onChange={handleChange('dayPhoneNumber')}>
          {() => (
            <FormControlLabel
              aria-label={PHONE_NUMBER}
              className={classes.phoneField}
              control={
                <TextField
                  variant={variant}
                  data-testid={'eu-phone-number'}
                  aria-required={false}
                  inputProps={{ 'data-key': 'dayPhoneNumber' }}
                  label={PHONE_NUMBER}
                  className={classes.wideField}
                />
              }
            />
          )}
        </InputMask>
      )}
      {!isStoreOrPickupAddress && !isPartnerOrder && (
        <InputMask
          mask='999-999-9999'
          value={dayPhoneNumber}
          onChange={handleChange('dayPhoneNumber')}>
          {() => (
            <FormControlLabel
              aria-label={PHONE_NUMBER}
              className={classes.phoneField}
              control={
                <TextField
                  variant={variant}
                  data-testid={'eu-phone-number'}
                  required
                  aria-required={true}
                  inputProps={{ 'data-key': 'dayPhoneNumber' }}
                  label={PHONE_NUMBER}
                  className={classes.wideField}
                />
              }
            />
          )}
        </InputMask>
      )}
    </div>
  );
};

export default EUAddressForm;

const useStyles = makeStyles({
  addressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " FN FN FN LN LN LN "
      " A1 A1 A1 A1 A1 A1 "
      " A2 A2 A2 A2 A2 A2 "
      " CI CI CO CO PO PO "
      " PH PH .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  storeOrPickupAddressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " LO LO LO LO LO LO "
      " A1 A1 A1 A1 A1 A1 "
      " A2 A2 A2 A2 A2 A2 "
      " CI CI CO CO PO PO "
    `,
    gridGap: '1rem 3rem',
  },
  firstNameField: {
    gridArea: 'FN',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  locationNameField: {
    gridArea: 'LO',
    margin: 0,
  },
  address1: {
    gridArea: 'A1',
    width: '100%',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    width: '100%',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    width: '100%',
    margin: 0,
  },
  country: {
    gridArea: 'CO',
    width: '100%',
    margin: 0,
  },
  postalCode: {
    gridArea: 'PO',
    width: '100%',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
});
