/** React Util */
import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import clsx from 'clsx';

/** Material UI */
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import translations from './exchange.i18n';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { step2SharedStyles } from '../sharedStyles';
import { OrderContext } from '../../../../store/contexts/orderContext';
import NewItemSelection from './newItemSelection';
import StyleLink from '../../content/shared/itemLink';
import ProductDetails from './productDetails';

const ChooseOrderLineReplacements = memo(
  ({ originalLineNumber, exchangeOptions, handleSelectSku, displayHeader = true }) => {
    const classes = useStyles();
    const { i18nString } = useContext(NikeI18nContext);
    const [dialogState] = useContext(DialogContext);
    const { selectedLines } = dialogState;
    const { ORIGINAL, NEW, STYLE } = mapValues(translations, i18nString);
    const [orderDetail] = useContext(OrderContext);

    const originalItem = selectedLines[originalLineNumber];

    return (
      <div data-testid={`product-options-${originalLineNumber}`}>
        {displayHeader && (
          <Typography className={clsx(classes.bold, classes.productTitle)}>
            {originalItem.item.itemDescription}
            <span className={classes.grey}>{`     ${STYLE}: `}</span>
            <StyleLink orderDetail={orderDetail} line={originalItem} />
          </Typography>
        )}
        <Container className={classes.productDetailsContainer}>
          {/* PRODUCT DETAILS OF ITEM BEING EXCHANGED */}
          <div className={classes.newItemInfo}>
            {displayHeader && (
              <Typography className={clsx(classes.bold, classes.header)}>{ORIGINAL}</Typography>
            )}
            <ProductDetails orderDetail={orderDetail} orderLine={originalItem} />
          </div>
          {/* SECTION TO SELECT NEW PRODUCT */}
          <div>
            {displayHeader && (
              <Typography
                className={clsx(classes.bold, classes.header)}
                style={{ marginLeft: '1.5rem' }}>
                {NEW}
              </Typography>
            )}
            <NewItemSelection
              handleSelectSku={handleSelectSku}
              exchangeOptions={exchangeOptions}
              originalLineNumber={originalLineNumber}
              key={`select-exchange-${originalLineNumber}`}
            />
          </div>
        </Container>
      </div>
    );
  }
);

ChooseOrderLineReplacements.propTypes = {
  originalLineNumber: PropTypes.number,
  exchangeOptions: PropTypes.array,
  handleSelectSku: PropTypes.func,
  displayHeader: PropTypes.bool,
};

export default ChooseOrderLineReplacements;

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
  bold: {
    fontWeight: 500,
  },
  header: {
    fontSize: '1.25rem',
  },
  productTitle: {
    margin: '1rem',
    whiteSpace: 'pre',
    fontSize: '1.5rem',
  },
  newItemInfo: {
    flexDirection: 'column',
    width: '25%',
  },
  productImage: {
    maxWidth: '10rem',
    maxHeight: '12rem',
    alignSelf: 'start',
  },
  productDetailsContainer: {
    width: '100%',
    display: 'flex',
    margin: '0',
    flexDirection: 'row',
    maxWidth: 'none',
  },
}));
