import { actionTypes } from '../actions/pidReviewSearchActions';
import { defaultState } from '../contexts/pidReviewSearchContext';

/**
 * The reducer for the pid search context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const pidReviewSearchReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_RESULTS: {
      const { totals: newTotals, data: newSearchResults } = action?.searchResults;
      if (!newTotals || !newSearchResults) {
        return state;
      }
      const finalSearchResults = {
        totals: newTotals,
        data: [...newSearchResults],
      };

      return {
        ...state,
        searchResults: finalSearchResults,
      };
    }
    case actionTypes.SET_SEARCH_FIELD_VALUE: {
      return {
        ...state,
        pidSearchFormState: {
          ...state.pidSearchFormState,
          [action.searchField.key]: action.searchField.value,
        },
      };
    }
    case actionTypes.RESET_SEARCH_FIELD_VALUES: {
      return {
        ...state,
        pidSearchFormState: defaultState.pidSearchFormState,
      };
    }
    case actionTypes.SET_SEARCH_TABLE_PAGE: {
      return {
        ...state,
        page: action.newPage,
      };
    }
    default: {
      return state;
    }
  }
};
