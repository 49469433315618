import { pickKanaName } from './kana';

/**
 * Goes through all the person fields of the orderDetail (orderLine.shipTo.recipient, paymentMethod)
 * and also the idnUser (if present); it then cross references them by first and last name and
 * populates their name fields with each other's values.
 *
 * Additionally, if kana names are provided by the IDN service, they are evaluated and then used as
 * the alternate names if the alternate names were not already qualified kana names.
 *
 * @param orderDetail {object} - the orderDetail
 * @param idnUser {object} - the user from the IDN service.
 */
export const setPersonNames = (orderDetail, idnUser) => {
  if (!orderDetail) return;

  // Create a person map to cross populate fields.
  const personMap = new Map();
  if (idnUser?.name) {
    updatePersonMap(personMap, {
      firstName: idnUser.name.latin?.given,
      middleName: idnUser.name.latin?.middle,
      lastName: idnUser.name.latin?.family,
      idnKanaFirstName: idnUser.name.kana?.given,
      idnKanaMiddleName: idnUser.name.kana?.middle,
      idnKanaLastName: idnUser.name.kana?.family,
    });
  }

  // First, put all the persons from orderLines and paymentMethods in the person map.
  orderDetail.orderLines?.forEach((orderLine) =>
    updatePersonMap(personMap, orderLine?.shipTo?.recipient)
  );
  orderDetail.paymentMethods?.forEach((paymentMethod) => updatePersonMap(personMap, paymentMethod));

  /*
   Second, go through the person map and set the alternate names to be kana names, if the kana names
   are provided and the alternate names are not already kana.
   */
  personMap.forEach((person) => {
    person.alternateFirstName = pickKanaName(person.alternateFirstName, person.idnKanaFirstName);
    person.alternateMiddleName = pickKanaName(person.alternateMiddleName, person.idnKanaMiddleName);
    person.alternateLastName = pickKanaName(person.alternateLastName, person.idnKanaLastName);
  });

  /*
  Third and finally, go through all the orderLines and paymentMethods and update them with the cross
  populated and processed fields in the person map.
   */
  orderDetail.orderLines
    ?.filter((orderLine) => orderLine?.shipTo?.recipient)
    ?.forEach((orderLine) => {
      const recipient = orderLine.shipTo.recipient;
      const personKey = getPersonKey(recipient);
      mergePersons(recipient, personMap.get(personKey));
    });
  orderDetail.paymentMethods?.forEach((paymentMethod) => {
    const personKey = getPersonKey(paymentMethod);
    mergePersons(paymentMethod, personMap.get(personKey));
  });
};

// Helper function to add or update a person in the person map.
const updatePersonMap = (personMap, newPerson) => {
  if (!newPerson) return;
  const personKey = getPersonKey(newPerson);
  if (personMap.has(personKey)) {
    const savedPerson = personMap.get(personKey);
    mergePersons(savedPerson, newPerson);
  } else {
    personMap.set(personKey, newPerson);
  }
};

// Helper function to merge persons with the same first and last name.
const mergePersons = (savedPerson, newPerson) => {
  if (!newPerson) return;
  savedPerson.middleName = newPerson.middleName || savedPerson.middleName;
  savedPerson.alternateFirstName = newPerson.alternateFirstName || savedPerson.alternateFirstName;
  savedPerson.alternateMiddleName =
    newPerson.alternateMiddleName || savedPerson.alternateMiddleName;
  savedPerson.alternateLastName = newPerson.alternateLastName || savedPerson.alternateLastName;
  savedPerson.idnKanaFirstName = newPerson.idnKanaFirstName || savedPerson.idnKanaFirstName;
  savedPerson.idnKanaMiddleName = newPerson.idnKanaMiddleName || savedPerson.idnKanaMiddleName;
  savedPerson.idnKanaLastName = newPerson.idnKanaLastName || savedPerson.idnKanaLastName;
};

// Helper function to derive a person key from the person for the person map.
const getPersonKey = (person) => {
  return `${person.firstName?.toLowerCase()} ${person.lastName?.toLowerCase()}`;
};
