import gql from 'graphql-tag';

// Mutation to verify address entered
const ADDRESS_VALIDATION_MUTATION = gql`
  mutation postAddressValidator($input: AddressValidatorRequestBodyV1!, $guid: String!) {
    addressValidator(input: $input, guid: $guid) {
      verificationCode
      score
      address {
        address1
        address2
        address3
        city
        state
        country
        postalCode
      }
    }
  }
`;

export default ADDRESS_VALIDATION_MUTATION;
