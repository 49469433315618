import React, { createContext, useContext } from 'react';

import { NikeI18nProvider } from '@nike/i18n-react';
import translations from '../../lib/translations.json';
import { supportedLanguages, sanitizeTranslations } from '../../utils/translations';
import SettingsContext from './settingsContext';
export const I18nContext = createContext({});
const { Provider } = I18nContext;

// TODO extract out language reducer so that language can be set after application launch
// TODO make and move to Providers folder
/**
 * This is a provider for the Nike i18n context, with the requisite attributes filled in.
 *
 * @param {Object} React.props – a React props object
 */
export const I18nProvider = ({ children }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [settingsState] = useContext(SettingsContext);

  // a map of the values provided by SwooshDesk and their i18n-compatible equivalents
  const languageMap = {
    en_US: 'en',
    ja: 'ja',
    zh_CN: 'zh-Hans',
    ko: 'ko',
  };

  // returns false if language is not set or undefined
  const getSettingsLanguage = () => {
    if (!settingsState?.language || settingsState?.language === 'undefined') {
      return false;
    }
    return settingsState.language;
  };

  const language = urlParams.get('lang');
  const i18NnLang = getSettingsLanguage() || languageMap[language];
  const currentLanguage = i18NnLang || 'en';

  return (
    <Provider value={[{ language: currentLanguage }]}>
      <NikeI18nProvider
        currentLanguageTag={currentLanguage}
        supportedLanguages={supportedLanguages}
        translations={sanitizeTranslations(translations)}>
        {children}
      </NikeI18nProvider>
    </Provider>
  );
};

export default I18nProvider;
