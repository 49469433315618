const translations = {
  ARIA_LINKED_ORDER: {
    description: 'aria label on linked orders',
    primaryValue: 'linked order',
    stringKey: 'ariaLinkedOrder',
  },
  ARIA_SEARCH_BACK: {
    description: 'aria label on back to search results button',
    primaryValue: 'back to search results',
    stringKey: 'ariaSearchBack',
  },
  ARIA_STORE_LABEL: {
    description: 'aria label for store',
    primaryValue: 'store',
    stringKey: 'ariaStoreLabel',
  },
  ARIA_STORE_LOADING: {
    description: 'aria label for store loading',
    primaryValue: 'store loading',
    stringKey: 'ariaStoreLoading',
  },
  ADV_RETURN_REASON: {
    description: 'label on Details page for the advanced return reason',
    primaryValue: 'Advanced Return Reason',
    stringKey: 'advReturnReason',
  },
  BACK_TO_SEARCH_RESULTS: {
    description: 'label on link which takes to search results page',
    primaryValue: 'BACK TO SEARCH RESULTS',
    stringKey: 'backToSearchResults',
  },
  BILL_OF_MATERIALS: {
    description: 'section label for NBY order line Bill of Materials',
    primaryValue: 'Bill of Materials',
    stringKey: 'billOfMaterials',
  },
  BOPIS_ORDER: {
    description: 'Label for BOPIS orders',
    primaryValue: 'BOPIS Order',
    stringKey: 'bopisOrder',
  },
  BURN_GIFTCARD_ERROR: {
    stringKey: 'burnGiftCardFailure',
    primaryValue: 'Return created, but removing the gift card balance failed.',
    description:
      'Error message to display when gift card is successfully returned, but balance is not successfully burned/cleared.',
  },
  CANCELED_ITEMS: {
    description: 'label on Details page for section with items that have been canceled',
    primaryValue: 'Cancelled Items',
    stringKey: 'canceledItems',
  },
  CARRIER: {
    description: 'label on Details page for "carrier"',
    primaryValue: 'Carrier',
    stringKey: 'carrier',
  },
  COLOR: {
    description: 'label on Details page for "color"',
    primaryValue: 'Color',
    stringKey: 'color',
  },
  CUST_RETURN_REASON: {
    description: 'label on Details page for "customer return reason"',
    primaryValue: 'Customer Return Reason',
    stringKey: 'customerReturnReason',
  },
  DATE_SUBMITTED: {
    description: 'day and time an order was submitted',
    primaryValue: 'Date Submitted',
    stringKey: 'dateSubmitted',
  },
  DESIGN_ID: {
    description: 'label for Design ID in item details for NBY products',
    primaryValue: 'Design ID',
    stringKey: 'designId',
  },
  DISCOUNT_TOTAL: {
    description: 'label on Details page for "discount total"',
    primaryValue: 'Discount Total',
    stringKey: 'discountTotal',
  },
  DISPLAY_SIZE: {
    description: 'label on Details page for "display size"',
    primaryValue: 'Display Size',
    stringKey: 'displaySize',
  },
  EDD: {
    description: 'label on Details page for "edd"',
    primaryValue: 'EDD',
    stringKey: 'edd',
  },
  DELIVERY_DATE: {
    description: 'label on Details page for the delivery date',
    primaryValue: 'Delivery Date',
    stringKey: 'deliveryDate',
  },
  GIFT_MESSAGE: {
    stringKey: 'giftMessage',
    primaryValue: 'Gift Message',
    description: 'label to precede displaying a gift message',
  },
  GIFTCARD_NUMBER: {
    stringKey: 'giftCardNumber',
    primaryValue: 'Gift Card Number',
    description: 'label to precede displaying agift card number',
  },
  SHIP_DATE: {
    description: 'label on Details page for the ship date',
    primaryValue: 'Ship Date',
    stringKey: 'shipDate',
  },
  ERROR_FROM_BOM_API: {
    stringKey: 'errorFromBOMAPI',
    primaryValue: 'Bill Of Materials not found for NIKE BY YOU item(s)',
    description:
      'Error message from bill of materials service when design ID fails to return BOM data',
  },
  FTC_DATE: {
    description: 'label on Details page for "ftc date"',
    primaryValue: 'FTC Date',
    stringKey: 'ftcDate',
  },
  FULFILLER: {
    description: 'label on Details page for "fulfiller"',
    primaryValue: 'Fulfiller',
    stringKey: 'fulfiller',
  },
  HOLD: {
    description: 'label for order holds',
    primaryValue: 'Hold',
    stringKey: 'hold',
  },
  STATUS: {
    description: 'label on Details page for "status"',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  ITEMS_AWAITING_SHIPMENT: {
    description: 'label on Details page for "items awaiting shipment"',
    primaryValue: 'Items Awaiting Shipment',
    stringKey: 'itemsAwaitingShipment',
  },
  ITEMS_READY_FOR_PICKUP: {
    description: 'label on Details page for "items ready for pickup"',
    primaryValue: 'Items Ready for Pickup',
    stringKey: 'itemsReadyForPickup',
  },
  LINKED_ORDER_DETAILS: {
    description:
      'label on the Details page to designate orders that relate to to the original sales order',
    primaryValue: 'Linked Order Details',
    stringKey: 'linkedOrderDetails',
  },
  ORDER_DETAILS: {
    description: 'accessible label for Order Details view',
    primaryValue: 'Order Details',
    stringKey: 'orderDetails',
  },
  ORDER_NUMBER: {
    description: 'label on the Details page to designate what the id of an order is',
    primaryValue: 'Order Number',
    stringKey: 'orderNumber',
  },
  ORDER_TYPE: {
    description: 'label on the Details page that tells user what type an order is',
    primaryValue: 'Order Type',
    stringKey: 'orderType',
  },
  PICKED_UP_ITEMS: {
    description: 'label on Details page for "picked up items"',
    primaryValue: 'Picked Up Items',
    stringKey: 'pickedUpItems',
  },
  PICKUP_POINT_DETAILS: {
    description: 'label on details page for pickup point details',
    primaryValue: 'Pickup Point Details',
    stringKey: 'pickupPointDetails',
  },
  PRODUCT_DETAILS: {
    description: 'label on the Details page for product details within the product expansion panel',
    primaryValue: 'Product Details',
    stringKey: 'productDetails',
  },
  PURCHASED_IN_STORE_DETAILS: {
    description: 'label on Details page for the purchased in store details',
    primaryValue: 'Purchased in Store',
    stringKey: 'purchasedInStoreDetails',
  },
  PURCHASED_ITEMS: {
    description: 'Label for purchased items for store orders',
    primaryValue: 'Purchased Items',
    stringKey: 'purchasedItems',
  },
  PUT_FUNDS_ON_HOLD: {
    description: 'message instructing athlete to put gift card funds on hold',
    primaryValue: 'Please put the Gift Card funds on hold.',
    stringKey: 'putFundsOnHold',
  },
  RESEND_RETURN_LABEL: {
    description: 'label on Details Page for "Resend Return Label"',
    primaryValue: 'Resend Return Label',
    stringKey: 'resendReturnLabel',
  },
  RETURN_LABEL: {
    description: 'label on Details page for "Return Label" card"',
    primaryValue: 'Return Label',
    stringKey: 'returnLabel',
  },
  RETURN_ORDER: {
    description: 'label on Details page for return orders',
    primaryValue: 'Return Order',
    stringKey: 'returnOrder',
  },
  RETURNED_ITEMS: {
    description: 'Label for returned items for store order',
    primaryValue: 'Returned Items',
    stringKey: 'returnedItems',
  },
  SHIP_GROUP: {
    description: 'label on Details page for the ship group',
    primaryValue: 'Ship Group',
    stringKey: 'shipGroup',
  },
  SHIPMENT: {
    description: 'label on Details page for "shipment"',
    primaryValue: 'Shipment',
    stringKey: 'shipment',
  },
  SHIPPED_TO_STORE_DETAILS: {
    description: 'label on Details page for the shipped to store details',
    primaryValue: 'Shipped to Store',
    stringKey: 'shippedToStoreDetails',
  },
  SHIPPING_DETAILS: {
    description: 'label on Details page for the shipping details',
    primaryValue: 'Shipping Details',
    stringKey: 'shippingDetails',
  },
  SHIPPING_METHOD: {
    description: 'label on Details page for the shipping method',
    primaryValue: 'Shipping Method',
    stringKey: 'shippingMethod',
  },
  STATUS_DATE: {
    description: 'label on Details page for "status date"',
    primaryValue: 'Status Date',
    stringKey: 'statusDate',
  },
  STORE_ADDRESS_DETAILS: {
    description: 'label on details page for store address',
    primaryValue: 'Store Address',
    stringKey: 'storeAddressDetails',
  },
  STORE_DETAILS: {
    description: 'label on details page for store details',
    primaryValue: 'Store Details',
    stringKey: 'storeDetails',
  },
  STORE_ORDER: {
    description: 'Label for store orders',
    primaryValue: 'Store Order',
    stringKey: 'storeOrder',
  },
  UNCATEGORIZED_ITEMS: {
    description:
      "Heading for purchased/returned items that for some reason don't fit into regular item classification (i.e. items that were purchased at the store, but should be delivered to customer's home)",
    primaryValue: 'Uncategorized Items',
    stringKey: 'uncategorizedItems',
  },
  TRACKING_NUMBER: {
    description: 'label on Details page for "Tracking Number"',
    primaryValue: 'Tracking Number',
    stringKey: 'tracking',
  },
  TYPE: {
    description: 'label on Details page for "type"',
    primaryValue: 'Type',
    stringKey: 'type',
  },
  UNBRIDGED_ORDER_MESSAGE: {
    description:
      'Error message to display for orders that have not yet bridged to DOMS but are available in Order Details',
    primaryValue:
      'Order modifications are not currently allowed on this order. Either the order has not been fully processed yet or the order is a retail order (POS, Assist, Assist in Cloud).',
    stringKey: 'unbridgedOrderMessage',
  },
  VIEW_CARRIER_TRACKING: {
    description: 'Link text for tracking when tracking number not available',
    primaryValue: 'View Carrier Tracking',
    stringKey: 'viewCarrierTracking',
  },
  PENDING_MOD_MESSAGE: {
    description: 'Message to display when one or more items in the order are pending modification',
    primaryValue:
      'is pending for one of the items on this order. Please wait until it is complete to make further modifications.',
    stringKey: 'pendingModMessage',
  },
  UPC: {
    description: 'label on Details page for the upc',
    primaryValue: 'UPC',
    stringKey: 'upc',
  },
  QUANTITY: {
    description: 'label on Details page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  NONPICKUP_FRIENDLY: {
    description: 'label on Details page for when a return order is non-pickup ',
    primaryValue: 'Non-Pickup',
    stringKey: 'nonpickupFriendly',
  },
  STORE_ID: {
    description: "number that corresponds to a store's unique identifier",
    primaryValue: 'Store ID',
    stringKey: 'storeId',
  },
  STORE_NAME: {
    description: 'label on the Address form for store name',
    primaryValue: 'Store Name',
    stringKey: 'storeName',
  },
  SDD: {
    description: 'label on Details page for "sdd"',
    primaryValue: 'SDD',
    stringKey: 'sdd',
  },
};

export default translations;
