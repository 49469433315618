export const actionTypes = {
  SET_SNACK: 'SET_SNACK',
  CLEAR_SNACK: 'CLEAR_SNACK',
  SET_SLOW_LOADING: 'SET_SLOW_LOADING',
  SET_OPEN: 'SET_OPEN',
  SET_LOADING: 'SET_LOADING',
  ADD_NETWORK_ERROR: 'ADD_NETWORK_ERROR',
  STOP_LOADING: 'STOP_LOADING',
};

export const actions = {
  setSnack:
    /**
     * This action sends an Snack, which will show up in the snackbar.
     *
     * @param {String} text – the text of the snack
     * @param {String} status - the status of snack (success, warning, or error)
     */
    (text, status, loading) => ({
      type: actionTypes.SET_SNACK,
      status,
      text,
      loading,
    }),

  clearSnack:
    /**
     * This action clears the current snack
     */
    () => ({
      type: actionTypes.CLEAR_SNACK,
    }),

  setSlowLoading:
    /**
     * This action starts a progress bar
     */
    () => ({
      type: actionTypes.SET_SLOW_LOADING,
    }),

  setLoading:
    /**
     * This action starts a progress bar
     */
    (value) => ({
      type: actionTypes.SET_LOADING,
    }),

  stopLoading:
    /**
     * This action stops loading without displaying a snack
     */
    (value) => ({
      type: actionTypes.STOP_LOADING,
    }),

  setOpen:
    /**
     * This action starts a progress bar
     */
    (isOpen) => ({
      type: actionTypes.SET_OPEN,
      isOpen,
    }),

  addNetworkError:
    /**
     * This action starts a progress bar
     */
    (text) => ({
      type: actionTypes.ADD_NETWORK_ERROR,
      text,
    }),
};

export default actions;
