import React, { useState, useContext, useEffect } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import translations from './admin.i18n.js';
import mapValues from 'lodash/mapValues';
import AdminContext from '../../store/contexts/adminContext';
import { Geos } from './admin.const';
import { AdminTabs } from './adminTabs';

/**
 * Component for editing permissions by permissions group, tabs + panel structure
 * based on material-ui example at https://material-ui.com/components/tabs/
 */
export default function AdminControl() {
  const [groupTabIndex, setGroupTabIndex] = useState(0);
  const [geoTabIndex, setGeoTabIndex] = useState(0);
  const { i18nString } = useContext(NikeI18nContext);
  const [adminState] = useContext(AdminContext);
  const { GROUPS, ARIA_ADMIN_CONTROL } = mapValues(translations, i18nString);
  const { groups } = adminState;

  const usGroups = groups.filter((group) => group.region === 'US');
  const euGroups = groups.filter((group) => group.region === 'EU');
  const cnGroups = groups.filter((group) => group.region === 'CN');
  const jpGroups = groups.filter((group) => group.region === 'JP');
  const krGroups = groups.filter((group) => group.region === 'KR');
  const gsGroups = groups.filter((group) => group.region === 'GS');
  const auGroups = groups.filter((group) => group.region === 'AU');

  const [activeGroup, setActiveGroup] = useState(groups[groupTabIndex]);
  const [activeGeo, setActiveGeo] = useState(Geos[geoTabIndex]);

  /**
   * returns the groups based on the geo passed
   * @param {*} geo - geo for which you need available groups
   */
  const getGeoSpecificGroups = (geo) => {
    switch (geo.code) {
      case 'US': {
        return usGroups;
      }
      case 'EU': {
        return euGroups;
      }
      case 'CN': {
        return cnGroups;
      }
      case 'JP': {
        return jpGroups;
      }
      case 'KR': {
        return krGroups;
      }
      case 'GS': {
        return gsGroups;
      }
      case 'AU': {
        return auGroups;
      }
      default: {
        return usGroups;
      }
    }
  };

  const geoLabels = {
    titleText: 'GEOS',
    groupAriaLabel: 'Geo Groups',
    listAriaLabel: 'Geos List',
  };

  const groupLabels = {
    titleText: GROUPS,
    groupAriaLabel: 'Permission Groups',
    listAriaLabel: 'Permission Groups List',
  };

  /**
   * Function that handles geo change in admin page
   * @param {*} e - element under change
   * @param {*} i - index
   */
  const handleGeoChange = (e, i) => {
    setGeoTabIndex(i);
    setGroupTabIndex(0);
    setActiveGeo(Geos[i]);
  };

  /**
   * Function that handles group change in admin page
   * @param {*} e - element under change
   * @param {*} i - index
   */
  const handleGroupChange = (e, i) => {
    setGroupTabIndex(i);
    setActiveGroup(getGeoSpecificGroups(activeGeo)[i]);
  };

  // If the groups object has changed, update the values of what is in the current tab.
  useEffect(() => {
    setActiveGroup(getGeoSpecificGroups(activeGeo)[groupTabIndex]);
  }, [groups]);

  // On switching geo's, select the first group displayed in the new selected geo
  useEffect(() => {
    setGroupTabIndex(0);
    setActiveGroup(getGeoSpecificGroups(activeGeo)[0]);
  }, [activeGeo]);

  return (
    <main aria-label={ARIA_ADMIN_CONTROL}>
      <AdminTabs
        orientation='horizontal'
        labels={geoLabels}
        tabIndex={geoTabIndex}
        handleChange={handleGeoChange}
        geoList={Geos}
        activeGroup={activeGroup}
      />
      <AdminTabs
        orientation='vertical'
        labels={groupLabels}
        tabIndex={groupTabIndex}
        handleChange={handleGroupChange}
        groupList={() => getGeoSpecificGroups(activeGeo)}
        activeGroup={activeGroup}
      />
    </main>
  );
}
