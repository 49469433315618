import gql from 'graphql-tag';

const PICKUP_POINTS_QUERY = gql`
  query pickupPointsV3ById($id: ID!) {
    pickupPointsV3ById(id: $id) {
      id
      carrier
      name
      latitude
      longitude
      address {
        address1
        address2
        address3
        address4
        city
        country
        state
        postalCode
      }
      operationHours {
        dayText
        day
        hours {
          openingTime
          closingTime
        }
      }
      offerings {
        type
      }
      pickupCriteria
      networkId
      networkName
      networkReferenceId
      vendorName
      locationType
      resourceType
      links {
        self {
          ref
        }
      }
    }
  }
`;

export default PICKUP_POINTS_QUERY;
