export const translations = {
  ACTIVATE_PAYMENT_ERROR: {
    description: 'error message for activating payment method failure',
    primaryValue: 'Failed to activate payment method',
    stringKey: 'activatePaymentError',
  },
  ACTIVATE_PAYMENT: {
    description: 'label for button that activates a payment on an order',
    primaryValue: 'Activate',
    stringKey: 'activatePayment',
  },
  SUSPENDED: {
    description: 'label on the Payment Details page for payment types in suspended status',
    primaryValue: 'Suspended',
    stringKey: 'suspended',
  },
  ARIA_ACTIVATING_PAYMENT: {
    description: 'aria label for activate payment button in loading state',
    primaryValue: 'Activate payment in progress',
    stringKey: 'ariaActivatePaymentLoading',
  },
  ARIA_SUSPENDING_PAYMENT: {
    description: 'aria label for suspend payment button in loading state',
    primaryValue: 'Suspend payment in progress',
    stringKey: 'ariaSuspendPaymentLoading',
  },
  AUTHORIZED: {
    description: 'label on the Payment Details page for "authorized"',
    primaryValue: 'Authorized',
    stringKey: 'authorized',
  },
  CHARGED: {
    description: 'label on the Payment Details page for "charged"',
    primaryValue: 'Charged',
    stringKey: 'charged',
  },
  CONFIRM_ACTIVATING_CARD: {
    description:
      'dialog text for confirmation on activating a payment method; the action will suspend the current active payment method.',
    primaryValue:
      'Activating this card will automatically suspend the current active payment method, ending in',
    stringKey: 'confirmActivatePaymentMethod',
  },
  EDIT_PAYMENT: {
    description: 'label on the Payment Details page for "editPayment"',
    primaryValue: 'Edit Payment',
    stringKey: 'editPayment',
  },
  SURE_TO_CONTINUE: {
    description: 'text asking user if they are sure they want to continue with the current action',
    primaryValue: 'Are you sure you want to continue?',
    stringKey: 'sureToContinue',
  },
  ACTIVATE_PAYMENT_METHOD: {
    description: 'title for confirmation modal to activate payment method',
    primaryValue: 'Activate Payment Method',
    stringKey: 'activatePaymentMethod',
  },
  REFUNDED: {
    description: 'label on the Payment Details page for "refunded"',
    primaryValue: 'Refunded',
    stringKey: 'refunded',
  },
  DETAILS: {
    description: 'label on the Payment Details page for "details" of each payment type',
    primaryValue: 'Details',
    stringKey: 'details',
  },
  PAYMENT_TYPE: {
    description: 'label on the Payment Details page for "paymentType"',
    primaryValue: 'Payment Type',
    stringKey: 'paymentType',
  },
  REQUESTED_AUTH: {
    description: 'label on the Payment Details page for "requestedAuth"',
    primaryValue: 'Requested Auth',
    stringKey: 'requestedAuth',
  },
  REQUESTED_CHARGE: {
    description: 'label on the Payment Details page for "requestedCharge"',
    primaryValue: 'Requested Charge',
    stringKey: 'requestedCharge',
  },
  SUSPEND_PAYMENT: {
    description: 'label on the Payment Details page for "suspendPayment"',
    primaryValue: 'Suspend',
    stringKey: 'suspendPayment',
  },
  TRANSACTIONS: {
    description: 'label on the Payment Details page for "transactions"',
    primaryValue: 'Transactions',
    stringKey: 'transactions',
  },
  BANK_NAME: {
    description: 'label for name of bank for bank payment method in order payment details',
    primaryValue: 'Bank Name',
    stringKey: 'bankName',
  },
  BANK_CITY: {
    description: 'label for bank city for bank payment method in order payment details',
    primaryValue: 'Bank City',
    stringKey: 'bankCity',
  },
  NAME_ON_ACCOUNT: {
    description: 'label for name on account for bank payment method in order payment details',
    primaryValue: 'Name on Account',
    stringKey: 'nameOnAccount',
  },
  SWIFT_BIC_CODE: {
    description: 'label for SWIFT/BIC code for bank payment method in order payment details',
    primaryValue: 'Swift/BIC Code',
    stringKey: 'swiftBicCode',
  },
  IBAN: {
    description: 'label for IBAN for bank payment method in order payment details',
    primaryValue: 'IBAN',
    stringKey: 'iban',
  },
  BANK_ADDRESS: {
    description: 'label for bank address for bank payment method in order payment details',
    primaryValue: 'Bank Address',
    stringKey: 'bankAddress',
  },
  BILLING_ADDRESS: {
    description: 'label on the returns wizard for "Billing Address"',
    primaryValue: 'Billing Address',
    stringKey: 'billingAddress',
  },
  ARIA_OFBT_PAYMENT_DETAILS: {
    stringKey: 'ariaBankPaymentDetails',
    primaryValue: 'bank payment details',
    description: 'aria label for bank payment details',
  },
  VOIDED: {
    stringKey: 'voided',
    primaryValue: 'Voided',
    description: 'label on the Payment Details page for voided payment method',
  },
  YES: {
    description:
      'text to confirm that user would like to proceed or answer a question in the affirmative',
    primaryValue: 'Yes',
    stringKey: 'yes',
  },
  VALIDATE_CARD: {
    description: 'label for button that validates a credit card number',
    primaryValue: 'Validate Card',
    stringKey: 'validateCard',
  },
  MERCHANT_ID: {
    description: 'label for merchant account number',
    primaryValue: 'Merchant ID',
    stringKey: 'merchantID',
  },
};

export default translations;
