import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TodayIcon from '@material-ui/icons/Today';
import Link from '@material-ui/core/Link';
import { formatDateTime } from './../../../utils/date.js';
import translations from './dataTable.i18n';
import mapValues from 'lodash/mapValues';
import { useHistoryPushWithSessionId } from '../../../hooks/useHistorySessionId.js';

const styles = {
  link: {
    cursor: 'pointer',
  },
  rowTight: {
    height: 'auto',
  },
  cellTight: {
    borderBottom: 'none',
    padding: 4,
    paddingRight: 4,
  },
  cellHeaderTight: {
    padding: 4,
    paddingRight: 4,
  },
  cellHeaderLoose: {},
  tooltip: {
    cursor: 'default',
  },
};

/**
 * Data table component.
 * Provide it:
 * the map (row or column label -> array of values or value)
 * the lines (optional) array of objects, if empty just uses map to make summary table
 * tight - if true removes padding and borders
 * horizontal - if true for summary table makes horizontal instead of vertical
 * isLink - if true displayed as link
 */

function DataTable(props) {
  const { map, lines, classes, tight, horizontal, isLink } = props;
  classes.cell = tight ? classes.cellTight : classes.cellLoose;
  classes.row = tight ? classes.rowTight : classes.rowLoose;
  classes.cellHeader = tight ? classes.cellHeaderTight : classes.cellHeaderLoose;
  const setRoute = useHistoryPushWithSessionId();
  const { i18nString } = useContext(NikeI18nContext);

  const { ARIA_ORDER_DATE, ARIA_RELATED_ORDER } = mapValues(translations, i18nString);

  const handleOrderClick = (orderNumber) => {
    setRoute(`/order/${orderNumber}/details`);
  };

  return (
    <Table>
      {(lines || horizontal) && (
        <>
          <TableHead className={classes.row}>
            <TableRow>
              {Object.keys(map).map((key) => (
                <TableCell className={classes.cellHeader} key={key}>
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {horizontal && (
              <TableRow className={classes.row}>
                {Object.values(map).map((value, j) => (
                  <TableCell className={classes.cell} key={j}>
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {!horizontal &&
              lines.map((note, i) => (
                <TableRow className={classes.row} key={i}>
                  {Object.values(map).map((value, j) => (
                    <TableCell className={classes.cell} key={j}>
                      {value[i]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </>
      )}
      {!lines && !horizontal && (
        <TableBody>
          {Object.keys(map).map((key) => (
            <TableRow className={classes.row} key={key}>
              <TableCell className={classes.cell} component='th' scope='row'>
                {isLink ? (
                  <Link
                    aria-label={ARIA_RELATED_ORDER}
                    tabIndex={0}
                    onClick={() => handleOrderClick(key)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleOrderClick(key);
                      }
                    }}
                    className={classes.link}>
                    {key}
                  </Link>
                ) : (
                  key
                )}
              </TableCell>

              <TableCell className={classes.cell} align='right'>
                {Array.isArray(map[key]) ? map[key][0] : map[key]}
              </TableCell>

              {isLink && (
                <TableCell className={classes.cell} align='left'>
                  <Tooltip
                    className={classes.tooltip}
                    role='tooltip'
                    aria-label={ARIA_ORDER_DATE}
                    title={Array.isArray(map[key]) ? formatDateTime(map[key][1]) : map[key]}>
                    <IconButton component={'div'}>
                      <TodayIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
}

export default withStyles(styles)(DataTable);
