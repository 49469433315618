import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { stepControlSharedStyles } from '../sharedStyles';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import translations from '../dialog.i18n';

// Back Button

/**
 * @param {boolean} disabled
 * @param {function} onClick
 */
const BackButton = ({ disabled, onClick }) => {
  const { BACK } = useMemoTranslations(translations);
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      aria-label={BACK.toLowerCase()}
      data-testid='return-back-button'
      onClick={onClick}
      classes={{
        root: classes.stepperButton,
        disabled: classes.backDisabled,
      }}>
      {BACK}
    </Button>
  );
};

BackButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Next Button

/**
 * @param {boolean} disabled
 * @param {function} onClick
 */
const NextButton = ({ disabled, onClick }) => {
  const { NEXT } = useMemoTranslations(translations);
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      variant='contained'
      color='primary'
      data-testid='next-button'
      onClick={onClick}
      aria-label={NEXT.toLowerCase()}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {NEXT}
    </Button>
  );
};

NextButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Submit Return Capture Button

/**
 * @param {boolean} disabled
 * @param {function} onClick
 */
const SubmitReturnCaptureButton = ({ disabled, onClick }) => {
  const { CREATE_RETURN } = useMemoTranslations(translations);
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      color='primary'
      type='submit'
      data-testid='return-submit-button'
      disabled={disabled}
      aria-label={CREATE_RETURN.toLowerCase()}
      onClick={onClick}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {CREATE_RETURN}
    </Button>
  );
};

SubmitReturnCaptureButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Resend Return Label Button

/**
 * @param {boolean} disabled
 * @param {function} onClick
 */
const ResendReturnLabelButton = ({ disabled, onClick }) => {
  const { RESEND_RETURN_LABEL } = useMemoTranslations(translations);
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      color='primary'
      type='submit'
      disabled={disabled}
      aria-label={RESEND_RETURN_LABEL.toLowerCase()}
      data-testid='resend-return-label-button'
      onClick={onClick}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {RESEND_RETURN_LABEL}
    </Button>
  );
};

ResendReturnLabelButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  ...stepControlSharedStyles,
}));

export { BackButton, NextButton, ResendReturnLabelButton, SubmitReturnCaptureButton };
