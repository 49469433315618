import gql from 'graphql-tag';

const INSPECT_RETURN = gql`
  mutation postReturnInspections($input: ReturnInspectionsInputV1!, $timeout: Int!) {
    returnInspections(input: $input, timeout: $timeout) {
      status
      eta
      links {
        self {
          ref
        }
      }
      error {
        httpStatus
        message
        errors {
          code
          field
          message
        }
      }
    }
  }
`;

export default INSPECT_RETURN;
