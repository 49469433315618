import gql from 'graphql-tag';

const PAYMENT_DETAILS_QUERY = gql`
  query getPaymentDetails($orderNumber: String!) {
    paymentDetails(orderNumber: $orderNumber) {
      orderInfo {
        orderNumber
        orderDate
        currency
        locale
        country
        orderTotalAmount
        upmId
        createTime
        updateTime
      }
      orderPaymentDetails {
        operationRequests {
          paymentId
          action
          paymentMethod
          requestAmount
          currency
          actualAmount
          requestStatus
          createTime
          updateTime
        }
        orderPayment {
          paymentId
          paymentMethod
          paymentStatus
          currency
          amountAuthorized
          amountVoided
          amountDebited
          amountCredited
          createTime
          updateTime
          account
          billingInfo {
            email
            firstName
            lastName
            address1
            address2
            address3
            city
            state
            postalCode
            country
            phoneNumber
          }
        }
        giftCardInfo {
          accountNumber
        }
        creditCardInfo {
          accountNumber
          cardType
          expirationMonth
          expirationYear
        }
        bankTransferInfo {
          accountName
          iban
          swiftCode
          bankName
          bankCity
        }
      }
      billingAddress {
        address {
          address1
          address2
          address3
          city
          country
          state
          postalCode
        }
        contactInfo {
          phoneNumber
          email
        }
        name {
          firstName
          altFirstName
          lastName
          altLastName
          middleName
        }
      }
    }
  }
`;

export default PAYMENT_DETAILS_QUERY;
