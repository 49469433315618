const translations = {
  ARIA_TAB_NAV: {
    stringKey: 'ariaTabNav',
    primaryValue: 'tab navigation',
    description: 'aria label for tab navigation',
  },
  ARIA_NIKE_PDP_HYPERLINK: {
    description: 'aria label on nike pdp hyperlink',
    primaryValue: 'hyperlink nike pdp',
    stringKey: 'ariaNikePdpHyperlink',
  },
};

export default translations;
