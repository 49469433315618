import React from 'react';
import { FormattedCurrency } from '../components/shared/formatCurrency';
import { format } from './date';

/**
 * Takes an order item and returns an array of values
 * matching the table columns in efapiao Items Table
 * @param {object} orderItem
 * @returns {array} [
 * Style Name,
 * Style-Color,
 * Size,
 * Quantity,
 * ]
 */

export const transformEfapiaoOrderItem = (orderItem = {}) => {
  return [orderItem.productName, orderItem.material, orderItem.size, orderItem.quantity];
};

/**
 * Takes an order invoice and a currency and returns an array of values
 * matching the table columns in eFapiaos Table
 * @param {object} efapiao
 * @returns {array} [
 * Fapiao Number
 * Date Issued,
 * Fapiao Amount,
 * Fapiao Status,
 * ]
 */

export const transformEfapiaoData = (efapiao = {}, locale, currency) => {
  return [
    efapiao.vatReceiptNo,
    format(efapiao.vatReceiptSendDate, true, locale),
    <FormattedCurrency amount={efapiao.vatReceiptAmount} currency={currency} />,
    efapiao.vatStatus,
  ];
};

/**
 * Takes an order invoice object from Order Invoice service and returns
 * an array of all eFapiao objects associated with order
 * @param {object} invoice
 * @returns {array} [
 * { eFapiao 1 },
 * { eFapiao 2 }, ...
 * ]
 */

export const getAlleFapiaos = (invoice = []) => {
  let alleFapiaos = [];
  for (let item of invoice) {
    if (item.vatReceipts) {
      alleFapiaos.push(item.vatReceipts);
    }
  }
  return alleFapiaos;
};

/**
 * find which efapiao need to be sent in an inspection
 * @param {Array} efapiaoData object array passed in from efapiaoData query
 * @param {Array} orderLines object array of orderLines that have been returned and are
 * being inspected
 * @returns vat details for the partial eFapiao to be sent with inspection mutation or null
 */
export const findPartialEfapiao = (efapiaoData, selectedOrderLines) => {
  // if either param isn't an array or is empty we want to return null
  if (
    !Array.isArray(efapiaoData) ||
    !Array.isArray(selectedOrderLines) ||
    efapiaoData.length < 1 ||
    selectedOrderLines.length < 1
  )
    return null;

  /**
   * sales order lines often have their quantity broken into multiple return order lines so we group
   * them into an object with the parent sales orderLine as key and the quantity of all matching
   * selected return orderLines as the value
   * example
   * sales orderLine:
   * {
   *  orderLineKey: 'thing',
   *  quantity: 2
   * }
   * if all of above orderLine is returned lines look like:
   * [
   *  {
   *    parentSalesOrderLineKey: 'thing',
   *    quantity: 1
   *  },
   *  {
   *    parentSalesOrderLineKey: 'thing',
   *    quantity: 1
   *  }
   * ]
   */
  const originalSaleLineQty = {};

  selectedOrderLines.forEach((line) => {
    if (originalSaleLineQty[line.parentSalesOrderLineKey]) {
      originalSaleLineQty[line.parentSalesOrderLineKey] += line.quantity;
      return;
    }
    originalSaleLineQty[line.parentSalesOrderLineKey] = line.quantity;
  });

  // efapiao that will be sent with inspection
  const efapiaoToSend = new Set();

  const efapiaoWithSelectedLines = [];
  const efapiaoWithoutSelectedLines = new Set();

  /**
   * handles efapiao lines with multiple quantity
   */
  efapiaoData.forEach((efapiao, i) => {
    // filter out efapiao that have not been issued or don't have vatReceipts
    if (efapiao.vatReceipts?.vatStatus === '600') {
      efapiao.orderLines.forEach((orderLine) => {
        if (originalSaleLineQty[orderLine.lineKey]) {
          efapiaoWithSelectedLines.push(i);
        } else {
          efapiaoWithoutSelectedLines.add(i);
        }
        if (Number(orderLine.quantity) > originalSaleLineQty[orderLine.lineKey]) {
          efapiaoToSend.add(i);
        }
      });
    }
  });

  /**
   * if an efapiao has lines that aren't being refunded and lines that are being refunded
   * we need new efapiao so we send the old one to be altered. this creates two efapiao:
   * - red, shows the items that have been refunded
   * - blue, shows the items that are kept by the consumer
   * if all the lines in an efapiao are being refunded we don't need to send it
   */

  efapiaoWithSelectedLines.forEach((selectedLineIndex) => {
    if (efapiaoWithoutSelectedLines.has(selectedLineIndex)) efapiaoToSend.add(selectedLineIndex);
  });

  // inspection schema requires only
  let vat = {
    vatCsrFlag: 'Y',
    vatReceiptList: [...efapiaoToSend].map((efapiaoIndex) => {
      const { vatReceiptKey, vatReceiptNo } = efapiaoData[efapiaoIndex].vatReceipts;
      return { vatReceiptKey, vatReceiptNumber: vatReceiptNo };
    }),
  };

  if (vat.vatReceiptList.length > 0) return vat;

  return null;
};
