import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Displays a styled card consisting of header[s], and card content
 * Is used to display information about order items and/or shipments
 *
 * @param {ReactNode | ReactNode[]} headings - card heading[s]
 * @param {React.Element} content - card content
 */
const DetailsCard = ({ headings = [], content, className, ...cardProps }) => {
  const classes = useStyles();

  if (!Array.isArray(headings)) {
    headings = [headings];
  }

  return (
    <Card className={clsx(classes.card, { [className]: className })} elevation={3} {...cardProps}>
      <CardContent>
        <CardHeader
          disableTypography
          className={classes.cardHeader}
          data-testid={'card-title-header'}
          title={
            <h2 className={classes.heading}>
              {headings.filter(Boolean).map((heading, i) => (
                <span key={heading} className={classes.headingSpan}>
                  {heading}
                </span>
              ))}
            </h2>
          }
        />
        {content}
      </CardContent>
    </Card>
  );
};

DetailsCard.propTypes = {
  headings: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  ]),
  content: PropTypes.node,
};

export default React.memo(DetailsCard);

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    padding: '16px 8px',
  },
  heading: {
    margin: '0.25em 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headingSpan: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
}));
