/** React/Utils */
import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';

/** Local */
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import translations from './recalculateTaxDialog.i18n';
import SimpleModalDialog from '../../../shared/simpleDialog';
import { ApiTimeOut, ResponseStatuses } from '../../../../constants/dialog.const';
import useSnacks from '../../../../hooks/useSnacks';
import RECALCULATE_TAX_MUTATION from '../../../../mutations/recalculateTax.mutation';
import AthleteContext from '../../../../store/contexts/athleteContext';

const RecalculateTaxModal = ({ isOpen, handleClose, orderNumber, onSuccess }) => {
  const {
    DIALOG_TITLE,
    TAX_RECALCULATE_SUCCESS,
    TAX_RECALCULATE_ERROR,
    TAX_RECALCULATE_TIME_OUT_ERROR_MESSAGE,
  } = useMemoTranslations(translations);
  const { setSnack, setError, setSlowLoading } = useSnacks();
  const [athleteInfo] = useContext(AthleteContext);

  // Mutation that calls recalculate tax
  const [postRecalculateTax] = useMutation(RECALCULATE_TAX_MUTATION, {
    onError: (err) => {
      setError(`${TAX_RECALCULATE_ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      const { recalculateTax: recalculateTaxResponse } = response;
      if (
        recalculateTaxResponse.status === ResponseStatuses.COMPLETED &&
        !recalculateTaxResponse.error
      ) {
        setSnack(`${TAX_RECALCULATE_SUCCESS}`);
        onSuccess();
      } else if (
        recalculateTaxResponse.status === ResponseStatuses.IN_PROGRES ||
        recalculateTaxResponse.status === ResponseStatuses.PENDING
      ) {
        setError(`${TAX_RECALCULATE_TIME_OUT_ERROR_MESSAGE}`);
      } else if (recalculateTaxResponse.error) {
        setError(
          `${recalculateTaxResponse.error.httpStatus}: ${recalculateTaxResponse.error.message}`
        );
      }
    },
  });

  // Function to trigger Recalculate Tax call
  const handleRecalculateTax = () => {
    setSlowLoading();
    postRecalculateTax({
      variables: {
        orderNumber: orderNumber,
        recalculateTaxDetails: {
          request: {
            requestId: `${uuidv4()}`,
            contactType: 'CSR Tax',
            contactReference: 'CSR',
            contactUser: athleteInfo.name,
            contactDate: new Date().toISOString(),
            details: 'Recalculated Tax',
            reasonCode: 'Tax',
          },
        },
        timeout: ApiTimeOut,
      },
    });
  };

  return (
    <SimpleModalDialog
      isOpen={isOpen}
      setIsOpen={handleClose}
      title={DIALOG_TITLE}
      testId='recalculate-tax-modal'
      confirmOptions={{
        action: () => handleRecalculateTax(),
      }}
    />
  );
};

export default RecalculateTaxModal;
