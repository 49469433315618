const translations = {
  ARIA_PROFILE_INFO: {
    description: 'aria label for profile information',
    primaryValue: 'profile information',
    stringKey: 'ariaProfileInfo',
  },
  BRAND_MEMBER: {
    description: 'label for Nike/Brand member user ids',
    primaryValue: 'Brand Member',
    stringKey: 'brandMember',
  },
  ERROR: {
    description: 'error message if the identity call fails',
    primaryValue: 'Error trying to fetch consumer details',
    stringKey: 'memberError',
  },
  GUEST: {
    description: 'label for Guest users',
    primaryValue: 'Guest',
    stringKey: 'guest',
  },
  ORDERED_BY: {
    description: 'title of member panel',
    primaryValue: 'Ordered By',
    stringKey: 'orderedBy',
  },
  SWOOSH_MEMBER: {
    description: 'label for Swoosh member user ids',
    primaryValue: 'Swoosh Member',
    stringKey: 'swooshMember',
  },
  LINK_PROFILE: {
    description: 'label to link an account with an order',
    primaryValue: 'LINK ACCOUNT',
    stringKey: 'linkAccount',
  },
  UNLINK_PROFILE: {
    description: 'label for button to unlink an account from an order',
    primaryValue: 'UNLINK ACCOUNT',
    stringKey: 'unlinkAccount',
  },
  ARIA_MEMBER_PROFILE_LINK: {
    stringKey: 'ariaMemberLink',
    primaryValue: 'link to member profile lookup app',
    description: 'aria label for member profile lookup app',
  },
};

export default translations;
