const translations = {
  ADD_COMMENT: {
    description: 'Title of Add Comment page',
    primaryValue: 'Add Comment',
    stringKey: 'addComment',
  },
  COMMENTS: {
    description: 'label for title of Comments page',
    primaryValue: 'Comments',
    stringKey: 'comments',
  },
  CONTACT_TYPE: {
    description: 'label on Comments page for contact type',
    primaryValue: 'Contact Type',
    stringKey: 'contactType',
  },
  DATE: {
    description: ' Field on Comments page for "date"',
    primaryValue: 'Date',
    stringKey: 'date',
  },
  DETAILS: {
    description: 'label on Comments page for "details"',
    primaryValue: 'Details',
    stringKey: 'details',
  },
  PRIME_LINE: {
    description: 'label on Comments page for "prime line"',
    primaryValue: 'Prime Line',
    stringKey: 'primeLine',
  },
  REASON: {
    description: 'label on Comments page for "reason"',
    primaryValue: 'Reason',
    stringKey: 'reason',
  },
  REFERENCE: {
    description: 'label on Comments page for "reference"',
    primaryValue: 'Reference',
    stringKey: 'reference',
  },
  USER: {
    description: 'label on Comments page for "user"',
    primaryValue: 'User',
    stringKey: 'user',
  },
  ARIA_COMMENTS: {
    description: 'aria label on Comments page',
    primaryValue: 'comments',
    stringKey: 'ariaComments',
  },
  NO_COMMENTS: {
    description: 'label on Comments page when there is no comment available',
    primaryValue: 'No related comments',
    stringKey: 'noComments',
  },
};

export default translations;
