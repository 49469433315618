import gql from 'graphql-tag';

const ACCERTIFY_RETURN_REQUEST_MUTATION = gql`
  mutation AccertifyReturnRequestMutation($input: AccertifyReturnRequest!) {
    accertifyReturnAudit(input: $input) {
      error
      transactionResults {
        transactionId
        crossReference
        rulesTripped
        totalScore
        recommendationCode
        remarks
      }
    }
  }
`;

export default ACCERTIFY_RETURN_REQUEST_MUTATION;
