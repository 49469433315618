// Return Created, Return Escalate, Return Denied, Return Origin Scan
export const inspectionStatusCodes = [
  '1100',
  '3950.300',
  '3950.400',
  '1100.200',
  '1100.500',
  '1100.050',
];

// Return Escalate, Return Denied
export const reInspectionStatusCodes = ['3950.300', '3950.400', '1100.500'];
