import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Link';
import Link from '@material-ui/core/Link';

import translations from './orderSearch.i18n';
import config from '../../utils/config';
import useHasPermission from '../../hooks/useHasPermission';
import {
  ViewPidReviewLink,
  GiftCardAccess,
  ViewPromoCodes,
  ViewMemberProfile,
} from '../../constants/permissions.const';
import NewTabButton from '../shared/newTabButton';

const NavLinks = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();
  const { hasPermission } = useHasPermission();
  const {
    GIFT_CARD_LOOKUP,
    PID_REVIEW,
    MEMBER_PROFILE_LOOKUP,
    ARIA_MEMBER_PROFILE_LINK,
    PROMO_CODES,
  } = mapValues(translations, i18nString);

  return (
    <Box component='div'>
      {hasPermission(ViewMemberProfile) && (
        <NewTabButton
          data-testid='member_profile_home_link'
          className={classes.link}
          href={config.memberProfileUrl}
          label={MEMBER_PROFILE_LOOKUP}
          ariaLabel={ARIA_MEMBER_PROFILE_LINK}
        />
      )}

      {hasPermission(ViewPromoCodes) && (
        <NewTabButton
          data-testid='link_promo_code'
          className={classes.link}
          label={PROMO_CODES}
          aria-label={PROMO_CODES}
          href={`${config.promoCodeUrl}`}
        />
      )}

      {hasPermission(GiftCardAccess) && (
        <Link
          className={classes.link}
          data-testid='link_gift_card_lookup'
          target='_blank'
          rel='noopener noreferrer'
          href={`${config.giftCardUrl}`}>
          {GIFT_CARD_LOOKUP}
        </Link>
      )}

      {hasPermission(ViewPidReviewLink) && (
        <Link
          className={classes.link}
          data-testid='pid_review'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {}}
          href={process.env.PUBLIC_URL?.toLowerCase() + '/pidReview'}>
          {PID_REVIEW}
        </Link>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginRight: theme.spacing(4),
    padding: 0,
  },
  link: {
    textDecoration: 'underline',
    margin: `0 ${theme.spacing(1)}px`,
  },
}));

export default NavLinks;
