import gql from 'graphql-tag';

const EXCHANGE_OPTIONS_QUERY = gql`
  query exchangeOptions($input: ExchangeInput!, $timeout: Int!, $userInfo: UserInfo!) {
    exchangeOptions(input: $input, timeout: $timeout, userInfo: $userInfo) {
      id
      status
      links {
        self {
          ref
        }
      }
      response {
        exchangeCreditValue
        skus {
          skuId
          styleName
          productImageUrl
          colorDescription
          colorCode
          sizeSpecifications {
            skuId
            localizedSize
            level
          }
          priceInfo {
            employeePrice
            discount
            total
            priceId
            priceSnapshotId
          }
        }
      }
      error {
        httpStatus
        message
        code
        errors {
          field
          code
          message
        }
      }
    }
  }
`;

export default EXCHANGE_OPTIONS_QUERY;
