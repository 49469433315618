import gql from 'graphql-tag';

const ADD_GC_PAYMENT_MUTATION = gql`
  mutation postAddGiftCardToOrder($input: AddGiftCardInput!, $timeout: Int!) {
    addGiftCardPaymentMethod(input: $input, timeout: $timeout) {
      id
      status
      response {
        giftCard {
          paymentId
          accountNumber
        }
      }
      error {
        httpStatus
        message
      }
    }
  }
`;

export default ADD_GC_PAYMENT_MUTATION;
