import { getOrderlineStatusCode, getOrderlineStatusDescription } from './order';
import { ReasonCodeTypes, DialogTypes } from './../constants/dialog.const';
import Geo from '../constants/geos.const';
import { PartnersExcludedFromDetailsInEMEA } from '../constants/origin.const';
import { inspectionStatusCodes } from '../constants/statusCodes.const';

/**
 * returns a set containing all the item types from the selected items object
 *
 * @param {Object} selectedLines – the lines to be parsed for item types
 */
export const getSelectedItemTypes = (selectedLines) => {
  const orderLineTypes = new Set();

  for (let key in selectedLines) {
    let orderLineType = selectedLines[key].orderLineType || '';
    orderLineType = orderLineType.toUpperCase();

    if (orderLineType) {
      orderLineTypes.add(orderLineType);
    }
  }

  return orderLineTypes;
};

/**
 * Function to check if the order line is eligible for inspection
 * @param line order line to be checked against
 * @param selectedLines order lines selected in UI
 * @returns {boolean}
 *  true if eligible for inspection false otherwise
 */
export const determineLineInspectability = (line, selectedLines) => {
  if (!Boolean(line) || !Boolean(selectedLines)) return;
  const statusCode = getOrderlineStatusCode(line);
  return inspectionStatusCodes.includes(statusCode);
};

/**
 * Function to check if the order line is eligible for price modification
 * @param line order line to be checked against
 * @param selectedLines order lines selected in UI
 * @returns {boolean}
 *  true if eligible for price modification false otherwise
 */
export const determinePriceModEligibility = (line) => {
  const { orderLineType, statuses } = line;
  const orderStatuses = Array.isArray(statuses) ? statuses : [];
  const statusCodes = orderStatuses ? orderStatuses.map((status) => status.statusCode) : [];
  const minLineStatus = statusCodes.sort()[0];

  if (!Boolean(line)) return;
  /*
    Price modification is not eligible for
    1. gift card line item
    2. electronic gift card line item
    3. store line item
    4. warranty ine item
    5. cancelled line item
    6. return processed line item
    7. if the unit price of the line item is less than or equal to 0 
  */
  if (
    minLineStatus >= 9000 ||
    orderLineType === 'GC' ||
    orderLineType === 'EGC' ||
    orderLineType === 'STORE' ||
    orderLineType === 'WARRANTY' ||
    (minLineStatus >= '3700.01' && minLineStatus <= '3700.06') ||
    line.linePriceInformation.unitPrice <= 0
  ) {
    return false;
  }
  return true;
};

/**
 *
 * @param line the order line to be checked against
 * @param selectedLines selectedLines order lines selected in UI
 * @returns {string} message explains why the order line is
 *  not eligible for inspection
 */
export const getUnInspectableMessage = (line, selectedLines) => {
  if (!Boolean(line) || !Boolean(selectedLines)) return;
  const statusDescription = getOrderlineStatusDescription(line);
  return `order line with ${statusDescription} status can not be inspected`;
};

/**
 *
 * @param line the order line to be checked against
 * @param selectedLines selectedLines order lines selected in UI
 * @returns {string} message explains why the order line is
 *  not eligible for price modifcation
 */
export const getMessageForPriceModInEligibility = (line, selectedLines) => {
  if (!Boolean(line) || !Boolean(selectedLines)) return;
  const statusDescription = getOrderlineStatusDescription(line);
  return `price can not be modified for order line with ${statusDescription}`;
};

/**
 * checks all the currently selected order lines for reasons. returns false if
 * all  items have reasons, and true otherwise.
 *
 * @param {Object} selectedLines – the currently selected lines
 */
export const areReasonsMissingFrom = (selectedLines, dialogType) => {
  let thereAreReasonsMissing = false;
  let reason = null;

  // setting reason attribute that needs to verified based on dialog type
  switch (dialogType) {
    case DialogTypes.RETURN: {
      reason = 'returnReason';
      break;
    }
    case DialogTypes.INSPECT: {
      reason = 'inspectionReason';
      break;
    }
    case DialogTypes.CANCEL: {
      reason = 'cancelReason';
      break;
    }
    case DialogTypes.MODIFY_PRICE: {
      reason = 'discountReason';
      break;
    }
    default:
      return;
  }

  for (let key in selectedLines) {
    const selectedLine = selectedLines[key];
    // -1 is the value given to the default option

    if (
      !Boolean(selectedLine[reason]) ||
      selectedLine[reason] === -1 ||
      selectedLine[reason]?.id === '-1' ||
      String(selectedLine[reason]?.code) === '-1'
    ) {
      thereAreReasonsMissing = true;
      break;
    }
  }
  return thereAreReasonsMissing;
};

/**
 * checks all the currently selected order lines for qunatity selection. returns true if
 * quantity is selected for all items, and false otherwise.
 *
 * @param {Object} selectedLines – the currently selected lines
 */
export const isQuantityMissingForLineItems = (selectedLines, dialogType) => {
  let isQuantityMissing = false;
  let quantity = null;

  // setting quantity attribute that needs to verified based on dialog type
  switch (dialogType) {
    case DialogTypes.RETURN: {
      quantity = 'quantityToReturn';
      break;
    }
    case DialogTypes.CANCEL: {
      quantity = 'quantityToCancel';
      break;
    }
    default:
      return;
  }

  for (let key in selectedLines) {
    const selectedLine = selectedLines[key];
    // -1 is the value given to the default option
    if (!selectedLine[quantity] || selectedLine[quantity] === 'Select Quantity') {
      isQuantityMissing = true;
      break;
    }
  }
  return isQuantityMissing;
};

/**
 * checks all the currently selected order lines for discount value entry, validation errors.
 * return true is if valid discount price is entered, false otherwise
 * @param {Object} selectedLines – the currently selected lines
 */
export const isPriceModFieldsInLineItemMissing = (selectedLines) => {
  let isPriceModFieldsMissing = false;

  for (let key in selectedLines) {
    const selectedLine = selectedLines[key];
    // -1 is the value given to the default option
    if (!selectedLine.discountValue || selectedLine.linePriceValidationError) {
      isPriceModFieldsMissing = true;
      break;
    }
  }
  return isPriceModFieldsMissing;
};

/**
  * Helper function that returns true if the all of the selected reason codes are one of
      a) Return to Shipper (undeliverable product - carrier return) - 7
      b) Tracer (Consumers says never got it) - 10
      c) Confirmed Fraud (product recovered) - 11
      d) Missing on Order (MOO) -16
      e) if all the selected order lines are gift card orderlines
      f) if the returns are created for Japan, China or Korea 
         where triggering return label is not required
 */
export const isReturnLabelNotRequired = (selectedLines, geo) => {
  const reasonCodesDoesNotRequireReturnLabel = ['7', '10', '11', '16'];
  const geosForWhichReturnLabelIsNotRequired = [Geo.CHINA, Geo.JAPAN, Geo.KOREA];

  if (!Boolean(selectedLines)) return false;
  const selectedLineKeys = Object.keys(selectedLines);
  if (!selectedLineKeys.length) return false;

  if (geosForWhichReturnLabelIsNotRequired.includes(geo)) {
    return true;
  }

  const areAllReasonCodesDoesNotRequireReturnLabels = selectedLineKeys.every((key) => {
    return reasonCodesDoesNotRequireReturnLabel.includes(selectedLines[key].returnReason?.code);
  });

  return areAllReasonCodesDoesNotRequireReturnLabels || isAllGiftCardOrderLines(selectedLines);
};

// Returns true if all the selected lines are gift card orderlines else returns false
export const isAllGiftCardOrderLines = (selectedLines) =>
  Object.values(selectedLines).every((selectedLine) => selectedLine.styleNumber === 'GIFTCARD');

// Checks email to be in `email@something.com` format
export const isEmailAddressValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Returns required fields based on geo - info from #mp-titan
export const getRequiredFields = (geo) => {
  /* checks if geo is one of these
  'JDSPORTS',
  'ZALANDOEU',
  'PRODIRECT',
  'PERRY' */
  if (PartnersExcludedFromDetailsInEMEA.has(geo)) {
    return ['firstName', 'lastName', 'address1', 'city', 'postalCode', 'email', 'country'];
  }
  switch (geo) {
    case Geo.US: {
      return [
        'firstName',
        'lastName',
        'address1',
        'city',
        'state',
        'postalCode',
        'email',
        'dayPhoneNumber',
      ];
    }
    case Geo.EUROPE: {
      return [
        'firstName',
        'lastName',
        'address1',
        'city',
        'postalCode',
        'email',
        'country',
        'dayPhoneNumber',
      ];
    }
    case Geo.JAPAN: {
      return [
        'email',
        'firstName',
        'lastName',
        'alternateFirstName',
        'alternateLastName',
        'address1',
        'address3',
        'city',
        'postalCode',
      ];
    }
    case Geo.CHINA: {
      return ['firstName', 'address1', 'state', 'city', 'county', 'dayPhoneNumber'];
    }
    case Geo.KOREA: {
      return [
        'firstName',
        'lastName',
        'address1',
        'state',
        'city',
        'country',
        'postalCode',
        'dayPhoneNumber',
      ];
    }
    default: {
      return [];
    }
  }
};

/**
 * Check if the address object has all the required fields.
 * @param {Object} address Object containing address fields to be validated.
 * @param {String} geo Object containing address fields to be validated.
 * @param isStoreOrPickupAddress
 */
export const addressHasRequiredFields = (geo, address, isStoreOrPickupAddress = true) => {
  if (!address) return false;
  const reqFields = getRequiredFields(geo);
  if (reqFields.length) {
    return Object.keys(address).every((key) => {
      if (reqFields.includes(key)) {
        if (key === 'email') return isEmailAddressValid(address[key]);
        else if (key === 'dayPhoneNumber') {
          return geo === Geo.US
            ? isStoreOrPickupAddress
              ? true
              : address[key] &&
                !address[key].includes('_') &&
                address[key].replace(/\D/g, '').length >= 10
            : address[key] && !address[key].includes('_');
        } else {
          return address[key];
        }
      }
      return true;
    });
  } else return true;
};

/**
 * returns email to be auto-populated in form in step 4 of the return flow
 *
 * @param {Object} address – Object containing address fields
 * @param {Object} billingAddress – Object containing the gcShippingAddress/billing fields
 * @param {String} consumerEmail – String containing consumerEmail
 */
export const getEmailForReturn = (address, billingAddress, consumerEmail) => {
  /*
  Picking email as per the below priority order
  a) from shipping address
  b) from billing address
  c) from consumer profile
  */
  let { email } = address || billingAddress;
  if (!email) {
    email = consumerEmail;
  }
  return email;
};

/**
 * checks all the currently selected order lines for disposition. returns false if
 * all items selected for approval and rejection have dispositions, and true otherwise.
 *
 * @param {Object} selectedLines – the currently selected lines
 */
export const isReturnDispositionMissing = (selectedLines) => {
  let isDispositionMissing = false;
  const statusesThatNeedsDisposition = [ReasonCodeTypes.INSPECTION_PASS, ReasonCodeTypes.REJECT];
  for (let key in selectedLines) {
    const selectedLine = selectedLines[key];
    // -1 is the value given to the default option
    if (
      String(selectedLine.disposition) === '-1' ||
      (statusesThatNeedsDisposition.includes(selectedLine.inspectReasonCodeType) &&
        (selectedLine.disposition === undefined ||
          (selectedLine.disposition && String(selectedLine.disposition.code) === '-1')))
    ) {
      isDispositionMissing = true;
    }
  }
  return isDispositionMissing;
};

/**
 * checks all the currently selected order lines for disposition. returns false if
 * all items selected for approval and rejection have dispositions, and true otherwise.
 *
 * @param {Object} selectedLines – the currently selected lines
 */
export const isEscalationNotesMissing = (selectedLines) => {
  let isEscalationNotesMissing = false;
  for (let key in selectedLines) {
    const selectedLine = selectedLines[key];
    // -1 is the value given to the default option
    if (
      selectedLine.inspectReasonCodeType === ReasonCodeTypes.ESCALATION &&
      !selectedLine.escalationNotes
    ) {
      isEscalationNotesMissing = true;
    }
  }
  return isEscalationNotesMissing;
};

/**
 * a reducer to produce the sum of costs of the selected lines * quantity selected
 * during the return flow
 * @param {Object} selectedLines – the currently selected lines.
 */
export const selectedLinesReducer = (selectedLines) => (previous, next) => {
  /*
   * if quantityToReturn is not available i state then set use 1
   * this happens when quantity dropdown is not selected in the return flow
   */
  return (
    previous +
    (selectedLines[next].linePriceInformation &&
      Number(selectedLines[next].linePriceInformation.unitPrice)) *
      (parseInt(selectedLines[next].quantityToReturn) || 1)
  );
};

/**
 * a function to get sum of the costs of the selected lines
 *
 * @param {Object} selectedLines – the currently selected lines
 */
export const getRefundTotal = (selectedLines) => {
  const reducer = selectedLinesReducer(selectedLines);
  const refundTotal = Object.keys(selectedLines).reduce(reducer, 0);
  return refundTotal.toFixed(2);
};

/**
 * Find the (first) key in the an Object whose value matches a provided parameter.
 *
 * @param {object} obj object that will scanned through.
 * @param {string} value value that we are matching.
 */
export const findKeyUsingValue = (obj, value) => {
  if (obj) {
    return Object.keys(obj).find((key) => obj[key] === value);
  }
  return;
};

/**
 * Using the provided lineNumber, flip the boolean value that it has in the frontRowMap
 * and then flip the boolean value for the item associated with sed item.
 * @param {object} frontRowMap map containing orderline keys and booleans representing if that
 *                             orderline key is in the 'front row'.
 * @param {object} inlineVasLineNumberMap map containing a reference from an item to a vas item.
 * @param {string} lineNumber unique identifier of the orderline being chosen.
 * @param {boolean} isVasItem represents whether the lineNumber is of a vas item or not.
 */
export const swapSpots = (frontRowMap, inlineVasLineNumberMap, lineNumber, isVasItem) => {
  if (!Boolean(frontRowMap) || !Boolean(inlineVasLineNumberMap) || !Boolean(lineNumber)) return;
  let newFrontRowMap = { ...frontRowMap };
  newFrontRowMap[lineNumber] = !newFrontRowMap[lineNumber];

  let backLineItemKey;
  // Find the lineNumber whose front row status will be flipped.
  if (isVasItem) {
    backLineItemKey = findKeyUsingValue(inlineVasLineNumberMap, lineNumber);
  } else {
    backLineItemKey = inlineVasLineNumberMap[lineNumber];
  }
  newFrontRowMap[backLineItemKey] = !newFrontRowMap[backLineItemKey];

  return newFrontRowMap;
};
