/* React/Utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

/* Local */
import {
  getDayPhoneNumberFromOrderLine,
  getEmailFromOrderLine,
  getEveningPhoneNumberFromOrderLine,
  prepareAddress,
} from '../../../../utils/address';
import { formatDateTime } from '../../../../utils/date';
import {
  formatOrderType,
  formatBomString,
  formatBomValue,
  getDisplaySize,
  getGiftMessage,
} from '../../../../utils/order';
import { getFTCDateFromOrderLine, ProductImage } from '../../../../utils/product';
import OrderContext from '../../../../store/contexts/orderContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import translations from './details.i18n';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';

/**
 * Main react component housing the expanded item details for each item shown on the Item Details
 * view
 */
export default function ExpandedItem({ line, giftCardImage }) {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [assets] = useContext(AssetsContext);
  const { i18nString } = useContext(NikeI18nContext);
  const {
    ARIA_LINKED_ORDER,
    BILL_OF_MATERIALS,
    COLOR,
    DATE_SUBMITTED,
    DESIGN_ID,
    DISPLAY_SIZE,
    FTC_DATE,
    GIFT_MESSAGE,
    LINKED_ORDER_DETAILS,
    ORDER_NUMBER,
    ORDER_TYPE,
    SHIPPED_TO_STORE_DETAILS,
    PRODUCT_DETAILS,
    PURCHASED_IN_STORE_DETAILS,
    SHIPPING_DETAILS,
    STATUS_DATE,
    STATUS,
    STORE_ADDRESS_DETAILS,
    TYPE,
    UPC,
    STORE_NAME,
    STORE_ID,
  } = mapValues(translations, i18nString);
  const setRoute = useHistoryPushWithSessionId();
  const { locale } = orderDetail;
  const storeName = line?.shipTo?.pickupPointAddress?.locationName;
  const storeId = line?.shipTo?.pickupPointAddress?.networkId;
  const giftMessage = getGiftMessage(line);

  const SectionHeader = ({ colSpan, content }) => (
    <TableCell component='th' scope='row' className={classes.sectionHeader} colSpan={colSpan}>
      {content}
    </TableCell>
  );

  const ProductInformationTableRow = ({ firstRow, label, value }) => {
    const rowClasses = [classes.productInformationTableRow];

    if (firstRow) {
      rowClasses.push(classes.firstRow);
    }
    return (
      <TableRow className={clsx(...rowClasses)}>
        <TableCell className={classes.shortTableHeader}>{label}</TableCell>
        <TableCell className={classes.shortTableCell}>{value}</TableCell>
      </TableRow>
    );
  };

  const handleOrderClick = (orderNumber) => {
    setRoute(`/order/${orderNumber}/details`);
  };

  const LinkedOrderTableRow = ({ label, value, isLink }) => {
    return (
      <TableRow className={classes.linkedOrderInformationTableRow}>
        <TableCell className={classes.shortTableHeader}>{label}</TableCell>
        <TableCell className={classes.shortTableCell}>
          {isLink ? (
            <Link aria-label={ARIA_LINKED_ORDER} onClick={() => handleOrderClick(value)}>
              {value}
            </Link>
          ) : (
            value
          )}
        </TableCell>
      </TableRow>
    );
  };

  /**
   *
   * @param {object} order - Order object with linked order details
   * @param {counter} i - counter to loop through order lines
   * @param {Boolean} isExchangeOrder - Boolean that tells if the order is an exchange order
   */
  const linkedOrderRow = (order, i, isExchangeOrder) => {
    return (
      <TableRow key={i}>
        <TableCell className={classes.linkedOrderDetailsColumn}>
          <Table>
            <TableBody>
              <LinkedOrderTableRow label={ORDER_NUMBER} value={order.orderNumber} isLink />
            </TableBody>
          </Table>
        </TableCell>

        <TableCell className={classes.linkedOrderDetailsColumn}>
          <Table>
            <TableBody>
              <LinkedOrderTableRow
                label={ORDER_TYPE}
                value={isExchangeOrder ? 'Exchange Order' : formatOrderType(order.orderType)}
              />
            </TableBody>
          </Table>
        </TableCell>

        <TableCell className={classes.linkedOrderDetailsColumn}>
          <Table>
            <TableBody>
              <LinkedOrderTableRow label={STATUS} value={order.status} />
            </TableBody>
          </Table>
        </TableCell>

        <TableCell className={classes.linkedOrderDetailsColumn}>
          <Table>
            <TableBody>
              <LinkedOrderTableRow
                label={DATE_SUBMITTED}
                value={formatDateTime(order.orderSubmitDate, locale)}
              />
            </TableBody>
          </Table>
        </TableCell>

        <TableCell className={classes.linkedOrderDetailsColumn}>
          <Table>
            <TableBody>
              <LinkedOrderTableRow
                label={STATUS_DATE}
                value={formatDateTime(order.modificationDate, locale)}
              />
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  /**
   * Render Bill of Materials information for NBY order lines
   *
   * @param {object} line Order line.
   */
  const BOMTableRows = ({ line }) => {
    const details = line?.bomDetails?.GetBuildBOMConfigurationService?.bom?.comps?.comp;
    if (details) {
      return (
        <>
          {/* BM */}
          <div className={classes.orderDetailsGridBOM}>
            <Table>
              <TableBody>
                <TableRow className={classes.headerRow} data-testid={'bom-details-heading'}>
                  <SectionHeader colSpan={10} content={BILL_OF_MATERIALS} />
                </TableRow>
                <TableRow className={classes.paymentDetails}>
                  <TableCell className={classes.bomDetailsColumn} data-testid={'bom-details'}>
                    <Table>
                      <TableBody>
                        <ProductInformationTableRow
                          label={DESIGN_ID}
                          value={line.customizedProductReference}
                        />
                        {// If there are 9+ key-value pairs for BOM data, then only print the first half here in this first column
                        details.length >= 9
                          ? details
                              .slice(0, details.length / 2)
                              .map((item) => (
                                <ProductInformationTableRow
                                  key={item.name}
                                  label={formatBomString(item.name)}
                                  value={formatBomValue(item.values.value)}
                                />
                              ))
                          : // Otherwise, if there are <9 key-value pairs, print them all here in just one column.
                            details.map((item) => (
                              <ProductInformationTableRow
                                key={item.name}
                                label={formatBomString(item.name)}
                                value={formatBomValue(item.values.value)}
                              />
                            ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  {/* If there are 9+ key-value pairs for BOM data, then we will need a second column
                here, rendering the second half of those values. */}
                  {details.length >= 9 && (
                    <TableCell className={classes.bomDetailsColumn}>
                      <Table>
                        <TableBody>
                          {details.slice(details.length / 2, details.length).map((item) => (
                            <ProductInformationTableRow
                              key={item.name}
                              label={formatBomString(item.name)}
                              value={formatBomValue(item.values.value)}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </>
      );
    }
    return null;
  };

  // returns address section header depending on fulfillment type of orderLine
  const getAddressSectionHeader = () => {
    if (!line.omoboFlags) return SHIPPING_DETAILS;

    const { isBOPIS, isShipToStore, isPurchasedInStore } = line.omoboFlags;
    if (isBOPIS) return STORE_ADDRESS_DETAILS;
    else if (isShipToStore) return SHIPPED_TO_STORE_DETAILS;
    else if (isPurchasedInStore) return PURCHASED_IN_STORE_DETAILS;
    else return SHIPPING_DETAILS;
  };

  return (
    <div className={classes.orderDetailsGrid}>
      {/*
        Comments below are grid-area names of the associated elements which can be found in the
        styles below. Please be mindful when moving things into / out of said elements
      */}

      {/* IM */}
      <div className={classes.orderDetailsGridProductImageContainer}>
        {/* if its a gift card order, pass in giftcard image as src prop if its available */}
        {line.styleNumber === 'GIFTCARD' && giftCardImage ? (
          <ProductImage
            src={giftCardImage}
            orderLine={line}
            className={classes.orderDetailsGridProductImage}
          />
        ) : (
          <ProductImage
            src={getProductImageFromOrderLine(line, assets)}
            orderLine={line}
            className={classes.orderDetailsGridProductImage}
          />
        )}
      </div>

      {/* PR */}
      <div className={classes.orderDetailsGridProductInfo}>
        <Table>
          <TableBody>
            <TableRow className={classes.headerRow}>
              <SectionHeader colSpan={2} content={PRODUCT_DETAILS} />
            </TableRow>

            <ProductInformationTableRow label={COLOR} value={line.colorDescription} />

            <ProductInformationTableRow label={TYPE} value={line.orderLineType} />

            <ProductInformationTableRow label={DISPLAY_SIZE} value={getDisplaySize(line)} />

            <ProductInformationTableRow
              label={UPC}
              value={line.item && line.item.universalProductCode}
            />

            <ProductInformationTableRow
              label={orderDetail.orderType !== 'RETURN_ORDER' && FTC_DATE}
              value={
                orderDetail.orderType !== 'RETURN_ORDER' && getFTCDateFromOrderLine(line, locale)
              }
            />

            {giftMessage && <ProductInformationTableRow label={GIFT_MESSAGE} value={giftMessage} />}
          </TableBody>
        </Table>
      </div>

      {/* SH */}
      <div className={classes.orderDetailsGridShippingInfo}>
        <Table>
          <TableBody>
            <TableRow
              className={classes.headerRow}
              data-testid={`shipping-details-header-${line.lineNumber}`}>
              <SectionHeader colSpan={2} content={getAddressSectionHeader()} />
            </TableRow>

            <TableRow>
              <TableCell
                className={classes.addressCell}
                colSpan={1}
                data-testid={`shipping-details-address-${line.lineNumber}`}>
                <TableRow>
                  <TableCell className={classes.shortTableCell}>
                    {prepareAddress(line)?.join('\n')}
                    {storeName && (
                      <Typography className={classes.storeInfo} style={{ marginTop: '1rem' }}>
                        {STORE_NAME}: {storeName}
                      </Typography>
                    )}
                    {storeId && (
                      <Typography className={classes.storeInfo}>
                        {STORE_ID}: {storeId}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableCell>

              <TableCell className={classes.contactInfoCell}>
                <Table>
                  <TableBody>
                    {getDayPhoneNumberFromOrderLine(line) && (
                      <TableRow className={classes.productInformationTableRow}>
                        <TableCell className={classes.shortTableCell}>
                          <span className={classes.contactInfo}>
                            <span className={classes.contactInfoLabel}>D: </span>
                            {getDayPhoneNumberFromOrderLine(line)}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {getEveningPhoneNumberFromOrderLine(line) && (
                      <TableRow className={classes.productInformationTableRow}>
                        <TableCell className={classes.shortTableCell}>
                          <span className={classes.contactInfo}>
                            <span className={classes.contactInfoLabel}>E: </span>
                            {getEveningPhoneNumberFromOrderLine(line)}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {getEmailFromOrderLine(line) && (
                      <TableRow className={classes.productInformationTableRow}>
                        <TableCell className={classes.shortTableCell}>
                          <span className={classes.contactInfo}>
                            <span className={classes.contactInfoLabel}>@: </span>
                            {getEmailFromOrderLine(line)}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      {line.orderLineType === 'NIKEID' && <BOMTableRows line={line} />}

      {/* RE */}
      <div className={classes.orderDetailsGridReturnInfo}>
        {Array.isArray(line.linkedOrders) && line.linkedOrders.length > 0 && (
          <Table>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <SectionHeader colSpan={5} content={LINKED_ORDER_DETAILS} />
              </TableRow>
            </TableHead>
            <TableBody>
              {line.linkedOrders.map((order, i) => {
                return linkedOrderRow(order, i, false);
              })}
              {line.exchangeOrders &&
                line.exchangeOrders.map((order, i) => {
                  return linkedOrderRow(order, i, true);
                })}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

ExpandedItem.propTypes = {
  line: PropTypes.shape({
    displaySize: PropTypes.string,
    colorDescription: PropTypes.string,
    exchangeOrders: PropTypes.arrayOf(PropTypes.shape({})),
    instructions: PropTypes.arrayOf(
      PropTypes.shape({
        detail: PropTypes.string,
        instructionType: PropTypes.string,
        sequenceNumber: PropTypes.number,
      })
    ),
    linkedOrders: PropTypes.arrayOf(PropTypes.shape({})),
    shipTo: PropTypes.shape({
      pickupPointAddress: PropTypes.shape({
        networkId: PropTypes.string,
        locationName: PropTypes.string,
      }),
    }),
    lineNumber: PropTypes.number,
    item: PropTypes.shape({
      universalProductCode: PropTypes.string,
    }),
    omoboFlags: PropTypes.shape({
      isPickUpPoint: PropTypes.bool,
      isBOPIS: PropTypes.bool,
      isShipToStore: PropTypes.bool,
      isPurchasedInStore: PropTypes.bool,
    }),
    orderLineType: PropTypes.string,
    styleNumber: PropTypes.string,
  }).isRequired,
  giftCardImage: PropTypes.string,
};

ExpandedItem.defaultProps = {
  giftCardImage: '',
};

const useStyles = makeStyles((theme) => ({
  addressCell: {
    borderBottom: 0,
    padding: 0,
    verticalAlign: 'top',
    whiteSpace: 'pre',
  },
  bomDetailsColumn: {
    borderBottom: 'none',
  },
  contactInfo: {
    whiteSpace: 'pre',
  },
  contactInfoCell: {
    borderBottom: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
    verticalAlign: 'top',
  },
  contactInfoLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  firstRow: {
    borderBottom: '1px solid #e0e0e0',
  },
  headerRow: {
    height: '30px',
  },
  orderDetailsGrid: {
    display: 'grid',
    gridGap: '2rem',
    gridTemplateAreas: `
      " IM PR SH "
      " IM BM BM "
      " RE RE RE "
      `,
    gridTemplateColumns: '155px 1fr 1fr',
    padding: '1.25em',
    width: '100%',
  },
  orderDetailsGridBOM: {
    gridArea: 'BM',
  },
  orderDetailsGridProductImage: {
    maxHeight: '100%',
    maxWidth: '164px',
  },
  orderDetailsGridProductImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gridArea: 'IM',
    paddingTop: '2px',
  },
  orderDetailsGridProductInfo: {
    gridArea: 'PR',
  },
  orderDetailsGridReturnInfo: {
    gridArea: 'RE',
  },
  orderDetailsGridShippingInfo: {
    gridArea: 'SH',
  },
  linkedOrderDetailsColumn: {
    borderBottom: 0,
    padding: 0,
  },
  productInformationTableRow: {
    height: '100%',
  },
  linkedOrderInformationTableRow: {
    height: '10px',
  },
  sectionHeader: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
  shortTableCell: {
    border: 0,
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  shortTableHeader: {
    border: 0,
    color: theme.palette.grey[700],
    fontSize: '0.8125rem',
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'pre',
  },
  storeInfo: {
    fontSize: 'inherit',
  },
}));
