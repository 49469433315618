/*
This file provides functions to determine the fulfillment scenario of a given orderDetail.

The functions in this file are used in the orderDetailDecoration file, to set omoboFlags on the
top-level orderDetail.  You will get better performance with the same result by using the flags
under the orderDetail.omoboFlags field.

Note two things:
1. In the orderDetailDecoration file, the omoboFlag object must be created on the orderDetail first
before calling these functions.
2. In the orderDetailDecoration file, these functions must be called AFTER setting the omoboFlags
for each orderLine with the functions in the orderLineFulfillmentScenarios file (as the flags for
the order are based on the flags for the orderLines).
 */

/**
 * This util function checks if an order is BOPIS by checking if
 * any of the line items match the criteria provided by the athena team.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isBOPISOrder = (orderDetail) => {
  if (orderDetail && orderDetail.orderLines) {
    return orderDetail.orderLines.some((orderLine) => {
      return orderLine.omoboFlags?.isBOPIS;
    });
  } else return false;
};

/**
 * This util function checks if an order is a "Ship To Store" order by checking if
 * any of the line items match the criteria provided by the athena team.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isShipToStoreOrder = (orderDetail) => {
  if (orderDetail && orderDetail.orderLines) {
    return orderDetail.orderLines.some((orderLine) => {
      return orderLine.omoboFlags?.isShipToStore;
    });
  } else return false;
};

/**
 * This util function checks if an order is Pick Up Point by checking if
 * any of the line items match the criteria provided by the athena team.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isPickUpPointOrder = (orderDetail) => {
  if (orderDetail && orderDetail.orderLines) {
    return orderDetail.orderLines.some((orderLine) => {
      return orderLine.omoboFlags?.isPickUpPoint;
    });
  } else return false;
};

/**
 * determines if a return order is related to a bopis order
 * @param {object} orderDetail
 * @return {boolean} true if child of bopis order false otherwise
 */
export const isBopisReturnOrder = (orderDetail) => {
  if (orderDetail?.csOrderSummary?.objects) {
    for (let object of orderDetail.csOrderSummary.objects) {
      if (object?.orderLines) {
        for (let orderLine of object.orderLines) {
          if (orderLine.fulfillmentMethod === 'PICKUP') {
            return true;
          }
        }
      }
    }
  }
  return false;
};

/**
 * Determines whether an order is a DSIS (Digital Sale in Store).
 * @param {object} orderDetail the details of a sale/return order.
 * @returns {boolean} true if the other is a DSIS order, false otherwise.
 */
export const isDSISOrder = (orderDetail) => {
  if (orderDetail?.orderClassification === 'STORE') {
    return orderDetail.orderLines.some(
      (ol) => ol.orderLineType === 'INLINE' && ol.fulfillmentMethod === 'SHIP'
    );
  }
  return false;
};
