/** React / Utils */
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

/** Local */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { FirstNameField, LastNameField, KanaFirstNameField, KanaLastNameField } from './nameFields';
import { DialogContext } from '../../store/contexts/dialogContext';
import Geo from '../../constants/geos.const';

/**
 * This is a reusable component for name and email
 *
 * @param {React.props} props – React props containing optional class name
 */
const NameAndEmailForm = ({
  isGiftCardAddress = false,
  className = '',
  region = region,
  customClasses,
  children,
  variant = 'standard',
}) => {
  const [, dialogDispatch] = useContext(DialogContext);

  const { i18nString } = useContext(NikeI18nContext);
  const classes = { ...useStyles(), ...customClasses };

  const { EMAIL } = mapValues(translations, i18nString);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const addressClassName = region === Geo.JAPAN ? classes.jpAddressGrid : classes.addressGrid;

  return (
    <div className={clsx(addressClassName, className)}>
      {children}
      {region === Geo.JAPAN ? (
        <>
          <LastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
          <FirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
          <KanaLastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
          <KanaFirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
        </>
      ) : (
        <>
          <FirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
          <LastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
        </>
      )}

      <FormControlLabel
        aria-label={EMAIL}
        className={classes.emailField}
        control={
          <TextField
            required
            onChange={handleChange('email')}
            aria-required={EMAIL}
            label={EMAIL}
            className={classes.wideField}
            data-testid={'input-email'}
            variant={variant}
          />
        }
      />
    </div>
  );
};

NameAndEmailForm.propTypes = {
  isGiftCardAddress: PropTypes.bool,
  className: PropTypes.string,
  region: PropTypes.string.isRequired,
  customClasses: PropTypes.object,
  children: PropTypes.node,
  variant: PropTypes.string,
};

export default NameAndEmailForm;

const useStyles = makeStyles((theme) => ({
  addressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
      " FN LN "
      " EM EM "
    `,
    gridGap: '1.5rem 1.5rem',
  },
  jpAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
        " LN FN "
        " KL KF "
        " EM EM "
      `,
    gridGap: '1.5rem 1.5rem',
  },
  firstNameField: {
    gridArea: 'FN',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  kanaLastNameField: {
    gridArea: 'KL',
    margin: 0,
  },
  kanaFirstNameField: {
    gridArea: 'KF',
    margin: 0,
  },
  emailField: {
    gridArea: 'EM',
    margin: 0,
  },
  wideField: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
}));
