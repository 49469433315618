import gql from 'graphql-tag';

// Mutation to resend email
const NOTIFICATION_RESEND_OMA_MUTATION = gql`
  mutation postEmailResendOMA($requestBody: NotificationResendRequestBody!, $requestId: String!) {
    notificationResendOMA(requestBody: $requestBody, requestId: $requestId) {
      send_id
      message
      errors {
        code
        message
      }
    }
  }
`;

export default NOTIFICATION_RESEND_OMA_MUTATION;
