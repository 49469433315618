/** React/Utils */
import React from 'react';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import Box from '@material-ui/core/Box';
import PaymentIcon from '@material-ui/icons/Payment';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import BasicTableRow from '../../../../shared/table/tableRow';
import { storePaymentServiceMethodslabels } from '../../../../../constants/paymentTypes.const';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import translations from '../paymentDetails.i18n';
import translations2 from './paymentMethodStatus.i18n';

import PaymentMods from './paymentMethodMods';

/**
 * Payment Types details component.
 * Provide it:
 * the payment - each payment comes with its own transactions (operationRequests),
 * credit card/gift card info (creditCardInfo/giftCardInfo),
 * and summary payment information (orderPayment)
 * children - table of the transactions for the payment
 */
export default function StoreOrdersPaymentDetails({
  cardClass,
  cellClass,
  children,
  index,
  orderNumber,
  orderType,
  parentReturnOrder,
  partner,
  payment,
  region,
  tableHeadingClass,
  updatePaymentDetails,
  isStoreOrder,
}) {
  const classes = useStyles({
    cardClass,
    cellClass,
    children,
    index,
    orderNumber,
    orderType,
    parentReturnOrder,
    partner,
    payment,
    region,
    tableHeadingClass,
    updatePaymentDetails,
    isStoreOrder,
  });

  const {
    ARIA_PAYMENT_SUMMARY,
    AUTHORIZED,
    CHARGED,
    DETAILS,
    PAYMENT_TYPE,
    REFUNDED,
    REQUESTED_AUTH,
    REQUESTED_CHARGE,
    MERCHANT_ID,
  } = useMemoTranslations(translations);
  const { i18nString } = React.useContext(NikeI18nContext);

  const { ACTIVE, ARIA_PAYMENT_DETAILS_2, SUSPENDED } = mapValues(translations2, i18nString);
  const {
    tenderUUID,
    bin,
    isReferenceRefundSupported,
    merchantAccount,
    originalAmount,
    truncatedAccountNumber,
    paymentMethod,
    remainingAmount,
    tenderType,
    recipient,
  } = payment;

  const summaryHeaders = [REQUESTED_AUTH, AUTHORIZED, REQUESTED_CHARGE, CHARGED, REFUNDED];
  // if the recipient is consumer, then it is a refund request and
  // hence only refunded amount is shown on the front end.
  const summaryValues =
    recipient === 'STORE'
      ? [
          originalAmount?.value,
          originalAmount?.value,
          originalAmount?.value,
          originalAmount?.value,
          originalAmount?.value - remainingAmount?.value,
        ]
      : [0, 0, 0, 0, originalAmount?.value];
  /**
   * This function checks the payment method type and returns the correct card header
   * with the applicable information. Extracted because this was cleaner than a bunch of nested
   * ternaries and can be modified to handle other payment methods in the future if necessary.
   * @returns typography react component with payment method information
   */
  const getPaymentMethodTitle = () => {
    const paymentMethodLabel = storePaymentServiceMethodslabels[tenderType];
    switch (paymentMethod) {
      case 'CreditDebit': {
        return (
          <Typography variant='h5' component='h2' className={classes.title}>
            {`${PAYMENT_TYPE}:
            ${payment?.subTenderType?.toUpperCase() + ' ' + paymentMethodLabel}`}
            <span
              className={classes.merchAccountDisplay}
              data-testid={`cc-merchant-acccount-${tenderUUID}`}>
              {merchantAccount ? `${MERCHANT_ID}:${merchantAccount}` : ''}
            </span>
          </Typography>
        );
      }
      default: {
        return (
          <Typography variant='h5' component='h2' className={classes.title}>
            {`${PAYMENT_TYPE}: ${paymentMethodLabel}`}
            <span
              className={classes.merchAccountDisplay}
              data-testid={`cc-merchant-acccount-${tenderUUID}`}>
              {merchantAccount ? `${MERCHANT_ID}:${merchantAccount}` : ''}
            </span>
          </Typography>
        );
      }
    }
  };

  const getAccountNumber = () => {
    switch (paymentMethod) {
      case 'GiftCard':
        return '***-***-****';

      default:
        return truncatedAccountNumber
          ? `**** **** **** ${truncatedAccountNumber}`
          : bin
          ? `${bin} ** ****`
          : '';
    }
  };
  const boldStyle = { fontWeight: 500 };

  return (
    <>
      <Box className={classes.titleContainer}>
        {/* payment method type and card info */}
        {getPaymentMethodTitle()}
      </Box>
      <Paper className={cardClass} data-testid={`payment-details-${tenderUUID}`}>
        <>
          <Divider />
          {/* payment method status section */}
          <Box className={classes.container}>
            <List aria-label={ARIA_PAYMENT_DETAILS_2} className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText
                  data-testid='payment-account-number'
                  primary={getAccountNumber()}
                  className={classes.listItemText}
                  primaryTypographyProps={paymentMethod !== 'GiftCard' ? { style: boldStyle } : {}}
                />
              </ListItem>
              <ListItem data-testid='pmt-status-label' className={classes.listItem}>
                <ListItemText
                  data-testid='pmt-status'
                  primary={
                    <Typography component='span' variant='subtitle1' className={classes.statusText}>
                      {isReferenceRefundSupported ? ACTIVE : SUSPENDED}
                    </Typography>
                  }
                  className={classes.listItemText}
                />
              </ListItem>
            </List>
            {isReferenceRefundSupported && (
              <PaymentMods
                index={index}
                isPaymentMethodActive={isReferenceRefundSupported}
                orderNumber={orderNumber}
                orderType={orderType}
                parentReturnOrder={parentReturnOrder}
                partner={partner}
                paymentId={tenderUUID}
                paymentMethod={paymentMethod}
                region={region}
                updatePaymentDetails={updatePaymentDetails}
                isStoreOrder={isStoreOrder}
                last4Digits={truncatedAccountNumber || bin}
              />
            )}
          </Box>
        </>
        <Divider />
        {/* transactions summary section */}
        <Typography
          aria-label={DETAILS.toLowerCase()}
          variant='subtitle1'
          component='h3'
          className={classes.cardSubHeading}>
          {DETAILS}
        </Typography>
        <Table aria-label={ARIA_PAYMENT_SUMMARY}>
          <TableBody>
            <BasicTableRow header data={summaryHeaders} cellClassName={tableHeadingClass} />
            <BasicTableRow
              data={summaryValues}
              cellClassName={cellClass}
              testId='transaction-table'
            />
          </TableBody>
        </Table>
        {/* transactions section */}
        {children}
      </Paper>
      {/* payment method details */}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  statusText: {
    lineHeight: 1.5,
    fontWeight: 500,
    color: (props) =>
      props.payment.isReferenceRefundSupported
        ? theme.palette.success.main
        : theme.palette.error.main,
  },
  accordionDetails: {
    padding: 0,
    margin: 0,
  },
  accordionExpandIcon: {
    padding: theme.spacing(1.2),
    paddingRight: 0,
    marginRight: theme.spacing(1.2),
  },
  accordionRoot: {
    '&.Mui-expanded': {
      marginTop: 0,
    },
    '&::before': {
      backgroundColor: 'unset',
    },
  },
  accordionSummary: {
    padding: 0,
    minHeight: 'unset',
  },
  accordionSummaryExpanded: {
    'padding': 0,
    'margin': 0,
    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
    '&.Mui-expanded': {
      'min-height': 'unset',
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  cardSubHeading: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: theme.spacing(1.25),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2.5)}px`,
  },
  list: {
    display: 'flex',
    margin: 0,
    // padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.25),
  },
  listItem: {
    minHeight: '1em',
    maxWidth: 'max-content',
    alignItems: 'flex-start',
    marginRight: theme.spacing(3.75),
    padding: 0,
  },
  listItemIcon: {
    minWidth: 'unset',
    marginRight: theme.spacing(1.25),
    color: theme.palette.text.primary,
  },
  listItemText: {
    color: theme.palette.text.primary,
    margin: 0,
  },
  listItemTextPrimary: {
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    paddingBottom: '0.1rem',
  },
  title: {
    margin: 0,
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.2,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  merchAccountDisplay: {
    float: 'right',
  },
}));
