/* React/Utils */
import React, { useState, useContext, useMemo } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import TabList from '@material-ui/lab/TabList';

/* Local */
import translations from './pidReview.i18n';
import PidSearchTable from './pidSearchTable';
import { PidReviewSearchContext } from '../../store/contexts/pidReviewSearchContext';

/**
 * This component is used to display pid review search results
 *
 * @param {object} props - react props object
 */
const PidSearchResults = () => {
  const [selectedTab, setSelectedTab] = useState('0');
  const classes = useStyles();

  const { i18nString } = useContext(NikeI18nContext);
  const { FOLLOW_UP, HELD, QUEUE } = mapValues(translations, i18nString);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [searchState] = useContext(PidReviewSearchContext);
  const { searchResults } = searchState;
  const reviewable = useMemo(
    () => searchResults?.data?.filter((res) => res.status === 'reviewable') ?? [],
    [searchResults]
  );
  const pendingFeedback = useMemo(
    () => searchResults?.data?.filter((res) => res.status === 'pendingFeedback') ?? [],
    [searchResults]
  );
  const held = useMemo(() => searchResults?.data?.filter((res) => res.status === 'held') ?? [], [
    searchResults,
  ]);

  return (
    <Paper className={classes.root}>
      <TabContext value={selectedTab}>
        <TabList
          onChange={handleTabChange}
          variant='fullWidth'
          indicatorColor='primary'
          className={classes.tabList}>
          <Tab label={`${QUEUE} (${reviewable.length})`} {...a11yProps('0')} />
          <Tab label={`${FOLLOW_UP} (${pendingFeedback.length})`} {...a11yProps('1')} />
          <Tab label={`${HELD} (${held.length})`} {...a11yProps('2')} />
        </TabList>
        <TabPanel value={`0`} className={classes.tabPanel}>
          <PidSearchTable searchResults={reviewable} status='reviewable' />
        </TabPanel>
        <TabPanel value={`1`} className={classes.tabPanel}>
          <PidSearchTable searchResults={pendingFeedback} status='pendingFeedback' />
        </TabPanel>
        <TabPanel value={`2`} className={classes.tabPanel}>
          <PidSearchTable searchResults={held} status='held' />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
  tabList: {
    position: 'sticky',
  },
  tabPanel: {
    'padding': `0`,
    'height': '800px',
    'overflowY': 'scroll',

    /* Chome, Safari, Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
  },
}));

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'value': index,
  };
}

export default PidSearchResults;
