/** React / Utils */
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Country } from '../../constants/country.const';

/** Local */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { FirstNameField, LastNameField, LocationNameField } from './nameFields';
import { PostOfficeOptions, RegionOptions } from '../../constants/address/militaryAddress.const';
import USStates from '../../constants/address/USStates.const';
import dialogActions from '../../store/actions/dialogActions';
import { DialogContext } from '../../store/contexts/dialogContext';
import { getOmoboFlags } from '../../utils/orderLine';

/**
 * This is a reusable component for US consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const USAddressForm = ({
  isGiftCardAddress = false,
  className = '',
  region = region,
  customClasses,
  children,
  variant = 'standard',
}) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  const classes = { ...useStyles(), ...customClasses };

  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const { isMilitaryAddress } = dialogState;
  const { isBOPIS, isShipToStore } = getOmoboFlags(dialogState.selectedLines);
  const address = isGiftCardAddress ? dialogState.gcShippingAddress : dialogState.address;
  const { locationName, address1, address2, city, state, postalCode, country } = address;
  const dayPhoneNumber =
    address.dayPhoneNumber ||
    dialogState.gcShippingAddress?.dayPhoneNumber ||
    dialogState.address?.dayPhoneNumber;
  const { ADDRESS_1, ADDRESS_2, REGION, APO_FPO, CITY, STATE, ZIP_CODE, PHONE_NUMBER } = mapValues(
    translations,
    i18nString
  );

  /**
   * This use effect is only intended to execute once, when the component loads, to ensure the
   * address country field is 'US'.
   */
  useEffect(() => {
    if (country?.toUpperCase() !== Country.US) {
      dialogDispatch(
        isGiftCardAddress
          ? setGCShippingAddressField('country', Country.US)
          : setAddressField('country', Country.US)
      );
    }
  }, []);

  // Updating the address and gcShippingAddress (billing address) phone numbers
  useEffect(() => {
    if (dayPhoneNumber && !dialogState.address?.dayPhoneNumber) {
      dialogDispatch(setAddressField('dayPhoneNumber', dayPhoneNumber));
    } else if (dayPhoneNumber && !dialogState.gcShippingAddress?.dayPhoneNumber) {
      dialogDispatch(setGCShippingAddressField('dayPhoneNumber', dayPhoneNumber));
    }
  }, [dayPhoneNumber]);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const isStoreOrPickupAddress = (isBOPIS || isShipToStore) && !isGiftCardAddress;

  return (
    <div className={clsx(classes.addressGrid, className)}>
      {children}
      {locationName ? (
        <LocationNameField
          classes={classes}
          isGiftCardAddress={isGiftCardAddress}
          required={true}
        />
      ) : (
        <>
          <FirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
          <LastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            variant={variant}
          />
        </>
      )}
      <FormControlLabel
        aria-label={ADDRESS_1}
        className={classes.address1}
        control={
          <TextField
            required
            value={address1}
            onChange={handleChange('address1')}
            label={ADDRESS_1}
            className={classes.wideField}
            variant={variant}
            data-testid={'us-address-1'}
          />
        }
      />
      <FormControlLabel
        aria-label={ADDRESS_2}
        className={classes.address2}
        control={
          <TextField
            value={address2 || undefined}
            onChange={handleChange('address2')}
            label={ADDRESS_2}
            aria-labelledby={ADDRESS_2}
            className={classes.wideField}
            variant={variant}
            data-testid={'us-address-2'}
          />
        }
      />
      {isMilitaryAddress ? (
        <>
          <FormControl className={classes.city} required>
            <InputLabel id='chooseCity' shrink>
              {APO_FPO}
            </InputLabel>
            <Select
              value={city}
              onChange={handleChange('city')}
              aria-labelledby='chooseCity'
              labelId='chooseCity'>
              <MenuItem data-key='city' disabled>
                —
              </MenuItem>
              {PostOfficeOptions.map((postOfficeOption, key) => (
                <MenuItem data-key='city' value={postOfficeOption.value} key={key}>
                  {postOfficeOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.state} required>
            <InputLabel id='chooseRegion' shrink>
              {REGION}
            </InputLabel>
            <Select
              value={state}
              onChange={handleChange('state')}
              aria-labelledby='chooseRegion'
              labelId='chooseRegion'>
              <MenuItem disabled>—</MenuItem>
              {RegionOptions.map((region, key) => (
                <MenuItem value={region.value} key={key}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : (
        <>
          <FormControlLabel
            aria-label={CITY}
            className={classes.city}
            control={
              <TextField
                required
                value={city}
                onChange={handleChange('city')}
                label={CITY}
                className={classes.wideField}
                variant={variant}
                data-testid={'us-city'}
              />
            }
          />
          <TextField
            select
            required
            variant={variant}
            data-testid={'us-state'}
            value={state}
            label={STATE}
            onChange={handleChange('state')}
            aria-labelledby='chooseState'
            className={clsx(classes.state, classes.wideField)}
            aria-label={STATE}>
            {USStates.map((state, key) => (
              <MenuItem data-key='state' value={state.abbreviation} key={key}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      <FormControlLabel
        aria-label={ZIP_CODE}
        className={classes.zipCode}
        control={
          <TextField
            required
            value={postalCode}
            onChange={handleChange('postalCode')}
            label={ZIP_CODE}
            className={classes.wideField}
            variant={variant}
            data-testid={'us-zip-code'}
          />
        }
      />
      {!isStoreOrPickupAddress && (
        <InputMask
          mask='999-999-9999'
          value={dayPhoneNumber}
          onChange={handleChange('dayPhoneNumber')}>
          {() => (
            <FormControlLabel
              aria-label={PHONE_NUMBER}
              className={classes.phoneField}
              control={
                <TextField
                  required
                  aria-required={true}
                  inputProps={{ 'data-key': 'dayPhoneNumber' }}
                  label={PHONE_NUMBER}
                  className={classes.wideField}
                  variant={variant}
                  data-testid={'us-phone-number'}
                />
              }
            />
          )}
        </InputMask>
      )}
    </div>
  );
};

export default USAddressForm;

const useStyles = makeStyles((theme) => ({
  addressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " FN FN FN LN LN LN "
      " A1 A1 A1 A1 A1 A1 "
      " A2 A2 A2 A2 A2 A2 "
      " CI CI ST ST ZI ZI "
      " PH PH .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  firstNameField: {
    gridArea: 'FN',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  locationNameField: {
    gridArea: 'LO',
    margin: 0,
  },
  address1: {
    gridArea: 'A1',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    margin: 0,
  },
  state: {
    gridArea: 'ST',
    margin: 0,
  },
  zipCode: {
    gridArea: 'ZI',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
}));
