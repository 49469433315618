/** React / Utils */
import React, { useContext } from 'react';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

/** Local */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { DialogContext } from '../../store/contexts/dialogContext';
import { getOmoboFlags } from '../../utils/orderLine';

/**
 * FormControlLabel wrapper for the first name field on an Address Form.
 *
 * @param props {object} - The properties of the FirstNameField
 * @returns {JSX.Element}
 * @constructor
 */
export const FirstNameField = ({
  isGiftCardAddress,
  variant = 'standard',
  required,
  disabled,
  classes,
}) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { FIRST_NAME } = mapValues(translations, i18nString);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { address, gcShippingAddress } = dialogState;
  const firstName = isGiftCardAddress ? gcShippingAddress.firstName : address.firstName;
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <FormControlLabel
      aria-label={FIRST_NAME}
      className={classes.firstNameField}
      control={
        <TextField
          required={required}
          disabled={disabled}
          label={FIRST_NAME}
          defaultValue={firstName}
          variant={variant}
          inputProps={{ 'data-key': 'firstName' }}
          className={classes.wideField}
          onChange={handleChange('firstName')}
          aria-required={required}
          data-testid={'input-first-name'}
        />
      }
    />
  );
};

/**
 * FormControlLabel wrapper for the last name field on an Address Form.
 *
 * @param props {object} - The properties of the LastNameField
 * @returns {JSX.Element}
 * @constructor
 */
export const LastNameField = ({
  isGiftCardAddress,
  variant = 'standard',
  required,
  disabled,
  classes,
}) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { LAST_NAME } = mapValues(translations, i18nString);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { address, gcShippingAddress } = dialogState;
  const lastName = isGiftCardAddress ? gcShippingAddress.lastName : address.lastName;
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <FormControlLabel
      aria-label={LAST_NAME}
      className={classes.lastNameField}
      control={
        <TextField
          variant={variant}
          required={required}
          disabled={disabled}
          label={LAST_NAME}
          defaultValue={lastName}
          inputProps={{ 'data-key': 'lastName' }}
          className={classes.wideField}
          onChange={handleChange('lastName')}
          aria-required={required}
          data-testid={'input-last-name'}
        />
      }
    />
  );
};

/**
 * FormControlLabel wrapper for the kana first name field on an Address Form.
 *
 * @param props {object} - The properties of the KanaFirstNameField
 * @returns {JSX.Element}
 * @constructor
 */
export const KanaFirstNameField = (props) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { KANA_FIRST_NAME } = mapValues(translations, i18nString);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { address, gcShippingAddress } = dialogState;
  const alternateFirstName = props.isGiftCardAddress
    ? gcShippingAddress.alternateFirstName
    : address.alternateFirstName;
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, props.isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <FormControlLabel
      aria-label={KANA_FIRST_NAME}
      className={props.classes.kanaFirstNameField}
      control={
        <TextField
          variant={props.variant}
          required={props.required}
          disabled={props.disabled}
          label={KANA_FIRST_NAME}
          defaultValue={alternateFirstName}
          inputProps={{ 'data-key': 'kanaFirstName' }}
          className={props.classes.wideField}
          onChange={handleChange('alternateFirstName')}
          aria-required={props.required}
          data-testid={'input-kana-first-name'}
        />
      }
    />
  );
};

/**
 * FormControlLabel wrapper for the kana last name field on an Address Form.
 *
 * @param props {object} - The properties of the KanaLastNameField
 * @returns {JSX.Element}
 * @constructor
 */
export const KanaLastNameField = (props) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { KANA_LAST_NAME } = mapValues(translations, i18nString);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { address, gcShippingAddress } = dialogState;
  const alternateLastName = props.isGiftCardAddress
    ? gcShippingAddress.alternateLastName
    : address.alternateLastName;
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, props.isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <FormControlLabel
      aria-label={KANA_LAST_NAME}
      className={props.classes.kanaLastNameField}
      control={
        <TextField
          variant={props.variant}
          required={props.required}
          disabled={props.disabled}
          label={KANA_LAST_NAME}
          defaultValue={alternateLastName}
          inputProps={{ 'data-key': 'kanaLastName' }}
          className={props.classes.wideField}
          onChange={handleChange('alternateLastName')}
          aria-required={props.required}
          data-testid={'input-kana-last-name'}
        />
      }
    />
  );
};

/**
 * FormControlLabel wrapper for the location name field on an Address Form.
 *
 * @param props {object} - The properties of the LocationNameField
 * @returns {JSX.Element}
 * @constructor
 */
export const LocationNameField = (props) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { STORE_NAME, PICKUP_POINT_NAME } = mapValues(translations, i18nString);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { address, gcShippingAddress } = dialogState;
  const locationName = props.isGiftCardAddress
    ? gcShippingAddress.locationName
    : address.locationName;
  const { isPickUpPoint } = getOmoboFlags(dialogState.selectedLines);
  const label = isPickUpPoint ? PICKUP_POINT_NAME : STORE_NAME;
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, props.isGiftCardAddress, event, dialogDispatch);
  };

  return (
    <FormControlLabel
      aria-label={label}
      className={props.classes.locationNameField}
      control={
        <TextField
          required={props.required}
          disabled={props.disabled}
          label={label}
          defaultValue={locationName}
          inputProps={{ 'data-key': 'locationName' }}
          className={props.classes.wideField}
          onChange={handleChange('locationName')}
          aria-required={props.required}
          data-testid={'input-location-name'}
        />
      }
    />
  );
};
