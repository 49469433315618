import React, { useContext, useState, useEffect } from 'react';
import mapValues from 'lodash/mapValues';
import { useLazyQuery } from 'react-apollo';
import { NikeI18nContext } from '@nike/i18n-react';
import { useLocation } from 'react-router-dom';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

/** Local */
import ErrorBoundary from '../../../error/errorBoundary';
import translations from './efapiao.i18n';
import ORDER_INVOICE_QUERY from '../../../../queries/orderInvoice.query';
import useSnacks from '../../../../hooks/useSnacks';
import EFapiaosTable from './efapiaosTable';
import IssueEfapiao from './issueEfapiao';
import { getAlleFapiaos } from '../../../../utils/efapiao';

/**
 * Tab on order details page that contains efapiao information
 */
const Efapiao = ({ paramOrderId }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();
  const location = useLocation();
  const { setError } = useSnacks();
  const {
    EFAPIAO,
    ORDER_INVOICE_ERROR,
    ARIA_INVOICE_LOADING,
    STYLE_NAME,
    STYLE_COLOR,
    SIZE,
    QUANTITY,
    FAPIAO_NUMBER,
    DATE_ISSUED,
    FAPIAO_AMOUNT,
    STATUS,
    NO_EFAPIAO_DATA,
    WHY_NO_EFAPIAO_MESSAGE,
    EFAPIAO_HISTORY,
    ITEMS,
    EFAPIAOS,
  } = mapValues(translations, i18nString);

  const itemsHeaders = [STYLE_NAME, STYLE_COLOR, SIZE, QUANTITY];
  const efapiaoHeaders = [FAPIAO_NUMBER, DATE_ISSUED, FAPIAO_AMOUNT, STATUS];

  const [orderInvoices, setOrderInvoices] = useState([]);
  const [noEfapiaoMessage, setNoEfapiaoMessage] = useState('');

  const [queryOrderInvoice, { data, loading, error }] = useLazyQuery(ORDER_INVOICE_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setOrderInvoices({});
      if (error?.message?.includes('404')) {
        setNoEfapiaoMessage(WHY_NO_EFAPIAO_MESSAGE);
      } else {
        setError(`${ORDER_INVOICE_ERROR}: ${error.message}`);
      }
    },
    onCompleted: () => {
      const haseFapiao = data?.orderInvoice?.objects?.[0]?.vatReceipts;
      if (haseFapiao) {
        // gets all vatReceipts objects from invoice response and set array of all to state
        setOrderInvoices(getAlleFapiaos(data.orderInvoice.objects));
      } else {
        setNoEfapiaoMessage(WHY_NO_EFAPIAO_MESSAGE);
      }
    },
  });

  useEffect(() => {
    if (location.pathname.includes('/efapiao') && !orderInvoices.length) {
      queryOrderInvoice({
        variables: { orderNumber: paramOrderId },
      });
    }
  }, [location.pathname]);

  return (
    <ErrorBoundary>
      <Card className={classes.cardSpacing} elevation={3}>
        <CardContent className={classes.cardContent}>
          <CardHeader
            data-testid='efapiao-card-header'
            classes={{ root: classes.cardHeaderRoot }}
            title={<h2 className={classes.cardHeading}>{EFAPIAO}</h2>}
          />

          {loading ? (
            <CircularProgress aria-label={ARIA_INVOICE_LOADING} />
          ) : (
            <>
              {Boolean(orderInvoices.length) ? (
                <Box data-testid='efapiao-card-content'>
                  <IssueEfapiao
                    orderInvoices={orderInvoices}
                    orderNumber={paramOrderId}
                    queryOrderInvoice={queryOrderInvoice}
                  />

                  <Typography variant='h3' className={classes.cardSubHeading}>
                    {EFAPIAO_HISTORY}
                  </Typography>
                  {Array.isArray(orderInvoices) &&
                    orderInvoices.map((efapiao, i) => (
                      <EFapiaosTable
                        key={i}
                        itemsHeaders={itemsHeaders}
                        efapiaoHeaders={efapiaoHeaders}
                        efapiao={efapiao}
                        itemsLabel={ITEMS}
                        efapiaosLabel={EFAPIAOS}
                      />
                    ))}
                </Box>
              ) : (
                <>
                  <Typography data-testid='no-efapiao-data'>{NO_EFAPIAO_DATA}</Typography>
                  <Typography data-testid='why-no-efapiao'>{noEfapiaoMessage}</Typography>
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardContent: {
    overflow: 'auto',
  },
  cardHeaderRoot: {
    paddingLeft: theme.spacing(1),
  },
  cardHeading: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  cardSubHeading: {
    fontSize: '1rem',
    padding: `10px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
  },
}));

export default Efapiao;
