import React, { useContext, Fragment } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import mapValues from 'lodash/mapValues';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import translations from './charges.i18n';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import {
  getDiscount,
  getDiscounts,
  getTax,
  getVatPrice,
  getCharges,
  getTaxDiscounts,
} from '../../../../utils/order';
import DetailsTableRow from '../../../shared/table/detailsTableRow';
import PropTypes from 'prop-types';

/**
 * Returns charge details table for both regular and VAS items
 *
 * @param {object} line - order line to display charges for
 */

export default function ItemCharges({ line }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState] = useContext(DialogContext);
  const { isMilitaryAddress } = dialogState;
  const { currency } = orderDetail;

  const {
    ATHLETE,
    ITEM_DISCOUNT_TOTAL,
    EMPLOYEE_DISCOUNT,
    ITEM_DISCOUNT,
    ITEM_TAX,
    LINE_DISCOUNT,
    UNIT_PRICE,
    UNIT_PRICE_TOTAL,
    MANUAL_DISCOUNT,
    MILITARY_DISCOUNT,
    PROMO_CODE,
    QUANTITY,
    REASON,
    SALE_DISCOUNT,
    SUBTOTAL,
    TAX_DISCOUNT,
    TAX_CODE,
    TAX_PRICE,
    TAX_RATE,
    TIG_TAX_CODE,
    VAS_DISCOUNT,
    VAS_PAYMENT_DETAILS,
    VAT_PRICE,
  } = mapValues(translations, i18nString);

  function getUnitPrice(line) {
    return line.linePriceInformation && line.linePriceInformation.unitPrice;
  }

  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }

  /**
   * Takes an array of discounts and both adds human readable labels, and categorizes
   * based on business definitions
   *
   * @param {array} discounts - array of discounts
   * @param {boolean} isVAS - whether or not the discounts apply to a VAS line
   * @returns {array, array, array, array}
   */
  function categorizeDiscounts(discounts = [], isVAS) {
    const lineLevelDiscounts = [];
    const promoDiscounts = [];
    const manualDiscounts = [];
    const vasDiscounts = [];

    discounts.forEach((discount) => {
      if (isVAS) {
        // VAS discount
        discount.humanCategory = VAS_DISCOUNT;
        vasDiscounts.push(discount);
      } else if (discount.chargeCategory === 'PROMO') {
        // Promo Code
        discount.humanCategory = PROMO_CODE;
        promoDiscounts.push(discount);
      } else if (discount.agentReference) {
        // Manual Discount
        discount.humanCategory = MANUAL_DISCOUNT;
        manualDiscounts.push(discount);
      } else {
        // Line Level discounts
        switch (discount.chargeCategory) {
          case 'EMPLOYEE':
            discount.humanCategory = EMPLOYEE_DISCOUNT;
            break;
          case 'ITEM':
            discount.humanCategory = ITEM_DISCOUNT;
            break;
          case 'PRICE':
            discount.humanCategory = SALE_DISCOUNT;
            break;
          default:
            discount.humanCategory = LINE_DISCOUNT;
        }

        if (discount.additionalInformation === 'Military Discount') {
          discount.humanCategory = MILITARY_DISCOUNT;
        }

        lineLevelDiscounts.push(discount);
      }
    });
    return { lineLevelDiscounts, promoDiscounts, manualDiscounts, vasDiscounts };
  }

  const chargeDetailsTable = (line, isVAS = false, i = 0) => {
    const unitPrice = getUnitPrice(line);
    const unitPriceTotal = unitPrice * line.quantity;
    const totalItemDiscount = getDiscount(line);
    const itemDiscounts = getDiscounts(line) || [];
    const militaryDiscount = isMilitaryAddress ? getDiscount(line) : 0;
    const charges = getCharges(line);
    const taxPrice = getTax(line);
    const vatPrice = getVatPrice(line);
    const taxDiscounts = getTaxDiscounts(line) || [];
    const itemTax = taxPrice - taxDiscounts;
    const taxRate =
      unitPriceTotal === 0
        ? (0).toFixed(2) + '%'
        : ((taxPrice / unitPriceTotal) * 100).toFixed(2) + '%';
    const subtotal = unitPriceTotal - totalItemDiscount - militaryDiscount + charges;
    const vasSubtotal = unitPriceTotal - totalItemDiscount - militaryDiscount;

    const {
      lineLevelDiscounts,
      promoDiscounts,
      manualDiscounts,
      vasDiscounts,
    } = categorizeDiscounts(itemDiscounts, isVAS);

    return (
      <Table key={i}>
        <TableBody>
          {isVAS && (
            <TableRow className={classes.headerRow}>
              <TableCell component='th' scope='row' colSpan={4} className={classes.sectionHeader}>
                {`${VAS_PAYMENT_DETAILS} (${line.item && line.item.itemDescription})`}
              </TableCell>
            </TableRow>
          )}

          <TableRow className={classes.chargeDetails}>
            <TableCell className={classes.chargeDetailsColumn}>
              <Table>
                <TableBody>
                  <DetailsTableRow
                    header
                    label={UNIT_PRICE_TOTAL}
                    data-testid={'unit-price-total'}
                    value={formatCurrency(unitPriceTotal)}
                  />
                  <DetailsTableRow label={UNIT_PRICE} value={formatCurrency(unitPrice)} />

                  <DetailsTableRow label={QUANTITY} value={line.quantity} />
                </TableBody>
              </Table>
            </TableCell>

            <TableCell className={classes.chargeDetailsColumn}>
              <Table>
                <TableBody>
                  <DetailsTableRow
                    header
                    label={ITEM_DISCOUNT_TOTAL}
                    data-testid={'discount-total'}
                    value={
                      <FormattedCurrency
                        amount={militaryDiscount + totalItemDiscount}
                        currency={currency}
                      />
                    }
                  />

                  {Boolean(lineLevelDiscounts.length) &&
                    lineLevelDiscounts.map((discount, i) => (
                      <Fragment key={i}>
                        <DetailsTableRow
                          row
                          label={discount.humanCategory}
                          data-testid={'line-level-discount'}
                          value={formatCurrency(discount.chargePerQuantity)}
                        />
                        {discount.chargeName && (
                          <DetailsTableRow
                            row
                            indented
                            data-testid={'promo-code'}
                            label={
                              discount.additionalInformation
                                ? `${discount.chargeName} ${discount.additionalInformation}`
                                : discount.chargeName
                            }
                          />
                        )}
                      </Fragment>
                    ))}

                  {Boolean(promoDiscounts.length) &&
                    promoDiscounts.map((discount, i) => (
                      <Fragment key={i}>
                        <DetailsTableRow
                          row
                          label={discount.humanCategory}
                          data-testid={'promo-discount'}
                          value={formatCurrency(discount.chargePerQuantity)}
                        />
                        <DetailsTableRow
                          row
                          indented
                          data-testid={'promo-code'}
                          label={
                            discount.additionalInformation
                              ? `${discount.chargeName} ${
                                  discount.additionalInformation
                                } ${orderDetail.couponCodes.reduce(
                                  (accu, code) => accu + ' ' + code,
                                  ''
                                )}`
                              : discount.chargeName
                          }
                        />
                      </Fragment>
                    ))}

                  {Boolean(manualDiscounts.length) &&
                    manualDiscounts.map((discount, i) => (
                      <Fragment key={i}>
                        <DetailsTableRow
                          row
                          label={discount.humanCategory}
                          data-testid={'manual-discount'}
                          value={formatCurrency(discount.chargePerQuantity)}
                        />
                        <DetailsTableRow
                          row
                          indented
                          data-testid={'manual-discount-agent'}
                          label={
                            <>
                              <span className={classes.label}>{ATHLETE}:</span>
                              {discount.agentReference || '--'}
                            </>
                          }
                        />
                        <DetailsTableRow
                          row
                          indented
                          data-testid={'manual-discount-reason'}
                          label={
                            <>
                              <span className={classes.label}>{REASON}:</span>
                              {discount.additionalInformation || '--'}
                            </>
                          }
                        />
                      </Fragment>
                    ))}

                  {isVAS &&
                    Boolean(vasDiscounts.length) &&
                    vasDiscounts.map((discount, i) => (
                      <Fragment key={i}>
                        <DetailsTableRow
                          row
                          label={discount.humanCategory}
                          data-testid={'vas-discount'}
                          value={formatCurrency(discount.chargePerQuantity)}
                        />
                        <DetailsTableRow
                          row
                          indented
                          data-testid={'vas-discount-agent'}
                          label={
                            <>
                              <span className={classes.label}>{ATHLETE}:</span>
                              {discount.agentReference || 'Unknown'}
                            </>
                          }
                        />
                        <DetailsTableRow
                          row
                          indented
                          data-testid={'vas-discount-reason'}
                          label={
                            <>
                              <span className={classes.label}>{REASON}:</span>
                              {discount.additionalInformation || 'Unknown'}
                            </>
                          }
                        />
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableCell>

            <TableCell className={classes.chargeDetailsColumn}>
              <Table>
                <TableBody>
                  <DetailsTableRow
                    header
                    label={SUBTOTAL}
                    data-testid={'subtotal'}
                    value={formatCurrency(isVAS ? vasSubtotal : subtotal)}
                  />
                </TableBody>
              </Table>
            </TableCell>

            <TableCell className={classes.chargeDetailsColumn}>
              <Table>
                <TableBody>
                  <DetailsTableRow
                    header
                    data-testid={'item-tax'}
                    label={ITEM_TAX}
                    value={formatCurrency(itemTax)}
                  />

                  <DetailsTableRow label={TAX_PRICE} value={formatCurrency(taxPrice)} />

                  {/* Displays Value Added Tax Price if region is EMEA */}
                  {Boolean(orderDetail.omsRegionReference === 'NIKEEUROPE') && (
                    <DetailsTableRow
                      label={VAT_PRICE}
                      value={formatCurrency(vatPrice)}
                      data-testid={'vat-price'}
                    />
                  )}

                  <DetailsTableRow label={TAX_DISCOUNT} value={formatCurrency(taxDiscounts)} />

                  <DetailsTableRow label={TAX_RATE} value={taxRate} data-testid={'tax-rate'} />

                  <DetailsTableRow
                    data-testid={'code-tax'}
                    label={TAX_CODE}
                    value={line.productId}
                  />

                  <DetailsTableRow
                    data-testid={'tig-tax'}
                    label={TIG_TAX_CODE}
                    value={line.commodityCode}
                  />
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      {/* line charges details */}
      {chargeDetailsTable(line)}
      {/* vas line charges details */}
      <div className={classes.orderDetailsGridVASPaymentInfo}>
        {line.nestedVasOrderLines?.map((nestedVasOrderLine, index) => {
          return chargeDetailsTable(nestedVasOrderLine, true, index);
        })}
      </div>
    </>
  );
}

ItemCharges.propTypes = {
  line: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  chargeDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
  },
  chargeDetailsColumn: {
    borderBottom: 0,
    padding: theme.spacing(2),
  },
  indentedCharge: {
    display: 'block',
    border: 'none',
    borderBottom: 'none',
    color: theme.palette.grey[700],
    fontSize: '0.8125rem',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(5),
    whiteSpace: 'pre',
  },
  label: {
    color: theme.palette.grey[900],
    paddingRight: theme.spacing(1),
  },
  sectionHeader: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));
