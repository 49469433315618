import React from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

import Box from '@material-ui/core/Box';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

import translations from './simpleDialog.i18n';

const SimpleModalDialog = ({
  cancelLabel,
  confirmOptions,
  content,
  isOpen,
  setIsOpen,
  testId,
  title,
  bottomText,
  manualClose,
}) => {
  const { i18nString } = React.useContext(NikeI18nContext);
  const { CLOSE, CONFIRM } = mapValues(translations, i18nString);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    confirmOptions.action();
    !manualClose && setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      onClose={() => setIsOpen(false)}
      classes={{ paper: classes.dialog }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      data-testid={testId}>
      <Box className={classes.dialogHeader}>
        <DialogTitle id='alert-dialog-title' className={classes.title}>
          {title}
        </DialogTitle>
        <IconButton className={classes.closeIcon} onClick={() => handleCancel()} disableRipple>
          <CloseOutlinedIcon color='inherit' />
        </IconButton>
      </Box>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {typeof content === 'string' ? (
          <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions disableSpacing={true}>
        {bottomText && <Typography className={classes.bottomText}>{bottomText}</Typography>}
        <Button
          className={classes.dialogButton}
          onClick={handleCancel}
          color='primary'
          variant='outlined'
          data-testid='close-dialog-button'>
          {cancelLabel || CLOSE}
        </Button>
        <Button
          className={classes.dialogButton}
          disabled={confirmOptions.disabled}
          onClick={handleSubmit}
          color='primary'
          variant='contained'
          data-testid='confirm-dialog-button'>
          {confirmOptions.label || CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimpleModalDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmOptions: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string,
  bottomText: PropTypes.string,
  manualClose: PropTypes.bool,
};

SimpleModalDialog.defaultProps = {
  cancelOptions: {
    label: '',
    action: () => null,
  },
  confirmOptions: {
    label: '',
    action: () => null,
    disabled: false,
  },
  testId: 'simple-dialog-modal',
  title: '',
  bottomText: '',
  manualClose: false,
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
    boxShadow: '-1px 4px 10px rgba(0, 0, 0, 0.05)',
    paddingTop: theme.spacing(6.5),
    paddingBottom: theme.spacing(5.75),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    minWidth: '660px',
    width: '66%',
    maxWidth: 'none',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  closeIcon: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    padding: theme.spacing(0.75),
    color: theme.palette.text.primary,
  },
  title: {
    padding: 0,
  },
  dialogContent: {
    padding: 0,
    marginBottom: theme.spacing(3.75),
  },
  dialogButton: {
    borderRadius: '24px',
    fontSize: '1rem',
    lineHeight: 1.5,
    textTransform: 'unset',
    padding: `${theme.spacing(0.875)}px ${theme.spacing(2.5)}px`,
    marginLeft: theme.spacing(2),
  },
  bottomText: {
    fontSize: '0.825rem',
    marginLeft: '3rem',
  },
}));

export default SimpleModalDialog;
