import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';

import translations from '../step4.i18n';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';

/**
 *
 * @param {string} shippingOption - currently selected shipping option
 * @param {function} onChange - a function called every time a radio button is selected
 */
const PickupNonPickupRadioButtons = ({ shippingOption, onChange }) => {
  const { PICKUP, NON_PICKUP, ARIA_CARRIER } = useMemoTranslations(translations);
  const classes = useStyles();

  return (
    <FormControl
      className={classes.shippingOptions}
      legend='carrier'
      aria-label={ARIA_CARRIER}
      component='fieldset'>
      <RadioGroup value={shippingOption} data-testid='carrierRadioGroup' onChange={onChange} row>
        <FormControlLabel
          data-testid='nonPickUpRadioButton'
          label={NON_PICKUP}
          value='nonPickup'
          control={<Radio color='primary' />}
        />
        <FormControlLabel
          data-testid='pickUpRadioButton'
          label={PICKUP}
          value='pickup'
          control={<Radio color='primary' />}
        />
      </RadioGroup>
    </FormControl>
  );
};

PickupNonPickupRadioButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  shippingOption: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  shippingOptions: {
    gridArea: 'SO',
  },
}));

export default PickupNonPickupRadioButtons;
