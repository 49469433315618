import { formatDate, formatLocale } from './date';

/**
 * Takes an sms data object returned by historical API
 * and returns an array of values in the order matching
 * the table columns in Communications Table
 *
 * @param {object} sms
 * @param {string} resendString Translated string for "Resend"
 *
 * @returns {array} [
 * Date,
 * Sent To,
 * Category,
 * Type
 * ]
 */
export const transformSmsData = (sms = {}, resendString = 'Resend') => {
  const classOrMessage =
    sms.notification_class === 'none'
      ? sms.communication_body?.slice(0, 32) + '...'
      : sms.notification_class;
  return [
    formatDate(sms.creation_date, formatLocale(sms.locale_language)),
    sms.user_phone_number,
    classOrMessage,
    sms.is_resend === 'N' ? '' : resendString,
  ];
};
