const translations = {
  EFAPIAO: {
    description: 'label for eFapiao details page',
    primaryValue: 'eFapiao',
    stringKey: 'efapiao',
  },
  ORDER_INVOICE_ERROR: {
    description: 'label for eFapiao error notification',
    primaryValue: 'Error fetching eFapiao',
    stringKey: 'efapiaoError',
  },
  ARIA_INVOICE_LOADING: {
    description: 'ARIA label for eFapiao details loading icon',
    primaryValue: 'efapiao info loading',
    stringKey: 'ariaEfapiaoLoading',
  },
  ITEMS: {
    description: 'label for eFapiao page items',
    primaryValue: 'Items',
    stringKey: 'items',
  },
  STYLE_NAME: {
    description: 'label on eFapio page for "style name"',
    primaryValue: 'Style Name',
    stringKey: 'styleName',
  },
  STYLE_COLOR: {
    description: 'label on eFapio page for "style color"',
    primaryValue: 'Style-Color',
    stringKey: 'styleColor',
  },
  SIZE: {
    description: 'label on eFapio page for "size"',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  QUANTITY: {
    description: 'label on eFapio page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  FAPIAO_TYPE: {
    description: 'label for Fapiao type (Personal / Business)',
    primaryValue: 'Fapiao Type',
    stringKey: 'fapiaoType',
  },
  FAPIAO_TYPE_PERSONAL: {
    description: 'label for Fapiao type option (Personal)',
    primaryValue: 'Personal',
    stringKey: 'fapiaoTypePersonal',
  },
  FAPIAO_TYPE_BUSINESS: {
    description: 'label for Fapiao type option (Business)',
    primaryValue: 'Business',
    stringKey: 'fapiaoTypeBusiness',
  },
  FAPIAO_TITLE: {
    description: 'label for Fapiao title',
    primaryValue: 'Fapiao Title',
    stringKey: 'fapiaoTitle',
  },
  TAX_ID: {
    description: 'label on eFapio page for Tax ID',
    primaryValue: 'Tax ID',
    stringKey: 'taxID',
  },
  FAPIAO_NUMBER: {
    description: 'label for Fapiao number',
    primaryValue: 'Fapiao Number',
    stringKey: 'fapiaoNumber',
  },
  DATE_ISSUED: {
    description: 'label on eFapio page for Date Issued',
    primaryValue: 'Date Issued',
    stringKey: 'dateIssued',
  },
  FAPIAO_AMOUNT: {
    description: 'label for Fapiao amount',
    primaryValue: 'Fapiao Amount',
    stringKey: 'fapiaoAmount',
  },
  STATUS: {
    description: 'label for Fapiao Status',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  ISSUE_EFAPIAO: {
    description: 'label for "Issue Efapiao" button',
    primaryValue: 'Issue Efapiao',
    stringKey: 'issueEfapiao',
  },
  ISSUE_EFAPIAO_ERROR: {
    description: 'Error message on the efapiao page for issue efapiao API call',
    primaryValue: 'Error issuing eFapiao. Please try again.',
    stringKey: 'issueEfapiaoError',
  },
  ISSUE_EFAPIAO_SUCCESS: {
    description: 'Success message on the efapiao page for issue efapiao API call',
    primaryValue: 'eFapiao issued successfully!',
    stringKey: 'issueEfapiaoSuccess',
  },
  NO_EFAPIAO_DATA: {
    description: 'Message to display when there is no eFapiao data to show',
    primaryValue: 'No eFapiao data available.',
    stringKey: 'noEfapiaoData',
  },
  WHY_NO_EFAPIAO_MESSAGE: {
    description: 'a message that explains why eFapiao data is unavailable',
    primaryValue:
      'eFapiao is not available when the order is not yet shipped or the order was paid for with a gift card.',
    stringKey: 'whyNoEfapiaoMessage',
  },
  EFAPIAO_HISTORY: {
    description: 'title for table of all efapiaos',
    primaryValue: 'eFapiao History',
    stringKey: 'eFapiaoHistory',
  },
  EFAPIAOS: {
    description: 'aria label for eFapiaos table',
    primaryValue: 'eFapiaos',
    stringKey: 'eFapiaos',
  },
};

export default translations;
