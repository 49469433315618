module.exports = {
  ja: {
    'JP-01': {
      name: '北海道',
      label: '北海道',
      abbreviation: 'JP-01',
    },
    'JP-02': {
      name: '青森県',
      label: '青森県',
      abbreviation: 'JP-02',
    },
    'JP-03': {
      name: '岩手県',
      label: '岩手県',
      abbreviation: 'JP-03',
    },
    'JP-04': {
      name: '宮城県',
      label: '宮城県',
      abbreviation: 'JP-04',
    },
    'JP-05': {
      name: '秋田県',
      label: '秋田県',
      abbreviation: 'JP-05',
    },
    'JP-06': {
      name: '山形県',
      label: '山形県',
      abbreviation: 'JP-06',
    },
    'JP-07': {
      name: '福島県',
      label: '福島県',
      abbreviation: 'JP-07',
    },
    'JP-08': {
      name: '茨城県',
      label: '茨城県',
      abbreviation: 'JP-08',
    },
    'JP-09': {
      name: '栃木県',
      label: '栃木県',
      abbreviation: 'JP-09',
    },
    'JP-10': {
      name: '群馬県',
      label: '群馬県',
      abbreviation: 'JP-10',
    },
    'JP-11': {
      name: '埼玉県',
      label: '埼玉県',
      abbreviation: 'JP-11',
    },
    'JP-12': {
      name: '千葉県',
      label: '千葉県',
      abbreviation: 'JP-12',
    },
    'JP-13': {
      name: '東京都',
      label: '東京都',
      abbreviation: 'JP-13',
    },
    'JP-14': {
      name: '神奈川県',
      label: '神奈川県',
      abbreviation: 'JP-14',
    },
    'JP-15': {
      name: '新潟県',
      label: '新潟県',
      abbreviation: 'JP-15',
    },
    'JP-16': {
      name: '富山県',
      label: '富山県',
      abbreviation: 'JP-16',
    },
    'JP-017': {
      name: '石川県',
      label: '石川県',
      abbreviation: 'JP-17',
    },
    'JP-18': {
      name: '福井県',
      label: '福井県',
      abbreviation: 'JP-18',
    },
    'JP-19': {
      name: '山梨県',
      label: '山梨県',
      abbreviation: 'JP-19',
    },
    'JP-20': {
      name: '長野県',
      label: '長野県',
      abbreviation: 'JP-20',
    },
    'JP-21': {
      name: '岐阜県',
      label: '岐阜県',
      abbreviation: 'JP-21',
    },
    'JP-22': {
      name: '静岡県',
      label: '静岡県',
      abbreviation: 'JP-22',
    },
    'JP-23': {
      name: '愛知県',
      label: '愛知県',
      abbreviation: 'JP-23',
    },
    'JP-24': {
      name: '三重県',
      label: '三重県',
      abbreviation: 'JP-24',
    },
    'JP-25': {
      name: '滋賀県',
      label: '滋賀県',
      abbreviation: 'JP-25',
    },
    'JP-26': {
      name: '京都府',
      label: '京都府',
      abbreviation: 'JP-26',
    },
    'JP-27': {
      name: '大阪府',
      label: '大阪府',
      abbreviation: 'JP-27',
    },
    'JP-28': {
      name: '兵庫県',
      label: '兵庫県',
      abbreviation: 'JP-28',
    },
    'JP-29': {
      name: '奈良県',
      label: '奈良県',
      abbreviation: 'JP-29',
    },
    'JP-30': {
      name: '和歌山県',
      label: '和歌山県',
      abbreviation: 'JP-30',
    },
    'JP-31': {
      name: '鳥取県',
      label: '鳥取県',
      abbreviation: 'JP-31',
    },
    'JP-32': {
      name: '島根県',
      label: '島根県',
      abbreviation: 'JP-32',
    },
    'JP-33': {
      name: '岡山県',
      label: '岡山県',
      abbreviation: 'JP-33',
    },
    'JP-34': {
      name: '広島県',
      label: '広島県',
      abbreviation: 'JP-34',
    },
    'JP-35': {
      name: '山口県',
      label: '山口県',
      abbreviation: 'JP-35',
    },
    'JP-36': {
      name: '徳島県',
      label: '徳島県',
      abbreviation: 'JP-36',
    },
    'JP-37': {
      name: '香川県',
      label: '香川県',
      abbreviation: 'JP-37',
    },
    'JP-38': {
      name: '愛媛県',
      label: '愛媛県',
      abbreviation: 'JP-38',
    },
    'JP-39': {
      name: '高知県',
      label: '高知県',
      abbreviation: 'JP-39',
    },
    'JP-40': {
      name: '福岡県',
      label: '福岡県',
      abbreviation: 'JP-40',
    },
    'JP-41': {
      name: '佐賀県',
      label: '佐賀県',
      abbreviation: 'JP-41',
    },
    'JP-42': {
      name: '長崎県',
      label: '長崎県',
      abbreviation: 'JP-42',
    },
    'JP-43': {
      name: '熊本県',
      label: '熊本県',
      abbreviation: 'JP-43',
    },
    'JP-44': {
      name: '大分県',
      label: '大分県',
      abbreviation: 'JP-44',
    },
    'JP-45': {
      name: '宮崎県',
      label: '宮崎県',
      abbreviation: 'JP-45',
    },
    'JP-46': {
      name: '鹿児島県',
      label: '鹿児島県',
      abbreviation: 'JP-46',
    },
    'JP-47': {
      name: '沖縄県',
      label: '沖縄県',
      abbreviation: 'JP-47',
    },
  },
  en: {
    'JP-01': {
      name: 'Hokkaido',
      label: 'Hokkaido',
      abbreviation: 'JP-01',
    },
    'JP-02': {
      name: 'Aomori',
      label: 'Aomori',
      abbreviation: 'JP-02',
    },
    'JP-03': {
      name: 'Iwate',
      label: 'Iwate',
      abbreviation: 'JP-03',
    },
    'JP-04': {
      name: 'Miyagi',
      label: 'Miyagi',
      abbreviation: 'JP-04',
    },
    'JP-05': {
      name: 'Akita',
      label: 'Akita',
      abbreviation: 'JP-05',
    },
    'JP-06': {
      name: 'Yamagata',
      label: 'Yamagata',
      abbreviation: 'JP-06',
    },
    'JP-07': {
      name: 'Fukushima',
      label: 'Fukushima',
      abbreviation: 'JP-07',
    },
    'JP-08': {
      name: 'Ibaraki',
      label: 'Ibaraki',
      abbreviation: 'JP-08',
    },
    'JP-09': {
      name: 'Tochigi',
      label: 'Tochigi',
      abbreviation: 'JP-09',
    },
    'JP-10': {
      name: 'Gunma',
      label: 'Gunma',
      abbreviation: 'JP-10',
    },
    'JP-11': {
      name: 'Saitama',
      label: 'Saitama',
      abbreviation: 'JP-11',
    },
    'JP-12': {
      name: 'Chiba',
      label: 'Chiba',
      abbreviation: 'JP-12',
    },
    'JP-13': {
      name: 'Tokyo',
      label: 'Tokyo',
      abbreviation: 'JP-13',
    },
    'JP-14': {
      name: 'Kanagawa',
      label: 'Kanagawa',
      abbreviation: 'JP-14',
    },
    'JP-15': {
      name: 'Niigata',
      label: 'Niigata',
      abbreviation: 'JP-15',
    },
    'JP-16': {
      name: 'Toyama',
      label: 'Toyama',
      abbreviation: 'JP-16',
    },
    'JP-17': {
      name: 'Ishikawa',
      label: 'Ishikawa',
      abbreviation: 'JP-17',
    },
    'JP-18': {
      name: 'Fukui',
      label: 'Fukui',
      abbreviation: 'JP-18',
    },
    'JP-19': {
      name: 'Yamanashi',
      label: 'Yamanashi',
      abbreviation: 'JP-19',
    },
    'JP-20': {
      name: 'Nagano',
      label: 'Nagano',
      abbreviation: 'JP-20',
    },
    'JP-21': {
      name: 'Gifu',
      label: 'Gifu',
      abbreviation: 'JP-21',
    },
    'JP-22': {
      name: 'Shizuoka',
      label: 'Shizuoka',
      abbreviation: 'JP-22',
    },
    'JP-23': {
      name: 'Aichi',
      label: 'Aichi',
      abbreviation: 'JP-23',
    },
    'JP-24': {
      name: 'Mie',
      label: 'Mie',
      abbreviation: 'JP-24',
    },
    'JP-25': {
      name: 'Shiga',
      label: 'Shiga',
      abbreviation: 'JP-25',
    },
    'JP-26': {
      name: 'Kyoto',
      label: 'Kyoto',
      abbreviation: 'JP-26',
    },
    'JP-27': {
      name: 'Osaka',
      label: 'Osaka',
      abbreviation: 'JP-27',
    },
    'JP-28': {
      name: 'Hyōgo',
      label: 'Hyōgo',
      abbreviation: 'JP-28',
    },
    'JP-29': {
      name: 'Nara',
      label: 'Nara',
      abbreviation: 'JP-29',
    },
    'JP-30': {
      name: 'Wakayama',
      label: 'Wakayama',
      abbreviation: 'JP-30',
    },
    'JP-31': {
      name: 'Tottori',
      label: 'Tottori',
      abbreviation: 'JP-31',
    },
    'JP-32': {
      name: 'Shimane',
      label: 'Shimane',
      abbreviation: 'JP-32',
    },
    'JP-33': {
      name: 'Okayama',
      label: 'Okayama',
      abbreviation: 'JP-33',
    },
    'JP-34': {
      name: 'Hiroshima',
      label: 'Hiroshima',
      abbreviation: 'JP-34',
    },
    'JP-35': {
      name: 'Yamaguchi',
      label: 'Yamaguchi',
      abbreviation: 'JP-35',
    },
    'JP-36': {
      name: 'Tokushima',
      label: 'Tokushima',
      abbreviation: 'JP-36',
    },
    'JP-37': {
      name: 'Kagawa',
      label: 'Kagawa',
      abbreviation: 'JP-37',
    },
    'JP-38': {
      name: 'Ehime',
      label: 'Ehime',
      abbreviation: 'JP-38',
    },
    'JP-39': {
      name: 'Kochi',
      label: 'Kochi',
      abbreviation: 'JP-39',
    },
    'JP-40': {
      name: 'Fukuoka',
      label: 'Fukuoka',
      abbreviation: 'JP-40',
    },
    'JP-41': {
      name: 'Saga',
      label: 'Saga',
      abbreviation: 'JP-41',
    },
    'JP-42': {
      name: 'Nagasaki',
      label: 'Nagasaki',
      abbreviation: 'JP-42',
    },
    'JP-43': {
      name: 'Kumamoto',
      label: 'Kumamoto',
      abbreviation: 'JP-43',
    },
    'JP-44': {
      name: 'Oita',
      label: 'Oita',
      abbreviation: 'JP-44',
    },
    'JP-45': {
      name: 'Miyazaki',
      label: 'Miyazaki',
      abbreviation: 'JP-45',
    },
    'JP-46': {
      name: 'Kagoshima',
      label: 'Kagoshima',
      abbreviation: 'JP-46',
    },
    'JP-47': {
      name: 'Okinawa',
      label: 'Okinawa',
      abbreviation: 'JP-47',
    },
  },
};
