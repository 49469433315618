import React, { createContext, useState } from 'react';
import { getValue } from '../../utils/browserStorage';

export const AthleteContext = createContext([{}, () => {}]);
const { Provider } = AthleteContext;

const defaultState = {
  email: '',
  name: '',
};

/**
 * A read-only context for athlete information
 *
 * @param {Object} props  – React props
 */
export const AthleteProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    let details = defaultState;
    try {
      // grab the token from local storage
      const jsonToken = getValue('okta-token-storage');
      details.email = jsonToken?.idToken?.claims?.email;
      details.name = jsonToken?.idToken?.claims?.name;
      details.groups = jsonToken?.idToken?.claims?.groups;
    } finally {
      return details;
    }
  });

  return <Provider value={[state, setState]}>{children}</Provider>;
};

export default AthleteContext;
