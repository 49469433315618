import gql from 'graphql-tag';

const DISPOSITION_MUTATION = gql`
  mutation putPidDisposition($pidReviewIds: [String]!, $disposition: String!, $comments: String) {
    putPidDisposition(pidReviewIds: $pidReviewIds, disposition: $disposition, comments: $comments) {
      status
      reason {
        extensions {
          code
          response {
            status
            statusText
            body
          }
        }
      }
    }
  }
`;

export default DISPOSITION_MUTATION;
