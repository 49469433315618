import { useHistory } from 'react-router';

/**
 * The hook will give you the value for the query param that is passed
 * @param {*} queryParam - queryPram key for which we need the value
 */
export const useGetUrlParams = (queryParam) => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  let query = useQuery();
  return query.get(queryParam);
};
