/** React/Utils */
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';

/** Local */
import EditableField from '../editableField/editableField';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import translation from '../updateEmail/updateEmail.i18n';
import CHANGE_BILLING_EMAIL_MUTATION from '../../../../mutations/changeBillingEmail.mutation';
import { ApiTimeOut } from '../../../../constants/dialog.const';
import useSnacks from '../../../../hooks/useSnacks';
import { emailPattern } from '../../../../utils/email';

/**
 *  Component to show/update email for order. Based on <EditableField> component.
 */
const UpdateEmail = ({ emailValue, contactName, orderNumber, onSuccess }) => {
  const {
    EDIT_EMAIL,
    EDIT_LABEL,
    VALIDATE_EMAIL_MSG,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_EMAIL_ERROR,
    UPDATE_EMAIL_DETAILS,
  } = useMemoTranslations(translation);

  const { setSnack, setError } = useSnacks();

  const [updateEmail, { loading: updateEmailLoading }] = useMutation(
    CHANGE_BILLING_EMAIL_MUTATION,
    {
      onError: (err) => {
        setError(`${UPDATE_EMAIL_ERROR}: ${err.message}`);
      },
      onCompleted: () => {
        setSnack(`${UPDATE_EMAIL_SUCCESS}`);
        onSuccess();
      },
    }
  );

  const onUpdateEmail = async (emailValue) => {
    const emailDetails = {
      requestId: `${uuidv4()}`,
      billTo: {
        contactInformation: {
          email: emailValue,
        },
      },
      orderNotes: [
        {
          contactDate: new Date().toISOString(),
          contactUser: contactName,
          details: `${UPDATE_EMAIL_DETAILS}`,
        },
      ],
    };
    await updateEmail({
      variables: {
        orderNumber,
        emailDetails,
        timeout: ApiTimeOut,
      },
    });
  };

  return (
    <EditableField
      fieldLabel={EDIT_LABEL}
      fieldValue={emailValue}
      editModeBtnLabel={EDIT_EMAIL}
      validatePattern={{
        value: emailPattern,
        message: VALIDATE_EMAIL_MSG,
      }}
      disableChange={updateEmailLoading}
      onSave={onUpdateEmail}
    />
  );
};

export default UpdateEmail;
