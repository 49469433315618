import { putValue, getValue } from './browserStorage';

// the key to store recent orders with in localStorage
const recentOrdersKey = 'recent-orders';
// the maximum number of orders to keep
const maximumOrders = 10;

/**
 * This function retrieves recent orders from localStorage. Orders will be sorted from most
 * recently visited, to least.
 *
 * @returns {array} – an array of recent orders, or, if none exist, an empty array
 */
export const getRecentOrders = () => {
  const recentOrders = getValue(recentOrdersKey);
  // most recent orders are pushed into the array, so we'll reverse it to make those first
  return Array.isArray(recentOrders) ? recentOrders.reverse() : [];
};
export const putRecentOrders = (value) => putValue(recentOrdersKey, value);

/**
 * This function adds a recent order to the recent-orders object in localStorage.
 *
 * @param {object} order – an order object to push to the recent orders array in localStorage
 * @returns {boolean} – true for a successful put, false otherwise
 */
export const storeRecentOrder = (order) => {
  if (!order || !order.orderNumber) return false;
  // get the orders
  let recentOrders = getRecentOrders();
  // remove the order if it's already there
  recentOrders = recentOrders.filter((element) => element.orderNumber !== order.orderNumber);
  // add the current date to the object
  order.dateVisited = new Date();
  recentOrders.push(order);
  // keep the array's length no longer than the max (defined above)
  if (recentOrders.length > maximumOrders) recentOrders.shift();
  return putRecentOrders(recentOrders);
};
