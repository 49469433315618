import gql from 'graphql-tag';

const TRACKING_SHIPMENTS_QUERY = gql`
  query getShipmentsByTracking($trackingNumber: String!) {
    shipmentsByTracking(trackingNumber: $trackingNumber) {
      objects {
        id
        shipmentIdentifier
        creationDate
        modificationDate
        orderNumber
        orderType
        billOfLadingNumber
        actualShipmentDate
        actualShippingMethod
        standardCarrierAlphaCode
        consolidatedCustomerTrackingUrl
        trackingNumber
        carrierTrackingUrl
        carrierStatus
        shipTo {
          upmId
          city
          state
          country
          postalCode
          locale
        }
        containers {
          containerNumber
          trackingNumber
          actualDeliveryDate
          carrierStatus
          carrierTrackingUrl
          customerTrackingUrl
          shipmentLines {
            orderLineIdentifier
            fulfillmentRequestNumber
            fulfillmentOrderLineIdentifier
            lineNumber
            universalProductCode
            customizedProductReference
            unitOfMeasure
            quantity
            serialNumbers {
              value
            }
          }
        }
      }
    }
  }
`;

export default TRACKING_SHIPMENTS_QUERY;
