import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';

/**
 * Wraps sidebar item for consistent styling
 */
export default function SidebarItem({ title, icon, children, dataTestId, ...rest }) {
  const classes = useStyles();
  return (
    <Box p={2} {...rest}>
      <CardHeader
        avatar={icon}
        className={classes.sidebarHeader}
        disableTypography
        data-testid={dataTestId}
        classes={{ root: classes.cardHeaderRoot, avatar: classes.cardHeaderAvatar }}
        title={<h1 className={classes.sidebarTitle}>{title}</h1>}
      />
      <Box p={1}>{children}</Box>
    </Box>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  dataTestId: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  sidebarHeader: {
    padding: '4px !important',
    color: theme.palette.grey[600],
  },
  cardHeaderAvatar: { marginRight: '7px' },
  sidebarTitle: {
    color: theme.palette.grey[600],
    fontSize: '0.875rem',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
}));
