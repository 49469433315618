import React from 'react';

export default function CspPaymentsIcon({ classes }) {
  return (
    <div className={classes} data-testid='csp-payments-view-icon'>
      <svg
        width='26'
        height='24'
        viewBox='0 0 26 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.0156 8.01563V6L3.98437 6V8.01562L20.0156 8.01563ZM20.0156 18L20.0156 12L3.98437 12L3.98437 18L20.0156 18ZM20.0156 3.98438C20.5781 3.98438 21.0469 4.1875 21.4219 4.59375C21.7969 4.96875 21.9844 5.4375 21.9844 6L21.9844 18C21.9844 18.5625 21.7969 19.0469 21.4219 19.4531C21.0469 19.8281 20.5781 20.0156 20.0156 20.0156L3.98437 20.0156C3.42187 20.0156 2.95312 19.8281 2.57812 19.4531C2.20312 19.0469 2.01562 18.5625 2.01562 18L2.01562 6C2.01562 5.4375 2.20312 4.96875 2.57812 4.59375C2.95312 4.1875 3.42187 3.98438 3.98437 3.98438L20.0156 3.98438Z'
          fill='#707072'
        />
        <circle cx='17' cy='15' r='8' fill='white' />
        <path d='M18 12V16L20 18' stroke='#707072' strokeWidth='1.5' />
        <path
          d='M12.1667 11.6667V14.5H15'
          stroke='#707072'
          strokeWidth='1.5'
          strokeMiterlimit='10'
        />
        <path
          d='M18 10.5C21.04 10.5 23.5 12.96 23.5 16C23.5 19.04 21.04 21.5 18 21.5C14.96 21.5 12.5 19.04 12.5 16'
          stroke='#707072'
          strokeWidth='1.5'
          strokeMiterlimit='10'
        />
        <path
          d='M12.7599 14.3333C13.4599 12.1133 15.5466 10.5 17.9999 10.5'
          stroke='#707072'
          strokeWidth='1.5'
          strokeMiterlimit='10'
        />
      </svg>
    </div>
  );
}
