/*
 This file contains the names and values for military address options menus in the app 
 */
export const PostOfficeOptions = [
  {
    name: 'Army Post Office',
    value: 'apo',
  },
  {
    name: 'Fleet Post Office',
    value: 'fpo',
  },
];

export const RegionOptions = [
  {
    name: 'Armed Forces Americas',
    value: 'AA',
  },
  {
    name: 'Armed Forces Europe',
    value: 'AE',
  },
  {
    name: 'Armed Forces Pacific',
    value: 'AP',
  },
];
