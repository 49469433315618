/* React/Utils */
import React, { useContext, useEffect, useState } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import InputMask from 'react-input-mask';

/* Material-UI */
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

/* Local */
import { handleFieldChange } from './addressFieldChangeHandler';
import translations from './addressForm.i18n';
import { FirstNameField, LastNameField, KanaFirstNameField, KanaLastNameField } from './nameFields';
import JPStates from '../../constants/address/JPStates.const';
import { Country } from '../../constants/country.const';
import dialogActions from '../../store/actions/dialogActions';
import { DialogContext } from '../../store/contexts/dialogContext';
import { ConsumerContext } from '../../store/contexts/consumerContext';
import { getEmailForReturn } from '../../utils/dialog';

/**
 * This is a reusable component for JP consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const JPAddressForm = ({
  isGiftCardAddress = false,
  className = '',
  region = region,
  customClasses,
  children,
  variant = 'standard',
  setIsJapanPhoneNumberValid,
}) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [consumerState] = useContext(ConsumerContext);
  const { i18nString } = useContext(NikeI18nContext);
  const [dayPhoneNumberError, setDayPhoneNumberError] = useState('');
  const [inputMask, setInputMask] = useState('999-9999-9999');
  const classes = { ...useStyles(), ...customClasses };

  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const address = isGiftCardAddress ? dialogState.gcShippingAddress : dialogState.address;
  const { address1, address2, address3, city, state, postalCode } = address;
  const { consumerEmail } = consumerState;
  const email = getEmailForReturn(address, dialogState.gcShippingAddress, consumerEmail);
  const dayPhoneNumber =
    address.dayPhoneNumber ||
    dialogState.gcShippingAddress?.dayPhoneNumber ||
    dialogState.address?.dayPhoneNumber;
  const {
    MUNICIPALITY,
    AREA_VILLAGE,
    STREET_ADDRESS,
    BUILDING_NAME,
    PREFECTURE,
    COUNTRY,
    JAPAN,
    POSTAL_CODE,
    PHONE_NUMBER,
    PHONE_NUMBER_VALIDATION_ERROR,
    EMAIL,
  } = mapValues(translations, i18nString);

  /**
   * This use effect is only intended to execute once, when the component loads, to ensure the
   * address country field is 'JP'.
   */
  useEffect(() => {
    if (address.country !== Country.JAPAN) {
      dialogDispatch(setAddressField('country', Country.JAPAN));
    }
    if (isGiftCardAddress && !address.email) {
      dialogDispatch(setGCShippingAddressField('email', email));
    }
    /*
      Check to see if the initial value being used for state matches the codes we have on file
      otherwise default to an empty string. This ensures the submit button is not enabled.
     */
    if (state && !JPStates.ja[state]) {
      dialogDispatch(setAddressField('state', ''));
    }
  }, []);

  // Updating the address and gcShippingAddress (billing address) phone numbers
  useEffect(() => {
    if (dayPhoneNumber && !dialogState.address?.dayPhoneNumber) {
      dialogDispatch(setAddressField('dayPhoneNumber', dayPhoneNumber));
    } else if (dayPhoneNumber && !dialogState.gcShippingAddress?.dayPhoneNumber) {
      dialogDispatch(setGCShippingAddressField('dayPhoneNumber', dayPhoneNumber));
    }
  }, [dayPhoneNumber]);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const handleOnBlur = (event) => {
    const { value } = event.target;
    /*
      The mask placed on the input field causes it to have _ and - characters, which leads it to
      always having 13 characters. The replace function below with this regex will remove anything
      that is not a digit.
     */
    const filteredPhoneNumber = value.replace(/\D/g, '');
    if (filteredPhoneNumber.length === 10) {
      setDayPhoneNumberError('');
      setInputMask('99-9999-9999');
      setIsJapanPhoneNumberValid(true);
    } else if (filteredPhoneNumber.length === 11) {
      setDayPhoneNumberError('');
      setIsJapanPhoneNumberValid(true);
    } else {
      setIsJapanPhoneNumberValid(false);
      setDayPhoneNumberError(PHONE_NUMBER_VALIDATION_ERROR);
    }
  };

  const handleOnFocus = (event) => {
    setDayPhoneNumberError('');
    setInputMask('999-9999-9999');
  };

  const addressGridClassName = isGiftCardAddress ? classes.gcAddressGrid : classes.addressGrid;

  return (
    <div className={clsx(addressGridClassName, className)}>
      {children}
      {/* If isGCAddress, show email field for step 3. */}
      {isGiftCardAddress && (
        <FormControlLabel
          aria-label={EMAIL}
          className={classes.email}
          control={
            <TextField
              required
              defaultValue={email}
              onChange={handleChange('email')}
              aria-required={EMAIL}
              label={EMAIL}
              className={classes.wideField}
              data-testid={'input-email'}
            />
          }
        />
      )}
      <LastNameField
        classes={classes}
        isGiftCardAddress={isGiftCardAddress}
        required={true}
        variant={variant}
      />
      <FirstNameField
        classes={classes}
        isGiftCardAddress={isGiftCardAddress}
        required={true}
        variant={variant}
      />
      <KanaLastNameField
        classes={classes}
        isGiftCardAddress={isGiftCardAddress}
        required={true}
        variant={variant}
      />
      <KanaFirstNameField
        classes={classes}
        isGiftCardAddress={isGiftCardAddress}
        required={true}
        variant={variant}
      />
      {!isGiftCardAddress && (
        <>
          <FormControlLabel
            aria-label={COUNTRY}
            className={classes.country}
            control={
              <TextField
                disabled
                value={JAPAN}
                onChange={handleChange('country')}
                label={COUNTRY}
                className={classes.wideField}
                data-testid={'input-country'}
                variant={variant}
              />
            }
          />
          <FormControlLabel
            aria-label={POSTAL_CODE}
            className={classes.postalCode}
            control={
              <TextField
                required
                value={postalCode}
                onChange={handleChange('postalCode')}
                label={POSTAL_CODE}
                className={classes.wideField}
                data-testid={'input-postal-code'}
                variant={variant}
              />
            }
          />
          <TextField
            select
            required
            value={state}
            onChange={handleChange('state')}
            data-testid={'select-prefecture'}
            aria-label={PREFECTURE}
            variant={variant}
            label={PREFECTURE}
            className={clsx(classes.prefecture, classes.wideField)}>
            {Object.values(JPStates.ja).map(({ name, abbreviation }) => (
              <MenuItem
                data-key='state'
                value={abbreviation}
                key={abbreviation}
                data-testid={`select-prefecture-${abbreviation}`}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          {/* Municipality maps to the city field */}
          <FormControlLabel
            aria-label={MUNICIPALITY}
            className={classes.city}
            control={
              <TextField
                required
                value={city}
                onChange={handleChange('city')}
                label={MUNICIPALITY}
                className={classes.wideField}
                data-testid={'input-municipality'}
                variant={variant}
              />
            }
          />
          {/* Area/Village maps to the address3 field */}
          <FormControlLabel
            aria-label={AREA_VILLAGE}
            className={classes.address3}
            control={
              <TextField
                required
                value={address3}
                onChange={handleChange('address3')}
                label={AREA_VILLAGE}
                className={classes.wideField}
                data-testid={'input-area-village'}
                variant={variant}
              />
            }
          />
          {/* Street Address maps to the address1 field */}
          <FormControlLabel
            aria-label={STREET_ADDRESS}
            className={classes.address1}
            control={
              <TextField
                required
                value={address1}
                onChange={handleChange('address1')}
                label={STREET_ADDRESS}
                className={classes.wideField}
                data-testid={'input-street-address'}
                variant={variant}
              />
            }
          />
          {/* Building Name maps to the address2 field */}
          <FormControlLabel
            aria-label={BUILDING_NAME}
            className={classes.address2}
            control={
              <TextField
                value={address2 || undefined}
                onChange={handleChange('address2')}
                label={BUILDING_NAME}
                aria-labelledby={BUILDING_NAME}
                className={classes.wideField}
                data-testid={'input-building-name'}
                variant={variant}
              />
            }
          />
          <InputMask
            mask={inputMask}
            value={dayPhoneNumber}
            onChange={handleChange('dayPhoneNumber')}>
            {() => (
              <FormControlLabel
                aria-label={PHONE_NUMBER}
                className={classes.phoneField}
                control={
                  <TextField
                    required
                    aria-required={true}
                    inputProps={{ 'data-key': 'dayPhoneNumber' }}
                    label={PHONE_NUMBER}
                    className={classes.wideField}
                    data-testid={'input-phone-number'}
                    variant={variant}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    error={dayPhoneNumberError}
                    helperText={dayPhoneNumberError}
                  />
                }
              />
            )}
          </InputMask>
        </>
      )}
    </div>
  );
};

export default JPAddressForm;

const useStyles = makeStyles({
  addressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " LN LN LN FN FN FN "
      " KL KL KL KF KF KF "
      " A3 A3 A3 A3 A3 A3 "
      " A1 A1 A1 A1 A1 A1 "
      " A2 A2 A2 A2 A2 A2 "
      " CI CI CO PR PR PO "
      " PH PH .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  gcAddressGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " EM EM EM EM EM EM "
      " LN LN LN FN FN FN "
      " KL KL KL KF KF KF "
    `,
    gridGap: '1rem 3rem',
  },
  email: {
    gridArea: 'EM',
    width: '100%',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  firstNameField: {
    gridArea: 'FN',
    margin: 0,
  },
  kanaLastNameField: {
    gridArea: 'KL',
    margin: 0,
  },
  kanaFirstNameField: {
    gridArea: 'KF',
    margin: 0,
  },
  address1: {
    gridArea: 'A1',
    width: '100%',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    width: '100%',
    margin: 0,
  },
  address3: {
    gridArea: 'A3',
    width: '100%',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    width: '100%',
    margin: 0,
  },
  prefecture: {
    gridArea: 'PR',
    width: '100%',
    margin: 0,
  },
  country: {
    gridArea: 'CO',
    width: '100%',
    margin: 0,
  },
  postalCode: {
    gridArea: 'PO',
    width: '100%',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
});
