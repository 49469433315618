/** React/Utils */
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import { NikeI18nContext } from '@nike/i18n-react';

/** Local */
import {
  getCarrier,
  getShipGroup,
  getShipmentDate,
  getShippingMethod,
  getStatus,
  getTrackingLinks,
  isDelivered,
  getOrderLineKey,
  getDeliveryDate,
} from '../../../../utils/shipment';
import { getOrderLineShipmentDetails } from '../../../../utils/orderLine';
import translations from './details.i18n';
import OrderContext from '../../../../store/contexts/orderContext';
import { displaySDDRegions } from '../../../../constants/order.const';

const ShipmentDetails = ({ orderLines, shipment, locale }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const delivered = isDelivered(shipment);
  const [orderDetail] = useContext(OrderContext);
  const classes = useStyles();
  const { fulfiller, estimatedDeliveryDate, scheduledDeliveryDate } = getOrderLineShipmentDetails(
    orderLines,
    getOrderLineKey(shipment),
    locale
  );

  const {
    CARRIER,
    EDD,
    FULFILLER,
    SHIP_GROUP,
    SHIPPING_METHOD,
    STATUS,
    TRACKING_NUMBER,
    QUANTITY,
    VIEW_CARRIER_TRACKING,
    DELIVERY_DATE,
    SHIP_DATE,
    SDD,
  } = mapValues(translations, i18nString);
  const displaySDD = displaySDDRegions.includes(orderDetail.omsRegionReference);
  return (
    <Table className={classes.parentTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.tableHeader}>{TRACKING_NUMBER}</TableCell>
          <TableCell className={classes.tableHeader}>{QUANTITY}</TableCell>
          <TableCell className={classes.tableHeader}>{CARRIER}</TableCell>
          <TableCell className={classes.tableHeader}>{SHIPPING_METHOD}</TableCell>
          <TableCell className={classes.tableHeader}>{SHIP_GROUP}</TableCell>
          <TableCell className={classes.tableHeader}>{SHIP_DATE}</TableCell>
          <TableCell className={classes.tableHeader}>{STATUS}</TableCell>
          {/* not show unless shipment has been delivered */}
          {delivered && <TableCell className={classes.tableHeader}>{DELIVERY_DATE}</TableCell>}
          <TableCell className={classes.tableHeader}>{EDD}</TableCell>
          {/* Display SDD header only for Japan orders */}
          {displaySDD && <TableCell className={classes.tableHeader}>{SDD}</TableCell>}
          <TableCell className={classes.tableHeader}>{FULFILLER}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.contentRow}>
          <TableCell className={classes.tableCell}>
            {getTrackingLinks(shipment).map((trackingLinkObj, i) => (
              <ListItem key={i} className={classes.trackingLinkListItem}>
                {trackingLinkObj.link ? (
                  <Link
                    aria-label='shipment tracking'
                    key={i}
                    href={trackingLinkObj.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-testid={`tracking-link-${i}`}>
                    {trackingLinkObj.trackingNum || VIEW_CARRIER_TRACKING}
                  </Link>
                ) : (
                  trackingLinkObj.trackingNum
                )}
              </ListItem>
            ))}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {getTrackingLinks(shipment).map((trackingLinkObj, i) => (
              <ListItem className={classes.qtyListItem} key={i} data-testid={`container-qty-${i}`}>
                {trackingLinkObj.qty ? `${trackingLinkObj.qty}` : '-'}
              </ListItem>
            ))}
          </TableCell>

          <TableCell className={classes.tableCell}>{getCarrier(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getShippingMethod(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getShipGroup(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getShipmentDate(shipment, locale)}</TableCell>
          <TableCell className={classes.tableCell}>{getStatus(shipment)}</TableCell>
          {/* not show unless shipment has been delivered */}
          {delivered && (
            <TableCell className={classes.tableCell}>{getDeliveryDate(shipment, locale)}</TableCell>
          )}
          <TableCell className={classes.tableCell}>{estimatedDeliveryDate}</TableCell>
          {/* Display SDD only for Japan orders */}
          {displaySDD && (
            <TableCell className={classes.tableCell}>{scheduledDeliveryDate}</TableCell>
          )}
          <TableCell className={classes.tableCell}>{fulfiller}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

ShipmentDetails.propTypes = {
  orderLines: PropTypes.arrayOf(PropTypes.object),
  shipment: PropTypes.object,
  locale: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  contentRow: {
    height: '30px',
  },
  headerRow: {
    height: '30px',
    fontSize: '0.75rem',
  },
  parentTable: {
    marginBottom: '40px',
  },
  tableCell: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  tableHeader: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  trackingLinkListItem: {
    padding: 0,
  },
  qtyListItem: {
    padding: 0,
    justifyContent: 'center',
  },
}));

export default ShipmentDetails;
