import gql from 'graphql-tag';

const CHANGE_BILLING_EMAIL_MUTATION = gql`
  mutation changeBillingEmail(
    $orderNumber: String!
    $emailDetails: ChangeEmailDetails!
    $timeout: Int!
  ) {
    changeBillingEmail(orderNumber: $orderNumber, emailDetails: $emailDetails, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      errors {
        error {
          field
          message
          code
          httpStatus
        }
      }
      error {
        field
        message
        code
        httpStatus
      }
    }
  }
`;

export default CHANGE_BILLING_EMAIL_MUTATION;
