import { actionTypes } from '../actions/consumerActions';
import { defaultState } from '../contexts/consumerContext';

/**
 * The reducer for the consumer context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const consumerReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    /*
    set a consumer's profile id, and set the isRegistered state to the
    truthiness of that value
    */
    case actionTypes.SET_CONSUMER: {
      const profileId = action.profileId;
      if (profileId) {
        return {
          ...state,
          profileId,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case actionTypes.SET_IS_SWOOSH_MEMBER: {
      return {
        ...state,
        isSwooshMember: Boolean(action.isSwooshMember),
      };
    }
    case actionTypes.SET_CONSUMER_TYPE: {
      return {
        ...state,
        consumerType: action.consumerType,
        /*
         Marking user as registered user based on consumerType which passed 
         after calling Identity Api
        */
        isRegistered: Boolean(action.consumerType && action.consumerType !== 'nike:guest'),
      };
    }
    case actionTypes.SET_CONSUMER_NAME: {
      return {
        ...state,
        consumerName: action.consumerName,
      };
    }
    case actionTypes.SET_CONSUMER_EMAIL: {
      return {
        ...state,
        consumerEmail: action.consumerEmail,
      };
    }
    case actionTypes.SET_KANA_FIRST_NAME: {
      return {
        ...state,
        kanaFirstName: action.kanaFirstName,
      };
    }
    case actionTypes.SET_KANA_LAST_NAME: {
      return {
        ...state,
        kanaLastName: action.kanaLastName,
      };
    }
    default: {
      return state;
    }
  }
};
