/* React/Utils */
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

/* local */
import translationResend from './invoiceDetails.i18n';
import RESEND_INVOICE from '../../../../../mutations/resendInvoice.mutation';
import useSnacks from '../../../../../hooks/useSnacks';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';

const ResendEmail = ({ invoiceDetails }) => {
  const [isResend, setIsResend] = useState(false);
  const { setError, setSnack } = useSnacks();
  const classes = useStyles();
  const {
    INVOICE_DETAILS_RESEND_INVOICE,
    INVOICE_DETAILS_INVOICE_RESENT,
    INVOICE_DETAILS_RESEND_ERROR,
    ARIA_INVOICE_RESENDING,
    INVOICE_NOT_READY,
  } = useMemoTranslations(translationResend);

  // to reset button text (from "Invoice sent" to "Recend invoice") when switching between invoices
  useEffect(() => {
    setIsResend(false);
  }, [invoiceDetails]);

  // lazy hook for resending invoice. resendInvoice should be called manually
  const [resendInvoice, { loading }] = useMutation(RESEND_INVOICE, {
    // if any error happens during resending invoice (calling resendInvoice function),
    // show snackbar with error
    onError: (err) => {
      setError(INVOICE_DETAILS_RESEND_ERROR);
    },
    // if no errors happend during resending invoice (calling resendInvoice function),
    // show snackbar and set Resend button text to "Invoice sent"
    onCompleted: (data) => {
      /*
        Due to the xml parsing of the response by dockyard, some error content may live
        inside a "completed" response.
      */
      const error = data?.resendInvoice?.Errors?.Error;
      if (error) {
        setError(`${INVOICE_NOT_READY}: ${error?.ErrorDescription}`);
      } else {
        setSnack(INVOICE_DETAILS_INVOICE_RESENT);
        setIsResend(true);
      }
    },
  });

  // function which invoces resendInvoice with passed parameters
  const resend = () => {
    resendInvoice({
      variables: {
        invoiceNumber: invoiceDetails?.resourceId,
      },
    });
  };

  return (
    <div className={classes.resendButtonWrapper}>
      <Button
        className={classes.resendButton}
        onClick={resend}
        variant='contained'
        disabled={isResend}
        size='medium'
        color='primary'
        data-testid='resend-invoice-button'>
        {isResend ? (
          <>
            <CheckIcon />
            {INVOICE_DETAILS_INVOICE_RESENT}
          </>
        ) : loading ? (
          <CircularProgress
            size='24px'
            color='white'
            className={classes.loaderMargin}
            aria-label={ARIA_INVOICE_RESENDING}
          />
        ) : (
          <>
            {INVOICE_DETAILS_RESEND_INVOICE} {invoiceDetails?.resourceId}
          </>
        )}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: { borderRadius: `24px` },
  bootstrapDialogTitle: {
    padding: '46px 64px 25px 64px',
    display: `flex`,
    justifyContent: `space-between`,
  },
  dialogContent: {
    padding: '45px 64px 10px 64px',
  },
  dialogActions: {
    padding: '25px 64px 46px 64px',
  },
  inputPanel: {
    width: `466px`,
  },
  closeButton: {
    'padding': `6px`,
    'backgroundColor': theme.palette.grey[100],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  cancelButton: {
    'borderRadius': `24px`,
    'border': `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  sendButton: {
    'borderRadius': `24px`,
    'backgroundColor': theme.palette.primary.main,
    'color': 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
    },
  },
  resendButtonWrapper: {
    flexBasis: '100%',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  resendButton: { borderRadius: '24px', padding: '6px 20px' },
  loaderMargin: {
    padding: '0px',
    margin: '0px',
  },
}));

export default ResendEmail;
