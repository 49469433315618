import React, { createContext, useState, useEffect } from 'react';
import config from '../../utils/config';
import useAuth from '../../hooks/useAuth';

export const PermissionContext = createContext([{}, () => {}]);
const { Provider } = PermissionContext;

export const PII_ACCESS = 'pii-access';

/**
 * A basic provider for a context with only a single value
 *
 * @param {Object} props  – React props
 */
export const PermissionProvider = ({ children }) => {
  const [state, setState] = useState([]);
  const [hasModificationPermissions, setHasModificationPermissions] = useState(null);
  const { oktaToken } = useAuth();

  useEffect(() => {
    const fetchPermissions = async () => {
      const requestHeaders = {
        headers: {
          authorization: oktaToken,
        },
        cors: true,
      };

      /*
        Retrieve list of active permissions determined by the user's role that was initially
        set through the Admin settings page. This is soley based on role without regards to
        any network seperation concerns.
       */
      const activePermissionsResponse = await fetch(
        config.foundry.activePermissions,
        requestHeaders
      );
      const usersActivePermissions = await activePermissionsResponse.json();
      const lowerCasePermissions =
        (Array.isArray(usersActivePermissions) &&
          usersActivePermissions.map((permission) => {
            return {
              region: permission.region,
              permissions: permission.permissions.map((permission) => permission.toLowerCase()),
            };
          })) ||
        [];
      setState(lowerCasePermissions);

      let modificationPermissions = localStorage.getItem(PII_ACCESS);

      if (process.env.PUBLIC_URL === '/portlets/postpurchase') {
        // With China network separation, we can assume that the user has modification permissions.
        modificationPermissions = 'granted';
      } else {
        /*
        Retrieve whether or not the user has modifications permissions. This exists
        due to network seperation concerns. Checking local storage first to refrain
        from multiple http requests. If this is modified by the user, we are not 
        concerned due to this change happening at the service layer when a modification
        request is made, but this allows us to limit the ui.
       */

        if (!modificationPermissions) {
          const modificationPermissionsResponse = await fetch(
            config.foundry.modificationPermissions,
            requestHeaders
          );

          const usersModificationPermissions = await modificationPermissionsResponse.json();
          modificationPermissions = usersModificationPermissions.access;
        }
      }

      localStorage.setItem(PII_ACCESS, modificationPermissions);
      setHasModificationPermissions(modificationPermissions);
    };

    if (oktaToken) {
      fetchPermissions();
    }
  }, [oktaToken]);

  return <Provider value={[state, hasModificationPermissions]}>{children}</Provider>;
};

export default PermissionContext;
