import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import translations from './itemDetails.i18n';

/**
 * @param {boolean} expanded - determines if the button is expanded
 * @param {function} onClick - handles button click
 */
const ExpansionButton = ({ expanded, onClick, ...rest }) => {
  const { HIDE_DETAIL, SEE_DETAIL } = useMemoTranslations(translations);
  const classes = useStyles();

  return (
    <Button
      {...rest}
      className={classes.expandButton}
      aria-label={expanded ? HIDE_DETAIL.toLowerCase() : SEE_DETAIL.toLowerCase()}
      aria-expanded={expanded}
      onClick={onClick}>
      {expanded ? HIDE_DETAIL : SEE_DETAIL}
    </Button>
  );
};

ExpansionButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  expandButton: {
    minWidth: '7.6em',
    color: theme.palette.primary.main,
  },
}));

export default ExpansionButton;
