import { formatDate, formatLocale } from './date';

/**
 * Validate string as an email address
 * @param {string} email
 * @returns {boolean}
 */
export const emailIsValid = (email) => {
  if (typeof email !== 'string') return false;

  return emailPattern.test(email);
};

export default emailIsValid;

/**
 * Pattern to validate email
 * @type {RegExp}
 */
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * Takes an email data object returned by historical API
 * and returns an array of values in the order matching
 * the table columns in Emails Table
 *
 * @param {object} email
 * @param {string} resendString Translated string for "Resend"
 *
 * @returns {array} [
 * Date,
 * Sent By,
 * Sent To,
 * Category,
 * Type
 * ]
 */
export const transformEmailData = (email = {}, resendString = 'Resend') => {
  return [
    formatDate(email.creation_date, formatLocale(email.locale_language)),
    email.user_email_address,
    email.notification_class,
    email.is_resend === 'N' ? '' : resendString,
  ];
};
