/* React/Utils */
import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import decodeToken from 'jwt-decode';
import { NikeI18nContext } from '@nike/i18n-react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import { v4 as uuidv4 } from 'uuid';

/* Material-UI */
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';

/* Local */
import translations from './topBar.i18n';
import { useGetUrlParams } from '../../../hooks/useGetUrlParams';

/**
 * TopBarContainer component containing top bar styling,
 * action buttons (optional) rendered as children
 */
export default function TopBarContainer({ children }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { ARIA_PAGE_HEADER, LOGO_BUTTON_SEARCH_ALT_TEXT, SETTINGS } = mapValues(
    translations,
    i18nString
  );
  const history = useHistory();
  const isCaseIdAvailable = useGetUrlParams('caseId');
  const sessionId = useGetUrlParams('sessionId');
  const { authState } = useOktaAuth();
  const { accessToken } = authState;

  useEffect(() => {
    /**
     * check for sessionId and generates one if it's missing
     * we use this sessionId to keep track of how long athletes spend in the app
     */
    if (!sessionId && !isCaseIdAvailable) {
      let route = window.location.pathname;
      const newSessionId = uuidv4();
      const { groups } = decodeToken(accessToken?.accessToken);

      // adding sessionId to newrelic as a custom attribute lets us sort users actions by session
      if (
        window.newrelic &&
        !groups.includes('App.ConsumerServices.Warehouse.US') &&
        !groups.includes('App.ConsumerServices.WarehouseSupervisor.US')
      ) {
        window.newrelic.setCustomAttribute('sessionId', newSessionId);
      }

      // remove process.env.PUBLIC_URL to avoid repeating the path
      route = route.replace(process.env.PUBLIC_URL, '');
      if (history.location.search) {
        history.push(
          route === '/' ? '' : route + `${history.location.search}&sessionId=${newSessionId}`
        );
      } else {
        history.push(`${route === '/' ? '' : route}?sessionId=${newSessionId}`);
      }
    }
  });

  return (
    <AppBar
      position='sticky'
      elevation={1}
      aria-label={ARIA_PAGE_HEADER}
      className={clsx(classes.appBar)}
      classes={{ root: clsx(classes.appBarRoot) }}>
      <Toolbar variant={'dense'} disableGutters={true} className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <Button
            component={Link}
            to={`/?sessionId=${sessionId}`}
            classes={{ root: classes.homeLink }}
            data-testid='logo-link'>
            <img
              src={process.env.PUBLIC_URL + '/assets/img/omobo.png'}
              className={classes.logo}
              alt={LOGO_BUTTON_SEARCH_ALT_TEXT}
            />
          </Button>
          <IconButton
            data-testid='settings-link'
            component={Link}
            className={classes.settingsLink}
            aria-label={SETTINGS}
            to={`/settings/?sessionId=${sessionId}`}>
            <SettingsIcon className={classes.settingsIcon} />
          </IconButton>
        </Box>
        {children}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: '55px',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 'auto',
  },
  appBarRoot: {
    width: 'auto',
    overflowY: 'hidden',
  },
  toolbar: {
    overflowY: 'hidden',
  },
  appBar: {
    minHeight: '56px',
  },
  settingsLink: { paddingLeft: 0, position: 'relative', top: '2.5px' },
  settingsIcon: {
    color: theme.palette.common.white,
    fontSize: '1.7rem',
  },
  logo: {
    width: 'auto',
    height: '55px',
    padding: '5px',
    paddingLeft: '8px',
  },
  homeLink: {
    color: theme.palette.common.white,
  },
}));
