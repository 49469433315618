const translations = {
  ARIA_EMAILS: {
    description: 'aria label for emails table',
    primaryValue: 'Emails',
    stringKey: 'emails',
  },
  ARIA_EMAIL_LOADING: {
    description: 'aria label for email details loading spinner',
    primaryValue: 'email loading',
    stringKey: 'ariaEmailLoading',
  },
  CATEGORY: {
    description: 'column header for order emails view',
    primaryValue: 'Category',
    stringKey: 'category',
  },
  DATE: {
    description: 'column header for date sent in emails view',
    primaryValue: 'Date Sent',
    stringKey: 'dateSent',
  },
  NO_EMAILS: {
    description: 'message when no emails are present',
    primaryValue: 'No related communications',
    stringKey: 'noRelatedCommunications',
  },
  ORDER_EMAILS_ERROR: {
    description: 'label for error message when failing to retrieve email history for an order',
    primaryValue: 'Order Emails Error',
    stringKey: 'orderEmailsError',
  },
  RESEND: {
    description: 'label for emails that are of type "resend"',
    primaryValue: 'Resend',
    stringKey: 'resend',
  },
  SENT_TO: {
    description: 'column header for recipient in emails view',
    primaryValue: 'Sent To',
    stringKey: 'sentTo',
  },
  TYPE: {
    description: 'column header for email type (resend or original) in emails view',
    primaryValue: 'Type',
    stringKey: 'type',
  },
  COMMUNICATIONS: {
    description: 'label for tab containing emails and SMS messages',
    primaryValue: 'Communications',
    stringKey: 'communications',
  },
  MESSAGE: {
    description: 'label for column of a table containing the text of an SMS message',
    primaryValue: 'Message',
    stringKey: 'message',
  },
};

export default translations;
