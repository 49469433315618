import gql from 'graphql-tag';

const ADD_SHIPPING_DISCOUNT_MUTATION = gql`
  mutation addShippingDiscount(
    $orderNumber: String!
    $input: AddShippingDiscountInputV1!
    $timeout: Int!
  ) {
    addShippingDiscount(orderNumber: $orderNumber, input: $input, timeout: $timeout) {
      id
      status
      response {
        orderNumber
        resourceType
      }
      error {
        httpStatus
        message
        code
      }
    }
  }
`;

export default ADD_SHIPPING_DISCOUNT_MUTATION;
