const translations = {
  DIALOG_TITLE: {
    description: 'Title of Unlink Member dialog',
    primaryValue: 'Unlink profile',
    stringKey: 'unlinkProfile_dialog_title',
  },
  DESCRIPTION: {
    description: 'Description of Unlink Member dialog',
    primaryValue: 'Are you sure you want to unlink profile from this order?',
    stringKey: 'unlinkProfile_dialog_description',
  },
  UNLINK_CAPTION: {
    description: 'Unlink button caption',
    primaryValue: 'Unlink',
    stringKey: 'unlinkProfile_dialog_unlink_button_caption',
  },
  CANCEL_BUTTON_CAPTION: {
    description: 'Caption of Cancel button',
    primaryValue: 'Cancel',
    stringKey: 'unlink_cancel_button_caption',
  },
  UNLINK_CUSTOMER_ERROR: {
    description: 'Error displayed if profile was not unlinked from order',
    primaryValue: 'Profile was not unlinked: ',
    stringKey: 'unlink_error_response',
  },
  UNLINK_CUSTOMER_SUCCESS: {
    description: 'Success message displayed if profile was unlinked from order',
    primaryValue: 'Profile succesfully unkinked',
    stringKey: 'unlink_success_response',
  },
};

export default translations;
