/* React, etc */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation } from 'react-apollo';

/* Material-UI */
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import { getValue } from '../../../../utils/browserStorage.js';

// Hooks
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../hooks/useSnacks';
import useGetOrderDetails from '../../../../hooks/useGetOrderDetails';
// Store
import OrderContext from '../../../../store/contexts/orderContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
// Queries
import EXCHANGE_PREVIEW_QUERY from '../../../../queries/exchangePreview.query';
import EXCHANGE_SUBMIT_MUTATION from '../../../../mutations/exchangeSubmit.mutation';
// Utils
import { prepareAddress } from '../../../../utils/address';
import { getNewSkuPrice } from '../../../../utils/price';
import { getBillingEmail } from '../../../../utils/order';
import { formatPolicyAsPerRegion } from '../../../../utils/exchanges';
import { ApiTimeOut, ResponseStatuses } from '../../../../constants/dialog.const';
// Components
import { FormattedCurrency } from '../../../shared/formatCurrency';
import Loading from '../../../shared/loading';
import ItemDetails from '../shared/itemDetails';
import StyleLink from '../../content/shared/itemLink';
import ThreeColumnContainer from './threeColumnContainer';
import LabelValueText from './labelValueText';
import ExchangePriceSummary from './priceSummary';
import translations from './exchange.i18n';
import { assembleExchangeSubmitInput } from '../../../../utils/exchanges';

/**
 * Last step of exchange flow, used to review items and price info
 * @returns React Component
 */
export default function Step4() {
  const classes = useStyles();
  const [randomId] = useState(uuidv4());
  const [consumerState] = useContext(ConsumerContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const {
    selectedLines, // Items chosen from the return order
    selectedSkus, // the replacements being chosen
    exchangeCreditValue,
    exchangeCountry,
    exchangeLocale,
    isExchangeSubmitting, // trigger for when to assemble exchangeSubmit data
  } = dialogState;
  const { profileId, consumerType } = consumerState;
  const { setIsExchangeSubmitting, setIsSubmitReady, reset } = dialogActions;

  const flatSkus = useMemo(() => Object.values(selectedSkus), [selectedSkus]);

  const cartCount = flatSkus.length;

  const {
    orderNumber: returnOrderNumber,
    currency,
    billTo,
    customerProfileReference,
  } = orderDetail;
  const email = billTo?.contactInformation?.email;
  const {
    DISCOUNT,
    EXCHANGE_ORDER_CREATED,
    EXCHANGE_ORDER_NOT_AVAILABLE,
    EXCHANGE_PREVIEW_ERROR,
    EXCHANGE_PRICING_SUMMARY,
    EXCHANGE_SUBMIT_ERROR,
    FINAL_ITEM_PRICE,
    ITEMS_TOTAL,
    NEW,
    ORIGINAL,
    RETURN_ORDER,
    SHIPPING_INFORMATION,
    SHIPPING_METHOD,
    STYLE,
    VIEW_CART,
    DISCOUNT_REASON,
    NO_DISCOUNT_NEEDED,
    ITEM_DISCOUNT,
    EXCHANGE_RETURN_ALL_MESSAGE,
    EXCHANGE_VALUE_MATCH_MESSAGE,
    EXCHANGE_VALUE_DIFF_MESSAGE1,
    EXCHANGE_VALUE_DIFF_MESSAGE2,
  } = useMemoTranslations(translations);

  const { setError, setSnack } = useSnacks();

  const { runQueryOrderDetails } = useGetOrderDetails({
    followUpOnCompleted: (data) => orderDetailsOnCompleted(data),
    followUpOnError: ({ error, data, isPollingComplete }) =>
      orderDetailsOnError({ error, data, isPollingComplete }),
  });

  const orderDetailsOnCompleted = (data) => {
    const numberOfItems = data?.orderDetail?.orderLines?.length;
    const successMessage = numberOfItems
      ? `${EXCHANGE_ORDER_CREATED} (${numberOfItems} ${ITEMS_TOTAL})`
      : EXCHANGE_ORDER_CREATED;
    setSnack(successMessage);
  };

  const orderDetailsOnError = ({ error, data, isPollingComplete }) => {
    const isOrderDetail404 =
      error?.message?.includes('404') && error?.path?.toString() === 'orderDetail';
    if (isOrderDetail404 && data?.orderDetail) {
      setSnack(EXCHANGE_ORDER_CREATED);
    } else if (isPollingComplete) {
      setError(EXCHANGE_ORDER_NOT_AVAILABLE);
    }
  };

  const [getExchangePreview, { data: exchangePreviewData, loading: previewLoading }] = useLazyQuery(
    EXCHANGE_PREVIEW_QUERY,
    {
      onError: (error) => {
        setError(`${EXCHANGE_PREVIEW_ERROR}:  ${error}`);
      },
      onCompleted: () => {
        dialogDispatch(setIsSubmitReady(Boolean(exchangePreviewData.exchangePreview?.response)));
        if (exchangePreviewData.exchangePreview?.error) {
          const errorMessageOnJobCompletion = `${EXCHANGE_PREVIEW_ERROR}: ${exchangePreviewData.exchangePreview.error.message}`;
          setError(errorMessageOnJobCompletion);
        }
      },
    }
  );

  const [submitExchange] = useMutation(EXCHANGE_SUBMIT_MUTATION, {
    onError: (err) => {
      // Log to keep track of error details
      console.error('Exchange Submit Error', err);
      setError(`${EXCHANGE_SUBMIT_ERROR}: ${err.message}`);
      dialogDispatch(setIsExchangeSubmitting(false));
    },
    onCompleted: (data) => {
      const { exchangeSubmit } = data;
      if (
        exchangeSubmit.status === ResponseStatuses.COMPLETED &&
        exchangeSubmit?.response?.exchangeOrderId
      ) {
        runQueryOrderDetails({
          orderNumber: exchangeSubmit.response.exchangeOrderId,
          isFollowUp: true,
        });
      } else if (
        exchangeSubmit.status === ResponseStatuses.IN_PROGRESS ||
        exchangeSubmit.status === ResponseStatuses.PENDING
      ) {
        setError(EXCHANGE_ORDER_NOT_AVAILABLE);
      } else if (exchangeSubmit.error) {
        const errorMessageOnJobCompletion = `${EXCHANGE_SUBMIT_ERROR}: ${exchangeSubmit.error.message}`;
        setError(errorMessageOnJobCompletion);
        dialogDispatch(reset());
      }
      dialogDispatch(setIsExchangeSubmitting(false));
      // Success message is displayed via `orderDetailsOnCompleted` callback function
    },
  });

  /*
    Listen for change to dialogState.isExchangeSubmitting, and proceed
    accordingly
  */
  useEffect(() => {
    const userInfo = {
      identityType: consumerType,
    };

    if (!customerProfileReference) {
      userInfo.email = email;
      userInfo.upmId = randomId;
    } else {
      userInfo.upmId = profileId;
    }
    if (isExchangeSubmitting) {
      const parentSalesOrder = getValue('parentSalesOrder');
      const exchangeSubmitRequest = assembleExchangeSubmitInput(
        exchangePreviewData,
        email,
        parentSalesOrder
      );
      submitExchange({
        variables: {
          input: exchangeSubmitRequest,
          timeout: ApiTimeOut,
          userInfo,
        },
      });
    }
    return () => dialogDispatch(setIsExchangeSubmitting(false));
  }, [isExchangeSubmitting]);

  useEffect(() => {
    const userInfo = {
      identityType: consumerType,
    };

    if (!customerProfileReference) {
      userInfo.email = email;
      userInfo.upmId = randomId;
    } else {
      userInfo.upmId = profileId;
    }
    getExchangePreview({
      variables: {
        input: {
          parentReturnOrderNumber: returnOrderNumber,
          email: getBillingEmail(orderDetail),
          country: exchangeCountry,
          currency,
          locale: exchangeLocale,
          items: flatSkus.map(({ selectedSize, discount, priceInfo, omoboGeneratedId }) => {
            const amountPerUnit = priceInfo?.total - getNewSkuPrice(priceInfo?.total, discount);
            const roundedDiscount = Math.round(amountPerUnit * 100) / 100;
            return {
              id: omoboGeneratedId,
              skuId: selectedSize.skuId,
              quantity: 1,
              priceAdjustments: {
                discount: {
                  id: uuidv4(),
                  amountPerUnit: roundedDiscount,
                  type: 'PRICE_OVERRIDE',
                  reasonCode: discount?.reason?.id || '',
                },
              },
            };
          }),
        },
        timeout: ApiTimeOut,
        userInfo,
      },
    });
  }, []);

  const displaySelectedReplacement = (orderLineNumber) => {
    const item = selectedSkus[orderLineNumber];
    if (!item) return null;
    // Reconstructing item to match the structure being used by the orderLines in orderDetails.
    const newItem = {
      item: {
        itemDescription: selectedLines[orderLineNumber]?.item?.itemDescription,
      },
      colorDescription: item.colorDescription,
      styleNumber: item.styleName,
      colorCode: item.colorCode,
      linePriceInformation: {
        retailPrice: item.priceInfo.total,
      },
      displaySize: item.selectedSize.localizedSize,
      quantity: 1,
    };
    return (
      <ItemDetails
        testid={`replacement-item-${orderLineNumber}`}
        item={newItem}
        displayProductDetailsLink={true}
      />
    );
  };

  const generateItemRows = () =>
    Object.keys(selectedLines).map((orderLineNumber) => {
      const originalItem = selectedLines[orderLineNumber];

      return (
        <div className={classes.step} key={`item-row-${orderLineNumber}`}>
          {/* Original Item Description */}
          <div className={classes.itemHeader}>
            <Typography display={'inline'} className={classes.columnHeaders}>
              {originalItem.item.itemDescription}&nbsp;
            </Typography>
            <span className={classes.typographySpacer}></span>
            <Typography display={'inline'} className={classes.greyLabel} style={{ height: '100%' }}>
              {STYLE}:&nbsp;
              <StyleLink orderDetail={orderDetail} line={selectedLines[orderLineNumber]} />
            </Typography>
          </div>
          {/* Display items being traded, traded for and discount section */}
          <ThreeColumnContainer
            headers={[ORIGINAL, NEW, DISCOUNT]}
            firstColumn={
              <ItemDetails
                testid={`original-item-${orderLineNumber}`}
                item={selectedLines[orderLineNumber]}
              />
            }
            secondColumn={displaySelectedReplacement(orderLineNumber)}
            thirdColumn={generateDiscountSection(orderLineNumber)}
          />
          <div style={{ marginBottom: '1rem' }}></div>
          <Divider variant='middle' />
        </div>
      );
    });

  const generateDiscountSection = (lineNumber) => {
    const sku = selectedSkus[lineNumber];
    const discountDescription = sku?.discount?.reason?.description;
    const lineId = sku.omoboGeneratedId;
    const fulfillmentGroups = exchangePreviewData?.exchangePreview?.response?.fulfillmentGroups;

    const orderLineFromPreviewResponse = fulfillmentGroups
      ?.flatMap((group) => group.items)
      .find((item) => item.id === lineId);

    const { total, discount } = orderLineFromPreviewResponse?.itemCosts?.priceInfo || {};
    const totalPrice = total - discount;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box className={classes.discountSummary} key={`sku-row-${lineNumber}`}>
          <Box sx={{ justifyContent: 'space-between' }} data-testid={`line-${lineNumber}-discount`}>
            <LabelValueText
              label={ITEM_DISCOUNT + ' '}
              value={discount ? <FormattedCurrency amount={discount} currency={currency} /> : 'N/A'}
            />
            <LabelValueText
              label={DISCOUNT_REASON + ' '}
              value={discount ? discountDescription : NO_DISCOUNT_NEEDED}
            />
          </Box>
          <Box
            sx={{ justifyContent: 'end' }}
            data-testid={`line-${lineNumber}-discount-final-price`}>
            <Typography className={classes.greenLabel}>
              {FINAL_ITEM_PRICE}
              {': '}
              <FormattedCurrency amount={totalPrice} currency={currency} />
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const currencyForMessage = (amount, currency, key) => (
    <span key={key} style={{ fontWeight: 'bold' }}>
      <FormattedCurrency amount={amount} currency={currency} />
    </span>
  );

  const generateShippingPricing = () => {
    const exchangePriceSummaryData = exchangePreviewData?.exchangePreview.response?.priceSummary;
    const { total, discount, taxTotal } = exchangePriceSummaryData || {};
    const combinedTotal = total + taxTotal - discount;
    const exchangeSummary = (
      <Box>
        <Typography className={classes.columnHeaders}>{EXCHANGE_PRICING_SUMMARY}</Typography>
        <ExchangePriceSummary
          exchangePriceSummaryData={exchangePriceSummaryData}
          currency={currency}
          exchangeCreditValue={exchangeCreditValue}
        />
        {exchangeCreditValue >= combinedTotal && (
          <>
            <InfoIcon className={classes.infoIcon} />
            <Typography
              data-testid='exchange-message-1'
              variant='body2'
              className={classes.viewExchangeVerbiage}>
              {exchangeCreditValue === combinedTotal
                ? [
                    currencyForMessage(exchangeCreditValue, currency, 1),
                    ` ${EXCHANGE_VALUE_MATCH_MESSAGE}`,
                  ]
                : [
                    currencyForMessage(exchangeCreditValue - combinedTotal, currency, 1),
                    ` ${EXCHANGE_VALUE_DIFF_MESSAGE1} `,
                    currencyForMessage(combinedTotal, currency, 2),
                    ` ${EXCHANGE_VALUE_DIFF_MESSAGE2}`,
                  ]}
            </Typography>
            <InfoIcon className={classes.infoIcon} />
            <Typography
              data-testid='exchange-message-2'
              variant='body2'
              className={classes.viewExchangeVerbiage}>
              {formatPolicyAsPerRegion(
                EXCHANGE_RETURN_ALL_MESSAGE,
                orderDetail?.omsRegionReference
              )}
            </Typography>
          </>
        )}
      </Box>
    );

    const shippingSummary = () => {
      const firstSelectedLine = selectedLines[Object.keys(selectedLines)[0]];
      return (
        <Box>
          <Typography className={classes.columnHeaders}>{SHIPPING_INFORMATION}</Typography>
          {prepareAddress(firstSelectedLine)?.map((addressLine, i) => (
            <Typography key={`shippingInfoLine-${i}`} className={classes.shippingInfoLine}>
              {addressLine}
            </Typography>
          ))}
          <Typography className={classes.shippingInfoLine}>
            {SHIPPING_METHOD}: {firstSelectedLine.shippingMethod}
          </Typography>
        </Box>
      );
    };
    return <ThreeColumnContainer thirdColumn={exchangeSummary} firstColumn={shippingSummary()} />;
  };

  return previewLoading ? (
    <Loading />
  ) : (
    <>
      <Box>
        <div>
          <Divider variant='middle' />
          {/* Header */}
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 1rem',
            }}>
            <Typography
              variant={'h5'}
              component={'h2'}
              style={{ margin: '.5rem 0' }}
              className={classes.greyLabel}>
              {VIEW_CART} (<span className={classes.innerLabel}>{cartCount}</span>)
            </Typography>
            <div>
              <Typography variant={'subtitle2'} component={'h2'} className={classes.greyLabel}>
                {RETURN_ORDER}: <span className={classes.innerLabel}>{returnOrderNumber}</span>
              </Typography>
            </div>
          </Box>
          <Divider variant='middle' />
        </div>
        {/* Items to display */}
        <>{generateItemRows()}</>

        {/* Pricing Summary */}
        <>{generateShippingPricing()}</>
        <Divider variant='middle' />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  itemHeader: {
    marginBottom: '1rem',
  },
  innerLabel: {
    color: theme.palette.common.black,
  },
  typographySpacer: {
    marginLeft: '0.5rem',
  },
  step: {
    margin: '1rem',
  },
  greyLabel: {
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  columnHeaders: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
  discountSummary: {
    'width': '100%',
    '& .MuiBox-root': {
      display: 'flex',
    },
  },
  greenLabel: {
    color: theme.palette.success.dark,
    fontSize: '1.25rem',
    paddingTop: '1rem',
  },
  shippingInfoLine: {
    fontSize: '1.25rem',
    padding: '.5rem 0 0 0',
  },
  viewExchangeVerbiage: {
    fontWeight: 400,
    lineHeight: '21px',
    fontSize: '14px',
    marginLeft: '30px',
    verticalALign: 'top',
  },
  infoIcon: {
    fontSize: 18,
    lineHeight: '21px',
    position: 'absolute',
    marginTop: '3px',
  },
}));
