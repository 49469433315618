/**
 * takes in data from the personalization query and maps it to data from the pidSearch query
 * @param {Array} pidQueryData data from the pidSearch in place
 * @param {Array} allPidTextData data from personalization query
 * @return {Array} new array composed of pidQueryData and allPidTextData data
 */
export const mapTextToPid = (pidQueryData, allPidTextData) => {
  const cache = {};
  const result = [];
  /**
   * index used to cycle through allPidTextData. kept here so we don't reset it per loop and
   * cycle through cached data
   */
  let i = 0;

  for (let pid of pidQueryData) {
    const { id } = pid;
    // check if the pid we're on has matching pid text in the cache
    if (cache[id]) {
      result.push({ ...pid, pidTextInfo: cache[id] });
      // don't continue with below code in this iteration
      continue;
    }
    while (allPidTextData[i]) {
      const pidTextInfo = allPidTextData[i];
      /**
       * if the text we're looking at matches the pid we want to stop the loop and move on to the
       * next pid so we'll break out of the loop. doing this to try to save memory and a bit of time
       */
      if (pidTextInfo.pidReviewId === id) {
        result.push({ ...pid, pidTextInfo: pidTextInfo.details });
        i++;
        break;
      }
      cache[pidTextInfo.pidReviewId] = pidTextInfo.details;
      i++;
    }
    if (result[result.length - 1]?.id !== id) {
      result.push({ ...pid, pidTextInfo: [] });
    }
  }
  return result;
};

/**
 * Returns inline validation rule for a given pid text
 *
 * @param {string} referenceId - id for validation rule
 * @param {array} rules - array of inline validation rules state
 * @returns {string} string to use for regex validation
 */
export const getRule = (referenceId, rules) => {
  const [rule] = rules.filter((rule) => {
    return rule?.id?.includes(referenceId);
  });
  return rule?.validationRule;
};

/**
 * Returns default inline validation state (all valid) for a pidTextInfo (all pid texts for order)
 *
 * @param {PidTextInfo[]} pidTextInfo - an array of personalization fields
 * @returns {object} inline validation state with all fields (id) set to true
 */
export const defaultInlineValid = (pidTextInfo) => {
  const state = {};
  pidTextInfo.forEach((detail) => {
    state[detail.id] = true;
  });
  return state;
};
