import gql from 'graphql-tag';

const PID_INLINE_VALIDATION_QUERY = gql`
  query getConsumerDesign($customizedProductReference: String!) {
    consumerDesigns(customizedProductReference: $customizedProductReference) {
      objects {
        validationRules {
          validationRule
          id
        }
      }
    }
  }
`;

export default PID_INLINE_VALIDATION_QUERY;
