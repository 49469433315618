const translations = {
  SALES_ORDER: {
    description: 'Sales Order Header',
    primaryValue: 'Sales Order:',
    stringKey: 'salesOrder',
  },
  RETURN_ORDER: {
    description: 'Return Order Header',
    primaryValue: 'Return Order(s):',
    stringKey: 'returnOrders',
  },
  EXCHANGE_ORDER: {
    description: 'Exchange Order Header',
    primaryValue: 'Exchange Order:',
    stringKey: 'exchangeOrder',
  },
  RELATED_ORDERS: {
    description: 'Related Order Header',
    primaryValue: 'Related Orders',
    stringKey: 'relatedOrders',
  },
  EXCHANGE_ORDERS_RETURNS: {
    description:
      'when the current exchange order has been returned we show that return order number here',
    primaryValue: "Exchange Order's Returns",
    stringKey: 'exchangeOrdersReturns',
  },
};
export default translations;
