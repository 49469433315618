import React, { createContext, useReducer } from 'react';
import { consumerReducer } from '../reducers/consumerReducer';

export const ConsumerContext = createContext({});
const { Provider } = ConsumerContext;

export const defaultState = {
  profileId: null,
  isRegistered: false,
  isSwooshMember: false,
  firstName: '',
  middleName: '',
  lastName: '',
  kanaFirstName: '',
  kanaLastName: '',
  consumerType: '',
  consumerName: '',
  consumerEmail: '',
};

/**
 * A typical provider pattern for a context with a default state and a reducer
 *
 * @param {Object} props – React props
 */
export const ConsumerProvider = ({ children }) => {
  const [state, reducer] = useReducer(consumerReducer, defaultState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
