/** React/Utils */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import TodayIcon from '@material-ui/icons/Today';
import CircularProgress from '@material-ui/core/CircularProgress';
import { putValue } from '../../../../utils/browserStorage.js';

/** Local */
import Geo from '../../../../constants/geos.const';
import { ReturnMethods } from '../../../../constants/order.const';
import OrderContext from '../../../../store/contexts/orderContext';
import { SearchContext } from '../../../../store/contexts/orderSearchContext';
import searchActions from './../../../../store/actions/orderSearchActions';
import STORE_LOCATION_QUERY from '../../../../queries/storeLocation.query';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import { formatDateTime } from '../../../../utils/date';
import { getArrayProperty } from '../../../../utils/getArrayProperty';
import { getShipGroup, lineShipmentMatching } from '../../../../utils/shipment';
import { separateCancelledItems, separateUncategorizedItems } from '../../../../utils/order';
import useSnacks from '../../../../hooks/useSnacks';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import ErrorBoundary from '../../../error/errorBoundary';
import ItemDetails from '../shared/itemDetails';
import translations from './details.i18n';
import ShipmentDetails from './shipmentDetails';
import ReturnLabelDetails from './returnLabelDetails';
import ExpandedItem from './expandedItem';
import DetailsCard from './detailsCard';
import InfoHeadingCard from './infoHeadingCard';
import { useBopisHeader } from './hooks/useBopisHeader';

/**
 * Main react component housing the item details tab, calls itemDetails for the content of each card
 */
export default function Details() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [searchState, searchDispatch] = useContext(SearchContext);
  const { setShowSearchPage } = searchActions;
  const { setError } = useSnacks();
  const setRoute = useHistoryPushWithSessionId();

  let {
    orderLines = [],
    shipmentsV2,
    csOrderSummary: linkedOrders,
    exchangeOrderSummary,
    orderClassification,
    orderHeaderKey,
    orderHolds,
    orderType,
    parentReturnOrder,
    status,
    store,
    locale,
    pendingModification,
    omsRegionReference,
  } = orderDetail;

  const isReturnOrder = orderType === 'RETURN_ORDER';
  const isBOPIS = orderDetail?.omoboFlags?.isBOPIS;

  // links will hold the linked orders data (csOrderSummary in order details).
  const links = getArrayProperty(linkedOrders, 'objects');

  // exchangeOrderLinks will hold the exchange orders data (exchangeOrderSummary in order details).
  const exchangeOrderLinks = getArrayProperty(exchangeOrderSummary, 'objects');

  // shipments contains shipping details of an order (shipments in order details).
  const shipments = getArrayProperty(shipmentsV2, 'objects');

  const {
    ARIA_SEARCH_BACK,
    ARIA_STORE_LOADING,
    BACK_TO_SEARCH_RESULTS,
    BOPIS_ORDER,
    BURN_GIFTCARD_ERROR,
    ERROR_FROM_BOM_API,
    CANCELED_ITEMS,
    HOLD,
    ITEMS_AWAITING_SHIPMENT,
    ORDER_DETAILS,
    PURCHASED_ITEMS,
    PUT_FUNDS_ON_HOLD,
    RETURN_LABEL,
    RETURN_ORDER,
    SHIPMENT,
    STORE_ERROR,
    STORE_ORDER,
    UNBRIDGED_ORDER_MESSAGE,
    PENDING_MOD_MESSAGE,
    UNCATEGORIZED_ITEMS,
    NONPICKUP_FRIENDLY,
  } = useMemoTranslations(translations, i18nString);

  let isStoreOrder = orderClassification?.includes('STORE');

  // if return order, have to get the parent sales order to get the store id
  const parentSalesOrderNumber = isReturnOrder && orderDetail?.orderLines[0].parentSalesOrderNumber;

  /* making another order detail query for store return orders because
   they do NOT have the store id in their order detail payload (this pains me)
   therefore, the parent sales order of the return order has to be queried to get the store id */
  const { data: parentData, loading: parentLoading, error: parentError } = useQuery(
    ORDER_DETAIL_QUERY,
    {
      variables: {
        orderNumber: parentSalesOrderNumber,
      },
      skip: !parentSalesOrderNumber,
      notifyOnNetworkStatusChange: true,
    }
  );
  putValue('parentSalesOrder', parentData?.orderDetail);
  /* if order detail data is returned with NIKEID order lines that are missing BOM data,
   check to see, display error snack re: BOM data missing. */
  useEffect(() => {
    let isBOM404 = false;
    orderDetail?.orderLines?.forEach((line) => {
      if (line.orderLineType === 'NIKEID') {
        const bomDetails = line?.bomDetails?.GetBuildBOMConfigurationService?.bom?.comps?.comp;
        if (!bomDetails) {
          isBOM404 = true;
        }
      }
    });

    if (isBOM404 && process.env.PUBLIC_URL !== '/portlets/postpurchase') {
      setError(ERROR_FROM_BOM_API);
    }
  }, [orderDetail, parentError]);

  /**
   * if sales store order, get from order details.
   * if return store order, get from the parent order details
   */
  const storeIdToQuery = isReturnOrder ? parentData?.orderDetail?.store?.storeId : store?.storeId;

  // query to get store name from store id
  const {
    data: storeLocationData,
    loading: storeLocationLoading,
    error: storeLocationError,
  } = useQuery(STORE_LOCATION_QUERY, {
    variables: {
      id: storeIdToQuery,
    },
    skip: !storeIdToQuery,
    notifyOnNetworkStatusChange: true,
  });

  let storeTitle = null;

  // conditional logic to set store title
  if ((storeLocationError && storeIdToQuery) || (parentError && parentSalesOrderNumber)) {
    // error, so display store error message
    storeTitle = STORE_ERROR;
  } else if (storeLocationLoading || parentLoading) {
    // loading, so display a spinner
    storeTitle = (
      <CircularProgress
        aria-label={ARIA_STORE_LOADING}
        className={classes.loading}
        size={'1.5rem'}
      />
    );
  } else if (storeLocationData) {
    // else, set the store title in correct format
    storeTitle =
      `${STORE_ORDER} - ` + storeLocationData.storeLocation.name + ' #' + store.storeNumber;
  }

  const orderLinesWithLinkedOrders = orderLines.map((line) => {
    /*
     Since all the order lines in a return order can only be created from the same sales order
     we are adding the sales order details to each order line with out any check
     */
    if (orderType === 'RETURN_ORDER' || parentReturnOrder) {
      line.linkedOrders = links;
      line.exchangeOrders = exchangeOrderLinks;
    } else {
      line.linkedOrders = links.filter((order) =>
        order.orderLines.some((lines) => {
          return (
            lines.parentSalesOrderLineKey &&
            lines.parentSalesOrderLineKey.includes(line.orderLineKey)
          );
        })
      );
    }
    return line;
  });
  /**
   * Drills down to the container level to get the orderLineKeys
   * associated with this shipment, and matches them to orderLineKeys in current OrderDetail
   */
  const { shippingContainers, allShippedOrderLines } = lineShipmentMatching(shipments, orderLines);

  /*
   * nonShippedLineOrders are orderLines
   * a) that are not part of allShippedOrderLines
   * b) orderLines without orderLineKey (This condition is for newly created return orders
   *    or when not synced to DOMs)
   */
  const nonShippedOrderLines = orderLinesWithLinkedOrders.filter((line) => {
    return line.orderLineKey ? !allShippedOrderLines.has(line.orderLineKey) : true;
  });

  /**
   * For orderline with multiple statuses like Cancelled and delivered ones,
   *  orderLinesWithLinkedOrders is covering both
   */

  const [nonCancelledItems, cancelledItems] = separateCancelledItems(orderLinesWithLinkedOrders);

  /**
   * For all orders except return orders `nonCancelledNonShipped` will only include
   *  non-cancelled-non-shipped items
   */
  const [nonCancelledNonShipped] = separateCancelledItems(
    isReturnOrder ? orderLinesWithLinkedOrders : nonShippedOrderLines
  );

  /**
   * For store orders we don't want a item with a home delivery to slip into the "Store Order" card
   * For other orders we want to throw shipped items without shipment info into 'uncategorized` card
   * this operation may MUTATE `itemsWithoutShipment` array
   */
  const uncategorizedItems = separateUncategorizedItems(
    nonCancelledNonShipped,
    storeLocationData?.storeLocation?.address,
    isStoreOrder,
    isReturnOrder
  );

  /**
   * handles when "back to search results" link is clicked
   */
  const handleLinkClick = () => {
    searchDispatch(setShowSearchPage(true));
    setRoute(`${process.env.PUBLIC_URL}`);
  };

  const bopisHeader = useBopisHeader({ status, nonCancelledItems, isBOPIS });
  const returnHeader =
    omsRegionReference === Geo.KOREA &&
    orderDetail?.returnMethod === ReturnMethods.receivedAtWarehouse
      ? `${RETURN_ORDER} (${NONPICKUP_FRIENDLY})`
      : RETURN_ORDER;

  let mainCardHeading;

  if (isBOPIS)
    // prevent duplicate BOPIS headers by spreading a set into an array
    mainCardHeading = [...new Set([bopisHeader, BOPIS_ORDER])];
  else if (isReturnOrder) mainCardHeading = returnHeader;
  else if (isStoreOrder) mainCardHeading = [PURCHASED_ITEMS, storeTitle];
  else mainCardHeading = ITEMS_AWAITING_SHIPMENT;

  const geosNotAllowingReturnLabel = new Set([
    Geo.CHINA,
    Geo.KOREA,
    Geo.JAPAN,
    Geo.NIKEGS,
    Geo.NIKEXA,
  ]);

  return (
    <ErrorBoundary>
      {Object.entries(searchState.searchResults).length > 1 &&
        !searchState.searchResults[0].shipmentIdentifier && (
          <Typography className={classes.backToSearch}>
            <Link
              aria-label={ARIA_SEARCH_BACK}
              component='button'
              onClick={() => handleLinkClick()}>
              {'< '}
              {BACK_TO_SEARCH_RESULTS}
            </Link>
          </Typography>
        )}
      <h1 className='accessibly-hidden'>{ORDER_DETAILS}</h1>

      {/** WARNINGS AND ERROR NOTIFICATIONS */}

      {/* "Order Modifications are Not Allowed" with exception
          to cancel and returns on digital and store orders */}
      {!orderHeaderKey && (
        <InfoHeadingCard headings={UNBRIDGED_ORDER_MESSAGE} data-testid='order-not-synced' error />
      )}

      {/* "Pending Modification" */}
      {pendingModification && (
        <InfoHeadingCard
          headings={`${pendingModification?.type || ''} ${PENDING_MOD_MESSAGE}`}
          data-testid='pending-mod-message'
          warning
          error
        />
      )}

      {/* Order Line Errors (e.g. burnGiftCards errors on specific order lines) */}
      {orderDetail?.orderLines?.some((line) => line.error?.message) && (
        <InfoHeadingCard
          error={true}
          headings={[
            <>
              <Typography variant='h6' color='error'>
                {BURN_GIFTCARD_ERROR} {PUT_FUNDS_ON_HOLD}
              </Typography>
              <List data-testid='order-line-error-list'>
                {orderDetail?.orderLines.map((line) =>
                  line.error?.message ? (
                    <ListItem key={line.lineNumber}>
                      <Typography variant='body1' color='error'>
                        {line?.error?.message}
                      </Typography>
                    </ListItem>
                  ) : null
                )}
              </List>
            </>,
          ]}
          data-testid='order-line-errors'
        />
      )}

      {/* Order Holds */}
      {orderHolds &&
        orderHolds
          .filter((hold) => hold.status !== 'Resolved')
          .map((holds, i) => (
            <InfoHeadingCard
              data-testid={`order-hold-${i}`}
              key={i}
              headings={
                <>
                  {holds.type + ' ' + HOLD + ' '}
                  <Tooltip title={formatDateTime(holds.lastHoldDate, locale)}>
                    <TodayIcon name='hold' className={classes.holdIcon} />
                  </Tooltip>
                </>
              }
              error
            />
          ))}

      {/* ORDER ITEMS INFORMATION */}

      {/* Return Order Labels (for Return Order and if present) */}
      {isReturnOrder && !geosNotAllowingReturnLabel.has(omsRegionReference) && (
        <DetailsCard
          headings={RETURN_LABEL}
          content={
            // this seems to be here because API may sometimes falsely return no shipment info
            (shipments?.length > 0 ? shipments : [{}]).map((shipment, i) => (
              <ReturnLabelDetails key={shipment?.id || i} shipment={shipment} locale={locale} />
            ))
          }
          data-testid='return-label-details'
        />
      )}

      {/* Main Card */}
      {/* Depending on the type of order, either of two:
       * a) sales order: nonCancelledNonShipped items without shipments
       * b) return order: all non-cancelled items
       * */}
      {nonCancelledNonShipped.length > 0 && (
        <DetailsCard
          headings={mainCardHeading}
          data-testid='items-without-shipments'
          content={
            <ItemDetails orderLines={nonCancelledNonShipped} ExpandedContent={ExpandedItem} />
          }
        />
      )}

      {/* Shipment Cards for Regular Sales Orders: 1 card/shipment */}
      {!isReturnOrder &&
        nonCancelledItems.length > 0 &&
        shipments.map((shipment, i) => (
          <DetailsCard
            headings={[`${SHIPMENT} ${getShipGroup(shipment) || ''}`, isStoreOrder && storeTitle]}
            key={shipment?.id || i}
            data-testid={`shipment-details-${i}`}
            content={
              <>
                <ShipmentDetails
                  orderLines={orderLinesWithLinkedOrders}
                  shipment={shipment}
                  locale={locale}
                />
                <ItemDetails
                  allOrderLines={orderLines}
                  orderLines={nonCancelledItems.filter((line) => {
                    return line.orderLineKey && shippingContainers[i].includes(line.orderLineKey);
                  })}
                  shipment={shipment}
                  csOrderSummary={linkedOrders}
                  ExpandedContent={ExpandedItem}
                />
              </>
            }
          />
        ))}

      {/* Cancelled Items */}
      {cancelledItems.length > 0 && (
        <DetailsCard
          headings={[
            CANCELED_ITEMS,
            isBOPIS && BOPIS_ORDER,
            isReturnOrder && RETURN_ORDER,
            isStoreOrder && storeTitle,
          ]}
          data-testid='canceled-items'
          content={<ItemDetails orderLines={cancelledItems} ExpandedContent={ExpandedItem} />}
        />
      )}

      {/* Generic "Uncategorized Items" Card */}
      {/* Includes:
       * a) Store orders with non-store delivery
       * b) Other orders when they are shipped but have no shipping info
       */}
      {uncategorizedItems.length > 0 && (
        <DetailsCard
          headings={[UNCATEGORIZED_ITEMS, isStoreOrder && storeTitle]}
          data-testid='uncategorized-lines'
          content={<ItemDetails orderLines={uncategorizedItems} ExpandedContent={ExpandedItem} />}
        />
      )}
    </ErrorBoundary>
  );
}

const useStyles = makeStyles(() => ({
  backToSearch: {
    display: 'flex',
    paddingTop: '12px',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  holdIcon: {
    paddingTop: '1px',
    marginBottom: '-4px',
    color: 'gray',
  },
}));
