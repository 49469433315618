import { DiscountTypes } from './../constants/dialog.const';
import { getDiscount, getCharges, getTaxDiscounts, getTax } from '../utils/order';

/**
 * calculates new unit price of a line item after applying discount
 * @param {*} line - selected orderline
 */
export const getNewUnitPrice = (line) => {
  let discountedPrice = getUnitPrice(line);
  switch (line.discountType) {
    case DiscountTypes.PERCENT_OFF:
      discountedPrice = discountedPrice - (discountedPrice * line.discountValue) / 100;
      break;
    case DiscountTypes.AMOUNT_OFF:
      discountedPrice = discountedPrice - line.discountValue;
      break;
    case DiscountTypes.EXACT_PRICE:
      discountedPrice = line.discountValue;
      break;
    default:
      break;
  }
  return discountedPrice;
};

/**
 * return true if any if any of the price validation fails
 * @param {*} line - selected order line
 */
export const showDiscountValidationError = (line) => {
  const unitPrice = line.linePriceInformation.unitPrice;
  let invalidDiscount = false;

  /*
    Set discount validation error to true if
    a) the new unit price (after applying discount) is less than zero
    b) the discount value is greater than original price when discount type
    selected is either Exact Match/Dollar Off  
  */
  if (
    (line.discountType === DiscountTypes.AMOUNT_OFF ||
      line.discountType === DiscountTypes.EXACT_PRICE) &&
    line.discountValue > unitPrice
  ) {
    invalidDiscount = true;
  }
  return Boolean(getNewUnitPrice(line, line.linePriceInformation.unitPrice) < 0) || invalidDiscount;
};

/* this function returns sub total of a line item
  sub total includes discount but does not include taxes
*/
export const getUnitPrice = (line) => {
  const unitPriceTotal = line.linePriceInformation?.unitPrice;
  const totalItemDiscount = getDiscount(line) / line.quantity;
  const charges = getCharges(line);
  const subtotal = unitPriceTotal - totalItemDiscount + charges;
  return subtotal;
};

/**
 * calculates new unit price of a line item after applying discount
 * @param {number} total - current unit price of item
 * @param {object} discount - current discount data
 *
 * @return {number|null} Returns null if total is falsy, otherwise returns a number
 */
export const getNewSkuPrice = (total, discount) => {
  if (!total) return total;
  if (!discount || !discount?.value) return total;

  let discountedPrice = total;
  switch (discount.type) {
    case DiscountTypes.PERCENT_OFF:
      discountedPrice = discountedPrice - (discountedPrice * discount.value) / 100;
      break;
    case DiscountTypes.AMOUNT_OFF:
      discountedPrice = discountedPrice - discount.value;
      break;
    case DiscountTypes.EXACT_PRICE:
      discountedPrice = discount.value;
      break;
    default:
      break;
  }
  return Number(discountedPrice);
};

/**
 * @param {*} line from orderDetail orderLines array
 * @param {Boolean} isMilitaryAddress from dialog state
 * @returns {Float} the price a consumer spent on a line sans shipping
 */
export const getLineTotal = (line, isMilitaryAddress) => {
  const unitPrice = getUnitPrice(line);
  const unitPriceTotal = unitPrice * line.quantity;
  const militaryDiscount = isMilitaryAddress ? getDiscount(line) : 0;

  return Number(
    (unitPriceTotal - getTaxDiscounts(line) - militaryDiscount + getTax(line)).toFixed(2)
  );
};
