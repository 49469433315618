import { getKeyValueFromEvent } from '../../utils/reactUtils';
import dialogActions from '../../store/actions/dialogActions';

/**
 * This function offers a concise means of handling basic changes to an address field, updating the
 * appropriate field of the appropriate address in the dialog context.
 *
 * @param keyParam - which field to change on the address in the dialog context
 * @param isGiftCardAddress - whether or not this change is for the gift card address
 * @param event {object} - the event object created by the change
 * @param dialogDispatch - the dialogDispatch function (since this handleFieldChange function isn't
 * part of a component, dialogDispatch must be passed in)
 *
 * @returns the key and value, in case anything more needs to be done with them
 */
export const handleFieldChange = (keyParam, isGiftCardAddress, event, dialogDispatch) => {
  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const { value } = getKeyValueFromEvent(event);
  const key = typeof keyParam === 'string' ? keyParam : event?.target?.dataset?.key;
  // only dispatch an event if we have a valid key
  if (key) {
    if (isGiftCardAddress) {
      dialogDispatch(setGCShippingAddressField(key, value));
    } else {
      dialogDispatch(setAddressField(key, value));
    }
  }
  return { key, value };
};
