/** React/Utils */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

/** Material UI */
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import CLOSE_TRANSACTION_MUTATION from '../../../../../mutations/closeTransaction.mutation';
import { ApiTimeOut } from '../../../../../constants/dialog.const';
import useSnacks from '../../../../../hooks/useSnacks';
import SimpleDialog from '../../../../shared/simpleDialog';
import translation from './openTransactionStatus.i18n';

/**
 * Component that displays modal window with unlinking customer profile functionality
 */
const OpenTransactionStatus = ({ transaction, omsRegion, orderNumber, onCloseTransaction }) => {
  const {
    DIALOG_TITLE,
    DESCRIPTION,
    CLOSE_BUTTON_CAPTION,
    CLOSE_TRANSACTION_ERROR,
    CLOSE_TRANSACTION_SUCCESS,
  } = useMemoTranslations(translation);

  const classes = useStyles();
  const { setSnack, setError } = useSnacks();
  const [isOpen, setIsOpen] = useState(false);

  const switchDialog = () => {
    setIsOpen(!isOpen);
  };

  // Mutation that calls link/unlink member profile
  const [closeTransactionHandler] = useMutation(CLOSE_TRANSACTION_MUTATION, {
    onError: (err) => {
      setError(`${CLOSE_TRANSACTION_ERROR} ${err.message}`);
    },
    onCompleted: () => {
      setSnack(`${CLOSE_TRANSACTION_SUCCESS}`);
      setIsOpen(false);
      onCloseTransaction();
    },
  });

  // Function to trigger postCustomerProfile call
  const handleCloseTransaction = () => {
    closeTransactionHandler({
      variables: {
        input: {
          requestId: `${uuidv4()}`,
          chargeTransactionKey: transaction.chargeTransactionKey,
          omsRegionReference: omsRegion,
          processedAmount: transaction.requestAmount,
          requestAmount: transaction.requestAmount,
        },
        orderNumber: orderNumber,
        timeout: ApiTimeOut,
      },
    });
  };

  return (
    <>
      <Typography
        variant='subtitle2'
        data-testid={`close-transaction-button-${transaction.chargeTransactionKey}`}
        className={`${classes.wrapIcon} ${classes.clickable}`}
        onClick={switchDialog}>
        {transaction.status}
        <CloseIcon
          data-testid={`close-transaction-cross-${transaction.chargeTransactionKey}`}
          className={classes.crossIcon}
        />
      </Typography>
      <SimpleDialog
        isOpen={isOpen}
        setIsOpen={switchDialog}
        confirmOptions={{ action: handleCloseTransaction, label: CLOSE_BUTTON_CAPTION }}
        testId={`close-transaction-dialog-title-${transaction.chargeTransactionKey}`}
        title={DIALOG_TITLE}
        content={
          <DialogContent>
            <DialogContentText
              data-testid={`close-transaction-dialog-description-${transaction.chargeTransactionKey}`}>
              {DESCRIPTION}
            </DialogContentText>
          </DialogContent>
        }></SimpleDialog>
    </>
  );
};

OpenTransactionStatus.propTypes = {
  transaction: PropTypes.object.isRequired,
  omsRegion: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  onCloseTransaction: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
  },
  crossIcon: {
    color: theme.palette.secondary.dark,
    marginTop: '-1px',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}));

export default OpenTransactionStatus;
