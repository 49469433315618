const translations = {
  CANNOT_ENTER_SPECIFIED_FLOW: {
    stringKey: 'cannotEnterSpecifiedFlow',
    primaryValue: 'The following flow is not available:',
    description: 'Error message to display when the specified flow is not available.',
  },
  ERROR_FROM_GIFT_CARD_API: {
    stringKey: 'errorFromGiftCardAPI',
    primaryValue: 'Error from gift card API',
    description: 'Error message from gift card api service',
  },
  MISSING_ORDER_LINES_ERROR: {
    stringKey: 'missingOrderLinesError',
    primaryValue: 'Order data is not available',
    description: 'Error message when data is incomplete',
  },
  KOREAN_DATA_ACCESS_ERROR: {
    stringKey: 'koreanDataAccessError',
    primaryValue: 'Can not access/modify Korea consumer data',
    description: 'Error message when user does not have permission to view Korean PII data',
  },
};

export default translations;
