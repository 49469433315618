/**
 * Util function prepares the appropriate language for the selected one
 * @param {string} language - selected language
 */
export const appropriateLanguage = (language) => {
  switch (language) {
    case 'us': {
      return 'en-US';
    }
    case 'ko': {
      return 'ko-KR';
    }
    case 'zh-Hans': {
      return 'zh-CN';
    }
    case 'ja': {
      return 'ja-JP';
    }
    default: {
      return 'en-US';
    }
  }
};
