const translations = {
  ARIA_NAV_DRAWER: {
    stringKey: 'ariaExpandedNav',
    primaryValue: 'secondary nav drawer',
    description: 'aria label for expandable navigation drawer',
  },
  ARIA_SECONDARY_NAV: {
    stringKey: 'ariaSecondaryNav',
    primaryValue: 'secondary navigation',
    description: 'aria label for secondary navigation',
  },
  ARIA_EXPAND_NAV: {
    stringKey: 'ariaExpandNav',
    primaryValue: 'expand seconary navigation',
    description: 'aria label for button to expand secondary navigation drawer',
  },
  DETAILS: {
    description: 'label for nav button to the details tab',
    primaryValue: 'Details',
    stringKey: 'details',
  },
  CHARGES: {
    description: 'label for nav button to the charges tab',
    primaryValue: 'Charges',
    stringKey: 'charges',
  },
  PAYMENTS: {
    description: 'label for nav button to the payments tab',
    primaryValue: 'Payments',
    stringKey: 'payments',
  },
  COMMENTS: {
    description: 'label for nav button to the comments tab',
    primaryValue: 'Comments',
    stringKey: 'comments',
  },
  COMMUNICATIONS: {
    description: 'label for tab containing emails and SMS messages',
    primaryValue: 'Communications',
    stringKey: 'communications',
  },
  CSP_PAYMENTS: {
    description: 'label for tab containing csp payment methods',
    primaryValue: 'CSP Payments',
    stringKey: 'cspPayments',
  },
  EFAPIAO: {
    description: 'label for eFapiao details page',
    primaryValue: 'eFapiao',
    stringKey: 'efapiao',
  },
};

export default translations;
