/**
 * Korean AD Groups who are allowed to submit returns.
 */
export const KoreanGroup = [
  'App.ConsumerServices.L1.KR',
  'App.ConsumerServices.L2.KR',
  'App.ConsumerServices.L3.KR',
  'App.ConsumerServices.Warehouse.KR',
];

export const JPGroup = [
  'App.ConsumerServices.L1.JP',
  'App.ConsumerServices.L2.JP',
  'App.ConsumerServices.L3.JP',
  'App.ConsumerServices.Warehouse.JP',
];

export const NAGroup = [
  'App.ConsumerServices.L1.US',
  'App.ConsumerServices.L2.US',
  'App.ConsumerServices.L3.US',
  'App.ConsumerServices.Warehouse.US',
];

export const EUGroup = [
  'App.ConsumerServices.L1.EU',
  'App.ConsumerServices.L2.EU',
  'App.ConsumerServices.L3.EU',
  'App.ConsumerServices.Warehouse.EU',
];
