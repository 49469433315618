import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { OrderContext } from '../../../../store/contexts/orderContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import translations from '../exchange/exchange.i18n';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import StyleLink from '../../content/shared/itemLink';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { getProductImageFromOrderLine } from '../../../../utils/product';

/**
 * Displays the image and details of an item.
 *
 * @param {*} item - the item which we want to display whose details comes from an orderLine.
 * @param {boolean} displayProductDetailsLink - flag indicating whether or not to display the link
 *  that will redirect the customer to a page for more product details.
 * @param {string} testId - the id that will be used for the testing field 'data-testid'.
 * @returns component that will display the item image along with its details.
 */
const ItemDetails = ({ item, displayProductDetailsLink = false, testId }) => {
  const [orderDetail] = useContext(OrderContext);
  const [assets] = useContext(AssetsContext);

  const classes = useStyles();
  const { EXCHANGE_ITEM_OOS_MESSAGE, SIZE, STYLE, QUANTITY, COLOR } = useMemoTranslations(
    translations
  );
  const { currency } = orderDetail;
  return (
    <>
      <Container className={classes.productDetailsContainer} data-testid={testId}>
        <div
          className={classes.productImage}
          style={{
            backgroundImage: `url(${getProductImageFromOrderLine(item, assets)})`,
            opacity: item.inStock === false ? 0.5 : 1,
          }}
          role='img'
          aria-label={item?.item?.itemDescription}></div>

        {/* 
          If item.inStock is equal to false, that means it was not included in the exchangePreview 
          response. Per the service team's guidance, if the item is not included, the item is not 
          in stock.
        */}
        {item.inStock !== false ? (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <span className={classes.label}>{`${SIZE}: `}</span>
              <Typography display={'inline'} className={classes.boldText}>
                {item.displaySize}
              </Typography>
            </Box>
            <Box>
              <span className={classes.label}>{`${STYLE}: `}</span>
              <span className={classes.boldText}>
                {displayProductDetailsLink ? (
                  <StyleLink orderDetail={orderDetail} line={item} />
                ) : (
                  `${item.styleNumber}-${item.colorCode}`
                )}
              </span>
            </Box>
            <Box>
              <span className={classes.label}>{`${COLOR}: `}</span>
              <Typography display={'inline'} className={classes.boldText}>
                {item.colorDescription}
              </Typography>
            </Box>
            <Box>
              <span className={classes.label}>{`${QUANTITY}: `}</span>
              <Typography display={'inline'} className={classes.boldText}>
                1
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.boldText} display={'inline'}>
                <FormattedCurrency
                  className={classes.text}
                  amount={item?.linePriceInformation?.retailPrice}
                  currency={currency}
                />
              </Typography>
            </Box>
          </Box>
        ) : (
          <div style={{ display: 'flex' }}>
            <ErrorOutlineIcon className={classes.errorOutlineIcon} />
            <Typography
              data-testid='step3-error-message'
              variant='body2'
              className={classes.viewExchangeErrorMessage}>
              {EXCHANGE_ITEM_OOS_MESSAGE}
            </Typography>
          </div>
        )}
      </Container>
    </>
  );
};

ItemDetails.propTypes = {
  displayProductDetailsLink: PropTypes.bool,
  item: PropTypes.shape({
    colorDescription: PropTypes.string,
    displaySize: PropTypes.string,
    item: PropTypes.shape({
      itemDescription: PropTypes.string,
    }),
    linePriceInformation: PropTypes.shape({
      retailPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    inStock: PropTypes.bool,
    colorCode: PropTypes.string,
    styleNumber: PropTypes.string,
  }).isRequired,
  msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testId: PropTypes.string,
};

ItemDetails.defaultProps = {
  displayProductDetailsLink: false,
  msrp: '',
  testId: '',
  loading: false,
};

export default ItemDetails;

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
    fontSize: '0.9rem',
  },
  text: {
    fontSize: '0.9rem',
    margin: '0',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    margin: '0',
  },
  errorOutlineIcon: {
    color: theme.palette.error.main,
    marginRight: '12px',
  },
  viewExchangeErrorMessage: {
    color: theme.palette.error.main,
  },
  productImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '198px',
    height: '227px',
  },
  productDetailsContainer: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  msrp: {
    marginRight: '0.5rem',
    color: theme.palette.grey[600],
  },
}));
