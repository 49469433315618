import { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

import useSnacks from '../../../../../hooks/useSnacks';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';

import PICKUP_POINTS_QUERY from '../../../../../queries/pickupPoints.query';
import STORE_LOCATION_QUERY from '../../../../../queries/storeLocation.query';

import { formatAddress } from '../../../../../utils/addressFormatting';
import translations from '../itemDetails.i18n';

/**
 * Fetches pickup point or store location data if that's necessary for a given orderLine
 * and MUTATES values in the provided array
 *
 * @param {OrderLine[]} orderLines - orderLines array
 * @returns {void}
 */
const useEnrichedOrderLines = (orderLines) => {
  const { PICKUP_POINTS_ERROR, STORE_ERROR } = useMemoTranslations(translations);
  const { setError } = useSnacks();

  // filter orderLines for PUP ones
  const pickupPointOrderLines = orderLines.filter((ol) => ol?.omoboFlags?.isPickUpPoint);
  // filter orderLines for Store ones that need to fetch StoreLocation service
  const storeOrderLines = orderLines.filter(
    (ol) => (ol?.omoboFlags?.isBOPIS || ol?.omoboFlags?.isShipToStore) && ol.orderVersion === 'v2'
  );
  const pickupPointOrderLine = pickupPointOrderLines[0];
  const storeOrderLine = storeOrderLines[0];

  // Call Pickup Points service to get pickupPoint address.
  const [fetchPickupPoint, { data: pickupPointData }] = useLazyQuery(PICKUP_POINTS_QUERY, {
    onCompleted: () => {
      const { name, networkId, address } = pickupPointData?.pickupPointsV3ById;
      // set the PUP address as the PUP address for all PUP orderLines
      pickupPointOrderLines.forEach((ol) => {
        ol.shipTo.address = { ...address, locationName: name };
        formatAddress(ol.shipTo.address);
        ol.shipTo.pickupPointAddress = { locationName: name, networkId };
      });
    },
    onError: (err) => {
      setError(`${PICKUP_POINTS_ERROR}, ${err}`);
    },
  });

  // Call StoreLocation service to get store address.
  const [fetchStoreLocation, { data: storeLocationData }] = useLazyQuery(STORE_LOCATION_QUERY, {
    onCompleted: () => {
      const { name, address } = storeLocationData?.storeLocation;
      // set the store address as the store address for all store orderLines
      storeOrderLines.forEach((ol) => {
        ol.shipTo.address = { ...address, locationName: name };
        formatAddress(ol.shipTo.address);
      });
    },
    onError: (err) => {
      setError(`${STORE_ERROR}, ${err}`);
    },
  });

  useEffect(() => {
    if (
      pickupPointOrderLine?.shipTo?.location?.id && // only v2 order lines have this
      process.env.PUBLIC_URL !== '/portlets/postpurchase'
    ) {
      fetchPickupPoint({ variables: { id: pickupPointOrderLine.shipTo.location.id } });
    } else if (pickupPointOrderLine?.shipTo?.address?.pickUpLocation) {
      // only v1 order lines have this ^
      pickupPointOrderLines.forEach(
        (ol) =>
          (ol.shipTo.address.locationName = pickupPointOrderLine.shipTo.address.pickUpLocation)
      );
    }
    if (storeOrderLine?.shipTo?.location?.id) {
      fetchStoreLocation({ variables: { id: storeOrderLine.shipTo.location.id } });
    }
  }, []);
};

export default useEnrichedOrderLines;
