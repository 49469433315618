import gql from 'graphql-tag';

const SHIPPING_CHARGES_QUERY = gql`
  query getReturnedShippingCharges($orderNumbers: [ID!]) {
    returnedShippingCharges(orderNumbers: $orderNumbers) {
      returnedShippingValue
    }
  }
`;

export default SHIPPING_CHARGES_QUERY;
