import gql from 'graphql-tag';

const LANGUAGE_QUERY = gql`
  query getMarketplaceLanguages($marketplace: String!) {
    marketplaceLanguages(marketplace: $marketplace) {
      id
      name
      languages {
        id
        name
        nativeName
      }
      defaultLanguage
      localeMappings {
        languageId
        locale
      }
      legacyMappings {
        langLocale
        legacyLanguageTag
      }
      marketplaceGroups
      currency {
        currencyCode
      }
    }
  }
`;

export default LANGUAGE_QUERY;
