/** React / Utils */
import React from 'react';

/** Material UI */
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { newWindowInSF } from '../../utils/link';
import { useGetUrlParams } from '../../hooks/useGetUrlParams';

/**
 * Button that will open a new tab in browser or in Sales Force depending on environment
 * @param {*} label self explanatory
 * @param {*} ariaLabel accessibility label
 * @param {String} href url for the new tab
 * @param {String} testId the id to be passed in to be used for data-testid
 * @param {String} query query params to attach to the url, if needed.
 * @returns a react component
 */
const NewTabButton = ({ label, ariaLabel = '', href, className, testId = '', query = '' }) => {
  const classes = useStyles();
  const sessionId = useGetUrlParams('sessionId');

  // check url if we're linking to member profile app or not for styling and sessionId
  const isMemberLink = href.indexOf('memberinfo') === -1 ? false : true;
  const linkHref = isMemberLink ? href : href + `/?sessionId=${sessionId}` + query;

  // check url if deep linking to member profile or sending to home for styling
  const isMemberDeepLink = href.indexOf('home') === -1 ? true : false;

  // function to prevent a bunch of ugly ternaries to get correct styling
  const getClass = () => {
    if (isMemberLink) {
      return isMemberDeepLink ? classes.memberProfileDeepLink : classes.memberProfileLinkHome;
    }
    return classes.giftCardLink;
  };

  /*
  checking whether we are in Sales Force or native OMOBO app 
  by comparing window.self to window.top
  */

  return window.self === window.top ? (
    <Link
      className={className || getClass()}
      target='_blank'
      rel='noopener noreferrer'
      aria-label={ariaLabel}
      href={linkHref}
      data-testid={testId}>
      {label}
    </Link>
  ) : (
    <Link
      component='button'
      className={className || getClass()}
      aria-label={ariaLabel}
      onClick={() => newWindowInSF(linkHref)}
      data-testid={testId}>
      {label}
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  giftCardLink: {
    color: theme.palette.primary.main,
    fontSize: 'inherit',
    paddingTop: '1px',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },
  memberProfileLinkHome: {
    textDecoration: 'underline',
    margin: `0 ${theme.spacing(1)}px`,
    fontSize: '0.875rem',
    paddingBottom: '2px',
    letterSpacing: 'inherit',
  },
  memberProfileDeepLink: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    fontStyle: 'inherit',
  },
}));

export default NewTabButton;
