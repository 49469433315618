import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import { makeStyles, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DialogContext } from '../../store/contexts/dialogContext';
import { OrderContext } from '../../store/contexts/orderContext';
import translations from './addressForm.i18n';
import mapValues from 'lodash/mapValues';

/**
 * Component used to display address on re-send return label modal
 */
export const ReturnLabelAddress = () => {
  const [dialogState] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const { i18nString } = useContext(NikeI18nContext);

  const {
    billTo: {
      contactInformation: { email: billToEmail = '' },
    },
  } = orderDetail;

  const address = dialogState.address;
  const {
    firstName,
    lastName,
    email,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
  } = address;
  const { RETURN_LABEL_ADDRESS } = mapValues(translations, i18nString);

  const classes = useStyles();
  const firstNameAndLastName = firstName + '\t' + lastName;
  const cityAndState = city + (state ? ',\t' + state + '\t' : '');

  /* 
   Read email address from billTo if not available in shipTo
   This scenario occurs when return is created with "mail" as delivery option
   and customer wants delivery option as "email" while resending return labels
   */
  const returnLabelAddress = [
    firstNameAndLastName,
    email ? email : billToEmail,
    address1,
    address2,
    cityAndState,
    postalCode,
    country,
  ];

  return (
    <Card raised={true} className={classes.resendLabelAddress}>
      <CardContent data-testid='return-label-address'>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          {RETURN_LABEL_ADDRESS}
        </Typography>
        {returnLabelAddress.map((value, key) => {
          return (
            <Typography className={classes.address} key={key}>
              {value}
            </Typography>
          );
        })}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  resendLabelAddress: {
    maxWidth: 500,
    minHeight: 200,
    marginTop: 50,
    marginBottom: 100,
  },
  title: {
    paddingLeft: 0,
    padding: 10,
    fontSize: '5/3rem',
  },
  address: {
    marginBottom: 10,
    paddingLeft: 50,
    fontSize: '1.167rem',
  },
}));
