import { formatDate } from './date';
import { nestVasOrderLines } from './orderDetailDecoration';
/**
 * Gets an omoboFlags object derived from the cumulative omoboFlags from each
 * orderLine in the given orderLines. The given orderLines can either be an array
 * or an object where each orderLine is mapped to its own field. An object value
 * will always be returned, even if the input is null or invalid.
 *
 * @param orderLines
 * @returns omoboFlags {object}
 */
export const getOmoboFlags = (orderLines) => {
  if (!orderLines) {
    return {};
  }

  // In some cases, the orderLines will not be in an array form, so we'll convert it.
  const orderLineArray = Array.isArray(orderLines)
    ? orderLines
    : Object.values(orderLines).filter(Boolean);

  const isBOPIS = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isBOPIS);
  const isShipToStore = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isShipToStore);
  const isPickUpPoint = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isPickUpPoint);
  const isReturnable = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isReturnable);
  const isCancellable = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isCancellable);

  return { isBOPIS, isShipToStore, isPickUpPoint, isReturnable, isCancellable };
};

/**
 * returns the fields from orderLine needed in shipmentDetails
 * returns fulfiller String and estimated delivery date
 * Prod orders need only shipNode and shipAdvice
 * Test orders need shipNode, contractNumber, and chainedOrderNumber
 *
 * @param orderLines
 * @param {String} orderLineKey key that ties a shipment and orderLine together
 * @returns {String} name and number of fulfiller
 */
export const getOrderLineShipmentDetails = (orderLines, orderLineKey, locale) => {
  if (!orderLineKey || !orderLines || orderLines.length === 0) {
    return { fulfiller: '', estimatedDeliveryDate: '', scheduledDeliveryDate: '' };
  }

  const orderLine = orderLines.filter((orderLine) => orderLine.orderLineKey === orderLineKey)[0];
  // if a matching orderLine is not found
  if (!orderLine) return { fulfiller: '', estimatedDeliveryDate: '', scheduledDeliveryDate: '' };
  const { shipAdvice, chainedOrderNumber, contractNumber, shipNode } = orderLine?.statuses?.[0];

  if (shipAdvice) {
    return {
      fulfiller: shipNode + (shipAdvice ? ` - ${shipAdvice}` : ''),
      estimatedDeliveryDate: formatDate(orderLine.estimatedDeliveryDate),
      scheduledDeliveryDate: orderLine.scheduledDeliveryStartDate
        ? formatDate(orderLine.scheduledDeliveryStartDate)
        : '',
    };
  } else {
    return {
      fulfiller:
        shipNode +
        (contractNumber ? ` - ${contractNumber}` : '') +
        (chainedOrderNumber ? ` (${chainedOrderNumber})` : ''),
      estimatedDeliveryDate: formatDate(orderLine.estimatedDeliveryDate, locale),
      scheduledDeliveryDate: orderLine.scheduledDeliveryStartDate
        ? formatDate(orderLine.scheduledDeliveryStartDate, locale)
        : '',
    };
  }
};

/**
 * returns the info required for the returned at store tooltip
 * @param {String} lineKey from an orderLine's orderLineKey
 * @param {Object} csOrderSummary result of a csOrderSummary query that is attached to an
 * orderDetail query
 * @returns an object with storeNumber, transactionNumber, transactionBeginDate, paymentType
 * depending on the data provided some or all may be null
 */
export const getReturnedAtStoreInfo = (lineKey, csOrderSummary) => {
  /**
   * sometimes an orderLine will be missing store or payment information
   * use this object to grab a default value of null to prevent errors
   */
  const fallBackInfo = {
    storeNumber: null,
    transactionNumber: null,
    transactionBeginDate: null,
    paymentType: null,
  };

  if (typeof lineKey !== 'string' || !csOrderSummary?.objects?.length) {
    return fallBackInfo;
  }

  if (csOrderSummary.objects.length > 1) {
    // filter csOrderSummary to find the correct orderLine
    const matchingOrder = csOrderSummary.objects.filter((object) => {
      for (let orderLine of object.orderLines) {
        if (orderLine.parentSalesOrderLineKey === lineKey) {
          return true;
        }
      }
      return false;
    });

    const { storeNumber, transactionNumber, transactionBeginDate } =
      matchingOrder[0]?.store?.[0] || fallBackInfo;

    const { paymentType } = matchingOrder[0]?.paymentMethods?.[0] || fallBackInfo;

    return { storeNumber, transactionNumber, transactionBeginDate, paymentType };
  } else if (csOrderSummary.objects.length === 1) {
    // default to grabbing the only orderLine and using it
    const { storeNumber, transactionNumber, transactionBeginDate } =
      csOrderSummary.objects[0]?.store?.[0] || fallBackInfo;

    const { paymentType } = csOrderSummary.objects[0]?.paymentMethods?.[0] || fallBackInfo;

    return { storeNumber, transactionNumber, transactionBeginDate, paymentType };
  }
  // if none of the orderLines match or we don't have any linked orders return an empty object
  return fallBackInfo;
};

/**
 * returns orderLines that can be exchanged. filters out NBY VAS and Voucher lines
 * @param {Array} orderLines objects from orderDetail request
 * @returns {Array} filtered orderLine Objects
 */
export const findExchangeableLines = (orderLines) => {
  let formattedOrderLines = orderLines;
  const exchangeableOrderLines = [];

  // if VAS orderLines exist, nest the VAS orderLines under their corresponding regular orderLines
  if (orderLines.some((orderLine) => orderLine.orderLineType === 'SERVICE')) {
    let regularOrderLines = orderLines.filter((orderLine) => orderLine.orderLineType !== 'SERVICE');
    let vasOrderLines = orderLines.filter((orderLine) => orderLine.orderLineType === 'SERVICE');
    nestVasOrderLines(regularOrderLines, vasOrderLines);
    formattedOrderLines = regularOrderLines;
  }

  // if all order lines are VAS, NBY, or Voucher then not eligible for exchange
  formattedOrderLines.forEach((orderLine) => {
    if (
      !(
        orderLine.hasNestedVasOrderLines || // VAS
        orderLine.orderLineType === 'NIKEID' || // NBY
        orderLine.orderLineType === 'VOUCHER'
      ) // Voucher
    ) {
      exchangeableOrderLines.push(orderLine.orderLineKey);
    }
  });

  return new Set(exchangeableOrderLines);
};

/**
 * Determine if the orderLine item is a gift card (either digital or physical)
 *
 * @param {Object} orderLine - Object of selected orderLine items for return or cancel.
 */
export const isLineItemGiftCard = (orderLine) =>
  orderLine?.item?.itemDescription.includes('Gift Card') ||
  orderLine?.styleNumber?.includes('GIFTCARD')
    ? true
    : false;
