import { Channel } from '../../../../../constants/dialog.const';
import { restructureAddress, generateOrderLines, getEmail } from './utility';
import { ShippingOptions } from '../../../../../constants/shippingOptions.const';
import { hasAndIsWarehouseWorkerOnly } from '../../../../../utils/adGroups';

/**
 * Convert the dialog state into the format that the return captures mutation input is expecting.
 * @param {Object} dialogState The dialogs state.
 * @param {Object} consumerState The sales containg consumer details.
 * @param {Object} orderDetails The sales order details.
 * @param {Boolean} permissionToSendReturnLabel Boolean that specifies if the athlete has permission
 * to send return label.
 * @param {String} receivingNode receivingNode that will be sent in return payload
 * @param {Object} standardCarrierAlphaCode standardCarrierAlphaCode that will be sent
 * in return payload
 * @param {Object} athleteInfo Object containing athlete details.
 */
export const generateReturnCapturePayload = (
  dialogState,
  consumerState,
  orderDetails,
  permissionToSendReturnLabel,
  receivingNode,
  standardCarrierAlphaCode,
  athleteInfo
) => {
  const refundAsGiftCard = dialogState.returnPaymentOption === 'giftcard';
  const orderLines = generateOrderLines(dialogState.selectedLines);
  const geo = orderDetails.omsRegionReference;
  const createDate = new Date();
  let returnMethod = '';
  const { NON_PICKUP } = ShippingOptions;
  const { shippingOption } = dialogState;
  /* 
      mp-titan wants us to send returnMethod which will all be `PICK_UP` for KOREA region unless, 
      the return is being created by either a warehouse/L1/L2/L3 employee and shippingOption is 
      nonPickup, which would mean that the item is already at the warehouse and the return is being
      created after the item has already been received. 
      */
  if (shippingOption === NON_PICKUP && hasAndIsWarehouseWorkerOnly(athleteInfo.groups, 'JP')) {
    returnMethod = 'RECEIVED_AT_WAREHOUSE';
  } else {
    returnMethod = undefined;
  }

  const result = {
    customerProfileReference: orderDetails.customerProfileReference,
    // mp-titan team asked us to send this channel info
    channel: Channel,
    // Limitation in Doms as assistingAgentReference can only take 20 characters
    assistingAgentReference: athleteInfo?.name?.substring(0, 20) || '',
    omsRegionReference: geo,
    locale: orderDetails.locale,
    currency: orderDetails.currency,
    orderNumber: orderDetails.orderNumber,
    // mp-titan wants us to send channel details for this field
    captureSystem: Channel,
    email: getEmail(dialogState),
    orderCreateDate: createDate.toISOString(),
    refundOrderFlag: refundAsGiftCard,
    /*
    We don't have to ask mp-titan to create return labels for china and Japan 
    (downstream services taking care of it)
    */
    createReturnLabelFlag: false,
    receivingNode: receivingNode,
    orderLines,
    returnMethod,
  };

  /*
    Location the physical return label will be sent to.
    For China and Japan downstream systems will handle the logic of sending return labels
    so we are sending the shipTo info in return capture payload irrespective of athelte permission
    to send return labels.
  */
  result.shipTo = { ...dialogState.shipTo };
  result.shipFrom = restructureAddress(dialogState.address, dialogState);
  result.standardCarrierAlphaCode = standardCarrierAlphaCode;

  /* 
	Overwrite shipFrom address with billTo address if 
		a) the refund is going to a gift card in Japan, modify our existing shipFrom to include the
			 values entered
		b) the refund is going to gift card location that the physical giftcard will be sent to,
			 replace shipFrom with the values entered
		c) if all the order lines that is being returned are gift cards, do the same
  */
  if (refundAsGiftCard || dialogState.areAllOrderLinesGiftCards) {
    /* 
    in Japan, we don't enter a physical address for giftcards, and so those fields are not
    auto-populated with the consumer's details. so that we don't write over the existing
    fields in shipFrom, which we need, we'll get only the fields present on the Japanese form,
    and fill those in.
    */

    // extract the entered GC details. these fields are required, and so are reliable
    const {
      email,
      firstName,
      lastName,
      alternateFirstName,
      alternateLastName,
    } = dialogState.gcShippingAddress;

    // and then use those values to replace the existing values
    result.shipFrom.recipient = {
      ...result.shipFrom.recipient,
      firstName,
      lastName,
      alternateFirstName,
      alternateLastName,
    };
    result.shipFrom.contactInformation.email = email;
  }
  return result;
};

/**
 * Convert state into the format that the return location mutation input is expecting.
 * @param {*} dialogState The dialogs state.
 * @param {*} consumerState The consumer state.
 * @param {*} orderDetails The sales order details.
 */
export const generateReturnLocationPayload = (dialogState, consumerState, orderDetails) => {
  let orderLineReference = '';
  const selectedLineKeys = Object.keys(dialogState.selectedLines);
  if (selectedLineKeys.length) {
    const selectedLine = dialogState.selectedLines[selectedLineKeys[0]];
    if (selectedLine?.orderLineKey) {
      orderLineReference = selectedLine.orderLineKey;
    }
  }

  return {
    registeredUser: consumerState.isRegistered,
    orderLineReference,
    omsRegionReference: orderDetails.omsRegionReference,
    // the below is for gift card orders when shipping address won't be available
    city: dialogState?.address?.city
      ? dialogState?.address?.city
      : dialogState?.gcShippingAddress?.city,
    state: dialogState?.address?.state
      ? dialogState?.address?.state
      : dialogState?.gcShippingAddress?.state,
    country: dialogState?.address?.country
      ? dialogState?.address?.country
      : dialogState?.gcShippingAddress?.country,
  };
};
