export const translations = {
  ADDRESS_1: {
    description: 'label on the Address form for address 1',
    primaryValue: 'Address 1',
    stringKey: 'address1',
  },
  ADDRESS_2: {
    description: 'label on the Address form for address 2',
    primaryValue: 'Address 2',
    stringKey: 'address2',
  },
  AREA_VILLAGE: {
    description: 'label on the Address form for area/village',
    primaryValue: 'Area/Village',
    stringKey: 'areaVillage',
  },
  APO_FPO: {
    description: 'label on the Address form for apo/fpo',
    primaryValue: 'APO/FPO',
    stringKey: 'apofpo',
  },
  BUILDING_NAME: {
    description: 'label on the Address form for building name',
    primaryValue: 'Building Name',
    stringKey: 'buildingName',
  },
  CHINA: {
    description: 'Name of the country China',
    primaryValue: 'China',
    stringKey: 'china',
  },
  CITY: {
    description: 'label on the Address form for city',
    primaryValue: 'City',
    stringKey: 'city',
  },
  KOREA_CITY: {
    description: 'label on the Address form for Korean city, district, county or town',
    primaryValue: 'City / District / County / Town',
    stringKey: 'koreaCity',
  },
  COUNTRY: {
    description: 'label on the Address form for country',
    primaryValue: 'Country',
    stringKey: 'country',
  },
  DISTRICT: {
    description: 'label on the Address form for district',
    primaryValue: 'District',
    stringKey: 'district',
  },
  EMAIL: {
    description: 'label on the Address form for email',
    primaryValue: 'Email',
    stringKey: 'email',
  },
  FIRST_NAME: {
    description: 'label on the Address form for first name',
    primaryValue: 'First Name',
    stringKey: 'firstName',
  },
  JAPAN: {
    description: 'name of the country Japan',
    primaryValue: 'Japan',
    stringKey: 'japan',
  },
  KANA_FIRST_NAME: {
    description: 'label on the Address form for kana first name',
    primaryValue: 'Kana First Name',
    stringKey: 'kanaFirstName',
  },
  KANA_LAST_NAME: {
    description: 'label on the Address form for kana last name',
    primaryValue: 'Kana Last Name',
    stringKey: 'kanaLastName',
  },
  LAST_NAME: {
    description: 'label on the Address form for last name',
    primaryValue: 'Last Name',
    stringKey: 'lastName',
  },
  MUNICIPALITY: {
    description: 'label on the Address form for municipality',
    primaryValue: 'Municipality',
    stringKey: 'municipality',
  },
  PHONE_NUMBER: {
    description: 'label on the Address form for phone number',
    primaryValue: 'Phone Number',
    stringKey: 'phoneNumber',
  },
  PICKUP_POINT_NAME: {
    description: 'label on the Address form for pickup point name',
    primaryValue: 'Pickup Point Name',
    stringKey: 'pickupPointName',
  },
  POSTAL_CODE: {
    description: 'label on the Address form for postal code',
    primaryValue: 'Postal Code',
    stringKey: 'postalCode',
  },
  PREFECTURE: {
    description: 'label on the Address form for prefecture',
    primaryValue: 'Prefectue',
    stringKey: 'prefecture',
  },
  PROVINCE: {
    description: 'label on the Address form for province',
    primaryValue: 'Province',
    stringKey: 'province',
  },
  KOREA_PROVINCE: {
    description: 'label on the Address form for Korean province or metropolitan area',
    primaryValue: 'Metropolitan / Province',
    stringKey: 'koreaProvince',
  },
  REGION: {
    description: 'label on the Address form for region',
    primaryValue: 'Region',
    stringKey: 'region',
  },
  RETURN_LABEL_ADDRESS: {
    description: 'label on return label address details',
    primaryValue: 'Return Label Address',
    stringKey: 'returnLabelAddress',
  },
  STATE: {
    description: 'label on the Address form for state',
    primaryValue: 'State',
    stringKey: 'state',
  },
  STORE_NAME: {
    description: 'label on the Address form for store name',
    primaryValue: 'Store Name',
    stringKey: 'storeName',
  },
  STREET_ADDRESS: {
    description: 'label on the Address form for street address',
    primaryValue: 'Street Address',
    stringKey: 'streetAddress',
  },
  STREET_NAME: {
    description: 'label on the Ko Address form for street name',
    primaryValue: 'Street Name',
    stringKey: 'streetName',
  },
  ZIP_CODE: {
    description: 'label on the Address form for zip code',
    primaryValue: 'Zip Code',
    stringKey: 'zipCode',
  },
  ADDRESS_OTHER: {
    description: 'label on Ko Address form for extras after street name: Building Name, Floor/Apt',
    primaryValue: 'Building Name, Floor/Apt',
    stringKey: 'addressOther',
  },
  PHONE_NUMBER_VALIDATION_ERROR: {
    description:
      'error message to display on the phone number input field when it fails validation',
    primaryValue: 'Please enter a valid phone number',
    stringKey: 'phoneNumberValidationError',
  },
};

export default translations;
