import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

export default function InvoiceDetailsSection({ rows, header }) {
  const classes = useStyles();
  return (
    <div className={classes.invoicesDetailPair}>
      <Box className={classes.invoiceDetailsHeader}>
        <Typography
          aria-label={header.toLowerCase()}
          variant='subtitle1'
          align='left'
          component='h3'
          className={classes.cardHeaderTitle}>
          {header}
        </Typography>
      </Box>

      {rows.map(({ label, value, element, testId }) => (
        <Box className={classes.productInformationPair} key={label}>
          <Typography
            align='left'
            variant='body2'
            className={classes.lightText}
            data-testid={`${testId}-label`}>
            {label}:
          </Typography>
          <Typography
            align='right'
            variant='body2'
            className={classes.boldText}
            data-testid={`${testId}-value`}>
            {value ? value : ''}
          </Typography>
          {element}
        </Box>
      ))}
    </div>
  );
}

InvoiceDetailsSection.propTypes = {
  header: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      element: PropTypes.element,
    })
  ),
  testId: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  productInformationPair: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  sectionHeader: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  invoicesDetailPair: {
    padding: `${theme.spacing(1.5)}px`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
  },
  invoiceDetailsHeader: {
    paddingBottom: theme.spacing(1.25),
  },
  lightText: {
    color: '#707072',
  },
  boldText: {
    fontWeight: 500,
  },
}));
