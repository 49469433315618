import gql from 'graphql-tag';

const RESEND_INVOICE = gql`
  mutation resendInvoice($invoiceNumber: String!) {
    resendInvoice(invoiceNumber: $invoiceNumber) {
      Errors {
        Error {
          ErrorCode
          ErrorDescription
          ErrorUniqueExceptionId
        }
      }
    }
  }
`;
export default RESEND_INVOICE;
