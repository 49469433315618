/* React/Utils */
import React, { useContext } from 'react';

/* Material-UI */
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

/* Local */
import { Partners } from '../../../../../constants/origin.const';
import OrderContext from '../../../../../store/contexts/orderContext';
import getArrayProperty from '../../../../../utils/getArrayProperty';
import { transformTransactionDetails } from '../../../../../utils/payment';
import BasicTableRow from '../../../../shared/table/tableRow';
import PaymentDetails from './paymentDetails';
import translations from '../payments.i18n';
import ErrorBoundary from '../../../../error/errorBoundary';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';

export default function Payments() {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const {
    paymentMethods,
    orderNumber,
    orderType,
    parentReturnOrder,
    channel,
    omsRegionReference,
    locale,
    currency,
  } = orderDetail;

  const {
    AMOUNT,
    AUTH_DATE,
    AUTH_NUM,
    DATE,
    INVOICES,
    PAYMENT_DETAILS,
    STATUS,
    TYPE,
    ARIA_PAY_TYPE_TRANS_DETAILS,
  } = useMemoTranslations(translations);

  const chargeTransactionDetails = getArrayProperty(orderDetail, 'chargeTransactionDetails');

  // header values for transactions table
  const transactionsHeaders = [DATE, AUTH_DATE, TYPE, AMOUNT, AUTH_NUM, STATUS, INVOICES];

  const partner = channel ? Partners[channel] : '';

  return (
    <ErrorBoundary>
      <h1 className='accessibly-hidden'>{PAYMENT_DETAILS}</h1>

      {paymentMethods &&
        paymentMethods.map((payment, i) => {
          const typeEntries = chargeTransactionDetails.filter(
            (transaction) => transaction.paymentKey === payment?.paymentKey
          );
          const typeRows = typeEntries.map((entry, k) => (
            <BasicTableRow
              key={k}
              data={transformTransactionDetails(entry, locale, currency)}
              cellRootClassName={classes.tableCell}
            />
          ));

          return (
            <PaymentDetails
              key={i}
              index={i}
              cardClass={classes.cardSpacing}
              cardSubHeadingClass={classes.cardSubHeading}
              tableHeadingClass={classes.tableHeader}
              cellClass={classes.tableCell}
              currency={currency}
              payment={payment}
              orderNumber={orderNumber}
              orderType={orderType}
              parentReturnOrder={parentReturnOrder}
              partner={partner}
              region={omsRegionReference}
              displayModButtons={false}>
              <Table aria-label={ARIA_PAY_TYPE_TRANS_DETAILS}>
                <TableBody>
                  <BasicTableRow
                    header
                    data={transactionsHeaders}
                    cellClassName={classes.tableHeader}
                  />
                  {typeRows}
                </TableBody>
              </Table>
            </PaymentDetails>
          );
        })}
      <Box className={classes.cardSpacing} />
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  cardSubHeading: {
    fontSize: '1rem',
    padding: `10px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
}));
