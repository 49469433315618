import gql from 'graphql-tag';

// Mutation for dispatching a return label.
const RETURN_LABEL_MUTATION = gql`
  mutation postReturnLabelsDispatch($input: ReturnLabelsDispatchInputV1!) {
    returnLabelsDispatch(input: $input) {
      returnOrderNumber
    }
  }
`;
export default RETURN_LABEL_MUTATION;
