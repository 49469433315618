/** React / Utils */
import React from 'react';

/** Local */
import EUAddressForm from './addressForm.eu';
import JPAddressForm from './addressForm.jp';
import USAddressForm from './addressForm.us';
import KOAddressForm from './addressForm.kr';
import AUAddressForm from './addressForm.au';
import Geo from '../../constants/geos.const';
import { PartnersExcludedFromDetailsInEMEA } from '../../constants/origin.const';

/**
 * Generic AddressForm to wrap a region-specific AddressForm.
 *
 * @param {string} omsRegionReference - the region
 * @param {boolean} isGiftCardAddress - whether or not this is a gift card address
 * @param {string} className - a class name
 * @param {function} setIsJapanPhoneNumberValid - function to pass to the japan address form to
 * indicated whether or not the japan phone number is valid.
 * @returns {JSX.Element}
 * @constructor
 */
export const AddressForm = ({
  region,
  isGiftCardAddress = false,
  className = '',
  customClasses = {},
  children,
  variant,
  setIsJapanPhoneNumberValid,
  shouldBeEditable,
}) => {
  if (region === Geo.CHINA) {
    // China doesn't need the edit address capability in return labels, so address will
    // be grabbed from the sales order.
    return null;
    // if the region is ZALANDOEU, PERRY, PRODIRECT, or JDSPORTS in addition to europe use euAddress
  } else if (region === Geo.EUROPE || PartnersExcludedFromDetailsInEMEA.has(region)) {
    return (
      <EUAddressForm
        isGiftCardAddress={isGiftCardAddress}
        className={className}
        region={region}
        children={children}
        customClasses={customClasses}
        variant={variant}
      />
    );
  } else if (region === Geo.JAPAN) {
    return (
      <JPAddressForm
        isGiftCardAddress={isGiftCardAddress}
        className={className}
        region={region}
        children={children}
        customClasses={customClasses}
        variant={variant}
        setIsJapanPhoneNumberValid={setIsJapanPhoneNumberValid}
      />
    );
  } else if (region === Geo.US) {
    return (
      <USAddressForm
        isGiftCardAddress={isGiftCardAddress}
        className={className}
        region={region}
        children={children}
        customClasses={customClasses}
        variant={variant}
      />
    );
  } else if (region === Geo.KOREA) {
    return (
      <KOAddressForm
        isGiftCardAddress={isGiftCardAddress}
        shouldBeEditable={shouldBeEditable}
        className={className}
      />
    );
  } else if (region === Geo.AUSTRALIA) {
    return (
      <AUAddressForm
        isGiftCardAddress={isGiftCardAddress}
        className={className}
        region={region}
        children={children}
        customClasses={customClasses}
        variant={variant}
      />
    );
  }
  // TODO: do we need to handle invalid omsRegionReferences?
  return '';
};

export default AddressForm;
