import gql from 'graphql-tag';

const ORDER_INVOICE_QUERY = gql`
  query getOrderInvoice($orderNumber: String, $invoiceNumber: String) {
    orderInvoice(orderNumber: $orderNumber, invoiceNumber: $invoiceNumber) {
      objects {
        fulfillmentReferenceNumber
        date
        omsOrderDocumentReference
        orderPurpose
        division
        currency
        omsRegionReference
        orderNumber
        parentReturnOrderNumber
        parentSalesOrderNumber
        status
        invoiceStatus
        resourceId
        eventId
        orderType
        amountCollected
        dateInvoiced
        invoiceNo
        invoiceType
        fulfillmentType
        totalAmount
        totalTax
        invoiceShipmentDate
        orderCreateDate
        sellerOrganizationCode
        couponIds
        employeeID
        channelID
        channel
        invoiceCollections {
          orderInvoiceKey
          amountCollected
          chargeTransactionDetails {
            authorizationExpirationDate
            paymentKey
            transactionDate
            chargeReference
            requestAmount
            bookAmount
            debitAmount
            status
            transferToOrderHeaderKey
            transferFromOrderHeaderKey
            collectionDate
            chargeTransactionKey
            executionDate
            preChargeFlag
            authorizationReference
            creditCardTransactions {
              cvvAuthorizationCode
              authorizationAVS
              authorizationAmount
              authorizationTime
              authorizationCode
              transactionDescription
              transactionAmount
              authorizationRequestReference
              paymentReferenceNumber
              reconciliationReference
              transactionReturnCode
              transactionReturnMessage
              authorizationReturnCode
              authorizationReturnMessage
              internalReturnMessage
            }
            paymentMethod {
              chargeSequence
              checkNumber
              checkReference
              creditCardExpiration
              creditCardHolderName
              creditCardType
              merchantAccountNumber
              customerPaymentReference
              displayCreditCardNumber
              displayDebitCardNumber
              displayGiftCardNumber
              middleName
              firstName
              lastName
              maximumChargeLimit
              paymentReference1
              paymentReference2
              paymentReference3
              paymentReference4
              paymentType
              paymentKey
              requestedAuthorizationAmount
              requestedChargeAmount
              suspendChargeReference
              unlimitedChargesFlag
              totalAuthorizedAmount
              totalRefundedAmount
              totalChargedAmount
            }
          }
        }
        headerTaxes {
          chargeCategory
          chargeName
          taxAmount
          taxName
          taxPercentage
          headerReference
          effectiveTaxAmount
          effectiveTaxPercentage
          taxCalculationTime
        }
        headerCharges {
          chargeAmount
          chargeCategory
          chargeName
          recordType
          chargePerQuantity
          isDiscount
        }
        headerTaxes {
          chargeCategory
          chargeName
          taxAmount
          taxName
          taxPercentage
          headerReference
          effectiveTaxAmount
          effectiveTaxPercentage
          taxCalculationTime
        }
        orderLines {
          lineTotal
          pickupLocation
          fulfillmentLocationType
          pickupLocationType
          deliveryMethod
          fulfillmentMethod
          shipToStoreNumber
          returnReasonCode
          lineKey
          lineNumber
          quantity
          shippedQty
          unitOfMeasure
          lineType
          returnInspectionCode
          colorCode
          colorDescription
          commerceItemId
          commodityCode
          displaySize
          orderDelayDays
          preorderFlag
          customizedProductReference
          productId
          shippingGroup
          sizeDescription
          styleNumber
          pickupFirstName
          pickupLastName
          shipTo {
            contactInformation {
              dayPhoneNumber
              email
              eveningPhoneNumber
            }
            recipient {
              firstName
              lastName
              middleName
            }
            address {
              address1
              address2
              address3
              address4
              city
              country
              zipCode
              state
            }
          }
          lineTaxes {
            chargeCategory
            chargeName
            taxName
            taxAmount
            taxPercentage
            effectiveTaxAmount
            effectiveTaxPercentage
            taxCalculationTime
          }
          linePromotions {
            promotionId
            promotionType
          }
          lineCharges {
            chargeAmount
            chargeCategory
            chargeName
            chargePerUnit
            chargePerLine
            chargePerQuantity
            isDiscount
          }
          linePriceInformation {
            extendedPrice
            unitPrice
            listPrice
            retailPrice
          }
          item {
            stockKeepingUnitId
            universalProductCode
            productType
            itemDescription
          }
          fulfillmentLocation {
            node
            nodeType
            resourceType
            resourceId
          }
        }
        billTo {
          contactInformation {
            dayPhoneNumber
            email
            eveningPhoneNumber
          }
          recipient {
            firstName
            lastName
            middleName
          }
          address {
            address1
            address2
            address3
            address4
            city
            country
            zipCode
            state
          }
        }
        headerPromotions {
          promotionId
          promotionType
        }
        vatReceipts {
          vatReceiptKey
          vatReceiptNo
          vatReceiptSendDate
          vatStatus
          vatReceiptAmount
          vatSourceKey
          vatReceiptTitle
          vatReversalNumber
          vatInvoiceReference
          chainedOrderNumber
          lineItems {
            lineNumber
            universalProductCode
            quantity
            lineTotal
            productName
            material
            size
            itemDivision
          }
        }
      }
    }
  }
`;

export default ORDER_INVOICE_QUERY;
