import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { DialogContext } from '../../../../store/contexts/dialogContext';
import ItemDetails from '../shared/itemDetails';

/**
 * Selected Replacement
 *
 * This component organizes and updates the data relevant to the selected product in the exchanges
 * dialog flow, before passing it on to the <ItemDetails /> component.
 *
 * @param {object} props React Component Props
 * @param {string} props.orderLineNumber OrderLine number used to identify the selected sku/line
 * @param {boolean} props.displayProductDetailsLink Flag to convert style/colorway into nike.com url
 * @returns
 */
export default function SelectedReplacement({ orderLineNumber, displayProductDetailsLink }) {
  const [dialogState] = useContext(DialogContext);
  const { selectedLines, selectedSkus } = dialogState;

  const item = selectedSkus[orderLineNumber];

  if (!item) return null;

  const {
    colorCode,
    colorDescription,
    styleName: styleNumber,
    priceInfo: { total: retailPrice },
    selectedSize: { localizedSize: displaySize },
    inStock,
  } = item;

  const newItem = {
    colorCode,
    colorDescription,
    displaySize,
    inStock,
    item: {
      itemDescription: selectedLines[orderLineNumber]?.item?.itemDescription,
    },
    linePriceInformation: { retailPrice },
    quantity: 1, // hard coded to 1 because each exchange order line will always be for 1 item
    styleNumber,
  };

  return (
    <ItemDetails
      testId={`item-${orderLineNumber}-replacement`}
      item={newItem}
      msrp={item.priceInfo?.total}
      displayProductDetailsLink={displayProductDetailsLink}
    />
  );
}

SelectedReplacement.propTypes = {
  displayProductDetailsLink: PropTypes.bool,
  orderLineNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
