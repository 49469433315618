import React, { createContext, useState } from 'react';
import { getOrderDetailQuery } from '../../utils/query';
import { storeRecentOrder } from '../../utils/recentOrders';
import { setOrderAndRegion } from '../../utils/browserStorage';

export const OrderContext = createContext([{}, () => {}]);
const { Provider } = OrderContext;

/**
 * A basic provider for a context with only a single value
 *
 * @param {Object} props  – React props
 */
export const OrderProvider = ({ children }) => {
  const defaultState = getOrderDetailQuery();
  const [state, setState] = useState(defaultState);

  const setStateAndStore = (order) => {
    // store recent orders, current order and order region in local storage
    storeRecentOrder(order);
    setOrderAndRegion(order.orderNumber, order.omsRegionReference);
    setState(order);
  };

  return <Provider value={[state, setStateAndStore]}>{children}</Provider>;
};

export default OrderContext;
