/** React / Utils */
import React, { useContext } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

/** Local */
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import {
  ResponseStatuses,
  InspectionStatus,
  ReasonCodeTypes,
  DialogTypes,
  ApiTimeOut,
} from '../../../../constants/dialog.const';
import Geo from '../../../../constants/geos.const';
import { EmeaPartnersAllowingInspections } from '../../../../constants/origin.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import INSPECT_RETURN from '../../../../mutations/inspectReturn.mutation';
import translations from './inspectReturn.i18n';
import {
  areReasonsMissingFrom,
  isReturnDispositionMissing,
  isEscalationNotesMissing,
} from '../../../../utils/dialog';
import { getEmeaPartner } from '../../../../utils/order';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import useSnacks from '../../../../hooks/useSnacks';
import { stepControlSharedStyles } from '../sharedStyles';
import { NextButton, BackButton } from '../shared/buttons';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { decorateOrderDetail } from '../../../../utils/orderDetailDecoration';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
export default function StepControl() {
  const classes = useStyles();
  const { setSlowLoading, setSnack, setError, getLoadingStatus } = useSnacks();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  // const [efapiaoData, setEfapiaoData] = useState(null);
  const [athleteInfo] = useContext(AthleteContext);
  const { prevStep, nextStep, reset, setHasTimedOut } = dialogActions;
  const { selectedLines, activeStep, submissionSteps, lock, hasTimedOut } = dialogState;
  const {
    INSPECT_ITEMS,
    RETURN_INSPECT_TIME_OUT_ERROR_MESSAGE,
    INSPECT,
    SUCCESS,
    ERROR,
    PLEASE_WAIT_AND_RELOAD,
  } = useMemoTranslations(translations);

  const [
    queryOrderDetails,
    { data: responseFromOrderDetailCall, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      dispatchError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      dialogDispatch(reset());
      setSnack(`${INSPECT} ${SUCCESS} ${PLEASE_WAIT_AND_RELOAD}`);
      const { orderDetail } = responseFromOrderDetailCall;
      decorateOrderDetail(orderDetail);
      setOrderDetail(orderDetail);
    },
  });

  const dispatchError = (errorMessage) => {
    dialogDispatch(reset());
    setError(`${INSPECT} ${ERROR} ${errorMessage}`);
  };

  // if locked, don't dispatch reset
  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  // If locked, we will display success snack and message to close the tab - not close the dialog
  const lockedCompletedAction = () => {
    setSnack(`${INSPECT} ${SUCCESS}`);
    dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true));
  };

  // Mutation that calls Inspect Return
  const [sendLineItems, { loading: inspectionLoading }] = useMutation(INSPECT_RETURN, {
    onError: (err) => {
      dispatchReset();
      setError(`${INSPECT} ${ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      const { returnInspections } = response;
      if ((returnInspections.status = ResponseStatuses.COMPLETED && !returnInspections.error)) {
        // if locked, display close tab message, else query order details + close dialog
        lock
          ? lockedCompletedAction()
          : queryOrderDetails({
              variables: {
                orderNumber: orderDetail.orderNumber,
                isFollowUp: true,
              },
            });
      } else if (
        returnInspections.status === ResponseStatuses.IN_PROGRESS ||
        returnInspections.status === ResponseStatuses.PENDING
      ) {
        dispatchError(RETURN_INSPECT_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (returnInspections.error) {
        const errorMessageOnJobCompletion = `${returnInspections.error.httpStatus}: ${returnInspections.error.message}`;
        dispatchError(errorMessageOnJobCompletion);
        dialogDispatch(reset());
      }
    },
  });

  /* commenting this code as a short term solution to not send and efapio info on return inspection
  const [queryOrderInvoice, { data, invoiceDataLoading, error }] = useLazyQuery(
    ORDER_INVOICE_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        setEfapiaoData(null);
        if (!error?.message?.includes('404')) {
          setError(`${ORDER_INVOICE_ERROR}: ${error.message}`);
        }
      },
      onCompleted: () => {
        const efapiaoData = data?.orderInvoice?.objects;
        if (Array.isArray(efapiaoData) && efapiaoData.length > 0) {
          setEfapiaoData(efapiaoData);
        } else {
          setEfapiaoData(null);
        }
      },
    }
  );

  useEffect(() => {
    if (orderDetail.omsRegionReference === 'NIKECN') {
      const parentSalesOrderNumber = orderDetail?.orderLines?.[0]?.parentSalesOrderNumber;
      queryOrderInvoice({
        variables: { orderNumber: parentSalesOrderNumber },
      });
    }
  }, [orderDetail.orderNumber]);
  */

  const thereAreReasonsMissing = areReasonsMissingFrom(selectedLines, DialogTypes.INSPECT);
  const isSubmissionStep = submissionSteps.includes(activeStep);
  const selectedItemKeys = Object.keys(selectedLines);
  const isDispositionMissing = isReturnDispositionMissing(selectedLines);
  const isNotesMissing = isEscalationNotesMissing(selectedLines);
  const isSubmissionLoading = getLoadingStatus() || inspectionLoading;

  const handleSubmit = () => {
    setSlowLoading();
    /* commenting this code as a short term solution to not send efapio info on return inspection
    const vat = findPartialEfapiao(efapiaoData, orderDetail.orderLines);
    const input = structureReturnInspectInput(selectedLines, orderDetail, athleteInfo.email, vat);
    */
    const input = structureReturnInspectInput(selectedLines, orderDetail, athleteInfo.email);
    sendLineItems({
      variables: { input, timeout: ApiTimeOut },
    });
  };

  if (!isSubmissionStep)
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <NextButton
          disabled={selectedItemKeys.length === 0}
          onClick={() => dialogDispatch(nextStep())}
        />
      </div>
    );
  else
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <Button
          variant='contained'
          color='primary'
          data-testid='inspection-submit-button'
          disabled={
            thereAreReasonsMissing ||
            isDispositionMissing ||
            isNotesMissing ||
            isSubmissionLoading ||
            hasTimedOut
          }
          onClick={handleSubmit}
          type='submit'
          className={classes.stepperButton}>
          {INSPECT_ITEMS}
        </Button>
      </div>
    );
}

/**
 *  The values from reason code api and the values accepted
 *  by return inspection API is not same.
 *  Hence we are doing this conversion
 *  @param reasonCodeType from reason codes API
 *  @returns reason code that is accepted by return inspection API
 */
export const getInspectionStatus = (reasonCodeType) => {
  switch (reasonCodeType) {
    case ReasonCodeTypes.INSPECTION_PASS:
      return InspectionStatus.APPROVE;
    case ReasonCodeTypes.REJECT:
      return InspectionStatus.DENY;
    case ReasonCodeTypes.ESCALATION:
      return InspectionStatus.ESCALATE;
    default:
      return null;
  }
};

/**
 * Function that returns the region warehouse and inspector source
 * @param region: the order region
 * @returns an object with the warehouse and inspector source
 */
export const getWarehouseAndInspectorSource = (region, channel) => {
  let warehouse = '';
  let inspectorSource = '';

  const partner = getEmeaPartner({ omsRegionReference: region, channel });

  if (region === Geo.US) {
    warehouse = 'XPO';
    inspectorSource = 'XPO';
  } else if (region === Geo.EUROPE || EmeaPartnersAllowingInspections.has(partner)) {
    // JD sports is also a US partner but this is fine because NIKEUS condition is first
    warehouse = 'Cycleon RRC';
    inspectorSource = 'CountryHub';
  } else if (region === Geo.JAPAN) {
    warehouse = '1085_0008008000';
    inspectorSource = 'TOMISATO';
  } else if (region === Geo.KOREA) {
    warehouse = '1082_0008009001';
    inspectorSource = 'CountryHub';
  } else if (region === Geo.AUSTRALIA) {
    warehouse = '1093_0008001000';
    inspectorSource = 'CountryHub';
  } else {
    warehouse = 'BZNIKE';
    inspectorSource = 'CountryHub';
  }

  return { warehouse, inspectorSource };
};

/**
 * Function that returns request body to call inspect returns
 * @param selectedLines : order lines selected in UI
 * @param orderDetail response from order details call
 * @param contactUser email of athlete taking this action
 * @returns request body to call return inspection
 */
export const structureReturnInspectInput = (selectedLines, orderDetail, contactUser) => {
  const receiptLines = Object.values(selectedLines).map((selectedLine) => {
    let receiptLineNotes = [];

    // default receipt line fields
    let receiptLine = {
      status: getInspectionStatus(selectedLine.inspectReasonCodeType) || '',
      quantity: selectedLine.quantity || '',
      refundReasonCode: selectedLine.inspectionReason.code || '',
      returnDispositionCode: selectedLine.disposition.code,
      orderLineKey: selectedLine.orderLineKey,
      lineNumber: selectedLine.lineNumber.toString(),
    };

    // EU needs gradingResult as an extra field
    if (orderDetail.omsRegionReference === 'NIKEEUROPE') {
      receiptLine.gradingResult = 'GRADE_A';
    }

    if (selectedLine.inspectReasonCodeType) {
      receiptLineNotes.push({
        contactReference: 'CSR',
        contactTime: new Date().toISOString(),
        contactUser,
        contactType: 'Inspection', // From csp code
        noteText: selectedLine.escalationNotes || 'Inspection', // this field can't be empty
        reasonCode: 'RETURN', // Based on csp code
      });
      receiptLine.notes = receiptLineNotes;
    }
    return receiptLine;
  });

  const { warehouse, inspectorSource } = getWarehouseAndInspectorSource(
    orderDetail.omsRegionReference,
    orderDetail.channel
  );

  const result = {
    warehouse: warehouse,
    inspectorSource: inspectorSource,
    documentType: orderDetail.orderType || '',
    shipment: {
      omsRegionReference: orderDetail.omsRegionReference || '',
      orderNumber: orderDetail.orderNumber || '',
    },
    receiptLines: receiptLines || [],
  };
  // commenting this code as a short term solution to not send and efapio info on return inspection
  // if (vat) result.vat = vat;

  return result;
};

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
  actionsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  loadingTab: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingLabel: {
    padding: theme.spacing(1),
  },
}));
