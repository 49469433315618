import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import translations from './exchange.i18n';
import LabelValueText from './labelValueText';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import StyleLink from '../../content/shared/itemLink';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';

/**
 * Displays a product image along with product details.
 *
 * @param orderDetails - required
 * @param orderLine - optional, will use data from orderLine to fill info if not provided in
 * explicit params below
 * @param imgUrl - Url of the image that will be displayed.
 * @param imgTitle - Text to be used with the image's alternate description.
 * @param size - Size of the product being displayed.
 * @param color - color of the product being displayed.
 * @param price - price of the product being displayed.
 * @param showQty - Boolean to show quantity or not, defaults to true
 * @param style - custom styling
 * @returns ProductDetails - a Container with product image and details formatted vertically
 */
const ProductDetails = ({
  orderDetail, // required
  orderLine,
  imgUrl,
  imgTitle,
  size,
  color,
  price,
  style,
}) => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [assets] = useContext(AssetsContext);
  const { SIZE, STYLE, COLOR } = mapValues(translations, i18nString);
  const { colorCode, styleNumber, item, displaySize, colorDescription, linePriceInformation } =
    orderLine || {};
  return (
    <Container className={classes.productDetailsContainer} style={style}>
      <div className={classes.imageContainer}>
        <img
          className={classes.productImage}
          src={getProductImageFromOrderLine(orderLine, assets) || imgUrl}
          alt={imgTitle || item?.itemDescription || `${styleNumber}-${colorCode}`}
        />
      </div>
      <LabelValueText label={`${SIZE}: `} value={size || displaySize} />
      <LabelValueText
        label={`${STYLE}: `}
        value={<StyleLink orderDetail={orderDetail} line={orderLine} />}
      />
      <LabelValueText label={`${COLOR}: `} value={color || colorDescription} />
      <LabelValueText
        style={{ lineHeight: '30px' }}
        value={
          <FormattedCurrency
            amount={price || linePriceInformation?.retailPrice || 0}
            currency={orderDetail.currency}
          />
        }
      />
    </Container>
  );
};

export default ProductDetails;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    height: '230px',
    alignItems: 'center',
  },
  productImage: {
    maxWidth: '200px',
    maxHeight: '230px',
    objectFit: 'cover',
  },
  productDetailsContainer: {
    padding: '0.7em',
    margin: '0',
    width: 'min-content',
  },
}));
