/** React/Utils */
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext } from 'react';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';

/** Local */
import { DialogTypes } from '../../../../constants/dialog.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import HasPermission from '../../../shared/hasPermission';
import { SendResendReturnLabel } from '../../../../constants/permissions.const';
import { getStatus, getStatusDate, getTrackingLinks } from '../../../../utils/shipment';
import translations from './details.i18n';
import { Partners } from '../../../../constants/origin.const.js';
import OrderContext from '../../../../store/contexts/orderContext';

/**
 * React component housing the card content for Return Label details
 * for Return Order Shipments (e.g. tracking number, shipment status, etc.)
 *
 * @param {object} shipment Shipment data object per the order details
 * @param {string} locale Geographic area (e.g. 'en-US') per the order details
 */
const ReturnLabelDetails = ({ shipment, locale }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const [, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);

  const classes = useStyles();

  const {
    TRACKING_NUMBER,
    STATUS,
    STATUS_DATE,
    RESEND_RETURN_LABEL,
    VIEW_CARRIER_TRACKING,
  } = mapValues(translations, i18nString);

  const handleOpenResendReturnLabelDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.RESEND_RETURN_LABEL));
  };

  const shipmentExists = shipment && Object.keys(shipment).length > 0;

  return (
    <Table data-testid='return-label-card'>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.tableHeader}>{TRACKING_NUMBER}</TableCell>
          <TableCell className={classes.tableHeader}>{STATUS}</TableCell>
          <TableCell className={classes.tableHeader}>{STATUS_DATE}</TableCell>
          <HasPermission permission={SendResendReturnLabel} region={orderDetail.omsRegionReference}>
            <TableCell className={classes.tableHeader}></TableCell>
          </HasPermission>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.contentRow}>
          <TableCell className={classes.tableCell}>
            {shipmentExists
              ? getTrackingLinks(shipment).map((trackingLinkObj, i) => (
                  <ListItem className={classes.trackingLinkListItem} key={i}>
                    {trackingLinkObj.link ? (
                      <Link
                        aria-label='shipment tracking'
                        href={trackingLinkObj.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        data-testid={`return-tracking-link-${i}`}>
                        {trackingLinkObj.trackingNum || VIEW_CARRIER_TRACKING}
                      </Link>
                    ) : (
                      trackingLinkObj.trackingNum
                    )}
                  </ListItem>
                ))
              : '-'}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {shipmentExists ? getStatus(shipment) : '-'}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {shipmentExists ? getStatusDate(shipment, locale) : '-'}
          </TableCell>
          <HasPermission permission={SendResendReturnLabel} region={orderDetail.omsRegionReference}>
            <TableCell className={classes.tableCell} align={'right'}>
              {/* conditionally render RESEND_RETURN_LABEL when order is not from a partner */}
              {!Partners[orderDetail?.channel] && (
                <Button
                  className={classes.resendButton}
                  onClick={handleOpenResendReturnLabelDialog}
                  aria-label={RESEND_RETURN_LABEL.toLowerCase()}
                  data-testid='resend-return-label'>
                  {RESEND_RETURN_LABEL}
                </Button>
              )}
            </TableCell>
          </HasPermission>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  contentRow: {
    height: '30px',
  },
  headerRow: {
    height: '30px',
    fontSize: '0.75rem',
  },
  tableCell: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  tableHeader: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  resendButton: {
    color: theme.palette.primary.main,
  },
  trackingLinkListItem: {
    padding: 0,
  },
}));

export default ReturnLabelDetails;
