import config from './config';

/**
 * opens new tab at provided url inside SalesForce
 * @param {*} url url to be opened
 */
export const newWindowInSF = (url) => {
  const sourceId = window.name;
  const giftCardAppMessage = {
    sourceId: sourceId,
    type: 'OPEN_PAGE',
    data: {
      url: url,
      target: 'TAB', // or SUBTAB
      label: 'OMOBO_GIFT_CARD', // this will be mapped to the actual label on SF end, we can define more labels for other purposes
    },
  };

  window.parent.postMessage(giftCardAppMessage, config.salesForceUrl);
};

export const protocolAndHost = () => `${window.location.protocol}//${window.location.host}`;
