import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Invoices from './invoices';
import translations from '../payments.i18n';
import BasicTableRow from '../../../../shared/table/tableRow';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../../hooks/useSnacks';
import OrderContext from '../../../../../store/contexts/orderContext';
import ORDER_INVOICE_QUERY from '../../../../../queries/orderInvoice.query';
import ORDER_DETAIL_QUERY from '../../../../../queries/orderDetail.query';
import {
  isTransactionCash,
  transformTransactionDetailsForInvoice,
} from '../../../../../utils/payment';
import OpenTransactionStatus from './openTransactionStatus';
import hasPermission from '../../../../shared/hasPermission';
import { MarkOpenPaymentsAsClosed } from '../../../../../constants/permissions.const';
import { getFilledOrderInvoices, invoiceDoesNotExist } from '../../../../../utils/invoice';

/**
 * displays transaction data with a built in invoice accordion
 * @param {object} params
 * chargeDetail object
 * i int
 * orderInvoices object
 * open boolean
 * setOpen function
 * loading boolean
 * @returns jsx element
 */
const TransactionRow = ({
  chargeDetail,
  index,
  orderInvoicesState,
  setOrderInvoices,
  GcOrderLink,
}) => {
  const [open, setOpen] = useState({ index: -1, invoiceNo: null, data: { invoiceNo: null } });
  const classes = useStyles();
  const { setError } = useSnacks();
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const { locale, currency } = orderDetail;
  const { ARIA_INVOICE_LOADING, ORDER_INVOICE_ERROR } = useMemoTranslations(translations);

  // Queries invoices details
  const [queryOrderInvoice, { loading, error: errorInvoice }] = useLazyQuery(ORDER_INVOICE_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setOrderInvoices([]);
      setError(`${ORDER_INVOICE_ERROR}: ${errorInvoice.message}`);
    },
    onCompleted: (dataInvoice) => {
      const invoiceData = dataInvoice?.orderInvoice?.objects;
      if (Array.isArray(invoiceData) && invoiceData.length > 0) {
        const filled = getFilledOrderInvoices(orderInvoicesState, invoiceData);
        setOrderInvoices(filled);
        setOpen((curr) => {
          return {
            index: curr.index,
            invoiceNo: curr.invoiceNo,
            data: invoiceData.find((item) => item.invoiceNo === curr.invoiceNo),
          };
        });
      }
    },
  });

  useEffect(() => {
    if (open.invoiceNo && invoiceDoesNotExist(orderInvoicesState, open.invoiceNo)) {
      queryOrderInvoice({
        variables: { invoiceNumber: open.invoiceNo },
      });
    }
  }, [open]);

  // Queries order details
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: () => {
      setError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      const { orderDetail } = queryOrderDetailsData;
      setOrderDetail(orderDetail);
    },
  });

  // method to re-fetch orderDetails after any modifying operation
  const reFetchOrderDetails = () => {
    queryOrderDetails({
      variables: {
        orderNumber: orderDetail.orderNumber,
      },
    });
  };

  return (
    <React.Fragment>
      <BasicTableRow
        body={false}
        data={transformTransactionDetailsForInvoice(
          chargeDetail,
          locale,
          currency,
          (i, invoiceNo) => {
            setOpen((curr) =>
              curr.index === index
                ? { ...curr, index: -1 }
                : {
                    index: index,
                    invoiceNo: curr.invoiceNo || invoiceNo,
                    data: orderInvoicesState.find(
                      (item) => item.invoiceNo === (curr.invoiceNo || invoiceNo)
                    ),
                  }
            );
          },
          index,
          open,
          classes,
          hasPermission(MarkOpenPaymentsAsClosed, orderDetail.omsRegionReference) &&
            orderDetail.omsRegionReference === 'NIKEJP' &&
            isTransactionCash(orderDetail, chargeDetail.paymentKey) &&
            chargeDetail.status === 'OPEN' ? (
            <OpenTransactionStatus
              transaction={chargeDetail}
              omsRegion={orderDetail.omsRegionReference}
              orderNumber={orderDetail.orderNumber}
              onCloseTransaction={reFetchOrderDetails}
            />
          ) : (
            <Typography
              variant='subtitle2'
              className={classes.wrapIcon}
              data-testid={`closed-transaction-${chargeDetail.chargeTransactionKey}`}>
              {chargeDetail.status}
            </Typography>
          ),
          GcOrderLink
        )}
        cellRootClassName={classes.tableCell}
      />
      <TableRow>
        <TableCell className={classes.transparent} colSpan={8} padding='none' align='center'>
          <Collapse in={open.index === index} timeout='auto' unmountOnExit>
            {loading || open.data?.invoiceNo !== open.invoiceNo ? (
              <CircularProgress
                className={classes.loaderMargin}
                aria-label={ARIA_INVOICE_LOADING}
              />
            ) : (
              <Invoices
                invoiceDetails={open.data}
                invoiceList={chargeDetail}
                locale={locale}
                action={(invoiceNo) => {
                  setOpen((curr) =>
                    curr.index === index && curr.invoiceNo === invoiceNo
                      ? { ...curr, index: -1 }
                      : {
                          index: index,
                          invoiceNo: invoiceNo,
                          data: orderInvoicesState.find((item) => item.invoiceNo === invoiceNo),
                        }
                  );
                }}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

TransactionRow.propTypes = {
  chargeDetail: PropTypes.object,
  index: PropTypes.number,
  orderInvoicesState: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceNo: PropTypes.string,
    })
  ),
  setOrderInvoices: PropTypes.func,
  GcOrderLink: PropTypes.elementType,
};
const useStyles = makeStyles((theme) => ({
  transparent: {
    backgroundColor: theme.palette.grey[100],
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  loaderMargin: {
    margin: theme.spacing(2.5),
  },
  carrot: {
    color: 'rgb(55,141,248)',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}));

export default TransactionRow;
