import React from 'react';
import { TableRow, TableHead, TableCell, TableBody } from '@material-ui/core';
import { isValidReactChild } from '../../../utils/reactUtils';

/**
 * Pass in string of values, returns a table row,
 * result needs to still be wrapped in a <Table> tag
 *
 * @prop {boolean} header - True if the tablerow is a header
 * @prop {array} data - Required array of values for each cell in the row
 * @prop {string} rowClassName - Optional className to apply to the row
 * @prop {string} cellRootClassName - Optional className to apply to the cell root
 * @prop {string || array} cellClassName - Optional className to apply to each cell,
 * if array, each className will be applied in zero-indexed order.
 */
export default function BasicTableRow({
  action,
  header,
  body,
  data,
  rowClassName,
  cellClassName,
  cellRootClassName,
  testId = '',
}) {
  const hasClassArray = Array.isArray(cellClassName);

  const tableRow = (
    <TableRow className={rowClassName} onClick={action} data-testid={testId}>
      {data &&
        data.map((content, i) => {
          return (
            <TableCell
              key={i}
              classes={{ root: cellRootClassName }}
              className={hasClassArray ? cellClassName[i] : cellClassName}>
              {isValidReactChild(content) && content}
            </TableCell>
          );
        })}
    </TableRow>
  );

  if (!body) return tableRow;

  return header ? <TableHead>{tableRow}</TableHead> : <TableBody>{tableRow}</TableBody>;
}
