import gql from 'graphql-tag';

const BURN_GC_MUTATION = gql`
  mutation burnGiftCards($giftCardsToBurn: [BurnGiftCardInput]!, $timeout: Int!) {
    burnGiftCards(giftCardsToBurn: $giftCardsToBurn, timeout: $timeout) {
      id
      status
      eta
      resourceType
      links {
        self {
          ref
        }
      }
      giftCardNumber
      error {
        httpStatus
        message
        errors {
          code
          message
          field
        }
      }
    }
  }
`;

export default BURN_GC_MUTATION;
