import React, { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import translations from './noPermission.i18n';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

export const NoPermission = () => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { NO_PERMISSION_MESSAGE } = mapValues(translations, i18nString);

  return (
    <Alert className={classes.alert} variant='outlined' severity='info'>
      {NO_PERMISSION_MESSAGE}
    </Alert>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    width: '55%',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(1),
    borderRadius: '18px',
  },
}));
