import React, { useContext, useEffect, useState } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import translations from './exchange.i18n';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { DiscountTypes } from '../../../../constants/dialog.const';
import { getLineTotal, getNewSkuPrice } from '../../../../utils/price';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { PauseBeforeValidation } from '../../../../constants/exchange.const';

const DiscountSection = ({ disabled, lineNumber, reasonCodes }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const translationStrings = mapValues(translations, i18nString);
  const {
    ENTER_ITEM_DISCOUNT,
    ENTER_AMOUNT,
    ENTER_PERCENTAGE,
    EXCHANGE_ITEM_IS_MORE_EXPENSIVE,
    FINAL_ITEM_PRICE,
    INVALID_DISCOUNT_AMOUNT,
    INVALID_DISCOUNT_PERCENTAGE,
    INVALID_DISCOUNT_NUMBER,
    REASON_CODES_LOADING,
    SELECT_REASON,
  } = translationStrings;
  const { setSelectedSkuDiscount } = dialogActions;
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { selectedSkus, selectedLines } = dialogState;
  const [orderDetail] = useContext(OrderContext);
  const classes = useStyles();

  const selectedSku = selectedSkus[lineNumber];
  const { discount } = selectedSku;
  const selectedLine = selectedLines[lineNumber];
  const { PERCENT_OFF, AMOUNT_OFF, EXACT_PRICE } = DiscountTypes;
  const [discountValuePlaceholder, setDiscountValuePlaceholder] = useState(ENTER_PERCENTAGE);
  const [missingReasonWarning, setMissingReasonWarning] = useState(false);
  const [finalItemPrice, setFinalItemPrice] = useState(
    getNewSkuPrice(selectedSku?.priceInfo?.total, discount)
  );
  const [validationTimeout, setValidationTimeout] = useState(null);
  /**
   * Validate discount input
   *
   * @param {object} discount - New discount value for selected sku
   * @return {object} errorData - { isInvalid: bool, message: case-specific error message }
   */
  const getDiscountValidationError = (discount) => {
    const unitPrice = selectedSku.priceInfo?.total;
    let invalidDiscount = false;
    let errorMessage = '';
    /*
      Set discount validation error to true if...
      a) amount_off/exact_price discount > total price of item is entered
      b) percentage discount > 100% is entered
      c) non-numeric value is entered 
    */
    if (
      (discount.type === AMOUNT_OFF || discount.type === EXACT_PRICE) &&
      Number(discount.value) > Number(unitPrice)
    ) {
      invalidDiscount = true;
      errorMessage = INVALID_DISCOUNT_AMOUNT;
    } else if (discount.type === PERCENT_OFF && Number(discount.value) > 100) {
      invalidDiscount = true;
      errorMessage = INVALID_DISCOUNT_PERCENTAGE;
    } else if (discount.value && !Number(discount.value)) {
      invalidDiscount = true;
      errorMessage = INVALID_DISCOUNT_NUMBER;
    }
    const newSkuPrice = getNewSkuPrice(selectedSku?.priceInfo?.total, discount);
    return {
      isInvalid: Boolean(newSkuPrice < 0) || invalidDiscount,
      message: errorMessage,
    };
  };

  /**
   * This callback handles user changes to the discount value field for a given exchange item
   * @param {object} target - event target for input value change
   */
  const handleDiscountChange = ({ target }) => {
    const { name: formName, value } = target;
    const name = formName.slice(0, formName.indexOf('-'));
    /**
     * passes ['0.0', '1000.0', '1000', '1.25', '1.0', '0.5', '.5', '.10', '.0', '1.'];
     * fails ['e', ' ', '_', 'whatever', 'e.1', '1.000', '.0000000000000000'];
     */
    if (name === 'value' && !value.match(/^[0-9]*([.][0-9]{0,2})?$/)) return;

    const newDiscount = {
      ...selectedSku?.discount,
      [name]:
        name === 'reason' ? reasonCodes?.data?.find((reason) => reason.code === value) : value,
    };
    // ensure the correct placeholder for discount value textfield
    if (name === 'type') {
      updateDiscountValuePlaceholder(value);
    }
    // process error validation for discount value textfield
    const error = getDiscountValidationError(newDiscount);
    const newSkuPrice = getNewSkuPrice(selectedSku?.priceInfo?.total, newDiscount);
    clearTimeout(validationTimeout);
    if (getLineTotal(selectedLine) < newSkuPrice) {
      setValidationTimeout(
        setTimeout(() => {
          newDiscount.error = true;
          newDiscount.errorMessage = EXCHANGE_ITEM_IS_MORE_EXPENSIVE;
          setFinalItemPrice(newSkuPrice);
          dialogDispatch(setSelectedSkuDiscount(newDiscount, lineNumber));
        }, PauseBeforeValidation)
      );
    }
    if (error.isInvalid) {
      newDiscount.error = true;
      newDiscount.errorMessage = error.message;
    } else {
      newDiscount.error = false;
      newDiscount.errorMessage = '';
    }
    setFinalItemPrice(newSkuPrice);
    dialogDispatch(setSelectedSkuDiscount(newDiscount, lineNumber));
  };

  // Conditionally sets the placeholder for Discount Value input
  const updateDiscountValuePlaceholder = (type) => {
    switch (type) {
      case PERCENT_OFF:
        setDiscountValuePlaceholder(ENTER_PERCENTAGE);
        break;
      case AMOUNT_OFF:
        setDiscountValuePlaceholder(ENTER_AMOUNT);
        break;
      case EXACT_PRICE:
        setDiscountValuePlaceholder(ENTER_AMOUNT);
        break;
      default:
        setDiscountValuePlaceholder(ENTER_PERCENTAGE);
        break;
    }
  };

  /**
   * Form field validation for discount value textfield, to be run
   * only onBlur/onFocusOut. After selecting/focusing the textfield and failing
   * to enter a value, user is notified they must provide a value.
   */
  const handleValueFocusOut = ({ target }) => {
    if (discount.value && !discount.reason.id && !discount?.errorMessage) {
      setMissingReasonWarning(true);
    }
  };

  useEffect(() => {
    /**
     * delayed validation message if a discount has been entered but no reason has been selected
     */
    if (
      discount.value &&
      !discount.reason.id &&
      !discount?.errorMessage &&
      !(getLineTotal(selectedLine) < getNewSkuPrice(selectedSku?.priceInfo?.total, discount))
    ) {
      const timer = setTimeout(() => setMissingReasonWarning(true), PauseBeforeValidation);
      return () => clearTimeout(timer);
    }
    setMissingReasonWarning(false);
  }, [discount]);

  useEffect(() => {
    if (getLineTotal(selectedLine) < finalItemPrice && !disabled) {
      const discount = selectedSku?.discount;
      discount.error = true;
      discount.errorMessage = EXCHANGE_ITEM_IS_MORE_EXPENSIVE;
      dialogDispatch(setSelectedSkuDiscount(discount, lineNumber));
    }
  }, [discount]);
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControl>
          <FormLabel id={`selected-item-${lineNumber}-discount-label`} className={classes.subtitle}>
            {ENTER_ITEM_DISCOUNT}
          </FormLabel>

          <RadioGroup
            row
            aria-labelledby={`selected-item-${lineNumber}-discount-label`}
            name={`type-discount-${lineNumber}`}
            defaultValue={DiscountTypes.PERCENT_OFF}
            onChange={handleDiscountChange}
            value={discount?.type}
            className={classes.radioGroup}>
            {Object.entries(DiscountTypes).map(([key, val]) => (
              <FormControlLabel
                key={`discount-type-${key}`}
                classes={{ root: classes.controlLabelRoot, label: classes.controlLabelLabel }}
                value={val}
                data-testid={`discount-type-${lineNumber}-${val}`}
                control={<Radio color='primary' />}
                label={translationStrings[key]}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box className={classes.inputsWrapper}>
        <TextField
          value={discount?.value}
          onChange={handleDiscountChange}
          onBlur={handleValueFocusOut}
          name={`value-discount-${lineNumber}`}
          variant='outlined'
          data-testid={`value-discount-${lineNumber}`}
          placeholder={discountValuePlaceholder}
          error={Boolean(discount?.errorMessage) ? discount?.error : false}
          FormHelperTextProps={{ classes: { root: classes.helperTextRoot } }}
          helperText={
            discount?.errorMessage && (
              <Typography data-testid={`value-discount-${lineNumber}-helpertext`} variant='caption'>
                {discount?.errorMessage}
              </Typography>
            )
          }
          disabled={disabled}
        />
        <Box className={classes.selectContainer}>
          {reasonCodes?.loading ? (
            <CircularProgress aria-label={REASON_CODES_LOADING} size='1rem' />
          ) : (
            <FormControl>
              <Select
                value={discount?.reason?.code || '-1'}
                onChange={handleDiscountChange}
                variant='outlined'
                data-testid={`reason-discount-${lineNumber}`}
                name={`reason-discount-${lineNumber}`}
                disabled={disabled || Boolean(discount?.errorMessage)}
                error={missingReasonWarning}
                color={missingReasonWarning ? 'secondary' : 'primary'}
                aria-label={SELECT_REASON}>
                <MenuItem value='-1' disabled>
                  {SELECT_REASON}
                </MenuItem>
                {Array.isArray(reasonCodes?.data) &&
                  reasonCodes?.data?.map((reason, j) => (
                    <MenuItem
                      key={reason.id}
                      value={reason.code}
                      data-testid={`exchange-discount-reason-${j}`}>
                      {reason.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {reasonCodes?.error && <Alert severity='warning'>{reasonCodes?.error?.message}</Alert>}
          {!disabled && !discount?.errorMessage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: '0.5rem',
              }}>
              <Typography
                color={discount?.error ? 'error' : 'inherit'}
                data-testid={`final-price-${lineNumber}`}
                classes={{
                  colorInherit: discount?.error
                    ? classes.displayNone
                    : classes.finalItemPriceSuccess,
                }}>
                {FINAL_ITEM_PRICE}:&nbsp;
                <FormattedCurrency amount={finalItemPrice} currency={orderDetail.currency} />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

DiscountSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  lineNumber: PropTypes.string.isRequired,
  reasonCodes: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  }),
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  displayNone: {
    display: 'none',
  },
  subtitle: {
    color: palette.text.primary,
    fontWeight: 500,
    marginBottom: spacing(2),
  },
  controlLabelRoot: {
    'marginRight': spacing(3),
    'marginBottom': spacing(1),
    '& .MuiRadio-root': {
      padding: `0 ${spacing(1)}px`,
    },
  },
  radioGroup: {
    marginBottom: spacing(1),
  },
  inputsWrapper: {
    'display': 'flex',
    'justifyContent': 'space-between',
    '& > :first-child': {
      marginRight: spacing(2),
    },
    '& .MuiBox-root': {
      width: '100%',
    },
    'maxWidth': '500px',
  },
  helperTextRoot: {
    margin: `${spacing(1)}px 0`,
  },
  selectContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-end',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  finalItemPriceSuccess: {
    color: palette.validation.success,
    whiteSpace: 'nowrap',
  },
  finalItemPriceError: {
    color: palette.validation.error,
    whiteSpace: 'nowrap',
  },
}));

export default DiscountSection;
