module.exports = [
  {
    name: 'New South Wales',
    label: 'New South Wales',
    abbreviation: 'NSW',
  },
  {
    name: 'Victoria',
    label: 'Victoria',
    abbreviation: 'VIC',
  },
  {
    name: 'Queensland',
    label: 'Queensland',
    abbreviation: 'QLD',
  },
  {
    name: 'Tasmania',
    label: 'Tasmania',
    abbreviation: 'TAS',
  },
  {
    name: 'South Australia',
    label: 'South Australia',
    abbreviation: 'SA',
  },
  {
    name: 'Western Australia',
    label: 'Western Australia',
    abbreviation: 'WA',
  },
  {
    name: 'Northern Territory',
    label: 'Northern Territory',
    abbreviation: 'NT',
  },
  {
    name: 'Australian Capital Territory',
    label: 'Australian Capital Territory',
    abbreviation: 'ACT',
  },
];
