import { formatDate } from './date';

// every function in this file operates on a shipment object

/**
 * Give me a shipment, and I'll tell you if it's been delivered.
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @return {boolean} delivered status
 */
export const isDelivered = (shipment) => {
  const status = shipment && shipment.carrierStatus;
  // explicity cast to boolean, so that `undefined` will return as `false`
  return Boolean(typeof status === 'string' && status === 'DELIVERED');
};

/**
 * Given a shipment provides the tracking number, tracking link, and quantity for each container
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {object array} an array of objects each containing fields trackingNum, link, and qty
 */
export const getTrackingLinks = (shipment) => {
  if (!shipment) return;
  const { containers, orderType } = shipment;
  if (!containers && !orderType) return;

  const customerTrackingUrl = shipment.consolidatedCustomerTrackingUrl;

  if (orderType?.includes('RETURN')) {
    // shipment.carrierTrackingUrl and shipment.trackingNumber only populate for return orders
    const carrierTrackingUrl = shipment.carrierTrackingUrl;
    const trackingNumber = shipment.trackingNumber;
    return [{ trackingNum: trackingNumber, link: carrierTrackingUrl || customerTrackingUrl }];
  } else if (Array.isArray(containers) && containers.length) {
    // for all other orderTypes iterate over containers to find info for each
    return containers.map((container) => {
      /*
       * if the first shipment line in the container has a quantity,
       * start there and add the rest of the shipment lines. if not, quantity returned is undefined
       */
      let quantity = container.shipmentLines?.[0]?.quantity;
      if (quantity) {
        for (let i = 1; i < container.shipmentLines?.length; i++) {
          quantity += container.shipmentLines[i].quantity;
        }
      }

      return {
        trackingNum: container.trackingNumber,
        link: container.carrierTrackingUrl || container.customerTrackingUrl,
        qty: quantity,
      };
    });
  } else return;
};

// abstracted logic to reduce code duplication in the functions that follow

/**
 * A utility to reduce duplication in other functions fetching fields form the shipment object.
 *
 * for example,
 *    getShipmentField(shipment, 'standardCarrierAlphaCode', carrierAlphaCodeToNameMap)
 * translates to
 *    carrierAlphaCodeToNameMap(shipment.standardCarrierAlphaCode)
 * but does so in a safe manner, with failout values.
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @param {string} field – the field we're after on the shipment object
 * @param {*} [map] – optional map to apply the field against prior to returning
 * @returns {*} whatever the object and/or map contains! it could be anything
 */
export const getShipmentField = (shipment, field, map) => {
  // a shipment is required
  if (!shipment || !field) return;
  // fetch the value of the field we're after
  let value = shipment[field];
  // if there is no such field, abort
  if (!value) return;
  // if there's no map passed in, we're done here!
  if (!map) return value;

  // prep the field for mapping and map it
  value = value.toUpperCase().trim();
  const name = map[value];

  // return either the result of the map, or, failing that, the value of the field fetched above
  return name ? name : value;
};

/**
 *
 * A function to fetch carrier from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string}
 */
export const getCarrier = (shipment) => {
  return getShipmentField(shipment, 'standardCarrierAlphaCode', carrierAlphaCodeToNameMap);
};

/**
 * A function to fetch shipping method from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string} the shipping method
 */
export const getShippingMethod = (shipment) => {
  return getShipmentField(shipment, 'actualShippingMethod', shippingMethodMap);
};

/**
 * A function to fetch ship group from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string} the shipment identifier
 */
export const getShipGroup = (shipment) => {
  return getShipmentField(shipment, 'shipmentIdentifier');
};

/**
 * A function to fetch status from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string} the shipping status
 */
export const getStatus = (shipment) => {
  return getShipmentField(shipment, 'carrierStatus', statusMap);
};

/**
 * A function to fetch the modification date from a shipment object
 *
 * @param {object} shipment - a shipment object, a child of our order objects
 * @param {string} locale - location of the order to format date appropriately
 * @returns {string} the shipment date, formatted nicely
 */
export const getStatusDate = (shipment, locale) => {
  return formatDate(getShipmentField(shipment, 'modificationDate', locale));
};

/**
 * A function to fetch shipment date from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @param {string} locale - location of the order to format date appropriately
 * @returns {string} the shipment date, formatted nicely
 */
export const getShipmentDate = (shipment, locale) => {
  return formatDate(getShipmentField(shipment, 'actualShipmentDate'), locale);
};

/**
 * A function to fetch delivery date from a shipment object
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string} the delivery date, formatted nicely
 */
export const getDeliveryDate = (shipment, locale) => {
  if (!shipment) return '';
  const { containers } = shipment;
  const container = containers ? containers[0] : null;
  const edd = getShipmentField(container, 'actualDeliveryDate');
  return edd ? formatDate(edd, locale) : '';
};

/**
 * A function to fetch the key for the orderLine that matches the provided shipment
 *
 * @param {object} shipment – a shipment object, a child of our order objects
 * @returns {string} the orderLineKey
 */
export const getOrderLineKey = (shipment) => {
  return shipment?.containers?.[0]?.shipmentLines?.[0]?.orderLineIdentifier;
};

export const lineShipmentMatching = (shipments, orderLines) => {
  const allShippedOrderLines = new Set();
  if (!shipments?.length > 0 || !orderLines?.length > 0)
    return { shippingContainers: [[undefined]], allShippedOrderLines };

  return {
    shippingContainers: shipments.map(
      (shipment) =>
        Array.isArray(shipment?.containers) &&
        [].concat(
          ...shipment.containers.map((container) => {
            return (
              Array.isArray(container.shipmentLines) &&
              container.shipmentLines.map((shipmentLine, i) => {
                let { orderLineIdentifier: shipLineKey } = shipmentLine;
                let matchingOrderLines = [];
                /** for complex orderLine types, we need to check other logic:
                 *    -for multi-statuses, we need to check which is in shipped status
                 *    -for NikeId orders, we need to check all NikeId orderLines
                 */

                orderLines.forEach((line) => {
                  if (line.orderLineType === 'NIKEID') {
                    line.nikeIdLines.forEach((subLine) => {
                      if (subLine.orderLineKey?.includes(shipLineKey))
                        matchingOrderLines.push(line);
                    });
                  } else {
                    if (line.orderLineKey?.includes(shipLineKey)) matchingOrderLines.push(line);
                  }
                });

                if (matchingOrderLines.length > 0) {
                  matchingOrderLines.forEach((line) => {
                    // if any of the statuses are in the Shipped status (statusCode has 3700)
                    if (line.statuses?.some((status) => status.statusCode?.includes('3700'))) {
                      shipLineKey = line.orderLineKey;
                      allShippedOrderLines.add(line.orderLineKey);
                    }
                  });
                }
                return shipLineKey;
              })
            );
          })
        )
    ),
    allShippedOrderLines,
  };
};
// TODO fill this out
const shippingMethodMap = {
  GROUND_SERVICE: 'Ground',
  // there's more, see https://developer.niketech.com/docs/projects/Shipping%20Methods%20V2?tab=api
};

const statusMap = {
  SHIPPED: 'Shipped',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  DELIVERED: 'Delivered',
};

// found by googling 'standard carrier alpha code'
const carrierAlphaCodeToNameMap = {
  AACT: 'AAA Cooper',
  ABFS: 'ABF Freight System',
  AIRB: 'Airborne Express',
  ASAQ: 'Allstates WorldCargo',
  AMCJ: 'American Star Industries',
  APAC: 'A - P - A Transport',
  ASUR: 'Asturias Trucking',
  AVRT: 'Averitt Express',
  AZEC: 'Azteca Enterprises',
  BNAF: 'BAX Global',
  MAZR: 'Benjamin Mazer Trucking',
  BETM: 'Betmar Hats',
  BVGT: 'Beverage Transportation',
  BLUS: 'Blue Sky Services',
  BDGJ: 'Bulldog',
  CNGD: 'C & G Delivery',
  RBTN: 'C.H.Robinson',
  CFAF: 'CF AirFreight',
  CAIE: 'California Transport Enterprises',
  CDHT: 'Cathy Daniels',
  CGMC: 'Cargo Transport',
  CDNK: 'Celadon Trucking',
  CFX: 'City Fashion Express',
  COHN: 'Cohen',
  CXIB: 'Combined Express',
  CFWY: 'Consolidated Freightways',
  CTSM: 'Contract Transportation Systems iCTS',
  CWCE: 'Conway Central Express',
  CRSL: 'Cressler Trucking',
  CRST: 'CRST',
  CMQT: 'CTC / Cosmopolitan Trucking',
  CGLY: 'Custom Global Logistics(formerly DSI  Worldwide)',
  DACC: 'Dallas Carriers',
  DHLC: 'DHL',
  ADDI: 'David Allen Inc.',
  DAWI: 'Dawes Transport',
  DTLI: 'Diamond Trucking',
  DRSA: 'Direct Shippers',
  DBYA: 'Distribution by Air, Inc.',
  DMSX: 'DMS Trucking',
  DYXI: 'Dynamic Express',
  EAGL: 'Eagle Global Logistics(formerly Eagle  USA)',
  ERYT: 'Early Transportation Services',
  ESFS: 'Essential Freight Systems',
  EXLA: 'Estes Express Lines',
  FEDX: 'FedEx',
  FOPC: 'Fort Pitt Consolidators',
  FDTD: 'Fritz Domestic Air Frieght',
  FMIT: 'FMI',
  FRZF: 'Frozen Food Express - FFE',
  LTL: 'Georgia Southern	GASR',
  GMOT: 'Glen Moore Transport',
  GCTQ: 'Gulf Coast Transport',
  HBNL: 'H B & L Trucking',
  HDDD: 'Haddad Apparel Group',
  HLSH: 'Hillside Whse.And Trucking',
  HUDD: 'HUDD Distribution',
  IMPK: 'Impac Corp(formerly AIT)',
  ILWH: 'Island Park Trucking',
  JQET: 'J Q Express',
  JDFD: 'Jaydees Trucking',
  AODY: 'Jefco',
  JESA: "JSA - Jeweler's Shipper's Association",
  KHNT: 'Kuhn Transportation(KTC)',
  LAHP: 'L & H Trucking',
  LDTS: 'Ladd Transportation',
  LEAM: 'Lanstar Express America',
  LEBI: 'Lebarnold',
  LFBX: 'Lefebvre Carriers',
  LMDW: 'LMD Warehousing',
  LGXN: 'Logisticxs Transport Inc.',
  MXKX: 'Max Kafer',
  MGMC: 'M G M TransPort',
  MIDX: 'MidEx / Midnight Express',
  MJKN: 'MJR Trucking',
  MTGP: 'MTI Group',
  NPAT: 'NAPA Transportation',
  NART: 'National Retail Consolidators',
  NRAD: 'National Routing and Distribution',
  NEMF: 'New England Motor Freight',
  NPME: 'New Penn Motor Express',
  NSHT: 'North Shore',
  ODFL: 'Old Dominion Freight Line',
  OMGX: 'Omega Transportation',
  ONWY: 'Oneway Warehousing and Distribution',
  OPUD: 'ONG Pick up and Delivery',
  OVNT: 'Overnite Transport',
  JMET: 'Peninsula Global',
  PFSI: 'Performance Freight Systems',
  PDCT: 'Phoenix Down',
  PAAF: 'Pilot Air Freight',
  PITD: 'Pitt - Ohio',
  PLYR: 'Plymouth Rock Transportation',
  PRES: 'Preston Trucking',
  QKAX: 'Quick Apparel Marking Service',
  RSDN: 'R & S Distribution',
  RSEL: 'Red Star',
  RAJL: 'R J Leasing',
  RCSY: 'Republic Cargo Systems',
  RDWY: 'Roadway Express',
  RPSI: 'RPS',
  SXPP: 'S & H Express',
  SFGD: 'Safeguard Trucking',
  SLPP: 'Select Transportation',
  SVBA: 'Service By Air',
  SHXP: "Shannahan's Express",
  SWXA: 'Spirit Warriors',
  SUKN: 'Summit Transportation',
  TMDC: 'T & M',
  TCTI: 'T.C.Transportation',
  TGAF: 'Target Logistics',
  TEDT: 'Tedmar Trucking',
  TLKX: 'TL Krup',
  TONI: "Tony's Express",
  TFCH: 'Traffic Tech',
  TEXP: 'TransGroup Worldwide',
  TTPJ: 'Triangle Transport',
  ACSK: 'Triangle Trucking',
  UPSI: 'UPS',
  UPSN: 'United Parcel Service',
  USFC: 'US Freightways',
  RETL: 'USF Reddaway',
  USIT: 'USA Trucking',
  VMSX: 'Value Motor Services',
  WWAT: 'Watkins Motor Lines',
  WBSI: 'Wells Bros.',
  YFSL: 'Yellow Freight System',
};
