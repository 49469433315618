export const codeArray = [
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Japan Nike Store',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '500',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Japan Retail Studio',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '501',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike Japan',
    CodeShortDescription: '',
    CodeType: 'WQENTERPRISECODE',
    CodeValue: 'NIKEJP',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Advance Exchange - Cancel Item on Receipt',
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '481',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '0150 BRD Digital',
    CodeShortDescription: 'OWNED',
    CodeType: 'WQFULFILLER',
    CodeValue: 'BRD',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike ID Factory',
    CodeShortDescription: 'OWNED',
    CodeType: 'WQFULFILLER',
    CodeValue: 'NIKEID',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Givex US',
    CodeShortDescription: 'OWNED',
    CodeType: 'WQFULFILLER',
    CodeValue: 'GIVEXUS',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1010 Menlo Golf',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1010',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1047 Northridge Footwear',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1047',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1051 Shelby Aprl/Equip',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1051',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1015 NFL',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1015',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1016 ArtFx',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1016',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: '1017 Robinsons',
    CodeShortDescription: 'DROPSHIP',
    CodeType: 'WQFULFILLER',
    CodeValue: '1017',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Fraud - Per Customer',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '17',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Out of Stock - Recycled UPC',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '18',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Duplicate order',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '19',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Delivery Timeframe',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '20',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Order created by mistake',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '25',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Need to change shipping address',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '26',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Need to change delivery method',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '27',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Need to change billing address',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '28',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Need to change payment method',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '29',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Change Quantity',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '30',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Change Style/Color/Size',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '31',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Fraud - Per Nike',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '33',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Fraud - Other',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '34',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Out of Stock - Materials unavailable (iD)',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '35',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Out of Stock - Other',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '36',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'FTC Cancel - System request',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '37',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Out of Stock - Inventory shortage',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '55',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PiD Cancel',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '88',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'FTC Cancel - Customer request',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '99',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Consumer Request - Unable to modify',
    CodeShortDescription: '',
    CodeType: 'CANCEL',
    CodeValue: '215',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Price match',
    CodeShortDescription: '',
    CodeType: 'PRICE_ADJUSTMENT',
    CodeValue: '38',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Pricing error',
    CodeShortDescription: '',
    CodeType: 'PRICE_ADJUSTMENT',
    CodeValue: '39',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Promo Code Replacement',
    CodeShortDescription: '',
    CodeType: 'PRICE_ADJUSTMENT',
    CodeValue: '40',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Swoosh Discount',
    CodeShortDescription: '',
    CodeType: 'PRICE_ADJUSTMENT',
    CodeValue: '41',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Courtesy Discount',
    CodeShortDescription: '',
    CodeType: 'PRICE_ADJUSTMENT',
    CodeValue: '42',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Shipping Refund',
    CodeShortDescription: '',
    CodeType: 'REFUND',
    CodeValue: '22',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Tax Refund',
    CodeShortDescription: '',
    CodeType: 'REFUND',
    CodeValue: '23',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Remake',
    CodeShortDescription: '',
    CodeType: 'REFUND',
    CodeValue: '219',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Returned to Consumer',
    CodeShortDescription: '',
    CodeType: 'Return Create Product Instructions',
    CodeValue: '250',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Re-Use A Shoe',
    CodeShortDescription: '',
    CodeType: 'Return Create Product Instructions',
    CodeValue: '251',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return Authorization Created by Mistake',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '301',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Need to change Style/Color/Size on Return Authorization',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '302',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Need to change Item Quantity on Return Authorization',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '303',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Need to change Return Reason on Return Authorization',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '304',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Manual Exchange in Lieu of Refund',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '305',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'RA Wrong Product Returned',
    CodeShortDescription: '',
    CodeType: 'RETURN_CANCEL',
    CodeValue: '306',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: "Can't find order",
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '44',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Invalid Payment Method',
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '45',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Wrong Product',
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '46',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Missing product',
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '47',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspection Escalation',
    CodeShortDescription: '',
    CodeType: 'ESCALATION',
    CodeValue: '48',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Product has been washed and worn',
    CodeShortDescription: '',
    CodeType: 'REJECT',
    CodeValue: '57',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Product Abuse',
    CodeShortDescription: '',
    CodeType: 'REJECT',
    CodeValue: '58',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Normal Wear/Tear',
    CodeShortDescription: '',
    CodeType: 'REJECT',
    CodeValue: '59',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspector Denial',
    CodeShortDescription: '',
    CodeType: 'REJECT',
    CodeValue: '60',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Non-Nike Product',
    CodeShortDescription: '',
    CodeType: 'REJECT',
    CodeValue: '61',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Courtesy',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Policy- Return within Returns policy',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '101',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Policy- Returned unwashed/unworn/unopened',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '102',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Manufacturer Policy -Material or Workmanship Flaw',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '103',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Under Warranty - Bat/Golf/Tennis/Timing/Other',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '104',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Damaged-Damaged During Delivery',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '105',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Damaged- Missing on Order',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '106',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Cycleon- Item count Match',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '107',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PO (Proof of delivery - inbound)',
    CodeShortDescription: '',
    CodeType: 'INSPECTION_PASS',
    CodeValue: '252',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return to stock (RTS)',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '49',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Non-RTS',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '50',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Returned to consumer',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '51',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Re-Use a shoe',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '52',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Non-Merchandise Return',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '53',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Handed to Nike',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '54',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Refunds in lieu of Exchange',
    CodeShortDescription: '',
    CodeType: 'RETURN_DISPOSITION',
    CodeValue: '56',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: "Doesn't Fit",
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '1',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Flawed',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '2',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Lost/Damaged In Transit',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '3',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Returning a gift',
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '4',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Wrong item shipped',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '5',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Not As Expected',
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '6',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return to Shipper (undeliverable product - carrier return)',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '7',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Duplicate Order',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '8',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: "Don't Want",
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '9',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Tracer (Consumers says never got it)',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '10',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Confirmed Fraud (product recovered)',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '11',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Out of Stock',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '12',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'No Reason',
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '13',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Missing on Order (MoO)',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '16',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'POD (Proof of delivery - inbound)',
    CodeShortDescription: 'C',
    CodeType: 'RETURN',
    CodeValue: '24',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Courtesy',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '254',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Technical Flaw',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '451',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Connectivity',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '452',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Battery/Charging',
    CodeShortDescription: 'N',
    CodeType: 'RETURN',
    CodeValue: '453',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike US',
    CodeShortDescription: '',
    CodeType: 'WQENTERPRISECODE',
    CodeValue: 'NIKEUS',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike Europe',
    CodeShortDescription: '',
    CodeType: 'WQENTERPRISECODE',
    CodeValue: 'NIKEEUROPE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'US Niketown',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '0',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'NikeFind',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '40',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Swoosh',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '55',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Catalog Store',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '56',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'US Retail Studio',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '201',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'EMEA Nike Store',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '300',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'EMEA Retail Studio',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '301',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Umbro',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '302',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inline',
    CodeShortDescription: '',
    CodeType: 'WQLINETYPE',
    CodeValue: 'INLINE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'NikeID',
    CodeShortDescription: '',
    CodeType: 'WQLINETYPE',
    CodeValue: 'NIKEID',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Digital GC',
    CodeShortDescription: '',
    CodeType: 'WQLINETYPE',
    CodeValue: 'EGC',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Gift Certificate',
    CodeShortDescription: '',
    CodeType: 'WQLINETYPE',
    CodeValue: 'GC',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Created',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '1100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Scheduled',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '1500',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Released',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Rescheduling',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '1300',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Awaiting Chained Order creation',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '1600',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Chained Order created',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '2100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PO Released',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '2100.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PO Acknowledged',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '2100.200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PO Sent to Warehouse',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '2100.300',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Acknowledged',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Sent To Warehouse',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.1000',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Scheduled for Pick',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Awaiting Shipment',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.300',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Order Received',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.400',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Order Processed',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.500',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Factory Processing',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.600',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Factory Completed',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.700',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Factory Delayed',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.800',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'DSV Acknowledged',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3200.900',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Shipped',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return created',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.01',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return In Progress',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.02',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return Received',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.03',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Returned at Store',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.04',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return Processed',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.05',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Return Denied',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.06',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Origin Scan',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.10',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Custom/Shipper Cleared',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivered to Store',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.110',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Picked Up',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.20',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Manifest Scan',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'In transit',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.30',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivery Attempt 1',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.40',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivery Attempt 2',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.50',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivery Attempt 3',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.60',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivered',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.70',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Unable to Deliver',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.80',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Refused Shipment',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '3700.90',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Cancelled',
    CodeShortDescription: '',
    CodeType: 'WQSOSTATUS',
    CodeValue: '9000',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'POS Purchase',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'POS_PURCHASE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Offline Bank Transfer',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'OFFLINE_BANK',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Check',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'CHECK',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Credit Card',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'CREDIT_CARD',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Customer Account',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'CUSTOMER_ACCOUNT',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Other',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'OTHER',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'PayPal',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'PAYPAL',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Gift Certificate',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'GIFT_CERTIFICATE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Voucher',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'VOUCHER',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Realtime Bank Transfer',
    CodeShortDescription: '',
    CodeType: 'WQPMTTYPE',
    CodeValue: 'REALTIME_BANK',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Authorized',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'AUTHORIZED',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Awaiting Authorization',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'AWAIT_AUTH',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Awaiting Payment Information',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'AWAIT_PAYMENT_INFO',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Invoiced',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'INVOICED',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Paid',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'PAID',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Failed Authorization',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'FAILED_AUTH',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Failed Charge',
    CodeShortDescription: '',
    CodeType: 'WQPMTSTATUS',
    CodeValue: 'FAILED_CHARGE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Ground',
    CodeShortDescription: '',
    CodeType: 'WQCARRSRVCCODE',
    CodeValue: 'Ground',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Two-day',
    CodeShortDescription: '',
    CodeType: 'WQCARRSRVCCODE',
    CodeValue: 'Two-day',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Next-day',
    CodeShortDescription: '',
    CodeType: 'WQCARRSRVCCODE',
    CodeValue: 'Next-day',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Military',
    CodeShortDescription: '',
    CodeType: 'WQCARRSRVCCODE',
    CodeValue: 'Military',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Created',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '1100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Intransit',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '1100.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Delivered to Return Center',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '1100.200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Early Refund',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '1100.300',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Received at Return Center',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspection Passed',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.200',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspection Escalate',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '1100.500',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspection Denied',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.400',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Passed at Consolidated Hub',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.500',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Denied at Consolidated Hub',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.600',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'CSR Refund',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.700',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Inspected And Invoiced',
    CodeShortDescription: '',
    CodeType: 'WQROSTATUS',
    CodeValue: '3950.01.100',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'CURRENT STATE',
    CodeShortDescription: '',
    CodeType: 'COL',
    CodeValue: 'IS',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'CURRENT STATE',
    CodeShortDescription: '',
    CodeType: 'SHP',
    CodeValue: 'CI',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'CURRENT STATE',
    CodeShortDescription: '',
    CodeType: 'DEL',
    CodeValue: 'CIS',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'CSR Issue',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'CSR_ISSUE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'New Member Free Shipping Failed',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'FREE_SHIP_FAIL',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'New Order vs. Exchange',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'NEW_ORD_VS_EXCH',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Order Arrived Late',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'ORD_ARR_LATE',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Product Not as Advertised',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'NOT_AS_ADVERT',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Promo Code Used Did Not Work',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'PROMO_USE_FAIL',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Received Wrong Order or Items',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'RECEIVED_WRONG_ORD',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Unable to Add to Existing Order',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'UNABLE_TO_ADD_TO_ORD',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Other (must describe reason)',
    CodeShortDescription: '',
    CodeType: 'PROMO_CODE',
    CodeValue: 'OTHER',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike China',
    CodeShortDescription: '',
    CodeType: 'WQENTERPRISECODE',
    CodeValue: 'NIKECN',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'Nike HK',
    CodeShortDescription: '',
    CodeType: 'WQENTERPRISECODE',
    CodeValue: 'NIKEHK',
    OrganizationCode: 'NIKEUS',
  },
  {
    CodeLocale: 'en_US',
    CodeLongDescription: 'BM Transactions',
    CodeShortDescription: '',
    CodeType: 'WQDIVISION',
    CodeValue: '600',
    OrganizationCode: 'NIKEUS',
  },
];

function Code(obj) {
  this.locale = obj.CodeLocale;
  this.longDescription = obj.CodeLongDescription;
  this.shortDescription = obj.CodeShortDescription;
  this.type = obj.CodeType;
  this.value = obj.CodeValue;
  this.organizationCode = obj.OrganizationCode;
}

Code.prototype.valueOf = function() {
  return this.longDescription;
};

const Codes = codeArray.reduce((acc, cur) => {
  acc[cur.CodeValue] = new Code(cur);
  return acc;
}, {});

export default Codes;
