/* React/Utils */
import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/* Material-UI */
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** Local */
import translations from '../payments.i18n';
import AddressForm from '../../../../address/addressForm';
import { ApiTimeOut } from '../../../../../constants/dialog.const';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import usePaymentMods, { GenerateOrderNotes } from '../../../../../hooks/usePaymentMods';
import useSnacks from '../../../../../hooks/useSnacks';
import SUBMIT_CREDIT_CARD_MUTATION from '../../../../../mutations/submitCreditCard.mutation';
import dialogActions from '../../../../../store/actions/dialogActions';
import { OrderContext } from '../../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../../store/contexts/dialogContext';
import { SettingsContext } from '../../../../../store/contexts/settingsContext';
import EUCountries from '../../../../../constants/address/EUCountries.const';
import Geo from '../../../../../constants/geos.const';
import Loading from '../../../../shared/loading';
import config from './../../../../../utils/config';

/**
 * implementation of the address form used to add a credit card to an order
 * @param {Object}: {
 *   @param {boolean} triggerSubmission - boolean indicating if the submit button has been clicked
 *   @param {function} setTriggerSubmission - function that sets triggerSubmission in modal state
 *   @param {function} shouldSubmitBeDisabled - function that sets submitDisabled in modal state
 *   @param {function} updatePaymentMethods - function that refetches payment details
 *   @param {object} activeCC - active credit card details, if one is active
 *   @param {function} setDialogOpen - function that sets isDialogOpen in state
 * }
 * @returns React Component
 */
export default function AddCCPaymentForm({
  triggerSubmission,
  setTriggerSubmission,
  shouldSubmitBeDisabled,
  updatePaymentMethods,
  activeCC,
  setDialogOpen,
}) {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [settingsState] = useContext(SettingsContext);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const address = dialogState.address;
  const { setError, setSnack } = useSnacks();

  const {
    firstName,
    lastName,
    address1,
    address2,
    address3,
    city,
    state,
    country,
    postalCode,
    dayPhoneNumber: phoneNumber,
  } = address;
  const [orderDetail] = useContext(OrderContext);
  const { orderNumber, omsRegionReference, billTo } = orderDetail;
  const { setAddressField } = dialogActions;

  // all the variables that we want to be truthy for the submit button to be enabled
  const addressValuesThatMustBeTruthy = [
    'firstName',
    'lastName',
    'address1',
    'city',
    'country',
    'postalCode',
  ];

  // Created this obj so that we can set the disabled country field using the full country name
  const EuUsJpAUCountries = [
    ...EUCountries,
    {
      name: 'Japan',
      label: 'Japan',
      abbreviation: 'JP',
      alpha3Code: 'JPN',
    },
    {
      name: 'United States',
      label: 'United States',
      abbreviation: 'US',
      alpha3Code: 'USA',
    },
    {
      name: 'Australia',
      label: 'Australia',
      abbreviation: 'AU',
      alpha3Code: 'AUS',
    },
  ];

  // Credit card payment form only supports 2 langLocales, US and JP.
  const langLocale = settingsState.language === 'ja' ? 'ja_JP' : 'en_US';

  const [creditCardInfoId] = useState(uuidv4());
  const [isCreditCardInfoValid, setIsCreditCardInfoValid] = useState(false);
  const [isJapanPhoneNumberValid, setIsJapanPhoneNumberValid] = useState(false);

  const [disabledCountry, setDisabledCountry] = useState('');

  const {
    ADD_CREDIT_CARD_SUCCESS,
    ADD_CREDIT_CARD_FAILURE,
    COUNTRY,
    CONFIRM_ACTIVATING_CARD,
    SURE_TO_CONTINUE,
  } = useMemoTranslations({ ...translations });

  const [submitCreditCard, { loading: addCreditCardLoading }] = useMutation(
    SUBMIT_CREDIT_CARD_MUTATION,
    {
      onError: (error) => {
        setError(`${ADD_CREDIT_CARD_FAILURE} ${error.message}`);
        setTriggerSubmission(false);
      },
      onCompleted: ({ submitCreditCard }) => {
        const error = submitCreditCard?.addCreditCardResponse?.error;
        if (error) {
          setError(`${ADD_CREDIT_CARD_FAILURE} ${error.message}`);
          setTriggerSubmission(false);
          return;
        }
        setDialogOpen(false);
        setSnack(ADD_CREDIT_CARD_SUCCESS);
        updatePaymentMethods();
      },
    }
  );

  const orderNotes = GenerateOrderNotes('CSR Payment Add', `Added credit card`);

  // Submit button for adding the credit card to the order.
  const handleAddCCPaymentType = () => {
    submitCreditCard({
      variables: {
        input: {
          creditCardInfoId,
          addCreditCardInput: {
            orderInfo: {
              id: orderNumber,
              billingInfo: {
                firstName,
                lastName,
                address1,
                address2,
                address3,
                city,
                state,
                postalCode,
                country,
                phoneNumber,
              },
            },
            orderNotes,
          },
        },
        timeout: ApiTimeOut,
      },
    });
  };

  const { handleSuspendPaymentMethod } = usePaymentMods(handleAddCCPaymentType);

  // Check to see if the submit button should be disabled.
  useEffect(() => {
    if (omsRegionReference === Geo.US) addressValuesThatMustBeTruthy.push('state');

    const isPhoneNumberValid =
      omsRegionReference === Geo.JAPAN
        ? isJapanPhoneNumberValid
        : /*
          The mask placed on the input field causes it to have _ and - characters, which leads it to
          always having 13 characters. The replace function below with this regex will remove 
          anything that is not a digit.
        */
          Boolean(address.dayPhoneNumber.replace(/\D/g, '').length);

    const isAddressInfoValid = addressValuesThatMustBeTruthy.every((property) =>
      Boolean(address[property])
    );

    shouldSubmitBeDisabled(!(isAddressInfoValid && isPhoneNumberValid) || !isCreditCardInfoValid);
  }, [address, isCreditCardInfoValid, isJapanPhoneNumberValid]);

  useEffect(() => {
    if (country) {
      const countryObj = EuUsJpAUCountries.find((euCountry) => euCountry.abbreviation === country);
      setDisabledCountry(countryObj?.name);
    }
  }, [country]);
  /*
    useEffect to check if the payment iframe is filled and valid. The iframe form will only
    submit and dispatch the event, that we are looking for, when the form has been properly
    filled out and is complete.
   */
  useEffect(() => {
    const messageHandler = (event) => {
      // checking the host as we are only interested in payment events
      if (event?.origin === config.paymentIframeHost) {
        // isValid can return: null, false, true
        setIsCreditCardInfoValid(event?.data?.isValid);
      }
    };
    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  useEffect(() => {
    if (triggerSubmission) {
      if (activeCC?.paymentId) {
        handleSuspendPaymentMethod(orderNumber, activeCC?.paymentId, suspendOrderNote);
        return;
      }
      handleAddCCPaymentType();
    }
  }, [triggerSubmission]);

  // when the app first loads we want to ensure we have a default country
  useEffect(() => {
    dialogDispatch(setAddressField('country', billTo?.address?.country));
    // adding email as well so we can reuse dialog address form
    dialogDispatch(setAddressField('email', 'needed@for.validation'));
    if (omsRegionReference === Geo.US) {
      classes.addressGrid = classes.usAddressGrid;
    } else if (omsRegionReference === Geo.JAPAN) {
      classes.addressGrid = classes.jpAddressGrid;
    } else if (omsRegionReference === Geo.EUROPE) {
      classes.addressGrid = classes.euAddressGrid;
    } else if (omsRegionReference === Geo.AUSTRALIA) {
      classes.addressGrid = classes.auAddressGrid;
    }
  }, [orderDetail]);

  const suspendOrderNote = GenerateOrderNotes('CSR Payment Modify', `Suspended Credit Card`);

  const creditCardIframeUrl = `${config.paymentIframeHost}/services/default?id=${creditCardInfoId}&ctx=checkout&language=${langLocale}&maskerEnabled=true`;

  return (
    <>
      <AddressForm
        region={omsRegionReference}
        customClasses={classes}
        variant={'outlined'}
        setIsJapanPhoneNumberValid={setIsJapanPhoneNumberValid}>
        {/* Choosing to use Loading instead of CircularProgress because if the form  does not then
        there is no point in having the athlete fill in the rest of the form. */}
        {!isIframeLoaded && <Loading />}
        <iframe
          className={classes.creditCardForm}
          title='paymentIframe'
          data-testid={'payment-iFrame'}
          src={creditCardIframeUrl}
          scrolling='no'
          frameBorder='0'
          height='70px'
          width='99.7%'
          onLoad={() => setIsIframeLoaded(true)}
        />
        <TextField
          required
          className={classes.disabledCountry}
          data-testid={'country'}
          disabled={true}
          value={disabledCountry}
          label={COUNTRY}
        />
        <div className={classes.suspendCheckbox}>
          {/* uncomment for future ticket https://jira.nike.com/browse/FORGE-11401 */}
          {/* <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              classes={{ root: classes.checkbox, checked: classes.checked }}
            />
          }
          label='Suspend previous payment method'
          disabled={activeCC?.paymentId}
        /> */}
          {activeCC?.paymentId && (
            <Typography>
              {CONFIRM_ACTIVATING_CARD} {activeCC?.accountNumber?.slice(-4)}. {SURE_TO_CONTINUE}
            </Typography>
          )}
        </div>
      </AddressForm>
      {addCreditCardLoading && <Loading />}
    </>
  );
}

AddCCPaymentForm.propTypes = {
  triggerSubmission: PropTypes.bool,
  setTriggerSubmission: PropTypes.func,
  shouldSubmitBeDisabled: PropTypes.func,
  updatePaymentMethods: PropTypes.func,
  activeCC: PropTypes.shape({
    accountNumber: PropTypes.string,
    paymentId: PropTypes.string,
  }),
  setDialogOpen: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  /** these styles apply to components in the address component */
  usAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
    " CF CF CF CF CF CF  "
    " FN FN FN LN LN LN  "
    " A1 A1 A1 A2 A2 A2  "
    " CI CI ST ST ZI ZI  "
    " DC DC DC .. .. ..  "
    " PH PH PH .. .. ..  "
    " CB CB CB CB CB CB  "
    `,
    gridGap: '1.5rem 1.5rem',
  },
  euAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " CF CF CF CF CF CF  "
      " EX EX EX CV CV CV  "
      " FN FN FN LN LN LN  "
      " A1 A1 A1 A2 A2 A2  "
      " CI CI CI PO PO PO  "
      " DC DC DC .. .. ..  "
      " PH PH PH .. .. ..  "
      " CB CB CB CB CB CB  "
      `,
    gridGap: '1.5rem 1.5rem',
  },
  jpAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
      " CF CF CF CF CF CF  "
      " EX EX EX CV CV CV  "
      " LN LN LN FN FN FN  "
      " KL KL KL KF KF KF  "
      " DC DC DC .. .. ..  "
      " PO PO PR PR CI CI  "
      " A3 A3 A3 A3 A3 A3  "
      " A1 A1 A1 A2 A2 A2  "
      " PH PH PH .. .. ..  "
      " CB CB CB CB CB CB  "
      `,
    gridGap: '1.5rem 1.5rem',
  },
  auAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
    " CF CF CF CF CF CF  "
    " FN FN FN LN LN LN  "
    " A1 A1 A1 A2 A2 A2  "
    " CI CI ST ST ZI ZI  "
    " DC DC DC .. .. ..  "
    " PH PH PH .. .. ..  "
    " CB CB CB CB CB CB  "
    `,
    gridGap: '1.5rem 1.5rem',
  },
  // hide the normal country selector inside Address form
  country: {
    display: 'none',
  },
  /** this style applies to both */
  Input: {
    'width': '100%',
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  /** these styles affect local components */
  creditCardForm: {
    gridArea: 'CF',
    margin: 0,
  },
  suspendCheckbox: {
    gridArea: 'CB',
  },
  // checkbox: {
  //   '&$checked': {
  //     color: theme.palette.common.black,
  //   },
  // },
  // checked: {},
  disabledCountry: {
    'width': '100%',
    'gridArea': 'DC',
    '& .MuiInputBase-formControl': {
      '& input': {
        color: theme.palette.common.black,
      },
      // removing underline to make it clear athletes can't change country
      '&::before': {
        border: '0',
      },
      '&::after': {
        border: '0',
      },
    },
  },
}));
