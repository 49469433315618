const translations = {
  DIALOG_TITLE: {
    description: 'Title of Recalculate tax dialog',
    primaryValue: 'Recalculate tax',
    stringKey: 'recalculateTax_dialog_title',
  },
  TAX_RECALCULATE_SUCCESS: {
    description: 'Success message displayed when tax recalculated',
    primaryValue: 'Tax recalculated successfully',
    stringKey: 'tax_recalculate_success_response',
  },
  TAX_RECALCULATE_ERROR: {
    description: 'Error message displayed when tax recalculated',
    primaryValue: 'Error on tax recalculation',
    stringKey: 'tax_recalculate_error_response',
  },
  TAX_RECALCULATE_TIME_OUT_ERROR_MESSAGE: {
    primaryValue: 'Recalculate tax is still pending',
    description: 'Error message when tax recalculate call times out',
    stringKey: 'tax_recalculate_time_out_error_message',
  },
};

export default translations;
